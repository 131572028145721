import React from "react";
import styled from "styled-components";

import { TextField } from "~/components/uiParts";
type Props = { value: string; onChange(value: string): void };
export const FreeTextInput = ({ value, onChange }: Props): JSX.Element => {
  return (
    <WhiteBackgroundTextField
      placeholder="入力してください"
      fullWidth
      variant="outlined"
      value={value}
      onChange={(e) => onChange(e.target.value)}
      size="small"
    />
  );
};

const WhiteBackgroundTextField = styled(TextField)`
  .MuiInputBase-root {
    background-color: white;
  }
`;
