import { useState, useCallback } from "react";

import { useCreateUnreadCountByEmployeeIds } from "../contactRoomUnreadCount";
import { useSnackbar } from "../shared";

import { apiClient } from "~/libs";
import { captureException } from "~/util";

/**
 *AssigneeとFollowersのIDを更新するための関数を提供するhooks
 * @returns isLoading データを処理中かどうか
 * @returns updateAssigneeAndFollowers
 */
export const useUpdateAssigneeAndFollowers = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { createUnreadCountByEmployeeIds } = useCreateUnreadCountByEmployeeIds();
  /**
   * aAssigneeとFollowersのIDを更新するための関数
   */
  const updateAssigneeAndFollowers = useCallback(
    async (contactRoomId: string, assigneeId: string | undefined, followerIds: string[]) => {
      setIsLoading(true);

      // 中途テナントの場合は入社者が担当者・フォロワーになる可能性があるため
      await createUnreadCountByEmployeeIds({
        employeeIds: [...followerIds, assigneeId].flatMap((id) => id || []),
        contactRoomId,
      }).catch((e) => {
        captureException({
          error: e as Error,
          tags: { type: "updateAssigneeAndFollowers:createUnreadCountByEmployeeIds" },
          extras: {
            contactRoomId,
            assigneeId,
            followerIds,
          },
        });
      });
      return await apiClient
        .patch("/contact_room_api/contact-room/assignee-and-followers", {
          contactRoomId,
          assigneeId,
          followerIds,
        })
        .then(() => {
          enqueueSnackbar(`対応チームを更新しました`, { variant: "success" });
        })
        .catch((e) => {
          enqueueSnackbar(e.message, { variant: "error" });
          throw new Error(e.message);
        })
        .finally(() => setIsLoading(false));
    },
    [createUnreadCountByEmployeeIds, enqueueSnackbar]
  );

  return { isLoading, updateAssigneeAndFollowers };
};
