import { OnnEvent, generatePagePath } from "@onn/common";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

import { useModal } from "~/hooks/modal";
import { useUpdateOnnEventAssignee } from "~/hooks/onnEvent";

export const useHooks = (allOnnEvents: OnnEvent[]) => {
  const navigation = useNavigate();
  const { handleModal } = useModal();
  const { updateOnnEventAssignee } = useUpdateOnnEventAssignee();

  // 「イベント名」検索の入力値
  // const [_, setSearchValue] = useState("");

  // イベント新規作成ボタンのクリックハンドラ
  const handleClickCreateButton = () => {
    navigation(generatePagePath.onnEvent.create({ eventType: "normal" }));
  };

  // 「担当者」の変更ボタンのクリックハンドラ
  const handleClickAssignButton = useCallback(
    (onnEventId: string) => {
      handleModal({
        name: "updateEventAssigneeModal",
        args: {
          existingAssigneeIds:
            allOnnEvents.find((event) => event.id === onnEventId)?.assigneeIds ?? [],
          onSubmit: async (employeeIds) => {
            updateOnnEventAssignee({ onnEventId, employeeIds });
          },
        },
      });
    },
    [allOnnEvents, handleModal, updateOnnEventAssignee]
  );

  return {
    handleClickCreateButton,
    handleClickAssignButton,
  };
};
