import { useCallback } from "react";

import { useSnackbar } from "../shared/useSnackbar";

import { apiClient } from "~/libs";
import { captureException } from "~/util";

export function useCancelContactMessagesOrder() {
  const { enqueueSnackbar } = useSnackbar();
  /**
   * 一括メッセージ予約をキャンセルする
   */
  const cancelOrder = useCallback(
    async (contactMessagesOrderId: string) => {
      try {
        await apiClient.delete(`/delete_contact_messages_order`, {
          ["contact-messages-order-id"]: contactMessagesOrderId,
        });

        enqueueSnackbar("配信予約をキャンセルしました", { variant: "success" });
      } catch (e) {
        const errorMessage =
          e instanceof Error && e.message
            ? e.message
            : "配信予約のキャンセルに失敗しました。キャンセルが必要な場合は、Onn の管理者にお問い合わせください。";
        enqueueSnackbar(errorMessage, { variant: "error" });
        captureException({
          error: e as Error,
          tags: {
            type: "useCancelContactMessagesOrder:cancelOrder",
          },
        });
      }
    },
    [enqueueSnackbar]
  );

  return { cancelOrder };
}
