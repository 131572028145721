import { OnnEvent } from "@onn/common";
import { OnnEventAnswerForDisplay } from "@onn/common/domain/OnnEvent/OnnEventAnswer";
import { isEmpty } from "lodash";
import React, { FC, useMemo } from "react";
import { GridCellRenderer, Index, MultiGridProps } from "react-virtualized";

import { BodyCell } from "./BodyCell";
import { HeaderCell } from "./HeaderCell";
import {
  BODY_CELL_HEIGHT_PX,
  HEADER_CELL_HEIGHT_PX,
  MULTI_GRID_MAX_HEIGHT,
  PAPER_PADDING,
  columnTypes,
  widthPxMap,
} from "./utils";

import { MultiGridOnPaper } from "~/components/uiParts";

const useMultiGridOnPaper = ({
  onnEventAnswersForDisplay,
  onnEvent,
}: {
  onnEventAnswersForDisplay: OnnEventAnswerForDisplay[];
  onnEvent: OnnEvent;
}) => {
  const headerRowCount = 1;
  const rowCount = headerRowCount + onnEventAnswersForDisplay.length;
  const columnCount = columnTypes.length;

  // 各行の高さを格納した配列
  const rowHeightList = useMemo(
    () => [HEADER_CELL_HEIGHT_PX, ...onnEventAnswersForDisplay.map(() => BODY_CELL_HEIGHT_PX)],
    [onnEventAnswersForDisplay]
  );

  // MultiGridが各行の高さを算出するための関数
  const rowHeight = (params: Index) => {
    return rowHeightList[params.index] || BODY_CELL_HEIGHT_PX;
  };

  // MultiGridが各列の幅を算出するための関数
  const columnWidth = (params: Index) => {
    const columnType = columnTypes[params.index];
    if (columnType) return widthPxMap[columnType];

    return widthPxMap.slotTime;
  };

  // MultiGridの高さ
  const multiGridHight = useMemo(() => {
    const height = rowHeightList.reduce((acc, cur) => acc + cur, 0);
    return height > MULTI_GRID_MAX_HEIGHT ? MULTI_GRID_MAX_HEIGHT : height;
  }, [rowHeightList]);

  // ペーパーの高さ
  const paperHeight = useMemo(() => {
    const paddingTop = PAPER_PADDING;
    const paddingBottom = PAPER_PADDING;

    const result = paddingTop + paddingBottom + multiGridHight;
    return result;
  }, [multiGridHight]);

  const cellRenderer: GridCellRenderer = ({ style, rowIndex, columnIndex }) => {
    const columnType = columnTypes[columnIndex];
    if (!columnType) return;
    const isLastColumn = columnIndex === columnTypes.length - 1;
    const isShowRightBorder = !isLastColumn;

    const key = String(rowIndex) + String(columnIndex);

    if (rowIndex === 0) {
      return (
        <HeaderCell
          key={key}
          style={style}
          columnType={columnType}
          numberOfAdjusted={
            onnEventAnswersForDisplay.filter((v) => v.getStatusForDisplay() === "adjusted").length
          }
          numberOfIsRejectedOrWithdrew={
            onnEventAnswersForDisplay.filter((v) => v.newGraduateToDisplay.isRejectedOrWithdrew())
              .length
          }
          isShowRightBorder={isShowRightBorder}
        />
      );
    }
    const onnEventAnswerForDisplay = onnEventAnswersForDisplay[rowIndex - 1];
    if (!onnEventAnswerForDisplay) return;

    return (
      <BodyCell
        key={key}
        onnEventAnswerForDisplay={onnEventAnswerForDisplay}
        style={style}
        columnType={columnType}
        isShowRightBorder={isShowRightBorder}
        onnEvent={onnEvent}
      />
    );
  };

  const multiGridProps: MultiGridProps = {
    rowCount,
    columnCount,
    rowHeight,
    columnWidth,
    height: multiGridHight,
    cellRenderer,
    enableFixedColumnScroll: true,
    fixedRowCount: 1,
    width: 0, // NOTE: widthはAutoSizerで取得するためこの値はdummy
  };
  return { multiGridProps, paperHeight };
};

type Props = {
  onnEventAnswersForDisplay: OnnEventAnswerForDisplay[];
  isLoading: boolean;
  onnEvent: OnnEvent;
};

export const OnnEventSlotDatesMultiGrid: FC<Props> = ({
  onnEventAnswersForDisplay,
  isLoading,
  onnEvent,
}) => {
  const { multiGridProps, paperHeight } = useMultiGridOnPaper({
    onnEventAnswersForDisplay,
    onnEvent,
  });

  return (
    <MultiGridOnPaper
      multiGridProps={multiGridProps}
      paperHeight={paperHeight}
      isLoading={isLoading}
      isEmpty={isEmpty(onnEventAnswersForDisplay)}
      labelWhenEmpty="面談予定がありません"
    />
  );
};
