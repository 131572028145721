import React, { FC, memo, useCallback } from "react";

import { DraggableProvidedDragHandleProps, DropResult } from "react-beautiful-dnd";
import { v4 } from "uuid";

import { RadioQuestionFormUIMemo } from "../../_share_in_create_edit/components/forms/RadioQuestionFormUIMemo";
import { Action } from "../../_share_in_create_edit/reducer";

import { RADIO_QUESTION_FOR_FORM } from "../../_share_in_create_edit/types";

import { useQuestionFormBase } from "./useQuestionFormBase";

export type Props = {
  radioQuestion: RADIO_QUESTION_FOR_FORM;
  dispatch: React.Dispatch<Action>;
  questionDragHandleProps: DraggableProvidedDragHandleProps | null | undefined;
};

const useRadioQuestionForm = ({
  dispatch,
  radioQuestion: question,
}: {
  dispatch: React.Dispatch<Action>;
  radioQuestion: RADIO_QUESTION_FOR_FORM;
}) => {
  const {
    onChangeQuestionOptionType,
    onChangeTitle,
    onChangeIsRequired,
    deleteQuestion,
    onChangeOptionOrder,
  } = useQuestionFormBase({
    question: {
      id: question.id,
      isRequired: question.isRequired,
      title: question.title,
      isDirtyTitle: question.isDirtyTitle,
      isTitleError: question.isTitleError,
      titleHelperText: question.titleHelperText,
      disabledOptionType: question.disabledOptionType,
      disabledTitle: question.disabledTitle,
    },
    dispatch,
  });

  const onClickDuplication = () => {
    dispatch({
      type: "DUPLICATION_QUESTION",
      payload: {
        question: {
          type: "RADIO",
          title: question.title,
          isRequired: question.isRequired,
          id: v4(),
          options: [...question.options],
          disabledTitle: false,
          disabledOptionType: false,
          isDirtyTitle: question.isDirtyTitle,
          isTitleError: question.isTitleError,
          titleHelperText: question.titleHelperText,
        },
        fromQuestionId: question.id,
      },
    });
  };

  const handleAddOption = () => {
    dispatch({
      type: "RADIO_QUESTION_ADD_OPTION",
      payload: {
        questionId: question.id,
        optionId: v4(),
      },
    });
  };

  const handleOnChangeOptionLabel = (id: string, label: string) => {
    dispatch({
      type: "RADIO_QUESTION_UPDATE_OPTION",
      payload: {
        questionId: question.id,
        optionId: id,
        text: label,
      },
    });
  };

  const handleDeleteOption = (id: string) => {
    dispatch({
      type: "RADIO_QUESTION_DELETE_OPTION",
      payload: {
        questionId: question.id,
        optionId: id,
      },
    });
  };

  const onChangeOptionsOrder = useCallback(
    (result: DropResult) => {
      // drop先がない場合は処理を終了
      if (!result.destination) return;

      // drag開始元とdrop先を取得
      const { index: sourceIndex } = result.source;
      const { index: destinationIndex } = result.destination;

      // drop可能範囲以外でのdropは無効 or 移動元と移動先が同じ場合は処理を終了
      if (destinationIndex === undefined || sourceIndex === destinationIndex) return;

      onChangeOptionOrder({
        sourceIndex,
        destinationIndex,
      });
    },
    [onChangeOptionOrder]
  );

  return {
    onChangeQuestionOptionType,
    onChangeTitle,
    onChangeIsRequired,
    deleteQuestion,
    handleAddOption,
    handleDeleteOption,
    handleOnChangeOptionLabel,
    onClickDuplication,
    onChangeOptionsOrder,
  };
};

// 後続TODO: 実装
export const RadioQuestionForm: FC<Props> = ({
  dispatch,
  radioQuestion,
  questionDragHandleProps,
}) => {
  const {
    onChangeQuestionOptionType,
    onChangeTitle,
    handleAddOption,
    handleDeleteOption,
    onChangeIsRequired,
    deleteQuestion,
    onClickDuplication,
    handleOnChangeOptionLabel,
    onChangeOptionsOrder,
  } = useRadioQuestionForm({
    dispatch,
    radioQuestion,
  });

  const { title: questionTitle, isRequired } = radioQuestion;

  return (
    <RadioQuestionFormUIMemo
      isRequired={isRequired}
      onChangeOptionType={onChangeQuestionOptionType}
      onChangeTitle={onChangeTitle}
      onChangeIsRequired={onChangeIsRequired}
      onClickDelete={deleteQuestion}
      onClickDuplication={onClickDuplication}
      onClickAddOption={handleAddOption}
      onClickDeleteOption={handleDeleteOption}
      onChangeOptionLabel={handleOnChangeOptionLabel}
      options={radioQuestion.options}
      onChangeOptionsOrder={onChangeOptionsOrder}
      questionDragHandleProps={questionDragHandleProps}
      titleTextField={{
        value: questionTitle,
        helperText: radioQuestion.titleHelperText,
        error: radioQuestion.isTitleError,
      }}
    />
  );
};

export const RadioQuestionFormMemo = memo<Props>(RadioQuestionForm);
