import React, { FC } from "react";

import { CellItem, StyleProps } from "./CellItem";

import { Icon, Typography } from "~/components/uiParts";

export const ProgressStatusCell: FC<
  {
    label?: string;
  } & StyleProps
> = ({ label, ...styleProps }) => {
  return (
    <CellItem {...styleProps}>
      {label ? (
        <Typography variant="body2" color={label === "未設定" ? "secondary" : "textSecondary"}>
          {label}
        </Typography>
      ) : (
        <Icon color="lightGrey" size="xl" icon="horizontalBar" />
      )}
    </CellItem>
  );
};
