import { OnnEventAnswer } from "../../../../domain";
import {
  toCloudFunctionsCompatible,
  ToCloudFunctionsCompatible,
} from "../../../../utils/toCloudFunctionsCompatible";
import { convertUnixTimeToDateOnnEventAnswer } from "../../_shared/convertUnixTimeToDate";

export class UpdateOnnEventAnswerResponse {
  static ResponseBody: ToCloudFunctionsCompatible<OnnEventAnswer>;
  public static createResponseBody({ onnEventAnswer }: { onnEventAnswer: OnnEventAnswer }) {
    return toCloudFunctionsCompatible(onnEventAnswer);
  }
  public static convertUnixTimeToDate(response: typeof this.ResponseBody) {
    const onnEventAnswer = response;
    return convertUnixTimeToDateOnnEventAnswer(onnEventAnswer);
  }
}
