export type FileType = "word" | "excel" | "powerPoint" | "pdf" | "image" | "zip" | "csv";

export const DEFAULT_MAX_FILE_SIZE_MB = 10;
export const DEFAULT_UPLOAD_ACCEPTED_FILE_TYPES: FileType[] = [
  "word",
  "excel",
  "powerPoint",
  "pdf",
  "image",
  "zip",
  "csv",
];
export const DEFAULT_UPLOAD_INPUT_ACCEPTED_FILE_TYPES =
  "application/gzip, .pdf, .doc, .docx, .xls, .xlsx, .pptx, .ppt, .gif, .jpg, .jpeg, .png, .zip";

export const fileTypeMatchers = {
  word: /.*\.(doc|docx)$/i,
  pdf: /.*\.(pdf)$/i,
  excel: /.*\.(xlsx|xls)$/i,
  powerPoint: /.*\.(pptx|ppt)$/i,
  image: /.*\.(gif|jpg|jpeg|png)$/i,
  zip: /.*\.(zip)$/i,
  csv: /.*\.(csv)$/i,
} as const satisfies Record<FileType, RegExp>;
