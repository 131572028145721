import {
  Transaction,
  TransactionStatus,
  TransactionDataType,
  MemoTransaction,
  Memo,
} from "@onn/common";
import {
  doc,
  DocumentReference,
  DocumentData,
  CollectionReference,
  collection,
  getDoc,
  updateDoc,
  setDoc,
  where,
  query,
  getDocs,
  Timestamp,
} from "firebase/firestore";
import { v4 } from "uuid";

// eslint-disable-next-line import/no-cycle
import {
  ITransactionRepository,
  IMemoTransactionRepository,
} from "../../service/repository/iTransactionRepository";

import { firestore } from "~/config/firebase";
import { convertToMemoTransaction } from "~/infrastructure/api/utils/transactionConverter";
import { convertDateToTimestamp } from "~/util/convertDateToTimestamp";
import { convertTimestampToDate } from "~/util/convertTimestampToDate";

export type MemoDB = {
  id: string;
  dataType: TransactionDataType.ADD_MEMO;
  contents: Memo;
  tenantId: string;
  employeeId: string;
  deleted: false;
  status: TransactionStatus.DONE;
  sendAt: Timestamp;
  createdAt: Timestamp;
  updatedAt: Timestamp;
};

const COLLECTION_NAME = "transactions";

export class TransactionRepository implements ITransactionRepository {
  async findById(id: string): Promise<Transaction> {
    const [doc] = (await getDocs(query(this.collection(), where("id", "==", id)))).docs;
    if (!doc) {
      throw new Error("no transaction found");
    }
    return this.convertToTransaction(doc.data());
  }
  async whereByDataTypes({
    employeeId,
    dataTypes,
  }: {
    employeeId: string;
    dataTypes: (keyof typeof TransactionDataType)[];
  }): Promise<(MemoTransaction | Transaction)[]> {
    const { docs } = await getDocs(
      query(
        this.collection(),
        where("employeeId", "==", employeeId),
        where("dataType", "in", dataTypes)
      )
    );

    return docs.map((doc) => {
      const data = doc.data();
      const dataType = data.dataType as TransactionDataType;

      switch (dataType) {
        case TransactionDataType.ADD_MEMO: {
          return convertToMemoTransaction(data as MemoDB);
        }

        default: {
          return this.convertToTransaction(data);
        }
      }
    });
  }
  async updateJoinDateType(tenantId: string, employeeId: string): Promise<void> {
    const refs = await getDocs(
      query(
        this.collection(),
        where("tenantId", "==", tenantId),
        where("employeeId", "==", employeeId),
        where("dataType", "==", "JOIN_DATE_INPUT")
      )
    );
    if (refs.docs.length === 0) {
      console.error("no join date input transaction found");
    } else {
      const doc = refs.docs[0] as (typeof refs.docs)[number];
      await updateDoc(doc.ref, { status: TransactionStatus.DONE });
    }
  }
  async updateProfileType(tenantId: string, employeeId: string): Promise<void> {
    const refs = await getDocs(
      query(
        this.collection(),
        where("tenantId", "==", tenantId),
        where("employeeId", "==", employeeId),
        where("dataType", "==", "PROFILE_COMMENT")
      )
    );

    if (refs.docs.length === 0) {
      console.error("no profile input transaction found");
    } else {
      const doc = refs.docs[0] as (typeof refs.docs)[number];
      await updateDoc(doc.ref, { status: TransactionStatus.DONE });
    }
  }

  private convertToTransaction = (data: DocumentData): Transaction => {
    return {
      id: data.id,
      contents: data.contents,
      dataType: data.dataType,
      remindDate: data.remindDate,
      resultContents: data.resultContents,
      sendAt: convertTimestampToDate(data.sendAt),
      sendTo: data.sendTo,
      status: data.status,
      templateId: data.templateId,
      tenantId: data.tenantId,
      employeeId: data.employeeId,
    } as Transaction;
  };

  private doc(id: string): DocumentReference<DocumentData> {
    return doc(firestore, COLLECTION_NAME, id);
  }

  private collection(): CollectionReference<DocumentData> {
    return collection(firestore, COLLECTION_NAME);
  }
}

export class MemoTransactionRepository implements IMemoTransactionRepository {
  async findMemo(id: string): Promise<MemoTransaction> {
    return await getDoc(this.doc(id)).then(async (memoTranDoc) => {
      return convertToMemoTransaction(memoTranDoc.data() as MemoDB);
    });
  }

  async createMemo({
    tenantId,
    employeeId,
    title,
    text,
    createdEmployeeId,
  }: {
    tenantId: string;
    employeeId: string;
    title: string;
    text: string;
    createdEmployeeId: string;
  }): Promise<{ memoId: string }> {
    const id = v4();
    const now = convertDateToTimestamp(new Date());
    const memoContent: Memo = {
      title,
      text,
      createdEmployeeId,
    };
    const memo: MemoDB = {
      id,
      dataType: TransactionDataType.ADD_MEMO,
      contents: memoContent,
      tenantId,
      employeeId,
      status: TransactionStatus.DONE,
      sendAt: now,
      createdAt: now,
      updatedAt: now,
      deleted: false,
    };

    const ref = this.doc(id);
    await setDoc(ref, memo);

    return { memoId: id };
  }

  async editMemo(id: string, memoContent: Memo): Promise<void> {
    const memoRef = this.doc(id);
    const updates: Pick<MemoDB, "contents" | "updatedAt"> = {
      contents: {
        title: memoContent.title,
        text: memoContent.text,
        createdEmployeeId: memoContent.createdEmployeeId,
      },
      updatedAt: convertDateToTimestamp(new Date()),
    };
    await updateDoc(memoRef, updates);
  }

  async deleteMemo(memoId: string): Promise<void> {
    const memoRef = this.doc(memoId);
    await updateDoc(memoRef, { deleted: true });
  }

  private doc(id: string): DocumentReference<DocumentData> {
    return doc(firestore, COLLECTION_NAME, id);
  }

  private collection(): CollectionReference<DocumentData> {
    return collection(firestore, COLLECTION_NAME);
  }
}

export type NewHireRegistrationDB = {
  id: string;
  dataType: TransactionDataType;
  remindDate: string;
  sendAt: Date;
  sendTo: string;
  status: TransactionStatus;
  templateId: string;
  tenantId: string;
  employeeId: string;
};
