import { uniq } from "lodash";
import { v4 } from "uuid";

import { getDayDiffInJST } from "../../../utils";
import { Employee } from "../../Employee";

import { ReadLog } from "../ReadLog/ReadLog";

import { IContactRoom } from "./schema";

export class ContactRoom implements IContactRoom {
  readonly id: string;
  readonly tenantId: string;
  spaceId?: string;
  employeeId: string; //入社者のid
  title: string;
  assigneeId?: string;
  followerIds: string[]; //メッセージを閲覧できるユーザーの配列
  isCompleted: boolean; //そのチャットのやり取りが完了しているかどうか
  readLogs: ReadLog[];
  incompleteSince: Date | null;
  type: "LINE_USER" | "EMAIL_USER";
  targetId?: string;

  createdEmployeeId?: string;
  updatedEmployeeId?: string;
  createdAt: Date;
  updatedAt: Date;

  constructor(init: ExcludeMethods<ContactRoom>) {
    this.id = init.id;
    this.tenantId = init.tenantId;
    this.spaceId = init.spaceId;
    this.employeeId = init.employeeId;
    this.title = init.title;
    this.followerIds = init.followerIds;
    this.isCompleted = init.isCompleted;
    this.readLogs = init.readLogs;
    this.assigneeId = init.assigneeId;
    this.incompleteSince = init.incompleteSince ?? null;
    this.type = init.type;
    this.targetId = init.targetId;

    this.createdEmployeeId = init.createdEmployeeId;
    this.updatedEmployeeId = init.updatedEmployeeId;
    this.createdAt = init.createdAt;
    this.updatedAt = init.updatedAt;
  }

  update(
    currentUserId: string | undefined,
    newObject: Partial<
      Pick<
        ContactRoom,
        | "title"
        | "employeeId"
        | "followerIds"
        | "isCompleted"
        | "readLogs"
        | "assigneeId"
        | "incompleteSince"
        | "spaceId"
      >
    >
  ): this {
    if (newObject.title) {
      this.title = newObject.title;
    }
    if (newObject.employeeId) {
      this.employeeId = newObject.employeeId;
    }
    if (newObject.followerIds) {
      this.followerIds = newObject.followerIds;
    }
    if (newObject.isCompleted != null && newObject.isCompleted !== this.isCompleted) {
      this.incompleteSince = newObject.isCompleted ? null : new Date();
    }
    if (newObject.isCompleted != null) {
      this.isCompleted = newObject.isCompleted;
    }
    if (newObject.readLogs) {
      this.readLogs = newObject.readLogs;
    }
    if (newObject.assigneeId) {
      this.assigneeId = newObject.assigneeId;
    }
    if (newObject.spaceId) {
      this.spaceId = newObject.spaceId;
    }

    // システムが更新するときはemployeeIdは存在しない
    if (currentUserId) {
      this.updatedEmployeeId = currentUserId;
    }
    this.updatedAt = new Date();

    return this;
  }

  updateReadLog(employeeId: string): this {
    const readLog = this.readLogs.find((readLog) => readLog.employeeId === employeeId);

    let newReadLogs: ReadLog[] = [];
    if (readLog) {
      newReadLogs = this.readLogs.map((readLog) => {
        if (readLog.employeeId === employeeId) return readLog.update();
        return readLog;
      });
    } else {
      newReadLogs = [...this.readLogs, ReadLog.createByEmployeeId(employeeId)];
    }

    return this.update(employeeId, {
      readLogs: newReadLogs,
    });
  }

  updateToLine(employee: Employee): ContactRoom {
    return ContactRoom.create({
      ...this,
      employeeId: employee.id,
      assigneeId: employee.mentorUserId || this.assigneeId,
      followerIds: uniq([...(employee.supportMemberEmployeeIds || []), ...this.followerIds]),
      spaceId: employee.spaceId,
      type: "LINE_USER",
      targetId: employee.lineUserId,
      updatedAt: new Date(),
    });
  }

  canNotify(employee: Employee) {
    if (this.type === "LINE_USER") {
      return employee.canNotifyWithLine();
    }

    return employee.canNotifyWithEmail();
  }

  public static create(params: Optional<ExcludeMethods<ContactRoom>, "id">): ContactRoom {
    return new ContactRoom({
      ...params,
      id: params.id ?? v4(),
    });
  }

  public static createForEmail({
    tenantId,
    currentUserId,
    followerIds,
    employeeId,
    spaceId,
  }: {
    tenantId: string;
    currentUserId: string;
    followerIds: string[];
    employeeId: string;
    spaceId?: string;
  }): ContactRoom {
    return ContactRoom.create({
      tenantId,
      employeeId,
      title: "メッセージルーム",
      assigneeId: undefined,
      followerIds,
      type: "EMAIL_USER",
      isCompleted: true,
      readLogs: [],
      createdAt: new Date(),
      createdEmployeeId: currentUserId,
      updatedAt: new Date(),
      updatedEmployeeId: currentUserId,
      incompleteSince: null,
      spaceId,
    });
  }

  public static createForLine(params: {
    tenantId: string;
    targetId: string;
    followerIds: string[];
    employeeId: string;
    spaceId?: string;
  }): ContactRoom {
    return ContactRoom.create({
      tenantId: params.tenantId,
      employeeId: params.employeeId,
      title: "コンタクトルーム",
      assigneeId: undefined,
      followerIds: params.followerIds,
      isCompleted: true,
      readLogs: [],
      createdAt: new Date(),
      createdEmployeeId: "",
      updatedAt: new Date(),
      updatedEmployeeId: "",
      incompleteSince: null,
      type: "LINE_USER",
      targetId: params.targetId,
      spaceId: params.spaceId,
    });
  }

  isAccessible(currentUser: Employee) {
    if (currentUser.tenantId !== this.tenantId) return false;
    if (currentUser.isAdmin()) return true;

    return (
      this.followerIds.includes(currentUser.id) ||
      currentUser.id === this.employeeId ||
      currentUser.id === this.assigneeId
    );
  }

  checkIsNewHire(employeeId: string) {
    return this.employeeId === employeeId;
  }

  shouldNotifyIncomplete(): boolean {
    return (
      !this.isCompleted &&
      this.incompleteSince != null &&
      getDayDiffInJST(new Date(), this.incompleteSince) >= 2 &&
      this.assigneeId != null
    );
  }

  timestampIncompleteSince(): void {
    this.incompleteSince = new Date();
  }

  static plainToInstance(init: ExcludeMethods<ContactRoom>): ContactRoom {
    return new ContactRoom({
      ...init,
      readLogs: init.readLogs.map((v) => ReadLog.plainToInstance(v)),
    });
  }
}
