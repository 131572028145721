import {
  CheckBoxQuestion,
  FileQuestion,
  FormRevision,
  OnnFormTask,
  RadioQuestion,
  TextQuestion,
  createIMockFormRevision,
  createIMockOnnFromTaskAnswer,
} from "@onn/common";
import React, { FC } from "react";

import { PageForFormTaskCore } from "./PageForFormTaskCore";

import { useLocalStorage } from "~/hooks/shared";

// プレビュー用
export const PageForFormTaskWhenPreview: FC = () => {
  type FIlE_QUESTION = FileQuestion & {
    sampleFile?: File;
    sampleFilePathForPreview: string;
  };

  type QuestionForState = TextQuestion | RadioQuestion | CheckBoxQuestion | FIlE_QUESTION;

  const { retrieveValue } = useLocalStorage();

  const localStorageData = retrieveValue<{
    onnFormTask: OnnFormTask;
    questions: QuestionForState[];
    formRevision?: FormRevision;
  }>("OnnFormTaskForPreview", {
    func: (object) => object,
    onError: () => {
      throw new Error("タスクの取得に失敗しました.useOnnTaskInPortal.retrieveValue");
    },
  });

  if (!localStorageData) {
    throw new Error("タスクの取得に失敗しました.useOnnTaskInPortal.retrieveValue");
  }

  const onnFormTask = new OnnFormTask({
    ...localStorageData.onnFormTask,
    createdAt: new Date(localStorageData.onnFormTask.createdAt),
    updatedAt: new Date(localStorageData.onnFormTask.updatedAt),
    scheduledDate: localStorageData.onnFormTask.scheduledDate
      ? new Date(localStorageData.onnFormTask.scheduledDate)
      : null,
    deadlineDate: localStorageData.onnFormTask.deadlineDate
      ? new Date(localStorageData.onnFormTask.deadlineDate)
      : null,
    firstDeliveredAt: localStorageData.onnFormTask.firstDeliveredAt
      ? new Date(localStorageData.onnFormTask.firstDeliveredAt)
      : null,
  });
  const questions = localStorageData.questions.map((question) => {
    if (question.type === "FILE") {
      return {
        ...question,
        sampleFilePath: question.sampleFilePathForPreview,
      };
    }
    return question;
  });

  const formRevision = localStorageData.formRevision
    ? new FormRevision({
        ...localStorageData.formRevision,
        createdAt: new Date(localStorageData.formRevision.createdAt),
      })
    : createIMockFormRevision();

  const draft = {
    id: "draft_preview",
    tenantId: "",
    employeeId: "",
    onnTaskFormAnswerId: "",
    answers: [],
    createdAt: new Date(),
    updatedAt: new Date(),
  };

  return (
    <PageForFormTaskCore
      onnFormTask={onnFormTask}
      questions={questions as QuestionForState[]}
      isPreview={true}
      formRevision={formRevision}
      onnFormTaskAnswer={createIMockOnnFromTaskAnswer({ answers: [] })} // プレビュー時はダミー
      draft={draft}
    />
  );
};
