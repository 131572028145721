import { Box, Menu } from "@material-ui/core";
import { OnnEventAnswerStatus } from "@onn/common";
import { isEmpty } from "lodash";
import React, { useState, FC, useCallback, useMemo, memo } from "react";

import styled from "styled-components";

import { AnswerStatusFilterSelectMenu } from "./AnswerStatusFilterSelectMenu";

import { Button, Icon, IconButton, Typography } from "~/components/uiParts";

// ====================
// constant
// ====================

const menuOptions: Array<{ value: OnnEventAnswerStatus; label: string }> = [
  {
    value: "answered",
    label: "調整済",
  },
  {
    value: "unAnswered",
    label: "未調整",
  },
];

// ====================
// props
// ====================

type Props = {
  selectedAnswerStatusTypes: OnnEventAnswerStatus[];
  onSetSelectedAnswerStatusTypes: React.Dispatch<React.SetStateAction<OnnEventAnswerStatus[]>>;
};

// ====================
// main
// ====================

export const AnswerStatusFilter: FC<Props> = memo(
  ({ selectedAnswerStatusTypes, onSetSelectedAnswerStatusTypes }) => {
    // ====================
    // state
    // ====================

    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

    // ====================
    // useMemo
    // ====================

    /**
     * 選択されている回答状況に応じてラベルを表示する
     */
    const labelText = useMemo(() => {
      if (
        selectedAnswerStatusTypes.includes("answered") &&
        selectedAnswerStatusTypes.includes("unAnswered")
      ) {
        return "全て";
      } else if (selectedAnswerStatusTypes.includes("answered")) {
        return "回答済";
      } else if (selectedAnswerStatusTypes.includes("unAnswered")) {
        return "未回答";
      } else {
        return "全て";
      }
    }, [selectedAnswerStatusTypes]);

    // ====================
    // event handler
    // ====================

    const handleClickCheckBox = useCallback(
      (value: OnnEventAnswerStatus) => {
        if (selectedAnswerStatusTypes.includes(value)) {
          onSetSelectedAnswerStatusTypes((current) => current.filter((v) => v !== value));
        } else {
          onSetSelectedAnswerStatusTypes((current) => [...current, value]);
        }
      },
      [selectedAnswerStatusTypes, onSetSelectedAnswerStatusTypes]
    );

    const handleClickClear = useCallback(() => {
      onSetSelectedAnswerStatusTypes([]);
    }, [onSetSelectedAnswerStatusTypes]);

    const handleClickOpenMenu = useCallback(
      (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setAnchorEl(e.currentTarget);
      },
      []
    );

    const handleClickCloseMenu = useCallback(() => {
      setAnchorEl(null);
    }, []);

    // ====================
    // jsx
    // ====================

    return (
      <>
        <Box position="relative">
          <Button
            onClick={handleClickOpenMenu}
            borderRadius="regular"
            variant="outlined"
            color="default"
            fullWidth
          >
            <Box width="100%" display="flex" justifyContent="space-between" alignItems="center">
              <Typography variant="body2" noWrap>
                {labelText}
              </Typography>
              <StyledIcon
                icon="filter"
                size="sm"
                color="grey"
                $isEmpty={isEmpty(selectedAnswerStatusTypes)}
              />
            </Box>
          </Button>
          {/* Buttonのhoverも効いてしまうので内包しないようにする */}
          {!isEmpty(selectedAnswerStatusTypes) && (
            <StyledIconButton icon="close" size="sm" color="grey" onClick={handleClickClear} />
          )}
        </Box>
        <Menu
          key="statusFilter"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClickCloseMenu}
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          transformOrigin={{ vertical: -8, horizontal: "right" }}
        >
          <AnswerStatusFilterSelectMenu
            menuOptions={menuOptions}
            selected={selectedAnswerStatusTypes}
            onClickCheckBox={handleClickCheckBox}
          />
        </Menu>
      </>
    );
  }
);

// ====================
// style
// ====================

const StyledIcon = styled(Icon)<{ $isEmpty: boolean }>`
  ${(props) => (props.$isEmpty ? "visibility: visible" : "visibility: hidden")}
`;

const StyledIconButton = styled(IconButton)`
  &.MuiIconButton-root {
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 0;
  }
`;
