import { Box } from "@material-ui/core";
import { OnnTask } from "@onn/common";
import React, { ChangeEvent, useCallback, useMemo, useState } from "react";
import styled from "styled-components";

import { Row } from "../_share/Row";

import {
  Checkbox,
  Chip,
  FormControlLabel,
  IconButton,
  Loading,
  TextField,
  Typography,
} from "~/components/uiParts";
import { useCurrentUser } from "~/hooks/employee";
import { useOnnTasks } from "~/hooks/onnTask";
import { useFilterObjectByPartialMatch } from "~/hooks/shared";

const useFilteredBySearchValue = (notExpiredOnnTasks: OnnTask[]) => {
  const [searchValue, setSearchValue] = useState("");

  const { filterObjectByPartialMatch } = useFilterObjectByPartialMatch();

  /**
   * タスク名の絞り込み結果
   */
  const onnTasksFilteredBySearchValue = useMemo(() => {
    return filterObjectByPartialMatch({
      objects: notExpiredOnnTasks,
      searchText: searchValue,
      getProperties: [(onnTask: OnnTask) => onnTask.title],
    });
  }, [filterObjectByPartialMatch, notExpiredOnnTasks, searchValue]);

  return { onnTasksFilteredBySearchValue, searchValue, setSearchValue };
};

export const OnnTaskTabPanel = ({
  selectedOnnTaskIds,
  addSelectedOnnTaskIds,
  removeSelectedOnnTaskIds,
}: {
  selectedOnnTaskIds: string[];
  addSelectedOnnTaskIds: (onnTaskIds: string[]) => void;
  removeSelectedOnnTaskIds: (onnTaskIds: string[]) => void;
}) => {
  const { currentUser } = useCurrentUser();
  // NOTE: 期限切れしていないタスクを取得する
  const { data: notExpiredOnnTasks = [], isLoading: isLoadingOnnTasks } = useOnnTasks({
    tenantId: currentUser.tenantId,
    isExceededDeadlineDate: false,
  });

  const { onnTasksFilteredBySearchValue, searchValue, setSearchValue } =
    useFilteredBySearchValue(notExpiredOnnTasks);

  const [isSelectAll, setIsSelectAll] = useState(false);

  const generateRowClickHandler = useCallback(
    ({ isSelected, onnTaskId }: { isSelected: boolean; onnTaskId: string }) =>
      () => {
        if (isSelected) {
          removeSelectedOnnTaskIds([onnTaskId]);
        } else {
          addSelectedOnnTaskIds([onnTaskId]);
        }
      },
    [addSelectedOnnTaskIds, removeSelectedOnnTaskIds]
  );

  return (
    <>
      <Box marginY="24px" display="flex" justifyContent="space-between">
        <Box display={"flex"} alignItems={"center"}>
          {/* すべてを選択する */}
          <StyledFormControlLabel
            control={
              <Checkbox
                checked={isSelectAll}
                onChange={() => {
                  if (isSelectAll) {
                    removeSelectedOnnTaskIds(
                      onnTasksFilteredBySearchValue.map((onnTask) => onnTask.id)
                    );
                  } else {
                    addSelectedOnnTaskIds(
                      onnTasksFilteredBySearchValue.map((onnTask) => onnTask.id)
                    );
                  }
                  setIsSelectAll(!isSelectAll);
                }}
              />
            }
            label={
              <StyledSelectAllBox display="flex">
                <Typography variant="body2" color="textSecondary">
                  すべて選択
                </Typography>
                <StyledChip
                  color="grey"
                  label={onnTasksFilteredBySearchValue.length.toString()}
                  bold
                />
              </StyledSelectAllBox>
            }
          />
        </Box>

        {/* タスク名 検索フィールド */}
        <Box width="258px">
          <StyledTextField
            fullWidth
            variant={"outlined"}
            placeholder={"タスク名"}
            value={searchValue}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setSearchValue(e.target.value);
            }}
            endAdornment={<IconButton icon="search" size="sm" />}
          />
        </Box>
      </Box>
      <StyledBox height={"212px"}>
        {isLoadingOnnTasks ? (
          <Loading fullHeight size="large" />
        ) : onnTasksFilteredBySearchValue.length === 0 ? (
          <Typography variant="caption" align="center" display="block" color="textSecondary">
            結果が見つかりませんでした
          </Typography>
        ) : (
          <div>
            {onnTasksFilteredBySearchValue.map((onnTask) => {
              const isSelected = selectedOnnTaskIds.includes(onnTask.id);
              return (
                <Row
                  key={onnTask.id}
                  isSelected={isSelected}
                  label={onnTask.title}
                  onClick={generateRowClickHandler({ isSelected, onnTaskId: onnTask.id })}
                />
              );
            })}
          </div>
        )}
      </StyledBox>
    </>
  );
};

const StyledBox = styled(Box)`
  overflow-y: scroll;
`;

const StyledTextField = styled(TextField)`
  .MuiInputBase-input {
    font-size: 14px;
    height: 24px;
    padding: 8px 16px;
  }
`;

const StyledFormControlLabel = styled(FormControlLabel)`
  &.MuiFormControlLabel-root {
    width: 100%;
    margin-left: 0px;
    gap: 8px;
  }
  .MuiCheckbox-root {
    padding: 0px;
  }
`;

const StyledSelectAllBox = styled(Box)`
  display: flex;
  gap: 16px;
`;

const StyledChip = styled(Chip)`
  cursor: pointer;
`;
