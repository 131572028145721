import { v4 } from "uuid";

import {
  contactMessagesOrderSchema,
  IContactMessagesOrder,
  Status,
  TEXT_MAX_LENGTH,
} from "./schema";

export class ContactMessagesOrder implements IContactMessagesOrder {
  private static validator = contactMessagesOrderSchema;
  static textMaxLength = TEXT_MAX_LENGTH;

  readonly id: string;
  readonly tenantId: string;
  readonly spaceId: string;
  readonly text: string;
  readonly filePaths: string[];
  readonly scheduledDate?: Date;

  readonly senderEmployeeId?: string;
  readonly receiverEmployeeIds: string[];

  readonly createdEmployeeId: string;
  readonly createdAt: Date;
  readonly updatedAt: Date;

  readonly status: Status;
  readonly sentEmployeeIds: string[];

  constructor(init: ExcludeMethods<ContactMessagesOrder>) {
    ContactMessagesOrder.validator.parse(init);

    this.id = init.id;
    this.tenantId = init.tenantId;
    this.spaceId = init.spaceId;
    this.text = init.text;
    this.filePaths = init.filePaths;
    this.scheduledDate = init.scheduledDate;
    this.senderEmployeeId = init.senderEmployeeId;
    this.receiverEmployeeIds = init.receiverEmployeeIds;
    this.createdEmployeeId = init.createdEmployeeId;
    this.createdAt = init.createdAt;
    this.updatedAt = init.updatedAt;
    this.status = init.status;
    this.sentEmployeeIds = init.sentEmployeeIds;
  }

  isInstantDelivery(): boolean {
    return this.scheduledDate === undefined;
  }

  isExceededScheduledDate(): boolean {
    if (this.isInstantDelivery() || this.scheduledDate === undefined) {
      return false;
    }

    return this.scheduledDate.getTime() < Date.now();
  }

  getScheduledDateLabel(): string {
    return this.scheduledDate ? this.scheduledDate.toLocaleString() : "即時配信";
  }

  /**
   * 送信済みのemployeeId を除いた receiverEmployeeIds
   */
  getReceiverEmployeeIdsWithoutSentEmployeeIds(): string[] {
    return this.receiverEmployeeIds.filter((id) => !new Set(this.sentEmployeeIds).has(id));
  }

  static createNew(
    params: Omit<
      ExcludeMethods<ContactMessagesOrder>,
      "id" | "status" | "sentEmployeeIds" | "createdAt" | "updatedAt"
    >
  ) {
    return new ContactMessagesOrder({
      ...params,
      id: v4(),
      status: "WAITING",
      sentEmployeeIds: [],
      createdAt: new Date(),
      updatedAt: new Date(),
    });
  }

  success({ sentEmployeeIds }: { sentEmployeeIds: string[] }) {
    // 配信成功時でも receiverEmployeeIds = sentEmployeeIds にはならないことがある。
    // 有効な employee ではない場合、配信skipされるためである。
    return new ContactMessagesOrder({
      ...this,
      status: "SENT",
      sentEmployeeIds: Array.from(new Set([...this.sentEmployeeIds, ...sentEmployeeIds])),
      updatedAt: new Date(),
    });
  }

  fail({ sentEmployeeIds }: { sentEmployeeIds: string[] }) {
    return new ContactMessagesOrder({
      ...this,
      status: "FAILED",
      sentEmployeeIds: Array.from(new Set([...this.sentEmployeeIds, ...sentEmployeeIds])),
      updatedAt: new Date(),
    });
  }

  cancel() {
    return new ContactMessagesOrder({
      ...this,
      status: "CANCELED",
      updatedAt: new Date(),
    });
  }
}
