import { z } from "zod";

import { Employee, NewGraduate, NewGraduateToDisplay, Role } from "../../../../domain/Employee";

import { onnEventDeterminedDateSchema } from "../../../../domain/OnnEvent";
import { uploadCsvRequestType } from "../_shared";

export interface APISchemaEmployees {
  "/update_recruitment_status": {
    PATCH: {
      body: {
        employeeId: string;
        recruitmentStatusId: string;
      };
      response: {
        data: {
          newGraduate: Employee;
        };
      };
    };
  };
  "/update_recruitment_statuses": {
    POST: {
      body: {
        employeeIds: string[];
        recruitmentStatusId: string;
      };
      response: {
        data: {
          count: {
            updatedNewGraduates: number;
          };
        };
      };
    };
  };
  "/get_registration_info": {
    POST: {
      body: z.infer<typeof getRegistrationInfoRequestSchema>["body"];
      response: {
        data: {
          // NOTE: get_registration_infoは管理者と新卒で共通のエンドポイントなので、新卒の場合はNewGraduateToDisplayを返す
          // TODO: ExcludeMethodsを噛ませたいが、get_registration_infoのレスポンス型を直接使っている箇所があるので、そこを一緒に修正する
          employee: Employee | NewGraduateToDisplay;
          tenantName: string;
          enTenantName?: string;
          tenantLogoUrl: string | null;
          emailAuthenticationStatus?: {
            isAuthenticated: boolean;
            types: ("email" | "google")[];
          };
          lineAuthenticationStatus?: {
            isAuthenticated: boolean;
          };
        };
      };
    };
  };
  "/send_invitation_notifications": {
    POST: {
      body: {
        ["employee-ids"]: string[];
      };
    };
  };
  "/get_employee": {
    GET: {
      query: z.infer<typeof getEmployeeRequestSchema>["query"];
      response: {
        data: Employee | null;
      };
    };
  };
  "/get_new_graduate": {
    GET: {
      query: z.infer<typeof getNewGraduateRequestSchema>["query"];
      response: {
        data: ExcludeMethods<NewGraduateToDisplay> | null;
      };
    };
  };
  "/get_employees": {
    POST: {
      body: z.infer<typeof getEmployeesRequestSchema>["body"];
      response: {
        data: Employee[];
      };
    };
  };
  "/get_new_graduates": {
    POST: {
      body: z.infer<typeof getNewGraduatesRequestSchema>["body"];
      response: {
        data: ExcludeMethods<NewGraduateToDisplay>[];
      };
    };
  };
  "/get_employees_by_uid": {
    GET: {
      query: z.infer<typeof getEmployeesByUidRequestSchema>["query"];
      response: {
        data: ExcludeMethods<Employee | NewGraduateToDisplay>[];
      };
    };
  };
  "/retrieve_registration_info_by_liff": {
    POST: {
      body: {
        liffAccessToken: string;
        liffId: string;
      };
      response: {
        data: { tenantName: string; userAccount: Employee };
      };
    };
  };
  "/signup": {
    POST: {
      body: z.infer<typeof signupSchema>["body"];
      response: {
        data: { userAccount: Employee };
      };
    };
  };
  "/signup_by_line": {
    POST: {
      body: z.infer<typeof signupByLineSchema>["body"];
      response: {
        data: { userAccount: Employee; customToken: string };
      };
    };
  };
  "/get_line_access_token_by_authorize_code": {
    POST: {
      body: z.infer<typeof getLineAccessTokenByAuthorizeCodeSchema>["body"];
      response: {
        data: { accessToken: string };
      };
    };
  };
  "/issue_custom_token_for_line": {
    POST: {
      body: z.infer<typeof issueCustomTokenForLineSchema>["body"];
      response: {
        data: { customToken: string };
      };
    };
  };
  // NOTE: このエンドポイントはLINE認証専用のエンドポイントです。現時点で複数テナントに1つの認証でログインすることができるのはLINE認証のみです。
  "/authorize_for_individual": {
    POST: {
      body: z.infer<typeof authorizeForIndividualSchema>["body"];
      response: {
        data: { userAccount: Employee };
      };
    };
  };
  // NOTE: このエンドポイントはLINE認証専用のエンドポイントです。現時点で複数テナントに1つの認証でログインすることができるのはLINE認証のみです。
  "/authorize_for_registration_invitation_link": {
    POST: {
      body: z.infer<typeof authorizeForRegistrationInvitationLinkSchema>["body"];
      response: {
        data: { userAccount: Employee };
      };
    };
  };
  "/update_current_employee": {
    PATCH: {
      body: z.infer<typeof updateCurrentEmployeeRequestSchema>["body"];
      response: {
        data: {
          employee: ExcludeMethods<Employee>;
        };
      };
    };
  };
  "/update_unregistered_employee_invited_at": {
    PATCH: {
      body: z.infer<typeof updateInvitedAtUnregisteredEmployeeRequestSchema>["body"];
      response: {
        data: {
          employee: ExcludeMethods<Employee>;
        };
      };
    };
  };
  "/update_last_refresh_time": {
    PATCH: {
      body: z.infer<typeof updateLastRefreshTimeRequestSchema>["body"];
      response: {
        data: undefined;
      };
    };
  };
  "/update_employee": {
    PATCH: {
      body: { id: string; updates: Partial<ExcludeMethods<Employee>> };
      response: {
        data: undefined;
      };
    };
  };
  "/search_new_graduates": {
    POST: {
      body: z.infer<typeof searchNewGraduatesBodySchema>["body"];
      response: {
        newGraduateIds: string[];
      };
    };
  };
  "/get_new_graduates_csv": {
    POST: {
      body: z.infer<typeof getNewGraduatesCSVBody>;
      // response: CSVファイル
    };
  };
  "/create_new_graduates_with_csv": {
    POST: {
      body: z.infer<typeof createNewGraduatesWithCsvSchema>["body"];
      response:
        | {
            isOk: true;
            createdNewGraduates: NewGraduate[];
          }
        | {
            isOk: false;
            errorMessages: string[];
          };
    };
  };

  "/change_authentication_by_link": {
    POST: {
      body: z.infer<typeof changeAuthenticationByChangeAuthenticationTypeLink>["body"];
      response: {
        data:
          | {
              isSuccess: true;
              customToken: string;
              isFollowedLineOfficialAccount: boolean;
            }
          | {
              isSuccess: false;
              type:
                | "NotFoundChangeAuthenticationTypeLink"
                | "ChangeAuthenticationTypeLinkWasUsed"
                | "ChangeAuthenticationTypeLinkIsExpired"
                | "NotFoundLineUser"
                | "AlreadyExistAuthInSameTenant";
            };
      };
    };
  };
  ["/list_tagged_employees"]: {
    GET: {
      query: z.infer<typeof listTaggedEmployeesQuerySchema>["query"];
      response: {
        data: {
          employees: ExcludeMethods<Employee>[];
        };
        meta: {
          employeeIdToTaggedAtMap: {
            [employeeId: string]: string;
          };
        };
      };
    };
  };
}

export const getRegistrationInfoRequestSchema = z.object({
  body: z.object({
    "invitation-token": z.string(),
    "line-access-token": z.string().optional(),
  }),
});

export const getEmployeeRequestSchema = z.object({
  query: z.object({
    id: z.string(),
  }),
});
export const getNewGraduateRequestSchema = z.object({
  query: z.object({
    id: z.string(),
  }),
});
export const getEmployeesRequestSchema = z.object({
  body: z.object({
    ids: z.array(z.string()).optional(),
    includeDeleted: z.boolean(),
  }),
});

export const getEmployeesByUidRequestSchema = z.object({
  query: z.object({
    includeDeleted: z.boolean().optional(),
  }),
});

export const getNewGraduatesRequestSchema = z.object({
  body: z.object({
    ids: z.array(z.string()).optional(),
    includeDeleted: z.boolean(),
  }),
});

export const authorizeForIndividualSchema = z.object({
  body: z.object({
    invitationToken: z.string(),
    firstName: z.string().optional(),
    lastName: z.string().optional(),
    lineAccessToken: z.string(),
  }),
});

export const authorizeForRegistrationInvitationLinkSchema = z.object({
  body: z.object({
    lineAccessToken: z.string().optional(),
    registrationInvitationLinkId: z.string(),
    firstName: z.string(),
    lastName: z.string(),
    email: z.string(),
  }),
});

export const getLineAccessTokenByAuthorizeCodeSchema = z.object({
  body: z.object({
    authorizeCode: z.string(),
    tenantId: z.string().optional(),
  }),
});

export const issueCustomTokenForLineSchema = z.object({
  body: z.object({
    accessToken: z.string(),
  }),
});

export const signupSchema = z.object({
  body: z.object({
    firstName: z.string(),
    lastName: z.string(),
    password: z.string(),
    invitationToken: z.string(),
  }),
});

export const signupByLineSchema = z.object({
  body: z.object({
    firstName: z.string(),
    lastName: z.string(),
    lineAccessToken: z.string(),
    invitationToken: z.string(),
  }),
});

export const updateRoleRequestSchema = z.object({
  body: z.object({
    employeeId: z.string(),
    role: z.nativeEnum(Role),
    assignedAsNewcomer: z.boolean().optional(),
  }),
});

export const updateCurrentEmployeeRequestSchema = z.object({
  body: z.object({
    lastActiveTime: z.date().optional(),
    firstName: z.string().optional(),
    lastName: z.string().optional(),
    email: z.string().optional(),
    profileIconImageUrl: z.string().optional(),
  }),
});

export const updateInvitedAtUnregisteredEmployeeRequestSchema = z.object({
  body: z.object({
    invitationToken: z.string(),
  }),
});

export const updateLastRefreshTimeRequestSchema = z.object({
  body: z.object({
    lastRefreshTime: z.date(),
  }),
});

export const idConditionSchema = z.object({
  target: z.literal("id"),
  searchString: z.string().trim().min(1), // 候補者IDを区切り文字(半角・全角スペース, ",", "、")で区切ったもの
});
export const nameConditionSchema = z.object({
  target: z.literal("name"),
  searchString: z.string().trim().min(1), // 候補者氏名を区切り文字(半角・全角スペース, ",", "、")で区切ったもの (カナ・漢字可)
});
export const onnEventConditionSchema = z.object({
  target: z.literal("onnEvent"),
  eventId: z.string(),
  attendanceStatuses: z.array(onnEventDeterminedDateSchema.shape.attendanceStatus).optional(),
  candidateDateIds: z.array(z.string()).optional(),
  eventFormatTypes: z.array(z.enum(["online", "offline"])).optional(),
});
export const tagConditionSchema = z.object({
  target: z.literal("tag"),
  tagIds: z.array(z.string()).min(1),
});
export const searchNewGraduateConditionSchema = z.union([
  idConditionSchema,
  nameConditionSchema,
  onnEventConditionSchema,
  tagConditionSchema,
]);
export const searchNewGraduatesBodySchema = z.object({
  body: z.object({
    type: z.enum(["AND", "OR"]),
    conditions: z.array(searchNewGraduateConditionSchema),
  }),
});

export const csvConditionFields = [
  "name",
  "kana_name",
  "recruitment_status",
  "assignee",
  "admin_memo",
  "tags",
  "gender",
  "birthday",
  "mail_address",
  "phone_number",
  "graduation_year_and_month",
  "affiliation",
  "address/current",
  "address/hometown",
  "id/unique_id",
  "id/external_id",
  "recruitment_process_records",
] as const;
export type CSVConditionField = (typeof csvConditionFields)[number];

// nonempty()を使ったスキーマから方を導出すると、空配列を禁止する厳しすぎる型になる。
// 型導出用とバリデーションスキーマで分けている
const getNewGraduatesCSVBody = z.object({
  employee_ids: z.array(z.string()),
  condition_fields: z.array(z.enum(csvConditionFields)),
});
export const getNewGraduatesCSVSchema = z.object({
  body: z.object({
    employee_ids: getNewGraduatesCSVBody.shape.employee_ids.nonempty(),
    /**
     * 出力するCSVの項目条件を指定する
     * - 配列の順序で、CSVの各列の順序を指定する
     */
    condition_fields: getNewGraduatesCSVBody.shape.condition_fields.nonempty(),
  }),
});
export const createNewGraduatesWithCsvSchema = z.object({
  body: uploadCsvRequestType,
});

export const changeAuthenticationByChangeAuthenticationTypeLink = z.object({
  body: z.object({
    changeAuthenticationTypeLinkId: z.string(),
    employeeId: z.string(),
    lineAccessToken: z.string(),
  }),
});
export const listTaggedEmployeesQuerySchema = z.object({
  query: z.object({
    employeeTagId: z.string(),
  }),
});
