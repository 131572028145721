import { Box } from "@material-ui/core";
import { Employee, Transaction, MemoTransaction } from "@onn/common";
import { isAfter } from "date-fns";
import React, { FC, ComponentProps, useMemo } from "react";
import styled from "styled-components";

import { ActivityListItemMemoForm, ActivityListItemMemoFormProps } from "./ActiviListItemMemoForm";
import { ActivityListItem } from "./ActivityListItem";

import { CommentList } from "~/components/domains/comments";
import { Loading, Typography, NotFoundPaper } from "~/components/uiParts";

type CommentListProps = ComponentProps<typeof CommentList>;
type ActivityListItemProps = ComponentProps<typeof ActivityListItem>;

type SuggestedMentionUsers = ActivityListItemProps["suggestedMentionUsers"];
type Comments = ActivityListItemProps["comments"];
type TransactionCommentsMap = { [transactionId: string]: Comments };

const returnTimeForSort = (t: Transaction | MemoTransaction) => {
  // ADD_MEMOはcreatedAtでソートする
  if (t instanceof MemoTransaction) return t.createdAt;

  return new Date(t.sendAt.toString());
};

type Props = {
  transactions: Array<Transaction | MemoTransaction> | undefined;
  isLoadingTransactions: boolean;
  contentId: string | null;
  employee: Employee;
  suggestedMentionUsers: SuggestedMentionUsers;
  transactionCommentsLoading: boolean;
  currentUserId: string;
  memoAttachedFilesMap: ActivityListItemProps["memoAttachedFilesMap"];
  memoAttachedFilesLoading: boolean;
  transactionCommentsMap: TransactionCommentsMap;
  commentFocused: boolean;
  onSubmitMemo?: ActivityListItemMemoFormProps["onSubmitMemo"];
  onDropFilesError?: ActivityListItemMemoFormProps["onDropFilesError"];
  onDownloadMemoAttachedFile: ActivityListItemProps["onDownloadMemoAttachedFile"];
  onDeleteMemoAttachedFile: ActivityListItemProps["onDeleteMemoAttachedFile"];
} & Pick<CommentListProps, "loadTransactionComments">;

export const ActivityTab: FC<Props> = ({
  transactions,
  isLoadingTransactions,
  contentId,
  employee,
  suggestedMentionUsers,
  transactionCommentsLoading,
  currentUserId,
  transactionCommentsMap,
  commentFocused,
  memoAttachedFilesMap,
  memoAttachedFilesLoading,
  onSubmitMemo,
  onDropFilesError,
  loadTransactionComments,
  onDownloadMemoAttachedFile,
  onDeleteMemoAttachedFile,
}) => {
  const sortedTransactionsAndMessageTasks = useMemo(() => {
    const getDate = (transactionOrMessageTask: NonNullable<typeof transactions>[number]) => {
      return returnTimeForSort(transactionOrMessageTask);
    };
    return [...(transactions ?? [])].sort((a, b) => {
      const dateA = getDate(a);
      const dateB = getDate(b);

      if (!dateA) return -1;
      if (!dateB) return 1;

      return isAfter(dateA, dateB) ? -1 : 1;
    });
  }, [transactions]);

  return (
    <Box pt={5} pb={5}>
      {transactions === undefined && isLoadingTransactions ? (
        <Loading size="small" />
      ) : (
        <>
          {sortedTransactionsAndMessageTasks.length === 0 ? (
            <NotFoundPaper text="アクティビティはまだ登録されていません。" />
          ) : (
            <StyledList>
              <Box pb={3}>
                <Typography variant="body1">最新アクティビティ</Typography>
              </Box>
              {onSubmitMemo && onDropFilesError && (
                <ActivityListItemMemoForm
                  onSubmitMemo={onSubmitMemo}
                  onDropFilesError={onDropFilesError}
                />
              )}
              {sortedTransactionsAndMessageTasks.map((sortedTransactionsAndMessageTask) => {
                return (
                  <ActivityListItem
                    key={sortedTransactionsAndMessageTask.id}
                    transaction={sortedTransactionsAndMessageTask}
                    isScrollTarget={contentId === sortedTransactionsAndMessageTask.id}
                    employee={employee}
                    currentUserId={currentUserId}
                    comments={transactionCommentsMap[sortedTransactionsAndMessageTask.id] ?? []}
                    commentDisabled={transactionCommentsLoading}
                    commentFocused={commentFocused}
                    suggestedMentionUsers={suggestedMentionUsers}
                    memoAttachedFilesMap={memoAttachedFilesMap}
                    memoAttachedFilesLoading={memoAttachedFilesLoading}
                    onDownloadMemoAttachedFile={onDownloadMemoAttachedFile}
                    onDeleteMemoAttachedFile={onDeleteMemoAttachedFile}
                    loadTransactionComments={loadTransactionComments}
                    isActive={sortedTransactionsAndMessageTask.id === contentId}
                  />
                );
              })}
            </StyledList>
          )}
        </>
      )}
    </Box>
  );
};

const StyledList = styled.ul`
  margin: 0px 0px 48px;
  padding: 0px;
`;
