import { Box } from "@material-ui/core";
import { OnnEventDeterminedDate } from "@onn/common";
import React, { FC, useCallback } from "react";
import styled from "styled-components";

import { Button, Icon, Linkify, Typography } from "~/components/uiParts";
import { colors } from "~/config/theme";
import { useClipboard } from "~/hooks/shared";

type Props = {
  onnEventDeterminedDate: OnnEventDeterminedDate;
};

export const EventFormatInfoSection: FC<Props> = ({ onnEventDeterminedDate }) => {
  const { handleCopy } = useClipboard();

  const handleClickCopy = useCallback(() => {
    if (
      onnEventDeterminedDate.eventFormat?.type === "online" &&
      onnEventDeterminedDate.eventFormat?.url
    ) {
      handleCopy(onnEventDeterminedDate.eventFormat?.url, "面談用リンク");
    }
  }, [handleCopy, onnEventDeterminedDate.eventFormat]);

  return (
    <Box>
      <Typography variant="body1" bold>
        開催方法
      </Typography>
      <Typography variant="body1" style={{ marginTop: 24 }}>
        {onnEventDeterminedDate.eventFormat?.type === "online" ? "オンライン" : "オフライン"}
      </Typography>
      <Box mt={1} p={2} style={{ background: colors.grey[50], borderRadius: 4 }}>
        <Typography variant="body2">
          <Linkify>{onnEventDeterminedDate.eventFormat?.description}</Linkify>
        </Typography>
        {onnEventDeterminedDate.eventFormat?.type === "online" && (
          <>
            {onnEventDeterminedDate.eventFormat?.url ? (
              <Box mt={3} display="flex" flexDirection="column" gridGap="16px">
                {/* TODO: 直接アクセスされるのではなくModalを開くようにする https://app.clickup.com/t/860rzpg10 */}
                <StyledAnchor href={onnEventDeterminedDate.eventFormat?.url} target="_blank">
                  <Button fullWidth borderRadius="circle" variant="contained" color="primary">
                    オンライン面談・面接を開始する
                  </Button>
                </StyledAnchor>
                <Box
                  display="flex"
                  gridGap="4px"
                  alignItems="center"
                  mx="auto"
                  onClick={handleClickCopy}
                  style={{ cursor: "pointer" }}
                >
                  <Typography variant="body2" bold color="primary">
                    面談・面接用のリンクをコピー
                  </Typography>
                  <Icon icon="copy" size="sm" color="primary" />
                </Box>
              </Box>
            ) : (
              <Box
                mt={3}
                py={1}
                style={{
                  textAlign: "center",
                  background: "white",
                  border: `1px solid ${colors.grey[200]}`,
                  borderRadius: 8,
                }}
              >
                <Typography variant="body2" bold color="textSecondary">
                  {`当日までに設定された\n面談・面接用のリンクが表示されます`}
                </Typography>
              </Box>
            )}
          </>
        )}
      </Box>
    </Box>
  );
};

const StyledAnchor = styled.a`
  text-decoration: none;
  color: inherit;
`;
