import { Box } from "@material-ui/core";
import { OnnEvent } from "@onn/common";

import React, { FC, useMemo } from "react";

import { TableRowProps } from "react-virtualized";

import { ThreeDotMenu } from "./ThreeDotMenuCell";

import { Loading, Typography } from "~/components/uiParts";
import { TableRowWrapper } from "~/components/uiParts/VirtualizedTable";
import { TableRow } from "~/components/uiParts/VirtualizedTable/TableRow";
import { VirtualizedTableV2 } from "~/components/uiParts/VirtualizedTable/VirtualizedTableV2";
import { useOnnEventAnswers } from "~/hooks/onnEvent/answerResult/useOnnEventAnswers";
import { useOnnEventAnswersForDisplay } from "~/hooks/onnEventAnswer/useOnnEventAnswersForDisplay";
import { useOnnEventSlotDatesForDisplay } from "~/hooks/onnEventSlotDates/useOnnEventSlotDatesForDisplay";

type Props = {
  interviewEvents: OnnEvent[];
  isLoading?: boolean;
};

export const NewInterviewsListTable: FC<Props> = ({ interviewEvents, isLoading }) => {
  return (
    <VirtualizedTableV2<OnnEvent>
      rowHeight={112}
      isLoading={isLoading}
      widthOptions={widthOptions}
      headers={generateHeaders(interviewEvents)}
      rows={interviewEvents}
      rowRenderer={(props) => <RowRenderer {...{ ...props }} />}
    />
  );
};

const RowRenderer: React.FC<Omit<TableRowProps, "rowData"> & { rowData: OnnEvent }> = ({
  index,
  style,
  rowData: onnEvent,
}) => {
  const contents = [
    <TitleCell key={onnEvent.id} onnEvent={onnEvent} />,
    onnEvent.type === "new_interview" ? (
      <PublicSlotsCountCell key={onnEvent.id} onnEventId={onnEvent.id} />
    ) : (
      <NumberOfDistributionCell key={onnEvent.id} onnEventId={onnEvent.id} />
    ),
    onnEvent.type === "new_interview" ? (
      <DeterminedSlotsCountToInterviewInTheFutureCell key={onnEvent.id} onnEventId={onnEvent.id} />
    ) : (
      <NumberOfResponses key={onnEvent.id} onnEventId={onnEvent.id} />
    ),
    <ThreeDotMenu key={onnEvent.id} onnEvent={onnEvent} />,
  ];
  return (
    <TableRowWrapper key={onnEvent.id} index={index} {...style}>
      <TableRow
        row={{
          contents,
          to: `/events/${onnEvent.id}`,
          isTargetBlank: false,
        }}
        widthOptions={widthOptions}
        hover={true}
      />
    </TableRowWrapper>
  );
};

// 公開予約枠を表示するセル
const PublicSlotsCountCell = ({ onnEventId }: { onnEventId: string }) => {
  const { data: onnEventSlotDatesForDisplay = [], isLoading: isLoadingFetchingSlots } =
    useOnnEventSlotDatesForDisplay(onnEventId);

  const publicSlotsCount = useMemo(() => {
    return onnEventSlotDatesForDisplay.filter((slot) => slot.isPublishedAndNotDone()).length;
  }, [onnEventSlotDatesForDisplay]);

  return isLoadingFetchingSlots ? (
    <Loading key={onnEventId} size="small" />
  ) : (
    <Box key={onnEventId}>{publicSlotsCount}</Box>
  );
};

// 面談予定を表示するセル
const DeterminedSlotsCountToInterviewInTheFutureCell = ({ onnEventId }: { onnEventId: string }) => {
  const { data: onnEventAnswersForDisplay = [], isLoading: isLoadingFetchingAnswers } =
    useOnnEventAnswersForDisplay(onnEventId);

  const determinedSlotsCountToInterviewInTheFuture = useMemo(() => {
    return onnEventAnswersForDisplay.filter((answer) =>
      answer.selectedOnnEventSlotDate ? !answer.selectedOnnEventSlotDate.isDone() : false
    ).length;
  }, [onnEventAnswersForDisplay]);

  return isLoadingFetchingAnswers ? (
    <Loading key={onnEventId} size="small" />
  ) : (
    <Box key={onnEventId}>{determinedSlotsCountToInterviewInTheFuture}</Box>
  );
};

// 配信数を表示するセル
const NumberOfDistributionCell = ({ onnEventId }: { onnEventId: string }) => {
  const { data: onnEventsAnswers, isLoading } = useOnnEventAnswers({ onnEventId });
  if (isLoading) return <Loading size="small" />;
  if (onnEventsAnswers && onnEventsAnswers.length !== 0) return <>{onnEventsAnswers.length}</>;
  return <>-</>;
};

// 回答数を表示するセル
const NumberOfResponses = ({ onnEventId }: { onnEventId: string }) => {
  const { data: onnEventsAnswers, isLoading } = useOnnEventAnswers({ onnEventId });
  if (isLoading) return <Loading size="small" fullHeight />;
  if (onnEventsAnswers && onnEventsAnswers.length !== 0)
    return <>{onnEventsAnswers.filter((v) => v.isAnswered()).length}</>;
  return <>-</>;
};

// タイトルを表示するセル
const TitleCell = ({ onnEvent }: { onnEvent: OnnEvent }) => {
  return (
    <Box key={onnEvent.id}>
      <Typography variant="body1" color="textPrimary" bold>
        {onnEvent.title}
      </Typography>
    </Box>
  );
};

const widthOptions = ["76%", "8%", "8%", "8%"];

const generateHeaders = (interviewEvents: OnnEvent[]) => {
  // NOTE:旧面談イベントと新面談イベントが混在することはないため、全てのイベントが旧面談イベントの場合は、配信数と回答者数を表示する
  const isShowOldInterviewLabel = (() => {
    return (
      interviewEvents.every((event) => event.type === "interview") && interviewEvents.length > 0
    );
  })();

  const headers = [
    {
      text: "タイトル",
    },
    {
      text: isShowOldInterviewLabel ? "配信数" : "公開予約枠",
    },
    {
      text: isShowOldInterviewLabel ? "回答者数" : "面談予定",
    },
    {
      text: "",
    },
  ];

  return headers;
};
