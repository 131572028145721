import { Announcement } from "@onn/common";
import { useCallback, useEffect } from "react";

import { useNavigate, useSearchParams } from "react-router-dom";

import { useInputs } from "../../shared/useInputs";

import { useGenerateFilePath } from "~/hooks/announcement";
import { useUpdateAnnouncement } from "~/hooks/announcement/useUpdateAnnouncement";

import { useCurrentUser } from "~/hooks/employee";
import { useOpenPortalAnnouncementPreview } from "~/hooks/openPortalPreview/useOpenPortalAnnouncementPreview";
import { useSnackbar } from "~/hooks/shared";
import { useCurrentSpace } from "~/hooks/space/useCurrentSpace";

export const usePage = ({ announcement }: { announcement: Announcement }) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const fromPage = searchParams.get("from_page");
  const { enqueueSnackbar } = useSnackbar();
  const { currentUser } = useCurrentUser();
  const { switchSpaceTemporary, currentSpace } = useCurrentSpace();
  const { execUpdateAnnouncement, isLoading: isUpdatingAnnouncement } = useUpdateAnnouncement();
  const { titleInput, contentInput, filesInput } = useInputs({
    announcement,
    tenantId: currentUser.tenantId,
  });
  const { openPortalAnnouncementPreview } = useOpenPortalAnnouncementPreview();
  const { generateFilePath } = useGenerateFilePath();

  useEffect(() => {
    if (!announcement) return;
    switchSpaceTemporary(announcement.spaceId);
  }, [announcement, switchSpaceTemporary]);

  const onClickPreview = useCallback(() => {
    let announcementForPreview: Announcement | undefined;
    try {
      announcementForPreview = new Announcement({
        id: announcement.id,
        creatorId: currentUser.id,
        title: titleInput.title,
        content: contentInput.content,
        tenantId: currentUser.tenantId,
        spaceId: currentSpace.id,
        filePaths: filesInput.files.map((file) =>
          generateFilePath({ announcementId: announcement.id, fileName: file.name })
        ),
      });
    } catch (e) {
      enqueueSnackbar("入力されている値ではプレビューを開けません", { variant: "error" });
      return;
    }

    openPortalAnnouncementPreview(announcementForPreview);
  }, [
    announcement.id,
    contentInput.content,
    currentSpace.id,
    currentUser.id,
    currentUser.tenantId,
    enqueueSnackbar,
    filesInput.files,
    generateFilePath,
    openPortalAnnouncementPreview,
    titleInput.title,
  ]);

  const onClickSaveAndToNext = useCallback(async () => {
    try {
      await execUpdateAnnouncement({
        announcementId: announcement.id,
        title: titleInput.title,
        content: contentInput.content,
        filePaths: filesInput.files.map((file) =>
          generateFilePath({ announcementId: announcement.id, fileName: file.name })
        ),
      });

      if (fromPage === "detail") {
        navigate(`/announcements/${announcement.id}?from_page=edit`);
      } else if (fromPage === "delivery_setting") {
        navigate(`/announcements/${announcement.id}/delivery_setting?from_page=edit`);
      } else {
        navigate("/tools#announcements");
      }
      enqueueSnackbar("お知らせを更新しました", { variant: "success" });
    } catch (e) {
      enqueueSnackbar("お知らせの更新に失敗しました", { variant: "error" });
    }
  }, [
    announcement.id,
    contentInput.content,
    enqueueSnackbar,
    execUpdateAnnouncement,
    filesInput.files,
    fromPage,
    generateFilePath,
    navigate,
    titleInput.title,
  ]);

  const onClickCancel = useCallback(() => {
    if (fromPage === "detail") {
      navigate(`/announcements/${announcement.id}?from_page=edit`);
      return;
    }

    navigate("/tools#announcements");
  }, [announcement.id, navigate, fromPage]);

  const isSaving = isUpdatingAnnouncement;
  const isDisabledSaveButton =
    isSaving || titleInput.titleInputError.error || contentInput.contentInputError.error;

  return {
    isDisabledSaveButton,
    onClickCancel,
    onClickSaveAndToNext,
    onClickPreview,
    isSaving,
    titleInput,
    contentInput,
    filesInput,
  };
};
