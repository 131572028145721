import liff from "@line/liff";
import { convertBytesToSize } from "@onn/common";
import React, { FC } from "react";

import { FileAttachedButtonUI } from "./FileAttachedButton";

import { Loading } from "~/components/uiParts";

import { useMetaDataByUrls } from "~/hooks/file";
import { useFileViewer } from "~/hooks/shared";

export const FileAttachedButtonForStorage: FC<{ filePath?: string; signedUrl?: string }> = ({
  filePath,
  signedUrl,
}) => {
  const { data: fileMetaData, isLoading } = useMetaDataByUrls(filePath ? [filePath] : []);
  const { handleDownloadFile } = useFileViewer();

  if (isLoading) return <Loading size="large" fullHeight />;
  if (!fileMetaData) return <></>;

  const fileMeta = fileMetaData[0] as (typeof fileMetaData)[number];
  const ext = fileMeta.name.split(".")?.pop() || "";

  const type = (() => {
    if (["png", "jpg", "jpeg", "gif"].includes(ext)) return "openNewTab";
    if (ext === "pdf") return "openNewTab";
    return "download";
  })();

  const onClick = () => {
    if (type !== "download") {
      window.open(fileMeta.url, "_blank");
      return;
    }

    if (liff.isInClient() && signedUrl) {
      liff.openWindow({
        url: signedUrl,
        external: true,
      });
      return;
    }

    handleDownloadFile(fileMeta.url, [fileMeta]);
    return;
  };

  return (
    <FileAttachedButtonUI
      fileName={fileMeta.name}
      type={type}
      onClick={onClick}
      fileSizeLabel={convertBytesToSize(fileMeta.size)}
    />
  );
};
