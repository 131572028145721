import { Box } from "@material-ui/core";
import { isEmpty } from "lodash";
import React, { FC } from "react";

import { widthPxMap } from "../const";

import { CellItem, StyleProps } from "./CellItem";

import { Icon, TooltipWhenTextTruncated, Typography } from "~/components/uiParts";
import { useFileViewer } from "~/hooks/shared";
import { FileAPIAdapter } from "~/infrastructure/usecases/file/fileAPIAdapter";
import { captureException } from "~/util";

export const RelatedFilesCell: FC<
  {
    filePaths?: string[];
  } & StyleProps
> = ({ filePaths, ...styleProps }) => {
  const { setFiles, setPreviewFileIndex } = useFileViewer();
  const files = filePaths?.map((path) => ({
    path,
    name: path.split("/").pop() || "",
  }));

  return (
    <CellItem
      style={{ ...styleProps.style }}
      isColumnDirection={true}
      isLastColumn={styleProps.isLastColumn}
    >
      <Box my="10px" display="grid" gridGap="4px">
        {!isEmpty(files) ? (
          (files || []).map((file, index) => (
            <Box
              key={index}
              px="10px"
              height={"24px"}
              width={widthPxMap["relatedFiles"] + "px"}
              onClick={async () => {
                const fileUrl = await fileAPIAdapter.fetchUrl({ path: file.path });
                if (fileUrl) {
                  setFiles([{ name: file.name, url: fileUrl, size: 100 }]);
                  setPreviewFileIndex(0);
                } else {
                  captureException({
                    error: new Error("ファイルが取得できませんでした"),
                    tags: { type: "RelatedFilesCell" },
                    extras: { filePath: file.path },
                  });
                }
              }}
              style={{ cursor: "pointer" }}
            >
              <TooltipWhenTextTruncated text={file.name}>
                {(ref) => (
                  <Typography variant="body2" color="primary" noWrap ref={ref}>
                    {file.name}
                  </Typography>
                )}
              </TooltipWhenTextTruncated>
            </Box>
          ))
        ) : (
          <Icon color="lightGrey" size="xl" icon="horizontalBar" />
        )}
      </Box>
    </CellItem>
  );
};

const fileAPIAdapter = new FileAPIAdapter({ bucketType: "private" });
