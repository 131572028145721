import { useMemo } from "react";

import { useAllNewcomers } from "~/hooks/employee";
import { useDeterminedDate } from "~/hooks/onnEvent/determinedDate/useDeterminedDate";

/**
 * 選択可能な候補者を取得する
 */
export const useSelectableNewGraduates = ({
  onnEventId,
  selectedEmployeeId,
}: {
  onnEventId: string;
  selectedEmployeeId?: string;
}) => {
  const { data: allNewComers, isLoading: isLoadingAllNewcomers } = useAllNewcomers();

  const { data: determinedDates, isLoading: isLoadingDeterminedDate } = useDeterminedDate({
    onnEventId,
  });

  const selectableNewGraduates = useMemo(() => {
    const alreadyAnsweredEmployeeIds = determinedDates?.map((v) => v.employeeId) || [];
    if (!allNewComers) return [];
    return allNewComers.filter((newGraduate) => {
      if (newGraduate.isNotRegisteredAndInvited()) return false;

      if (newGraduate.isRejectedOrWithdrew()) return false;
      if (newGraduate.id === selectedEmployeeId) return true;
      if (alreadyAnsweredEmployeeIds.includes(newGraduate.id)) return false;

      return true;
    });
  }, [allNewComers, determinedDates, selectedEmployeeId]);

  const selectableNewGraduatesMap = new Map(
    selectableNewGraduates.map((employee) => [employee.id, employee])
  );

  const isLoading = isLoadingAllNewcomers || isLoadingDeterminedDate;
  return {
    selectableNewGraduates,
    selectableNewGraduatesMap,
    isLoading,
  };
};
