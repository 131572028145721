import { Table, TableBody, TableCell, TableRow } from "@material-ui/core";
import { EmployeeInformation, NewGraduateToDisplay } from "@onn/common";
import React, { FC } from "react";
import styled from "styled-components";

import { InformationValueViewer } from "./parts/InformationValueViewer";
import { ViewerAndEditorManagerRenderer } from "./parts/ViewerAndEditorManagerRenderer";

import { Typography } from "~/components/uiParts";
import theme from "~/config/theme";
import { useClipboard } from "~/hooks/shared";

type Props = {
  newGraduate: NewGraduateToDisplay;
  employeeInformation?: EmployeeInformation | null;
};

export const EmployeeInformationTable: FC<Props> = ({ newGraduate, employeeInformation }) => {
  const { handleCopy } = useClipboard();

  return (
    <StyledTable>
      <TableBody>
        <TableRow>
          <StyledTableCellLeft colSpan={2} align="center" width={320}>
            <Typography variant="body2">氏名</Typography>
          </StyledTableCellLeft>
          <StyledTableCellRight>
            <ViewerAndEditorManagerRenderer
              employeeId={newGraduate.id}
              type="name"
              text="氏名"
              data={newGraduate}
            />
          </StyledTableCellRight>
        </TableRow>
        <TableRow>
          <StyledTableCellLeft colSpan={2} align="center">
            <Typography variant="body2">フリガナ</Typography>
          </StyledTableCellLeft>
          <StyledTableCellRight>
            <ViewerAndEditorManagerRenderer
              employeeId={newGraduate.id}
              type="kanaName"
              text="フリガナ"
              data={employeeInformation?.value.kanaName}
            />
          </StyledTableCellRight>
        </TableRow>
        <TableRow>
          <StyledTableCellLeft colSpan={2} align="center">
            <Typography variant="body2">性別</Typography>
          </StyledTableCellLeft>
          <StyledTableCellRight>
            <ViewerAndEditorManagerRenderer
              employeeId={newGraduate.id}
              type="gender"
              text="性別"
              data={employeeInformation?.value.gender}
            />
          </StyledTableCellRight>
        </TableRow>
        <TableRow>
          <StyledTableCellLeft colSpan={2} align="center">
            <Typography variant="body2">生年月日(年齢)</Typography>
          </StyledTableCellLeft>
          <StyledTableCellRight>
            <ViewerAndEditorManagerRenderer
              employeeId={newGraduate.id}
              type="dateOfBirth"
              text="生年月日"
              data={employeeInformation?.value.dateOfBirth}
            />
          </StyledTableCellRight>
        </TableRow>
        <TableRow>
          <StyledTableCellLeft colSpan={2} align="center" width={320}>
            <Typography variant="body2">メールアドレス</Typography>
          </StyledTableCellLeft>
          <StyledTableCellRight>
            {newGraduate.email ? (
              <InformationValueViewer
                label={<Typography variant="body2">{newGraduate.email}</Typography>}
                onClickCopy={() => handleCopy(newGraduate.email, "メールアドレス")}
              />
            ) : (
              <Typography variant="body2" style={{ color: theme.palette.grey[200] }}>
                未登録
              </Typography>
            )}
          </StyledTableCellRight>
        </TableRow>
        <TableRow>
          <StyledTableCellLeft colSpan={2} align="center">
            <Typography variant="body2">電話番号</Typography>
          </StyledTableCellLeft>
          <StyledTableCellRight>
            <ViewerAndEditorManagerRenderer
              employeeId={newGraduate.id}
              type="phoneNumber"
              text="電話番号"
              data={employeeInformation?.value.phoneNumber}
            />
          </StyledTableCellRight>
        </TableRow>
        <TableRow>
          <StyledTableCellLeft colSpan={2} align="center">
            <Typography variant="body2">卒業年月</Typography>
          </StyledTableCellLeft>
          <StyledTableCellRight>
            <ViewerAndEditorManagerRenderer
              employeeId={newGraduate.id}
              type="graduationYearAndMonth"
              text="卒業年月"
              data={employeeInformation?.value.graduationYearAndMonth}
            />
          </StyledTableCellRight>
        </TableRow>
        <TableRow>
          <StyledTableCellLeft colSpan={2} align="center">
            <Typography variant="body2">所属</Typography>
          </StyledTableCellLeft>
          <StyledTableCellRight>
            <ViewerAndEditorManagerRenderer
              employeeId={newGraduate.id}
              type="affiliation"
              text="所属"
              data={employeeInformation?.value.affiliation}
            />
          </StyledTableCellRight>
        </TableRow>
        <TableRow>
          <StyledTableCellLeft rowSpan={2} align="center">
            <Typography variant="body2">住所</Typography>
          </StyledTableCellLeft>
          <StyledTableCellLeft align="center">
            <Typography variant="body2">自宅(現住所)</Typography>
          </StyledTableCellLeft>
          <StyledTableCellRight>
            <ViewerAndEditorManagerRenderer
              employeeId={newGraduate.id}
              type="address"
              text="自宅(現住所)"
              data={employeeInformation?.value.address}
            />
          </StyledTableCellRight>
        </TableRow>
        <TableRow>
          <StyledTableCellLeft align="center">
            <Typography variant="body2">帰省先</Typography>
          </StyledTableCellLeft>
          <StyledTableCellRight>
            <ViewerAndEditorManagerRenderer
              employeeId={newGraduate.id}
              type="hometownAddress"
              text="帰省先住所"
              data={employeeInformation?.value.hometownAddress}
            />
          </StyledTableCellRight>
        </TableRow>
        <TableRow>
          <StyledTableCellLeft rowSpan={2} align="center">
            <Typography variant="body2">ID</Typography>
          </StyledTableCellLeft>
          <StyledTableCellLeft align="center">
            <Typography variant="body2">Onn 固定ID</Typography>
          </StyledTableCellLeft>
          <StyledTableCellRight>
            <InformationValueViewer
              label={
                newGraduate.uniqueId ? (
                  <Typography variant="body2">{newGraduate.uniqueId}</Typography>
                ) : undefined
              }
              onClickCopy={() => handleCopy(newGraduate.uniqueId, "Onn 固定ID")}
            />
          </StyledTableCellRight>
        </TableRow>
        <TableRow>
          <StyledTableCellLeft align="center">
            <Typography variant="body2">予備ID</Typography>
          </StyledTableCellLeft>
          <StyledTableCellRight>
            <ViewerAndEditorManagerRenderer
              employeeId={newGraduate.id}
              type="externalId"
              text="予備ID"
              data={employeeInformation?.value.externalId}
            />
          </StyledTableCellRight>
        </TableRow>
        <TableRow>
          <StyledTableCellLeft rowSpan={3} align="center">
            <Typography variant="body2">ファイル</Typography>
          </StyledTableCellLeft>
          <StyledTableCellLeft align="center">
            <Typography variant="body2">履歴書</Typography>
          </StyledTableCellLeft>
          <StyledTableCellRight>
            <ViewerAndEditorManagerRenderer
              employeeId={newGraduate.id}
              type="resumeFilePaths"
              text="履歴書"
              data={employeeInformation?.value.resumeFilePaths}
            />
          </StyledTableCellRight>
        </TableRow>
        <TableRow>
          <StyledTableCellLeft align="center">
            <Typography variant="body2">エントリーシート</Typography>
          </StyledTableCellLeft>
          <StyledTableCellRight>
            <ViewerAndEditorManagerRenderer
              employeeId={newGraduate.id}
              type="entrySheetFilePaths"
              text="エントリーシート"
              data={employeeInformation?.value.entrySheetFilePaths}
            />
          </StyledTableCellRight>
        </TableRow>
        <TableRow>
          <StyledTableCellLeft align="center">
            <Typography variant="body2">SPI</Typography>
          </StyledTableCellLeft>
          <StyledTableCellRight>
            <ViewerAndEditorManagerRenderer
              employeeId={newGraduate.id}
              type="spiFilePaths"
              text="SPI"
              data={employeeInformation?.value.spiFilePaths}
            />
          </StyledTableCellRight>
        </TableRow>
      </TableBody>
    </StyledTable>
  );
};

const StyledTable = styled(Table)`
  &.MuiTable-root {
    width: 100%;
    table-layout: fixed;
  }
  .MuiTableRow-root {
    .MuiTableCell-root {
      border: 1px solid ${(props) => props.theme.palette.grey[100]};
    }
    .MuiTableCell-root:first-child {
      border-left: none;
    }
    .MuiTableCell-root:last-child {
      border-right: none;
    }
  }
`;

const StyledTableCellLeft = styled(TableCell)`
  &.MuiTableCell-root {
    background-color: ${(props) => props.theme.palette.grey[50]};
    padding: 16px 8px;
  }
`;

const StyledTableCellRight = styled(TableCell)`
  &.MuiTableCell-root {
    padding: 8px 16px;
  }
`;
