import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { mutate } from "swr";

import { FIlE_ANSWER, State } from "../reducer";

import { useCurrentUser } from "~/hooks/employee";
import { useUpdateOnnFormTaskAnswers } from "~/hooks/onnFormTaskAnswer";
import { generateOnnFormTaskAnswersKey } from "~/hooks/onnFormTaskAnswer/useOnnFormTaskAnswers";
import { useSnackbar } from "~/hooks/shared";
import { FileAPIAdapter } from "~/infrastructure/usecases/file/fileAPIAdapter";
import { captureException } from "~/util";

type UploadFilesInfo = {
  questionId: string;
  path: string;
  file: File;
};

const fileAPIAdapter = new FileAPIAdapter({ bucketType: "private" });

export const useSubmitHandler = ({
  isPreview,
  state,
  onnFormTaskId,
  formRevisionId,
  isAlreadyAnswered,
}: {
  isPreview?: boolean;
  state: State;
  onnFormTaskId: string;
  formRevisionId: string;
  isAlreadyAnswered: boolean;
}) => {
  const { currentUser } = useCurrentUser();
  const { updateOnnFormTaskAnswers } = useUpdateOnnFormTaskAnswers();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigation = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const onClickSubmit = async () => {
    if (isPreview) window.close();

    setIsSubmitting(true);

    try {
      const uploadFilesInfo: UploadFilesInfo[] = state.answers.flatMap((answer) => {
        if (answer.type !== "FILE") return [];
        const fileAnswer = answer as FIlE_ANSWER;
        if (!fileAnswer.file) return [];
        const filePath = fileAnswer.filePath;
        return {
          questionId: answer.questionId,
          path: filePath,
          file: fileAnswer.file,
        };
      });

      await fileAPIAdapter.uploadFiles(uploadFilesInfo);
    } catch (e) {
      enqueueSnackbar("添付ファイルのアップロードに失敗しました", { variant: "error" });
      captureException({
        error: e as Error,
        tags: { type: "onClickSubmit:fileAPIAdapter.uploadFiles" },
      });
      setIsSubmitting(false);
      return;
    }

    try {
      await updateOnnFormTaskAnswers({
        onnFormTaskId,
        answers: state.answers,
        employeeId: currentUser.id,
        answeredFormRevisionId: formRevisionId,
      });

      mutate(generateOnnFormTaskAnswersKey(onnFormTaskId));

      if (isAlreadyAnswered) {
        // 初回回答時のみthanksページへ遷移
        enqueueSnackbar("回答内容を更新しました", { variant: "success" });
        navigation(`/portal/onn_tasks/${onnFormTaskId}/landing`);
      } else {
        navigation(`/portal/onn_tasks/${onnFormTaskId}/thanks`);
      }
    } catch (e) {
      enqueueSnackbar(
        `フォームの回答に失敗しました。通信環境をご確認の上、再度お試しください。${
          e instanceof Error ? `（${e.message}）` : ""
        } `,
        {
          variant: "error",
        }
      );
      captureException({
        error: e as Error,
        tags: { type: "onClickSubmit:updateOnnFormTaskAnswer" },
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return { onClickSubmit, isSubmitting };
};
