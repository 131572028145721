import { OnnTask, OnnFormTask } from "@onn/common";
import useSWR, { SWRResponse } from "swr";

import { apiClient } from "~/libs";

export const useOnnTasks = ({
  tenantId,
  isExceededDeadlineDate,
}: {
  tenantId: string;
  isExceededDeadlineDate?: boolean;
}): SWRResponse<OnnTask[], Error> => {
  const asyncJob = async (): Promise<OnnTask[]> => {
    const response = await apiClient.get(`/get_onn_tasks`, {
      isExceededDeadlineDate,
    });
    return response.data.map((d) => {
      switch (d.type) {
        case "FORM_TASK": {
          return new OnnFormTask(d);
        }
        default: {
          throw new Error(`${d.type} is not implemented yet`);
        }
      }
    });
  };

  return useSWR(generateOnnTasksKey(tenantId), asyncJob);
};

const generateOnnTasksKey = (tenantId: string) => {
  return { name: `onn-tasks-${tenantId}` };
};
