import { Reminder } from "@onn/common";
import React, { FC, createContext, ReactNode } from "react";

import { useCurrentUser } from "~/hooks/employee";
import { useExpiredRemindersForAllContactRoom } from "~/hooks/reminder/useExpiredRemindersForAllContactRoom";

export const NotificationCountContext = createContext<{
  expiredReminders: Reminder[];
  expiredRemindersMutate: () => void;
}>({
  expiredReminders: [],
  expiredRemindersMutate: () => {
    void 0;
  },
});

export const NotificationCountProvider: FC<{
  children: ReactNode;
}> = ({ children }) => {
  const { currentUser } = useCurrentUser();

  const { data: expiredReminders, mutate } = useExpiredRemindersForAllContactRoom({
    tenantId: currentUser.tenantId,
  });

  return (
    <NotificationCountContext.Provider
      value={{
        expiredReminders: expiredReminders || [],
        expiredRemindersMutate: mutate,
      }}
    >
      {children}
    </NotificationCountContext.Provider>
  );
};
