import { OnnEvent } from "@onn/common";
import React, { useEffect } from "react";

import { useParams } from "react-router-dom";

import { ForNewInterviewPage } from "./forNewInterview/page";
import { ForNormalEventPage } from "./forNormalEvent/page";

import { Loading } from "~/components/uiParts";
import { useOnnEvent } from "~/hooks/onnEvent";
import { useOnnEventAnswers } from "~/hooks/onnEvent/answerResult/useOnnEventAnswers";
import { useCurrentSpace } from "~/hooks/space/useCurrentSpace";
import { useTenant } from "~/hooks/tenant/useTenant";

import { NotFound } from "~/pages/NotFound";
import { captureException } from "~/util";

export const Page: React.FC = () => {
  const params = useParams();
  const onnEventId = params.id;
  const { tenant } = useTenant();
  const { switchSpaceTemporary } = useCurrentSpace();
  const {
    data: onnEvent,
    isLoading: isLoadingOnnEvent,
    mutate: mutateOnnEvent,
  } = useOnnEvent({
    tenantId: tenant.tenantId,
    onnEventId,
  });

  const {
    data: onnEventAnswers,
    isLoading: isLoadingOnnEventAnswers,
    mutate: mutateOnnEventAnswers,
  } = useOnnEventAnswers({
    onnEventId,
  });

  useEffect(() => {
    if (!onnEvent) return;
    switchSpaceTemporary(onnEvent.spaceId);
  }, [switchSpaceTemporary, onnEvent]);

  const isLoading = isLoadingOnnEvent || isLoadingOnnEventAnswers;

  if (isLoading) {
    return <Loading size="large" fullHeight />;
  }

  if (!onnEventId || !onnEvent || !onnEventAnswers) {
    return <NotFound />;
  }
  if (onnEvent.isNormalEvent()) {
    return (
      <ForNormalEventPage
        onnEvent={onnEvent}
        onnEventAnswers={onnEventAnswers}
        mutateOnnEvent={mutateOnnEvent}
        mutateOnnEventAnswers={mutateOnnEventAnswers}
      />
    );
  }

  if (onnEvent.isNewInterviewEvent()) {
    return <ForNewInterviewPage onnEvent={onnEvent} onnEventAnswers={onnEventAnswers} />;
  }

  return <ErrorPage onnEvent={onnEvent} />;
};

const ErrorPage: React.FC<{ onnEvent: OnnEvent }> = ({ onnEvent }) => {
  useEffect(() => {
    captureException({
      error: new Error("Invalid event type"),
      tags: { type: "Invalid event type" },
      extras: {
        onnEvent,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <NotFound />;
};
