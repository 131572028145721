import { Box } from "@material-ui/core";
import { Employee, OnnEvent } from "@onn/common";
import { format } from "date-fns";
import ja from "date-fns/locale/ja";
import React, { FC } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { Paper, Typography, Button, Tooltip, Icon, Checkbox, Linkify } from "~/components/uiParts";
import { useOpenPortalOnnEventPreview } from "~/hooks/openPortalPreview/onnEvent";

type Props = {
  currentUser: Employee;
  onnEvent: OnnEvent;
};

export const EventContentContainer: FC<Props> = ({ currentUser, onnEvent }) => {
  const navigation = useNavigate();

  const { openPortalOnnEventPreview, isReady: isReadyToOpenPortalOnnEventPreview } =
    useOpenPortalOnnEventPreview(onnEvent.id);

  const handleClickOpenPreview = (onnEvent: OnnEvent) => {
    openPortalOnnEventPreview(onnEvent);
  };

  const onClickEditOnnEvent = () => {
    navigation(`/events/${onnEvent.id}/edit/?from_page=detail`);
  };

  return (
    <EventContentPaper
      square={false}
      style={{ display: "flex", flexDirection: "column", rowGap: 32 }}
    >
      <Box display="flex" justifyContent="space-between" alignItems="center" gridGap="16px">
        <Typography variant="h3">{onnEvent.title}</Typography>
        <StyledEventContentHeaderBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          gridGap="8px"
          height="32px"
        >
          <Button
            variant="text"
            borderRadius="regular"
            onClick={() => handleClickOpenPreview(onnEvent)}
            color="primary"
            disabled={!isReadyToOpenPortalOnnEventPreview}
            startIcon={<Icon icon="eye" color={"primary"} size="md" />}
          >
            プレビュー
          </Button>
          {currentUser.isAdmin() && (
            <Button
              onClick={onClickEditOnnEvent}
              color="primary"
              variant="outlined"
              borderRadius="regular"
            >
              編集
            </Button>
          )}
        </StyledEventContentHeaderBox>
      </Box>
      <EventContentBox>
        <Linkify>{onnEvent.content}</Linkify>
      </EventContentBox>
      <Box display="flex" flexDirection="column" gridRowGap={16}>
        <Typography variant="body2" bold>
          イベントタイプ
        </Typography>
        <Typography variant="body1">{OnnEvent.getEventTypeTextMap(onnEvent.type)}</Typography>
      </Box>
      {onnEvent.type === "normal" && (
        <Box>
          <Typography variant="body2" bold>
            候補日時
          </Typography>
          {onnEvent.hasCapacity() && (
            <Box height="24px" my="16px" display="flex" alignItems="center">
              <Box display="flex" alignItems="center">
                <StyledCursorNotAllowedBox>
                  <StyledCheckBox checked={true} disabled />
                </StyledCursorNotAllowedBox>
                <Typography variant="caption" display="inline">
                  日時ごとに定員を設け、定員上限まで自動で日程確定処理を行う
                </Typography>
              </Box>
              <Tooltip
                title="定員を設定する場合、定員上限まで先着順で参加日程が確定し自動で参加確定通知が回答者に送信されます。また、定員に達するとそれ以降の回答者は該当の日程を選択できなくなります。"
                placement="top-start"
              >
                <Icon icon="help" size="ssm" color="grey" />
              </Tooltip>
            </Box>
          )}
          <CandidateDateChipsBox mt={2}>
            {onnEvent.candidateDates.map((d) => {
              const dateStr = format(d.from, "MM/dd(eee)", { locale: ja });
              const fromTimeStr = format(d.from, "HH:mm", { locale: ja });
              const untilTimeStr = format(d.until, "HH:mm", { locale: ja });
              const label = `${dateStr} ${fromTimeStr}〜${untilTimeStr}`;
              return (
                <Typography key={d.id} variant="body1" color="textSecondary">
                  ・{label}
                  {onnEvent.hasCapacity() && <> | 定員 {d.capacity}名</>}
                </Typography>
              );
            })}
          </CandidateDateChipsBox>
        </Box>
      )}
      {onnEvent.type !== "interview" && (
        <Box display="flex" flexDirection="column" gridRowGap="16px">
          <Typography variant="body2" bold>
            回答／編集制限
          </Typography>
          {onnEvent.type === "new_interview" && (
            <Box display="flex" alignItems="center" gridColumnGap="4px">
              <Box display="flex" alignItems="center">
                <StyledCursorNotAllowedBox>
                  <StyledCheckBox checked={onnEvent.isRestrictedAnswerFromNewGraduate} disabled />
                </StyledCursorNotAllowedBox>
                <Typography variant="caption">候補者の回答を停止する</Typography>
              </Box>
              <Tooltip
                title="有効にする場合管理者の代理回答のみ有効となり、回答依頼の通知は候補者に届きません。"
                placement="top-start"
              >
                <Icon icon="help" size="ssm" color="grey" />
              </Tooltip>
            </Box>
          )}
          {onnEvent.type === "new_interview" && (
            <Box display="flex" flexDirection="column" gridRowGap="8px">
              <Box display="flex" alignItems="center" gridColumnGap="4px">
                <Box display="flex" alignItems="center">
                  <StyledCursorNotAllowedBox>
                    <StyledCheckBox
                      checked={onnEvent.isRestrictedEditAnswerFromNewGraduate}
                      disabled
                    />
                  </StyledCursorNotAllowedBox>
                  <Typography variant="caption">回答の編集を停止する</Typography>
                </Box>
                <Tooltip
                  title="有効にする場合管理者による回答の更新のみ有効となり、候補者は一度した回答を編集できません。"
                  placement="top-start"
                >
                  <Icon icon="help" size="ssm" color="grey" />
                </Tooltip>
              </Box>
            </Box>
          )}
          <Box display="flex" flexDirection="column" gridRowGap="8px">
            <Box display="flex" alignItems="center" gridColumnGap="4px">
              <Box display="flex" alignItems="center">
                <StyledCursorNotAllowedBox>
                  <StyledCheckBox checked={onnEvent.isRestrictedDaysAgoWhenAnswer} disabled />
                </StyledCursorNotAllowedBox>
                <Typography variant="caption">回答／編集に制限をかける</Typography>
              </Box>
              <Tooltip
                title="候補者が回答／編集を行える期間を制限することができます。制限を加えると、未回答の候補者には該当の候補日程が非表示になり、回答済みの候補者には編集不可のメッセージが表示されます。"
                placement="top-start"
              >
                <Icon icon="help" size="ssm" color="grey" />
              </Tooltip>
            </Box>
            {onnEvent.isRestrictedDaysAgoWhenAnswer && (
              <Typography variant="body2" color="textSecondary">
                {`候補日程の${onnEvent.daysAgoRestrictAnswer || 0}日前から回答／編集が不可`}
              </Typography>
            )}
          </Box>
        </Box>
      )}
      {onnEvent.type === "normal" && (
        <Box>
          <Typography variant="body2" bold>
            配信タイミング
          </Typography>
          <Box mt="16px">
            <Typography variant="body1">
              {onnEvent.scheduledDate
                ? format(onnEvent.scheduledDate, "yyyy/MM/dd H:mm", { locale: ja })
                : "未設定"}
            </Typography>
          </Box>
        </Box>
      )}
      {onnEvent.type === "normal" && (
        <Box>
          <Typography variant="body2" bold>
            回答期限
          </Typography>
          <Box mt="16px">
            <Typography variant="body1">
              {onnEvent.deadlineDate
                ? format(onnEvent.deadlineDate, "〜yyyy/MM/dd", { locale: ja })
                : "未設定"}
            </Typography>
          </Box>
          <Box height="24px" my="16px" display="flex" alignItems="center">
            <Box display="flex" alignItems="center">
              <StyledCursorNotAllowedBox>
                <StyledCheckBox checked={onnEvent.canAnswerAfterDeadline} disabled />
              </StyledCursorNotAllowedBox>
              <Typography variant="caption" display="inline">
                回答期限を過ぎても回答可能にする
              </Typography>
            </Box>
            <Tooltip
              title="回答期限に設定されている日程の2週間後まで回答可能になります。"
              placement="top-start"
            >
              <Icon icon="help" size="ssm" color="grey" />
            </Tooltip>
          </Box>
        </Box>
      )}
    </EventContentPaper>
  );
};

const EventContentPaper = styled(Paper)`
  padding: 40px;
  margin-right: 40px;
  width: 67%;
  height: 100%;
`;

const EventContentBox = styled(Box)`
  width: 100%;
  padding: 16px;
  font-size: 16px;
  border: 1px solid;
  color: ${(props) => props.theme.palette.text.primary};
  border: 1px solid ${(props) => props.theme.palette.grey[200]};
  border-radius: 5px;
  background-color: ${(props) => props.theme.palette.grey[50]};
  white-space: pre-wrap;
`;

const CandidateDateChipsBox = styled(Box)`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  row-gap: 8px;
  column-gap: 8px;
`;

const StyledCheckBox = styled(Checkbox)`
  &.MuiIconButton-root {
    padding: 0;
    margin-right: 8px;
  }
`;

const StyledCursorNotAllowedBox = styled(Box)`
  &.MuiBox-root {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: not-allowed;
  }
`;

const StyledEventContentHeaderBox = styled(Box)`
  button {
    height: 32px;
  }
`;
