import { Transaction, MemoTransaction, TransactionDataType } from "@onn/common";

import { factory } from "~/service/repository/iTransactionRepository";

// @FIXME Classで実装する
const transactionRepository = factory.transactionRepository();
export const TransactionUseCase = {
  whereByDataTypes: async ({
    employeeId,
    dataTypes,
  }: {
    employeeId: string;
    dataTypes: (keyof typeof TransactionDataType)[];
  }): Promise<Array<Transaction | MemoTransaction>> => {
    return await transactionRepository.whereByDataTypes({ employeeId, dataTypes });
  },
  updateJoinDateType: async (tenantId: string, employeeId: string): Promise<void> => {
    await transactionRepository.updateJoinDateType(tenantId, employeeId);
  },
  updateProfileType: async (tenantId: string, employeeId: string): Promise<void> => {
    await transactionRepository.updateProfileType(tenantId, employeeId);
  },
};

const memoTransactionRepository = factory.memoTransactionRepository();
export const memoTransactionUseCase = {
  find: async (id: string): Promise<MemoTransaction> => {
    return memoTransactionRepository.findMemo(id);
  },
  edit: async (id: string, { title, text }: { title: string; text: string }): Promise<void> => {
    const targetMemo = await memoTransactionRepository.findMemo(id);
    if (!targetMemo) {
      throw new Error("メモが見つかりませんでした");
    }

    await memoTransactionRepository.editMemo(id, { ...targetMemo.contents, title, text });
  },
  delete: async (id: string): Promise<void> => {
    await memoTransactionRepository.deleteMemo(id);
  },
};
