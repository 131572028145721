import { APISchema, Employee, NewGraduateToDisplay } from "@onn/common";
import useSWR from "swr";

import { apiClient } from "~/libs";

export type RegistrationInfo = APISchema["/get_registration_info"]["POST"]["response"]["data"];

/**
 * invitationTokenを元に登録に必要なデータを取得する
 * @param invitationToken invitationToken
 * @returns {RegistrationInfo}
 */
export const useRegistrationInfoByInvitationToken = (
  invitationToken?: string,
  liffAccessToken?: string
) => {
  return useSWR(
    invitationToken
      ? (["/get_registration_info", invitationToken, liffAccessToken] as const)
      : null,
    async ([endpoint, invitationToken, liffAccessToken]) => {
      const response = await apiClient.post(endpoint, {
        "invitation-token": invitationToken,
        "line-access-token": liffAccessToken,
      });

      const employeeOrNewGraduateToDisplay: Employee | NewGraduateToDisplay = (() => {
        const employee = new Employee(response.data.employee);
        // NOTE: 管理者・受け入れメンバーの場合はemployeeをそのまま返す
        if (!employee.isNewGraduateEmployee()) {
          return employee;
        }
        const newGraduateToDisplayExcludeMethods = response.data.employee as NewGraduateToDisplay;
        // NOTE: 新卒の場合は、レスポンスがNewGraduateToDisplayになるためNewGraduateToDisplayにキャストして返す
        const newGraduateToDisplay = new NewGraduateToDisplay(
          Employee.castToNewGraduate(employee),
          newGraduateToDisplayExcludeMethods.employeeTagIds,
          {
            id: newGraduateToDisplayExcludeMethods.recruitmentStatusId,
            label: newGraduateToDisplayExcludeMethods.recruitmentStatusLabel,
            type: newGraduateToDisplayExcludeMethods.recruitmentStatusType,
          }
        );
        return newGraduateToDisplay;
      })();

      return {
        employee: employeeOrNewGraduateToDisplay,
        tenantName: response.data.tenantName,
        enTenantName: response.data.enTenantName,
        tenantLogoUrl: response.data.tenantLogoUrl,
        emailAuthenticationStatus: response.data.emailAuthenticationStatus,
        lineAuthenticationStatus: response.data.lineAuthenticationStatus,
      };
    }
  );
};
