import { zodResolver } from "@hookform/resolvers/zod";
import { Box, Link } from "@material-ui/core";
import React, { FC, useCallback, useState } from "react";

import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { z } from "zod";

import { loginFormSchema } from "./loginFormSchema";

import { Button, PasswordField, TextField, Typography } from "~/components/uiParts";
import { useQuery, useSnackbar } from "~/hooks/shared";
import { AccountUseCase } from "~/service/usecases/employeeUseCase";
import { captureException } from "~/util";

type InputState = z.infer<typeof loginFormSchema>;
export const LoginEmailForm: FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { formState, control, handleSubmit } = useForm<InputState>({
    mode: "onBlur",
    resolver: zodResolver(loginFormSchema),
  });

  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { query } = useQuery();
  const destPath = query.get("dest-path");

  const handleSubmitForm = useCallback(
    async (data: InputState) => {
      try {
        setIsLoading(true);
        await AccountUseCase.signIn(data.email, data.password);

        navigate(destPath ?? "/portal");
      } catch (e) {
        if (e instanceof Error) {
          enqueueSnackbar(e.message, { variant: "error" });
        }
        captureException({
          error: e as Error,
          tags: {
            type: "LoginEmailForm:handleSubmitForm",
          },
        });
      } finally {
        setIsLoading(false);
      }
    },
    [destPath, enqueueSnackbar, navigate]
  );

  return (
    <StyledForm onSubmit={handleSubmit(handleSubmitForm)}>
      <Box display="flex" flexDirection="column" gridRowGap="16px">
        <Typography variant="body2" color="textPrimary">
          メールアドレスでログイン
        </Typography>

        <Controller
          name="email"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <TextField
              placeholder="メールアドレス"
              variant="outlined"
              fullWidth
              error={!!error}
              helperText={error?.message}
              {...field}
            />
          )}
        />
        <Box display="flex" flexDirection="column" gridRowGap="8px">
          <Controller
            name="password"
            control={control}
            render={({ field }) => (
              <PasswordField
                placeholder="パスワード"
                variant="outlined"
                fullWidth
                label={null}
                {...field}
              />
            )}
          />
          <Link href="/reset_password" underline="none" style={{ width: "fit-content" }}>
            <Typography variant="caption" bold>
              パスワードを忘れた場合
            </Typography>
          </Link>
        </Box>
      </Box>
      <Button
        type="submit"
        fullWidth
        borderRadius="circle"
        variant="contained"
        color="primary"
        isLoading={isLoading}
        disabled={!formState.isValid}
      >
        ログイン
      </Button>
    </StyledForm>
  );
};

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  row-gap: 24px;
`;
