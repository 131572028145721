import { OnnEventEvaluation } from "@onn/common";
import { useCallback, useMemo, useState } from "react";

import { useOnnEventEvaluationRanks } from "~/hooks/onnEventEvaluationRank/useOnnEventEvaluationRanks";

// 評価ランク入力セレクターのカスタムフック
export const useOnnEventEvaluationRanksForm = (currentOnnEventEvaluation?: OnnEventEvaluation) => {
  const { data: onnEventEvaluationRanks = [], isLoading } = useOnnEventEvaluationRanks();

  const [selectedOnnEventEvaluationRankId, setSelectedOnnEventEvaluationRankId] = useState<
    string | null
  >(currentOnnEventEvaluation?.onnEventEvaluationRankId || null);
  const onClickEvaluationRank = useCallback((onnEventEvaluationRankId: string | null) => {
    setSelectedOnnEventEvaluationRankId(onnEventEvaluationRankId);
  }, []);

  const selectedOnnEventEvaluationRank = useMemo(() => {
    return onnEventEvaluationRanks.find((v) => v.id === selectedOnnEventEvaluationRankId) || null;
  }, [onnEventEvaluationRanks, selectedOnnEventEvaluationRankId]);

  return {
    onnEventEvaluationRanks,
    selectedOnnEventEvaluationRank,
    onClickEvaluationRank,
    isLoading,
  };
};
