import { OnnEvent } from "@onn/common";
import { useMemo, useState } from "react";

import { useFilterObjectByPartialMatch } from "~/hooks/shared";

export type Filter = {
  interviewName: string;
};

export const useFilter = ({ interviews }: { interviews: OnnEvent[] }) => {
  const [filter, setFilter] = useState<Filter>({
    interviewName: "",
  });

  const { filterObjectByPartialMatch } = useFilterObjectByPartialMatch();

  const filteredInterviews = useMemo(() => {
    return filter.interviewName
      ? filterObjectByPartialMatch({
          objects: interviews,
          searchText: filter.interviewName,
          getProperties: [(e: OnnEvent) => e.title],
        })
      : interviews;
  }, [filter.interviewName, filterObjectByPartialMatch, interviews]);

  return { filter, setFilter, filteredInterviews };
};
