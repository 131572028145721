import { Box } from "@material-ui/core";
import {
  Employee,
  AllContactRoom,
  LineContactRoom,
  NewGraduateToDisplay,
  EmployeeToDisplay,
} from "@onn/common";
import { isEmpty } from "lodash";
import React, { FC } from "react";
import styled from "styled-components";

import { NewGraduateIconWithLabel } from "../NewGraduate/NewGraduateIconWithLabel";
import { DetailMenu } from "../parts";

import { Typography, UserIconWithLabel as CommonUserIconWithLabel } from "~/components/uiParts";
import { SmallUserIconGroup } from "~/components/uiParts/SmallUserIconGroup";

export const ContactRoomItemHeader: FC<{
  contactRoom: AllContactRoom;
  newGraduate: NewGraduateToDisplay | undefined;
  assignee: EmployeeToDisplay | undefined;
  followers: Employee[];
  handleClickManageButton: () => void;
}> = ({ contactRoom, newGraduate, assignee, followers, handleClickManageButton }) => {
  return (
    <StyledHeaderBox
      position="relative"
      display="flex"
      p="16px 40px"
      bgcolor="white"
      justifyContent="space-between"
    >
      <NewGraduateIconWithLabel
        iconPath={(() => {
          if (contactRoom.employee) return contactRoom.employee.profileIconImageUrl;
          if (contactRoom instanceof LineContactRoom) return contactRoom.lineUser.pictureUrl;
          return "";
        })()}
        userName={contactRoom.getRoomName()}
        to={contactRoom.employee?.deleted ? undefined : `/employee/${contactRoom.employeeId}`}
        newGraduate={newGraduate || undefined}
      />
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        gridGap={10}
      >
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          gridGap={40}
        >
          <StyledBox
            ml="auto"
            display="flex"
            flexDirection="column"
            justifyContent="flex-end"
            onClick={handleClickManageButton}
          >
            <StyledTypography variant="caption" color="textSecondary" noWrap>
              担当者
            </StyledTypography>
            <CommonUserIconWithLabel
              name={assignee?.getName() ?? "未登録"}
              iconCircular
              size="extraSmall"
              textSize="small"
              iconPath={assignee?.profileIconImageUrl}
            />
          </StyledBox>
          <StyledBox
            width={80}
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            onClick={handleClickManageButton}
          >
            <StyledTypography variant="caption" color="textSecondary" noWrap>
              フォロワー
            </StyledTypography>
            {isEmpty(followers) ? (
              <Typography variant="caption" color="textPrimary">
                未設定
              </Typography>
            ) : (
              <SmallUserIconGroup
                userInfo={followers.map((a) => ({
                  username: a.getName(),
                  profileIconImageUrl: a.profileIconImageUrl,
                  borderColor: undefined,
                }))}
              />
            )}
          </StyledBox>
        </Box>
        <Box>
          <DetailMenu openManageContactTeamModal={handleClickManageButton} />
        </Box>
      </Box>
    </StyledHeaderBox>
  );
};

const StyledTypography = styled(Typography)`
  color: ${(props) => props.theme.palette.grey[300]};
`;

const StyledBox = styled(Box)`
  &:hover {
    cursor: pointer;
  }
`;

const StyledHeaderBox = styled(Box)`
  box-shadow: ${(props) => props.theme.shadows[10]};
`;
