import { z } from "zod";

import { JsonValueSchema } from "../inputTypeSchemas/JsonValueSchema";
import { ProgressStatusSchema } from "../inputTypeSchemas/ProgressStatusSchema";

/////////////////////////////////////////
// RECRUITMENT PROCESS RECORD SCHEMA
/////////////////////////////////////////

export const RecruitmentProcessRecordSchema = z.object({
  progressStatus: ProgressStatusSchema.nullish(),
  id: z.string(),
  tenantId: z.string(),
  spaceId: z.string(),
  employeeId: z.string(),
  recruitmentStatusId: z.string(),
  relationSetting: JsonValueSchema,
  createdAt: z.date(),
  updatedAt: z.date(),
});

export type RecruitmentProcessRecord = z.infer<typeof RecruitmentProcessRecordSchema>;

/////////////////////////////////////////
// RECRUITMENT PROCESS RECORD OPTIONAL DEFAULTS SCHEMA
/////////////////////////////////////////

export const RecruitmentProcessRecordOptionalDefaultsSchema = RecruitmentProcessRecordSchema.merge(
  z.object({
    createdAt: z.date().optional(),
    updatedAt: z.date().optional(),
  })
);

export type RecruitmentProcessRecordOptionalDefaults = z.infer<
  typeof RecruitmentProcessRecordOptionalDefaultsSchema
>;

export default RecruitmentProcessRecordSchema;
