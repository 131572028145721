import { zodResolver } from "@hookform/resolvers/zod";
import { OnnEvent, OnnEventSlotDate } from "@onn/common";
import { parse } from "date-fns";
import { toNumber } from "lodash";
import { useState } from "react";
import { useForm } from "react-hook-form";

import { mutate } from "swr";

import { generateInitialValues } from "./formUtils/generateInitialValues";
import { InputState, zodFormSchema } from "./formUtils/zodFormSchema";

import { useCurrentUser } from "~/hooks/employee";
import { useCreateOnnEventSlotDates } from "~/hooks/onnEventSlotDates/useCreateOnnEventSlotDates";
import { generateOnnEventSlotDatesForDisplayKey } from "~/hooks/onnEventSlotDates/useOnnEventSlotDatesForDisplay";
import { useUpdateOnnEventSlotDates } from "~/hooks/onnEventSlotDates/useUpdateOnnEventSlotDates";

import { useSnackbar } from "~/hooks/shared";
import { ApiResponseError, captureException } from "~/util";
export type Mode = "create" | "edit";

export const useCreateOrEditOnnEventSlotDateForm = ({
  onnEventSlotDate,
  mode,
  onCancel,
  onnEvent,
}: {
  onnEventSlotDate?: OnnEventSlotDate;
  mode: Mode;
  onCancel: () => void;
  onnEvent: OnnEvent;
}) => {
  const form = useForm<InputState>({
    defaultValues: generateInitialValues({
      onnEventSlotDate,
      defaultValueSettings: onnEvent.slotDefaultValueSetting,
    }),
    mode: "all",
    resolver: zodResolver(zodFormSchema),
  });

  const { updateOnnEventSlotDate, isLoading } = useCreateOrUpdateOnnEventSlotDate({
    mode,
    onnEventSlotDate,
    onCancel,
    onnEventId: onnEvent.id,
  });

  const handleSubmit = form.handleSubmit(updateOnnEventSlotDate, () => {
    form.trigger();
  });

  const hasError = Object.keys(form.formState.errors).length > 0;
  const isSubmitButtonDisabled = form.formState.isSubmitting || hasError || isLoading;

  return { form, handleSubmit, isLoading, isSubmitButtonDisabled };
};

const useCreateOrUpdateOnnEventSlotDate = ({
  mode,
  onnEventSlotDate,
  onCancel,
  onnEventId,
}: {
  mode: Mode;
  onnEventSlotDate?: OnnEventSlotDate;
  onCancel: () => void;
  onnEventId: string;
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { currentUser } = useCurrentUser();
  const [isLoading, setIsLoading] = useState(false);

  const { createOnnEventSlotDates } = useCreateOnnEventSlotDates();
  const { updateOnnEventSlotDates } = useUpdateOnnEventSlotDates();

  const updateOnnEventSlotDate = async (inputValue: InputState) => {
    try {
      setIsLoading(true);
      const from = parse(inputValue.slotFromTimeString, "HH:mm", inputValue.slotDate);
      const until = parse(inputValue.slotUntilString, "HH:mm", inputValue.slotDate);
      const capacity = toNumber(inputValue.capacity);

      if (mode === "create") {
        await createOnnEventSlotDates({
          onnEventId,
          paramsToCreate: {
            from,
            until,
            capacity,
            assigneeId: inputValue.assigneeId || null,
            eventType: inputValue.slotInfo.type,
            description:
              inputValue.slotInfo.type === "online"
                ? inputValue.slotInfo.online.description
                : inputValue.slotInfo.offline.description,
            url: inputValue.slotInfo.type === "online" ? inputValue.slotInfo.online.url : "",
            eventPlaceId:
              inputValue.slotInfo.type === "offline" ? inputValue.slotInfo.offline.location : "",
            eventAddressPostCode:
              inputValue.slotInfo.type === "offline" ? inputValue.slotInfo.offline.postCode : "",
            eventAddressText:
              inputValue.slotInfo.type === "offline" ? inputValue.slotInfo.offline.address : "",
            status: "published",
          },
        });
      } else if (mode === "edit" && onnEventSlotDate) {
        await updateOnnEventSlotDates({
          onnEventId: onnEventSlotDate.onnEventId,
          onnEventSlotId: onnEventSlotDate.id,
          paramsToUpdate: {
            from,
            until,
            capacity,
            assigneeId: inputValue.assigneeId || undefined,
            eventType: inputValue.slotInfo.type,
            description:
              inputValue.slotInfo.type === "online"
                ? inputValue.slotInfo.online.description
                : inputValue.slotInfo.offline.description,
            url: inputValue.slotInfo.type === "online" ? inputValue.slotInfo.online.url : "",
            eventPlaceId:
              inputValue.slotInfo.type === "offline" ? inputValue.slotInfo.offline.location : "",
            eventAddressPostCode:
              inputValue.slotInfo.type === "offline" ? inputValue.slotInfo.offline.postCode : "",
            eventAddressText:
              inputValue.slotInfo.type === "offline" ? inputValue.slotInfo.offline.address : "",
            status: undefined,
          },
        });
      }
      mutate(generateOnnEventSlotDatesForDisplayKey(onnEventId));
      onCancel();
    } catch (e) {
      captureException({
        error: e as Error,
        tags: {
          type: "useEditOnnEventSlotDateForm",
        },
        extras: {
          inputValue,
          currentUser,
          mode,
        },
      });
      let errorMessage = "エラーが発生しました。お手数ですが担当者までご連絡ください";
      if (e instanceof ApiResponseError) {
        if (e.status === 400) {
          errorMessage = (e.responseBody as { error: string }).error;
        }
      }

      enqueueSnackbar(errorMessage, {
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return { updateOnnEventSlotDate, isLoading };
};
