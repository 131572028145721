import { Box } from "@material-ui/core";
import { NewGraduateToDisplay } from "@onn/common";
import React, { FC } from "react";
import { CSVLink } from "react-csv";
import styled from "styled-components";

import { useGenerateSampleCSV } from "./useGenerateSampleCSV";

import { Button, Icon, Typography } from "~/components/uiParts";

type Props = {
  onClickNextButton: () => void;
  allNewGraduates: NewGraduateToDisplay[];
};

export const HEADER_LABELS = [
  "Onn固有ID",
  "姓",
  "名",
  "姓（フリガナ）",
  "名（フリガナ）",
  "性別",
  "生年月日(例：20240101)",
  "電話番号",
  "卒業年",
  "卒業月",
  "学校名",
  "学部・院名",
  "自宅住所・郵便番号",
  "自宅住所・都道府県",
  "自宅住所・市区町村",
  "自宅住所・番地",
  "自宅住所・建物名",
  "帰省先住所・郵便番号",
  "帰省先住所・都道府県",
  "帰省先住所・市区町村",
  "帰省先住所・番地",
  "帰省先住所・建物名",
  "ID（その他）",
];

const SAMPLE_DATA_ROW = [
  "",
  "田中",
  "太郎",
  "タナカ",
  "タロウ",
  "男性",
  "20240101",
  "000-1234-5678",
  "2024",
  "3",
  "○○大学",
  "□□学部○○学科",
  "000-0000",
  "東京都",
  "千代田区",
  "千代田1-1",
  "千代田ビル",
  "000-0000",
  "東京都",
  "千代田区",
  "千代田1-1",
  "千代田ビル",
  "1234567890",
];

// packages/front/src/components/domains/_share/DownloadSampleFileStepContent.tsxを使用すること
export const DownloadSampleFileStepContent: FC<Props> = ({
  onClickNextButton,
  allNewGraduates,
}) => {
  const NEW_EMPLOYEES_SAMPLE_DATA = [HEADER_LABELS, SAMPLE_DATA_ROW];

  const { generateSampleCSV } = useGenerateSampleCSV();

  return (
    <>
      <Typography>
        最新のサンプルファイルをダウンロードして、必要事項を記入したアップロード用ファイルをご準備ください。
      </Typography>
      <Box mt={4} display="flex" flexDirection="row" gridGap={16}>
        <StyledCSVLink
          data={NEW_EMPLOYEES_SAMPLE_DATA}
          filename="候補者情報一括登録_アップロード用サンプルファイル"
        >
          <Button
            startIcon={<Icon icon="download" size="md" color="primary" />}
            color={"primary"}
            borderRadius={"regular"}
            variant={"outlined"}
            fullHeight
          >
            サンプルファイルをダウンロード
          </Button>
        </StyledCSVLink>
        <StyledCSVLink
          data={generateSampleCSV(allNewGraduates)}
          filename="候補者情報一括登録_設定値ファイル"
        >
          <Button
            startIcon={<Icon icon="download" size="md" color="primary" />}
            color={"primary"}
            borderRadius={"regular"}
            variant={"outlined"}
            fullHeight
          >
            設定値ファイルをダウンロード
          </Button>
        </StyledCSVLink>
      </Box>
      <StyledContainerBox p={2} mt={4} mb={5}>
        <Typography bold={true}>アップロード用ファイル作成に関するヒント</Typography>
        <Typography>
          フォーマットとなるサンプルファイルは内容が更新される可能性があります。毎回ダウンロードし直してご利用ください。
          <br />
          指定のある項目は設定値ファイルからコピー&ペーストして入力を行なってください。
        </Typography>
      </StyledContainerBox>
      <Box display="flex" justifyContent="center">
        <Box width="240px">
          <Button
            fullWidth={true}
            onClick={onClickNextButton}
            color={"primary"}
            borderRadius={"circle"}
            variant={"contained"}
          >
            次へ
          </Button>
        </Box>
      </Box>
    </>
  );
};

const StyledContainerBox = styled(Box)`
  background-color: ${(props) => props.theme.palette.grey[50]};
`;

const StyledCSVLink = styled(CSVLink)`
  height: 48px;
  text-decoration: none;
`;
