// NOTE: このファイルはProviderのため様々な上位コンポーネントから呼び出されます 循環参照を避けるために、直接importしてください。
import React, { FC, useState, createContext, ReactNode, useContext, useCallback } from "react";

import { AnnouncementDeleteConfirmModal } from "../domains/announcement";
import { ChangeAssigneeModal } from "../domains/contactRooms/ChangeAssigneeModal";
import {
  AnnouncementDistributionDeleteConfirmModal,
  AssignNewGraduateFollowersModal,
  AssignNewGraduateMentorModal,
  ChangeNewGraduateMentorConfirmModal,
  DismissNewGraduateFollowerConfirmModal,
  NewGraduateInviteModal,
} from "../domains/employees";
import { AddEmployeeTagsActionModal } from "../domains/employees/AddEmployeeTagsActionModal";
import { AddTagToEmployeesByCSVModal } from "../domains/employees/AddTagToEmployeesByCsvModal";
import { AssignTagsToEmployeeModal } from "../domains/employees/AssignTagsToEmployeeModal";
import { CreateNewGraduateModal } from "../domains/employees/CreateNewGraduateModal";
import { DownloadEmployeesWithCSVActionModal } from "../domains/employees/DownloadEmployeesWithCSVActionModal";
import { GenerateRegistrationInvitationLinkModal } from "../domains/employees/GenerateRegistrationInvitationLinkModal/GenerateRegistrationInvitationLinkModal";
import { InviteNewGraduateModal } from "../domains/employees/InviteNewGraduateModal";
import { InviteNewGraduateWithCSVModal } from "../domains/employees/InviteNewGraduateWithCSVModal";
import { NewGraduateManageMentorAndFollowersModal } from "../domains/employees/NewGraduateManageMentorAndFollowersModal";
import { NewGraduateSearchModal } from "../domains/employees/NewGraduateSearchModal";
import { RecruitmentProcessDetailModal } from "../domains/employees/RecruitmentProcessDetailModal/RecruitmentProcessDetailModal";
import { RemoveEmployeeFromOnnEventInterviewModal } from "../domains/employees/RemoveEmployeeFromOnnEventInterviewModal/RemoveEmployeeFromOnnEventInterviewModal";
import { RemoveNewInterviewDeterminedDateModal } from "../domains/employees/RemoveNewInterviewDeterminedDateModal";
import { SPNewGraduateDetailModal } from "../domains/employees/SPNewGraduateDetailModal/SPNewGraduateDetailModal";
import { UpdateEmployeeInformationWithCSVModal } from "../domains/employees/UpdateEmployeeInformationWithCSVModal";
import { UpdateRecruitmentStatusActionModal } from "../domains/employees/UpdateRecruitmentStatusActionModal/UpdateRecruitmentStatusActionModal";
import { UpdateEventAssigneeModal, EventDeleteConfirmModal } from "../domains/events";
import { CreateOrEditOnnEventSlotDateModal } from "../domains/events/CreateOrEditOnnEventSlotDateModal";
import { CreateOrUpdateEventPlaceModal } from "../domains/events/CreateOrUpdateEventPlaceModal/CreateOrUpdateEventPlaceModal";
import { EditOnnEventEvaluationModal } from "../domains/events/EditOnnEventEvaluationModal";
import { EventAttendanceQRModal } from "../domains/events/EventAttendanceQRModal";
import { EventBulkRemindModal } from "../domains/events/EventBulkRemindModal";

import { ListOfEmployeesParticipatingInInterviewModal } from "../domains/events/ListOfEmployeesParticipatingInInterviewModal/ListOfEmployeesParticipatingInInterviewModal";
import { AnswerInterviewEventOnBehalfModal } from "../domains/onnEvents/AnswerInterviewEventOnBehalfModal";
import { AnswerInterviewEventOnBehalfWithCSVModal } from "../domains/onnEvents/AnswerInterviewEventOnBehalfWithCSVModal";
import { AnswerNewInterviewEventOnBehalfModal } from "../domains/onnEvents/AnswerNewInterviewEventOnBehalfModal";
import { AnswerNormalEventOnBehalfModal } from "../domains/onnEvents/AnswerNormalEventOnBehalfModal";
import { AttendNormalEventOnBehalfModal } from "../domains/onnEvents/AttendNormalEventOnBehalfModal";
import { ConfirmDeleteSlotModal } from "../domains/onnEvents/ConfirmDeleteSlotModal";
import { CreateOnnEventSlotsWithCSVModal } from "../domains/onnEvents/CreateOnnEventSlotsWithCSVModal";
import { DeleteOnnTaskModal } from "../domains/onnTasks/DeleteOnnTaskModal";
import { OnnTaskBulkRemindModal } from "../domains/onnTasks/OnnTaskBulkRemindModal";
import { RemoveEmployeeFromOnnTaskAnswersModal } from "../domains/onnTasks/RemoveEmployeeFromOnnTaskAnswersModal";
import { ConfirmDeleteTagModal } from "../domains/tenantSettings/ConfirmDeleteTagModal";

import { ConfirmModal } from "../uiParts/Modal/ConfirmModal";

import { ModalTypes } from "./ModalTypes";

import {
  ConfirmPostMessageModal,
  CreateReminderModal,
  EditContactMessageTemplateModal,
  DeleteContactMessageTemplateModal,
} from "~/components/domains/contactRooms";
import { AddFollowersModal } from "~/components/domains/contactRooms/AddFollowersModal";
import { ManageContactTeamModal } from "~/components/domains/contactRooms/ManageContactTeamModal";
import { AddAdminModal } from "~/components/domains/employees/AddAdminModal";
import { AddNewGraduateByCSVModal } from "~/components/domains/employees/AddNewGraduateByCSVModal";
import { AddNewGraduateModal } from "~/components/domains/employees/AddNewGraduateModal";
import { AssignMentorModal } from "~/components/domains/employees/AssignMentorModal";
import { AssignTagToEmployeesModal } from "~/components/domains/employees/AssignTagToEmployeesModal";
import { ChangeMentorConfirmModal } from "~/components/domains/employees/ChangeMentorConfirmModal";
import { ConfirmChangeRoleToAdminModal } from "~/components/domains/employees/ConfirmChangeRoleToAdminModal";
import { DeleteMemberModal } from "~/components/domains/employees/DeleteMemberModal";
import { DismissSupportMemberConfirmModal } from "~/components/domains/employees/DismissSupportMemberConfirmModal";
import { RemoveEmployeeFromOnnEventCandidateListModal } from "~/components/domains/employees/RemoveEmployeeFromOnnEventCandidateListModal";
import { ResendInvitationModal } from "~/components/domains/employees/ResendInvitationModal";
import { ConfirmAddNewGraduateOnnEventModal } from "~/components/domains/events/ConfirmAddNewGraduateOnnEventModal";
import { AddOrEditTagModal } from "~/components/domains/tenantSettings/AddOrEditTagModal";

type ModalProps = ModalTypes[keyof ModalTypes] | undefined | null;

const DURATION = 195; // モーダルの開閉のアニメーション時間

export const ModalContext = createContext<{
  modal: ModalProps;
  open: boolean;
  handleModal: (props: ModalProps) => void;
}>({ modal: undefined, open: false, handleModal: () => void 0 });

export const ModalProvider: FC<{
  children: ReactNode;
}> = ({ children }) => {
  const [modal, setModal] = useState<ModalProps>();
  const [open, setOpen] = useState(false);

  const handleModal = useCallback((args: ModalProps) => {
    if (!args) {
      // モーダルを閉じるときは先にopenをfalsyにして閉じるアニメーションを実行する
      setOpen(false);
      // アニメーションが終了したらmodalをnullにしてコンポーネントを破棄する
      setTimeout(() => setModal(null), DURATION);
    } else {
      setModal(args);
      setOpen(true);
    }
  }, []);

  return (
    <ModalContext.Provider value={{ modal, open, handleModal }}>
      <Modal />
      {children}
    </ModalContext.Provider>
  );
};

const Modal = () => {
  const { modal, open, handleModal } = useContext(ModalContext);
  const handleCancel = useCallback(() => handleModal(null), [handleModal]);

  if (!modal) return null;

  switch (modal.name) {
    case "addAdminModal": {
      return <AddAdminModal open={open} onCancel={handleCancel} {...modal.args} />;
    }
    case "addFollowersModal": {
      return <AddFollowersModal open={open} onCancel={handleCancel} {...modal.args} />;
    }
    case "addNewGraduateByCSVModal": {
      return <AddNewGraduateByCSVModal open={open} onCancel={handleCancel} {...modal.args} />;
    }
    case "addNewGraduateModal": {
      return <AddNewGraduateModal open={open} onCancel={handleCancel} {...modal.args} />;
    }
    case "addOrEditTagModal": {
      return <AddOrEditTagModal open={open} onCancel={handleCancel} {...modal.args} />;
    }
    case "answerInterviewEventOnBehalfModal": {
      return (
        <AnswerInterviewEventOnBehalfModal open={open} onCancel={handleCancel} {...modal.args} />
      );
    }
    case "answerNewInterviewEventOnBehalfModal": {
      return (
        <AnswerNewInterviewEventOnBehalfModal open={open} onCancel={handleCancel} {...modal.args} />
      );
    }
    case "answerInterviewEventOnBehalfWithCSVModal": {
      return (
        <AnswerInterviewEventOnBehalfWithCSVModal
          open={open}
          onCancel={handleCancel}
          {...modal.args}
        />
      );
    }
    case "answerNormalEventOnBehalfModal": {
      return <AnswerNormalEventOnBehalfModal open={open} onCancel={handleCancel} {...modal.args} />;
    }
    case "assignMentorModal": {
      return <AssignMentorModal open={open} onCancel={handleCancel} {...modal.args} />;
    }
    case "assignNewGraduateMentorModal": {
      return <AssignNewGraduateMentorModal open={open} onCancel={handleCancel} {...modal.args} />;
    }
    case "assignNewGraduateFollowersModal": {
      return (
        <AssignNewGraduateFollowersModal open={open} onCancel={handleCancel} {...modal.args} />
      );
    }
    case "attendNormalEventOnBehalfModal": {
      return <AttendNormalEventOnBehalfModal open={open} onCancel={handleCancel} {...modal.args} />;
    }
    case "announcementDeleteConfirmModal": {
      return <AnnouncementDeleteConfirmModal open={open} onCancel={handleCancel} {...modal.args} />;
    }
    case "announcementDistributionDeleteConfirmModal": {
      return (
        <AnnouncementDistributionDeleteConfirmModal
          open={open}
          onCancel={handleCancel}
          {...modal.args}
        />
      );
    }
    case "createReminderModal": {
      return <CreateReminderModal open={open} onCancel={handleCancel} {...modal.args} />;
    }
    case "createOnnEventSlotsWithCSVModal": {
      return (
        <CreateOnnEventSlotsWithCSVModal open={open} onCancel={handleCancel} {...modal.args} />
      );
    }
    case "changeAssigneeModal": {
      return <ChangeAssigneeModal open={open} onCancel={handleCancel} {...modal.args} />;
    }
    case "changeMentorConfirmModal": {
      return <ChangeMentorConfirmModal open={open} onCancel={handleCancel} {...modal.args} />;
    }
    case "changeNewGraduateMentorConfirmModal": {
      return (
        <ChangeNewGraduateMentorConfirmModal open={open} onCancel={handleCancel} {...modal.args} />
      );
    }
    case "confirmPostMessageModal": {
      return <ConfirmPostMessageModal open={open} onCancel={handleCancel} {...modal.args} />;
    }
    case "confirmChangeRoleToAdminModal": {
      return <ConfirmChangeRoleToAdminModal open={open} onCancel={handleCancel} {...modal.args} />;
    }
    case "confirmDeleteTagModal": {
      return <ConfirmDeleteTagModal open={open} onCancel={handleCancel} {...modal.args} />;
    }
    case "confirmAddNewGraduateOnnEventModal": {
      return (
        <ConfirmAddNewGraduateOnnEventModal open={open} onCancel={handleCancel} {...modal.args} />
      );
    }
    case "createNewGraduateModal": {
      return <CreateNewGraduateModal open={open} onCancel={handleCancel} {...modal.args} />;
    }
    case "deleteContactMessageTemplateModal": {
      return (
        <DeleteContactMessageTemplateModal open={open} onCancel={handleCancel} {...modal.args} />
      );
    }
    case "deleteMemberModal": {
      return <DeleteMemberModal open={open} onCancel={handleCancel} {...modal.args} />;
    }
    case "deleteOnnTaskModal": {
      return <DeleteOnnTaskModal open={open} onCancel={handleCancel} {...modal.args} />;
    }
    case "dismissSupportMemberConfirmModal": {
      return (
        <DismissSupportMemberConfirmModal open={open} onCancel={handleCancel} {...modal.args} />
      );
    }
    case "dismissNewGraduateFollowerConfirmModal": {
      return (
        <DismissNewGraduateFollowerConfirmModal
          open={open}
          onCancel={handleCancel}
          {...modal.args}
        />
      );
    }
    case "editContactMessageTemplateModal": {
      return (
        <EditContactMessageTemplateModal open={open} onCancel={handleCancel} {...modal.args} />
      );
    }
    case "generateRegistrationInvitationLinkModal": {
      return (
        <GenerateRegistrationInvitationLinkModal
          open={open}
          onCancel={handleCancel}
          {...modal.args}
        />
      );
    }
    case "inviteNewGraduateModal": {
      return <InviteNewGraduateModal open={open} onCancel={handleCancel} {...modal.args} />;
    }
    case "inviteNewGraduateWithCSVModal": {
      return <InviteNewGraduateWithCSVModal open={open} onCancel={handleCancel} {...modal.args} />;
    }
    case "manageContactTeamModal": {
      return <ManageContactTeamModal open={open} onCancel={handleCancel} {...modal.args} />;
    }
    case "newGraduateManageMentorAndFollowersModal": {
      return (
        <NewGraduateManageMentorAndFollowersModal
          open={open}
          onCancel={handleCancel}
          {...modal.args}
        />
      );
    }
    case "newGraduateInviteModal": {
      return <NewGraduateInviteModal open={open} onCancel={handleCancel} {...modal.args} />;
    }
    case "onnTaskBulkRemindModal": {
      return <OnnTaskBulkRemindModal open={open} onCancel={handleCancel} {...modal.args} />;
    }
    case "removeEmployeeFromOnnEventCandidateListModal": {
      return (
        <RemoveEmployeeFromOnnEventCandidateListModal
          open={open}
          onCancel={handleCancel}
          {...modal.args}
        />
      );
    }
    case "removeEmployeeFromOnnTaskAnswersModal": {
      return (
        <RemoveEmployeeFromOnnTaskAnswersModal
          open={open}
          onCancel={handleCancel}
          {...modal.args}
        />
      );
    }
    case "resendInvitationModal": {
      return <ResendInvitationModal open={open} onCancel={handleCancel} {...modal.args} />;
    }
    case "spNewGraduateDetailModal": {
      return <SPNewGraduateDetailModal open={open} onCancel={handleCancel} {...modal.args} />;
    }
    case "eventBulkRemindModal": {
      return <EventBulkRemindModal open={open} onCancel={handleCancel} {...modal.args} />;
    }
    case "eventAttendanceQRModal": {
      return <EventAttendanceQRModal open={open} onCancel={handleCancel} {...modal.args} />;
    }
    case "eventDeleteConfirmModal": {
      return <EventDeleteConfirmModal open={open} onCancel={handleCancel} {...modal.args} />;
    }
    case "updateEventAssigneeModal": {
      return <UpdateEventAssigneeModal open={open} onCancel={handleCancel} {...modal.args} />;
    }
    case "updateEmployeeInformationWithCSVModal": {
      return (
        <UpdateEmployeeInformationWithCSVModal
          open={open}
          onCancel={handleCancel}
          {...modal.args}
        />
      );
    }
    case "editOnnEventEvaluationModal": {
      return <EditOnnEventEvaluationModal open={open} onCancel={handleCancel} {...modal.args} />;
    }
    case "assignTagsToEmployeeModal": {
      return <AssignTagsToEmployeeModal open={open} onCancel={handleCancel} {...modal.args} />;
    }
    case "assignTagToEmployeesModal": {
      return <AssignTagToEmployeesModal open={open} onCancel={handleCancel} {...modal.args} />;
    }
    case "addTagToEmployeesByCSVModal": {
      return <AddTagToEmployeesByCSVModal open={open} onCancel={handleCancel} {...modal.args} />;
    }
    case "newGraduateSearchModal": {
      return <NewGraduateSearchModal open={open} onCancel={handleCancel} {...modal.args} />;
    }
    case "recruitmentProcessDetailModal": {
      return <RecruitmentProcessDetailModal open={open} onCancel={handleCancel} {...modal.args} />;
    }
    case "updateRecruitmentStatusActionModal": {
      return (
        <UpdateRecruitmentStatusActionModal open={open} onCancel={handleCancel} {...modal.args} />
      );
    }
    case "addEmployeeTagsActionModal": {
      return <AddEmployeeTagsActionModal open={open} onCancel={handleCancel} {...modal.args} />;
    }
    case "downloadEmployeesWithCSVActionModal": {
      return (
        <DownloadEmployeesWithCSVActionModal open={open} onCancel={handleCancel} {...modal.args} />
      );
    }
    case "createOrEditOnnEventSlotDateModal": {
      return (
        <CreateOrEditOnnEventSlotDateModal open={open} onCancel={handleCancel} {...modal.args} />
      );
    }
    case "listOfEmployeesParticipatingInInterviewModal": {
      return (
        <ListOfEmployeesParticipatingInInterviewModal
          open={open}
          onCancel={handleCancel}
          {...modal.args}
        />
      );
    }
    case "removeEmployeeFromOnnEventInterviewModal": {
      return (
        <RemoveEmployeeFromOnnEventInterviewModal
          open={open}
          onCancel={handleCancel}
          {...modal.args}
        />
      );
    }
    case "removeNewInterviewDeterminedDateModal": {
      return (
        <RemoveNewInterviewDeterminedDateModal
          open={open}
          onCancel={handleCancel}
          {...modal.args}
        />
      );
    }
    case "confirmDeleteSlotModal": {
      return <ConfirmDeleteSlotModal open={open} onCancel={handleCancel} {...modal.args} />;
    }
    case "confirmModal": {
      return <ConfirmModal open={open} onCancel={handleCancel} {...modal.args} />;
    }
    case "createOrUpdateEventPlaceModal": {
      return <CreateOrUpdateEventPlaceModal open={open} onCancel={handleCancel} {...modal.args} />;
    }
    default:
      throw new Error("modalのnameが不正です");
  }
};
