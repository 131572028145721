import { Box, Link } from "@material-ui/core";
import { Employee } from "@onn/common";
import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import styled, { CSSObject } from "styled-components";

import { useFirstAndLastNameInput } from "~/components/domains/account/_shared";
import { FirstAndLastNameInput } from "~/components/domains/account/_shared/FirstAndLastNameInput";
import TermsOfUse from "~/components/domains/account/_shared/TermsOfUse";

import { Paper, Button, Divider, Icon, Typography } from "~/components/uiParts";
import { useAuthorizeForIndividual } from "~/hooks/employee/useAuthorizeForIndividual";
import { RegistrationInfo } from "~/hooks/employee/useRegistrationInfoByInvitationToken";
import { useSignUpByLine } from "~/hooks/employee/useSignUpByLine";
import { useLiffId } from "~/hooks/liff";
import { useSnackbar } from "~/hooks/shared";
import { mixin, captureException } from "~/util";
export const RegisterIndividualByLine = ({
  registrationInfo,
  liffAccessToken,
}: {
  registrationInfo: RegistrationInfo;
  liffAccessToken: string | null | undefined;
}) => {
  const { t } = useTranslation(["account"]);

  const { enqueueSnackbar } = useSnackbar();
  const { signUpByLine } = useSignUpByLine();
  const liffId = useLiffId();

  const [isAgreed, setIsAgreed] = useState(false);
  const [isSignupLoading, setIsSignupLoading] = useState(false);

  const employee = registrationInfo.employee;
  const tenantName = registrationInfo.tenantName;
  const isAuthenticated = registrationInfo.lineAuthenticationStatus?.isAuthenticated;
  const { authorize } = useAuthorizeForIndividual();

  const {
    firstName,
    firstNameError,
    lastName,
    lastNameError,
    onChangeFirstName,
    onChangeLastName,
  } = useFirstAndLastNameInput(employee);

  const isDisableSignUpButton = useMemo(() => {
    return firstName.length === 0 || lastName.length === 0 || !isAgreed;
  }, [firstName.length, isAgreed, lastName.length]);

  const onClickSignUp = useCallback(async () => {
    if (!liffAccessToken) {
      enqueueSnackbar("予期せぬエラーが発生しました。再度お試しください", { variant: "error" });
      captureException({
        error: new Error("[要対応] LINE個別登録でアクセストークンが取得できませんでした"),
        tags: { type: "Action required(要対応)" },
        extras: {
          employee,
          registrationInfo,
          isAuthenticated,
        },
      });
      return;
    }

    try {
      setIsSignupLoading(true);
      if (isAuthenticated) {
        await authorize({
          invitationToken: employee.invitationToken,
          firstName,
          lastName,
          lineAccessToken: liffAccessToken,
        });
      } else {
        await signUpByLine({
          firstName,
          lastName,
          invitationToken: employee.invitationToken,
          lineAccessToken: liffAccessToken,
        });
      }
    } catch (error) {
      captureException({
        error: new Error(
          `[要対応]LIFF上で候補者のLINE認証登録のエラーが発生しました。${
            isAuthenticated ? "authorize" : "signupByLine"
          }でエラーが発生しました。`
        ),
        tags: { type: "Action required(要対応)" },
        extras: {
          tenantId: employee.tenantId,
          email: employee.email,
          employeeId: employee.id,
          liffId,
          location: window.location.href,
          isCookieEnabled: navigator.cookieEnabled,
          isAuthenticated,
        },
      });
    } finally {
      setIsSignupLoading(false);
    }
  }, [
    authorize,
    employee,
    enqueueSnackbar,
    firstName,
    isAuthenticated,
    lastName,
    liffAccessToken,
    liffId,
    registrationInfo,
    signUpByLine,
  ]);

  return (
    <StyledContainer maxWidth="500px">
      <StyledPaper>
        <Box display="inline" fontWeight={400} lineHeight="24px">
          <Typography variant="body2" color="textSecondary">
            {t("guideText", {
              tenantName,
              role: Employee.displayRoleMap[employee.role],
              context: employee.isNewGraduate ? "newGraduate" : "midCareer",
            })}
          </Typography>
        </Box>
        <Box>
          <StyledDiv>
            <Box mb={5}>
              <Typography variant="body2" bold gutterBottom>
                メールアドレス
              </Typography>
              <Box display="flex">
                <Box width="95%">
                  {/* TODO: FixedEmailInput に置き換える */}
                  <StyledFixedEmailInput
                    value={employee.email}
                    name="email"
                    type="email"
                    readOnly
                  />
                </Box>
                <Icon icon="check" color="primary" size="sm" />
              </Box>
              <StyledDivider />
            </Box>
            <Box>
              <Typography variant="body2" bold>
                アカウント登録情報
              </Typography>
              <FirstAndLastNameInput
                firstNameError={firstNameError}
                lastNameError={lastNameError}
                onChangeFirstName={onChangeFirstName}
                onChangeLastName={onChangeLastName}
                firstName={firstName}
                lastName={lastName}
              />
            </Box>
            <TermsOfUse
              isAgreed={isAgreed}
              isNewGrad={true}
              onChange={() => setIsAgreed((prv) => !prv)}
            />
            <Box
              textAlign="center"
              color="textSecondary"
              lineHeight="24px"
              mt={5}
              mb={4}
              pl={3}
              pr={3}
            >
              <Typography variant="caption">アカウントを作成することにより、Onnの</Typography>
              <Link
                href="https://onn-hr.com/privacy_policy"
                underline="always"
                target="_blank"
                color="textSecondary"
              >
                <Typography variant="caption">プライバシーポリシー</Typography>
              </Link>
              <Typography variant="caption">に同意するものとします。</Typography>
            </Box>
            <Box textAlign="center" mt={2} mb={2}>
              <Button
                fullWidth
                type="submit"
                variant="contained"
                borderRadius="circle"
                color="primary"
                isLoading={isSignupLoading}
                disabled={isDisableSignUpButton || isSignupLoading}
                onClick={onClickSignUp}
              >
                {isSignupLoading ? "送信中" : "アカウント作成する"}
              </Button>
            </Box>
          </StyledDiv>
        </Box>
      </StyledPaper>
    </StyledContainer>
  );
};

const StyledFixedEmailInput = styled.input`
  width: 100%;
  user-select: none;
  color: ${(props) => props.theme.palette.text.secondary};
  ${(props) => props.theme.typography.subtitle2 as CSSObject};
  font-weight: bold;

  &:focus {
    outline: none;
  }
`;

const StyledContainer = styled(Box)`
  padding: 64px 24px;
  width: 100%;

  ${mixin.breakDown.sm`
    padding: 40px 0px;
    padding-top: 0px;
  `}
`;

const StyledPaper = styled(Paper)`
  &.MuiPaper-root {
    ${mixin.breakDown.sm`
      box-shadow: none;
      `}
  }
`;

const StyledDiv = styled.div`
  width: 100%;
  font-size: 12px;
  color: ${(props) => props.theme.palette.text.secondary};
`;

const StyledDivider = styled(Divider)`
  height: 2px;
`;
