import { Box } from "@material-ui/core";
import React, { FC } from "react";
import { AutoSizer, MultiGrid, MultiGridProps } from "react-virtualized";
import styled from "styled-components";

import { Loading } from "../Loading/Loading";
import { Paper } from "../Paper/Paper";
import { Typography } from "../Typography/Typography";

type Props = {
  paperHeight: number;
  isLoading: boolean;
  isEmpty: boolean;
  labelWhenEmpty: string;
  multiGridProps: MultiGridProps;
  paperPadding?: number;
};

export const MultiGridOnPaper: FC<Props> = ({
  paperHeight,
  isLoading,
  isEmpty,
  labelWhenEmpty,
  multiGridProps,
  paperPadding = 24,
}) => {
  let children = null;

  if (isLoading) {
    children = (
      <StyledEmptyBox>
        <Loading size="large" />
      </StyledEmptyBox>
    );
  } else if (isEmpty) {
    children = (
      <StyledEmptyBox>
        <Typography variant="body2" align="center" color="textSecondary">
          {labelWhenEmpty}
        </Typography>
      </StyledEmptyBox>
    );
  } else {
    children = (
      <AutoSizer disableHeight>
        {({ width }) => {
          return (
            <StyledBox width={width}>
              <MultiGrid {...multiGridProps} width={width} />
            </StyledBox>
          );
        }}
      </AutoSizer>
    );
  }

  return (
    <StyledPaper $height={paperHeight} $paperPadding={paperPadding}>
      {children}
    </StyledPaper>
  );
};

const StyledPaper = styled(Paper)<{ $height: number; $paperPadding: number }>`
  padding: ${(props) => props.$paperPadding}px;
  height: ${(props) => props.$height}px;

  &.MuiPaper-rounded {
    border-radius: 10px;
  }
`;

const StyledBox = styled(Box)`
  .ReactVirtualized__Grid:first-child {
    -ms-overflow-style: none;
    ::-webkit-scrollbar {
      display: none;
    }
  }
`;

const StyledEmptyBox = styled(Box)`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
