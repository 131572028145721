import { zodResolver } from "@hookform/resolvers/zod";
import { format, isSameDay } from "date-fns";
import { first, sortBy, uniq, uniqBy } from "lodash";
import { useCallback, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { getSelectableEventTypes } from "./getSelectableEventTypes";

import { useCurrentUser } from "~/hooks/employee";
import { useAnswerNewInterviewEvent } from "~/hooks/onnEvent/useAnswerNewInterviewEvent";

import {
  OnnEventDataForPortal,
  mutateOnnEventForPortal,
} from "~/hooks/onnEvent/useOnnEventForPortal";
import { useAnswerOnnEventForPreview } from "~/hooks/openPortalPreview/onnEvent";
import { useQuery, useSnackbar } from "~/hooks/shared";
import { useNavigateWithQuery } from "~/hooks/shared/useNavigateWithQuery";

const inputStateSchema = z.object({
  eventType: z.union([z.literal("offline"), z.literal("online")]),
  // NOTE: オンラインの場合に選択されない場合があるのでnullableかつoptionalにしている
  eventPlaceId: z.string().nullable().optional(),
  selectedDate: z.date().nullable(),
  onnEventSlotDateId: z.string(),
});

export const useAnswerNewInterviewForm = (eventData: OnnEventDataForPortal) => {
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { currentUser } = useCurrentUser();
  const { query } = useQuery();

  const { execAnswerNewInterviewEvent } = useAnswerNewInterviewEvent();
  const navigate = useNavigateWithQuery();
  const selectableEventTypes: ("offline" | "online" | undefined)[] =
    getSelectableEventTypes(eventData);
  // NOTE: 選択可能なイベントタイプが1つの場合は固定してそのイベントタイプのみを表示する
  const isFixedEventTypes = selectableEventTypes.length === 1;
  const defaultOnnEventSlotDateData = eventData.onnEventSlotDateWithNumberOfParticipants.find(
    (v) => v.onnEventSlotDate.id === eventData.onnEventDeterminedDate?.onnEventSlotDateId
  );

  const isPreview = query.get("preview") === "true";
  const { answerNewInterviewEventForPreview } = useAnswerOnnEventForPreview();

  const { control, handleSubmit, setValue, watch, formState, trigger } = useForm<
    Optional<z.infer<typeof inputStateSchema>, "onnEventSlotDateId">
  >({
    defaultValues: {
      eventType: isFixedEventTypes
        ? first(selectableEventTypes)
        : defaultOnnEventSlotDateData?.onnEventSlotDate.eventType || undefined,
      eventPlaceId:
        isFixedEventTypes && first(selectableEventTypes) === "offline"
          ? null
          : defaultOnnEventSlotDateData?.onnEventSlotDate.eventPlaceId || undefined,
      selectedDate: defaultOnnEventSlotDateData?.onnEventSlotDate.from || undefined,
      onnEventSlotDateId: defaultOnnEventSlotDateData?.onnEventSlotDate.id || undefined,
    },
    mode: "onChange",
    resolver: zodResolver(inputStateSchema),
  });

  const submit = handleSubmit(
    async (inputValue: Optional<z.infer<typeof inputStateSchema>, "onnEventSlotDateId">) => {
      setIsLoading(true);

      try {
        if (isPreview) {
          // NOTE: バリデーションはすでに通っているのでundefinedはありえない
          answerNewInterviewEventForPreview(eventData, inputValue.onnEventSlotDateId as string);
        } else {
          await execAnswerNewInterviewEvent({
            onnEventId: eventData.onnEvent.id,
            // NOTE: バリデーションはすでに通っているのでundefinedはありえない
            onnEventSlotDateId: inputValue.onnEventSlotDateId as string,
          });
        }
        enqueueSnackbar("回答しました", { variant: "success" });
        mutateOnnEventForPortal({
          isPreview,
          employeeId: currentUser.id,
          id: eventData.onnEvent.id,
        });
        navigate(`/portal/events/${eventData.onnEvent.id}`);
      } catch (error) {
        enqueueSnackbar("回答に失敗しました", { variant: "error" });
      }

      setIsLoading(false);
    }
  );

  const eventPlaceMap = useMemo(
    () => new Map(eventData.onnEventPlaces.map((v) => [v.id, v])),
    [eventData.onnEventPlaces]
  );
  const onnEventSlotDateMap = useMemo(
    () =>
      new Map(
        eventData.onnEventSlotDateWithNumberOfParticipants.map(({ onnEventSlotDate }) => [
          onnEventSlotDate.id,
          onnEventSlotDate,
        ])
      ),
    [eventData.onnEventSlotDateWithNumberOfParticipants]
  );
  const eventType = watch("eventType");
  const eventPlaceId = watch("eventPlaceId");
  const selectedDate = watch("selectedDate");
  const onnEventSlotDateId = watch("onnEventSlotDateId");
  // NOTE: defaultValuesにundefinedを渡すとformState.isValidとformState.isDirtyの挙動が不安定になるので比較を行う
  const isDisabledSubmit =
    Object.keys(formState.errors).length !== 0 ||
    formState.defaultValues?.onnEventSlotDateId === onnEventSlotDateId;
  const selectedEventPlace = eventPlaceId ? eventPlaceMap.get(eventPlaceId) : undefined;
  const selectedOnnEventSlotDate = onnEventSlotDateId
    ? onnEventSlotDateMap.get(onnEventSlotDateId)
    : undefined;

  const selectableEventPlace = useMemo(() => {
    const onnEventPlaceIds = uniq(
      eventData.onnEventSlotDateWithNumberOfParticipants.flatMap((v) => {
        if (!v.canParticipate()) return [];
        return v.onnEventSlotDate.eventPlaceId ? v.onnEventSlotDate.eventPlaceId : [];
      })
    );
    return eventData.onnEventPlaces.filter((v) => onnEventPlaceIds.includes(v.id));
  }, [eventData.onnEventPlaces, eventData.onnEventSlotDateWithNumberOfParticipants]);

  const selectableSlotDates = useMemo(() => {
    if (!eventType) return [];
    return eventData.onnEventSlotDateWithNumberOfParticipants
      .filter((v) => v.onnEventSlotDate.eventType === eventType)
      .filter((v) => {
        if (!eventPlaceId) return !v.onnEventSlotDate.eventPlaceId; // オンラインの時
        return v.onnEventSlotDate.eventPlaceId === eventPlaceId; // オフラインの時
      })
      .filter(
        (v) =>
          v.onnEventSlotDate.id === eventData.onnEventDeterminedDate?.onnEventSlotDateId ||
          v.canParticipate()
      );
  }, [
    eventData.onnEventDeterminedDate?.onnEventSlotDateId,
    eventData.onnEventSlotDateWithNumberOfParticipants,
    eventPlaceId,
    eventType,
  ]);

  const isDisabledDate = useCallback(
    (date: Date) => {
      if (!eventType) return true;
      const isSelectable = selectableSlotDates.some((v) =>
        isSameDay(v.onnEventSlotDate.from, date)
      );
      return !isSelectable;
    },
    [eventType, selectableSlotDates]
  );

  const selectableSlotHours = useMemo(() => {
    if (!selectedDate) return [];
    return uniqBy(
      sortBy(
        selectableSlotDates
          .filter((v) => isSameDay(v.onnEventSlotDate.from, selectedDate))
          .filter(
            (v) =>
              v.onnEventSlotDate.id === eventData.onnEventDeterminedDate?.onnEventSlotDateId ||
              v.canParticipate()
          ),
        "onnEventSlotDate.from"
      ),
      // NOTE: 同じ日時のものが複数ある場合は1つにまとめる、その時先に生成されていたものを優先して選択させる
      ({ onnEventSlotDate }) =>
        `${format(onnEventSlotDate.from, "yyyy年MM月dd日 HH:mm")}~${format(
          onnEventSlotDate.until,
          "HH:mm"
        )}`
    );
  }, [eventData.onnEventDeterminedDate?.onnEventSlotDateId, selectableSlotDates, selectedDate]);

  return {
    control,
    setValue,
    trigger,
    isDisabledSubmit,
    isLoading,
    isDisabledDate,
    selectedEventPlace,
    selectedOnnEventSlotDate,
    submit,
    eventType,
    selectableEventPlace,
    selectableSlotDates,
    selectableSlotHours,
    isFixedEventTypes,
    selectableEventTypes,
  };
};
