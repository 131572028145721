import { Box, Grid } from "@material-ui/core";
import React, { useState, useCallback, ComponentProps, FC, useMemo } from "react";
import styled from "styled-components";

import { ActivityContent } from "./ActivityContent";

import { ActivityListItemLayout } from "./ActivityListItemLayout";

import { CommentList, DeleteCommentConfirmModal } from "~/components/domains/comments";
import { Paper } from "~/components/uiParts";
import { useDeleteComment } from "~/hooks/comment/useDeleteComment";
import { mixin } from "~/util";

type ActivityContentProps = ComponentProps<typeof ActivityContent>;
type CommentListProps = ComponentProps<typeof CommentList>;

type Props = { commentFocused: boolean; isActive?: boolean } & Pick<
  ActivityContentProps,
  | "transaction"
  | "memoAttachedFilesMap"
  | "memoAttachedFilesLoading"
  | "isScrollTarget"
  | "employee"
  | "onDownloadMemoAttachedFile"
  | "onDeleteMemoAttachedFile"
> &
  Pick<
    CommentListProps,
    | "comments"
    | "commentDisabled"
    | "currentUserId"
    | "suggestedMentionUsers"
    | "loadTransactionComments"
  >;

export const ActivityListItem: FC<Props> = ({
  transaction,
  isScrollTarget,
  comments,
  commentDisabled,
  employee,
  memoAttachedFilesMap,
  memoAttachedFilesLoading,
  suggestedMentionUsers,
  currentUserId,
  commentFocused,
  onDownloadMemoAttachedFile,
  onDeleteMemoAttachedFile,
  loadTransactionComments,
  isActive = false,
}) => {
  const { deleteComment } = useDeleteComment();

  const [deleteTargetCommentId, setDeleteTargetCommentId] = useState("");
  const deleteTargetComment = useMemo(
    () => comments.find(({ id }) => id === deleteTargetCommentId),
    [comments, deleteTargetCommentId]
  );
  const [submitCommentFormFocused, setSubmitCommentFormFocused] = useState(
    commentFocused && isScrollTarget
  );

  const handleClickDeleteCommentButton = useCallback(
    async (commentId: string) => {
      setDeleteTargetCommentId("");
      await deleteComment(commentId, { transactionId: transaction.id });
      loadTransactionComments(transaction.id);
    },
    [deleteComment, loadTransactionComments, transaction.id]
  );

  const handleClickAddCommentButton = useCallback(() => setSubmitCommentFormFocused(true), []);
  const handleBlurSubmitCommentForm = useCallback(() => setSubmitCommentFormFocused(false), []);

  return (
    <ActivityListItemLayout>
      <Grid container alignItems="flex-start">
        <Grid item sm={7}>
          <ActivityContent
            transaction={transaction}
            employee={employee}
            isScrollTarget={isScrollTarget}
            memoAttachedFilesMap={memoAttachedFilesMap}
            memoAttachedFilesLoading={memoAttachedFilesLoading}
            onClickAddCommentButton={handleClickAddCommentButton}
            onDownloadMemoAttachedFile={onDownloadMemoAttachedFile}
            onDeleteMemoAttachedFile={onDeleteMemoAttachedFile}
            isActive={isActive && !commentFocused}
          />
        </Grid>
        <Grid item sm={5}>
          <Box display="flex" mt={6} ml={3} maxHeight={900}>
            <StyledPaper $isActive={isActive && commentFocused}>
              <CommentList
                comments={comments}
                currentUserId={currentUserId}
                transactionId={transaction.id}
                commentDisabled={commentDisabled}
                submitCommentFormFocused={submitCommentFormFocused}
                suggestedMentionUsers={suggestedMentionUsers}
                onBlurSubmitCommentForm={handleBlurSubmitCommentForm}
                onClickDeleteCommentButton={(commentId) => setDeleteTargetCommentId(commentId)}
                loadTransactionComments={loadTransactionComments}
              />
            </StyledPaper>
          </Box>
        </Grid>
        {deleteTargetComment && (
          <DeleteCommentConfirmModal
            open={!!deleteTargetCommentId}
            comment={deleteTargetComment}
            onCancel={() => setDeleteTargetCommentId("")}
            onAccept={handleClickDeleteCommentButton}
          />
        )}
      </Grid>
    </ActivityListItemLayout>
  );
};

const StyledPaper = styled(Paper)<{ $isActive: boolean }>`
  display: flex;
  width: 100%;
  padding: 24px;

  ${(props) => props.$isActive && mixin.outlineAnimation()}
`;
