import { Box } from "@material-ui/core";
import { format } from "date-fns";
import React from "react";

import { Paper, Icon, Typography, Tooltip, FormControlLabel, Checkbox } from "~/components/uiParts";
import { DeadlineDatePicker } from "~/pages/events/_shared/DeadlineDatePicker";

import { ScheduledDatePicker } from "~/pages/events/_shared/ScheduledDatePicker";

type Props = {
  fixedScheduledDate?: Date;
  scheduledDateInput: {
    error: boolean;
    helperText: string;
    isChanged: boolean;
  };
  scheduledDate: Date | null;
  updateScheduledDate: (date: Date | null) => void;
  deadlineDateInput: {
    error: boolean;
    helperText: string;
    isChanged: boolean;
  };
  deadlineDate: Date | null;
  updateDeadlineDate: (date: Date | null) => void;

  canAnswerAfterDeadline: boolean;
  updateCanAnswerAfterDeadline: (value: boolean) => void;
};

export const ScheduleDeliverySettingsPaper = ({
  scheduledDateInput,
  scheduledDate,
  updateScheduledDate,
  deadlineDateInput,
  deadlineDate,
  updateDeadlineDate,
  canAnswerAfterDeadline,
  updateCanAnswerAfterDeadline,
  fixedScheduledDate,
}: Props): JSX.Element => {
  return (
    <Paper>
      <Box display={"flex"} flexDirection={"column"} gridGap={"16px"}>
        <Box display={"flex"} alignItems={"center"} gridGap={"8px"}>
          <Typography variant="body1" bold>
            配信タイミング
          </Typography>
          <Tooltip
            title={"まだ候補者に配信されていない場合、配信タイミングを変更することができます。"}
            placement="top-start"
          >
            <Icon icon="help" size="sm" color="grey" />
          </Tooltip>
        </Box>
        <Box>
          {fixedScheduledDate ? (
            format(fixedScheduledDate, "yyyy/MM/dd HH:mm")
          ) : (
            <ScheduledDatePicker
              date={scheduledDate}
              onChangeDate={updateScheduledDate}
              dateHelperText={scheduledDateInput.helperText}
              dateError={
                scheduledDateInput.isChanged || deadlineDateInput.isChanged
                  ? scheduledDateInput.error
                  : false
              }
            />
          )}
        </Box>
        <Box>
          <Typography variant="body1" bold>
            回答期限
          </Typography>
        </Box>
        <DeadlineDatePicker
          value={deadlineDate}
          onChange={(date: Date | null) => {
            updateDeadlineDate(date);
          }}
          helperText={deadlineDateInput.helperText}
          error={
            scheduledDateInput.isChanged || deadlineDateInput.isChanged
              ? deadlineDateInput.error
              : false
          }
        />
        <Box>
          <FormControlLabel
            onChange={() => updateCanAnswerAfterDeadline(!canAnswerAfterDeadline)}
            control={
              <Checkbox
                checked={canAnswerAfterDeadline}
                name="canAnswerAfterDeadline"
                value={canAnswerAfterDeadline}
              />
            }
            labelPlacement="end"
            label={
              <Box width="100%" display="flex" alignItems="center" gridGap="4px" overflow="hidden">
                <Typography variant="body2" color="textPrimary" noWrap>
                  回答期限を過ぎても回答可能にする
                </Typography>
                <Tooltip
                  title={"回答期限に設定されている日程の2週間後まで回答可能になります。"}
                  placement="top-start"
                >
                  <Icon icon="help" size="sm" color="grey" />
                </Tooltip>
              </Box>
            }
          />
        </Box>
        <Box>
          <Typography variant="caption" color="textSecondary">
            ※選択された日付の23時59分が最終的な締切時間となります。
          </Typography>
        </Box>
      </Box>
    </Paper>
  );
};
