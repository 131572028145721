import { format } from "date-fns";
import { ja } from "date-fns/locale";
import React, { FC } from "react";

import { CellItem, StyleProps } from "./CellItem";

import { Typography } from "~/components/uiParts";

export const UpdatedAtCell: FC<
  {
    updatedAt: Date;
  } & StyleProps
> = ({ updatedAt, ...styleProps }) => {
  return (
    <CellItem {...styleProps}>
      <Typography variant="body2" color="textSecondary">
        {format(updatedAt, "MM/dd(E) H:mm", { locale: ja })}
      </Typography>
    </CellItem>
  );
};
