export const generatePagePath = {
  onnEvent: {
    create: (queryParams?: Record<string, string>) => {
      const queryString = queryParams ? `?${new URLSearchParams(queryParams).toString()}` : "";
      return `/events/new${queryString}`;
    },
    edit: (id: string, queryParams?: Record<string, string>) => {
      const queryString = queryParams ? `?${new URLSearchParams(queryParams).toString()}` : "";
      return `/events/${id}/edit${queryString}`;
    },
    deliverySetting: (id: string, queryParams?: Record<string, string>) => {
      const queryString = queryParams ? `?${new URLSearchParams(queryParams).toString()}` : "";
      return `/events/${id}/delivery_setting${queryString}`;
    },
    setting: (queryParams?: Record<string, string>) => {
      const queryString = queryParams ? `?${new URLSearchParams(queryParams).toString()}` : "";
      return `/events/settings${queryString}`;
    },
    slotDefaultValueSetting: (id: string, queryParams?: Record<string, string>) => {
      const queryString = queryParams ? `?${new URLSearchParams(queryParams).toString()}` : "";
      return `/events/${id}/slot_default_value_setting${queryString}`;
    },
  },
};
