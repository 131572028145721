import { answerNewEventOnBehalfOfNewGraduateRequestSchema } from "@onn/common/domain/api/schema/onnEvent";
import { useCallback } from "react";

import { z } from "zod";

import { useSnackbar } from "../shared";

import { apiClient } from "~/libs";

export const useAnswerNewInterviewEventOnBehalfOfNewGraduate = () => {
  const { enqueueSnackbar } = useSnackbar();
  const execAnswerNewInterviewEventOnBehalfOfNewGraduate = useCallback(
    async (
      params: z.infer<typeof answerNewEventOnBehalfOfNewGraduateRequestSchema>["body"]
    ): Promise<void> => {
      await apiClient.post("/answer_new_interview_event_on_behalf", params);
      enqueueSnackbar("回答を追加しました", { variant: "success" });
    },
    [enqueueSnackbar]
  );

  return { execAnswerNewInterviewEventOnBehalfOfNewGraduate };
};
