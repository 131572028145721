import { zodResolver } from "@hookform/resolvers/zod";
import { OnnEventDeterminedDate, CandidateDate, OnnEvent } from "@onn/common";

import { uniqBy } from "lodash";
import { useEffect } from "react";
import { useForm } from "react-hook-form";

import {
  InputState,
  answerInterviewEventOnBehalfFormSchema,
} from "../answerInterviewEventOnBehalfFormSchema";

import { useHandleSubmit } from "./useHandleSubmit";
import { useSelectableNewGraduates } from "./useSelectableNewGraduates";

import { useSelectableOnnEventSlotDatesForDisplay } from "./useSelectableOnnEventSlotDatesForDisplay";

import { useAdmins, useCurrentUser } from "~/hooks/employee";

import { useOnnEventPlaces } from "~/hooks/onnEventPlace/useOnnEventPlaces";

export const useAnswerNewInterviewEventOnBehalfForm = ({
  onnEventId,
  current: _current,
  onSubmit,
  fixedOption,
  defaultValues,
  slotDefaultValueSetting,
}: {
  current?: {
    onnEventDeterminedDate: OnnEventDeterminedDate;
    candidateDate: CandidateDate;
  };
  onnEventId: string;
  onSubmit: () => void;
  fixedOption?: {
    selectedEmployeeId?: string;
    selectedSlotDateId?: string;
  };
  defaultValues?: {
    onnEventSlotDateId?: string;
  };
  slotDefaultValueSetting: OnnEvent["slotDefaultValueSetting"];
}) => {
  const { currentUser } = useCurrentUser();
  const { data: admins, isLoading: isLoadingAdmins } = useAdmins(currentUser.tenantId);
  const { data: onnEventPlaces = [], isLoading: isLoadingPlaces } = useOnnEventPlaces();
  const adminsMap = new Map((admins || []).map((employee) => [employee.id, employee]));

  const {
    selectableOnnEventSlotDatesForDisplay,
    selectableOnnEventSlotDatesForDisplayMap,
    isLoading: isLoadingSelectableOnnEventSlotDatesForDisplay,
  } = useSelectableOnnEventSlotDatesForDisplay({
    onnEventId,
    includeIdsRegardlessOfFull: [
      defaultValues?.onnEventSlotDateId || [],
      fixedOption?.selectedSlotDateId || [],
    ].flat(),
  });

  const {
    selectableNewGraduates,
    selectableNewGraduatesMap,
    isLoading: isLoadingSelectableNewGraduates,
  } = useSelectableNewGraduates({
    onnEventId,
    selectedEmployeeId: fixedOption?.selectedEmployeeId,
  });

  // 既存の予約枠から選択する時の選択肢
  const selectedOnnEventSlotDateOptions = uniqBy(
    selectableOnnEventSlotDatesForDisplay.map((date) => ({
      value: date.id,
      name: date.getTermText(),
    })),
    "name"
  );

  const form = useForm<InputState>({
    defaultValues: {
      slotSelectionType:
        fixedOption?.selectedSlotDateId || defaultValues?.onnEventSlotDateId
          ? "existed"
          : undefined,
      employeeId: fixedOption?.selectedEmployeeId,
      selectedOnnEventSlotDateId:
        fixedOption?.selectedSlotDateId || defaultValues?.onnEventSlotDateId,
    },
    resolver: zodResolver(answerInterviewEventOnBehalfFormSchema),
  });

  const { handleSubmit, isSubmitting } = useHandleSubmit({ form, onnEventId, onSubmit });

  const hasError = Object.keys(form.formState.errors).length > 0;
  const isSubmitButtonDisabled =
    form.formState.isSubmitting ||
    hasError ||
    isSubmitting ||
    isLoadingSelectableOnnEventSlotDatesForDisplay ||
    isLoadingSelectableNewGraduates;
  const watchSelectedOnnEventSlotDateId = form.watch("selectedOnnEventSlotDateId");
  const watchSlotSelectionType = form.watch("slotSelectionType");
  const selectedOnnEventSlotDate = selectableOnnEventSlotDatesForDisplayMap.get(
    watchSelectedOnnEventSlotDateId
  );

  useEffect(() => {
    if (selectedOnnEventSlotDate && watchSlotSelectionType === "existed") {
      form.setValue("assigneeId", selectedOnnEventSlotDate.assigneeId);
      form.setValue("slotInfo.type", selectedOnnEventSlotDate.eventType);
      form.setValue("slotInfo.online.description", selectedOnnEventSlotDate.description);
      form.setValue("slotInfo.online.url", selectedOnnEventSlotDate.url || undefined);
      form.setValue("slotInfo.offline.description", selectedOnnEventSlotDate.description);
      form.setValue(
        "slotInfo.offline.postCode",
        selectedOnnEventSlotDate.eventAddressPostCode || ""
      );
      form.setValue("slotInfo.offline.address", selectedOnnEventSlotDate.eventAddressText || "");
      form.setValue("slotInfo.offline.location", selectedOnnEventSlotDate.eventPlaceId || "");
    } else {
      form.resetField("assigneeId", { defaultValue: null });
      form.resetField("selectedOnnEventSlotDateId");
      form.resetField("slotInfo.type", { defaultValue: null });
      form.resetField("slotInfo.online.description");
      form.resetField("slotInfo.online.url");
      form.resetField("slotInfo.offline.description");
      form.resetField("slotInfo.offline.postCode");
      form.resetField("slotInfo.offline.address");
      form.resetField("slotInfo.offline.location");
    }
  }, [form, selectedOnnEventSlotDate, watchSlotSelectionType]);

  // NOTE: イベントに紐づく予約枠のデフォルト値をフォームにセットする
  useEffect(() => {
    if (slotDefaultValueSetting?.online?.description) {
      form.setValue("slotInfo.online.description", slotDefaultValueSetting.online.description);
    }
    if (slotDefaultValueSetting?.online?.url) {
      form.setValue("slotInfo.online.url", slotDefaultValueSetting.online.url);
    }
    if (slotDefaultValueSetting?.offline?.description) {
      form.setValue("slotInfo.offline.description", slotDefaultValueSetting.offline.description);
    }
  }, [
    form,
    selectedOnnEventSlotDate,
    slotDefaultValueSetting?.offline?.description,
    slotDefaultValueSetting?.online?.description,
    slotDefaultValueSetting?.online?.url,
    watchSlotSelectionType,
  ]);

  return {
    form,
    isLoadingSelectableNewGraduates,
    isLoadingAdmins,
    isLoadingPlaces,
    isSubmitButtonDisabled,
    isLoadingSelectableOnnEventSlotDatesForDisplay,
    admins,
    adminsMap,
    selectableOnnEventSlotDatesForDisplay,
    selectedOnnEventSlotDate,
    onnEventPlaces,
    selectableNewGraduates,
    selectableNewGraduatesMap,
    handleSubmit,
    isSubmitting,
    selectedOnnEventSlotDateOptions,
  };
};
