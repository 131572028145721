import { isInvalidDate } from "@onn/common";
import { parse } from "date-fns";
import { toNumber } from "lodash";
import { z } from "zod";

export type InputState = z.infer<typeof zodFormSchema>;

export const zodFormSchema = z
  .object({
    assigneeId: z.string().optional().nullable(),
    slotDate: z.date(),
    slotFromTimeString: z.string(),
    slotUntilString: z.string(),
    capacity: z
      .string()
      .refine((v) => !isNaN(toNumber(v)), { message: "半角数字を入力してください" })
      .refine((v) => toNumber(v) > 0, { message: "1以上の半角数字を入力してください" }),
    slotInfo: z
      .object({
        type: z.enum(["online", "offline"]),
        online: z.object({
          description: z.string(),
          url: z.string(),
        }),
        offline: z.object({
          description: z.string(),
          location: z.string(),
          postCode: z.string(),
          address: z.string(),
        }),
      })
      .superRefine((value, ctx) => {
        if (value.type === "online" && value.online.description == "") {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: "開催概要を入力してください",
            path: ["online.description"],
          });
        }
        if (value.type === "offline" && value.offline.description == "") {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: "開催概要を入力してください",
            path: ["offline.description"],
          });
        }
        if (value.type === "offline" && value.offline.location == "") {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: "会場を選択してください",
            path: ["offline.location"],
          });
        }
      }),
  })
  .superRefine((value, ctx) => {
    if (!isInvalidDate(value.slotDate)) {
      const from = parse(value.slotFromTimeString, "HH:mm", value.slotDate);
      const until = parse(value.slotUntilString, "HH:mm", value.slotDate);

      if (from >= until) {
        ctx.addIssue({
          code: z.ZodIssueCode.invalid_date,
          message: "終了日時は開始日時より後に設定してください",
          path: [`slotUntilString`],
        });
      }
    }
  });
