import { Box, Link } from "@material-ui/core";
import { RegistrationInvitationLink, isValidPassword } from "@onn/common";
import React, { useState, useCallback, useMemo } from "react";
import styled from "styled-components";

import { useEmailInput } from "../hooks/useEmailInput";

import { TermsOfUse } from "~/components/domains/account/_shared";
import { TextField, Paper, Button, Typography, PasswordField } from "~/components/uiParts";
import { useSignUp } from "~/hooks/employee";
import { mixin } from "~/util";

const checkName = (
  value: string,
  setValue: (value: string) => void,
  setError?: (error: boolean) => void
): void => {
  setValue(value);

  if (!setError) return;

  if (value.length > 0) {
    setError(false);
  } else {
    setError(true);
  }
};

/**
 * Registration.tsx を踏襲
 */
const usePassword = () => {
  const [password, setPassword] = useState<string>("");

  const passwordError = useMemo(
    () => password.length > 0 && !isValidPassword(password),
    [password]
  );

  return {
    password,
    passwordError,
    passwordHelperText: passwordError
      ? "半角英数字6文字以上で入力してください"
      : "半角英数字で入力してください",
    setPassword,
  };
};

export const ShareInviteByEmailCoreFirstTime = ({
  registrationInvitationLink,
}: {
  registrationInvitationLink: RegistrationInvitationLink;
}) => {
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const {
    error: emailError,
    helperText: emailHelperText,
    isChanged: isChangedEmail,
  } = useEmailInput({ email });
  const [firstNameError, setFirstNameError] = useState<boolean>(false);
  const [lastNameError, setLastNameError] = useState<boolean>(false);
  const { password, passwordError, passwordHelperText, setPassword } = usePassword();
  const [isAgreed, setIsAgreed] = useState(false);
  const { signUpByRegistrationInvitationLink, loading: isSignUpLoading } = useSignUp();

  const isDisableSignUpButton = useMemo(() => {
    return (
      firstName.trim().length === 0 ||
      lastName.trim().length === 0 ||
      passwordError ||
      firstNameError ||
      lastNameError ||
      !isAgreed
    );
  }, [firstName, passwordError, firstNameError, isAgreed, lastName, lastNameError]);

  const handleSubmit = useCallback(async () => {
    if (!registrationInvitationLink) {
      return;
    }

    await signUpByRegistrationInvitationLink({
      registrationInvitationLinkId: registrationInvitationLink.id,
      email,
      firstName,
      lastName,
      password,
    });
  }, [
    email,
    firstName,
    lastName,
    password,
    registrationInvitationLink,
    signUpByRegistrationInvitationLink,
  ]);

  return (
    <StyledContainer maxWidth="500px">
      <StyledPaper>
        <Box mb="40px">
          <StyledForm
            noValidate
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
          >
            <Box display="flex" flexDirection="column">
              <StyledTypographyTitle variant="h2" color="textPrimary" bold>
                アカウント情報入力
              </StyledTypographyTitle>
              <StyledTypographyDescription variant="body1" display="block">
                アカウントに必要な情報を入力してください。
              </StyledTypographyDescription>
              <StyledTypographySectionTitle variant="body2" gutterBottom>
                氏名
              </StyledTypographySectionTitle>
              <StyledBoxSection display="flex" gridColumnGap="16px">
                <TextField
                  fullWidth
                  error={lastNameError}
                  placeholder="姓"
                  label={null}
                  name="lname"
                  variant="outlined"
                  type="text"
                  size="medium"
                  helperText={lastNameError && "姓を入力してください"}
                  onChange={(event) => checkName(event.target.value, setLastName, setLastNameError)}
                />
                <TextField
                  fullWidth
                  error={firstNameError}
                  placeholder="名"
                  label={null}
                  name="fname"
                  variant="outlined"
                  type="text"
                  size="medium"
                  helperText={firstNameError && "名前を入力してください"}
                  onChange={(event) =>
                    checkName(event.target.value, setFirstName, setFirstNameError)
                  }
                />
              </StyledBoxSection>
              <StyledBoxSection>
                <StyledTypographySectionTitle variant="body2" gutterBottom>
                  メールアドレス
                </StyledTypographySectionTitle>
                <TextField
                  placeholder="メールアドレス"
                  label={null}
                  fullWidth
                  error={isChangedEmail && emailError}
                  name="email"
                  variant="outlined"
                  type="text"
                  size="medium"
                  helperText={isChangedEmail && emailHelperText}
                  onChange={(event) => setEmail(event.target.value)}
                />
              </StyledBoxSection>
              <StyledBoxSection>
                <StyledTypographySectionTitle variant="body2" gutterBottom>
                  パスワード
                </StyledTypographySectionTitle>
                <PasswordField
                  placeholder="パスワード"
                  label={null}
                  error={passwordError}
                  fullWidth
                  helperText={passwordHelperText}
                  value={password}
                  onChange={(inputValue: string): void => setPassword(inputValue)}
                />
              </StyledBoxSection>
            </Box>

            <StyledTypographyMinusMargin variant="body1" color="textPrimary" bold gutterBottom>
              情報の取扱いに関する同意
            </StyledTypographyMinusMargin>
            <TermsOfUse isAgreed={isAgreed} isNewGrad onChange={() => setIsAgreed((prv) => !prv)} />
            <Box
              textAlign="center"
              color="textSecondary"
              lineHeight="24px"
              mt={5}
              mb={4}
              pl={3}
              pr={3}
            >
              <Typography variant="caption">アカウントを作成することにより、Onnの</Typography>
              <Link
                href="https://onn-hr.com/privacy_policy"
                underline="always"
                target="_blank"
                color="textSecondary"
              >
                <Typography variant="caption">プライバシーポリシー</Typography>
              </Link>
              <Typography variant="caption">に同意するものとします。</Typography>
            </Box>
            <Box textAlign="center" mt={2} mb={2}>
              <Button
                fullWidth
                type="submit"
                variant="contained"
                borderRadius="circle"
                color="primary"
                isLoading={isSignUpLoading}
                disabled={isDisableSignUpButton || isSignUpLoading}
              >
                {isSignUpLoading ? "送信中" : "登録"}
              </Button>
            </Box>
          </StyledForm>
        </Box>
      </StyledPaper>
    </StyledContainer>
  );
};

const StyledContainer = styled(Box)`
  padding: 64px 24px;
  width: 100%;

  ${mixin.portalSp`
    padding: 40px 0px;
    padding-top: 0px;
  `}
`;

const StyledPaper = styled(Paper)`
  &.MuiPaper-root {
    ${mixin.portalSp`
      box-shadow: none;
      `}
  }
`;

const StyledForm = styled.form`
  width: 100%;
  font-size: 12px;
  color: ${(props) => props.theme.palette.text.secondary};
`;

const StyledTypographyTitle = styled(Typography)`
  &.MuiTypography-root {
    margin-bottom: 24px;
  }
`;

const StyledTypographyDescription = styled(Typography)`
  &.MuiTypography-root {
    margin-bottom: 48px;
  }
`;

const StyledTypographySectionTitle = styled(Typography)`
  &.MuiTypography-root {
    margin-bottom: 16px;
    display: block;
  }
`;

const StyledBoxSection = styled(Box)`
  margin-bottom: 32px;
`;

// TODO: CheckTerms の mt が消えたら消す
const StyledTypographyMinusMargin = styled(Typography)`
  &.MuiTypography-root {
    margin-bottom: -24px;
    display: block;
  }
`;
