import { Box, Menu, MenuItem } from "@material-ui/core";
import { Employee, OnnEvent } from "@onn/common";

import React, { FC, MouseEvent, useCallback, useMemo, useState } from "react";
import { CSVLink } from "react-csv";
import { AutoSizer, MultiGrid } from "react-virtualized";
import styled from "styled-components";

import { useAnswerResultTab } from "../../hooks/OnnEventAnswerResultTab/useAnswerResultTab";

import { CandidateDateFilter } from "./CandidateDateFilter/CandidateDateFilter";
import { StatusFilter } from "./StatusFilter/StatusFilter";
import { useAnswerResultTable } from "./useAnswerResultTable";

import {
  Button,
  IconButton,
  Loading,
  Typography,
  TableActionsLayoutWithFiltersAndSearchForm,
  Paper,
} from "~/components/uiParts";

import { SearchForm } from "~/components/uiParts/SearchForm";

import { useOutputResultAnswerCsv } from "~/hooks/onnEvent";

type Props = {
  currentUser: Employee;
  onnEvent: OnnEvent;
  onClickBulkRemindButton: (tenantId: string, eventId: string) => void;
};

export const TabPage: FC<Props> = ({ currentUser, onnEvent, onClickBulkRemindButton }) => {
  const {
    selectedOnnEventAnswers,
    setSearchValue,
    determinedDates,
    isLoading,
    setSelectedStatusTypes,
    selectedStatusTypes,
    candidateDatesWithNumberOfParticipants,
    numberOfDistribution,
    numberOfResponses,
    employeeIdToLatestEmployeeActiveLogMap,
    selectedCandidateDateIds,
    setSelectedCandidateDateIds,
  } = useAnswerResultTab({
    onnEventId: onnEvent.id,
  });

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const openMenu = useCallback((event: MouseEvent<HTMLElement>) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  }, []);

  const closeMenu = useCallback((event: MouseEvent<HTMLElement>) => {
    event.preventDefault();
    setAnchorEl(null);
  }, []);

  const handleClickSubmitBulkRemind = useCallback(
    (event: MouseEvent<HTMLElement>) => {
      closeMenu(event);
      onClickBulkRemindButton(onnEvent.tenantId, onnEvent.id);
    },
    [onnEvent.tenantId, onnEvent.id, closeMenu, onClickBulkRemindButton]
  );

  const {
    cellRenderer,
    rowCount,
    columnCount,
    rowHeight,
    columnWidth,
    paperHeight,
    multiGridHight,
    isExistBodyRows,
  } = useAnswerResultTable({
    onnEvent,
    employeeIdToDeterminedDate: new Map(
      (determinedDates || []).map((determinedDate) => [determinedDate.employeeId, determinedDate])
    ),
    candidateDateList: candidateDatesWithNumberOfParticipants || [],
    onnEventAnswers: selectedOnnEventAnswers,
    hasCapacity: onnEvent.hasCapacity(),
    numberOfDistribution,
    numberOfResponses,
    employeeIdToLatestEmployeeActiveLogMap,
    selectedCandidateDateIds,
  });

  const {
    csvData,
    filename,
    isLoading: isCsvDataLoading,
  } = useOutputResultAnswerCsv({
    onnEvent,
    onnEventAnswers: selectedOnnEventAnswers,
    employeeIdToLatestEmployeeActiveLogMap,
    selectedCandidateDateIds,
  });

  const firstFilter = useMemo(
    () => (
      <Box display="flex" flexDirection="column" gridRowGap="8px">
        <Typography variant="body2" bold color="textSecondary">
          ステータス
        </Typography>
        <Box>
          <StatusFilter
            selectedStatusTypes={selectedStatusTypes}
            onSetSelectedStatusTypes={setSelectedStatusTypes}
          />
        </Box>
      </Box>
    ),
    [selectedStatusTypes, setSelectedStatusTypes]
  );

  const secondFilter = useMemo(
    () => (
      <Box display="flex" flexDirection="column" gridRowGap="8px">
        <Typography variant="body2" bold color="textSecondary">
          開催日時
        </Typography>
        <Box>
          <CandidateDateFilter
            candidateDates={candidateDatesWithNumberOfParticipants || []}
            selectedCandidateDateIds={selectedCandidateDateIds}
            onSetSelectedCandidateDateIds={setSelectedCandidateDateIds}
          />
        </Box>
      </Box>
    ),

    [candidateDatesWithNumberOfParticipants, selectedCandidateDateIds, setSelectedCandidateDateIds]
  );

  /**
   * 全日程で定員が満員かどうか判定する
   */
  const isCapacityFull = useMemo(
    () => (candidateDatesWithNumberOfParticipants ?? []).every((v) => !v.canParticipate()),
    [candidateDatesWithNumberOfParticipants]
  );

  if (isLoading) {
    return <Loading size="large" />;
  }

  return (
    <>
      <Box
        my="36px"
        display="flex"
        alignItems="flex-end"
        justifyContent="space-between"
        gridGap={24}
      >
        <TableActionsLayoutWithFiltersAndSearchForm
          firstFilter={firstFilter}
          secondFilter={secondFilter}
          searchForm={
            <SearchForm
              onSearchValue={setSearchValue}
              placeholder="ユーザー名・メールアドレスで検索"
              variant="standard"
              fullWidth
            />
          }
        />
        {isCsvDataLoading ? (
          <Box height={32}>
            <Button color="primary" variant="outlined" borderRadius="regular" fullWidth>
              {/* NOTE: ローディング中とローディングでない時にボタンの幅が同じになるように調整。ボタンを押してデータをfetchする処理にした後に修正する。 */}
              <Box width="113.58px">
                <Loading size="small" />
              </Box>
            </Button>
          </Box>
        ) : (
          <StyledCSVLink data={csvData} filename={filename}>
            <Box height={40}>
              <Button
                color="primary"
                variant="outlined"
                borderRadius="regular"
                fullWidth
                fullHeight
              >
                CSVダウンロード
              </Button>
            </Box>
          </StyledCSVLink>
        )}
        {currentUser.isAdmin() && (
          <>
            <IconButton icon="menuVert" onClick={openMenu} />
            <Menu
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={closeMenu}
              getContentAnchorEl={null}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <MenuItem onClick={handleClickSubmitBulkRemind} disabled={isCapacityFull}>
                <Typography variant="body2">一括リマインド送信</Typography>
              </MenuItem>
            </Menu>
          </>
        )}
      </Box>
      <StyledPaper height={paperHeight}>
        <AutoSizer disableHeight>
          {({ width }) => {
            return (
              <StyledBox width={width}>
                <MultiGrid
                  cellRenderer={cellRenderer}
                  rowHeight={rowHeight}
                  columnWidth={columnWidth}
                  columnCount={columnCount}
                  rowCount={rowCount}
                  fixedColumnCount={1}
                  fixedRowCount={1}
                  enableFixedColumnScroll
                  height={multiGridHight}
                  width={width}
                />
              </StyledBox>
            );
          }}
        </AutoSizer>
        {!isExistBodyRows && (
          <Box
            width="100%"
            height="100%"
            display="flex"
            alignItems="center"
            justifyContent="center"
            pb="40px" // NOTE: 上下中央に配置するためにpaddingを設定
          >
            <Typography variant="body2" align="center" color="textSecondary">
              結果が見つかりませんでした。
            </Typography>
          </Box>
        )}
      </StyledPaper>
    </>
  );
};

const StyledCSVLink = styled(CSVLink)`
  text-decoration: none;
`;

const StyledPaper = styled(Paper)<{ height: number }>`
  padding: 40px;
  height: ${(props) => props.height}px;
`;

const StyledBox = styled(Box)`
  .ReactVirtualized__Grid:first-child {
    -ms-overflow-style: none;
    ::-webkit-scrollbar {
      display: none;
    }
  }
`;
