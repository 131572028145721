import { Menu, MenuItem } from "@material-ui/core";
import React, { ComponentProps, useCallback, useState, MouseEvent, FC } from "react";

import { IconButton, Typography } from "~/components/uiParts";

type Props = {
  id: string;
  onClickEdit: () => void;
  onClickAssign: () => void;
  onClickDelete: () => void;
};
export const ContextMenu: FC<Props> = ({ id, onClickEdit, onClickAssign, onClickDelete }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleClickButton = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const handleClickMenuItem = useCallback(
    (
      callback:
        | ComponentProps<typeof ContextMenu>["onClickEdit"]
        | ComponentProps<typeof ContextMenu>["onClickDelete"]
    ) => {
      callback();
      setAnchorEl(null);
    },
    []
  );

  return (
    <>
      <IconButton onClick={handleClickButton} icon="menuVert" />
      <Menu
        id={id}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem key={`${id}-edit`} onClick={() => handleClickMenuItem(onClickEdit)}>
          <Typography variant="body2">編集</Typography>
        </MenuItem>
        <MenuItem key={`${id}-assign`} onClick={() => handleClickMenuItem(onClickAssign)}>
          <Typography variant="body2">一括付与</Typography>
        </MenuItem>
        <MenuItem key={`${id}-delete`} onClick={() => handleClickMenuItem(onClickDelete)}>
          <Typography variant="body2">削除</Typography>
        </MenuItem>
      </Menu>
    </>
  );
};
