import { APISchema, OnnTask, OnnFormTask } from "@onn/common";
import useSWR, { SWRResponse } from "swr";

import { apiClient } from "~/libs";

export const useOnnTask = ({ onnTaskId }: { onnTaskId?: string }): SWRResponse<OnnTask, Error> => {
  const asyncJob = async ({ onnTaskId }: { onnTaskId: string }): Promise<OnnTask> => {
    const queryParam: APISchema["/get_onn_task"]["GET"]["query"] = {
      id: onnTaskId,
    };
    const response = await apiClient.get(`/get_onn_task`, queryParam);
    switch (response.data.type) {
      case "FORM_TASK": {
        return new OnnFormTask(response.data);
      }
      default: {
        throw new Error(`${response.data.type} is not implemented yet`);
      }
    }
  };

  return useSWR(onnTaskId ? generateOnnTaskKey(onnTaskId) : null, asyncJob);
};

const generateOnnTaskKey = (onnTaskId: string) => {
  return { name: `onn-task-${onnTaskId}`, onnTaskId };
};
