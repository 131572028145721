import { Chip as MuiChip } from "@material-ui/core";
import { OnnEventAnswerForDisplay } from "@onn/common/domain/OnnEvent/OnnEventAnswer";
import React, { FC } from "react";
import styled from "styled-components";

import { CellItem, StyleProps, Typography } from "~/components/uiParts";

import theme from "~/config/theme";

type Props = {
  statusForDisplay: ReturnType<OnnEventAnswerForDisplay["getStatusForDisplay"]>;
} & StyleProps;

export const StatusCell: FC<Props> = ({ statusForDisplay, style, ...styleProps }) => {
  switch (statusForDisplay) {
    case "rejected":
      return (
        <CellItem {...styleProps} style={{ ...style, background: theme.palette.grey[50] }}>
          <Typography variant="body2" color="textSecondary">
            不採用
          </Typography>
        </CellItem>
      );
    case "withdrew":
      return (
        <CellItem {...styleProps} style={{ ...style, background: theme.palette.grey[50] }}>
          <Typography variant="body2" color="textSecondary">
            辞退
          </Typography>
        </CellItem>
      );
    case "adjusted":
      return (
        <CellItem {...styleProps} style={{ ...style, background: theme.palette.primary.light }}>
          <StyledChip color="primary" label={"調整済み"} />
        </CellItem>
      );
    case "adjusting":
      return (
        <CellItem {...styleProps} style={{ ...style }}>
          <Typography variant="body2" color="secondary">
            未設定
          </Typography>
        </CellItem>
      );
  }
};

const StyledChip = styled(MuiChip)`
  &.MuiChip-root {
    height: 32px;
    font-size: 14px;
    background-color: white;
    border: 1px solid ${(props) => props.theme.palette.primary.main};
    color: ${(props) => props.theme.palette.primary.main};
    font-weight: 700;
  }
`;
