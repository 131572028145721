import { Employee } from "@onn/common";
import React, { useContext } from "react";
import styled from "styled-components";

import { RecruitmentStatusesContext } from "~/components/providers/RecruitmentStatusProvider";

import { FilterButton } from "~/components/uiParts";

type Props = {
  allNewGraduates: ReadonlyArray<Employee>;
  newGraduatesByRecruitmentStatuses: Record<string, ReadonlyArray<Employee>>;
  selectedRecruitmentStatusIdOrNull: string | null;
  onSelected: (recruitmentStatusId: string | null) => void;
};

export function NewGraduateRequirementStatusFilter({
  allNewGraduates,
  newGraduatesByRecruitmentStatuses,
  selectedRecruitmentStatusIdOrNull,
  onSelected,
}: Props) {
  const { recruitmentStatuses } = useContext(RecruitmentStatusesContext);

  return (
    <Root>
      <FilterButton
        title="全て"
        totalCount={allNewGraduates.length}
        isSelected={selectedRecruitmentStatusIdOrNull === null}
        onClick={() => onSelected(null)}
      />
      <RecruitmentStatusFilterButtonContainer>
        {recruitmentStatuses.map((recruitmentStatus) => {
          return (
            <FilterButton
              key={recruitmentStatus.id}
              title={recruitmentStatus.label}
              totalCount={newGraduatesByRecruitmentStatuses[recruitmentStatus.id]?.length || 0}
              isSelected={selectedRecruitmentStatusIdOrNull === recruitmentStatus.id}
              onClick={() => onSelected(recruitmentStatus.id)}
            />
          );
        })}
      </RecruitmentStatusFilterButtonContainer>
    </Root>
  );
}

const Root = styled.div`
  display: flex;
  column-gap: 40px;
`;

const RecruitmentStatusFilterButtonContainer = styled.div`
  display: flex;
  column-gap: 16px;
`;
