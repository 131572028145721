import { sortBy } from "lodash";

import { NewGraduateToDisplay } from "../../Employee";
import { OnnEventSlotDate } from "../OnnEventSlotDate";

import { OnnEventAnswer } from "./OnnEventAnswer";

export class OnnEventAnswerForDisplay extends OnnEventAnswer {
  selectedOnnEventSlotDate?: OnnEventSlotDate;
  newGraduateToDisplay: NewGraduateToDisplay;
  assigneeInfo?: {
    id: string;
    name: string;
    iconUrl?: string;
  };
  constructor(init: ExcludeMethods<OnnEventAnswerForDisplay>) {
    super(init);
    this.selectedOnnEventSlotDate = init.selectedOnnEventSlotDate;
    this.assigneeInfo = init.assigneeInfo;
    this.newGraduateToDisplay = init.newGraduateToDisplay;
  }

  public getStatusForDisplay(): "withdrew" | "rejected" | "adjusting" | "adjusted" {
    if (this.newGraduateToDisplay.recruitmentStatusType === "withdrew") return "withdrew";
    if (this.newGraduateToDisplay.recruitmentStatusType === "rejected") return "rejected";
    if (!this.selectedOnnEventSlotDate) return "adjusting";
    return "adjusted";
  }

  /**
   * 第一ソート：アクティブ➝非アクティブ（辞退・不採用）
   * 第二ソート：開催日時順（未設定は最後尾）
   * 第三ソート：配信対象追加順
   */
  static sortForDisplay(
    onnEventAnswersForDisplay: OnnEventAnswerForDisplay[]
  ): OnnEventAnswerForDisplay[] {
    const sortByRejectedOrWithdrew = (onnEventAnswerForDisplay: OnnEventAnswerForDisplay) => {
      return onnEventAnswerForDisplay.newGraduateToDisplay.isRejectedOrWithdrew();
    };
    const sortBySelectedOnnEventSlotDateFromDate = (
      onnEventAnswerForDisplay: OnnEventAnswerForDisplay
    ) => {
      // 未設定は最後尾にするためInfinityを返す
      if (!onnEventAnswerForDisplay.selectedOnnEventSlotDate) return Infinity;
      return onnEventAnswerForDisplay.selectedOnnEventSlotDate.from;
    };
    const sortByCreatedAtDate = (onnEventAnswerForDisplay: OnnEventAnswerForDisplay) => {
      return onnEventAnswerForDisplay.createdAt;
    };

    return sortBy(onnEventAnswersForDisplay, [
      sortByRejectedOrWithdrew,
      sortBySelectedOnnEventSlotDateFromDate,
      sortByCreatedAtDate,
    ]);
  }
}
