import { Box } from "@material-ui/core";
import React, { FC } from "react";

import { useNavigate } from "react-router-dom";

import { AnnouncementsListTable } from "./AnnouncementsListTable/AnnouncementsListTable";

import { Button, Icon, Loading } from "~/components/uiParts";
import { useAnnouncements } from "~/hooks/announcement";
import { useCurrentUser } from "~/hooks/employee";

export const AnnouncementTab: FC = () => {
  const navigate = useNavigate();
  const { currentUser } = useCurrentUser();
  const { data: announcements = [], isLoading } = useAnnouncements();
  // const [selectedEmployeeIds, setSelectedEmployeeIds] = useState<string[]>([]);
  // const selectableEmployeeIds = useMemo(() => {
  //   return announcements.map((announcement) => announcement.creatorId);
  // }, [announcements]);

  if (isLoading) <Loading size="large" fullHeight />;

  return (
    <Box pt={5} pb={4}>
      <Box>
        <Box mb="24px" display="flex">
          <Box mb="8px" height="72px" flex="0 1 240px" flexGrow={1}>
            {/* <Box height="30px">
                <Typography variant="body2" bold color="textSecondary">
                  作成者
                </Typography>
              </Box>
              <Box height="46px">
                <EmployeeFilter
                  selectedEmployeeIds={selectedEmployeeIds}
                  selectableEmployeeIds={selectableEmployeeIds}
                  onChange={(newSelectedEmployeeIds) =>
                    setSelectedEmployeeIds(newSelectedEmployeeIds)
                  }
                />
              </Box> */}
          </Box>

          <Box display="flex" alignItems="flex-end" gridGap="32px">
            {/* <Box pb="8px">
                <SearchForm
                  variant="standard"
                  onSearchValue={() => alert("検索")}
                  placeholder="タイトルで検索"
                />
              </Box> */}
            {currentUser.isAdmin() && (
              <Button
                color="primary"
                variant="outlined"
                borderRadius="regular"
                startIcon={<Icon icon="add" color="primary" size="md" />}
                onClick={() => navigate("/announcements/new")}
              >
                新規作成
              </Button>
            )}
          </Box>
        </Box>
      </Box>
      <AnnouncementsListTable announcements={announcements} isLoading={isLoading} />
    </Box>
  );
};
