import { Box } from "@material-ui/core";
import { NewGraduateToDisplay, Employee } from "@onn/common";
import { IEmployeeInformationValue } from "@onn/common/domain/EmployeeInformation/schema";
import React, { FC, useCallback, useMemo, useState } from "react";

import { ErrorMessageContent } from "./ErrorMessageContent";

import { useCheckInputValues } from "./useCheckInputValues";
import { useConvertCSVtoPlainObject } from "./useConvertCSVtoPlainObject";
import { useGetErrorMessage } from "./useGetErrorMessage";

import { Button, FilePicker, Icon, Typography } from "~/components/uiParts";
import { Payload } from "~/components/uiParts/FilePicker/FilePicker";
import { useCurrentUser } from "~/hooks/employee";
import { useNotifyOperationLog, usePrompt, useSnackbar } from "~/hooks/shared";

type Props = {
  allNewGraduates: NewGraduateToDisplay[];
  onClickCancellation: () => void;
  onClickUploadButton: (
    inputValues: {
      uniqueId: string;
      employeeName: Partial<Pick<Employee, "lastName" | "firstName">>;
      employeeInformationValue: IEmployeeInformationValue;
    }[]
  ) => Promise<void>;
  onUploadNewHires: () => void;
};

export const UploadFileStepContent: FC<Props> = ({
  allNewGraduates,
  onClickCancellation,
  onClickUploadButton,
  onUploadNewHires,
}) => {
  const [loadedFileName, setLoadedFileName] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const { convertCSVtoPlainObject } = useConvertCSVtoPlainObject();
  const { currentUser } = useCurrentUser();
  const { checkInputValues } = useCheckInputValues();
  const { getErrorMessage } = useGetErrorMessage();
  const { notifyOperationLog, operationLog } = useNotifyOperationLog();

  const [loadedInputs, setLoadedInputs] = useState<
    {
      uniqueId: string;
      employeeName: Partial<Pick<Employee, "lastName" | "firstName">>;
      employeeInformationValue: IEmployeeInformationValue;
    }[]
  >([]);
  const isReadyToUpload = loadedInputs.length !== 0;
  const [errorMessage, setErrorMessage] = useState("");
  const [isUploading, setIsUploading] = useState(false);
  usePrompt("候補者の招待が正常に完了しない場合があります", isUploading);

  const handleInputFile = useCallback(
    async (payload: Payload) => {
      if (payload.status === "error") {
        enqueueSnackbar(payload.message, { variant: "error" });
        return;
      }

      const reader = new FileReader();
      const file = payload.files[0] as (typeof payload.files)[number];
      reader.readAsText(file);

      await new Promise((resolve) => {
        reader.onload = () => {
          resolve(reader.result);
        };
      });

      if (typeof reader.result !== "string") {
        enqueueSnackbar(
          "エラーが発生しました。もう一度お試しいただけますでしょうか。もし解決しない場合はお問い合わせください。",
          { variant: "error" }
        );
        return;
      }

      const parseResult = convertCSVtoPlainObject(reader.result);

      if (parseResult.isFailure()) {
        enqueueSnackbar(parseResult.error.message, { variant: "error" });
        return;
      }

      setLoadedFileName((payload.files[0] as (typeof payload.files)[number]).name);
    },
    [convertCSVtoPlainObject, enqueueSnackbar]
  );

  const validateAfterParsing = useCallback(
    (files: File[]) => {
      const reader = new FileReader();
      const file = files[0] as (typeof files)[number];
      reader.readAsText(file);

      return new Promise<{ isValid: boolean; errorMessage?: string }>((resolve, reject) => {
        reader.onload = async () => {
          if (typeof reader.result !== "string") {
            enqueueSnackbar(
              "エラーが発生しました。もう一度お試しいただけますでしょうか。もし解決しない場合はお問い合わせください。",
              { variant: "error" }
            );
            return reject();
          }
          const parseResult = convertCSVtoPlainObject(reader.result);
          if (parseResult.isFailure()) {
            enqueueSnackbar(parseResult.error.message, { variant: "error" });
            return reject();
          }

          const { value } = parseResult;

          const result = checkInputValues(allNewGraduates, value);
          if (result.errorMap.size > 0) {
            const errorMessage = getErrorMessage(result.errorMap, {
              inputRowSize: parseResult.value.length,
            });
            setErrorMessage(errorMessage);
            notifyOperationLog(
              operationLog.notifyCSVUploadError(currentUser, "候補者情報一括追加", errorMessage)
            );

            return resolve({ isValid: false, errorMessage: "エラーが発生しました。" });
          }
          setLoadedInputs(result.validatedInputValues);
          setErrorMessage("");
          return resolve({ isValid: true });
        };
      });
    },
    [
      convertCSVtoPlainObject,
      checkInputValues,
      allNewGraduates,
      enqueueSnackbar,
      getErrorMessage,
      notifyOperationLog,
      operationLog,
      currentUser,
    ]
  );

  const handleClickUploadButton = useCallback(async () => {
    setIsUploading(true);

    onClickUploadButton(loadedInputs)
      .then(() => {
        onUploadNewHires();
      })
      .finally(() => {
        setIsUploading(false);
      });
  }, [loadedInputs, onClickUploadButton, onUploadNewHires]);

  const fileLabel = useMemo(() => {
    if (loadedFileName) return loadedFileName;
    return "ファイルが選択されていません";
  }, [loadedFileName]);

  return (
    <>
      <Typography>準備したCSVファイルを選択し、アップロードしてください。</Typography>
      {errorMessage && (
        <Box mt={4}>
          <ErrorMessageContent errorMessage={errorMessage} />
        </Box>
      )}
      <Box height={48} mt={4} mb={5} gridGap={16} display="flex" alignItems="center">
        <FilePicker
          accepts={["csv"]}
          inputAccept={".csv"}
          multiple={false}
          onChange={handleInputFile}
          validateAfterParsing={validateAfterParsing}
        >
          <Box height={48}>
            <Button
              startIcon={<Icon icon="clip" size="sm" color="primary" />}
              color={"primary"}
              borderRadius={"regular"}
              variant={"outlined"}
              fullHeight
            >
              ファイルを選択
            </Button>
          </Box>
        </FilePicker>
        <Typography variant="caption">{fileLabel}</Typography>
      </Box>
      <Box pl={5} pr={5} display="flex" alignItems="center" justifyContent="center">
        <Box width={"240px"} mr={3}>
          <Button
            fullWidth={true}
            color={"default"}
            borderRadius={"circle"}
            variant={"outlined"}
            onClick={onClickCancellation}
          >
            キャンセル
          </Button>
        </Box>
        <Box width={"240px"}>
          <Button
            fullWidth={true}
            color={"primary"}
            borderRadius={"circle"}
            variant={"contained"}
            onClick={handleClickUploadButton}
            disabled={!isReadyToUpload}
            isLoading={isUploading}
          >
            アップロード
          </Button>
        </Box>
      </Box>
    </>
  );
};
