import { Menu, MenuItem } from "@material-ui/core";
import React, { FC, MouseEvent, memo, useCallback, useState } from "react";

type MenuItemOption =
  | {
      onClick: () => void;
      text: string;
    }
  | {
      renderCustomComponent: (closeMenu: () => void) => React.ReactNode;
    };

// NOTE: 必要になったら渡せる項目を増やす
type Props = {
  renderButton: (openMenu: (event: MouseEvent<HTMLElement>) => void) => React.ReactNode;
  menuItemOptions: MenuItemOption[];
};

export const UncontrolledMenu: FC<Props> = memo(({ renderButton, menuItemOptions }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const openMenu = useCallback((event: MouseEvent<HTMLElement>) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  }, []);

  const closeMenu = useCallback((event: MouseEvent<HTMLElement>) => {
    event.preventDefault();
    setAnchorEl(null);
  }, []);

  return (
    <>
      {renderButton(openMenu)}
      <Menu
        anchorEl={anchorEl}
        keepMounted={false}
        open={Boolean(anchorEl)}
        onClose={closeMenu}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {menuItemOptions.map((menuItemOption, index) => {
          if ("renderCustomComponent" in menuItemOption) {
            return menuItemOption.renderCustomComponent(() => setAnchorEl(null));
          }
          return (
            <MenuItem
              key={index}
              onClick={(e) => {
                closeMenu(e);
                menuItemOption.onClick();
              }}
            >
              {menuItemOption.text}
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
});
