import { zodResolver } from "@hookform/resolvers/zod";
import { prefectures } from "@onn/common";
import { addressSchema } from "@onn/common/domain/EmployeeInformation/schema";
import React, { FC } from "react";
import { Controller, useForm } from "react-hook-form";

import styled from "styled-components";
import { z } from "zod";

import { InformationValueEditor } from "../InformationValueEditor";

import { SelectForm, TextField, Typography } from "~/components/uiParts";

type Props = {
  onSave: (address: z.infer<typeof addressSchema>) => void;
  onCancel: () => void;
  data?: z.infer<typeof addressSchema>;
};

export const AddressEditor: FC<Props> = ({ onSave, onCancel, data }) => {
  const { handleSubmit, formState, control } = useForm<z.infer<typeof addressSchema>>({
    defaultValues: {
      ...data,
    },
    mode: "onChange",
    resolver: zodResolver(addressSchema),
  });

  return (
    <InformationValueEditor
      form={
        <form
          style={{ display: "flex", flexDirection: "column", rowGap: 16 }}
          onSubmit={handleSubmit(onSave)}
        >
          <Controller
            name="postalCode"
            control={control}
            render={({ field, fieldState }) => (
              <div style={{ display: "flex", alignItems: "center", columnGap: 16 }}>
                <div style={{ width: 120 }}>
                  <Typography variant="body2">郵便番号</Typography>
                </div>
                <StyledTextField
                  placeholder="000-0000"
                  variant="outlined"
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                  fullWidth
                  {...field}
                />
              </div>
            )}
          />
          <Controller
            name="prefecture"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <div style={{ display: "flex", alignItems: "center", columnGap: 16 }}>
                <div style={{ width: 120 }}>
                  <Typography variant="body2">都道府県</Typography>
                </div>
                <SelectForm
                  fullWidth
                  selected={field.value || null}
                  menuItems={prefectures.map((v) => {
                    return { name: v, value: v };
                  })}
                  errorBorder={!!error}
                  errorText={error?.message}
                  onChange={field.onChange}
                />
              </div>
            )}
          />
          <Controller
            name="city"
            control={control}
            render={({ field, fieldState }) => (
              <div style={{ display: "flex", alignItems: "center", columnGap: 16 }}>
                <div style={{ width: 120 }}>
                  <Typography variant="body2">市区町村</Typography>
                </div>
                <StyledTextField
                  placeholder="千代田区"
                  variant="outlined"
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                  fullWidth
                  {...field}
                />
              </div>
            )}
          />
          <Controller
            name="addressLine1"
            control={control}
            render={({ field, fieldState }) => (
              <div style={{ display: "flex", alignItems: "center", columnGap: 16 }}>
                <div style={{ width: 120 }}>
                  <Typography variant="body2">番地</Typography>
                </div>
                <StyledTextField
                  placeholder="千代田1-1"
                  variant="outlined"
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                  fullWidth
                  {...field}
                />
              </div>
            )}
          />
          <Controller
            name="addressLine2"
            control={control}
            render={({ field, fieldState }) => (
              <div style={{ display: "flex", alignItems: "center", columnGap: 16 }}>
                <div style={{ width: 120 }}>
                  <Typography variant="body2">建物名</Typography>
                </div>
                <StyledTextField
                  placeholder="千代田ビル"
                  variant="outlined"
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                  fullWidth
                  {...field}
                />
              </div>
            )}
          />
        </form>
      }
      onSave={handleSubmit(onSave)}
      onCancel={onCancel}
      disabled={!formState.isValid || !formState.isDirty}
    />
  );
};

const StyledTextField = styled(TextField)`
  .MuiInputBase-input {
    font-size: 14px;
    height: 24px;
    padding: 8px 16px;
  }
`;
