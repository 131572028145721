import { OnnFormTaskAnswer, NewGraduateToDisplay } from "@onn/common";

import { useGenerateDateRow } from "./useGenerateDateRow";
import { SortedRevisionQuestionDataList } from "./useGenerateSortedRevisionQuestionDataList";

export const useGenerateDateRows = () => {
  const { generateDataRow } = useGenerateDateRow();
  const generateDataRows = ({
    newGraduates,
    onnFormTaskAnswers,
    sortedRevisionQuestionDataList,
  }: {
    newGraduates: NewGraduateToDisplay[];
    onnFormTaskAnswers: OnnFormTaskAnswer[];
    sortedRevisionQuestionDataList: SortedRevisionQuestionDataList;
  }) => {
    const newGraduateIdToNewGraduateMap = newGraduates.reduce((acc, newGraduate) => {
      acc.set(newGraduate.id, newGraduate);
      return acc;
    }, new Map<string, NewGraduateToDisplay>());

    const data = onnFormTaskAnswers
      .flatMap((onnFormTaskAnswer) => {
        const newGraduate = newGraduateIdToNewGraduateMap.get(onnFormTaskAnswer.employeeId);
        if (!newGraduate) {
          return [];
        }
        const row = generateDataRow({
          onnFormTaskAnswer,
          newGraduate,
          sortedRevisionQuestionDataList,
        });
        return {
          row,
          recruitmentStatusType: newGraduate.recruitmentStatusType,
        };
      })
      .sort((a, b) => {
        const aIsRejected = a.recruitmentStatusType === "rejected";
        const bIsRejected = b.recruitmentStatusType === "rejected";
        const aIsWithdrew = a.recruitmentStatusType === "withdrew";
        const bIsWithdrew = b.recruitmentStatusType === "withdrew";
        if (aIsRejected && bIsWithdrew) return -1;
        if (aIsWithdrew && bIsRejected) return 1;
        if (aIsRejected || aIsWithdrew) return 1;
        if (bIsRejected || bIsWithdrew) return -1;
        return 0;
      });

    const dataRows = data.map((d) => d.row);
    return dataRows;
  };

  return { generateDataRows };
};
