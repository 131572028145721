import { NewGraduateToDisplay } from "@onn/common";
import { useCallback } from "react";

export const useGenerateSampleCSV = () => {
  const generateSampleCSV = useCallback((allNewComers: NewGraduateToDisplay[]) => {
    return [
      ["「Onn固有ID」コピー欄", "姓", "名", "メールアドレス"],
      ...allNewComers.map((v) => {
        return [v.uniqueId, v.lastName, v.firstName, v.email];
      }),
    ];
  }, []);

  return { generateSampleCSV };
};
