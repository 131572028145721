import {
  IOnnTaskLinkedOnnEventCandidateDateOrder,
  onnTaskLinkedOnnEventCandidateDateOrderSchema,
} from "./schema";

const statues = ["WAITING", "SENT", "SENDING", "FAILED"] as const;
export type Status = (typeof statues)[number];

export class OnnTaskLinkedOnnEventCandidateDateOrder
  implements IOnnTaskLinkedOnnEventCandidateDateOrder
{
  private static validator = onnTaskLinkedOnnEventCandidateDateOrderSchema;

  id: string;
  tenantId: string;
  spaceId: string;
  onnEventId: string;
  onnEventCandidateDateId: string;
  onnTaskId: string;
  status: Status;
  createdAt: Date;
  updatedAt: Date;

  constructor(init: ExcludeMethods<OnnTaskLinkedOnnEventCandidateDateOrder>) {
    OnnTaskLinkedOnnEventCandidateDateOrder.validator.parse(init);

    this.id = init.id;
    this.tenantId = init.tenantId;
    this.spaceId = init.spaceId;
    this.onnEventId = init.onnEventId;
    this.onnEventCandidateDateId = init.onnEventCandidateDateId;
    this.onnTaskId = init.onnTaskId;
    this.status = init.status;
    this.createdAt = init.createdAt;
    this.updatedAt = init.updatedAt;
  }
}
