import { Employee } from "@onn/common";
import React, { useCallback } from "react";

import { useModal } from "~/hooks/modal";

export const useAddNewGraduateToOnnEventWithCSVButtonHandler = ({
  selectedNewGraduates,
  alreadyRegisteredNewGraduateIds,
  setSelectedNewGraduates,
}: {
  selectedNewGraduates: Employee[];
  alreadyRegisteredNewGraduateIds: string[];
  setSelectedNewGraduates: React.Dispatch<React.SetStateAction<Employee[]>>;
}) => {
  const { handleModal } = useModal();

  const onSubmit = useCallback(
    (newTargets: Employee[]) => {
      setSelectedNewGraduates((prev) => {
        const prevIds = prev.map((v) => v.id);
        const newSelectedNewGraduates = [
          ...prev,
          ...newTargets.filter((v) => !prevIds.includes(v.id)), // 重複を除外
        ];
        return newSelectedNewGraduates;
      });
    },
    [setSelectedNewGraduates]
  );

  // 「CSVアップロード」ボタンハンドラー
  const onClickUploadCSVButton = useCallback(() => {
    handleModal({
      name: "addNewGraduateByCSVModal",
      args: {
        currentSelectedNewGraduates: selectedNewGraduates,
        registeredEmployeeIds: alreadyRegisteredNewGraduateIds,
        onSubmit,
      },
    });
  }, [handleModal, selectedNewGraduates, alreadyRegisteredNewGraduateIds, onSubmit]);

  return { onClickUploadCSVButton };
};
