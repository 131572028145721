import liff from "@line/liff";

import React, { ReactNode, createContext, useEffect, useState } from "react";

import { LoadingForInitialRendering } from "../shared";

import { useLiffId } from "~/hooks/liff/useLiffId";
import { useSnackbar } from "~/hooks/shared";
import { apiClient } from "~/libs";
import { captureException } from "~/util";
import { CustomConsole } from "~/util/initializeCustomConsole";
import { captureMessage } from "~/util/loggerUtil";

export const LiffInitializeContext = createContext<{
  isReady: boolean;
}>({
  isReady: false,
});

const fetchLinkRequest = async (liffAccessToken: string, invitationToken: string) => {
  await apiClient.post("/link_line_user_and_employee", { liffAccessToken, invitationToken });
};

const onSuccessInitializeLiff = async (
  enqueueSnackbar: ReturnType<typeof useSnackbar>["enqueueSnackbar"]
) => {
  if (!liff.isInClient()) {
    return;
  }

  const accessToken = liff.getAccessToken();
  if (!accessToken) {
    captureException({
      error: new Error("[要確認]LIFFアクセストークンがLIFFブラウザで取得できませんでした"),
      tags: {
        type: "Cloud not retrieve a LIFF access token on the LIFF browser",
      },
      extras: {
        location: window.location.href,
        isCookieEnabled: navigator.cookieEnabled,
      },
    });
    return;
  }

  const pathname = window.location.pathname.replace(new RegExp(/\/$/), ""); // 末尾のスラッシュを削除
  const isAccountRegistrationPage = new RegExp(
    "/portal/accounts/invitations/[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}/registration/line"
  ).test(pathname); // https://gist.github.com/johnelliott/cf77003f72f889abbc3f32785fa3df8d?permalink_comment_id=4318295#gistcomment-4318295
  if (isAccountRegistrationPage) {
    const invitationToken = pathname
      .replace(new RegExp("/portal/accounts/invitations/"), "")
      .replace(new RegExp("/registration/line"), "");
    captureMessage({
      message: "LineUserとEmployeeの紐付けを行います",
      tags: { type: "Link a LineUser and an Employee" },
      extras: { accessToken, invitationToken },
    });

    await fetchLinkRequest(accessToken, invitationToken).catch((error) => {
      enqueueSnackbar(
        "エラーが発生したためこの操作を続けることはできません。採用担当者にお問い合わせください。",
        {
          variant: "error",
        }
      );
      captureException({
        error: new Error("[要確認]LineUserとEmployeeの紐付けに失敗しました"),
        tags: {
          type: "Failed to link a LineUser and an Employee",
        },
        extras: {
          accessToken,
          invitationToken,
          error,
          location: window.location.href,
          isCookieEnabled: navigator.cookieEnabled,
        },
      });
    });
  }
};

export const LiffInitializeProvider = ({ children }: { children: ReactNode }): JSX.Element => {
  const [isReady, setIsReady] = useState<boolean>(false);

  const liffId = useLiffId();
  const clientOS = liff.getOS();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const isLIFFUrl = !!liffId;
    if (!isLIFFUrl || isReady) {
      return;
    }

    liff.init(
      { liffId },
      async () => {
        await onSuccessInitializeLiff(enqueueSnackbar);
        setIsReady(true);
      },
      (e) => {
        captureException({
          error: new Error(`LIFF の初期化に失敗しました (${clientOS})`),
          tags: {
            type: "LineAccessTokenByLiffProvider liff.initに失敗しました",
          },
          extras: {
            error: e,
            stack: e.stack,
            isReady,
            isInClient: liff.isInClient(),
            // eslint-disable-next-line no-console
            history: JSON.stringify((console as CustomConsole).history ?? {}),
          },
        });
        throw e;
      }
    );
  }, [clientOS, enqueueSnackbar, isReady, liffId]);

  if (liff.isInClient() && !isReady) {
    return <LoadingForInitialRendering />;
  }

  return (
    <LiffInitializeContext.Provider
      value={{
        isReady,
      }}
    >
      {children}
    </LiffInitializeContext.Provider>
  );
};
