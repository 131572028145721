import { Employee } from "@onn/common";
import useSWR, { useSWRConfig } from "swr";

import { apiClient } from "~/libs";

const getKey = (employeeTagId: string) => {
  return { endpoint: "/list_tagged_employees", employeeTagId } as const;
};

export const useTaggedEmployees = (employeeTagId: string) => {
  return useSWR(getKey(employeeTagId), async ({ endpoint, employeeTagId }) => {
    const response = await apiClient.get(endpoint, { employeeTagId });

    return {
      taggedEmployees: response.data.employees.map((employee) => {
        return new Employee(employee);
      }),
      meta: response.meta,
    };
  });
};

export const useMutateTaggedEmployees = () => {
  const { mutate } = useSWRConfig();

  const mutateTaggedEmployees = (employeeTagId: string) => {
    mutate(getKey(employeeTagId));
  };

  return {
    mutateTaggedEmployees,
  };
};
