import { Box } from "@mui/material";
import { OnnEvent } from "@onn/common";
import React, { FC, useEffect } from "react";

import { Controller } from "react-hook-form";
import { useParams } from "react-router-dom";

import { useSlotDefaultValueForm } from "./hooks/useSlotDefaultValueForm";

import {
  Icon,
  Loading,
  Paper,
  TextField,
  TextareaAutosize,
  Tooltip,
  Typography,
} from "~/components/uiParts";
import { ActionFooter } from "~/components/uiParts/ActionFooter";
import { useOnnEvent } from "~/hooks/onnEvent";
import { useCurrentSpace } from "~/hooks/space/useCurrentSpace";
import { useTenant } from "~/hooks/tenant/useTenant";

import { NotFound } from "~/pages/NotFound";

export const Page: React.FC = () => {
  const params = useParams();
  const onnEventId = params.id;
  const { tenant } = useTenant();
  const { switchSpaceTemporary } = useCurrentSpace();
  const { data: onnEvent, isLoading: isLoadingOnnEvent } = useOnnEvent({
    tenantId: tenant.tenantId,
    onnEventId,
  });

  useEffect(() => {
    if (!onnEvent) return;
    switchSpaceTemporary(onnEvent.spaceId);
  }, [switchSpaceTemporary, onnEvent]);

  const isLoading = isLoadingOnnEvent;

  if (isLoading) {
    return <Loading size="large" fullHeight />;
  }

  if (!onnEventId || !onnEvent) {
    return <NotFound />;
  }

  // NOTE: 通常タイプや面談タイプのイベントの場合はデフォルトの会場設定に対応していないためNotFoundページを表示する
  if (onnEvent.type === "normal" || onnEvent.type === "interview") {
    return <NotFound />;
  }

  return <PageCore onnEvent={onnEvent} />;
};

type Props = {
  onnEvent: OnnEvent;
};

const PageCore: FC<Props> = ({ onnEvent }) => {
  const { form, isSaving, onClickCancel, handleSubmit, submitButtonText } = useSlotDefaultValueForm(
    { onnEvent }
  );

  return (
    <Box display="flex" justifyContent="center">
      <Box width="800px" display="flex" flexDirection="column" rowGap="40px">
        <Typography variant="h4" bold>
          共通設定
        </Typography>
        <Paper>
          <Box display="flex" flexDirection="column" rowGap="16px">
            <Box display="flex" columnGap="4px" alignItems="center">
              <Typography variant="body2" bold>
                開催方法
              </Typography>
              <Box p="4px">
                <Tooltip
                  title="開催方法に記載される概要のデフォルトの設定を行います。"
                  placement="top-start"
                >
                  <Icon icon="help" size="sm" color="grey" />
                </Tooltip>
              </Box>
            </Box>
            <Box display="flex" flexDirection="column" rowGap="24px">
              <Box display="flex" flexDirection="column" rowGap="12px">
                <Typography variant="body2">オンライン</Typography>
                <Box pl="28px">
                  <Box display="flex" alignItems="center" columnGap="16px">
                    <Icon icon="line" size="md" color="lightGrey" />
                    <Controller
                      name="online.description"
                      control={form.control}
                      render={({ field, fieldState }) => (
                        <TextareaAutosize
                          {...field}
                          fullWidth
                          placeholder="開催概要を入力"
                          minRows={3}
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  </Box>
                  <Box display="flex" alignItems="center" columnGap="16px">
                    <Icon icon="link" size="md" color="lightGrey" />
                    <Controller
                      name="online.url"
                      control={form.control}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          fullWidth
                          variant="outlined"
                          placeholder="オンライン会議ツールのURL"
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  </Box>
                </Box>
              </Box>
              <Box display="flex" flexDirection="column" rowGap="12px">
                <Typography variant="body2">オフライン・対面</Typography>
                <Box mt="12px" pl="28px">
                  <Box display="flex" alignItems="center" columnGap="16px">
                    <Icon icon="line" size="md" color="lightGrey" />
                    <Controller
                      name="offline.description"
                      control={form.control}
                      render={({ field, fieldState }) => (
                        <TextareaAutosize
                          {...field}
                          fullWidth
                          placeholder="開催概要を入力"
                          minRows={3}
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Paper>
        <ActionFooter
          cancelButtonText={"キャンセル"}
          submitButtonText={submitButtonText}
          onClickCancel={onClickCancel}
          onClickConfirmSave={handleSubmit}
          isSaving={isSaving}
          isDisabledSaveButton={false}
        />
      </Box>
    </Box>
  );
};
