import React, { FC } from "react";

import { mutate } from "swr";

import { BodyRowData } from "../const";

import { CellItem, StyleProps } from "./CellItem";

import { Icon, Typography, UserIcon } from "~/components/uiParts";
import { useCurrentUser, useEmployee } from "~/hooks/employee";
import { useModal } from "~/hooks/modal";
import { generateUseRecruitmentProcessRecordsByEmployeeKey } from "~/hooks/recruitmentProcess/useRecruitmentProcessRecordsByEmployee";

export const AssigneeCell: FC<BodyRowData["assignee"] & StyleProps> = ({
  onnEventInfo,
  assignee,
  isEvent,
  shouldPromptForScheduleSetting,
  newGraduateId,
  ...styleProps
}) => {
  const { handleModal } = useModal();
  const { currentUser } = useCurrentUser();
  const { data: onnEventSlotAssignee } = useEmployee(
    onnEventInfo?.slotDate?.assigneeId || undefined
  );

  if (!isEvent || !onnEventInfo) {
    return (
      <CellItem {...styleProps}>
        <Icon color="lightGrey" size="xl" icon="horizontalBar" />
      </CellItem>
    );
  }

  if (shouldPromptForScheduleSetting) {
    return <></>;
  }

  const { onnEvent, determinedDate, candidateDate } = onnEventInfo;

  // TODO: 新面談タイプのでも代理回答ができるようにする https://app.clickup.com/t/86entfrbb
  const openAnswerModal = () => {
    if (onnEvent.type !== "interview") return;
    if (!determinedDate || !candidateDate) return;
    // shouldPromptForScheduleSetting が false でインタビューイベント の場合は、
    // 選考イベントで、決定日と候補日が必ず存在する

    handleModal({
      name: "answerInterviewEventOnBehalfModal",
      args: {
        onnEvent,
        mode: {
          type: "edit",
          onnEventDeterminedDate: determinedDate,
          candidateDate,
        },
        additionalMutate: () =>
          mutate(generateUseRecruitmentProcessRecordsByEmployeeKey(newGraduateId)),
      },
    });
  };

  const openNewInterviewAnswerModal = () => {
    if (onnEvent.type !== "new_interview") return;
    if (!determinedDate || !determinedDate.onnEventSlotDateId) return;

    handleModal({
      name: "answerNewInterviewEventOnBehalfModal",
      args: {
        onnEventId: onnEvent.id,
        fixedOption: {
          selectedEmployeeId: newGraduateId,
        },
        slotDefaultValueSetting: {},
        mode: {
          type: "edit",
          onnEventSlotDateId: determinedDate.onnEventSlotDateId,
        },
      },
    });
  };

  const onClickUserIcon = (() => {
    if (!currentUser.isAdmin()) return undefined;
    if (onnEvent.type === "interview") return openAnswerModal;
    if (onnEvent.type === "new_interview") return openNewInterviewAnswerModal;
    return undefined;
  })();

  const profileIconImageUrl = (() => {
    if (onnEvent.type === "normal" || onnEvent.type === "interview") {
      return assignee?.profileIconImageUrl;
    }
    if (onnEvent.type === "new_interview") {
      return onnEventSlotAssignee?.profileIconImageUrl;
    }
    return undefined;
  })();

  return (
    <CellItem {...styleProps}>
      <Typography variant="body2" color="textSecondary">
        <UserIcon
          username={assignee?.getName() || ""}
          profileIconImageUrl={profileIconImageUrl}
          size={"small"}
          circular={true}
          onClick={onClickUserIcon}
        />
      </Typography>
    </CellItem>
  );
};
