import { format } from "date-fns";
import { v4 } from "uuid";

import { isValidUrl } from "../../utils/isValidUrl";
import { Employee as IEmployee } from "../_gen/zodSchema/index";

import { LangType } from "../shared/LangType";

import { AuthenticationType, AuthenticationTypeForNewGraduate } from "./AuthenticationType";
import { Role } from "./Role";

const displayRoleMap = {
  [Role.ADMIN]: "管理者",
  [Role.MEMBER]: "メンバー",
};

type iconType = "NONE" | "URL" | "FIRE_STORAGE";

export type NewGraduate = Employee & {
  isNewGraduate: true;
  assignedAsNewcomer: true;
  recruitmentStatusId: string;
  spaceId: string;
  uniqueId: string;
};

/*
 * メンバー権限(入社者、バディ)
 */
export class Employee implements IEmployee {
  id: string;
  tenantId: string;
  /** メールアドレス
   * - 共通招待リンクアクセスによるアカウント作成(未登録)では、空文字のケースがあるため注意する
   */
  email: string;
  firstName: string;
  lastName: string;
  /** 招待日時
   * - この日時がある場合は招待済みと扱う
   * - 登録時の日時が入っているだけで、招待通知が送られていないケースもある */
  invitedAt?: Date;
  /** 招待通知が最後に送られた日時
   * - invitedAtとは異なり、招待通知が送られた日時のみを保持する
   * - invitedAtに日時が入っていても、こちらは undefined のケースもある
   */
  lastInvitedAt?: Date;
  invitationToken: string; // 招待トークン、このトークンはEmployee生成時に必ず発行される
  accountCreateAt: string;
  createdAt: Date;
  joinAt?: string; // TODO: 新卒では「入社日」情報は不要なのでDB移行の際に削除する
  role: Role;
  assignedAsNewcomer: boolean; // オンボーディング対象者として割り当てられたかどうか
  uid?: string;
  profileIconImageUrl?: string;
  mentorUserId?: string;
  supportMemberEmployeeIds: string[]; // サポートメンバーのidの配列
  deleted: boolean;
  lastRefreshTime?: Date;
  lastActiveTime?: Date;
  // 以下新卒入社者向けのプロパティ
  isNewGraduate?: boolean;
  lineUserId?: string;
  /** 公式アカウントをフォローしているかどうか(undefined と false は同じように扱える) */
  isFollowedLineOfficialAccount?: boolean; // TODO: LineUser domainへ移行する
  admin_memo?: string;
  // 管理者がアカウントを作成する際にどの認証方法で登録してもらうか指定する
  selectableAuthenticationFlowTypes: AuthenticationTypeForNewGraduate[];
  // ユーザーがどの認証方法を選択したかを保持する
  selectedAuthenticationFlowType: AuthenticationTypeForNewGraduate;
  currentAuthenticationType?: AuthenticationType;
  recruitmentStatusId?: string;
  spaceId?: string;
  lang: LangType; // アンケートなどで利用する言語
  uniqueId?: string;

  readonly deletedAt?: Date;

  static displayRoleMap = displayRoleMap;

  static castToNewGraduate(employee: Employee): NewGraduate {
    if (!employee.isNewGraduateEmployee()) throw new Error("employee is not new graduate");
    return employee as NewGraduate;
  }

  constructor({
    id,
    tenantId,
    email,
    firstName,
    lastName,
    invitedAt,
    lastInvitedAt,
    invitationToken,
    accountCreateAt,
    createdAt,
    joinAt,
    role,
    assignedAsNewcomer,
    uid,
    profileIconImageUrl,
    mentorUserId,
    supportMemberEmployeeIds,
    deleted,
    lastRefreshTime,
    lastActiveTime,
    isNewGraduate,
    lineUserId,
    admin_memo,
    deletedAt,
    isFollowedLineOfficialAccount,
    recruitmentStatusId,
    selectableAuthenticationFlowTypes,
    selectedAuthenticationFlowType,
    currentAuthenticationType,
    spaceId,
    lang,
    uniqueId,
  }: ExcludeMethods<Employee>) {
    this.id = id;
    this.tenantId = tenantId;
    this.email = email;
    this.firstName = firstName;
    this.lastName = lastName;
    this.invitationToken = invitationToken;
    this.accountCreateAt = accountCreateAt;
    this.createdAt = createdAt;
    this.joinAt = joinAt;
    this.role = role;
    this.assignedAsNewcomer = assignedAsNewcomer;
    this.uid = uid;
    this.profileIconImageUrl = profileIconImageUrl;
    this.mentorUserId = mentorUserId;
    this.supportMemberEmployeeIds = supportMemberEmployeeIds ?? [];
    this.deleted = deleted;
    this.invitedAt = typeof invitedAt === "string" ? new Date(invitedAt) : invitedAt;
    this.lastInvitedAt =
      typeof lastInvitedAt === "string" ? new Date(lastInvitedAt) : lastInvitedAt;
    this.lastRefreshTime =
      typeof lastRefreshTime === "string" ? new Date(lastRefreshTime) : lastRefreshTime;
    this.lastActiveTime =
      typeof lastActiveTime === "string" ? new Date(lastActiveTime) : lastActiveTime;
    this.isNewGraduate = isNewGraduate;
    this.lineUserId = lineUserId;
    this.deletedAt = typeof deletedAt === "string" ? new Date(deletedAt) : deletedAt;
    this.admin_memo = admin_memo;
    this.isFollowedLineOfficialAccount = isFollowedLineOfficialAccount;
    this.recruitmentStatusId = recruitmentStatusId;
    this.selectableAuthenticationFlowTypes = selectableAuthenticationFlowTypes;
    this.selectedAuthenticationFlowType = selectedAuthenticationFlowType;
    this.currentAuthenticationType = currentAuthenticationType;
    this.spaceId = spaceId;
    this.lang = lang;
    this.uniqueId = uniqueId;
  }

  public isMember(): boolean {
    return this.role === Role.MEMBER;
  }

  public isAdmin(): boolean {
    return this.role === Role.ADMIN;
  }

  public isNewcomer(): boolean {
    return this.assignedAsNewcomer;
  }

  public hasMentor(): boolean {
    return Boolean(this.mentorUserId);
  }

  public getName(): string {
    return `${this.deleted ? "【削除済み】" : ""}${this.lastName} ${this.firstName}`;
  }

  public getDisplayRole(): string {
    return displayRoleMap[this.role];
  }

  public validateCanRegister() {
    if (this.deleted) {
      throw new Error("削除済みのユーザーです");
    }
    if (this.isRegistered()) {
      throw new Error("既に登録されています");
    }
  }

  isNewGraduateEmployee(): this is NewGraduate {
    if (!this.spaceId) return false;
    if (!this.recruitmentStatusId) false;
    return !!this.isNewGraduate;
  }

  /**
   * 招待通知を送信済みかどうかを返す
   */
  hasNotifiedInvitation(): this is NonNullable<Employee["invitedAt"]> {
    return !!this.lastInvitedAt;
  }

  /**
   * 招待済みかどうかを返す
   * NOTE: 共通招待リンク経由など、招待通知を送信していない場合に true を返すこともある
   * そのため、招待通知を送信済みかどうかには利用しないこと。
   * WARN: 招待をされずに登録が完了するケースもある（個別招待フローのアカウント登録→QRコード経由による登録など）
   isNotRegisteredAndInvited を利用すべきケースに利用しないように気を付ける。
   */
  isInvited(): this is NonNullable<Employee["invitedAt"]> {
    return !!this.invitedAt;
  }

  /**
   * 未登録の際に未招待であるかどうかを返す
   */
  isNotRegisteredAndInvited(): this is NonNullable<Employee["invitedAt"]> {
    return !this.isRegistered() && !this.invitedAt;
  }

  /**
   * 一度でもログインしたことがあるかを返す
   */
  isEverLogined(): this is NonNullable<Employee["lastRefreshTime"]> {
    return !!this.lastRefreshTime;
  }

  /**
   * アカウントが登録済みかどうかを返す
   * @returns boolean
   */
  isRegistered(): this is Employee & { uid: string } {
    return !!this.uid;
  }

  /**
   * アカウントに通知可能かどうかを返す
   * @deprecated 認証タイプに関わらず通知可能かを判断しているため使用しないようにする。実際は認証タイプに基づいて通知可否を判断する必要がある。
   * @returns boolean
   */
  isNotifiable(): boolean {
    if (this.deleted) return false;

    return this.canNotifyWithEmail() || this.canNotifyWithLine();
  }

  /**
   * アカウントに通知可能かどうかを認証タイプに基づいて返す
   * @returns boolean
   */
  isNotifiableBySelectedAuthenticationFlowType(): boolean {
    if (this.deleted) return false;

    switch (this.selectedAuthenticationFlowType) {
      case "email":
        return this.canNotifyWithEmail();
      case "line":
        return this.canNotifyWithLine();
    }
  }

  public isOnlySelectableLineAuthentication(): boolean {
    return (
      this.selectableAuthenticationFlowTypes.length === 1 &&
      this.selectableAuthenticationFlowTypes[0] === "line"
    );
  }

  // 未登録の場合にemailが存在する場合は個別招待、存在しない場合は共通招待を利用しているアカウントと判断する
  // [caution] 未登録でない時は常にtrueなので使わない
  public isNotRegisteredAccountFromIndividualRegister(): boolean {
    return !this.isRegistered() && this.canNotifyWithEmail();
  }
  /**
   * Email通知が可能かどうかを返す
   * NOTE: LINE通知が可能であってもDBに保存されていない場合は通知されない
   */
  public canNotifyWithEmail(): boolean {
    return !!this.email;
  }

  /**
   * LINE通知が可能かどうかを返す
   * NOTE: LINE通知が可能であってもDBに保存されていない場合は通知されない
   * @returns boolean
   */
  public canNotifyWithLine(): this is EmployeeCanNotifyWithLine {
    return !!this.lineUserId && !!this.isFollowedLineOfficialAccount;
  }

  public canSegregateLine(): boolean {
    // Email通知が可能な場合、LINE連携を解除できる
    return !!this.lineUserId && this.canNotifyWithEmail();
  }

  public canInvite(): boolean {
    // 招待通知はメールのみ対応しているため、Email通知が不可能な場合は送れない
    return !this.isRegistered() && this.canNotifyWithEmail();
  }

  /**
   * バディとサポートメンバーのemployee.idの配列を取得する
   */
  public getMentorAndSupportMemberIds(): string[] {
    return [
      this.mentorUserId,
      ...(this.supportMemberEmployeeIds ? this.supportMemberEmployeeIds : []),
    ].filter((v): v is NonNullable<typeof v> => Boolean(v));
  }

  public selectAuthenticationType(authenticationType: AuthenticationType) {
    this.currentAuthenticationType = authenticationType;

    if (authenticationType !== "google") this.selectedAuthenticationFlowType = authenticationType;
  }

  public register(uid: string) {
    this.uid = uid;
  }

  /**
   * 新卒の通知で使う遷移先のリンクを生成するロジック
   */
  public generateNotificationLinkAboutNewGraduate({
    link,
    lineLiffId,
    tenantId,
    spaceId,
  }: {
    link: string;
    lineLiffId?: string;
    tenantId: string;
    spaceId?: string;
  }): string {
    const url = new URL(link);

    // 登録されていない場合はアカウント登録画面へのリンクを返す
    if (!this.isRegistered()) {
      url.searchParams.set("dest-path", url.pathname);
      if (this.isNotRegisteredAccountFromIndividualRegister() || !lineLiffId) {
        url.pathname = `/portal/accounts/invitations/${this.invitationToken}`;
      } else {
        url.pathname = "/account/register";
      }
    }

    if (this.selectedAuthenticationFlowType !== "line" || !lineLiffId) {
      return url.toString();
    }

    // NOTE: LINEトークルームメッセージからポータルへ遷移する場合のログを取るためのパラメータ
    url.searchParams.set("from", "lineMessage");
    url.searchParams.set("isLineUserActiveLog", "true");
    url.searchParams.set("tenantId", tenantId);
    spaceId && url.searchParams.set("spaceId", spaceId);

    const liffUrl = new URL(`/${lineLiffId}${url.pathname}${url.search}`, `https://liff.line.me`);
    return liffUrl.toString();
  }

  /**
   * 最終招待通知日時を更新する
   * lastInvitedAtは招待通知を送った時に更新され、招待通知送付済みかどうかの判定に使われる
   */
  public updateLastInvitedAt() {
    return new Employee({ ...this, lastInvitedAt: new Date() });
  }

  /**
   * 招待日時が空の場合のみ更新する
   * inviteAtは招待済みかどうかのラベル表示に使われる
   * 招待通知を送らなくても、招待が完了している場合（直接QRコードを読み込んだ場合など）もあるため注意する
   */
  public invite() {
    if (this.invitedAt) {
      return this;
    } else {
      return new Employee({ ...this, invitedAt: new Date() });
    }
  }

  /**
   * email認証からline認証に切り替える
   */
  public updateToLineAuthentication({
    uid,
    lineUserId,
    isFollowedLineOfficialAccount,
    profileIconImageUrl,
  }: {
    uid: string;
    lineUserId: string;
    isFollowedLineOfficialAccount: boolean;
    profileIconImageUrl?: string;
  }) {
    const _profileIconImageUrl = this.profileIconImageUrl || profileIconImageUrl;
    return new Employee({
      ...this,
      currentAuthenticationType: "line",
      uid,
      lineUserId,
      isFollowedLineOfficialAccount,
      profileIconImageUrl: _profileIconImageUrl,
    });
  }

  /**
   * 候補者でしか使わない
   * NewGraduate にキャストする
   */
  public updateRecruitmentStatusId(recruitmentStatusId: string): NewGraduate {
    return new Employee({ ...this, recruitmentStatusId }) as NewGraduate;
  }

  public getProfileIconImageType(): iconType {
    if (!this.profileIconImageUrl) {
      return "NONE";
    } else if (isValidUrl(this.profileIconImageUrl)) {
      return "URL";
    } else {
      return "FIRE_STORAGE";
    }
  }

  public updateForRegistration(params: {
    firstName?: string;
    lastName?: string;
    email: string;
    recruitmentStatusId: string;
  }): NewGraduate {
    return new Employee({
      ...this,
      ...params,

      // 入力がない場合、上書きしない
      firstName: params.firstName || this.firstName,
      lastName: params.lastName || this.lastName,
    }) as NewGraduate;
  }

  public updateToAssign({
    assigneeId,
    followerIds,
  }: {
    assigneeId?: string;
    followerIds: string[];
  }) {
    return new Employee({
      ...this,
      mentorUserId: assigneeId,
      supportMemberEmployeeIds: followerIds,
    });
  }

  public static createNewGraduateEmployee(
    {
      firstName,
      lastName,
      email,
      joinAt,
      tenantId,
      isFollowedLineOfficialAccount,
      recruitmentStatusId,
      selectableAuthenticationFlowTypes,
      selectedAuthenticationFlowType,
      currentAuthenticationType,
      spaceId,
      uniqueId,
    }: {
      firstName?: string;
      lastName?: string;
      email: string;
      joinAt?: string;
      tenantId: string;
      isFollowedLineOfficialAccount?: boolean;
      recruitmentStatusId: string;
      selectableAuthenticationFlowTypes: AuthenticationTypeForNewGraduate[];
      selectedAuthenticationFlowType: Exclude<AuthenticationType, "google">;
      currentAuthenticationType?: AuthenticationType;
      spaceId: string;
      uniqueId: string;
    },
    options: { isInvited: boolean }
  ): NewGraduate {
    return new Employee({
      id: v4(),
      email: email,
      tenantId: tenantId,
      firstName: firstName || "",
      lastName: lastName || email,
      invitedAt: options.isInvited ? new Date() : undefined,
      lastInvitedAt: undefined,
      invitationToken: v4(),
      joinAt,
      role: Role.MEMBER,
      assignedAsNewcomer: true,
      mentorUserId: "",
      supportMemberEmployeeIds: [],
      profileIconImageUrl: undefined,
      accountCreateAt: format(new Date(), "yyyy-MM-dd"),
      createdAt: new Date(),
      uid: undefined,
      admin_memo: "",
      isNewGraduate: true,
      isFollowedLineOfficialAccount,
      recruitmentStatusId,
      deleted: false,
      selectableAuthenticationFlowTypes,
      selectedAuthenticationFlowType,
      currentAuthenticationType,
      spaceId,
      lang: LangType.JA, // 現時点では `ja` に固定して、必要なユーザーのみ手動で `en` にしている
      uniqueId,
    }) as NewGraduate;
  }

  /**
   * 受け入れメンバーを作成する
   * @returns Employee
   */
  public static createAcceptanceMember({
    ...params
  }: {
    tenantId: string;
    email: string;
    lastName: string;
    profileIconImageUrl?: string;
    role: Employee["role"];
    selectableAuthenticationFlowTypes: ["email"];
    selectedAuthenticationFlowType: Exclude<AuthenticationType, "google">;
    currentAuthenticationType?: AuthenticationType;
  }): Employee {
    return new Employee({
      id: v4(),
      tenantId: params.tenantId,
      email: params.email,
      firstName: "",
      lastName: params.lastName,
      invitedAt: new Date(),
      invitationToken: v4(),
      joinAt: undefined,
      role: params.role,
      assignedAsNewcomer: false,
      profileIconImageUrl: params.profileIconImageUrl,
      accountCreateAt: format(new Date(), "yyyy-MM-dd"),
      createdAt: new Date(),
      uid: "",
      deleted: false,
      selectableAuthenticationFlowTypes: params.selectableAuthenticationFlowTypes,
      selectedAuthenticationFlowType: params.selectedAuthenticationFlowType,
      currentAuthenticationType: params.currentAuthenticationType,
      lang: LangType.JA, // 現時点では `ja` に固定して、必要なユーザーのみ手動で `en` にしている
      supportMemberEmployeeIds: [],
    });
  }

  static plainToInstance(init: ExcludeMethods<Employee>): Employee {
    return new Employee(init);
  }
}

export type EmployeeCanNotifyWithLine = Employee & {
  lineUserId: string;
  isFollowedLineOfficialAccount: true;
};
