import { Box } from "@material-ui/core";
import { OnnEventAnswerStatus } from "@onn/common";
import React, { FC } from "react";
import styled from "styled-components";

import { Checkbox, FormControlLabel, Typography } from "~/components/uiParts";

// ====================
// props
// ====================

type Props = {
  menuOptions: { value: OnnEventAnswerStatus; label: string }[];
  selected: OnnEventAnswerStatus[];
  onClickCheckBox: (value: OnnEventAnswerStatus) => void;
};

// ====================
// main
// ====================

export const AnswerStatusFilterSelectMenu: FC<Props> = ({
  menuOptions,
  selected,
  onClickCheckBox,
}) => {
  // ====================
  // jsx
  // ====================

  return (
    <Box py="16px" width="240px" display="flex" flexDirection="column">
      <Box px="24px" maxHeight={300} overflow="auto" display="flex" flexDirection="column">
        {menuOptions.map((option) => (
          <StyledFormControlLabel
            key={option.value}
            onChange={() => onClickCheckBox(option.value)}
            control={
              <Checkbox
                checked={selected.some((v) => v === option.value)}
                name={option.label}
                value={option.value}
              />
            }
            disabled={false}
            label={
              <Box width="100%" display="flex" alignItems="center" gridGap="4px" overflow="hidden">
                <StyledTypography variant="body2" noWrap>
                  {option.label}
                </StyledTypography>
              </Box>
            }
          />
        ))}
      </Box>
    </Box>
  );
};

// ====================
// style
// ====================

const StyledFormControlLabel = styled(FormControlLabel)`
  &.MuiFormControlLabel-root {
    width: 100%;
  }
  .MuiFormControlLabel-label {
    overflow: hidden;
  }
`;

const StyledTypography = styled(Typography)`
  &.MuiTypography-root {
    margin-left: 8px;
    color: ${(props) => props.theme.palette.text.secondary};
  }
`;
