import {
  subDays,
  isSameDay,
  set,
  isEqual,
  format,
  isPast,
  addDays,
  differenceInCalendarDays,
} from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import { v4 } from "uuid";

import { Employee } from "../../Employee";
import { CandidateDate } from "../CandidateDate";

import { OnnEventSlotDate } from "../OnnEventSlotDate";

import { IOnnEvent, onnEventSchema } from "./schema";
import { OnnEventType } from "./types";

export type OnnEventSettingCompleted = OnnEvent & {
  deadlineDate: Date;
  scheduledDate: Date;
  canAnswerAfterDeadline: boolean;
};

const eventTypeTextMap: Record<OnnEvent["type"], string> = {
  new_interview: "選考",
  interview: "選考",
  normal: "その他",
};

export type NewInterviewEvent = OnnEvent & {
  type: "new_interview";
  deadlineDate?: undefined;
  scheduledDate?: undefined;
  canAnswerAfterDeadline?: undefined;
  firstDeliveredAt?: undefined;
  shouldRequestAttendance?: undefined;
};

export class OnnEvent implements IOnnEvent {
  static readonly validator = onnEventSchema;

  readonly id: string;
  readonly tenantId: string;
  readonly spaceId: string;
  readonly title: string;
  readonly content: string;
  readonly candidateDates: CandidateDate[];
  readonly assigneeIds: string[];
  readonly type: OnnEventType;
  readonly createdAt: Date;
  readonly updatedAt: Date;
  readonly deadlineDate?: Date;
  readonly scheduledDate?: Date;
  readonly canAnswerAfterDeadline?: boolean;
  readonly firstDeliveredAt?: Date;
  readonly shouldRequestAttendance?: boolean;
  /**
   * イベント参加登録時に、アカウント登録する場合に使用される招待リンクID
   */
  readonly registrationInvitationLinkId?: string;
  readonly isRestrictedAnswerFromNewGraduate?: boolean;
  readonly isRestrictedDaysAgoWhenAnswer?: boolean;
  readonly isRestrictedEditAnswerFromNewGraduate?: boolean;
  readonly daysAgoRestrictAnswer?: number;
  readonly slotDefaultValueSetting?: {
    slotType?: "online" | "offline";
    online?: {
      description?: string;
      url?: string;
    };
    offline?: {
      description?: string;
    };
  };

  constructor(init: ExcludeMethods<OnnEvent>) {
    const parsedInit = OnnEvent.validator.parse(init);

    this.id = parsedInit.id;
    this.tenantId = parsedInit.tenantId;
    this.spaceId = parsedInit.spaceId;
    this.title = parsedInit.title;
    this.content = parsedInit.content;
    this.deadlineDate = parsedInit.deadlineDate;
    this.candidateDates = parsedInit.candidateDates.map((d) => new CandidateDate(d));
    this.assigneeIds = parsedInit.assigneeIds;
    this.type = parsedInit.type;
    this.createdAt = parsedInit.createdAt;
    this.updatedAt = parsedInit.updatedAt;
    this.scheduledDate = parsedInit.scheduledDate;
    this.firstDeliveredAt = parsedInit.firstDeliveredAt;
    this.canAnswerAfterDeadline = parsedInit.canAnswerAfterDeadline;
    this.shouldRequestAttendance = parsedInit.shouldRequestAttendance;
    this.registrationInvitationLinkId = parsedInit.registrationInvitationLinkId;
    this.isRestrictedAnswerFromNewGraduate = parsedInit.isRestrictedAnswerFromNewGraduate;
    this.isRestrictedDaysAgoWhenAnswer = parsedInit.isRestrictedDaysAgoWhenAnswer;
    this.isRestrictedEditAnswerFromNewGraduate = parsedInit.isRestrictedEditAnswerFromNewGraduate;
    this.daysAgoRestrictAnswer = parsedInit.daysAgoRestrictAnswer;
    this.slotDefaultValueSetting = parsedInit.slotDefaultValueSetting;
  }

  public update(update: Partial<Omit<ExcludeMethods<OnnEvent>, "updatedAt">>): OnnEvent {
    return new OnnEvent({
      ...this,
      ...update,
      updatedAt: new Date(),
    });
  }

  /**
   * OnnEventを新規作成するときに使うメソッド
   * @param {Optional<ExcludeMethods<OnnEvent>, "id" | "createdAt" | "updatedAt">} params
   * @returns OnnEvent
   */
  public static create(
    params: Optional<ExcludeMethods<OnnEvent>, "id" | "createdAt" | "updatedAt">
  ): OnnEvent {
    return new OnnEvent({
      ...params,
      id: params.id ?? v4(),
      createdAt: params.createdAt ?? new Date(),
      updatedAt: params.updatedAt ?? new Date(),
    });
  }

  /**
   * NewInterviewEventを新規作成するときに使うメソッド
   */
  public static createForNewInterviewEvent(
    params: Pick<
      ExcludeMethods<NewInterviewEvent>,
      "tenantId" | "spaceId" | "title" | "content" | "slotDefaultValueSetting"
    >
  ): NewInterviewEvent {
    return new OnnEvent({
      ...params,
      type: "new_interview",
      id: v4(),
      candidateDates: [],
      assigneeIds: [],
      createdAt: new Date(),
      updatedAt: new Date(),
      isRestrictedEditAnswerFromNewGraduate: true,
    }) as NewInterviewEvent;
  }

  canFirstDeliver(): boolean {
    // NOTE: インタビュータイプのイベントは配信タイミングでの通知を行わないためfalseを返す
    if (this.type === "interview") return false;

    return this.isExceededScheduledDate() && this.firstDeliveredAt == null;
  }

  isSettingCompleted(): this is OnnEventSettingCompleted {
    return !!this.deadlineDate && !!this.scheduledDate && this.canAnswerAfterDeadline != null;
  }

  isExceededScheduledDate(): boolean {
    if (!this.scheduledDate) {
      return false;
    }

    return this.scheduledDate.getTime() < Date.now();
  }

  /**
   * 作成可能かどうかを返す
   * @param {Employee} currentUser 操作者
   * @returns boolean
   */
  isCreatable(currentUser: Employee): boolean {
    if (currentUser.tenantId !== this.tenantId) return false;
    return currentUser.isAdmin();
  }

  /**
   * 編集可能かどうかを返す
   * @param {Employee} currentUser 操作者
   * @returns boolean
   */
  isEditable(currentUser: Employee): boolean {
    if (currentUser.tenantId !== this.tenantId) return false;
    return currentUser.isAdmin() || this.assigneeIds.includes(currentUser.id);
  }

  /**
   * 削除可能かどうかを返す
   * @param {Employee} currentUser 操作者
   * @returns boolean
   */
  isDeletable(currentUser: Employee): boolean {
    if (currentUser.tenantId !== this.tenantId) return false;
    return currentUser.isAdmin() || this.assigneeIds.includes(currentUser.id);
  }

  /**
   * イベントの配信から候補者を削除した場合に、候補者に通知するかどうかを返す
   */
  isNotifyWhenRemoveDeliveredNewGraduate(): boolean {
    if (this.isNewInterviewEvent()) return true;

    return this.isExceededScheduledDate();
  }

  /**
   * candidateDates から通知対象の candidateDateを返す
   * 候補日の前日が通知の対象
   * @returns {CandidateDate[]} 通知対象の候補日
   */
  public getRemindTargetCandidateDates(currentDate: Date = new Date()): CandidateDate[] {
    return this.candidateDates.filter((candidateDate) => {
      return isSameDay(subDays(new Date(candidateDate.from), 1), currentDate);
    });
  }

  public getCandidateDatesById(candidateDateId: string): CandidateDate | undefined {
    return this.candidateDates.find((candidateDate) => candidateDate.id === candidateDateId);
  }

  public getAnswerableCandidateDates(): CandidateDate[] {
    const { isRestrictedDaysAgoWhenAnswer, daysAgoRestrictAnswer } = this;

    if (!isRestrictedDaysAgoWhenAnswer) return this.candidateDates;
    if (!daysAgoRestrictAnswer) return this.candidateDates;

    return this.candidateDates.filter(
      (candidateDate) =>
        daysAgoRestrictAnswer < differenceInCalendarDays(candidateDate.from, new Date())
    );
  }

  public getAnswerableCandidateDateIds(): string[] {
    return this.getAnswerableCandidateDates().map((v) => v.id);
  }

  /**
   * イベントが回答期限切れが確認する
   */
  public isExpired(): boolean {
    // 回答期限日の23:59:59までが期限内と判定
    const deadlineDate = this.getDeadlineDate();

    if (!deadlineDate) {
      return false;
    }

    return deadlineDate < new Date();
  }

  /**
   * 期日の3日前から期日当日まで "期日が近い" 扱いとする
   */
  public isNearDeadline(): boolean {
    if (!this.deadlineDate) {
      return false;
    }

    const diff = differenceInCalendarDays(this.deadlineDate, new Date());
    return 0 <= diff && diff <= 3;
  }

  public getDeadlineDate(): Date | undefined {
    if (!this.deadlineDate) {
      return undefined;
    }

    // 回答期限日の23:59:59までが期限内と判定
    return set(this.deadlineDate, { hours: 23, minutes: 59, seconds: 59 });
  }

  public canAnswer(): boolean {
    // NOTE: インタビュータイプのイベントは配信タイミングや回答期限がないため常に回答可能
    if (this.type === "new_interview") return true;

    const deadlineDate = this.getDeadlineDate();
    if (!this.scheduledDate || !deadlineDate) return false;

    if (!this.isExceededScheduledDate()) {
      return false;
    }

    if (!this.canAnswerAfterDeadline) {
      return !this.isExpired();
    }

    return !isPast(addDays(deadlineDate, 14));
  }

  /**
   * @deprecated 通常タイプと旧面談タイプのイベントでしか扱えない関数
   * - CandidateDateからSlotDateに移行した後に削除する
   */
  canEditAnswer(selectedCandidateDateId?: string): boolean {
    if (!this.canAnswer() || !selectedCandidateDateId) return false;

    // NOTE: 回答できる日付は編集可能な日付であることも意味する
    return this.getAnswerableCandidateDateIds().includes(selectedCandidateDateId);
  }

  /**
   * 選択済みのスロットに応じて回答を編集できるかどうかを返す
   * - 通常イベントではcandidateDateに基づいているのでslotDateへ移行したらこの関数を使うようにする
   */
  canEditAnswerBasedOnSlotDate(selectedOnnEventSlotDate?: OnnEventSlotDate): boolean {
    if (this.isRestrictedAnswerFromNewGraduate) return false;
    if (this.isRestrictedEditAnswerFromNewGraduate) return false;

    if (!selectedOnnEventSlotDate) return false;
    if (!this.isRestrictedDaysAgoWhenAnswer || !this.daysAgoRestrictAnswer) return true;

    return (
      this.daysAgoRestrictAnswer <
      differenceInCalendarDays(new Date(selectedOnnEventSlotDate.from), new Date())
    );
  }

  isDisplaySlotDateForPortal(
    targetSlotDate: OnnEventSlotDate,
    selectedSlotDateId?: string
  ): boolean {
    if (targetSlotDate.id === selectedSlotDateId) return true;
    if (!targetSlotDate.isPublishedAndNotDone()) return false;
    if (!this.isRestrictedDaysAgoWhenAnswer || !this.daysAgoRestrictAnswer) return true;

    return (
      this.daysAgoRestrictAnswer <
      differenceInCalendarDays(new Date(targetSlotDate.from), new Date())
    );
  }

  /**
   * イベントの候補日を更新する際に、候補日が問題ないかどうか検証する
   * @param {Pick<OnnEvent, "title" | "content" | "candidateDates">} updateObject 更新可能な値
   * @returns boolean
   */
  public validateCandidateDates(
    updateObject: Pick<OnnEvent, "title" | "content" | "candidateDates">
  ): boolean {
    // NOTE: 1st版では追加のみを許容するので既存のイベント候補日の値が変わっていれば不正な更新値となる
    return this.candidateDates.every((existedCandidateDate) => {
      const candidateDate = updateObject.candidateDates?.find(
        (v) => v.id === existedCandidateDate.id
      );
      if (!candidateDate) return false;

      if (!isEqual(candidateDate.from, existedCandidateDate.from)) return false;
      if (!isEqual(candidateDate.until, existedCandidateDate.until)) return false;

      return true;
    });
  }

  insertCandidateDate(candidateDate: CandidateDate): OnnEvent {
    return this.update({
      candidateDates: [...this.candidateDates, candidateDate],
    });
  }
  insertCandidateDates(candidateDates: CandidateDate[]): OnnEvent {
    return this.update({
      candidateDates: [...this.candidateDates, ...candidateDates],
    });
  }

  /**
   * イベントの回答期限を更新する際に、問題ないかどうか検証する
   * 回答期限の編集日を含む未来日への変更のみを許容する
   * @returns boolean
   */
  static validateDeadlineDateWhenUpdate(deadlineDate: Date): boolean {
    const today = set(new Date(), {
      hours: 0,
      minutes: 0,
      seconds: 0,
      milliseconds: 0,
    });
    return deadlineDate >= today;
  }

  /*
   * イベントに定員が存在するか確認する
   */
  public hasCapacity(): boolean {
    return this.candidateDates.some((v) => v.capacity !== undefined);
  }

  /*
   * 回答期限日から指定した日数前かどうかを返す
   * @param currentDate 比較する日付
   * @param diffDays 指定する日数
   */
  public isNDaysBeforeDeadline(currentDate: Date, diffDays: number): boolean {
    if (!this.deadlineDate) {
      return false;
    }

    // deadlineDate の時刻は適切な値が入っていないため、日付のみで比較する
    return (
      format(utcToZonedTime(this.deadlineDate, "Asia/Tokyo"), "yyyy-MM-dd") ===
      format(utcToZonedTime(addDays(currentDate, diffDays), "Asia/Tokyo"), "yyyy-MM-dd")
    );
  }

  isNormalEvent(): boolean {
    return this.type === "normal";
  }
  isNewInterviewEvent(): this is NewInterviewEvent {
    return this.type === "new_interview";
  }

  static castToNewInterviewEvent(onnEvent: OnnEvent): NewInterviewEvent {
    if (!onnEvent.isNewInterviewEvent()) throw new Error("onnEvent is not new interview event");
    return onnEvent;
  }

  static getEventTypeTextMap(type: OnnEvent["type"]) {
    return eventTypeTextMap[type];
  }

  /**
   * storage から取得したあとに変換するときなどに使用する
   * - NOTE: 型は Record が正しいが、optional などを想定していくと型定義が難しいので一旦 IOnnEvent にしている
   */
  static forceConvertToDate(onnEvent: ExcludeMethods<OnnEvent>) {
    return new OnnEvent({
      ...onnEvent,
      createdAt: new Date(onnEvent.createdAt),
      updatedAt: new Date(onnEvent.updatedAt),
      deadlineDate: onnEvent.deadlineDate ? new Date(onnEvent.deadlineDate) : undefined,
      scheduledDate: onnEvent.scheduledDate ? new Date(onnEvent.scheduledDate) : undefined,
      firstDeliveredAt: onnEvent.firstDeliveredAt ? new Date(onnEvent.firstDeliveredAt) : undefined,
      candidateDates: onnEvent.candidateDates.map((v) => CandidateDate.forceConvertToDate(v)),
    });
  }
}
