import { Box } from "@material-ui/core";
import { OnnEventPlace } from "@onn/common";
import React, { FC } from "react";
import { Controller, UseFormReturn } from "react-hook-form";
import styled from "styled-components";

import { InputState } from "../answerInterviewEventOnBehalfFormSchema";

import {
  Typography,
  Tooltip,
  Icon,
  Loading,
  TextField,
  SelectForm,
  FormControlLabel,
  RadioButton,
  TextareaAutosize,
} from "~/components/uiParts";

// 「開催方法」入力部分のコンポーネント
export const SlotTypeInputPart: FC<{
  form: UseFormReturn<InputState>;

  onnEventPlaces: OnnEventPlace[];
  isLoadingPlaces: boolean;
}> = ({ form, onnEventPlaces, isLoadingPlaces }) => {
  const { control, watch, setValue, trigger } = form;
  return (
    <Box display="flex" flexDirection="column" gridRowGap="24px">
      <Box display="flex" flexDirection="column" gridGap="12px">
        <Box display="flex" gridGap="8px" alignItems="center">
          <Typography variant="body2" bold>
            開催方法
          </Typography>
          <Tooltip
            title="開催方法に記載される概要は日程確定後、候補者側からいつでも確認可能になります。候補者へリマインドが送られる「開催日程の前日」までに概要の追加／編集を完了してください。"
            placement="top-start"
          >
            <Icon icon="help" size="sm" color="grey" />
          </Tooltip>
        </Box>
        <Box display="flex" flexDirection="column" gridGap="12px">
          <Controller
            name="slotInfo.type"
            control={control}
            render={({ field: { onChange, value } }) => (
              <StyledFormControlLabel
                value="online"
                control={
                  <StyledRadioButton
                    color="primary"
                    defaultColor={value === "online" ? "primary" : "default"}
                    checked={value === "online"}
                    onChange={(e) => {
                      onChange(e.target.value);
                      trigger([
                        "slotInfo.type",
                        "slotInfo.offline",
                        "slotInfo.offline.description",
                        "slotInfo.offline.address",
                        "slotInfo.offline.location",
                      ]);
                    }}
                    disabled={watch("slotSelectionType") === "existed"}
                  />
                }
                label={<Typography variant="body2">オンライン</Typography>}
              />
            )}
          />
          {watch("slotInfo.type") === "online" && (
            <Box display="flex" flexDirection="column" gridGap="8px">
              <Box mt="12px" pl="28px" gridGap="8px">
                <Box display="flex" alignItems="center" gridGap="16px">
                  <Icon icon="line" size="md" color="lightGrey" />
                  <Controller
                    name="slotInfo.online.description"
                    control={control}
                    render={({ field, fieldState }) => (
                      <TextareaAutosize
                        {...field}
                        fullWidth
                        placeholder="開催概要を入力"
                        minRows={3}
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                        disabled={watch("slotSelectionType") === "existed"}
                      />
                    )}
                  />
                </Box>
                <Box display="flex" alignItems="center" gridGap="16px">
                  <Icon icon="link" size="md" color="lightGrey" />
                  <Controller
                    name="slotInfo.online.url"
                    control={control}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        fullWidth
                        variant="outlined"
                        placeholder="オンライン会議ツールのURL"
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                        disabled={watch("slotSelectionType") === "existed"}
                      />
                    )}
                  />
                </Box>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
      <Box>
        <Controller
          name="slotInfo.type"
          control={control}
          render={({ field: { onChange, value } }) => (
            <StyledFormControlLabel
              value="offline"
              control={
                <StyledRadioButton
                  color="primary"
                  defaultColor={value === "offline" ? "primary" : "default"}
                  checked={value === "offline"}
                  onChange={(e) => {
                    onChange(e.target.value);
                    trigger([
                      "slotInfo.type",
                      "slotInfo.online",
                      "slotInfo.online.description",
                      "slotInfo.online.url",
                    ]);
                  }}
                  disabled={watch("slotSelectionType") === "existed"}
                />
              }
              label={<Typography variant="body2">オフライン・対面</Typography>}
            />
          )}
        />
        {watch("slotInfo.type") === "offline" && (
          <Box mt="12px" pl="28px">
            <Box display="flex" alignItems="center" gridGap="16px">
              <Icon icon="line" size="md" color="lightGrey" />
              <Controller
                name="slotInfo.offline.description"
                control={control}
                render={({ field, fieldState }) => (
                  <TextareaAutosize
                    {...field}
                    fullWidth
                    placeholder="開催概要を入力"
                    minRows={3}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    disabled={watch("slotSelectionType") === "existed"}
                  />
                )}
              />
            </Box>
            <Box display="flex" alignItems="center" gridGap="16px">
              <Icon icon="location" size="md" color="lightGrey" />
              <Controller
                name="slotInfo.offline.location"
                control={control}
                render={({ field, fieldState }) =>
                  isLoadingPlaces ? (
                    <Loading size="small" />
                  ) : (
                    <StyledSelectForm
                      {...field}
                      fullWidth
                      menuItems={onnEventPlaces.map((place) => ({
                        value: place.id,
                        name: place.name,
                      }))}
                      selected={field.value || null}
                      onChange={(e) => {
                        field.onChange(e.target.value);
                        const selectedPlace = onnEventPlaces.find(
                          (place) => place.id === e.target.value
                        );
                        if (selectedPlace) {
                          setValue("slotInfo.offline.postCode", selectedPlace.postCode);
                          setValue(
                            "slotInfo.offline.address",
                            `${selectedPlace.state}${selectedPlace.city}${selectedPlace.address1}${selectedPlace.address2}`
                          );
                        }
                        trigger([
                          "slotInfo.offline.location",
                          "slotInfo.offline.postCode",
                          "slotInfo.offline.address",
                        ]);
                      }}
                      errorBorder={!!fieldState.error}
                      errorText={fieldState.error?.message}
                      disabled={watch("slotSelectionType") === "existed"}
                    />
                  )
                }
              />
            </Box>
            <Box display="flex" alignItems="center" gridGap="16px" mt="8px">
              <Box width={"80px"}>
                <Typography variant="body2" color="textSecondary">
                  郵便番号
                </Typography>
              </Box>

              <Controller
                name="slotInfo.offline.postCode"
                control={control}
                render={({ field, fieldState }) => (
                  <StyledTextField
                    {...field}
                    variant="outlined"
                    fullWidth
                    onChange={(e) => {
                      field.onChange(e.target.value);
                      trigger([
                        "slotInfo.offline.location",
                        "slotInfo.offline.postCode",
                        "slotInfo.offline.address",
                      ]);
                    }}
                    placeholder="(例: 123-4567)"
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    disabled={watch("slotSelectionType") === "existed"}
                  />
                )}
              />
            </Box>
            <Box display="flex" alignItems="center" gridGap="16px" mt="8px">
              <Box width={"80px"}>
                <Typography variant="body2" color="textSecondary">
                  住所詳細
                </Typography>
              </Box>
              <Controller
                name="slotInfo.offline.address"
                control={control}
                render={({ field, fieldState }) => (
                  <TextareaAutosize
                    {...field}
                    fullWidth
                    onChange={(e) => {
                      field.onChange(e.target.value);
                      trigger([
                        "slotInfo.offline.location",
                        "slotInfo.offline.postCode",
                        "slotInfo.offline.address",
                      ]);
                    }}
                    placeholder=""
                    minRows={3}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    disabled={watch("slotSelectionType") === "existed"}
                  />
                )}
              />
            </Box>
          </Box>
        )}
      </Box>
      {form.getFieldState("slotInfo.type").error && (
        <Box ml="8px">
          <Typography variant="caption" color="error">
            {`「開催方法」を選択してください。`}
            {/* TODO: zodスキーマでエラーメッセージを管理させる */}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

const StyledSelectForm = styled(SelectForm)`
  .MuiInputBase-formControl {
    max-width: 240px;
    min-width: 100px;
    height: 40px;
  }
`;

const StyledFormControlLabel = styled(FormControlLabel)`
  &.MuiFormControlLabel-root {
    height: 24px;
    margin-left: 0px;
  }
  .MuiFormControlLabel-label {
    overflow: hidden;
  }
`;

const StyledRadioButton = styled(RadioButton)`
  padding: 0;
  margin-right: 8px;
`;

const StyledTextField = styled(TextField)`
  .MuiInputBase-root {
    height: 40px;
  }
`;
