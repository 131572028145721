import { Employee } from "@onn/common";

import { BodyRowData } from "./const";

import { RecruitmentProcessRecordByEmployee } from "~/hooks/recruitmentProcess/useRecruitmentProcessRecordsByEmployee";

export const createBodyRowData = (
  record: RecruitmentProcessRecordByEmployee,
  employee: Employee
): BodyRowData => {
  const hasEvent = !!record.relation.onnEvent;
  const isInterviewOrNewInterview =
    record.relation.onnEvent?.type === "interview" ||
    record.relation.onnEvent?.type === "new_interview";
  const shouldPromptForScheduleSetting =
    isInterviewOrNewInterview && record.progressStatusLabel === "未設定";

  return {
    recordLabel: record,
    status: record.progressStatusLabel,
    schedule: {
      isEvent: hasEvent,
      shouldPromptForScheduleSetting,
      onnEventInfo: record.relation.onnEvent
        ? {
            onnEvent: record.relation.onnEvent,
            candidateDate: record.relation.candidateDate,
            determinedDate: record.relation.onnEventDeterminedDate,
            slotDate: record.relation.slotDate,
          }
        : undefined,
      newGraduateId: employee.id,
    },
    assignee: {
      isEvent: hasEvent,
      shouldPromptForScheduleSetting,
      onnEventInfo: record.relation.onnEvent
        ? {
            onnEvent: record.relation.onnEvent,
            candidateDate: record.relation.candidateDate,
            determinedDate: record.relation.onnEventDeterminedDate,
            slotDate: record.relation.slotDate,
          }
        : undefined,
      assignee: record.relation.assignee,
      newGraduateId: employee.id,
    },

    evaluation: {
      isEvent: hasEvent,
      newGraduateId: employee.id,
      newGraduateName: employee.getName(),
      onnEventId: record.relation.onnEvent?.id,
      shouldPromptForScheduleSetting,
      canEvaluate: !!record.relation.onnEventDeterminedDate,
      evaluation: record.relation.onnEventEvaluation,
    },
    relatedFiles: [
      // タスクに紐づくファイル
      ...(record.relation.onnTasksWithRelation?.flatMap(
        (task) => task.answer?.answers.flatMap((a) => (a.type === "FILE" ? [a.filePath] : [])) || []
      ) || []),
      // イベントの評価に紐づくファイル
      ...(record.relation.onnEventEvaluation?.filePaths || []),
    ],
    updatedAt: record.processRecord.updatedAt,
  };
};
