import { useMemo } from "react";

import { useAllNewcomers } from "~/hooks/employee";
import { useTaggedEmployees } from "~/hooks/employeeTag/useTaggedEmployees";

function useNewGraduates(tagId: string) {
  const { data: allNewGraduates } = useAllNewcomers();
  const { data: taggedEmployeesData } = useTaggedEmployees(tagId);

  return useMemo(() => {
    // 既にタグが付与されているユーザーを除外する
    return allNewGraduates?.filter(
      (employee) =>
        !taggedEmployeesData?.taggedEmployees.some(
          (taggedEmployee) => taggedEmployee.id === employee.id
        )
    );
  }, [allNewGraduates, taggedEmployeesData]);
}

const csvHeader = ["姓", "名", "メールアドレス"] as const;

export function useNewGraduatesCsvData(tagId: string) {
  const newGraduates = useNewGraduates(tagId);

  const records = newGraduates?.map((employee) => {
    return [employee.lastName, employee.firstName, employee.email];
  });

  return {
    data: [csvHeader, ...(records ?? [])],
  };
}
