import { Box, Grid } from "@material-ui/core";
import React, { FC, memo } from "react";

import { NewGraduateTab } from "./parts/NewGraduateTab";
import { useViewModel } from "./useViewModel";

import { Typography, Loading } from "~/components/uiParts";

export const NewGraduateTopIndex: FC = memo(() => {
  const {
    filteredNewGraduatesForAllStatus,
    newGraduatesByRecruitmentStatuses,
    newGraduatesForTableView,
    selectedRecruitmentStatusIdOrNull,
    changeSelectedRecruitmentStatusIdOrNull,
    setSearchValue,
    allMentorIds,
    selectedMentorIds,
    setSelectedMentorIds,
    conditions,
    logicType,
    validConditionsCount,
    allNewGraduatesCount,
    defaultSearchResultCount,
    onSearchConfirm,
    onResetSearchConditions,
    onResetSelectedEmployees,
    onSelectEmployee,
    selectedEmployees,
    toggleSelectAll,
    allSelectionState,
    onConfirmUpdateRecruitmentStatus,
    onConfirmAddTags,
  } = useViewModel();

  return (
    <>
      {!filteredNewGraduatesForAllStatus ? (
        <Loading size="large" fullHeight />
      ) : (
        <>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <Box display="inline" pr={2}>
                <Typography variant="h4" bold display="inline">
                  候補者
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <NewGraduateTab
            filteredEmployeesForAllStatus={filteredNewGraduatesForAllStatus}
            newGraduatesByRecruitmentStatuses={newGraduatesByRecruitmentStatuses}
            employeesForTableView={newGraduatesForTableView}
            selectedRecruitmentStatusIdOrNull={selectedRecruitmentStatusIdOrNull}
            setSelectedRecruitmentStatusIdOrNull={changeSelectedRecruitmentStatusIdOrNull}
            setSearchValue={setSearchValue}
            allMentorIds={allMentorIds}
            selectedMentorIds={selectedMentorIds}
            setSelectedMentorIds={setSelectedMentorIds}
            conditions={conditions}
            logicType={logicType}
            validConditionsCount={validConditionsCount}
            allNewGraduatesCount={allNewGraduatesCount}
            defaultSearchResultCount={defaultSearchResultCount}
            onSearchConfirm={onSearchConfirm}
            onResetSearchConditions={onResetSearchConditions}
            onResetSelectedEmployees={onResetSelectedEmployees}
            onSelectEmployee={onSelectEmployee}
            selectedEmployees={selectedEmployees}
            toggleSelectAll={toggleSelectAll}
            allSelectionState={allSelectionState}
            onConfirmUpdateRecruitmentStatus={onConfirmUpdateRecruitmentStatus}
            onConfirmAddTags={onConfirmAddTags}
          />
        </>
      )}
    </>
  );
});
