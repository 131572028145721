import { Box, Grid } from "@material-ui/core";
import { OnnEvent } from "@onn/common";
import { format } from "date-fns";
import { ja } from "date-fns/locale";
import { isEmpty } from "lodash";
import React, { useCallback, useMemo } from "react";

import { OnnEventCondition } from "../../../types/condition";

import { ClosableContainer } from "../../parts/ClosableContainer";
import { SelectTargetButton } from "../../parts/buttons/SelectTargetButton";

import {
  Choice,
  SelectMultipleConditionDropdown,
} from "../../parts/dropdown-menus/SelectMultipleConditionDropdown";

import { SelectSingleConditionDropdown } from "../../parts/dropdown-menus/SelectSingleConditionDropdown";

import { CommonProps } from "./type";

import { Loading } from "~/components/uiParts";
import { useAllOnnEvent } from "~/hooks/onnEvent";

const TARGET = "onnEvent";

/**
 * NOTE: CandidateDate は同じラベルでもIDが違う場合があるため、IDをコンマつなぎで繋いで value にする
 * (Choice の型を変える手もあったが、このためだけに変えたくなかったため)
 */
const CANDIDATE_DATE_ID_VALUE_SEPARATOR = ",";

type Props = CommonProps<OnnEventCondition>;
export const OnnEventConditionSelector = ({
  index,
  condition,
  onChangeCondition,
  onChangeTarget,
}: Props): JSX.Element => {
  const { data: allOnnEvents, isLoading } = useAllOnnEvent();

  const {
    onChangeEvent,
    onChangeAbsenceStatuses,
    onChangeCandidateDates,
    onChangeEventFormatTypes,
  } = useOnChangeConditions({
    index,
    condition,
    onChangeCondition,
  });
  const { onnEventChoices, absenceStatusChoices, candidateDateChoices, eventFormatTypeChoices } =
    useChoices({ allOnnEvents, selectedEventId: condition.eventId });

  if (isLoading) {
    return <Loading size="small" />;
  }

  return (
    <Box width={"100%"}>
      <Grid container spacing={1}>
        <Grid item xs={4}>
          <SelectTargetButton
            target={TARGET}
            onChange={(target) => onChangeTarget(index, target)}
          />
        </Grid>
        <Grid item xs={4}>
          <SelectSingleConditionDropdown
            placeHolder="イベントを選択"
            key={"event"}
            selectedChoice={onnEventChoices.find((c) => c.value === condition.eventId)}
            choices={onnEventChoices}
            onChange={onChangeEvent}
          />
        </Grid>
        <Grid item xs={4}>
          <SelectMultipleConditionDropdown
            placeHolder="ステータスを選択"
            key={"attendanceStatuses"}
            selectedChoices={absenceStatusChoices.filter((c) =>
              condition.attendanceStatuses?.includes(c.value)
            )}
            choices={absenceStatusChoices}
            onChange={onChangeAbsenceStatuses}
          />
        </Grid>
      </Grid>
      {/* FIXME: gridGap があたらないので mt あてた */}
      <Box width={"100%"} mt="8px">
        <ClosableContainer
          label="詳細条件"
          defaultShown={
            !isEmpty(condition.candidateDateIds) || !isEmpty(condition.eventFormatTypes)
          }
        >
          <Box width={"100%"} mb={"8px"}>
            <Grid container spacing={1}>
              <Grid item xs={9}>
                <SelectMultipleConditionDropdown
                  placeHolder="開催日時を選択"
                  key={"candidateDates"}
                  selectedChoices={candidateDateChoices.filter((c) =>
                    c.value
                      .split(CANDIDATE_DATE_ID_VALUE_SEPARATOR)
                      .some((v) => condition.candidateDateIds?.includes(v))
                  )}
                  choices={candidateDateChoices}
                  onChange={onChangeCandidateDates}
                />
              </Grid>
              <Grid item xs={3}>
                <SelectMultipleConditionDropdown
                  placeHolder="開催方法を選択"
                  key={"eventFormatType"}
                  selectedChoices={eventFormatTypeChoices.filter((c) =>
                    condition.eventFormatTypes?.includes(c.value)
                  )}
                  choices={eventFormatTypeChoices}
                  onChange={onChangeEventFormatTypes}
                />
              </Grid>
            </Grid>
          </Box>
        </ClosableContainer>
      </Box>
    </Box>
  );
};

const useOnChangeConditions = ({
  index,
  condition,
  onChangeCondition,
}: Pick<Props, "index" | "onChangeCondition" | "condition">) => {
  const onChangeEvent = useCallback(
    (newChoice: Choice<string>) => {
      // NOTE: イベントを変更した場合はeventId以外をリセットする
      onChangeCondition(index, { target: "onnEvent", eventId: newChoice.value });
    },
    [index, onChangeCondition]
  );

  const onChangeAbsenceStatuses = useCallback(
    (newChoices: Choice<"ATTENDED" | "ABSENT">[]) => {
      onChangeCondition(index, {
        ...condition,
        attendanceStatuses: newChoices.map((c) => c.value),
      });
    },
    [condition, index, onChangeCondition]
  );

  const onChangeCandidateDates = useCallback(
    (newChoices: Choice<string>[]) => {
      onChangeCondition(index, {
        ...condition,
        candidateDateIds: newChoices.flatMap((c) =>
          c.value.split(CANDIDATE_DATE_ID_VALUE_SEPARATOR)
        ),
      });
    },
    [condition, index, onChangeCondition]
  );

  const onChangeEventFormatTypes = useCallback(
    (newChoices: Choice<"offline" | "online">[]) => {
      onChangeCondition(index, {
        ...condition,
        eventFormatTypes: newChoices.map((c) => c.value),
      });
    },
    [condition, index, onChangeCondition]
  );

  return {
    onChangeEvent,
    onChangeAbsenceStatuses,
    onChangeCandidateDates,
    onChangeEventFormatTypes,
  };
};

const useChoices = ({
  allOnnEvents,
  selectedEventId,
}: {
  allOnnEvents: OnnEvent[] | undefined;
  selectedEventId: string | undefined;
}) => {
  const selectedOnnEvent = useMemo(
    () => allOnnEvents && allOnnEvents.find((e) => e.id === selectedEventId),
    [allOnnEvents, selectedEventId]
  );

  const candidateDateChoices = useMemo(() => {
    if (!selectedOnnEvent) {
      return [];
    }

    const formatted: Choice<string>[] = selectedOnnEvent.candidateDates
      .sort((a, b) => {
        // 候補日の遅い順でソート
        if (a.from.getTime() === b.from.getTime()) {
          return a.until.getTime() - b.until.getTime();
        }
        return b.from.getTime() - a.from.getTime();
      })
      .map((cd) => ({
        label: `${format(cd.from, "MM/dd(E) HH:mm", { locale: ja })}〜${format(cd.until, "HH:mm", {
          locale: ja,
        })}`,
        value: cd.id,
      }));

    const choices: Choice<string>[] = [];
    formatted.forEach((f) => {
      const choiceWithSameLabel = choices.find((c) => c.label === f.label);
      if (choiceWithSameLabel) {
        choiceWithSameLabel.value = `${choiceWithSameLabel.value}${CANDIDATE_DATE_ID_VALUE_SEPARATOR}${f.value}`;
      } else {
        choices.push(f);
      }
    });
    return choices;
  }, [selectedOnnEvent]);

  if (!allOnnEvents) {
    return {
      onnEventChoices: [],
      candidateDateChoices: [],
      absenceStatusChoices: [],
      eventFormatTypeChoices: [],
    };
  }

  return {
    onnEventChoices: allOnnEvents.map((e) => ({ label: e.title, value: e.id })),
    candidateDateChoices,
    absenceStatusChoices: [
      { label: "参加" as const, value: "ATTENDED" as const },
      { label: "不参加" as const, value: "ABSENT" as const },
      { label: "参加未登録" as const, value: "UNREGISTERED" as const },
    ],
    eventFormatTypeChoices: [
      { label: "オンライン" as const, value: "online" as const },
      { label: "オフライン" as const, value: "offline" as const },
    ],
  };
};
