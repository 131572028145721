import { useCallback } from "react";

import { useSnackbar } from "~/hooks/shared";

export const useEnqueueSuccessSnackbar = () => {
  const { enqueueSnackbar } = useSnackbar();

  const enqueueSuccessSnackbar = useCallback(
    (deliveredCount: number) => {
      const text = `${deliveredCount}名の候補者に面談イベント配信・通知しました`;

      enqueueSnackbar(text, {
        variant: "success",
      });
    },
    [enqueueSnackbar]
  );

  return { enqueueSuccessSnackbar };
};
