import { Employee } from "@onn/common";
import { useCallback, useState } from "react";

import { useSignOut } from "../useSignOut";

import { auth } from "~/config/firebase";
import { useRedirectAfterRegistration } from "~/hooks/portal/redirect";
import { useSnackbar } from "~/hooks/shared";
import { useCurrentTenantId } from "~/hooks/tenant/useCurrentTenantId";
import { apiClient } from "~/libs";
import { AccountUseCase } from "~/service/usecases/employeeUseCase";
import { captureException } from "~/util";

/**
 * アカウント登録をする関数を提供するhooks
 */
export const useSignUp = () => {
  const [loading, setLoading] = useState<boolean>(false);

  const { enqueueSnackbar } = useSnackbar();
  const { signOut } = useSignOut();
  const { updateCurrentTenantId } = useCurrentTenantId();
  const { redirectAfterRegistration } = useRedirectAfterRegistration();

  /**
   * アカウント登録をする関数
   */
  const signUp = useCallback(
    async ({
      firstName,
      lastName,
      password,
      invitationToken,
    }: {
      firstName: string;
      lastName: string;
      password: string;
      invitationToken: string;
    }) => {
      setLoading(true);
      const isSignedIn = !!auth.currentUser;

      try {
        const employee = await apiClient
          .post("/signup", {
            firstName,
            lastName,
            password,
            invitationToken,
          })
          .then((res) => {
            return new Employee(res.data.userAccount);
          });

        await AccountUseCase.signIn(employee.email, password);

        updateCurrentTenantId(employee.tenantId);
        await redirectAfterRegistration(employee);
        enqueueSnackbar("アカウント登録が完了しました", { variant: "success" });
      } catch (e) {
        if (e instanceof Error) {
          enqueueSnackbar(e.message, { variant: "error" });
        }
        // in case error and signed in -> sign out
        if (isSignedIn) {
          await signOut();
        }
        captureException({
          error: e as Error,
          tags: { type: "useSignUp:signUp" },
        });
        setLoading(false);
      }
    },
    [enqueueSnackbar, redirectAfterRegistration, signOut, updateCurrentTenantId]
  );

  /**
   * 共有リンクからアカウント登録をする関数
   */
  const signUpByRegistrationInvitationLink = useCallback(
    async ({
      registrationInvitationLinkId,
      email,
      firstName,
      lastName,
      password,
    }: {
      registrationInvitationLinkId: string;
      email: string;
      firstName: string;
      lastName: string;
      password: string;
    }) => {
      setLoading(true);
      try {
        const response = await apiClient.post("/sign_up_by_registration_invitation_link", {
          id: registrationInvitationLinkId,
          email,
          firstName,
          lastName,
          password,
        });

        await AccountUseCase.signIn(email, password);
        updateCurrentTenantId(response.data.newGraduate.tenantId);
        await redirectAfterRegistration(new Employee(response.data.newGraduate));
        enqueueSnackbar("アカウント登録が完了しました", { variant: "success" });
      } catch (e) {
        if (e instanceof Error) {
          enqueueSnackbar(e.message, { variant: "error" });
        }
        await signOut();
        setLoading(false);
      }
    },
    [enqueueSnackbar, redirectAfterRegistration, signOut, updateCurrentTenantId]
  );

  return { signUp, loading, signUpByRegistrationInvitationLink };
};
