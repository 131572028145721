import { Box } from "@material-ui/core";
import { FormRevision, OnnFormTask, OnnFormTaskAnswer, OnnTaskQuestion } from "@onn/common";

import { isEmpty } from "lodash";
import React, { FC, useCallback, useEffect, useReducer, useRef, useState } from "react";

import styled from "styled-components";

import { ContainerPaper } from "../../_share/ContainerPaper";
import { OnnTaskDescriptionTypography } from "../../_share/TaskDesctiptionTypograpy";
import { TitleAndDeadlineDate } from "../../_share/TitleAndDeadlineDate";

import { usePageForFormTaskCore } from "../hooks/usePageForFormTaskCore";
import { createInitialState, reducer } from "../reducer";

import { AnswerEditor } from "./parts/AnswerEditor";
import { AnswerPreview } from "./parts/AnswerPreview";

import { BottomButtons } from "./parts/BottomButtons";

import { Divider } from "~/components/uiParts";

import { useUpdateOnnTaskFormAnswerDraft } from "~/hooks/onnTask";
import { OnnTaskFormAnswerDraft } from "~/types/firestore/OnnTaskFormAnswerDraft";
import { mixin } from "~/util";

export const PageForFormTaskCore: FC<{
  onnFormTask: OnnFormTask;
  questions: OnnTaskQuestion[];
  isPreview?: boolean;
  formRevision: FormRevision;
  onnFormTaskAnswer: OnnFormTaskAnswer;
  sampleFileSignedUrlMapObj?: Record<string, string>;
  draft: OnnTaskFormAnswerDraft;
}> = ({
  onnFormTask,
  questions,
  isPreview,
  formRevision,
  onnFormTaskAnswer,
  sampleFileSignedUrlMapObj,
  draft,
}) => {
  const isAlreadyAnswered = !isEmpty(onnFormTaskAnswer.answers);
  const [state, dispatch] = useReducer(
    reducer,
    createInitialState(
      questions,
      onnFormTaskAnswer.lastAnsweredAt ? onnFormTaskAnswer.answers : draft.answers
    )
  );
  const { onClickSubmit, isSubmitting, isDisabledSubmit } = usePageForFormTaskCore({
    state,
    isPreview,
    onnFormTaskId: onnFormTask.id,
    formRevisionId: formRevision.id,
    questions,
    isAlreadyAnswered,
  });
  const [isConfirm, setIsConfirm] = useState(false);
  const { updateDraft } = useUpdateOnnTaskFormAnswerDraft(draft);

  // 下書きを自動保存する
  const saveDraft = useCallback(
    async () => await updateDraft(state.answers),
    [state.answers, updateDraft]
  );

  const updateDraftRef = useRef<() => void>(saveDraft);

  useEffect(() => {
    updateDraftRef.current = saveDraft;
  }, [saveDraft]);

  useEffect(() => {
    if (isPreview || onnFormTaskAnswer.lastAnsweredAt) return;

    const interval = setInterval(() => updateDraftRef.current(), 3000);
    return () => clearInterval(interval);
  }, [isPreview, onnFormTaskAnswer.lastAnsweredAt]);

  return (
    <ContainerPaper>
      <Box mb="40px">
        <TitleAndDeadlineDate title={onnFormTask.title} deadlineDate={onnFormTask.deadlineDate} />
      </Box>
      {isPreview && (
        <Box mb="40px">
          <Box pb="16px">
            <OnnTaskDescriptionTypography description={onnFormTask.description} />
          </Box>
          <StyledDivider orientation="horizontal" variant="fullWidth" />
        </Box>
      )}

      <StyledCard mb="40px">
        {isConfirm ? (
          <AnswerPreview
            formRevision={formRevision}
            state={state}
            sampleFileSignedUrlMapObj={sampleFileSignedUrlMapObj ?? {}}
          />
        ) : (
          <AnswerEditor
            onnFormTask={onnFormTask}
            state={state}
            dispatch={dispatch}
            questions={questions}
            sampleFileSignedUrlMapObj={sampleFileSignedUrlMapObj ?? {}}
          />
        )}
      </StyledCard>

      <BottomButtons
        canEditAnswer={onnFormTask.canEditAnswer()}
        isConfirm={isConfirm}
        isPreview={!!isPreview}
        isSubmitting={isSubmitting}
        isDisabledSubmit={isDisabledSubmit}
        setIsConfirm={setIsConfirm}
        onClickSubmit={onClickSubmit}
      />
    </ContainerPaper>
  );
};

const StyledDivider = styled(Divider)`
  &.MuiDivider-root {
    margin-bottom: 12px;
  }
  margin-bottom: 12px;
  height: 2px;
`;

const StyledCard = styled(Box)`
  background-color: white;
  box-shadow: ${(props) => props.theme.shadows[10]};
  border-radius: 8px;
  margin-top: 28px;
  padding: 40px;

  ${mixin.breakDown.sm`
    box-shadow: none;
    border-radius: 0;
    margin-bottom: 24px;
    margin-top: 40px;
    padding: 0px;
    width: 100%;
  `}
`;
