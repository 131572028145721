import { ErrorStatus } from "@onn/common";
import { useEffect, useMemo, useState } from "react";

import { useGetErrorMessage } from "./useGetErrorMessage";

import { apiClient } from "~/libs";

type UseParseCsvReturn =
  | {
      status: "success";
      result: { tagId: string; emails: string[] };
    }
  | {
      status: "error";
      errors: string[];
    }
  | {
      status: "none";
    };

export function useParseCsv(file: File | undefined, tagId: string): UseParseCsvReturn {
  const { getErrorMessage } = useGetErrorMessage();

  const [result, setResult] = useState<UseParseCsvReturn>({ status: "none" });
  const reader = useMemo(() => new FileReader(), []);

  reader.onload = async () => {
    if (typeof reader.result !== "string") {
      return;
    }
    const [headers, ...records] = reader.result
      .trimEnd()
      .split(/\r\n|\r|\n/)
      .map((row) => row.split(",").map((cell) => cell.replaceAll(/^"|"$/g, "")));

    if ((headers && headers.length !== 1) || (headers && headers[0] !== "メールアドレス")) {
      setResult({
        status: "error",
        errors: [
          "ヘッダーの形式が不正です。サンプルファイルを参照し、正しい形式のヘッダーを使用してください。",
        ],
      });
      return;
    }

    const record = records.flat();

    if (!record) {
      setResult({
        status: "error",
        errors: ["CSVファイルにデータが存在しません"],
      });
      return;
    }

    const result = await apiClient.post("/tag_api/add-tag-on-employees", {
      tagId,
      emails: record,
      isOnlyValidate: true,
    });

    if (result.data?.errors) {
      const errors = new Map(Object.entries(result.data.errors)) as ErrorStatus;
      setResult({
        status: "error",
        errors: getErrorMessage(errors, true),
      });
      return;
    }

    setResult({ status: "success", result: { tagId, emails: record } });
  };

  useEffect(() => {
    if (file) {
      reader.readAsText(file);
    }
  }, [file, reader]);

  return result;
}
