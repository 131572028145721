import { useCallback, useState } from "react";

export const useUpdateRecruitmentStatusActionModal = () => {
  const [selectedStatusId, setSelectedStatusId] = useState<string | undefined>(undefined);

  const onChangeStatusId = useCallback((statusId: string) => {
    setSelectedStatusId(statusId);
  }, []);

  return {
    selectedStatusId,
    onChangeStatusId,
  };
};
