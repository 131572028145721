import { Box } from "@material-ui/core";
import React, { FC, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useCurriedOnClickZipDL } from "../../_share/hooks/useCurriedOnClickZipDL";

import { filterOptionStatus, FilterOptionStatus, useFilteredOnnTasks } from "./useFilterOnTasks";

import { EmployeeFilter } from "~/components/domains/employees";

import { OnnTaskTable } from "~/components/domains/onnTasks/OnnTaskTable";
import { useExtractAssigneeEmployeeIds } from "~/components/domains/onnTasks/useExtractAssigneeEmployeeIds";
import { Button, FilterButton, Icon, SearchForm, Typography } from "~/components/uiParts";

import { useCurrentUser } from "~/hooks/employee";
import { useOnnTasks } from "~/hooks/onnTask";

type StatusFilterOption = {
  status: FilterOptionStatus;
  title: string;
  totalCount: number;
};

export const OnnTaskListTab: FC = () => {
  const navigation = useNavigate();
  const { currentUser } = useCurrentUser();
  const { data: onnTasks = [], isLoading: isLoadingOnnTasks } = useOnnTasks({
    tenantId: currentUser.tenantId,
  });
  const { extractAssigneeEmployeeIds } = useExtractAssigneeEmployeeIds();
  const [selectedAssigneeIds, setSelectedAssigneeIds] = useState<string[]>([]);
  const [selectedFilterOptionStatus, setSelectedFilterOptionStatus] = useState<FilterOptionStatus>(
    filterOptionStatus.ALL
  );
  const [queryString, setQueryString] = useState<string>();
  const selectableEmployeeIds = extractAssigneeEmployeeIds(onnTasks);
  const { filteredOnnTasks } = useFilteredOnnTasks(
    selectedAssigneeIds,
    queryString,
    selectedFilterOptionStatus,
    onnTasks
  );

  const statusFilterOptions: StatusFilterOption[] = useMemo(
    () => [
      { status: filterOptionStatus.ALL, title: "全て", totalCount: onnTasks.length },
      {
        status: filterOptionStatus.EXPIRED,
        title: "回答期限終了",
        totalCount: onnTasks.filter((task) => task.isExceededDeadlineDate()).length,
      },
    ],
    [onnTasks]
  );

  const handleChangeTaskName = (value: string) => {
    setQueryString(value);
  };

  const handleClickStatusFilterButton = (status: FilterOptionStatus) => {
    setSelectedFilterOptionStatus(status);
  };

  const { curriedOnClickZipDL } = useCurriedOnClickZipDL();

  return (
    <>
      <Box py={5} display="flex">
        {statusFilterOptions.map((option: StatusFilterOption, i) => {
          return (
            <Box mr={i === 0 ? 5 : 2} key={`FilterButtonBox__${i}`}>
              <FilterButton
                title={option.title}
                totalCount={option.totalCount}
                isSelected={selectedFilterOptionStatus === option.status}
                onClick={() => handleClickStatusFilterButton(option.status)}
              />
            </Box>
          );
        })}
      </Box>

      <Box display="flex" justifyContent="space-between" gridGap="16px">
        <Box display="flex" flexDirection="column" gridRowGap="8px" width="212px">
          <Typography variant="body2" bold color="textSecondary" style={{ lineHeight: 1 }}>
            担当者
          </Typography>
          <EmployeeFilter
            currentUser={currentUser}
            selectableEmployeeIds={[currentUser.id, ...selectableEmployeeIds]}
            selectedEmployeeIds={selectedAssigneeIds}
            onChange={(selectedEmployeeIds) => {
              setSelectedAssigneeIds(selectedEmployeeIds);
            }}
          />
        </Box>
        <Box display="flex" alignItems="flex-end" gridGap="24px">
          <SearchForm
            variant="standard"
            onSearchValue={handleChangeTaskName}
            placeholder="タスク名で検索"
          />
          {currentUser.isAdmin() && (
            <Button
              color="primary"
              variant="outlined"
              borderRadius="regular"
              startIcon={<Icon icon="add" color="primary" size="md" />}
              onClick={() => navigation("/onn_tasks/new")}
            >
              新規作成
            </Button>
          )}
        </Box>
      </Box>
      <Box mt="32px">
        <OnnTaskTable
          onnTasks={filteredOnnTasks}
          isLoading={isLoadingOnnTasks}
          currentUser={currentUser}
          curriedOnClickZipDL={curriedOnClickZipDL}
        />
      </Box>
    </>
  );
};
