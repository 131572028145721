import { Employee } from "@onn/common";
import { useCallback } from "react";

import { useGenerateMutateEmployee } from "../useEmployee";

import { useAllEmployees, useAllNewcomers, useGenerateMutateNewGraduate } from "~/hooks/employee";
import { useSnackbar } from "~/hooks/shared";
import { functionOperator } from "~/infrastructure/api/functionOperator";
import { captureException } from "~/util";

export const useAssignMentorWithAssignContactRoom = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { generateMutateEmployee } = useGenerateMutateEmployee();
  const { generateMutateNewGraduate } = useGenerateMutateNewGraduate();
  const { mutate: mutateAllNewcomers } = useAllNewcomers();
  const { mutateAllEmployees } = useAllEmployees();

  const assignMentorWithAssignContactRoom = useCallback(
    async ({
      newGraduate,
      emailForInvite,
      newMentorId,
      mentor,
    }: {
      newGraduate: Employee;
      emailForInvite?: string;
      newMentorId?: string;
      mentor?: Employee;
    }) => {
      await functionOperator
        .httpsCallFor2ndGen("invitementorwithassigncontactroom", {
          employeeId: newGraduate.id,
          oldMentorId: newGraduate.mentorUserId,
          nextMentorId: newMentorId,
          mentorEmail: emailForInvite,
        })
        .then(async () => {
          enqueueSnackbar(`担当者を${mentor ? "変更" : "設定"}しました`, {
            variant: "success",
          });
          generateMutateEmployee(newGraduate.id)();
          generateMutateNewGraduate(newGraduate.id)();
          mutateAllNewcomers();
          mutateAllEmployees();
        })
        .catch((e) => {
          enqueueSnackbar(`担当者の${mentor ? "変更" : "設定"}に失敗しました`, {
            variant: "error",
          });
          captureException({
            error: e as Error,
            tags: {
              type: "useAssignMentorWithAssignContactRoom:assignMentorWithAssignContactRoom",
            },
          });
        });
    },
    [
      enqueueSnackbar,
      generateMutateEmployee,
      generateMutateNewGraduate,
      mutateAllNewcomers,
      mutateAllEmployees,
    ]
  );

  return { assignMentorWithAssignContactRoom };
};
