import { Employee, NewGraduateToDisplay } from "@onn/common";
import { uniqBy } from "lodash";
import React, { useCallback } from "react";

import { useAddNewGraduateToOnnEventWithCSVButtonHandler } from "./useAddNewGraduateToOnnEventWithCSVButtonHandler";

import { useModal } from "~/hooks/modal";

export const useAddNewGraduateToOnnEventButtonHandler = ({
  selectedNewGraduates,
  alreadyRegisteredNewGraduateIds,
  setSelectedNewGraduates,
}: {
  selectedNewGraduates: Employee[];
  alreadyRegisteredNewGraduateIds: string[];
  setSelectedNewGraduates: React.Dispatch<React.SetStateAction<Employee[]>>;
}) => {
  const { handleModal } = useModal();
  const { onClickUploadCSVButton } = useAddNewGraduateToOnnEventWithCSVButtonHandler({
    selectedNewGraduates,
    alreadyRegisteredNewGraduateIds,
    setSelectedNewGraduates,
  });

  const onSubmit = useCallback(
    async (modalSelectedNewGraduates: Employee[]) => {
      setSelectedNewGraduates(
        uniqBy([...modalSelectedNewGraduates, ...selectedNewGraduates], "id")
      );
    },
    [selectedNewGraduates, setSelectedNewGraduates]
  );

  // モーダルで選択可能な候補者をフィルタリング
  const filterSelectableEmployeeOnModal = useCallback(
    (newGraduate: NewGraduateToDisplay) => {
      if (!newGraduate.canUseNotificationFeatures()) return false;
      const isAlreadySelected = selectedNewGraduates.map((e) => e.id).includes(newGraduate.id);
      const isAlreadyRegistered = alreadyRegisteredNewGraduateIds.includes(newGraduate.id);
      return !isAlreadySelected && !isAlreadyRegistered;
    },
    [alreadyRegisteredNewGraduateIds, selectedNewGraduates]
  );

  // 「配信対象を追加」ボタンハンドラー
  const onClickAddNewGraduateToOnnEventButton = useCallback(() => {
    handleModal({
      name: "addNewGraduateModal",
      args: {
        filter: filterSelectableEmployeeOnModal,
        onSubmit,
        openCsvUploadModal: onClickUploadCSVButton,
      },
    });
  }, [filterSelectableEmployeeOnModal, handleModal, onClickUploadCSVButton, onSubmit]);

  return { onClickAddNewGraduateToOnnEventButton };
};
