import { OnnTaskTypes } from "@onn/common";
import React, { FC, memo, useCallback } from "react";

import { OnnTaskOverViewFormUIMemo } from "../_share_in_create_edit/components/OnnTaskOverViewFormUIMemo";

import { Action } from "../_share_in_create_edit/reducer";

// TODO: TODO_TASKを実装する時にコメントを外す
// const menuItems = Object.keys(OnnTaskTypes).map((key) => ({
//   value: key,
//   name: key === "FORM_TASK" ? "回答フォーム" : key === "TODO_TASK" ? "TODO" : "",
// }));

type Props = {
  selectedOnnTaskType: keyof typeof OnnTaskTypes;
  isAllowEditAnswer: boolean;
  dispatch: React.Dispatch<Action>;
  onnTaskTitleTextField: {
    value: string;
    error: boolean;
    helperText: string;
  };
  onnTaskDescriptionTextField: {
    value: string;
    error: boolean;
    helperText: string;
  };
};

export const OnnTaskOverViewForm: FC<Props> = ({
  selectedOnnTaskType,
  isAllowEditAnswer,
  dispatch,
  onnTaskTitleTextField,
  onnTaskDescriptionTextField,
}) => {
  const onChangeTaskType = useCallback(
    (e: React.ChangeEvent<{ value: unknown }>) => {
      const onnTaskTypes = e.target.value as keyof typeof OnnTaskTypes;
      dispatch({
        type: "CHANGE_ONN_TASK_TYPE",
        payload: {
          onnTaskTypes,
        },
      });
    },
    [dispatch]
  );

  const onChangeIsAllowEditAnswer = useCallback(() => {
    dispatch({
      type: "TOGGLE_IS_ALLOW_ANSWERED_ONCE",
    });
  }, [dispatch]);

  const onChangeTitle = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const isEmpty = e.target.value === "";
      dispatch({
        type: "CHANGE_ONN_TASK_TITLE",
        payload: {
          onnTaskTitle: e.target.value,
          error: isEmpty,
          helperText: isEmpty ? "タイトルを入力してください" : "",
        },
      });
    },
    [dispatch]
  );

  const onChangeDescription = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      const isEmpty = e.target.value === "";

      dispatch({
        type: "CHANGE_ONN_TASK_DESCRIPTION",
        payload: {
          onnTaskDescription: e.target.value,
          error: isEmpty,
          helperText: isEmpty ? "説明を入力してください" : "",
        },
      });
    },
    [dispatch]
  );

  return (
    <OnnTaskOverViewFormUIMemo
      selectedOnnTaskType={selectedOnnTaskType}
      onChangeTaskType={onChangeTaskType}
      onChangeTitle={onChangeTitle}
      isAllowEditAnswer={isAllowEditAnswer}
      onChangeIsAllowEditAnswer={onChangeIsAllowEditAnswer}
      onChangeDescription={onChangeDescription}
      onnTaskTitleTextField={onnTaskTitleTextField}
      onnTaskDescriptionTextField={onnTaskDescriptionTextField}
    />
  );
};

export const OnnTaskOverViewFormMemo = memo<Props>(OnnTaskOverViewForm);
