import { APISchema, Employee } from "@onn/common";
import { useCallback, useState } from "react";

import { useSignOut } from "../useSignOut";

import { auth } from "~/config/firebase";
import { useRedirectAfterRegistration } from "~/hooks/portal/redirect";
import { useSnackbar } from "~/hooks/shared";
import { useCurrentTenantId } from "~/hooks/tenant/useCurrentTenantId";
import { apiClient } from "~/libs";
import { AccountUseCase } from "~/service/usecases/employeeUseCase";
import { captureException } from "~/util";

/**
 * Line認証を使ってアカウント登録をする関数を提供するhooks
 */
export const useSignUpByLine = () => {
  const [loading, setLoading] = useState<boolean>(false);

  const { enqueueSnackbar } = useSnackbar();
  const { signOut } = useSignOut();
  const { updateCurrentTenantId } = useCurrentTenantId();
  const { redirectAfterRegistration } = useRedirectAfterRegistration();

  /**
   * アカウント登録をする関数
   */
  const signUpByLine = useCallback(
    async ({
      firstName,
      lastName,
      lineAccessToken,
      invitationToken,
    }: {
      firstName: string;
      lastName: string;
      lineAccessToken: string;
      invitationToken: string;
    }) => {
      setLoading(true);
      const isSignedIn = !!auth.currentUser;

      try {
        const { employee, customToken } = await apiClient
          .post("/signup_by_line", {
            firstName,
            lastName,
            lineAccessToken,
            invitationToken,
          })
          .then((res) => {
            return {
              employee: Employee.plainToInstance(res.data.userAccount),
              customToken: res.data.customToken,
            };
          });

        await AccountUseCase.signInWithCustomToken(customToken);

        updateCurrentTenantId(employee.tenantId);
        await redirectAfterRegistration(employee);
        enqueueSnackbar("アカウント登録が完了しました", { variant: "success" });
      } catch (e) {
        if (e instanceof Error) {
          enqueueSnackbar(e.message, { variant: "error" });
        }
        // in case error and signed in -> sign out
        if (isSignedIn) {
          await signOut();
        }
        captureException({
          error: e as Error,
          tags: { type: "useSignUp:signUp" },
        });
        setLoading(false);
      }
    },
    [enqueueSnackbar, redirectAfterRegistration, signOut, updateCurrentTenantId]
  );

  /**
   * LINE認証用共有リンクからアカウント登録をする関数
   */
  const signUpByLineRegistrationInvitationLink = useCallback(
    async ({
      registrationInvitationLinkId,
      email,
      firstName,
      lastName,
      lineAccessToken,
    }: {
      registrationInvitationLinkId: string;
      email: string;
      firstName: string;
      lastName: string;
      lineAccessToken: string;
    }) => {
      setLoading(true);
      try {
        const body: APISchema["/sign_up_by_line_registration_invitation_link"]["POST"]["body"] = {
          id: registrationInvitationLinkId,
          email,
          firstName,
          lastName,
          lineAccessToken,
        };
        const {
          data: { customToken, newGraduate },
        } = await apiClient.post("/sign_up_by_line_registration_invitation_link", body);

        await AccountUseCase.signInWithCustomToken(customToken);
        updateCurrentTenantId(newGraduate.tenantId);
        await redirectAfterRegistration(new Employee(newGraduate));
        enqueueSnackbar("アカウント登録が完了しました", { variant: "success" });
      } catch (e) {
        if (e instanceof Error) {
          enqueueSnackbar(e.message, { variant: "error" });
        }
        await signOut();
        setLoading(false);
      }
    },
    [enqueueSnackbar, redirectAfterRegistration, signOut, updateCurrentTenantId]
  );

  return { signUpByLine, signUpByLineRegistrationInvitationLink, loading };
};
