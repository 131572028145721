import { EventFormat } from "@onn/common";
import { useCallback } from "react";

import { useSnackbar } from "../shared";

import { apiClient } from "~/libs";
import { captureException } from "~/util";

export const useAnswerEventOnBehalfOfNewGraduate = () => {
  const { enqueueSnackbar } = useSnackbar();
  const execAnswerEventOnBehalfOfNewGraduate = useCallback(
    async ({
      onnEventId,
      employeeId,
      assigneeId,
      candidateDate,
      eventFormat,
    }: {
      onnEventId: string;
      employeeId: string;
      assigneeId?: string;
      candidateDate: {
        from: Date;
        until: Date;
      };
      eventFormat: EventFormat;
    }): Promise<void> => {
      try {
        await apiClient.post("/answer_event_on_behalf_of_new_graduate", {
          onnEventId,
          employeeId,
          assigneeId,
          candidateDate,
          eventFormat,
        });
        enqueueSnackbar("回答を追加しました", { variant: "success" });
      } catch (e) {
        const errorMessage =
          e instanceof Error && e.message ? e.message : "回答の追加に失敗しました";
        enqueueSnackbar(errorMessage, { variant: "error" });
        captureException({
          error: e as Error,
          tags: {
            type: "useAnswerEventOnBehalfOfNewGraduate:execAnswerEventOnBehalfOfNewGraduate",
          },
        });
        throw e;
      }
    },
    [enqueueSnackbar]
  );

  return { execAnswerEventOnBehalfOfNewGraduate };
};
