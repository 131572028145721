import { Typography as MuiTypography } from "@material-ui/core";
import React, { FC, ComponentProps, forwardRef, ElementType } from "react";
import styled from "styled-components";

type CustomProps = {
  bold?: boolean;
  disablePreWrap?: boolean;
  appendRequiredSymbol?: boolean;
};

type CustomVariant =
  | "h1"
  | "h2"
  | "h3"
  | "h4"
  | "body1"
  | "body2"
  | "button"
  | "caption"
  | "overline";

// 現状、MUIの問題でcomponentに型が聞いていない
// @see https://github.com/mui/material-ui/issues/19512
type WorkaroundForComponentProp = { component?: ElementType };

type Props = ChangeTypeOfKeys<ComponentProps<typeof MuiTypography>, "variant", CustomVariant> &
  CustomProps &
  WorkaroundForComponentProp;

const _Typography = (
  { appendRequiredSymbol, disablePreWrap, bold, ...props }: Props,
  ref: React.ForwardedRef<HTMLSpanElement>
) => (
  <StyledMuiTypography
    {...props}
    ref={ref}
    $appendRequiredSymbol={appendRequiredSymbol}
    $disablePreWrap={disablePreWrap}
    $bold={bold}
  />
);

// NOTE: なぜかref型がエラーになるため、anyで回避
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const Typography: FC<Props & { ref?: any }> = forwardRef(_Typography);

const StyledMuiTypography = styled(MuiTypography)<{
  $bold?: boolean;
  $disablePreWrap?: boolean;
  $appendRequiredSymbol?: boolean;
}>`
  &.MuiTypography-root {
    /* TODO: Mui v5対応後削除する */
    ${(props) => props.$bold && `font-weight: bold;`}

    /* noWrapのときはpre-wrapしないようにする */
    white-space: ${(props) => (props.$disablePreWrap || props.noWrap ? `nowrap` : `pre-wrap`)};

    ${(props) =>
      props.$appendRequiredSymbol &&
      `&:after {
        content: "*";
        color: ${props.theme.palette.secondary.main}
      }`}
  }

  /* overlineでアッパーケースに変換しない */
  &.MuiTypography-overline {
    text-transform: none;
  }
`;
