import { Box } from "@material-ui/core";
import { EmployeeTag } from "@onn/common";
import React, { FC, useCallback } from "react";

import { ContextMenu } from "./ContextMenu";

import { Typography } from "~/components/uiParts";
import { TableRowWrapper } from "~/components/uiParts/VirtualizedTable";
import { TableRow } from "~/components/uiParts/VirtualizedTable/TableRow";
import { VirtualizedTableV2 } from "~/components/uiParts/VirtualizedTable/VirtualizedTableV2";
import { useCurrentUser } from "~/hooks/employee";
import { useAddTagToEmployees } from "~/hooks/employee/useAddTagToEmployees";
import { useModal } from "~/hooks/modal";
import { useDeleteEmployeeTag, useUpdateEmployeeTag } from "~/hooks/tenantSetting";

// ====================
// props
// ====================

type Props = {
  employeeTags: EmployeeTag[];
  relationsCountMap: Record<string, number>;
  mutateEmployeeTags: () => void;
};

// ====================
// main
// ====================

export const EmployeeTagsTable: FC<Props> = ({
  employeeTags,
  relationsCountMap,
  mutateEmployeeTags,
}) => {
  // ====================
  // hooks
  // ====================

  const { handleModal } = useModal();
  const { currentUser } = useCurrentUser();
  const { deleteEmployeeTag } = useDeleteEmployeeTag();
  const { updateEmployeeTag } = useUpdateEmployeeTag();
  const { addTagToEmployees } = useAddTagToEmployees();

  const handleOpenEdit = useCallback(
    (newTag: EmployeeTag) => {
      handleModal({
        name: "addOrEditTagModal",
        args: {
          mode: "edit",
          selectedTag: newTag,
          onSubmit: async (tag) => {
            await updateEmployeeTag(currentUser.tenantId, newTag.update({ name: tag.name })).then(
              () => mutateEmployeeTags()
            );
          },
          employeeTags,
        },
      });
    },
    [handleModal, employeeTags, updateEmployeeTag, currentUser.tenantId, mutateEmployeeTags]
  );

  const handleOpenCsvUpload = useCallback(
    (tagId: string) => {
      handleModal({
        name: "addTagToEmployeesByCSVModal",
        args: {
          tagId,
          onSubmit: async ({ tagId, emails }: { tagId: string; emails: string[] }) =>
            await addTagToEmployees(tagId, emails).then(() => mutateEmployeeTags()),
        },
      });
    },
    [handleModal, addTagToEmployees, mutateEmployeeTags]
  );

  const handleOpenAssign = useCallback(
    (tagId: string) => {
      handleModal({
        name: "assignTagToEmployeesModal",
        args: {
          tagId,
          onSubmit: async (emails) =>
            await addTagToEmployees(tagId, emails).then(() => mutateEmployeeTags()),
          onClickCsvUpload: () => handleOpenCsvUpload(tagId),
        },
      });
    },
    [handleModal, addTagToEmployees, mutateEmployeeTags, handleOpenCsvUpload]
  );

  const handleOpenDelete = useCallback(
    (tagName: string, tagId: string) => {
      handleModal({
        name: "confirmDeleteTagModal",
        args: {
          deleteTagName: tagName,
          onAccept: async () => {
            await deleteEmployeeTag(currentUser.tenantId, tagId).then(() => mutateEmployeeTags());
          },
        },
      });
    },
    [deleteEmployeeTag, currentUser.tenantId, handleModal, mutateEmployeeTags]
  );

  // ====================
  // component
  // ====================

  const widthOptions = ["80%", "10%", "2.5%"];
  return (
    <VirtualizedTableV2<EmployeeTag>
      hover
      headers={[
        {
          text: "タグ名",
        },
        {
          text: "付与対象",
        },
        {
          text: "",
        },
      ]}
      rowHeight={75}
      widthOptions={widthOptions}
      emptyText="結果が見つかりませんでした"
      rows={employeeTags}
      rowRenderer={({ key, index, style, rowData: tag }) => {
        const contents = [
          <Box key="tagName">
            <Typography variant="body1" color="textSecondary">
              {tag.name}
            </Typography>
          </Box>,
          <Box key="numberOfTaggedEmployees">
            <Typography variant="h4">{relationsCountMap[tag.id] ?? "0"}</Typography>
          </Box>,
          <Box key="options">
            <ContextMenu
              id={tag.id}
              onClickEdit={() => handleOpenEdit(tag)}
              onClickAssign={() => handleOpenAssign(tag.id)}
              onClickDelete={() => handleOpenDelete(tag.name, tag.id)}
            />
          </Box>,
        ];
        return (
          <TableRowWrapper key={key} index={index} {...style}>
            <TableRow
              row={{
                contents,
                to: `/settings/employee_tags/${tag.id}`,
                isTargetBlank: true,
              }}
              widthOptions={widthOptions}
              hover={true}
            />
          </TableRowWrapper>
        );
      }}
    />
  );
};

// ====================
// styles
// ====================
