import { Box } from "@material-ui/core";
import { EmployeeTag, NewGraduatePropertyForInvite, OnnEvent } from "@onn/common";
import { isEmpty } from "lodash";
import React, { FC, useCallback, useMemo, useState } from "react";

import { ErrorMessageContent } from "./ErrorMessageContent";

import { Button, FilePicker, Icon, Typography } from "~/components/uiParts";
import { useCreateNewGraduateWithCsv } from "~/hooks/employee";
import { useConvertFileToBase64 } from "~/hooks/file";
import { useHandleInputCsvFileByFilePicker } from "~/hooks/file/useHandleInputCsvFileByFilePicker";
import { usePrompt, useSnackbar } from "~/hooks/shared";
import { captureException } from "~/util";

type Props = {
  onClickCancellation: () => void;
  onClickUploadButton: (inputValues: NewGraduatePropertyForInvite[]) => Promise<void>;
  employeeTags: EmployeeTag[];
  onnEvents: OnnEvent[];
  onUploadNewHires: () => void;
};

/**
 * 添付されたcsvファイルを管理するhooks
 */
const useHandleInputFile = () => {
  const { createNewGraduateWithCsv } = useCreateNewGraduateWithCsv();
  const { convertFileToBase64 } = useConvertFileToBase64();
  const checkingFileOnInputFile = useCallback(
    async (file: File) => {
      const base64EncodedCsvFile = await convertFileToBase64(file);
      const response = await createNewGraduateWithCsv({ base64EncodedCsvFile, isDryRun: true });
      return {
        isOk: response.isOk,
        errorMessages: response.isOk ? [] : response.errorMessages,
      };
    },
    [convertFileToBase64, createNewGraduateWithCsv]
  );

  const result = useHandleInputCsvFileByFilePicker({
    objectForErrorLog: { type: "createNewGraduateWithCSV" },
    checkingFileOnInputFile,
  });

  return { ...result };
};

/**
 * アップロードボタンが押された時の処理を管理するhooks
 */
const useHandleClickUploadButton = ({
  inputFile,
  closeModal,
}: {
  inputFile?: File;
  closeModal: () => void;
}) => {
  const [isUploading, setIsUploading] = useState(false);
  const { convertFileToBase64 } = useConvertFileToBase64();
  const { createNewGraduateWithCsv } = useCreateNewGraduateWithCsv();
  const { enqueueSnackbar } = useSnackbar();

  const handleClickUploadButton = useCallback(async () => {
    if (!inputFile) return;
    try {
      setIsUploading(true);

      const base64EncodedCsvFile = await convertFileToBase64(inputFile);
      const response = await createNewGraduateWithCsv({ base64EncodedCsvFile, isDryRun: false });
      const createdNewGraduates = response.isOk ? response.createdNewGraduates : [];
      if (!isEmpty(createdNewGraduates)) {
        enqueueSnackbar(`${createdNewGraduates.length}名の候補者が追加されました`, {
          variant: "success",
        });
      }

      closeModal();
    } catch (error) {
      captureException({
        error: error as Error,
        tags: {
          type: "InviteNewGraduateWithCSVModal:handleClickUploadButton",
        },
        extras: {
          inputFileText: await inputFile.text(),
        },
      });
      enqueueSnackbar(`候補者の追加に失敗しました。担当者までご連絡ください。`, {
        variant: "error",
      });
    } finally {
      setIsUploading(false);
    }
  }, [inputFile, convertFileToBase64, createNewGraduateWithCsv, closeModal, enqueueSnackbar]);

  return { handleClickUploadButton, isUploading };
};

export const UploadFileStepContent: FC<Props> = ({ onClickCancellation }) => {
  const { errorMessages, handleInputFile, isCheckingCsv, loadedFileName, inputFile } =
    useHandleInputFile();

  const { handleClickUploadButton, isUploading } = useHandleClickUploadButton({
    inputFile,
    closeModal: onClickCancellation,
  });

  usePrompt("候補者の招待が正常に完了しない場合があります", isUploading);

  const fileLabel = useMemo(() => {
    if (loadedFileName) return loadedFileName;
    if (isCheckingCsv) return "ファイルをチェックしています…";
    return "ファイルが選択されていません";
  }, [loadedFileName, isCheckingCsv]);

  const isUploadButtonDisabled = isCheckingCsv || isUploading || errorMessages.length > 0;
  const isUploadButtonLoading = isCheckingCsv || isUploading;

  const isFilePickerDisabled = isCheckingCsv || isUploading;

  return (
    <>
      <Typography>準備したCSVファイルを選択し、アップロードしてください。</Typography>
      {errorMessages.length > 0 && (
        <Box mt={4}>
          <ErrorMessageContent errorMessage={errorMessages.join("\n")} />
        </Box>
      )}
      <Box height={48} mt={4} mb={5} gridGap={16} display="flex" alignItems="center">
        <FilePicker
          accepts={["csv"]}
          inputAccept={".csv"}
          multiple={false}
          onChange={handleInputFile}
          disabled={isFilePickerDisabled}
        >
          <Box height={48}>
            <Button
              startIcon={<Icon icon="clip" size="sm" color="primary" />}
              color={"primary"}
              borderRadius={"regular"}
              variant={"outlined"}
              disabled={isFilePickerDisabled}
              fullHeight
            >
              ファイルを選択
            </Button>
          </Box>
        </FilePicker>
        <Typography variant="caption">{fileLabel}</Typography>
      </Box>
      <Box pl={5} pr={5} display="flex" alignItems="center" justifyContent="center">
        <Box width={"240px"} mr={3}>
          <Button
            fullWidth={true}
            color={"default"}
            borderRadius={"circle"}
            variant={"outlined"}
            onClick={onClickCancellation}
          >
            キャンセル
          </Button>
        </Box>
        <Box width={"240px"}>
          <Button
            fullWidth={true}
            color={"primary"}
            borderRadius={"circle"}
            variant={"contained"}
            onClick={handleClickUploadButton}
            disabled={isUploadButtonDisabled}
            isLoading={isUploadButtonLoading}
          >
            アップロード
          </Button>
        </Box>
      </Box>
    </>
  );
};
