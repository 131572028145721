import { useCallback } from "react";
import { useSWRConfig } from "swr";
import useSWRMutation from "swr/mutation";

import { useMutateAllNewcomers } from "./useAllNewcomers";

import { generateUseNewGraduateKey } from "./useNewGraduate";

import { generateUseEmployeeKeys } from "~/hooks/employee/useEmployee";
import { apiClient } from "~/libs";

export const useEditTagsOnEmployee = ({ employeeId }: { employeeId: string }) => {
  const { mutate } = useSWRConfig();
  const { mutateAllNewcomers } = useMutateAllNewcomers();

  const post = useCallback(
    async ({ employeeId }: { employeeId: string }, arg: { arg: { employeeTagIds: string[] } }) => {
      const { employeeTagIds } = arg.arg;
      await apiClient.post("/tag_api/edit-tags-on-employee", {
        employeeId,
        employeeTagIds,
      });
    },
    []
  );

  const onSuccess = useCallback(() => {
    mutate(generateUseEmployeeKeys(employeeId));
    mutate(generateUseNewGraduateKey({ newGraduateId: employeeId }));
    mutateAllNewcomers();
  }, [employeeId, mutate, mutateAllNewcomers]);

  return useSWRMutation({ key: "editTagsOnEmployee", employeeId }, post, {
    onSuccess,
  });
};
