import {
  toCloudFunctionsCompatible,
  ToCloudFunctionsCompatible,
} from "../../../../utils/toCloudFunctionsCompatible";

export class DeleteOnnEventResponse {
  static ResponseBody: ToCloudFunctionsCompatible<object>;
  public static createResponseBody() {
    return toCloudFunctionsCompatible({});
  }
}
