import { format } from "date-fns";
import { v4 } from "uuid";

import { IIndividualInvitationSettingSchema, individualInvitationSettingSchema } from "./schema";

/**
 * 候補者ユーザーが未招待→招待済みになるときに、イベント・タスクの回答を紐付けるためのリンク
 */
export class IndividualInvitationSetting implements IIndividualInvitationSettingSchema {
  static readonly validator = individualInvitationSettingSchema;
  public id: string;
  public tenantId: string;
  public spaceId: string;
  public employeeId: string;
  public onnEventIds: string[];
  public onnTaskIds: string[];
  public createdAt: Date;
  public updatedAt: Date;
  public lastUpdaterEmployeeId: string | null;

  constructor(init: ExcludeMethods<IndividualInvitationSetting>) {
    const parsedInit = IndividualInvitationSetting.validator.parse(init);

    this.id = parsedInit.id;
    this.tenantId = parsedInit.tenantId;
    this.spaceId = parsedInit.spaceId;
    this.employeeId = parsedInit.employeeId;
    this.onnEventIds = parsedInit.onnEventIds;
    this.onnTaskIds = parsedInit.onnTaskIds;
    this.createdAt = parsedInit.createdAt;
    this.updatedAt = parsedInit.updatedAt;
    this.lastUpdaterEmployeeId = parsedInit.lastUpdaterEmployeeId;
  }

  public static create(
    params: Omit<ExcludeMethods<IndividualInvitationSetting>, "id" | "createdAt" | "updatedAt">
  ): IndividualInvitationSetting {
    return new IndividualInvitationSetting({
      ...params,
      id: v4(),
      createdAt: new Date(),
      updatedAt: new Date(),
    });
  }

  // 個別招待設定は秒数までが同じ場合は、同一であると判定している
  public isEqualCreatedAtAndUpdatedAt() {
    return (
      format(this.createdAt, "yyyy-MM-dd HH:mm:ss") ===
      format(this.updatedAt, "yyyy-MM-dd HH:mm:ss")
    );
  }
}
