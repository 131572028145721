import { OnnEventAnswer } from "../../domain/OnnEvent";

export const createIMockOnnEventAnswer = (mock: Partial<OnnEventAnswer> = {}): OnnEventAnswer => {
  return new OnnEventAnswer({
    id: mock.id || "onnEventAnswer1",
    onnEventId: mock.onnEventId || "onnEvent1",
    tenantId: mock.tenantId || "tenant1",
    employeeId: mock.employeeId || "employee1",
    answer: mock.answer || {},
    selectedOnnEventSlotDateId: mock.selectedOnnEventSlotDateId,
    createdAt: mock.createdAt || new Date(2022, 2, 20),
    updatedAt: mock.updatedAt || new Date(2022, 2, 20),
  });
};
