import { DEFAULT_MAX_FILE_SIZE_MB, DEFAULT_UPLOAD_ACCEPTED_FILE_TYPES } from "@onn/common";
import React, { useMemo } from "react";

import { Control, Controller } from "react-hook-form";
import styled from "styled-components";

import { ContactMessagesOrderForm } from "../hooks/contactMessagesOrderFormSchema";

import { useContactMessageTemplatesMenuHandling } from "../hooks/useContactMessageTemplatesMenuHandling";

import { ContactMessageTemplateMenu } from "~/components/domains/contactRooms/ContactRoomItem/parts";
import { IconButton, TextareaAutosizeAttachableFile } from "~/components/uiParts";
import { FormWithTitlePaper } from "~/components/uiParts/FormWithTitlePaper";

type Props = {
  control: Control<ContactMessagesOrderForm, unknown>;
  changeFiles: (newFiles: (File | Pick<File, "name">)[]) => void;
  files: File[];
  setText: (templateMessage: string) => void;
  currentMessage: string;
};

export const TextFormPaper = ({
  control,
  changeFiles,
  files,
  setText,
  currentMessage,
}: Props): JSX.Element => {
  const contactMessageTemplatesMenuHandling = useContactMessageTemplatesMenuHandling({
    setNewMessage: setText,
    currentMessage,
  });

  const form = useMemo(
    () => (
      <Controller
        name="text"
        control={control}
        render={({ field, fieldState: { error } }) => (
          <StyledContentTextareaAutosizeAttachableFile
            fullWidth
            minRows={3}
            maxRows={15}
            maxFileSizeMb={DEFAULT_MAX_FILE_SIZE_MB}
            accepts={DEFAULT_UPLOAD_ACCEPTED_FILE_TYPES}
            onChangeFiles={changeFiles}
            attachedFiles={files}
            error={!!error}
            helperText={error?.message}
            footerLeftActions={
              /* メッセージテンプレートボタン */
              <IconButton
                icon="document"
                size="sm"
                color="grey"
                borderRadius="regular"
                onClick={contactMessageTemplatesMenuHandling.handleOpen}
              />
            }
            {...field}
            ref={contactMessageTemplatesMenuHandling.textareaAutosizeAttachableFileRef}
          />
        )}
      />
    ),
    [
      changeFiles,
      contactMessageTemplatesMenuHandling.handleOpen,
      contactMessageTemplatesMenuHandling.textareaAutosizeAttachableFileRef,
      control,
      files,
    ]
  );

  return (
    <>
      <FormWithTitlePaper
        title={"メッセージ本文"}
        tooltipTitle={"配信対象全員のコンタクトへ個別に同じ内容のメッセージが送信されます。"}
        form={form}
      />
      <ContactMessageTemplateMenu
        menuStartAnchorEl={contactMessageTemplatesMenuHandling.anchorEl}
        closeMenu={contactMessageTemplatesMenuHandling.handleClose}
        reflectMessage={contactMessageTemplatesMenuHandling.handleReflectMessage}
      />
    </>
  );
};

const StyledContentTextareaAutosizeAttachableFile = styled(TextareaAutosizeAttachableFile)`
  font-size: 14px;
  height: 72px;
  &:focus {
    // borderが太くなることによる微妙なstyleくずれを相殺する
    padding: 14.5px 15px;
  }
`;
