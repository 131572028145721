import { Box } from "@material-ui/core";
import { NewGraduateToDisplay, Employee } from "@onn/common";
import { IEmployeeInformationValue } from "@onn/common/domain/EmployeeInformation/schema";
import React, { FC, useMemo, useState } from "react";
import styled from "styled-components";

import { DownloadSampleFileStepContent } from "./DownloadSampleFileStepContent";
import { UploadFileStepContent } from "./UploadFileStepContent";

import { Modal, VerticalStepper } from "~/components/uiParts";
import { useCurrentSpace } from "~/hooks/space/useCurrentSpace";

type Props = {
  open: boolean;
  onCancel: () => void;
  allNewGraduates: NewGraduateToDisplay[];
  onClickUploadButton: (
    inputValues: {
      uniqueId: string;
      employeeName: Partial<Pick<Employee, "lastName" | "firstName">>;
      employeeInformationValue: IEmployeeInformationValue;
    }[]
  ) => Promise<void>;
};

export const UpdateEmployeeInformationWithCSVModal: FC<Props> = ({
  open,
  onCancel,
  onClickUploadButton,
  allNewGraduates,
}) => {
  const { currentSpace, spaces, isShowSpaceOnScreen } = useCurrentSpace();
  const [activeStep, setActiveStep] = useState(1);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const stepContent = useMemo(() => {
    switch (activeStep) {
      case 1:
        return (
          <DownloadSampleFileStepContent
            onClickNextButton={handleNext}
            allNewGraduates={allNewGraduates}
          />
        );
      case 2:
        return (
          <UploadFileStepContent
            allNewGraduates={allNewGraduates}
            onClickCancellation={onCancel}
            onClickUploadButton={onClickUploadButton}
            onUploadNewHires={onCancel}
          />
        );
    }
  }, [activeStep, allNewGraduates, onCancel, onClickUploadButton]);

  return (
    <Modal
      open={open}
      title={
        isShowSpaceOnScreen(spaces)
          ? `候補者情報一括登録｜${currentSpace.name}`
          : "候補者情報一括登録"
      }
      content={
        <Box display="flex" alignItems="flex-start">
          <VerticalStepper activeStep={activeStep} labels={["ファイル準備", "アップロード"]} />
          <StyledStepContentContainer>{stepContent}</StyledStepContentContainer>
        </Box>
      }
      onCancel={onCancel}
    />
  );
};

const StyledStepContentContainer = styled(Box)`
  padding-left: 32px;
  margin-left: 32px;
  width: 720px;
  border-left: 1px solid ${(props) => props.theme.palette.grey[100]};
`;
