import React, { FC, useMemo } from "react";
import { useController, Control } from "react-hook-form";
import styled from "styled-components";

import { InputState } from "./InputState";

import { TextareaAutosize } from "~/components/uiParts";

type Props = {
  control: Control<InputState>;
};

export const ContentTextarea: FC<Props> = ({ control }) => {
  const {
    field: { onChange, value },
    fieldState: { error },
  } = useController({ control, name: "content" });
  const isError = error !== undefined;
  const helperText = isError
    ? value.length === 0
      ? "説明文を入力してください"
      : error.message
    : "";
  const Component = useMemo(() => {
    return (
      <StyledEventContentTextarea
        name="content"
        placeholder="イベントの説明文を入力してください"
        maxTextCount={1000}
        value={value}
        onChange={onChange}
        fullWidth
        minRows={2}
        error={isError}
        autoFocus
        helperText={helperText}
      />
    );
  }, [helperText, isError, onChange, value]);

  return Component;
};

const StyledEventContentTextarea = styled(TextareaAutosize)`
  font-size: 14px;
  height: 72px;
  &:focus {
    // borderが太くなることによる微妙なstyleくずれを相殺する
    padding: 14.5px 15px;
  }
`;
