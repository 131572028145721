import { useState } from "react";

export const CAN_NOT_JOIN = "canNotJoin";

/**
 * イベント実施日フィルターのHooks
 * 行ではなく実施日列の表示制御を行うためにこのHooksを使う
 */
export const useFilterByCandidateDates = () => {
  const [selectedCandidateDateIds, setSelectedCandidateDateIds] = useState<string[]>([]);

  return {
    selectedCandidateDateIds,
    setSelectedCandidateDateIds,
  };
};
