import { z } from "zod";

import { Employee } from "../../../Employee";
import { EmailType, LineType } from "../../../Employee/AuthenticationType";
import { RecruitmentStatus } from "../../../Recruitment/RecruitmentStatus";
import { RegistrationInvitationLink } from "../../../RegistrationInvitationLink";
import { RegistrationInvitationLinkAccessLog } from "../../../RegistrationInvitationLinkAccessLog";

export interface APISchemaRegistrationInvitationLink {
  "/get_registration_invitation_links_default": {
    GET: {
      response: {
        data: ExcludeMethods<RegistrationInvitationLink> | null;
      };
    };
  };
  "/get_spaces_registration_invitation_links_default": {
    GET: {
      query: z.infer<typeof retrieveSpacesRegistrationInvitationLinksDefaultApiSchema>["query"];
      response: {
        data: {
          registrationInvitationLink: ExcludeMethods<RegistrationInvitationLink> | null;
          recruitmentStatus: ExcludeMethods<RecruitmentStatus> | null;
        };
      };
    };
  };
  "/check_email_for_registration_invitation_link": {
    POST: {
      body: {
        registrationInvitationLinkId: string;
        email: string;
      };
      response: {
        data: {
          isValid: boolean;
          isDuplicateInSameTenant: boolean;
          isDuplicateInOtherTenants: boolean;
          isDuplicateRegisteredUserInOtherTenants: boolean;
        };
      };
    };
  };
  "/get_registration_invitation_link": {
    GET: {
      query: {
        id: string;
      };
      response: {
        data: {
          registrationInvitationLink: ExcludeMethods<RegistrationInvitationLink>;
          recruitmentStatus: RecruitmentStatus;
          tenantInfo: { tenantName: string; tenantLogoUrl: string };
        };
      };
    };
  };
  "/sign_up_by_registration_invitation_link": {
    POST: {
      body: z.infer<(typeof RegistrationInvitationlinkApiSchema)["id"]["signup"]["post"]>["body"];
      response: {
        data: {
          registrationInvitationLink: RegistrationInvitationLink;
          newGraduate: Employee;
        };
      };
    };
  };
  "/sign_up_by_line_registration_invitation_link": {
    POST: {
      body: z.infer<
        (typeof RegistrationLineInvitationlinkApiSchema)["id"]["signup"]["post"]
      >["body"];
      response: {
        data: {
          registrationInvitationLink: RegistrationInvitationLink;
          newGraduate: Employee;
          customToken: string;
        };
      };
    };
  };
  "/retrieve_last_registration_invitation_link": {
    POST: {
      body: z.infer<typeof retrieveLastRegistrationInvitationLinkSchema>["body"];
      response: {
        data: {
          registrationInvitationLink: RegistrationInvitationLink | null;
        };
      };
    };
  };
  "/post_registration_invitation_link_access_logs": {
    POST: {
      body: z.infer<typeof postRegistrationInvitationLinksAccessLogsApiSchema>["body"];
      response: {
        data: ExcludeMethods<RegistrationInvitationLinkAccessLog>;
      };
    };
  };
  "/post_registration_invitation_link": {
    POST: {
      body: z.infer<typeof postRegistrationInvitationLinkApiSchema>["body"];
      response: {
        data: {
          registrationInvitationLink: ExcludeMethods<RegistrationInvitationLink>;
        };
      };
    };
  };
  "/patch_registration_invitation_link": {
    PATCH: {
      body: z.infer<typeof patchRegistrationInvitationLinkApiSchema>["body"];
      response: {
        data: {
          registrationInvitationLink: ExcludeMethods<RegistrationInvitationLink>;
        };
      };
    };
  };
}

export const RegistrationInvitationlinkApiSchema = {
  id: {
    signup: {
      post: z.object({
        body: z.object({
          id: z.string(),
          firstName: z.string(),
          lastName: z.string(),
          email: z.string().email(),
          password: z.string(),
          liffAccessToken: z.string().optional(),
        }),
      }),
    },
  },
};

export const retrieveSpacesRegistrationInvitationLinksDefaultApiSchema = z.object({
  query: z.object({
    "tenant-id": z.string(),
    "space-id": z.string(),
  }),
});

export const RegistrationLineInvitationlinkApiSchema = {
  id: {
    signup: {
      post: z.object({
        body: z.object({
          id: z.string(),
          firstName: z.string(),
          lastName: z.string(),
          email: z.string().email(),
          lineAccessToken: z.string(),
        }),
      }),
    },
  },
};

export const retrieveLastRegistrationInvitationLinkSchema = z.object({
  body: z.object({
    lineAccessToken: z.string(),
    liffId: z.string(),
  }),
});

export const postRegistrationInvitationLinksAccessLogsApiSchema = z.object({
  body: z.object({
    registrationInvitationLinkId: z.string(),
    lineAccessToken: z.string(),
    liffId: z.string(),
  }),
});

export const postRegistrationInvitationLinkApiSchema = z.object({
  body: z.object({
    conditions: z.object({
      spaceId: z.string(),
      selectableAuthenticationFlowTypes: z.union([
        z.tuple([z.literal("line" as LineType)]),
        z.tuple([z.literal("line" as LineType), z.literal("email" as EmailType)]),
      ]),
      recruitmentStatusId: z.string(),
      onnEventIds: z.array(z.string()),
      onnTaskIds: z.array(z.string()),
      tagIds: z.array(z.string()),
    }),
  }),
});

export const patchRegistrationInvitationLinkApiSchema = z.object({
  body: z.object({
    registrationInvitationLinkId: z.string(),
    conditions: z.object({
      selectableAuthenticationFlowTypes: z.union([
        z.tuple([z.literal("line" as LineType)]),
        z.tuple([z.literal("line" as LineType), z.literal("email" as EmailType)]),
      ]),
      recruitmentStatusId: z.string(),
      onnEventIds: z.array(z.string()),
      onnTaskIds: z.array(z.string()),
      tagIds: z.array(z.string()),
    }),
  }),
});
