import { APISchema, Reminder } from "@onn/common";
import useSWR, { SWRResponse } from "swr";

import { apiClient } from "~/libs";

/**
 * 期限切れのリマインダー一覧を取得するhooks
 */
export const useExpiredRemindersForAllContactRoom = ({
  tenantId,
}: {
  tenantId: string;
}): SWRResponse<Reminder[], Error> => {
  const asyncJob = async (): Promise<Reminder[]> => {
    const response = await apiClient.get(`/reminder_api/reminders`, queryParams);
    return response.data.map((d) => new Reminder(d));
  };

  return useSWR(generateExpiredRemindersForAllContactRoomKey(tenantId), asyncJob);
};

type Query = APISchema["/reminder_api/reminders"]["GET"]["query"];

const queryParams: Query = {
  "is-completed": false,
  "is-expired": true,
};

const generateExpiredRemindersForAllContactRoomKey = (tenantId: string) => {
  return { name: `reminders-${tenantId}`, ...queryParams };
};
