import { Box } from "@material-ui/core";
import React, { FC } from "react";
import styled from "styled-components";

import { Icon } from "~/components/uiParts";

export const OptionDeleteIcon: FC<{ handleDeleteOption: () => void }> = ({
  handleDeleteOption,
}) => {
  return (
    <StyledBox
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
      onClick={handleDeleteOption}
      width="40px"
      height="40px"
    >
      <Icon icon="close" size="md" color="grey" />
    </StyledBox>
  );
};

const StyledBox = styled(Box)`
  cursor: pointer;
`;
