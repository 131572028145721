import { Box } from "@material-ui/core";
import { OnnEvent } from "@onn/common";
import React, { ChangeEvent, useCallback, useMemo, useState } from "react";
import styled from "styled-components";

import { Row } from "../_share/Row";

import {
  Checkbox,
  Chip,
  FormControlLabel,
  IconButton,
  Loading,
  TextField,
  Typography,
} from "~/components/uiParts";
import { useCurrentUser } from "~/hooks/employee";
import { useNotExpiredOnnEvents } from "~/hooks/onnEvent";
import { useFilterObjectByPartialMatch } from "~/hooks/shared";

const useFilteredBySearchValue = (notExpiredOnnEvents: OnnEvent[]) => {
  const [searchValue, setSearchValue] = useState("");

  const { filterObjectByPartialMatch } = useFilterObjectByPartialMatch();

  /**
   * イベント名の絞り込み結果
   */
  const onnEventsFilteredBySearchValue = useMemo(() => {
    return filterObjectByPartialMatch({
      objects: notExpiredOnnEvents,
      searchText: searchValue,
      getProperties: [(onnEvent: OnnEvent) => onnEvent.title],
    });
  }, [filterObjectByPartialMatch, notExpiredOnnEvents, searchValue]);

  return { onnEventsFilteredBySearchValue, searchValue, setSearchValue };
};

export const OnnEventTabPanel = ({
  selectedOnnEventIds,
  addSelectedOnnEventIds,
  removeSelectedOnnEventIds,
}: {
  selectedOnnEventIds: string[];
  addSelectedOnnEventIds: (onnEventIds: string[]) => void;
  removeSelectedOnnEventIds: (onnEventIds: string[]) => void;
}) => {
  const { currentUser } = useCurrentUser();
  // NOTE: 個別招待時に付与できるイベントと同じく期限切れしていないイベントを取得する
  const { data: notExpiredOnnEvents = [], isLoading: isLoadingOnnEvents } = useNotExpiredOnnEvents({
    tenantId: currentUser.tenantId,
  });

  const { onnEventsFilteredBySearchValue, searchValue, setSearchValue } =
    useFilteredBySearchValue(notExpiredOnnEvents);

  const [isSelectAll, setIsSelectAll] = useState(false);

  const generateRowClickHandler = useCallback(
    ({ isSelected, onnEventId }: { isSelected: boolean; onnEventId: string }) =>
      () => {
        if (isSelected) {
          removeSelectedOnnEventIds([onnEventId]);
        } else {
          addSelectedOnnEventIds([onnEventId]);
        }
      },
    [addSelectedOnnEventIds, removeSelectedOnnEventIds]
  );

  return (
    <>
      <Box marginY="24px" display="flex" justifyContent="space-between">
        <Box display={"flex"} alignItems={"center"}>
          {/* すべてを選択する */}
          <StyledFormControlLabel
            control={
              <Checkbox
                checked={isSelectAll}
                onChange={() => {
                  if (isSelectAll) {
                    removeSelectedOnnEventIds(
                      onnEventsFilteredBySearchValue.map((onnEvent) => onnEvent.id)
                    );
                  } else {
                    addSelectedOnnEventIds(
                      onnEventsFilteredBySearchValue.map((onnEvent) => onnEvent.id)
                    );
                  }
                  setIsSelectAll(!isSelectAll);
                }}
              />
            }
            label={
              <StyledSelectAllBox display="flex">
                <Typography variant="body2" color="textSecondary">
                  すべて選択
                </Typography>
                <StyledChip
                  color="grey"
                  label={onnEventsFilteredBySearchValue.length.toString()}
                  bold
                />
              </StyledSelectAllBox>
            }
          />
        </Box>

        {/* イベント名 検索フィールド */}
        <Box width="258px">
          <StyledTextField
            fullWidth
            variant={"outlined"}
            placeholder={"イベント名"}
            value={searchValue}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setSearchValue(e.target.value);
            }}
            endAdornment={<IconButton icon="search" size="sm" />}
          />
        </Box>
      </Box>
      <StyledBox height={"212px"}>
        {isLoadingOnnEvents ? (
          <Loading fullHeight size="large" />
        ) : onnEventsFilteredBySearchValue.length === 0 ? (
          <Typography variant="caption" align="center" display="block" color="textSecondary">
            結果が見つかりませんでした
          </Typography>
        ) : (
          <div>
            {onnEventsFilteredBySearchValue.map((onnEvent) => {
              const isSelected = selectedOnnEventIds.includes(onnEvent.id);
              return (
                <Row
                  key={onnEvent.id}
                  isSelected={isSelected}
                  label={onnEvent.title}
                  onClick={generateRowClickHandler({ isSelected, onnEventId: onnEvent.id })}
                />
              );
            })}
          </div>
        )}
      </StyledBox>
    </>
  );
};

const StyledBox = styled(Box)`
  overflow-y: scroll;
`;

const StyledTextField = styled(TextField)`
  .MuiInputBase-input {
    font-size: 14px;
    height: 24px;
    padding: 8px 16px;
  }
`;

const StyledFormControlLabel = styled(FormControlLabel)`
  &.MuiFormControlLabel-root {
    width: 100%;
    margin-left: 0px;
    gap: 8px;
  }
  .MuiCheckbox-root {
    padding: 0px;
  }
`;

const StyledSelectAllBox = styled(Box)`
  display: flex;
  gap: 16px;
`;

const StyledChip = styled(Chip)`
  cursor: pointer;
`;
