import { ContactMessageDraft, AllContactRoom } from "@onn/common";
import {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";

import { useContactRoomListV2 } from "./useContactRoomListV2";

import { RecruitmentStatusesContext } from "~/components/providers/RecruitmentStatusProvider";
import { useContactMessageDraftByCreatedUserId } from "~/hooks/contactMessageDraft";
import { useCurrentUser } from "~/hooks/employee";
import { useLocalStorage, useQuery, useSetQueryString } from "~/hooks/shared";

const IS_DISPLAY_ONLY_MY_CANDIDATES = "isDisplayOnlyMyCandidates";

const useContactRoomIdInQuery = () => {
  const { query } = useQuery();
  const contactRoomIdInQuery = query.get("contactRoomId");

  return { contactRoomIdInQuery };
};

const useSyncContactRoomIdInQueryAndState = ({
  setSelectedContactRoomId,
  currentContactRooms,
}: {
  setSelectedContactRoomId: Dispatch<SetStateAction<string | undefined>>;
  currentContactRooms: AllContactRoom[];
}) => {
  const { contactRoomIdInQuery } = useContactRoomIdInQuery();

  useEffect(() => {
    if (currentContactRooms) {
      if (contactRoomIdInQuery) setSelectedContactRoomId(contactRoomIdInQuery);
    }
    // ページにアクセスして一度だけチェックすれば良いのでqueryは依存配列に含めない
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentContactRooms]);
};

export const useViewModelV2 = () => {
  const { recruitmentStatuses } = useContext(RecruitmentStatusesContext);

  const { currentUser } = useCurrentUser();
  const { storeValue, retrieveValue } = useLocalStorage();

  const [selectedContactRoomId, setSelectedContactRoomId] = useState<string>();

  const [activeRecruitmentStatusTab, setActiveRecruitmentStatusTab] = useState<string | null>(null);

  // NOTE: 初期表示タブは管理者の場合は全体、そうでない場合は自身が担当者のみのルームのみ
  const [isDisplayOnlyMyCandidates, setIsDisplayOnlyMyCandidates] = useState(
    retrieveValue<boolean>(IS_DISPLAY_ONLY_MY_CANDIDATES) ?? !currentUser.isAdmin()
  );

  const contactRoomListOperationObj = useContactRoomListV2({
    selectedRecruitmentStatusIds:
      activeRecruitmentStatusTab === null ? [] : [activeRecruitmentStatusTab],
    isDisplayOnlyMyCandidates,
  });

  useSyncContactRoomIdInQueryAndState({
    setSelectedContactRoomId,
    currentContactRooms: contactRoomListOperationObj.currentContactRooms,
  });

  const isLoading =
    contactRoomListOperationObj.isLoadingContactRooms ||
    contactRoomListOperationObj.isLoadingLatestMessages;

  const { contactMessageDrafts, isLoadingDraft, saveContactMessageDraft, contactMessageDraft } =
    useContactMessageDraft({ selectedContactRoomId });

  const { setQueryString } = useSetQueryString();

  const handleChangeActiveRecruitmentStatus = useCallback(
    (_: ChangeEvent<unknown>, recruitmentStatusIdOrAll: string) => {
      // NOTE: TabContextではnullを使えないため文字列"all"が渡ってきた場合はnullとみなす
      const recruitmentStatusIdOrNull =
        recruitmentStatusIdOrAll === "all" ? null : recruitmentStatusIdOrAll;
      setActiveRecruitmentStatusTab(recruitmentStatusIdOrNull);
    },
    []
  );

  const handleChangeIsDisplayOnlyMyCandidates = useCallback(() => {
    storeValue(IS_DISPLAY_ONLY_MY_CANDIDATES, !isDisplayOnlyMyCandidates);
    setIsDisplayOnlyMyCandidates((prev) => !prev);
  }, [isDisplayOnlyMyCandidates, storeValue]);

  const handleOnClickContactRoomListItem = useCallback(
    (contactRoomId: string | undefined) => {
      setSelectedContactRoomId(contactRoomId);
      setQueryString({ contactRoomId });
    },
    [setQueryString]
  );

  // NOTE: contactRoomIdから最新のメッセージを取得するためのMap
  const contactRoomIdToLatestMessageMap = new Map(
    contactRoomListOperationObj.latestContactMessages.map((latestContactMessage) => [
      latestContactMessage.contactRoomId,
      latestContactMessage,
    ])
  );

  // NOTE: contactRoomIdから未読数を取得するためのMap
  const contactRoomIdToUnreadCountMap = new Map(
    contactRoomListOperationObj.contactRoomNotificationCountList.map((unreadCountInfo) => {
      return [unreadCountInfo.contactRoomId, unreadCountInfo.unreadCount];
    })
  );

  const recruitmentStatusTabs: Array<{
    label: string;
    recruitmentStatusIdOrAll: string;
  }> = [
    { label: "すべて", recruitmentStatusIdOrAll: "all" },
    ...recruitmentStatuses.map(({ id, label }) => ({
      label,
      recruitmentStatusIdOrAll: id,
    })),
  ];

  return {
    ...contactRoomListOperationObj,
    handleChangeIsDisplayOnlyMyCandidates,
    activeRecruitmentStatusTab,
    handleChangeActiveRecruitmentStatus,
    recruitmentStatusTabs,
    isLoading,
    contactMessageDrafts,
    isLoadingDraft,
    saveContactMessageDraft,
    contactMessageDraft,
    isDisplayOnlyMyCandidates,
    selectedContactRoomId,
    handleOnClickContactRoomListItem,
    contactRoomIdToLatestMessageMap,
    contactRoomIdToUnreadCountMap,
  };
};

const useContactMessageDraft = ({ selectedContactRoomId }: { selectedContactRoomId?: string }) => {
  const { currentUser } = useCurrentUser();

  const {
    contactMessageDrafts,
    isLoading: isLoadingDraft,
    saveContactMessageDraft,
  } = useContactMessageDraftByCreatedUserId(currentUser.id);

  const contactMessageDraft = selectedContactRoomId
    ? contactMessageDrafts.find(({ contactRoomId }) => contactRoomId === selectedContactRoomId) ??
      ContactMessageDraft.create({
        tenantId: currentUser.tenantId,
        createdUserId: currentUser.id,
        contactRoomId: selectedContactRoomId,
        text: "",
      })
    : undefined;

  return { contactMessageDrafts, isLoadingDraft, saveContactMessageDraft, contactMessageDraft };
};
