import { Box } from "@material-ui/core";
import {
  CandidateDateWithNumberOfParticipants,
  EmployeeActiveLog,
  OnnEvent,
  OnnEventDeterminedDate,
  RecruitmentStatus,
} from "@onn/common";
import React, { FC } from "react";
import styled from "styled-components";

import { AnswerStatusCell } from "./Body/AnswerStatusCell";
import { AssigneeCell } from "./Body/AssigneeCell";
import { DateCell } from "./Body/DateCell";
import { EventDescriptionCell } from "./Body/EventDescriptionCell";
import { EventTypeCell } from "./Body/EventTypeCell";
import { UserIconWithLabel } from "./Body/UserIconWithLabel";

import { OnnEventAnswerWithEmployee } from "~/hooks/onnEvent";
import { captureException } from "~/util";

export const BodyCell: FC<
  {
    style: React.CSSProperties;
  } & BodyCellCoreProps
> = ({
  style,
  columnIndex,
  onnEvent,
  onnEventAnswer,
  columnLength,
  determinedDate,
  candidateDate,
  employeeActiveLog,
  recruitmentStatusMap,
}) => {
  return (
    <BodyCellWrapper style={{ ...style }}>
      <BodyCellCore
        columnIndex={columnIndex}
        onnEvent={onnEvent}
        onnEventAnswer={onnEventAnswer}
        columnLength={columnLength}
        determinedDate={determinedDate}
        candidateDate={candidateDate}
        employeeActiveLog={employeeActiveLog}
        recruitmentStatusMap={recruitmentStatusMap}
      />
    </BodyCellWrapper>
  );
};

export const BodyCellWrapper: FC<{
  children: React.ReactNode;
  style: React.CSSProperties;
}> = ({ children, style }) => {
  return <Box style={{ ...style }}>{children}</Box>;
};

type BodyCellCoreProps = {
  columnIndex: number;
  columnLength: number;
  onnEvent: OnnEvent;
  onnEventAnswer?: OnnEventAnswerWithEmployee;
  determinedDate?: OnnEventDeterminedDate;
  candidateDate?: CandidateDateWithNumberOfParticipants;
  employeeActiveLog?: EmployeeActiveLog;
  recruitmentStatusMap: Map<string, RecruitmentStatus>;
};

const BodyCellCore: FC<BodyCellCoreProps> = ({
  columnIndex,
  onnEvent,
  onnEventAnswer,
  determinedDate,
  candidateDate,
  employeeActiveLog,
  recruitmentStatusMap,
}) => {
  if (!onnEventAnswer) {
    captureException({
      error: new Error("onnEventAnswer が想定しない undefined になっています"),
      tags: { type: "BodyCellCore" },
    });
    return null;
  }
  const employee = onnEventAnswer.employee;

  const recruitmentStatus = recruitmentStatusMap.get(employee.recruitmentStatusId || "");
  if (!recruitmentStatus) {
    captureException({
      error: new Error("recruitmentStatus が想定しない undefined になっています"),
      tags: { type: "BodyCellCore" },
      extras: {
        employeeId: employee.id,
        recruitmentStatusId: employee.recruitmentStatusId,
        recruitmentStatusMap,
      },
    });
    return null;
  }

  // NOTE: 「ユーザーアイコン」列のレンダリング
  if (columnIndex === 0) {
    return (
      <BorderBox>
        <UserIconWithLabel
          employee={employee}
          key={onnEventAnswer.id}
          onnEvent={onnEvent}
          onnEventDeterminedDate={determinedDate}
          candidateDate={candidateDate}
        />
      </BorderBox>
    );
  }

  if (columnIndex === 1) {
    return (
      <AnswerStatusCell
        isAnswered={onnEventAnswer.isAnswered()}
        recruitmentStatus={recruitmentStatus}
        lastReadAt={employeeActiveLog ? employeeActiveLog.createdAt : null}
      />
    );
  }

  if (columnIndex === 2) {
    return <AssigneeCell determinedDate={determinedDate} onnEvent={onnEvent} />;
  }

  if (columnIndex === 3) {
    return <DateCell determinedDate={determinedDate} onnEvent={onnEvent} />;
  }

  if (columnIndex === 4) {
    return <EventTypeCell determinedDate={determinedDate} />;
  }

  if (columnIndex === 5) {
    return determinedDate?.eventFormat?.description ? (
      <EventDescriptionCell description={determinedDate.eventFormat.description} />
    ) : null;
  }

  return <></>;
};

const BorderBox = styled(Box)<{ $isLast?: boolean }>`
  height: 100%;
  width: 100%;
  border: solid ${(props) => props.theme.palette.grey[100]};
  border-width: 1px ${(props) => (props.$isLast ? 0 : 1)}px 0px 0px;
  display: flex;
  align-items: center;
`;
