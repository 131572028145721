import React, { FC, MouseEvent, useState } from "react";
import styled from "styled-components";

import { TextareaAutosize } from "~/components/uiParts";
import { useDebouncedCallback } from "~/hooks/shared";

type Props = {
  memo: string;
  onUpdateMemo: (newMemo: string) => Promise<void>;
};

export const NewGraduateMemo: FC<Props> = ({ memo, onUpdateMemo }) => {
  const [text, setText] = useState(memo);

  const handleDebounceCallback = useDebouncedCallback((callback) => callback(), 200);

  return (
    <StyledTextarea
      placeholder="関係者へのメモを入力しましょう"
      value={text}
      fullWidth
      maxRows={2}
      minRows={2}
      // 上位コンポーネントでのクリック時画面遷移を回避
      onClick={(e: MouseEvent<HTMLElement>) => {
        e.stopPropagation();
        e.preventDefault();
      }}
      onChange={(e) => {
        // 画面の描画だけ先に済ませ、更新処理は200ms後に走らせる
        setText(e.target.value);
        handleDebounceCallback(() => {
          onUpdateMemo(e.target.value);
        });
      }}
    />
  );
};

const StyledTextarea = styled(TextareaAutosize)`
  font-size: 12px;
  height: 72px;

  &:focus {
    // borderが太くなることによる微妙なstyleくずれを相殺する
    padding: 14.5px 15px;
  }
`;
