import { Box } from "@material-ui/core";
import { createIMockOnnEventAnswer, Employee } from "@onn/common";
import { format, isPast } from "date-fns";
import { ja } from "date-fns/locale";
import React, { FC, useCallback, useEffect, useMemo, useState } from "react";

import { Navigate, useNavigate, useParams } from "react-router-dom";

import styled from "styled-components";

import { OnnEventNewOrEditPageNewInterview } from "./components/OnnEventNewOrEditPageNewInterview";

import { Typography, Loading, Button, Divider, RadioButtonGroup } from "~/components/uiParts";

import { useCurrentUser } from "~/hooks/employee";
import { useCreateEmployeeActiveLog } from "~/hooks/employeeActiveLog";
import { useUpdateOnnEventAnswer, useOnnEventForPortal } from "~/hooks/onnEvent";
import {
  ONN_EVENT_PREVIEW_DATA_KEY,
  OnnEventDataForPortal,
} from "~/hooks/onnEvent/useOnnEventForPortal";

import { useLocalStorage, useQuery, useSnackbar } from "~/hooks/shared";
import { NotFound } from "~/pages/NotFound";

import { captureException, mixin } from "~/util";

export const OnnEventNewOrEdit: FC = () => {
  const { currentUser } = useCurrentUser();
  const { id: onnEventId } = useParams<"id">();
  const { query } = useQuery();

  const isPreview = query.get("preview") === "true";
  const { data: eventData, isLoading } = useOnnEventForPortal({
    employeeId: currentUser.id,
    id: typeof onnEventId === "string" ? onnEventId : undefined,
    isPreview,
  });
  const { enqueueSnackbar } = useSnackbar();

  const navigate = useNavigate();
  useEffect(() => {
    if (!isPreview && eventData && !eventData.onnEvent.canAnswer()) {
      enqueueSnackbar("回答期限が過ぎています", { variant: "error" });
      navigate("/portal/events");
    }
  }, [eventData, enqueueSnackbar, navigate, isPreview]);

  if (isLoading || (!isPreview && eventData && !eventData.onnEvent.canAnswer())) {
    return <Loading size="large" fullHeight />;
  }

  if (!eventData) {
    return <NotFound />;
  }

  /* NOTE: 初期版では面談イベントは入社者が回答を編集できないようにするため */
  if (eventData.onnEvent.type === "interview") {
    return <Navigate to="/portal/events" />;
  }

  if (eventData.onnEvent.type === "new_interview") {
    return <OnnEventNewOrEditPageNewInterview eventData={eventData} />;
  }

  return (
    <OnnEventNewOrEditPage eventData={eventData} isPreview={isPreview} currentUser={currentUser} />
  );
};

type OnnEventNewOrEditPageProps = {
  eventData: OnnEventDataForPortal;
  isPreview: boolean;
  currentUser: Employee;
};

export const OnnEventNewOrEditPage: FC<OnnEventNewOrEditPageProps> = ({
  eventData,
  isPreview,
  currentUser,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { storeValue } = useLocalStorage();
  const { execUpdateOnnEventAnswer, isLoading: isLoadingUpdating } = useUpdateOnnEventAnswer();

  const filteredCandidateDatesForDisplay = eventData.onnEvent
    .getAnswerableCandidateDates()
    .filter((date) => {
      if (!isPast(date.from)) return true;
      // NOTE: 参加できる日程がないを選択しているので過去の日程は全て表示しない
      if (eventData.onnEventAnswer.isUnavailableCandidates()) return false;
      const answeredCandidateDateId = eventData.onnEventAnswer.getPossibleCandidateDateId();
      // NOTE: 選択中の日程は過去であっても表示するためdefaultSelectedValueと同じ場合は除外しない
      return date.id === answeredCandidateDateId;
    });

  const defaultSelectedValue = eventData.onnEventAnswer.isAnswered()
    ? (() => {
        if (eventData.onnEventAnswer.isUnavailableCandidates())
          return filteredCandidateDatesForDisplay.length;
        const answeredCandidateDateId = eventData.onnEventAnswer.getPossibleCandidateDateId();
        return filteredCandidateDatesForDisplay.findIndex(
          (date) => date.id === answeredCandidateDateId
        );
      })()
    : null;

  const [selectedValue, setSelectedValue] = useState<number | null>(defaultSelectedValue);
  const navigate = useNavigate();
  const { createEmployeeLog } = useCreateEmployeeActiveLog();

  useEffect(() => {
    createEmployeeLog("VISITED_ONN_EVENT_FORM_PAGE", eventData.onnEvent.id, currentUser.tenantId);
    // NOTE: アクセスしたタイミングのみ記録するため、eslint-disable-next-lineを記述
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const previewAnswer = useCallback(() => {
    // 「参加できる日程がない」を選択した場合
    if (selectedValue === eventData.onnEvent.getAnswerableCandidateDates().length) {
      return eventData.onnEvent.candidateDates.reduce(
        (previous, current) => ({ ...previous, [current.id]: "impossible" }),
        {}
      );
      // 候補日程を選択した場合
    } else {
      // FIXME: 一旦selectedValueがnumberなので一時的なfindになっているがidを使った検索にリファクタする
      const candidateDate = eventData.onnEvent
        .getAnswerableCandidateDates()
        .find((_v, index) => index === selectedValue);
      return candidateDate ? { [candidateDate.id]: "possible" } : {};
    }
  }, [eventData.onnEvent, selectedValue]);

  const handleSubmit = useCallback(async () => {
    if (!eventData) return;

    try {
      if (isPreview) {
        storeValue(ONN_EVENT_PREVIEW_DATA_KEY, {
          onnEvent: eventData.onnEvent,
          onnEventAnswer: createIMockOnnEventAnswer({ answer: previewAnswer() }),
        });
      } else if (selectedValue === filteredCandidateDatesForDisplay.length) {
        // NOTE: 「参加できる日程がない」を選択した場合は、すべての候補日を参加不可にする
        const answer = eventData.onnEvent.candidateDates.reduce((previous, current) => {
          return { ...previous, [current.id]: "impossible" };
        }, {});
        await execUpdateOnnEventAnswer({
          onnEventAnswerId: eventData.onnEventAnswer.id,
          answer: answer,
          employeeId: currentUser.id,
          onnEventId: eventData.onnEvent.id,
        });
      } else {
        if (selectedValue == null) return;
        const targetCandidateDate = filteredCandidateDatesForDisplay[selectedValue];
        if (!targetCandidateDate) throw new Error();
        await execUpdateOnnEventAnswer({
          onnEventAnswerId: eventData.onnEventAnswer.id,
          answer: { [targetCandidateDate.id]: "possible" },
          employeeId: currentUser.id,
          onnEventId: eventData.onnEvent.id,
        });
      }
      navigate(`/portal/events/${eventData.onnEvent.id}/thanks${isPreview ? "?preview=true" : ""}`);
    } catch (e) {
      enqueueSnackbar("日程の回答に失敗しました。", { variant: "error" });
      captureException({
        error: e as Error,
        tags: { type: "OnnEventNewOrEditPage:handleSubmit" },
      });
    }
  }, [
    eventData,
    isPreview,
    selectedValue,
    filteredCandidateDatesForDisplay,
    navigate,
    storeValue,
    previewAnswer,
    execUpdateOnnEventAnswer,
    currentUser.id,
    enqueueSnackbar,
  ]);

  /**
   *   // FIXME: 単一回答ではない場合を想定しなければならない
   */
  const options = useMemo(() => {
    const answerableCandidateDateIds = eventData.onnEvent.getAnswerableCandidateDateIds();
    const candidateDatesOptions = filteredCandidateDatesForDisplay
      .filter((v) => answerableCandidateDateIds.includes(v.id))
      .map((date, index) => {
        const candidateDateDetail = (eventData.candidateDatesWithNumberOfParticipants || []).find(
          (v) => v.id === date.id
        );
        const labelDate = `${format(date.from, "MM/dd(E) HH:mm", { locale: ja })}〜${format(
          date.until,
          "HH:mm"
        )}`;
        const labelCapacity = `定員${date.capacity}人`;
        const overCapacity = `定員締め切り`;
        const option = (() => {
          if (eventData.onnEvent.hasCapacity()) {
            if (candidateDateDetail && !candidateDateDetail.canParticipate()) {
              return `${labelDate}  |  ${overCapacity}`;
            }
            return `${labelDate}  |  ${labelCapacity}`;
          }
          return labelDate;
        })();

        const disabled =
          isPast(date.from) || (candidateDateDetail && !candidateDateDetail.canParticipate());
        return {
          index,
          option,
          isOther: false,
          disabled,
        };
      });
    return [
      ...candidateDatesOptions,
      {
        index: candidateDatesOptions.length,
        option: "参加できる日程がない",
        isOther: false,
        disabled: false,
      },
    ];
  }, [
    eventData.candidateDatesWithNumberOfParticipants,
    eventData.onnEvent,
    filteredCandidateDatesForDisplay,
  ]);

  if (!eventData) {
    return <NotFound />;
  }

  return (
    <StyledBox maxWidth="848px">
      <Typography variant="h2" color="textPrimary" style={{ fontWeight: 400 }}>
        {eventData.onnEvent.title}
      </Typography>
      <StyledCard>
        <Box mb={1}>
          <Typography variant="body2" color="textPrimary">
            以下の日程から参加希望日程を1つだけ選択してください。回答を送信後、選択された日程での参加が自動で確定します。
          </Typography>
        </Box>
        <Divider />
        <Box my={4}>
          <StyledTypography variant="body1" bold>
            候補日程
          </StyledTypography>
          <StyledRadioButtonGroup
            options={options}
            name="answer"
            value={selectedValue}
            onChange={(value) => setSelectedValue(value)}
            onChangeFreeAnswerText={() => undefined}
          />
        </Box>
        <Button
          fullWidth
          variant="contained"
          color="primary"
          borderRadius="circle"
          onClick={handleSubmit}
          disabled={selectedValue == null || selectedValue === defaultSelectedValue}
          isLoading={isLoadingUpdating}
        >
          回答を送信
        </Button>
      </StyledCard>
    </StyledBox>
  );
};

const StyledBox = styled(Box)`
  background-color: ${(props) => props.theme.palette.grey[50]};
  padding: 80px 24px;

  ${mixin.portalSp`
    background-color: white;
    padding: 40px 24px;
    width: 100%;
    height: 100%;
  `}
`;

const StyledCard = styled(Box)`
  background-color: white;
  box-shadow: ${(props) => props.theme.shadows[10]};
  border-radius: 8px;
  margin-top: 40px;
  padding: 80px;

  ${mixin.portalSp`
    box-shadow: none;
    border-radius: 0;
    margin-bottom: 24px;
    margin-top: 28px;
    padding: 0px;
    width: 100%;
  `}
`;

const StyledTypography = styled(Typography)`
  &.MuiTypography-root {
    margin-bottom: 16px;
  }
`;

const StyledRadioButtonGroup = styled(RadioButtonGroup)`
  &.MuiFormGroup-root {
    gap: 8px;
  }
`;
