import { Box } from "@material-ui/core";
import React, { useState, useCallback, FC } from "react";
import styled from "styled-components";

import { useGetIconUrl } from "../../../hooks/useGetIconUrl";

import { Footer } from "./Footer";

import { ImageUploadArea, TextField, Typography } from "~/components/uiParts";
import { useCurrentUser } from "~/hooks/employee";
import { checkInputIconHasValues, useInputIcon, useUploadImageFile } from "~/hooks/file";
import { useSnackbar } from "~/hooks/shared";
import { usePrompt } from "~/hooks/shared/usePrompt";
import { EmployeeUseCase } from "~/service/usecases/employeeUseCase";
import { captureException, mixin } from "~/util";

export const SettingsAccountForLineAuth: FC = () => {
  const { currentUser, fetchCurrentUser } = useCurrentUser();
  const { enqueueSnackbar } = useSnackbar();

  const initialAccountInfo = {
    firstName: currentUser.firstName,
    lastName: currentUser.lastName,
  };
  const initialChangedAccountInfo = {
    firstName: false,
    lastName: false,
    icon: false,
  };

  const [newAccountInfo, setNewAccountInfo] = useState(initialAccountInfo);
  const [changedAccountInfo, setChangedAccountInfo] = useState(initialChangedAccountInfo);

  const { inputIcon, handleUploadIcon, handleErrorUploadIcon, resetInputIcon, allowImageSize } =
    useInputIcon({
      dataUrl: undefined,
      path: undefined,
    });

  const { getIconUrl } = useGetIconUrl();

  // このタイミングでprofileIconImageUrlがundefinedのことは仕様的にないはずなので、一旦空文字をいれてる。
  const defaultImage = getIconUrl(currentUser.profileIconImageUrl || "");
  const { uploadImageFile } = useUploadImageFile();

  // パスワードはブランクかポリシーを満たすもの
  const canSubmit =
    Object.values(changedAccountInfo).some((v) => v) && // 一箇所以上変更されている
    Boolean(newAccountInfo.firstName.trim()) && // 名はブランクでない
    Boolean(newAccountInfo.lastName.trim()); // 姓はブランクでない

  usePrompt(
    "編集内容を破棄しますか？",
    // 一箇所でも変更されている場合は確認ダイアログを表示する
    Object.values(changedAccountInfo).some((v) => v)
  );

  const updateAccountInfo = async (): Promise<void> => {
    try {
      const shouldUploadImage =
        checkInputIconHasValues(inputIcon) &&
        /data:image\//i.test(inputIcon.dataUrl) &&
        currentUser.profileIconImageUrl !== inputIcon.path;

      if (shouldUploadImage) {
        await uploadImageFile(inputIcon);
      }

      await EmployeeUseCase.updateCurrentEmployee({
        firstName: newAccountInfo.firstName,
        lastName: newAccountInfo.lastName,
        ...(shouldUploadImage ? { profileIconImageUrl: inputIcon.path } : {}),
      });

      setNewAccountInfo((prev) => ({ ...prev, password: "" }));
      setChangedAccountInfo(initialChangedAccountInfo);
      fetchCurrentUser();
      enqueueSnackbar("アカウント設定を更新しました", { variant: "success" });
    } catch (e) {
      if (e instanceof Error) {
        enqueueSnackbar(e.message, { variant: "error" });
      }
      captureException({
        error: e as Error,
        tags: { type: "SettingsAccount:updateAccountInfo" },
      });
    }
  };

  const handleClickSave = () => {
    updateAccountInfo();
  };

  // キャンセルすることで初期化する
  const handleDiscard = () => {
    setNewAccountInfo(initialAccountInfo);
    setChangedAccountInfo(initialChangedAccountInfo);
    resetInputIcon();
  };

  const handleChangeField = useCallback((value: string, target: keyof typeof newAccountInfo) => {
    setNewAccountInfo((prev) => ({ ...prev, [target]: value }));
    setChangedAccountInfo((prev) => ({ ...prev, [target]: true }));
  }, []);

  const handleChangeIcon = useCallback(
    (file: File) => {
      handleUploadIcon(file);
      setChangedAccountInfo((prev) => ({ ...prev, icon: true }));
    },
    [handleUploadIcon]
  );

  return (
    <>
      <Typography variant="body2" bold color="textSecondary">
        アイコン
      </Typography>
      <Typography variant="caption" align="left" color="textSecondary">
        アイコン画像を設定してください。顔写真を推奨しています。
      </Typography>
      <Box height="20px" />
      <ImageUploadArea
        alt="アイコン"
        defaultImage={defaultImage}
        imagePath={inputIcon?.dataUrl || ""}
        allowImageSize={allowImageSize}
        onChange={handleChangeIcon}
        onError={handleErrorUploadIcon}
        size="large"
      />
      <Box height="40px" />
      <StyledTitleTypography variant="body2" bold color="textSecondary">
        氏名
      </StyledTitleTypography>
      <Box display="flex" gridGap="16px">
        <TextField
          fullWidth
          name="lname"
          value={newAccountInfo.lastName}
          variant="outlined"
          onChange={(e) => handleChangeField(e.target.value, "lastName")}
        />
        <TextField
          fullWidth
          name="fname"
          value={newAccountInfo.firstName}
          variant="outlined"
          onChange={(e) => handleChangeField(e.target.value, "firstName")}
        />
      </Box>
      <Box height="40px" />
      <Footer onClickCancel={handleDiscard} onClickSave={handleClickSave} canSubmit={canSubmit} />
    </>
  );
};

const StyledTitleTypography = styled(Typography)`
  ${mixin.sp`
    &.MuiTypography-root {
      margin-bottom: 16px;
    }
  `}
`;
