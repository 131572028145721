import { Box } from "@material-ui/core";
import React from "react";
import styled from "styled-components";

import { Button } from "~/components/uiParts";

export const ModalFooter = ({
  onAddConditions,
  onCancel,
  isLoading,
}: {
  onAddConditions: () => Promise<void>;
  onCancel: () => void;
  isLoading: boolean;
}) => {
  return (
    <StyledButtonContainer>
      <Button
        variant="text"
        onClick={onCancel}
        color="default"
        borderRadius="circle"
        disabled={isLoading}
      >
        キャンセル
      </Button>
      <Button
        variant="contained"
        color="primary"
        borderRadius="circle"
        onClick={onAddConditions}
        disabled={isLoading}
        isLoading={isLoading}
      >
        条件を付与
      </Button>
    </StyledButtonContainer>
  );
};

const StyledButtonContainer = styled(Box)`
  display: flex;
  gap: 8px;
  justify-content: right;
`;
