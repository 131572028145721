import { v4 } from "uuid";

import { AttendanceStatus, IOnnEventDeterminedDate, onnEventDeterminedDateSchema } from "./schema";

export type EventFormat =
  | {
      type: "offline";
      description: string;
    }
  | {
      type: "online";
      description: string;
      url?: string;
    };

export type OnnEventDeterminedDateForNewInterviewEvent = Omit<
  IOnnEventDeterminedDate,
  "assigneeId" | "eventFormat" | "candidateDateId"
> & {
  onnEventSlotDateId: string;
};

export class OnnEventDeterminedDate implements IOnnEventDeterminedDate {
  static readonly validator = onnEventDeterminedDateSchema;

  readonly id: string;
  readonly onnEventId: string;
  readonly employeeId: string;
  readonly candidateDateId: string;
  readonly onnEventSlotDateId?: string;
  readonly createdAt: Date;
  readonly updatedAt: Date;
  readonly tenantId: string;
  readonly assigneeId?: string | null;
  readonly eventFormat?: EventFormat;
  readonly attendanceStatus: AttendanceStatus;
  readonly latestSentAtOnlineUrlNotification?: Date;

  constructor(init: ExcludeMethods<OnnEventDeterminedDate>) {
    const parsedInit = OnnEventDeterminedDate.validator.parse(init);

    this.id = parsedInit.id;
    this.onnEventId = parsedInit.onnEventId;
    this.employeeId = parsedInit.employeeId;
    this.candidateDateId = parsedInit.candidateDateId;
    this.onnEventSlotDateId = parsedInit.onnEventSlotDateId;
    this.createdAt = parsedInit.createdAt;
    this.updatedAt = parsedInit.updatedAt;
    this.tenantId = parsedInit.tenantId;
    this.assigneeId = parsedInit.assigneeId;
    this.attendanceStatus = parsedInit.attendanceStatus;
    this.eventFormat = parsedInit.eventFormat;
    this.latestSentAtOnlineUrlNotification = parsedInit.latestSentAtOnlineUrlNotification;
  }

  public update(
    update: Partial<Omit<ExcludeMethods<OnnEventDeterminedDate>, "updatedAt">>
  ): OnnEventDeterminedDate {
    return new OnnEventDeterminedDate({
      ...this,
      ...update,
      updatedAt: new Date(),
    });
  }

  public attend(candidateDateId?: string): OnnEventDeterminedDate {
    return this.update({
      attendanceStatus: "ATTENDED",
      candidateDateId: candidateDateId ?? this.candidateDateId,
    });
  }

  public isAttended(): boolean {
    return this.attendanceStatus === "ATTENDED";
  }

  public isRegisteredAttendance(): boolean {
    return this.attendanceStatus !== "UNREGISTERED";
  }

  /**
   * 対象のユーザのイベント日程が確定しているかどうかを判定するメソッド
   */
  public isEmployeeDeterminate({
    onnEventId,
    employeeId,
  }: {
    onnEventId: string;
    employeeId: string;
  }): boolean {
    return this.onnEventId === onnEventId && this.employeeId === employeeId;
  }

  /**
   * OnnEventDeterminedDateを新規作成するときに使うメソッド
   * @param {Optional<ExcludeMethods<OnnEventDeterminedDate>, "id" | "createdAt" | "updatedAt">} params
   * @returns OnnEventDeterminedDate
   */
  public static create(
    params: Optional<
      ExcludeMethods<OnnEventDeterminedDate>,
      "id" | "createdAt" | "updatedAt" | "attendanceStatus"
    >
  ): OnnEventDeterminedDate {
    return new OnnEventDeterminedDate({
      ...params,
      id: params.id ?? v4(),
      createdAt: params.createdAt ?? new Date(),
      updatedAt: params.updatedAt ?? new Date(),
      attendanceStatus: params.attendanceStatus ?? "UNREGISTERED",
    });
  }

  isOnnEventDeterminedDateForNewInterviewEvent(): this is OnnEventDeterminedDateForNewInterviewEvent {
    return !!this.onnEventSlotDateId;
  }

  static castToForNewInterviewEvent(
    onnEventDeterminedDate: OnnEventDeterminedDate
  ): OnnEventDeterminedDateForNewInterviewEvent {
    if (!onnEventDeterminedDate.isOnnEventDeterminedDateForNewInterviewEvent())
      throw new Error("This is not a OnnEventDeterminedDateForNewInterviewEvent");
    return onnEventDeterminedDate;
  }

  /**
   * storage から取得したあとに変換するときなどに使用する
   * - NOTE: 型は Record が正しいが、optional などを想定していくと型定義が難しいので一旦 IOnnEventDeterminedDate にしている
   */
  static forceConvertToDate(onnEventDeterminedDate: ExcludeMethods<OnnEventDeterminedDate>) {
    return new OnnEventDeterminedDate({
      ...onnEventDeterminedDate,
      createdAt: new Date(onnEventDeterminedDate.createdAt),
      updatedAt: new Date(onnEventDeterminedDate.updatedAt),
      latestSentAtOnlineUrlNotification: onnEventDeterminedDate.latestSentAtOnlineUrlNotification
        ? new Date(onnEventDeterminedDate.latestSentAtOnlineUrlNotification)
        : undefined,
    });
  }
}
