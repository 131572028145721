import { z } from "zod";

export type InputState = z.infer<typeof zodFormSchema>;

export const zodFormSchema = z.object({
  placeName: z.string().trim().min(1, { message: "必須項目です" }),
  postCode: z.string().trim().min(1, { message: "必須項目です" }),
  state: z.string().trim().min(1, { message: "必須項目です" }),
  city: z.string().trim().min(1, { message: "必須項目です" }),
  address1: z.string().trim().min(1, { message: "必須項目です" }),
  address2: z.string().trim().min(1, { message: "必須項目です" }),
});
