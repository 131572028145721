import { MenuItem } from "@material-ui/core";
import { FormRevision, OnnFormTaskAnswer } from "@onn/common";
import React, { FC, useCallback } from "react";
import { CSVLink } from "react-csv";
import styled from "styled-components";

import { Loading, Typography } from "~/components/uiParts";
import { useNewGraduates } from "~/hooks/employee";

import { useCreateCSVData } from "~/pages/onn_task/detail/hooks/OnnFormTaskAnswerResultTab/useCreateCSVData";
import { useGenerateSortedRevisionQuestionDataList } from "~/pages/onn_task/detail/hooks/OnnFormTaskAnswerResultTab/useGenerateSortedRevisionQuestionDataList";

export const CsvDLMenuItem: FC<{
  onnFormTaskAnswers: OnnFormTaskAnswer[];
  taskTitle: string;
  formRevisions: FormRevision[];
}> = ({ onnFormTaskAnswers, taskTitle, formRevisions }) => {
  const { generateSortedRevisionQuestionDataList } = useGenerateSortedRevisionQuestionDataList();
  const sortedRevisionQuestionDataList = generateSortedRevisionQuestionDataList(formRevisions);

  const targetEmployeeIds = onnFormTaskAnswers.map((answer) => answer.employeeId);
  const { data: targetNewGraduates, isLoading } = useNewGraduates({
    newGraduateIds: targetEmployeeIds,
  });
  const { csvData, filename } = useCreateCSVData({
    onnFormTaskAnswers,
    taskTitle,
    sortedRevisionQuestionDataList,
    targetNewGraduates: targetNewGraduates || [],
  });

  const handleClickHF = useCallback((e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
  }, []);

  if (isLoading)
    return (
      <MenuItem>
        <Loading size="small" fullHeight />
      </MenuItem>
    );
  return (
    <StyledCSVLink data={csvData} filename={filename}>
      <MenuItem onClick={handleClickHF}>
        <Typography variant="body2">{"CSVダウンロード"}</Typography>
      </MenuItem>
    </StyledCSVLink>
  );
};

const StyledCSVLink = styled(CSVLink)`
  text-decoration: none;
`;
