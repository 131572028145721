import { AllContactRoom, Employee } from "@onn/common";
import { useCallback, useState } from "react";

import { useFilterObjectByPartialMatch } from "~/hooks/shared";

/**
 * 検索フィールド入力フィルターに関わるhooks
 */
export const useFilterBySearchValue = () => {
  const [searchValue, setSearchValue] = useState<string>();

  const { filterObjectByPartialMatch } = useFilterObjectByPartialMatch();

  const handleChangeSearchValue = useCallback((searchValue: string) => {
    setSearchValue(searchValue);
  }, []);

  // TODO: LINEユーザーでのフィルターに対応する
  const filterContactRoomsBySearchTextV2 = useCallback(
    (contactRooms: AllContactRoom[]) => {
      if (!searchValue) return contactRooms;
      return filterObjectByPartialMatch<AllContactRoom>({
        objects: contactRooms,
        searchText: searchValue,
        getProperties: [
          (contactRoom: AllContactRoom) => {
            return contactRoom.employee instanceof Employee ? contactRoom.employee.email : "";
          },
          (contactRoom: AllContactRoom) => {
            return contactRoom.getRoomName();
          },
        ],
      });
    },
    [filterObjectByPartialMatch, searchValue]
  );

  return {
    searchValue,
    handleChangeSearchValue,
    filterContactRoomsBySearchTextV2,
  };
};
