import { throttle } from "lodash";
import { useEffect, useRef } from "react";

import { useContactMessages } from "~/hooks/contactMessage";
import { useCurrentUser } from "~/hooks/employee";
import { LatestContactMessageRepository } from "~/infrastructure/api/latestContactMessageRepository";

const latestContactMessageRepository = new LatestContactMessageRepository();

type Props = {
  contactRoomId: string;
};
export const useContactMessagesAndLogs = ({ contactRoomId }: Props) => {
  const { currentUser } = useCurrentUser();

  const {
    data: contactRoomAndMessages = { contactRoom: undefined, messages: [] },
    isLoading,
    mutate,
  } = useContactMessages({
    tenantId: currentUser.tenantId,
    contactRoomId,
  });

  // NOTE:throttleを使用することで、一気にメッセージの更新が行われても、CFへのリクエストが一気に行われることを防ぐ
  const throttleMutate = throttle(() => {
    mutate();
  }, 3000);

  // NOTE:
  // firestoreのonSnapshotでlatestContactMessageRepositoryのリスナーを登録すると、
  // リスナー登録時に必ず docChanges()["added"イベント] が発火するため、コンタクトルーム詳細アクセス時に
  // throttleMutate()が実行されてしまう。
  // それを防ぐため、isFinishedFirstListenerChangedを使用して、リスナー登録時は
  // throttleMutate()を実行しないようにしている。
  // https://firebase.google.com/docs/firestore/query-data/listen?hl=ja#view_changes_between_snapshots
  const isFinishedFirstListenerChanged = useRef(false);
  // コンタクトルーム一覧の最新メッセージをリアルタイムで更新するため、コンタクトルームの更新を監視
  useEffect(() => {
    const unsubscribeFunctions = latestContactMessageRepository.listenLatestContactMessages(
      currentUser.tenantId,
      [contactRoomId],
      () => {
        if (isFinishedFirstListenerChanged.current) {
          throttleMutate();
        }
        isFinishedFirstListenerChanged.current = true;
      }
    );
    return () => {
      unsubscribeFunctions.forEach((unsubscribeFunction) => {
        unsubscribeFunction();
      });
      isFinishedFirstListenerChanged.current = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contactRoomId]);

  return { contactRoomAndMessages, isLoading };
};
