import { Box } from "@material-ui/core";
import { NewGraduateToDisplay } from "@onn/common";
import React, { ComponentProps, FC, memo } from "react";

import { NewGraduateInvitationChip } from "./NewGraduateInvitationChip";
import { NewGraduateRecruitmentStatusChip } from "./NewGraduateRecruitmentStatusChip";

type Props = {
  newGraduate: NewGraduateToDisplay;
  updateRecruitmentStatus: ComponentProps<
    typeof NewGraduateRecruitmentStatusChip
  >["updateRecruitmentStatus"];
};

/**
 * 新卒候補者用
 * 候補者(選考)ステータス・招待ステータス を表示するためのチップ
 * 候補者(選考)ステータスは変更可能
 */
export const NewGraduateStatusChip: FC<Props> = memo(({ newGraduate, updateRecruitmentStatus }) => {
  return (
    <Box display={"flex"} gridColumnGap={"8px"}>
      <NewGraduateRecruitmentStatusChip
        recruitmentStatusInfo={{
          id: newGraduate.recruitmentStatusId,
          label: newGraduate.recruitmentStatusLabel,
          type: newGraduate.recruitmentStatusType,
        }}
        updateRecruitmentStatus={updateRecruitmentStatus}
      />
      {!newGraduate.isRegistered() && (
        <NewGraduateInvitationChip invited={newGraduate.isInvited()} />
      )}
    </Box>
  );
});
