import { useCallback } from "react";

import { apiClient } from "~/libs";

export const useUpdateAttendanceStatus = () => {
  const execUpdateAttendanceStatus = useCallback(
    async ({
      onnEventId,
      employeeId,
      updates,
    }: {
      onnEventId: string;
      employeeId: string;
      assigneeId?: string;
      updates:
        | { attendanceStatus: "ATTENDED"; selectedCandidateDateId: string }
        | { attendanceStatus: "ABSENT" | "UNREGISTERED" };
    }): Promise<void> => {
      await apiClient.patch("/update_attendance_status", {
        onnEventId,
        targetEmployeeId: employeeId,
        updates,
      });
    },
    []
  );

  return { execUpdateAttendanceStatus };
};
