import { parse } from "date-fns";
import { toNumber } from "lodash";
import { useState } from "react";
import { UseFormReturn } from "react-hook-form";

import { InputState } from "../answerInterviewEventOnBehalfFormSchema";

import { useCurrentUser } from "~/hooks/employee";
import { mutateOnnEventAnswers } from "~/hooks/onnEvent/answerResult/useOnnEventAnswers";
import { mutateOnnEventAnswersWithEmployee } from "~/hooks/onnEvent/answerResult/useOnnEventAnswersWithEmployee";
import { mutateDeterminedDate } from "~/hooks/onnEvent/determinedDate/useDeterminedDate";

import { useAnswerNewInterviewEventOnBehalfOfNewGraduate } from "~/hooks/onnEvent/useAnswerNewInterviewEventOnBehalfOfNewGraduate";
import { mutateCandidateDatesWithNumberOfParticipants } from "~/hooks/onnEvent/useCandidateDatesWithNumberOfParticipants";
import { mutateOnnEvent } from "~/hooks/onnEvent/useOnnEvent";
import { mutateOnnEventAnswersForDisplay } from "~/hooks/onnEventAnswer/useOnnEventAnswersForDisplay";
import { mutateOnnEventSlotDatesForDisplay } from "~/hooks/onnEventSlotDates/useOnnEventSlotDatesForDisplay";
import { mutateRecruitmentProcessRecordsByEmployee } from "~/hooks/recruitmentProcess/useRecruitmentProcessRecordsByEmployee";
import { useSnackbar } from "~/hooks/shared";
import { captureException } from "~/util";

export const useHandleSubmit = ({
  form,
  onnEventId,
  onSubmit,
}: {
  form: UseFormReturn<InputState>;
  onnEventId: string;
  onSubmit: () => void;
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { currentUser } = useCurrentUser();
  const { enqueueSnackbar } = useSnackbar();
  const { execAnswerNewInterviewEventOnBehalfOfNewGraduate } =
    useAnswerNewInterviewEventOnBehalfOfNewGraduate();

  const handleSubmit = form.handleSubmit(async (inputValue) => {
    setIsSubmitting(true);
    const execAnswerNewInterviewEventOnBehalfOfNewGraduateArgs = (() => {
      if (inputValue.slotSelectionType === "existed") {
        return {
          onnEventId,
          employeeId: inputValue.employeeId,
          selectedOnnEventSlotDateId: inputValue.selectedOnnEventSlotDateId,
        };
      } else {
        if (inputValue.slotInfo.type == null) return null;
        return {
          onnEventId,
          employeeId: inputValue.employeeId,
          assigneeId: inputValue.assigneeId ?? null,
          eventType: inputValue.slotInfo.type,
          selectedOnnEventSlotDateId: null,
          from: parse(
            inputValue.slotTimeInfo.slotFromTimeString,
            "HH:mm",
            inputValue.slotTimeInfo.slotDate
          ),
          until: parse(
            inputValue.slotTimeInfo.slotUntilString,
            "HH:mm",
            inputValue.slotTimeInfo.slotDate
          ),
          capacity: toNumber(inputValue.capacity),
          description:
            inputValue.slotInfo.type === "online"
              ? inputValue.slotInfo.online?.description
              : inputValue.slotInfo.offline.description,
          url:
            inputValue.slotInfo.type === "online" ? inputValue.slotInfo.online.url || null : null,
          eventPlaceId:
            inputValue.slotInfo.type === "offline" ? inputValue.slotInfo.offline.location : null,
          eventAddressPostCode:
            inputValue.slotInfo.type === "offline" ? inputValue.slotInfo.offline.postCode : null,
          eventAddressText:
            inputValue.slotInfo.type === "offline" ? inputValue.slotInfo.offline.address : null,
        };
      }
    })();

    if (!execAnswerNewInterviewEventOnBehalfOfNewGraduateArgs) {
      // NOTE: バリデーションで弾かれるはずなのでここには来ない想定
      const errorMessage = "回答の追加に失敗しました";
      enqueueSnackbar(errorMessage, { variant: "error" });
      captureException({
        error: new Error(errorMessage),
        tags: {
          type: "useAnswerEventOnBehalfOfNewGraduate:execAnswerEventOnBehalfOfNewGraduate",
        },
      });
      return;
    }

    const saveJob = execAnswerNewInterviewEventOnBehalfOfNewGraduate(
      execAnswerNewInterviewEventOnBehalfOfNewGraduateArgs
    );

    await saveJob
      .then(() => {
        onSubmit();
        mutateDeterminedDate(onnEventId);
        mutateOnnEvent(currentUser.tenantId, onnEventId);
        mutateOnnEventAnswers(onnEventId);
        mutateOnnEventAnswersWithEmployee(onnEventId);
        mutateCandidateDatesWithNumberOfParticipants(currentUser.id, onnEventId);
        mutateOnnEventAnswersForDisplay(onnEventId);
        mutateRecruitmentProcessRecordsByEmployee(inputValue.employeeId);
        mutateOnnEventSlotDatesForDisplay(onnEventId);
      })
      .catch((e) => {
        const errorMessage = e.message ? e.message : "回答の追加に失敗しました";
        enqueueSnackbar(errorMessage, { variant: "error" });
        captureException({
          error: e as Error,
          tags: {
            type: "useAnswerEventOnBehalfOfNewGraduate:execAnswerEventOnBehalfOfNewGraduate",
          },
        });
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  });

  return { handleSubmit, isSubmitting };
};
