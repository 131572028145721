import { Box } from "@material-ui/core";
import React from "react";
import { CSVLink } from "react-csv";
import styled from "styled-components";

import { useNewGraduatesCsvData } from "./useNewGraduatesCsvData";

import { Button, Icon, Typography } from "~/components/uiParts";

type Props = {
  tagId: string;
  onNext: () => void;
};

const sampleFileData = [["メールアドレス"], ["example@example.com"]];

export function FilePreparationStep({ tagId, onNext }: Props) {
  const { data: newGraduatesData } = useNewGraduatesCsvData(tagId);

  return (
    <Box display="grid" gridGap="40px">
      <Box display="grid" gridGap="32px" justifyItems="flex-start">
        <Typography>
          最新のサンプルファイルをダウンロードして、必要事項を記入したアップロード用ファイルをご準備ください。
        </Typography>
        <Box display="flex" gridGap="16px">
          <CSVLink data={sampleFileData} filename="タグ一括付与用サンプルファイル">
            <Button
              startIcon={<Icon icon="download" size="md" color="primary" />}
              color="primary"
              borderRadius="regular"
              variant="outlined"
            >
              サンプルファイルをダウンロード
            </Button>
          </CSVLink>
          <CSVLink data={newGraduatesData} filename="タグ一括付与_設定値ファイル">
            <Button
              startIcon={<Icon icon="download" size="md" color="primary" />}
              color="primary"
              borderRadius="regular"
              variant="outlined"
            >
              設定値ファイルをダウンロード
            </Button>
          </CSVLink>
        </Box>
        <UploadFileTips>
          <Typography bold={true}>アップロード用ファイルを作成するヒント</Typography>
          <Typography>
            フォーマットとなるサンプルファイルは内容が更新される可能性があります。毎回ダウンロードし直してご利用ください。
            <br />
            指定のある項目は設定値ファイルからコピー&ペーストして入力を行なってください。
          </Typography>
        </UploadFileTips>
      </Box>
      <Box display="grid" gridTemplateColumns="minmax(auto, 240px)" justifyContent="center">
        <Button
          fullWidth
          color="primary"
          variant="contained"
          borderRadius="circle"
          onClick={onNext}
        >
          次へ
        </Button>
      </Box>
    </Box>
  );
}

const UploadFileTips = styled(Box)`
  background-color: ${(props) => props.theme.palette.grey[50]};
  display: grid;
  gap: 8px;
  padding: 16px;
  justify-items: flex-start;
`;
