import { useCallback } from "react";

import { useAuthorizationNonGuarded } from "~/hooks/context";
import { useRedirectAfterRegistration } from "~/hooks/portal/redirect";
import { useSnackbar } from "~/hooks/shared";
import { useCurrentTenantId } from "~/hooks/tenant/useCurrentTenantId";

import { apiClient } from "~/libs";

/**
 * すでに別テナントでアカウント登録ずみのユーザーの登録を行う
 */
export const useAuthorizeForIndividual = () => {
  const { updateCurrentTenantId } = useCurrentTenantId();
  const { redirectAfterRegistration } = useRedirectAfterRegistration();
  const { enqueueSnackbar } = useSnackbar();
  const { mutateAuthorizedUsers } = useAuthorizationNonGuarded();

  const authorize = useCallback(
    async ({
      invitationToken,
      firstName,
      lastName,
      lineAccessToken,
    }: {
      invitationToken: string;
      firstName?: string;
      lastName?: string;
      lineAccessToken: string;
    }) => {
      const response = await apiClient
        .post("/authorize_for_individual", {
          invitationToken,
          firstName,
          lastName,
          lineAccessToken,
        })
        .catch((e) => {
          enqueueSnackbar(e.message, { variant: "error" });
          throw e;
        });

      await mutateAuthorizedUsers();
      updateCurrentTenantId(response.data.userAccount.tenantId);
      await redirectAfterRegistration(response.data.userAccount);
      enqueueSnackbar("アカウント登録が完了しました", { variant: "success" });

      return response;
    },
    [redirectAfterRegistration, updateCurrentTenantId, enqueueSnackbar, mutateAuthorizedUsers]
  );

  return { authorize };
};
