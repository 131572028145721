import { Box, Menu, MenuItem } from "@material-ui/core";
import { Employee, NewGraduatePropertyForInvite, type RecruitmentStatusType } from "@onn/common";
import { NewGraduateToDisplay } from "@onn/common";
import { IEmployeeInformationValue } from "@onn/common/domain/EmployeeInformation/schema";
import { isEmpty } from "lodash";
import pako from "pako";
import React, { FC, ReactNode, useMemo, useCallback, useEffect, useState } from "react";
import styled from "styled-components";

import { BulkActionControl } from "./BulkActionControl/BulkActionControl";
import { NewGraduateRequirementStatusFilter } from "./NewGraduateRequirementStatusFilter";
import { OpenInviteMembersModalButton } from "./OpenInviteMembersModalButton";

import { OpenSearchModalButton } from "./OpenSearchModalButton";

import { EmployeeFilter } from "~/components/domains/employees";
import {
  AnyInputCondition,
  AnyValidCondition,
} from "~/components/domains/employees/NewGraduateSearchModal/types/condition";
import { LogicType } from "~/components/domains/employees/NewGraduateSearchModal/types/logic-type";
import { NewGraduateTable } from "~/components/domains/employees/NewGraduateTable";
import { useRecruitmentStatusList } from "~/components/providers/RecruitmentStatusProvider";
import {
  IconButton,
  Loading,
  NotFoundPaper,
  Typography,
  TableActionsLayoutWithFiltersAndSearchForm,
  Button,
  Icon,
} from "~/components/uiParts";
import { SearchForm } from "~/components/uiParts/SearchForm";
import { useAllNewcomers, useCurrentUser } from "~/hooks/employee";
import { useCreateNewGraduates } from "~/hooks/employee/useCreateNewGraduates";
import { useSendInvitationNotifications } from "~/hooks/employee/useSendInvitationNotifications";
import { useUpdateEmployeeInformations } from "~/hooks/employeeInformation";
import { useEmployeeTags } from "~/hooks/employeeTag";
import { useModal } from "~/hooks/modal";
import { useNotExpiredOnnEvents } from "~/hooks/onnEvent";
import { useOnnTasks } from "~/hooks/onnTask";
import { useLocalStorage, useQuery, useSnackbar } from "~/hooks/shared";
import { State } from "~/hooks/shared/useToggleSelectAll/useToggleSelectAll";

type Props = {
  filteredEmployeesForAllStatus: Employee[];
  newGraduatesByRecruitmentStatuses: Record<RecruitmentStatusType, Employee[]>;
  employeesForTableView: NewGraduateToDisplay[];
  selectedRecruitmentStatusIdOrNull: string | null;
  setSelectedRecruitmentStatusIdOrNull: (recruitmentStatusIdOrNull: string | null) => void;
  allMentorIds: string[];
  selectedMentorIds: string[];
  setSearchValue: (value: string) => void;
  setSelectedMentorIds: (selectedMentorIds: string[]) => void;
  conditions: AnyInputCondition[];
  validConditionsCount: number;
  logicType: LogicType;
  defaultSearchResultCount: number;
  allNewGraduatesCount: number;
  onSearchConfirm(logicType: LogicType, conditions: AnyValidCondition[]): Promise<void>;
  onResetSearchConditions(): void;
  onResetSelectedEmployees(): void;
  onSelectEmployee(employee: Employee): void;
  selectedEmployees: Employee[];
  toggleSelectAll(): void;
  allSelectionState: State;
  onConfirmUpdateRecruitmentStatus(statusId: string): Promise<void>;
  onConfirmAddTags(tagIds: string[]): Promise<void>;
};

export const NewGraduateTab: FC<Props> = ({
  filteredEmployeesForAllStatus,
  newGraduatesByRecruitmentStatuses,
  employeesForTableView,
  selectedRecruitmentStatusIdOrNull,
  allMentorIds,
  selectedMentorIds,
  setSelectedRecruitmentStatusIdOrNull,
  setSearchValue,
  setSelectedMentorIds,
  conditions,
  validConditionsCount,
  logicType,
  defaultSearchResultCount,
  allNewGraduatesCount,
  onSearchConfirm,
  onResetSearchConditions,
  onResetSelectedEmployees,
  onSelectEmployee,
  selectedEmployees,
  toggleSelectAll,
  allSelectionState,
  onConfirmUpdateRecruitmentStatus,
  onConfirmAddTags,
}) => {
  const { data: allNewComers, mutate: mutateAllNewcomers } = useAllNewcomers();
  const { currentUser } = useCurrentUser();
  const { handleModal } = useModal();
  const { query } = useQuery();
  const { enqueueSnackbar } = useSnackbar();
  const { storeValue } = useLocalStorage();

  const { data: employeeTagsData, isLoading: isLoadingEmployeeTagsData } = useEmployeeTags();
  const { data: onnEvents, isLoading: isLoadingOnnEvents } = useNotExpiredOnnEvents({
    tenantId: currentUser.tenantId,
  });
  const { data: onnTasks, isLoading: isLoadingOnnTasks } = useOnnTasks({
    tenantId: currentUser.tenantId,
    isExceededDeadlineDate: false,
  });
  const { recruitmentStatuses } = useRecruitmentStatusList();
  const { createNewGraduates } = useCreateNewGraduates();
  const { sendInvitations } = useSendInvitationNotifications();
  const { execUpdateEmployeeInformations } = useUpdateEmployeeInformations();

  const openDialogType = query.get("openDialogType");

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleOpenMenu = useCallback((event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClickedCsvUploadMenuItem = useCallback(() => {
    handleModal({
      name: "inviteNewGraduateWithCSVModal",
      args: {
        inviteType: "newGraduate",
        employeeTags: employeeTagsData?.employeeTags || [],
        onnEvents: onnEvents || [],
        onnTasks: onnTasks || [],
        recruitmentStatuses: recruitmentStatuses || [],
        onClickUploadButton: async (inputValues: NewGraduatePropertyForInvite[]) => {
          await createNewGraduates(inputValues);
        },
      },
    });
    setAnchorEl(null);
  }, [
    handleModal,
    employeeTagsData?.employeeTags,
    onnEvents,
    onnTasks,
    recruitmentStatuses,
    createNewGraduates,
  ]);

  const handleOpenNewGraduateInviteModal = useCallback(() => {
    handleModal({
      name: "newGraduateInviteModal",
      args: {
        async onSubmit(employeeIds) {
          await sendInvitations(employeeIds);
        },
      },
    });
    setAnchorEl(null);
  }, [handleModal, sendInvitations]);

  const handleOpenNewGraduateSearchModal = useCallback(() => {
    handleModal({
      name: "newGraduateSearchModal",
      args: {
        conditions,
        logicType,
        onSearchConfirm,
        defaultSearchResultCount,
        allNewGraduatesCount,
      },
    });
  }, [
    allNewGraduatesCount,
    conditions,
    defaultSearchResultCount,
    handleModal,
    logicType,
    onSearchConfirm,
  ]);

  const handleOpenUpdateRecruitmentStatusActionModal = useCallback(() => {
    handleModal({
      name: "updateRecruitmentStatusActionModal",
      args: {
        onConfirm: onConfirmUpdateRecruitmentStatus,
      },
    });
  }, [onConfirmUpdateRecruitmentStatus, handleModal]);

  const handleOpenAddEmployeeTagsActionModal = useCallback(() => {
    handleModal({
      name: "addEmployeeTagsActionModal",
      args: {
        onConfirm: onConfirmAddTags,
      },
    });
  }, [handleModal, onConfirmAddTags]);

  const handleOpenDownloadEmployeesWithCSVActionModal = useCallback(() => {
    handleModal({
      name: "downloadEmployeesWithCSVActionModal",
      args: {
        employeeIds: selectedEmployees.map((e) => e.id),
      },
    });
  }, [handleModal, selectedEmployees]);

  const handleNavigateToBulkSendMessage = useCallback(() => {
    // NOTE: URL の文字数制限が2048文字程度なため、クエリパラメータは使用しなかった
    // NOTE: ローカルストレージの平均的な容量が5MBであることを考慮して圧縮後の値を保存し、解凍して使用するようにしている
    const compressedReceiverIds = pako.deflate(JSON.stringify(selectedEmployees.map((e) => e.id)));

    // NOTE: 連続で素早く一括メッセージ送信タブを開いた場合は最新の値で上書きされた状態で全てのタブが開くのでFBがあった場合に対応する
    // 対応する場合、一案として searchParams と localStorage に同一の文字列を持たせて参照することで実現できる
    storeValue("compressedReceiverIds", compressedReceiverIds);

    window.open("/contact_rooms/contact_messages_orders/new", "_blank");
  }, [selectedEmployees, storeValue]);

  const handleClickedUpdateEmployeeInformationWithCSVMenuItem = useCallback(() => {
    handleModal({
      name: "updateEmployeeInformationWithCSVModal",
      args: {
        allNewGraduates: allNewComers || [],
        onClickUploadButton: async (
          inputValues: {
            uniqueId: string;
            employeeName: Partial<Pick<Employee, "lastName" | "firstName">>;
            employeeInformationValue: IEmployeeInformationValue;
          }[]
        ) => {
          await execUpdateEmployeeInformations({ data: inputValues })
            .then(() => {
              enqueueSnackbar("候補者情報を一括更新しました", {
                variant: "success",
              });
              mutateAllNewcomers();
            })
            .catch(() => {
              enqueueSnackbar("候補者情報の一括更新に失敗しました。再度お試しください。", {
                variant: "error",
              });
            });
        },
      },
    });
    setAnchorEl(null);
  }, [
    handleModal,
    allNewComers,
    execUpdateEmployeeInformations,
    enqueueSnackbar,
    mutateAllNewcomers,
  ]);

  const openInviteMembersModal = useCallback(() => {
    handleModal({
      name: "createNewGraduateModal",
      args: {
        onSubmit: async (
          userDataArray: { email: string; employeeTagIds: string[] }[],
          selectedOnnEventIds: string[],
          isSendEmail: boolean
        ) => {
          await createNewGraduates(
            userDataArray.map((userData) => {
              return {
                ...userData,
                onnEventIds: selectedOnnEventIds,
                isNewGraduate: true,
                employeeTagIds: userData.employeeTagIds,
                onnTaskIds: [],
                isSendInvitationEmail: isSendEmail,
              };
            })
          );
        },
        currentUser,
        onnEvents: onnEvents || [],
        employeeTags: employeeTagsData?.employeeTags || [],
      },
    });
  }, [createNewGraduates, currentUser, handleModal, onnEvents, employeeTagsData?.employeeTags]);

  useEffect(() => {
    if (openDialogType === "invite" && !isLoadingOnnEvents && !isLoadingOnnTasks) {
      openInviteMembersModal();
    }
  }, [openDialogType, isLoadingOnnEvents, openInviteMembersModal, isLoadingOnnTasks]);

  const [notFoundText, notFoundButtons] = useMemo(() => {
    let text = "";
    let buttons: ReactNode | undefined = undefined;

    switch (selectedRecruitmentStatusIdOrNull) {
      // NOTE: selectedRecruitmentStatusIdOrNullが選択されていない場合、全候補者を表示のタブが選択されている。
      case null:
        text = "該当の候補者が見つかりません。";
        buttons = [
          <OpenInviteMembersModalButton
            key="invite-member-modal-button"
            onClick={() => openInviteMembersModal()}
          />,
        ];
        break;
      default:
        text = "該当するユーザーが存在しません。";
        break;
    }
    return [text, buttons];
  }, [openInviteMembersModal, selectedRecruitmentStatusIdOrNull]);

  const firstFilter = useMemo(
    () => (
      <Box display="flex" flexDirection="column" gridRowGap="8px">
        <Typography variant="body2" bold color="textSecondary">
          担当者
        </Typography>

        <EmployeeFilter
          selectableEmployeeIds={allMentorIds}
          selectedEmployeeIds={selectedMentorIds}
          onChange={(selectedEmployeeIds: string[]) => setSelectedMentorIds(selectedEmployeeIds)}
        />
      </Box>
    ),
    [allMentorIds, selectedMentorIds, setSelectedMentorIds]
  );

  if (isLoadingEmployeeTagsData) {
    return (
      <Box pt={5}>
        <Loading size="large" />
      </Box>
    );
  }

  return (
    <Box pt={"40px"}>
      <StyledBox pb={5}>
        <NewGraduateRequirementStatusFilter
          allNewGraduates={filteredEmployeesForAllStatus}
          newGraduatesByRecruitmentStatuses={newGraduatesByRecruitmentStatuses}
          selectedRecruitmentStatusIdOrNull={selectedRecruitmentStatusIdOrNull}
          onSelected={setSelectedRecruitmentStatusIdOrNull}
        />
      </StyledBox>
      <Box mb="24px" width="100%" display="flex" alignItems="flex-end" gridGap={24}>
        {allSelectionState.checked ? (
          <BulkActionControl
            selectedCount={selectedEmployees.length}
            onResetSelection={onResetSelectedEmployees}
            onClickSendMessage={handleNavigateToBulkSendMessage}
            onClickAddTags={handleOpenAddEmployeeTagsActionModal}
            onClickUpdateRecruitmentStatus={handleOpenUpdateRecruitmentStatusActionModal}
            onClickDownloadWithCSV={handleOpenDownloadEmployeesWithCSVActionModal}
          />
        ) : (
          <>
            <TableActionsLayoutWithFiltersAndSearchForm
              firstFilter={firstFilter}
              secondFilter={
                <OpenSearchModalButton
                  onReset={onResetSearchConditions}
                  onClick={handleOpenNewGraduateSearchModal}
                  count={validConditionsCount}
                />
              }
              searchForm={
                <SearchForm
                  onSearchValue={setSearchValue}
                  placeholder="メールアドレスで検索"
                  variant="standard"
                  fullWidth
                />
              }
            />
            <Box width="200px" display="flex" alignItems="flex-end" justifyContent="space-between">
              {!currentUser.isMember() && (
                <>
                  <Button
                    color="primary"
                    variant="outlined"
                    borderRadius="regular"
                    startIcon={<Icon icon="add" color="primary" size="md" />}
                    onClick={openInviteMembersModal}
                  >
                    候補者を追加
                  </Button>
                  <IconButton icon="menuVert" onClick={handleOpenMenu} />
                  <Menu
                    anchorEl={anchorEl}
                    keepMounted={true}
                    open={Boolean(anchorEl)}
                    onClose={() => setAnchorEl(null)}
                    getContentAnchorEl={null}
                    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                    transformOrigin={{ vertical: "top", horizontal: "right" }}
                  >
                    <MenuItem onClick={() => handleOpenNewGraduateInviteModal()}>
                      <Typography variant="body2">{"招待"}</Typography>
                    </MenuItem>
                    <MenuItem onClick={handleClickedCsvUploadMenuItem}>
                      <Typography variant="body2">{"候補者一括追加"}</Typography>
                    </MenuItem>
                    <MenuItem onClick={handleClickedUpdateEmployeeInformationWithCSVMenuItem}>
                      <Typography variant="body2">{"候補者情報一括登録"}</Typography>
                    </MenuItem>
                  </Menu>
                </>
              )}
            </Box>
          </>
        )}
      </Box>

      {employeesForTableView && (
        <>
          {!isEmpty(employeesForTableView) ? (
            <NewGraduateTable
              displayEmployees={employeesForTableView}
              employeeTags={employeeTagsData?.employeeTags ?? []}
              selectedEmployees={selectedEmployees}
              onSelectEmployee={onSelectEmployee}
              checked={allSelectionState.checked}
              indeterminate={allSelectionState.indeterminate}
              onChangeCheckBox={toggleSelectAll}
            />
          ) : currentUser.isMember() ? (
            <NotFoundPaper text="バディ・サポートメンバーに設定されていないため、表示する候補者がいません" />
          ) : (
            <NotFoundPaper text={notFoundText} buttons={notFoundButtons} />
          )}
        </>
      )}
    </Box>
  );
};

const StyledBox = styled(Box)`
  display: flex;
  overflow-x: scroll;
`;
