import { Box } from "@material-ui/core";
import { ANSWER_INTERVIEW_EVENT_ON_BEHALF_HEADER_LABELS } from "@onn/common";
import React, { FC } from "react";
import { CSVLink } from "react-csv";
import styled from "styled-components";

import { useGenerateSampleCSV } from "./useGenerateSampleCSV";

import { Button, Icon, Typography } from "~/components/uiParts";

type Props = {
  onClickNextButton: () => void;
  onnEventId: string;
};

const SAMPLE_DATA_ROW = [
  "AAA0000000001",
  "20240310",
  "10:00",
  "11:00",
  "admin@example.com",
  "オンライン",
  "イベント概要",
  "https://example.com",
];

// packages/front/src/components/domains/_share/DownloadSampleFileStepContent.tsxを使用すること
export const DownloadSampleFileStepContent: FC<Props> = ({ onClickNextButton, onnEventId }) => {
  const NEW_EMPLOYEES_SAMPLE_DATA = [
    ANSWER_INTERVIEW_EVENT_ON_BEHALF_HEADER_LABELS,
    SAMPLE_DATA_ROW,
  ];

  const { generateSampleCSV } = useGenerateSampleCSV({ onnEventId });

  return (
    <>
      <Typography>
        最新のサンプルファイルをダウンロードして、必要事項を記入したアップロード用ファイルをご準備ください。
      </Typography>
      <Box mt={4} display="flex" flexDirection="row" gridGap={16}>
        <StyledCSVLink
          data={NEW_EMPLOYEES_SAMPLE_DATA}
          filename="回答一括登録アップロード用サンプルファイル"
        >
          <Button
            startIcon={<Icon icon="download" size="md" color="primary" />}
            color={"primary"}
            borderRadius={"regular"}
            variant={"outlined"}
            fullHeight
          >
            サンプルファイルをダウンロード
          </Button>
        </StyledCSVLink>
        <StyledCSVLink data={generateSampleCSV()} filename="回答一括登録_設定値ファイル">
          <Button
            startIcon={<Icon icon="download" size="md" color="primary" />}
            color={"primary"}
            borderRadius={"regular"}
            variant={"outlined"}
            fullHeight
          >
            設定値ファイルをダウンロード
          </Button>
        </StyledCSVLink>
      </Box>
      <StyledContainerBox p={2} mt={4} mb={5}>
        <Typography bold={true}>アップロード用ファイル作成に関するヒント</Typography>
        <Typography>
          フォーマットとなるサンプルファイルは内容が更新される可能性があります。毎回ダウンロードし直してご利用ください。
          <br />
          指定のある項目は設定値ファイルからコピー&ペーストして入力を行なってください。
        </Typography>
      </StyledContainerBox>
      <Box display="flex" justifyContent="center">
        <Box width="240px">
          <Button
            fullWidth={true}
            onClick={onClickNextButton}
            color={"primary"}
            borderRadius={"circle"}
            variant={"contained"}
          >
            次へ
          </Button>
        </Box>
      </Box>
    </>
  );
};

const StyledContainerBox = styled(Box)`
  background-color: ${(props) => props.theme.palette.grey[50]};
`;

const StyledCSVLink = styled(CSVLink)`
  height: 48px;
  text-decoration: none;
`;
