import { Box } from "@material-ui/core";
import React, { FC } from "react";
import { CSVLink } from "react-csv";
import styled from "styled-components";

import { Button, Icon, Typography } from "~/components/uiParts";

type Props = {
  onClickNextButton: () => void;
  sampleFile: {
    csvData: string[][];
    filename: string;
    isLoading?: boolean;
  };
  settingFile: {
    csvData: string[][];
    filename: string;
    isLoading?: boolean;
  };
};

export const DownloadSampleFileStepContent: FC<Props> = ({
  sampleFile,
  settingFile,
  onClickNextButton,
}) => {
  return (
    <>
      <Typography>
        最新のサンプルファイルをダウンロードして、必要事項を記入したアップロード用ファイルをご準備ください。
      </Typography>
      <Box mt={4} display="flex" flexDirection="row" gridGap={16}>
        <StyledCSVLink data={sampleFile.csvData} filename={sampleFile.filename}>
          <Button
            startIcon={<Icon icon="download" size="md" color="primary" />}
            color={"primary"}
            borderRadius={"regular"}
            variant={"outlined"}
            fullHeight
            isLoading={sampleFile.isLoading}
            disabled={sampleFile.isLoading}
          >
            サンプルファイルをダウンロード
          </Button>
        </StyledCSVLink>
        <StyledCSVLink data={settingFile.csvData} filename={settingFile.filename}>
          <Button
            startIcon={<Icon icon="download" size="md" color="primary" />}
            color={"primary"}
            borderRadius={"regular"}
            variant={"outlined"}
            fullHeight
            isLoading={settingFile.isLoading}
            disabled={settingFile.isLoading}
          >
            設定値ファイルをダウンロード
          </Button>
        </StyledCSVLink>
      </Box>
      <StyledContainerBox p={2} mt={4} mb={5}>
        <Typography bold={true}>アップロード用ファイル作成に関するヒント</Typography>
        <Typography>
          フォーマットとなるサンプルファイルは内容が更新される可能性があります。毎回ダウンロードし直してご利用ください。
          <br />
          指定のある項目は設定値ファイルからコピー&ペーストして入力を行なってください。
        </Typography>
      </StyledContainerBox>
      <Box display="flex" justifyContent="center">
        <Box width="240px">
          <Button
            fullWidth={true}
            onClick={onClickNextButton}
            color={"primary"}
            borderRadius={"circle"}
            variant={"contained"}
          >
            次へ
          </Button>
        </Box>
      </Box>
    </>
  );
};

const StyledContainerBox = styled(Box)`
  background-color: ${(props) => props.theme.palette.grey[50]};
`;

const StyledCSVLink = styled(CSVLink)`
  height: 48px;
  text-decoration: none;
`;
