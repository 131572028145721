import { Box } from "@material-ui/core";
import React, { FC } from "react";

import { StyledHeaderCellWrapper } from "./StyledHeaderCellWrapper";

import { Typography } from "~/components/uiParts";

// 回答結果列のヘッダーのセル
export const AnswerStatusHeaderCell: FC<{
  style: React.CSSProperties;
}> = ({ style }) => {
  return (
    <StyledHeaderCellWrapper px="16px" style={{ ...style }} isLastColumn={false}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          height: "100%",
          width: "100%",
          justifyContent: "center",
        }}
      >
        <Typography
          display="block"
          variant="body1"
          noWrap
          // Muiのデフォルトのスタイルを上書きするためにstyleで記述。styledで上書きされなかったため。
          style={{ lineHeight: 1.4, textAlign: "center" }}
        >
          ステータス
        </Typography>
      </Box>
    </StyledHeaderCellWrapper>
  );
};
