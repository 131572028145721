import { RecruitmentStatus, APISchema, RegistrationInvitationLink } from "@onn/common";
import useSWR, { SWRResponse } from "swr";

import { apiClient } from "~/libs";

const generateKeyObj = (id: string) => {
  return {
    name: "get_registration_invitation_link",
    id,
  };
};

export const useRegistrationInvitationLink = (
  id?: string
): SWRResponse<
  {
    registrationInvitationLink: RegistrationInvitationLink;
    recruitmentStatus: RecruitmentStatus;
    tenantInfo: { tenantName: string; tenantLogoUrl: string };
  },
  Error
> => {
  return useSWR(id ? generateKeyObj(id) : null, async (key: { id: string }) => {
    const queryParam: APISchema["/get_registration_invitation_link"]["GET"]["query"] = {
      id: key.id,
    };
    const response = await apiClient.get("/get_registration_invitation_link", queryParam);
    return {
      registrationInvitationLink: new RegistrationInvitationLink(
        response.data.registrationInvitationLink
      ),
      recruitmentStatus: new RecruitmentStatus(response.data.recruitmentStatus),
      tenantInfo: response.data.tenantInfo,
    };
  });
};
