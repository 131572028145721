import React, { FC, useCallback } from "react";

import { mutate } from "swr";

import { BodyRowData } from "../const";

import { CellItem, IconButton, StyleProps, UncontrolledMenu } from "~/components/uiParts";
import { useModal } from "~/hooks/modal";
import { generateOnnEventSlotDatesForDisplayKey } from "~/hooks/onnEventSlotDates/useOnnEventSlotDatesForDisplay";
import { useUpdateOnnEventSlotDates } from "~/hooks/onnEventSlotDates/useUpdateOnnEventSlotDates";

export const ThreeDotCell: FC<{ rowData: BodyRowData["menu"] } & StyleProps> = ({
  rowData,
  style,
}) => {
  const { handleModal } = useModal();

  const openEdiOnnEventSlotsModal = useCallback(() => {
    handleModal({
      name: "createOrEditOnnEventSlotDateModal",
      args: {
        data: {
          mode: "edit",
          onnEventSlotDate: rowData.slot,
          onnEvent: rowData.onnEvent,
        },
      },
    });
  }, [handleModal, rowData.onnEvent, rowData.slot]);

  const openConfirmDeleteSlotModal = useCallback(() => {
    handleModal({
      name: "confirmDeleteSlotModal",
      args: {
        onnEventId: rowData.slot.onnEventId,
        onnEventSlotId: rowData.slot.id,
        isExistReservation: rowData.reservedCount > 0,
      },
    });
  }, [handleModal, rowData.reservedCount, rowData.slot.id, rowData.slot.onnEventId]);

  const { updateOnnEventSlotDates } = useUpdateOnnEventSlotDates();

  const updateOnnEventSlotStatus = useCallback(
    async (status: "draft" | "published" | "closed") => {
      await updateOnnEventSlotDates({
        onnEventId: rowData.slot.onnEventId,
        onnEventSlotId: rowData.slot.id,
        paramsToUpdate: { status },
      });
      await mutate(generateOnnEventSlotDatesForDisplayKey(rowData.slot.onnEventId));
    },
    [rowData.slot.id, rowData.slot.onnEventId, updateOnnEventSlotDates]
  );

  const isShowToPublicMenu = rowData.slot.isDraft() || rowData.slot.isClosed();

  const isShowToDraftMenu =
    rowData.reservedCount === 0 && rowData.slot.getStatusForDisplay() === "adjusting";

  const isShowToClosedMenu = rowData.slot.status === "published";

  const menuItemOption = [
    {
      onClick: openEdiOnnEventSlotsModal,
      text: "編集",
    },
    isShowToClosedMenu
      ? {
          onClick: () => updateOnnEventSlotStatus("closed"),
          text: "募集締切",
        }
      : [],
    isShowToPublicMenu
      ? {
          onClick: () => updateOnnEventSlotStatus("published"),
          text: "公開",
        }
      : [],
    isShowToDraftMenu
      ? {
          onClick: () => updateOnnEventSlotStatus("draft"),
          text: "非公開",
        }
      : [],
    {
      onClick: openConfirmDeleteSlotModal,
      text: "削除",
    },
  ].flat();

  return (
    <CellItem style={{ ...style }} isShowTopBorder isShowRightBorder={false}>
      <UncontrolledMenu
        renderButton={(openMenu) => <IconButton icon="menuVert" onClick={openMenu} />}
        menuItemOptions={menuItemOption}
      />
    </CellItem>
  );
};
