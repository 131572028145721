import { Box } from "@material-ui/core";
import { ContactMessageTemplate } from "@onn/common";
import React, { useMemo, FC } from "react";
import { Controller } from "react-hook-form";
import styled from "styled-components";

import { useContactMessageTemplateForm } from "./hooks/useContactMessageTemplateForm";

import { Button, Modal, TextField, TextareaAutosize } from "~/components/uiParts";
import { useCurrentSpace } from "~/hooks/space/useCurrentSpace";
import { useTenant } from "~/hooks/tenant";
import { mixin } from "~/util";

type ModeType = "create" | "edit";

const titleTextMap = {
  create: "作成",
  edit: "編集",
} as const;

type Props = {
  open: boolean;
  onCancel: () => void;
  mode: ModeType;
  oldContactMessageTemplate?: ContactMessageTemplate;
  onSubmit: (newContactMessageTemplate: ContactMessageTemplate) => void;
};

export const EditContactMessageTemplateModal: FC<Props> = ({
  open,
  onCancel,
  oldContactMessageTemplate,
  mode,
  onSubmit,
}) => {
  const {
    tenant: { tenantId },
  } = useTenant();
  const { currentSpace } = useCurrentSpace();

  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = useContactMessageTemplateForm({
    oldContactMessageTemplate,
    tenantId,
    spaceId: currentSpace.id,
    onSubmit,
  });

  const content = useMemo(
    () => (
      <Box marginTop="16px">
        <Box marginBottom="32px">
          <Controller
            name="title"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                fullWidth
                autoFocus
                placeholder="テンプレートタイトル"
                maxTextCount={ContactMessageTemplate.titleMaxLength}
                error={!!error}
                helperText={error?.message}
                {...field}
                inputProps={{ style: { fontSize: 24 } }}
              />
            )}
          />
        </Box>
        <Box>
          <Controller
            name="text"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextareaAutosize
                fullWidth
                placeholder="テンプレートに登録するメッセージを入力してください"
                minRows={3}
                error={!!error}
                helperText={error?.message}
                {...field}
              />
            )}
          />
        </Box>
      </Box>
    ),
    [control]
  );

  const footer = useMemo(
    () => (
      <StyledButtonContainer>
        <Button
          fullWidth
          borderRadius="circle"
          variant="outlined"
          color="default"
          onClick={onCancel}
        >
          キャンセル
        </Button>
        <Button
          fullWidth
          borderRadius="circle"
          variant="contained"
          color="primary"
          onClick={() => {
            handleSubmit();
            onCancel();
          }}
          disabled={!isValid}
        >
          保存
        </Button>
      </StyledButtonContainer>
    ),
    [onCancel, isValid, handleSubmit]
  );

  return (
    <Modal
      title={`テンプレート${titleTextMap[mode]}`}
      open={open}
      onCancel={onCancel}
      content={content}
      footer={footer}
    />
  );
};

const StyledButtonContainer = styled(Box)`
  ${mixin.fixedWidthButtonContainer}
`;
