import { RecruitmentStatus, RegistrationInvitationLink } from "@onn/common";
import useSWR from "swr";

import { apiClient } from "~/libs";

const generateKeyObj = (params: { tenantId: string; spaceId: string }) => {
  return {
    endpoint: `/get_spaces_registration_invitation_links_default`,
    ...params,
  } as const;
};

export const useDefaultRegistrationInvitationLinkBySpace = ({
  tenantId,
  spaceId,
}: {
  tenantId?: string | null;
  spaceId?: string | null;
}) => {
  return useSWR(
    tenantId && spaceId ? generateKeyObj({ tenantId, spaceId }) : null,
    async ({ endpoint, spaceId, tenantId }) => {
      const response = await apiClient.get(endpoint, {
        "tenant-id": tenantId,
        "space-id": spaceId,
      });

      const { registrationInvitationLink, recruitmentStatus } = response.data;
      return {
        registrationInvitationLink: registrationInvitationLink
          ? new RegistrationInvitationLink(registrationInvitationLink)
          : null,
        recruitmentStatus: recruitmentStatus ? new RecruitmentStatus(recruitmentStatus) : null,
      };
    }
  );
};
