import liff from "@line/liff";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

/**
 * Logger（Sentry）の初期化を行います
 *
 * このメソッドを実行することによって、以後Sentryに情報を送信できるようになります
 */
export const initializeLogger = (): void => {
  const env = process.env.NODE_ENV || "";
  if (["staging", "production", "lab"].includes(env)) {
    Sentry.init({
      dsn: "https://32c2a9d323784ca1b3a6c24eb83b3b68@o448244.ingest.sentry.io/5455147",
      integrations: [new Integrations.BrowserTracing()],
      environment: process.env.NODE_ENV,
      release: process.env.SENTRY_RELEASE,
    });
  }
};

/**
 * キャッチされたエラーを付加情報とともにSentryに送信します
 *
 * @param error キャッチされたエラーオブジェクト
 * @param tags エラーの種別を指定するタグ
 * @param extras エラーの付加情報を指定するオブジェクト
 *
 * @returns Sentry上でエラーごとにイベントとして記録されるId
 *
 * @example
 * try {
 *  ...
 * } catch(e) {
 *  captureException({
 *    error: e,
 *    tags: { type: 'Not Found' },
 *    extras: { status: 'JOINED' }
 *  })
 * }
 */
export const captureException = async ({
  error,
  extras = {},
  tags,
}: {
  error: Error | ApiResponseError;
  tags: {
    type: string;
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  extras?: Record<string, any>;
}): Promise<string> => {
  if (liff.isInClient()) {
    await liff
      .getProfile()
      .then((profile) => {
        extras.__profile = { userId: profile?.userId, displayName: profile?.displayName };
      })
      .catch((e) => {
        extras.__profile_error = e;
      });
  }

  return new Promise((resolve) => {
    Sentry.withScope((scope) => {
      scope.setTags(tags);
      if (extras) {
        scope.setExtras(extras);
      }
      if (error instanceof ApiResponseError) {
        scope.setExtras({
          URL: error.url,
          requestBody: error.requestBody,
          responseBody: error.responseBody,
          status: error.status,
        });

        // NOTE: Slack通知のタイトルになるため上書きする
        // - 元の error.message は error.responseBody から判断できるため情報は失われていない
        error.message = `[${error.status}] ${error.url}`;
      }
      const eventID = Sentry.captureException(error);
      resolve(eventID);
    });
  });
};

export const captureMessage = async ({
  message,
  extras = {},
  tags,
}: {
  message: string;
  tags: {
    type: string;
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  extras?: Record<string, any>;
}): Promise<string> => {
  if (liff.isInClient()) {
    await liff
      .getProfile()
      .then((profile) => {
        extras.__profile = { userId: profile?.userId, displayName: profile?.displayName };
      })
      .catch((e) => {
        extras.__profile_error = e;
      });
  }

  return new Promise((resolve) => {
    Sentry.withScope((scope) => {
      scope.setTags(tags);
      if (extras) {
        scope.setExtras(extras);
      }
      const eventID = Sentry.captureMessage(message);
      resolve(eventID);
    });
  });
};

/**
 * APIレスポンスエラーの場合は、APIリクエスト・レスポンスの内容を含めてエラーを送信します
 */
export class ApiResponseError extends Error {
  url: string;
  requestBody: unknown;
  responseBody: unknown;
  status: number;
  constructor(message: string, url: string, req: unknown, res: unknown, status: number) {
    super(message);
    this.url = url;
    this.requestBody = req;
    this.responseBody = res;
    this.status = status;
  }
}

export class NetworkError extends Error {
  url: string;
  constructor(message: string, url: string) {
    super(message);
    this.url = url;
  }
}

/**
 * ユーザーがエラーについてのフィードバックを入力し、Sentryに送信できるようにするためのダイアログを開きます
 */
export const showReportDialog = Sentry.showReportDialog;
