import { v4 } from "uuid";

import { autoContactMessageOrderSchema, IAutoContactMessageOrder, Status } from "./schema";

export class AutoContactMessageOrder implements IAutoContactMessageOrder {
  private static validator = autoContactMessageOrderSchema;

  readonly id: string;
  readonly tenantId: string;
  readonly spaceId: string;

  readonly autoContactMessageSettingId: string;
  readonly scheduleDate: Date;
  readonly receiverEmployeeId: string;

  readonly status: Status;
  readonly createdAt: Date;
  readonly updatedAt: Date;

  constructor(init: ExcludeMethods<AutoContactMessageOrder>) {
    AutoContactMessageOrder.validator.parse(init);

    this.id = init.id;
    this.tenantId = init.tenantId;
    this.spaceId = init.spaceId;

    this.autoContactMessageSettingId = init.autoContactMessageSettingId;
    this.scheduleDate = init.scheduleDate;
    this.receiverEmployeeId = init.receiverEmployeeId;

    this.status = init.status;
    this.createdAt = init.createdAt;
    this.updatedAt = init.updatedAt;
  }

  static createNew(
    params: Omit<
      ExcludeMethods<AutoContactMessageOrder>,
      "id" | "status" | "createdAt" | "updatedAt"
    >
  ) {
    return new AutoContactMessageOrder({
      ...params,
      id: v4(),
      status: "WAITING",
      createdAt: new Date(),
      updatedAt: new Date(),
    });
  }

  success() {
    return new AutoContactMessageOrder({
      ...this,
      status: "SENT",
      updatedAt: new Date(),
    });
  }

  fail() {
    return new AutoContactMessageOrder({
      ...this,
      status: "FAILED",
      updatedAt: new Date(),
    });
  }
}
