import { APISchema } from "@onn/common";
import { instanceToPlain } from "class-transformer";

import { apiClient } from "~/libs";

type Body = APISchema["/patch_delivery_settings_of_onn_task"]["PATCH"]["body"];

export const useUpdateDeliverySettings = () => {
  const updateDeliverySettings = async ({
    scheduledDate,
    onnFormTaskId,
    deadlineDate,
    canAnswerAfterDeadline,
    newGraduateIds,
  }: {
    scheduledDate?: Date | null;
    onnFormTaskId: string;
    deadlineDate?: Date | null;
    canAnswerAfterDeadline: boolean;
    newGraduateIds: string[];
  }) => {
    const body = {
      "scheduled-date": scheduledDate,
      "onn-form-task-id": onnFormTaskId,
      "deadline-date": deadlineDate,
      "can-answer-after-deadline": canAnswerAfterDeadline,
      "new-graduate-ids": newGraduateIds,
    };
    await apiClient.patch("/patch_delivery_settings_of_onn_task", instanceToPlain(body) as Body);
  };

  return { updateDeliverySettings };
};
