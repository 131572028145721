import { useSearchParams } from "react-router-dom";

import { InputState } from "../../_shared/createAndEdit";

export const useGenerateRHFDefaultValue = () => {
  const eventTypeInSearchParam = useOnnEventTypeInSearchParam();

  const defaultType: InputState["type"] = (() => {
    if (eventTypeInSearchParam === "normal" || eventTypeInSearchParam === "new_interview") {
      return eventTypeInSearchParam;
    }
    return "normal";
  })();

  const defaultCandidateDates =
    defaultType === "normal"
      ? [
          {
            candidateDateId: "",
            from: null,
            until: null,
            fromTime: 10,
            untilTime: 11,
            capacity: "10",
          },
          {
            candidateDateId: "",
            from: null,
            until: null,
            fromTime: 10,
            untilTime: 11,
            capacity: "10",
          },
        ]
      : [];

  const defaultValues: InputState = {
    title: "",
    content: "",
    type: defaultType,
    hasCapacity: true,
    candidateDates: defaultCandidateDates,
    isRestrictedDaysAgoWhenAnswer: false,
    daysAgoRestrictAnswer: undefined,
  };

  return { defaultValues };
};

const useOnnEventTypeInSearchParam = () => {
  const [searchParams] = useSearchParams();
  const eventTypeInSearchParam = searchParams.get("eventType");

  return eventTypeInSearchParam;
};
