import { AllContactRoom, Employee, LatestContactMessage } from "@onn/common";
import { intersectionBy } from "lodash";
import { useMemo } from "react";

import { useFilterByIsInvited } from "./useFilterByIsInvited";
import { useFilterByRecruitmentStatusIds } from "./useFilterByRecruitmentStatusIds";
import { useFilterBySearchValue } from "./useFilterBySearchValue";

import { useSortContactRoomsV2 } from "~/components/domains/contactRooms/hooks";

type Props = {
  contactRooms: AllContactRoom[];
  currentUser: Employee;
  selectedRecruitmentStatusIds: string[];
  isDisplayOnlyMyCandidates: boolean;
  latestContactMessages?: LatestContactMessage[];
};

/**
 * コンタクトルーム一覧のフィルターとソートをする
 */
export const useFilterAndSortContactRooms = ({
  contactRooms,
  currentUser,
  selectedRecruitmentStatusIds,
  isDisplayOnlyMyCandidates,
  latestContactMessages,
}: Props) => {
  const { sortContactRooms: sortContactRoomsV2 } = useSortContactRoomsV2();

  // 検索フィルター
  const { searchValue, handleChangeSearchValue, filterContactRoomsBySearchTextV2 } =
    useFilterBySearchValue();

  // 選考ステータスフィルター
  const { filterContactRoomsByRecruitmentStatusIds } = useFilterByRecruitmentStatusIds();
  const { filterByIsInvited } = useFilterByIsInvited();

  /**
   * 検索フィルターと選考ステータスフィルターの積集合
   */
  const filteredContactRooms = useMemo(
    () =>
      intersectionBy(
        filterContactRoomsBySearchTextV2(contactRooms),
        filterContactRoomsByRecruitmentStatusIds(contactRooms, selectedRecruitmentStatusIds),
        filterByIsInvited(contactRooms)
      ),
    [
      filterContactRoomsBySearchTextV2,
      contactRooms,
      filterContactRoomsByRecruitmentStatusIds,
      selectedRecruitmentStatusIds,
      filterByIsInvited,
    ]
  );

  const currentContactRooms = useMemo(() => {
    const tempResult = sortContactRoomsV2(filteredContactRooms, latestContactMessages);
    if (isDisplayOnlyMyCandidates) {
      return tempResult.filter((room) => room.assigneeId === currentUser.id);
    } else {
      return tempResult;
    }
  }, [
    sortContactRoomsV2,
    filteredContactRooms,
    latestContactMessages,
    isDisplayOnlyMyCandidates,
    currentUser.id,
  ]);

  return {
    currentContactRooms,

    searchValue,
    handleChangeSearchValue,

    selectedRecruitmentStatusIds,
  };
};
