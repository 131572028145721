import { Employee, EmployeeToDisplay } from "@onn/common";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useSWRConfig } from "swr";

import { useAllNewcomers } from "./../useAllNewcomers";
import { generateUseEmployeeKeys } from "./../useEmployee";
import { useDismissSupportMember } from "./useDismissSupportMember";

import { generateUseNewGraduateKey } from "~/hooks/employee";
import { useModal } from "~/hooks/modal";
import { useSnackbar } from "~/hooks/shared";
import { EmployeeUseCase } from "~/service/usecases/employeeUseCase";
import { captureException } from "~/util";

/**
 * 入社者操作系のモーダルの呼び出し
 * 複数箇所で利用されるのでこのモーダルにロジックなどを隠蔽する
 */
export const useEmployeeModals = () => {
  const navigate = useNavigate();
  const { mutate } = useSWRConfig();
  const { handleModal } = useModal();
  const { enqueueSnackbar } = useSnackbar();

  const { mutate: mutateAllNewcomers } = useAllNewcomers();

  const { dismissSupportMember } = useDismissSupportMember();

  const handleOpenDeleteEmployeeModal = useCallback(
    (newHire: Employee) => {
      handleModal({
        name: "deleteMemberModal",
        args: {
          onSubmit: async () => {
            await EmployeeUseCase.deleteNewHire(newHire.id)
              .then(async () => {
                enqueueSnackbar("入社者を削除しました", { variant: "success" });
                // topページ以外で削除した場合はtopページに戻る
                if (location.pathname !== "/") {
                  navigate("/");
                }
                mutateAllNewcomers();
              })
              .catch((e) => {
                enqueueSnackbar("入社者の削除に失敗しました", { variant: "error" });
                captureException({
                  error: e as Error,
                  tags: { type: "useEmployeeModals:handleOpenDeleteEmployeeModal" },
                });
              });
          },
          profileIconImageUrl: newHire.profileIconImageUrl,
          username: newHire.getName(),
        },
      });
    },
    [handleModal, enqueueSnackbar, mutateAllNewcomers, navigate]
  );

  const handleOpenDismissSupportMemberConfirmModal = useCallback(
    (newHire: Employee, supportMember: Employee) =>
      handleModal({
        name: "dismissSupportMemberConfirmModal",
        args: {
          onSubmit: async () => {
            await dismissSupportMember(newHire.id, supportMember.id).then(async () => {
              mutate(generateUseEmployeeKeys(newHire.id));
              mutate(generateUseNewGraduateKey({ newGraduateId: newHire.id }));
              mutateAllNewcomers();
            });
          },
          profileIconImageUrl: supportMember.profileIconImageUrl,
          username: supportMember.getName(),
        },
      }),
    [dismissSupportMember, handleModal, mutate, mutateAllNewcomers]
  );

  const handleAddTag = useCallback(
    (newHire: EmployeeToDisplay) => {
      handleModal({
        name: "assignTagsToEmployeeModal",
        args: {
          employee: newHire,
        },
      });
    },
    [handleModal]
  );

  return {
    handleOpenDeleteEmployeeModal,
    handleOpenDismissSupportMemberConfirmModal,
    handleAddTag,
  };
};
