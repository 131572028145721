import { z } from "zod";

import { EmployeeInformationSchema } from "../_gen/zodSchema";

export const kanaNameSchema = z.object({
  lastName: z.string().optional(),
  firstName: z.string().optional(),
});

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IKanaNameSchema extends z.infer<typeof kanaNameSchema> {}

export const addressSchema = z.object({
  postalCode: z.string().optional(),
  prefecture: z.string().optional(),
  city: z.string().optional(),
  addressLine1: z.string().optional(),
  addressLine2: z.string().optional(),
});

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IAddressSchema extends z.infer<typeof addressSchema> {}

export const graduationYearAndMonthSchema = z.object({
  graduationYear: z.number().min(1).optional(),
  graduationMonth: z.number().min(1).max(12).optional(),
});
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IGraduationYearAndMonthSchema
  extends z.infer<typeof graduationYearAndMonthSchema> {}

export const affiliationSchema = z.object({
  schoolName: z.string().optional(),
  faculty: z.string().optional(),
});
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IAffiliationSchema extends z.infer<typeof affiliationSchema> {}

export const employeeInformationValueSchema = z.object({
  kanaName: kanaNameSchema.optional(),
  gender: z.string().optional(),
  dateOfBirth: z.date().optional(),
  phoneNumber: z.string().optional(),
  address: addressSchema.optional(),
  hometownAddress: addressSchema.optional(),
  graduationYearAndMonth: graduationYearAndMonthSchema.optional(),
  externalId: z.string().optional(),
  affiliation: affiliationSchema.optional(),
  resumeFilePaths: z.array(z.string()).optional(),
  entrySheetFilePaths: z.array(z.string()).optional(),
  spiFilePaths: z.array(z.string()).optional(),
});

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IEmployeeInformationValue extends z.infer<typeof employeeInformationValueSchema> {}

export const employeeInformationSchema = EmployeeInformationSchema.extend({
  value: employeeInformationValueSchema,
});

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IEmployeeInformation extends z.infer<typeof employeeInformationSchema> {}
