import { Box } from "@material-ui/core";
import { OnnFormTaskAnswer, FormRevision, NewGraduateToDisplay } from "@onn/common";
import React, { useCallback } from "react";
import { GridCellRenderer, Index } from "react-virtualized";

import { useCreateCSVData } from "./useCreateCSVData";
import { useGenerateRows } from "./useGenerateRows";
import { useGenerateSortedRevisionQuestionDataList } from "./useGenerateSortedRevisionQuestionDataList";

const HEADER_CELL_HEIGHT = 60;
const ROW_CELL_HEIGHT = 72;

export const useAnswerResultTable = ({
  targetNewGraduates,
  onnFormTaskAnswers,
  formRevisions,
  formTaskTitle,
}: {
  targetNewGraduates: NewGraduateToDisplay[];
  onnFormTaskAnswers: OnnFormTaskAnswer[];
  formRevisions: FormRevision[];
  formTaskTitle: string;
}) => {
  const { generateSortedRevisionQuestionDataList } = useGenerateSortedRevisionQuestionDataList();
  const sortedRevisionQuestionDataList = generateSortedRevisionQuestionDataList(formRevisions);
  const { generateRows } = useGenerateRows();

  const { csvData, filename } = useCreateCSVData({
    taskTitle: formTaskTitle,
    onnFormTaskAnswers,
    sortedRevisionQuestionDataList,
    targetNewGraduates,
  });

  const rows = generateRows({
    newGraduates: targetNewGraduates,
    onnFormTaskAnswers,
    sortedRevisionQuestionDataList,
  });

  const cellRenderer: GridCellRenderer = useCallback(
    ({ key, style, rowIndex, columnIndex }) => {
      const cell = rows[rowIndex]?.[columnIndex];
      return (
        <Box key={key} style={{ ...style, border: "1px" }}>
          {cell}
        </Box>
      );
    },
    [rows]
  );

  // 列の数は表示する設問の数＋氏名アイコン列＋回答状況表示列
  const columnCount = sortedRevisionQuestionDataList.length + 2;

  // 行の数は表示する候補者の数＋ヘッダーの数
  const headerRowCount = 1;
  const rowCount = rows.length + headerRowCount;

  // ヘッダーの高さを指定する
  const rowHeight = (params: Index) => (params.index === 0 ? HEADER_CELL_HEIGHT : ROW_CELL_HEIGHT);

  // 各カラムの幅を指定する
  const columnWidth = (params: Index) => {
    if (params.index === 0) return 204;
    if (params.index === 1) return 148;
    return 200;
  };

  return {
    rows,
    cellRenderer,
    rowCount,
    columnCount,
    rowHeight,
    columnWidth,
    headerCellHeight: HEADER_CELL_HEIGHT,
    rowCellHeight: ROW_CELL_HEIGHT,
    csv: { csvData, filename },
  };
};
