import { v4 } from "uuid";

import { OnnEventType } from "../../OnnEvent/OnnEvent/types";
import { RecruitmentProcessRelationSetting } from "../RecruitmentProcessRelationSetting";

import { IRecruitmentStatusSchema, RecruitmentStatusType, recruitmentStatusSchema } from "./schema";

/**
 * 選考ステータスのマスターデータ
 * - space ごとに作成される
 */
export class RecruitmentStatus implements IRecruitmentStatusSchema {
  static readonly validator = recruitmentStatusSchema;

  id: string;
  tenantId: string;
  spaceId: string;
  type: RecruitmentStatusType;
  label: string;

  /**
   * 選考ステータスを設定した際に発行される選考プロセスの設定値
   * - targetId は、type に応じたエンティティの id
   */
  recruitmentProcessRelationSetting?: RecruitmentProcessRelationSetting;

  createdAt: Date;
  updatedAt: Date;
  order: number;
  constructor(init: ExcludeMethods<RecruitmentStatus>) {
    const parsedInit = RecruitmentStatus.validator.parse(init);

    this.id = parsedInit.id;
    this.tenantId = parsedInit.tenantId;
    this.spaceId = parsedInit.spaceId;
    this.createdAt = parsedInit.createdAt;
    this.updatedAt = parsedInit.updatedAt;
    this.type = parsedInit.type;
    this.label = parsedInit.label;
    this.order = parsedInit.order;
    this.recruitmentProcessRelationSetting = parsedInit.recruitmentProcessRelationSetting;
  }

  public static create(
    params: Omit<ExcludeMethods<RecruitmentStatus>, "id" | "createdAt" | "updatedAt">
  ): RecruitmentStatus {
    return new RecruitmentStatus({
      ...params,
      id: v4(),
      createdAt: new Date(),
      updatedAt: new Date(),
    });
  }

  isPreEntry(): this is RecruitmentStatus & { type: "pre_entry" } {
    return this.type === "pre_entry";
  }

  isSelectableWhenCreateNewGraduate(): boolean {
    return this.type !== "rejected" && this.type !== "withdrew";
  }

  /**
   * 選考履歴を切り替える際に、回答の箱作成と通知を行うイベントIDとタスクIDを取得する
   * - 面談イベントは対象外になる
   */
  getIdsForProcessSetting(): {
    onnEventIds: string[];
    onnTaskIds: string[];
  } {
    const availableOnnEventTypes: OnnEventType[] = ["normal", "new_interview"];
    const [onnEventIds, onnTaskIds] = this.recruitmentProcessRelationSetting
      ? [
          this.recruitmentProcessRelationSetting.event &&
          availableOnnEventTypes.includes(this.recruitmentProcessRelationSetting.event.type)
            ? [this.recruitmentProcessRelationSetting.event.id]
            : [],
          this.recruitmentProcessRelationSetting.tasks.map((task) => task.id),
        ]
      : [[], []];

    return {
      onnEventIds,
      onnTaskIds,
    };
  }
}
