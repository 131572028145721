import { Employee, NewGraduateToDisplay, OnnEvent, OnnEventDeterminedDate } from "@onn/common";
import { format } from "date-fns";
import { ja } from "date-fns/locale";
import { useMemo } from "react";

import { removeUnusableCharacters } from "~/util/fileUtil";

const generateAnswerStatusCell = (newGraduate: NewGraduateToDisplay) => {
  if (newGraduate?.recruitmentStatusType === "withdrew") return "辞退";
  if (newGraduate?.recruitmentStatusType === "rejected") return "不合格";

  return "回答済み";
};

const generateAssigneeCell = (assignee?: Employee) => {
  if (!assignee) return "-";

  return assignee.getName();
};

const generateEventTypeCell = (determinedDate: OnnEventDeterminedDate) => {
  if (!determinedDate?.eventFormat) return "-";

  if (determinedDate.eventFormat.type === "offline") return "オフライン・対面";

  return `オンライン${determinedDate.eventFormat.url ? `\n${determinedDate.eventFormat.url}` : ""}`;
};

const generateDateCell = (onnEvent: OnnEvent, determinedDate: OnnEventDeterminedDate) => {
  const candidateDate = determinedDate?.candidateDateId
    ? onnEvent.getCandidateDatesById(determinedDate.candidateDateId)
    : undefined;

  if (!candidateDate) return "-";

  return `${format(candidateDate.from, "MM/dd(E) H:mm", { locale: ja })}〜${format(
    candidateDate.until,
    "H:mm"
  )}`;
};

const generateEventDescriptionCell = (determinedDate: OnnEventDeterminedDate) => {
  if (!determinedDate?.eventFormat?.description) return "-";

  return determinedDate?.eventFormat?.description;
};

const generateRow = (
  onnEvent: OnnEvent,
  newGraduatesMap: Map<string, NewGraduateToDisplay>,
  assigneeMap: Map<string, Employee>,
  determinedDate: OnnEventDeterminedDate
) => {
  const newGraduate = newGraduatesMap.get(determinedDate.employeeId);
  if (!newGraduate) return [];
  const assignee = determinedDate.assigneeId
    ? assigneeMap.get(determinedDate.assigneeId)
    : undefined;

  return [
    newGraduate.getName(),
    generateAnswerStatusCell(newGraduate),
    generateAssigneeCell(assignee),
    generateDateCell(onnEvent, determinedDate),
    generateEventTypeCell(determinedDate),
    generateEventDescriptionCell(determinedDate),
  ];
};

export const useOutputResultAnswerCsv = ({
  onnEvent,
  determinedDates,
  assigneeMap,
  newGraduatesMap,
}: {
  onnEvent: OnnEvent;
  determinedDates: OnnEventDeterminedDate[];
  assigneeMap: Map<string, Employee>;
  newGraduatesMap: Map<string, NewGraduateToDisplay>;
}) => {
  const csvData = useMemo(
    () => [
      [`回答数:${determinedDates.length}`, "調整状況", "選考担当者", "日程", "開催方法", "概要"],
      ...determinedDates.map((determinedDate) =>
        generateRow(onnEvent, newGraduatesMap, assigneeMap, determinedDate)
      ),
    ],
    [assigneeMap, determinedDates, newGraduatesMap, onnEvent]
  );

  const filename = `イベント回答結果_${removeUnusableCharacters(onnEvent.title)}`;

  return { csvData, filename };
};
