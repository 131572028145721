import { EventFormat } from "@onn/common";
import { useCallback } from "react";

import { useSnackbar } from "../shared";

import { apiClient } from "~/libs";
import { captureException } from "~/util";

export const useReAnswerEventOnBehalfOfNewGraduate = () => {
  const { enqueueSnackbar } = useSnackbar();
  const execReAnswerEventOnBehalfOfNewGraduate = useCallback(
    async (params: {
      onnEventDeterminedDateId: string;
      assigneeId?: string | null;
      candidateDate?: {
        from: Date;
        until: Date;
      };
      eventFormat?: EventFormat;
    }): Promise<void> => {
      try {
        await apiClient.patch("/update_event_on_behalf_of_new_graduate", params);
        enqueueSnackbar("回答を更新しました", { variant: "success" });
      } catch (e) {
        const errorMessage =
          e instanceof Error && e.message ? e.message : "回答の更新に失敗しました";
        enqueueSnackbar(errorMessage, { variant: "error" });
        captureException({
          error: e as Error,
          tags: {
            type: "useReAnswerEventOnBehalfOfNewGraduate:execReAnswerEventOnBehalfOfNewGraduate",
          },
        });
        throw e;
      }
    },
    [enqueueSnackbar]
  );

  return { execReAnswerEventOnBehalfOfNewGraduate };
};
