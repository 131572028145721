import { Box } from "@material-ui/core";
import React from "react";

import { StyledHeaderCellWrapper } from "./StyledHeaderCellWrapper";

import { Typography } from "~/components/uiParts";

export const TotalCountHeaderCell = ({
  numberOfResponses,
  style,
}: {
  numberOfResponses: number;
  style: React.CSSProperties;
}) => {
  return (
    <StyledHeaderCellWrapper style={{ ...style }} isLastColumn={false}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          height: "100%",
          width: "100%",
        }}
      >
        <Typography
          variant="body2"
          color="textSecondary"
          // Muiのデフォルトのスタイルを上書きするためにstyleで記述。styledで上書きされなかったため。
          style={{ lineHeight: 1.5 }}
        >
          調整済み：{numberOfResponses}
        </Typography>
      </Box>
    </StyledHeaderCellWrapper>
  );
};
