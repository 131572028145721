import { z } from "zod";

export const onnEventAnswerSchema = z.object({
  id: z.string(),
  onnEventId: z.string(),
  tenantId: z.string(),
  employeeId: z.string(),
  answer: z.record(z.enum(["possible", "impossible", "pending"])), // possible: O, impossible: X, pending: ▲
  selectedOnnEventSlotDateId: z.string().optional().nullable(),
  createdAt: z.date(),
  updatedAt: z.date(),
});

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IOnnEventAnswer extends z.infer<typeof onnEventAnswerSchema> {}
