import { zodResolver } from "@hookform/resolvers/zod";
import { Box } from "@material-ui/core";
import { EmployeeTag, employeeTagSchema } from "@onn/common";
import React, { useState, FC, useCallback, useMemo } from "react";
import { useForm, Controller } from "react-hook-form";
import styled from "styled-components";
import { z } from "zod";

import { Button, Typography, Modal, TextField } from "~/components/uiParts";
import { useCurrentUser } from "~/hooks/employee";
import { mixin } from "~/util";

type ModeType = "create" | "edit";
const titleTextMap = {
  create: "追加",
  edit: "編集",
} as const;

type Props = {
  open: boolean;
  onCancel: () => void;
  onSubmit: (tag: EmployeeTag) => Promise<void>;
  selectedTag?: EmployeeTag;
  mode: ModeType;
  employeeTags: ExcludeMethods<EmployeeTag>[] | undefined;
};

type InputState = Pick<z.infer<typeof employeeTagSchema>, "name">;

export const AddOrEditTagModal: FC<Props> = ({
  open,
  onCancel,
  onSubmit,
  selectedTag,
  mode,
  employeeTags,
}) => {
  const isModeCreate = mode === "create";
  // 編集のときのみ、初期値に選択された部署のnameを入れる
  const initialName = (!isModeCreate && selectedTag?.name) || "";
  const [isLoading, setIsLoading] = useState(false);
  const { currentUser } = useCurrentUser();

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
    getValues,
  } = useForm<InputState>({
    defaultValues: { name: selectedTag?.name || "" },
    mode: "onChange",
    resolver: zodResolver(
      z.object({
        // TODO: issues が複数ある場合の見せ方を検討して改善する https://app.clickup.com/t/864dwv1ka
        // react-hook-form と @hookform/resolversを調べる必要ありそう
        name: employeeTagSchema.shape.name.superRefine((inputTag: string, ctx: z.RefinementCtx) => {
          const employeeTagsForValidation =
            mode === "create" ? employeeTags : employeeTags?.filter((t) => t.name !== initialName);
          try {
            employeeTagsForValidation?.forEach((tag) => {
              if (tag.name === inputTag) {
                throw new Error("同じ名前のタグが既に存在します");
              }
            });
          } catch (err) {
            if (err instanceof Error) {
              ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: err.message,
              });
            }
          }
        }),
      })
    ),
  });

  const onSubmitForm = useCallback(() => {
    setIsLoading(true);
    onSubmit(
      EmployeeTag.create({
        name: getValues("name"),
        tenantId: currentUser.tenantId,
        creatorId: currentUser.id,
      })
    )
      .then(() => {
        onCancel();
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [onSubmit, getValues, currentUser.tenantId, currentUser.id, onCancel]);

  // NOTE: バリデーションのためにnameの値を監視する
  const watchName = watch("name");
  const isButtonDisabled = useMemo(() => {
    return watchName === "" || errors.name !== undefined;
  }, [errors.name, watchName]);

  return (
    <Modal
      open={open}
      title={`タグ${titleTextMap[mode]}`}
      content={
        <form onSubmit={handleSubmit(onSubmitForm)}>
          <Typography variant="body2" bold>
            タグ名
          </Typography>
          <Box mt="16px">
            <Controller
              name="name"
              control={control}
              render={({ field: { onChange, value } }) => {
                /**
                 * # "too_small"のエラーを表示しない理由
                 * 空文字(0文字)であればタグ名が必須なことはユーザーにとって自明である。
                 * また、空文字(0文字)のときは”追加"ボタンがdisabledになる処理が別途記述されている。
                 */
                const isError = errors.name !== undefined && errors.name.type !== "too_small";
                const helperText = `(${value.trim().length}/30文字) ${
                  isError ? errors.name?.message : ""
                }`;
                return (
                  <TextField
                    onChange={onChange}
                    value={value}
                    fullWidth
                    name="name"
                    variant="outlined"
                    placeholder="例：サマーインターン"
                    error={isError}
                    helperText={helperText}
                    autoFocus
                  />
                );
              }}
            />
          </Box>
          {/* React-hook-formを使うため、formタグで囲む必要があったのでcontentプロパティにボタンも入れています */}
          <StyledButtonContainer>
            <Button
              fullWidth
              borderRadius="circle"
              variant="outlined"
              color="default"
              onClick={onCancel}
            >
              キャンセル
            </Button>
            <Button
              type="submit"
              fullWidth
              borderRadius="circle"
              variant="contained"
              color="primary"
              disabled={isButtonDisabled}
              isLoading={isLoading}
            >
              {mode === "create" ? "追加" : "保存"}
            </Button>
          </StyledButtonContainer>
        </form>
      }
      onCancel={onCancel}
    />
  );
};

const StyledButtonContainer = styled(Box)`
  margin-top: 72px;
  ${mixin.fixedWidthButtonContainer}
`;
