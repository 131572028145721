import { v4 } from "uuid";

import { IOnnEventPlace, onnEventPlaceSchema } from "./schema";

export class OnnEventPlace implements IOnnEventPlace {
  static validator = onnEventPlaceSchema;

  readonly id: string;
  readonly tenantId: string;
  readonly spaceId: string;
  readonly name: string;
  readonly postCode: string;
  readonly state: string;
  readonly city: string;
  readonly address1: string;
  readonly address2: string;
  readonly createdAt: Date;
  readonly updatedAt: Date;

  constructor(init: ExcludeMethods<OnnEventPlace>) {
    const parsedInit = OnnEventPlace.validator.parse(init);

    this.id = parsedInit.id;
    this.tenantId = parsedInit.tenantId;
    this.spaceId = parsedInit.spaceId;
    this.name = parsedInit.name;
    this.postCode = parsedInit.postCode;
    this.state = parsedInit.state;
    this.city = parsedInit.city;
    this.address1 = parsedInit.address1;
    this.address2 = parsedInit.address2;
    this.createdAt = parsedInit.createdAt;
    this.updatedAt = parsedInit.updatedAt;
  }

  public static create(
    params: Optional<ExcludeMethods<OnnEventPlace>, "id" | "createdAt" | "updatedAt">
  ): OnnEventPlace {
    return new OnnEventPlace({
      ...params,
      id: params.id ?? v4(),
      createdAt: new Date(),
      updatedAt: new Date(),
    });
  }

  public update(update: Partial<ExcludeMethods<OnnEventPlace>>): OnnEventPlace {
    return new OnnEventPlace({
      ...this,
      ...update,
      updatedAt: new Date(),
    });
  }

  public getFullAddress(): string {
    return `${this.state}${this.city}${this.address1}${this.address2}`;
  }
}
