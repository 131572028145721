import { SlotTypeForDisplay } from "@onn/common";
import React, { ComponentProps, FC, useMemo } from "react";

import styled from "styled-components";

import { CellItem, Icon, StyleProps, Typography } from "~/components/uiParts";
import { colors } from "~/config/theme";

// TODO: オフライン・現地の場合のonClick処理を追加する
export const SlotTypeCell: FC<{ rowData: SlotTypeForDisplay } & StyleProps> = ({
  rowData,
  style,
}) => {
  const isWarning = rowData.isWarning();
  const type = rowData.type;

  const icon: ComponentProps<typeof Icon>["icon"] = useMemo(() => {
    return isWarning ? "warning" : type === "offline" ? "location" : "link";
  }, [isWarning, type]);

  const iconColor: ComponentProps<typeof Icon>["color"] = useMemo(() => {
    return isWarning ? "secondary" : "primary";
  }, [isWarning]);

  return (
    <CellItem
      style={{ ...style, backgroundColor: isWarning ? colors.secondary.light : undefined }}
      isShowTopBorder
    >
      {rowData.isOnline() && rowData.hasUrl() ? (
        <StyledAnchor href={rowData.url} target="_blank">
          <CellLabel isWarning={isWarning} label={rowData.getLabel()} cursor={"pointer"} />
        </StyledAnchor>
      ) : (
        <CellLabel isWarning={isWarning} label={rowData.getLabel()} />
      )}
      <Icon icon={icon} size="md" color={iconColor} />
    </CellItem>
  );
};

const CellLabel: FC<{
  isWarning: boolean;
  label: string;
  cursor?: string;
}> = ({ isWarning, cursor, label }) => {
  return (
    <StyledTypography
      variant="body2"
      color={isWarning ? "textPrimary" : "primary"}
      cursor={cursor}
      style={{ paddingRight: "6px" }}
    >
      {label}
    </StyledTypography>
  );
};

const StyledTypography = styled(Typography)<{ cursor?: string }>`
  cursor: ${({ cursor }) => cursor};
`;

const StyledAnchor = styled.a`
  text-decoration: none;
`;
