import { EmployeeInformationErrorStatus, EmployeeInformationErrorType } from "@onn/common";
import { useCallback } from "react";

export const useGetErrorMessage = () => {
  const getErrorMessage = useCallback(
    (errorStatus: EmployeeInformationErrorStatus, options: { inputRowSize: number }): string => {
      const errorMessages: string[] = [];
      errorStatus.forEach((rows, errorType) => {
        const errorRowNumbers = rows.toString();

        if (errorType === EmployeeInformationErrorType.OVER_THE_LIMIT) {
          errorMessages.push(
            `※ ${options.inputRowSize}件のデータが入力(空白行を含む)されています。一度あたりの最大登録数は5000件です`
          );
        }

        if (errorType === EmployeeInformationErrorType.NO_UNIQUE_ID) {
          errorMessages.push(`${errorRowNumbers}行目の「Onn固有ID」が入力されていません。`);
        }

        if (errorType === EmployeeInformationErrorType.NOT_EXIST_UNIQUE_ID) {
          errorMessages.push(`${errorRowNumbers}行目の「Onn固有ID」を持つ候補者が存在しません。`);
        }

        if (errorType === EmployeeInformationErrorType.INVALID_FORMAT_GENDER) {
          errorMessages.push(
            `${errorRowNumbers}行目の「性別」を指定のフォーマット(例: 男性・女性・その他)に沿って入力し直してください。`
          );
        }

        if (errorType === EmployeeInformationErrorType.INVALID_FORMAT_DATE_OR_BIRTH) {
          errorMessages.push(
            `${errorRowNumbers}行目の「生年月日」を指定のフォーマット(例: 20240101)に沿って入力し直してください。`
          );
        }

        if (errorType === EmployeeInformationErrorType.INVALID_FORMAT_GRADUATION_YEAR) {
          errorMessages.push(
            `${errorRowNumbers}行目の「卒業年」は数値(2024~2034)を入力してください。`
          );
        }

        if (errorType === EmployeeInformationErrorType.INVALID_FORMAT_GRADUATION_MONTH) {
          errorMessages.push(`${errorRowNumbers}行目の「卒業月」は数値(1~12)を入力してください。`);
        }

        if (errorType === EmployeeInformationErrorType.INVALID_FORMAT_ADDRESS_PREFECTURE) {
          errorMessages.push(
            `${errorRowNumbers}行目の「自宅住所・都道府県」を指定のフォーマット(例: 東京都・埼玉県などのように都道府県を後ろに付与する)に沿って入力し直してください。`
          );
        }

        if (errorType === EmployeeInformationErrorType.INVALID_FORMAT_HOMETOWN_ADDRESS_PREFECTURE) {
          errorMessages.push(
            `${errorRowNumbers}行目の「帰省先住所・都道府県」を指定のフォーマット(例: 東京都・埼玉県などのように都道府県を後ろに付与する)に沿って入力し直してください。`
          );
        }
      });

      return errorMessages.join("\n");
    },
    []
  );

  return { getErrorMessage };
};
