import { Box } from "@material-ui/core";
import {
  EmployeeTag,
  NewGraduatePropertyForInvite,
  OnnEvent,
  OnnTask,
  RecruitmentStatus,
} from "@onn/common";
import React, { FC, useMemo, useState } from "react";
import styled from "styled-components";

import { DownloadSampleFileStepContent } from "./DownloadSampleFileStepContent";
import { UploadFileStepContent } from "./UploadFileStepContent";

import { Modal, VerticalStepper } from "~/components/uiParts";
import { useCurrentSpace } from "~/hooks/space/useCurrentSpace";

type Props = {
  open: boolean;
  onCancel: () => void;
  inviteType: "newGraduate";
  employeeTags: EmployeeTag[];
  onnEvents: OnnEvent[];
  onnTasks: OnnTask[];
  recruitmentStatuses: RecruitmentStatus[];
  onClickUploadButton: (inputValues: NewGraduatePropertyForInvite[]) => Promise<void>;
};

export const InviteNewGraduateWithCSVModal: FC<Props> = ({
  open,
  onCancel,
  onClickUploadButton,
  onnEvents,
  ...props
}) => {
  const { currentSpace, spaces, isShowSpaceOnScreen } = useCurrentSpace();
  const [activeStep, setActiveStep] = useState(1);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const stepContent = useMemo(() => {
    switch (activeStep) {
      case 1:
        return (
          <DownloadSampleFileStepContent
            onClickNextButton={handleNext}
            onnEvents={onnEvents}
            {...props}
          />
        );
      case 2:
        return (
          <UploadFileStepContent
            onClickCancellation={onCancel}
            onClickUploadButton={onClickUploadButton}
            onUploadNewHires={onCancel}
            onnEvents={onnEvents}
            {...props}
          />
        );
    }
  }, [activeStep, onnEvents, props, onClickUploadButton, onCancel]);

  return (
    <Modal
      open={open}
      title={
        isShowSpaceOnScreen(spaces) ? `候補者一括追加｜${currentSpace.name}` : "候補者一括追加"
      }
      content={
        <Box display="flex" alignItems="flex-start">
          <VerticalStepper activeStep={activeStep} labels={["ファイル準備", "アップロード"]} />
          <StyledStepContentContainer>{stepContent}</StyledStepContentContainer>
        </Box>
      }
      onCancel={onCancel}
    />
  );
};

const StyledStepContentContainer = styled(Box)`
  padding-left: 32px;
  margin-left: 32px;
  width: 720px;
  border-left: 1px solid ${(props) => props.theme.palette.grey[100]};
`;
