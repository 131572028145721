import { Employee } from "@onn/common";
import { useCallback } from "react";

import { useGenerateMutateEmployee } from "../useEmployee";

import { useAllEmployees, useAllNewcomers, useGenerateMutateNewGraduate } from "~/hooks/employee";
import { useSnackbar } from "~/hooks/shared";
import { EmployeeUseCase } from "~/service/usecases/employeeUseCase";
import { captureException } from "~/util";

export const useAssignFollowers = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { generateMutateEmployee } = useGenerateMutateEmployee();
  const { generateMutateNewGraduate } = useGenerateMutateNewGraduate();
  const { mutate: mutateAllNewcomers } = useAllNewcomers();
  const { mutateAllEmployees } = useAllEmployees();

  const assignFollowers = useCallback(
    async ({ employeeIds, newGraduate }: { employeeIds: string[]; newGraduate: Employee }) => {
      await EmployeeUseCase.inviteSupportMember(newGraduate.id, employeeIds)
        .then(() => {
          enqueueSnackbar("フォロワーに追加しました", { variant: "success" });
          generateMutateEmployee(newGraduate.id)();
          generateMutateNewGraduate(newGraduate.id)();
          mutateAllNewcomers();
          mutateAllEmployees();
        })
        .catch((e) => {
          enqueueSnackbar("フォロワーへの追加に失敗しました", { variant: "error" });
          captureException({
            error: e as Error,
            tags: { type: "useAssignFollowers:assignFollowers" },
          });
          return;
        });
    },
    [
      enqueueSnackbar,
      generateMutateEmployee,
      generateMutateNewGraduate,
      mutateAllNewcomers,
      mutateAllEmployees,
    ]
  );

  return { assignFollowers };
};
