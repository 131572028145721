import { OnnEventDeterminedDate } from "../../domain/OnnEvent";

export const createIMockOnnEventDeterminedDate = (
  mock: Partial<OnnEventDeterminedDate> = {}
): OnnEventDeterminedDate => {
  return new OnnEventDeterminedDate({
    id: mock.id || "onnEventAnswer1",
    onnEventId: mock.onnEventId || "onnEvent1",
    employeeId: mock.employeeId || "employee1",
    candidateDateId: mock.candidateDateId || "candidateDate1",
    onnEventSlotDateId: mock.onnEventSlotDateId,
    createdAt: mock.createdAt || new Date(2022, 2, 20),
    updatedAt: mock.updatedAt || new Date(2022, 2, 20),
    tenantId: mock.tenantId || "tenant1",
    assigneeId: mock.assigneeId,
    attendanceStatus: mock.attendanceStatus || "UNREGISTERED",
    eventFormat: mock.eventFormat,
  });
};
