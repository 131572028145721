import { onnEventOfflineFormatSchema, onnEventOnlineFormatSchema } from "@onn/common";
import { z } from "zod";

export const answerInterviewEventOnBehalfFormSchema = z.object({
  employeeId: z.string(),
  assigneeId: z.string().optional().nullable(),
  candidateDate: z
    .object({
      // NOTE: optionalにして後続のsuperRefineでバリデーションを行っている
      from: z.date().optional(),
      until: z.date().optional(),
    })
    .superRefine((value, ctx) => {
      if (!value.from) {
        ctx.addIssue({
          code: z.ZodIssueCode.invalid_date,
          message: "開始日時を入力してください",
          path: [`from`],
        });
        return z.INVALID;
      }
      if (!value.until) {
        ctx.addIssue({
          code: z.ZodIssueCode.invalid_date,
          message: "終了日時を入力してください",
          path: [`until`],
        });
        return z.INVALID;
      }
      if (value.from < value.until) return;

      ctx.addIssue({
        code: z.ZodIssueCode.invalid_date,
        message: "終了日時は開始日時より後に設定してください",
        path: [`until`],
      });
      return z.INVALID;
    }),
  eventFormat: z
    .object({
      type: z.union([
        onnEventOnlineFormatSchema.shape.type,
        onnEventOfflineFormatSchema.shape.type,
      ]),
      // NOTE: optionalにして後続のsuperRefineでバリデーションを行っている
      descriptionForOnline: z.string().optional(),
      descriptionForOffline: z.string().optional(),
      urlForOnline: z.string().optional(),
    })
    // type毎にバリデーションの対象が異なるのでsuperRefineを使っている。
    .superRefine((value, ctx) => {
      if (value.type === "online") {
        const result1 = onnEventOnlineFormatSchema.shape.description.safeParse(
          value.descriptionForOnline
        );
        const result2 = onnEventOnlineFormatSchema.shape.url.safeParse(value.urlForOnline);
        if (result1.success && result2.success) return;

        ctx.addIssue({
          code: z.ZodIssueCode.invalid_date,
          message: "開催概要を入力してください",
          path: [`descriptionForOnline`],
        });
        return z.INVALID;
      }
      if (value.type === "offline") {
        const result = onnEventOfflineFormatSchema.shape.description.safeParse(
          value.descriptionForOffline
        );
        if (result.success) return;

        ctx.addIssue({
          code: z.ZodIssueCode.invalid_date,
          message: "開催概要を入力してください",
          path: [`descriptionForOffline`],
        });
        return z.INVALID;
      }
    }),
});
