import { Employee, NewGraduateToDisplay } from "@onn/common";
import React, { useCallback, useEffect, useMemo, useRef, useState, FC } from "react";
import styled, { css } from "styled-components";

import { NewGraduateSummaryPaper } from "./NewGraduateSummaryPaper";

import { useContactContext } from "~/hooks/contactMessage/useContactContext";
import { useShouldSPView } from "~/hooks/shared/useShouldSPView";

// ====================
// props
// ====================

type Props = {
  newHire: NewGraduateToDisplay;
  currentUser: Employee;
  manageWelcomeMessageModalOpened: boolean;
};

// ====================
// main
// ====================

export const NewGraduateSummary: FC<Props> = (props) => {
  // ====================
  // hooks
  // ====================

  const shouldSPView = useShouldSPView();

  const { contactRoomNotificationCountList, contactRoomsWithoutCurrentUser: contactRooms } =
    useContactContext();

  // ====================
  // state
  // ====================

  const defaultContainerRef = useRef<HTMLDivElement>(null);
  const [defaultContainerPosition, setDefaultContainerPosition] = useState<number | undefined>(
    undefined
  );

  // ====================
  // variables
  // ====================

  const handleSetDefaultContainerPosition = useCallback(() => {
    const clientRect = defaultContainerRef.current?.getBoundingClientRect();
    if (clientRect) {
      setDefaultContainerPosition(clientRect.top);
    }
  }, [defaultContainerRef]);

  // NOTE: 1st版では1つの入社者につき1つのルームなので find で1つ目の値を用いる
  const contactRoom = useMemo(
    () => contactRooms.find((room) => room.employeeId === props.newHire.id),
    [contactRooms, props.newHire]
  );

  const unreadContactMessageCount = useMemo(() => {
    if (!contactRoom) return 0;
    const targetRoom = contactRooms.find((room) => room.employeeId === props.newHire.id);
    if (!targetRoom) return 0;
    return (
      contactRoomNotificationCountList.find((uc) => uc.contactRoomId === targetRoom.id)
        ?.unreadCount || 0
    );
  }, [contactRoom, contactRooms, contactRoomNotificationCountList, props.newHire.id]);
  // ====================
  // effect
  // ====================

  useEffect(() => {
    // スクロールイベントで NewGraduateSummary の位置を取得してセットする
    window.addEventListener("scroll", handleSetDefaultContainerPosition);

    return () => window.removeEventListener("scroll", handleSetDefaultContainerPosition);
  }, [handleSetDefaultContainerPosition]);

  // ====================
  // component
  // ====================

  return (
    <>
      {/* デフォルト表示用 */}
      <div ref={defaultContainerRef}>
        <NewGraduateSummaryPaper
          {...props}
          contactRoom={contactRoom}
          unreadContactMessageCount={unreadContactMessageCount}
        />
      </div>

      {/* ダウンスクロール時にヘッダー固定表示用 */}
      <StyledHeaderFixedContainer
        $visible={defaultContainerPosition !== undefined && defaultContainerPosition < 0}
        $isSP={shouldSPView}
      >
        <NewGraduateSummaryPaper
          {...props}
          contactRoom={contactRoom}
          unreadContactMessageCount={unreadContactMessageCount}
          isRounded={false}
        />
      </StyledHeaderFixedContainer>
    </>
  );
};

// ====================
// style
// ====================

const StyledHeaderFixedContainer = styled.div<{ $visible: boolean; $isSP: boolean }>`
  position: fixed;
  top: 0;
  z-index: ${(props) => props.theme.zIndex.appBar};

  width: calc(
    100% -
      ${(props) => {
        if (props.$isSP) {
          return 0;
        }

        return props.theme.isOpenSidebar
          ? props.theme.constants.SIDEBAR_OPEN_WIDTH
          : props.theme.constants.SIDEBAR_CLOSED_WIDTH;
      }}px
  );
  left: ${(props) => {
    if (props.$isSP) {
      return 0;
    }

    return props.theme.isOpenSidebar
      ? props.theme.constants.SIDEBAR_OPEN_WIDTH
      : props.theme.constants.SIDEBAR_CLOSED_WIDTH;
  }}px;

  ${({ $visible }) =>
    $visible
      ? css`
          opacity: 1;
          visibility: visible;
        `
      : css`
          opacity: 0;
          visibility: hidden;
        `}
`;
