import React, { FC } from "react";

export const ArrowDown: FC = () => {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.4004 16.6L10.8004 12L15.4004 7.4L14.0004 6L8.00039 12L14.0004 18L15.4004 16.6Z"
        stroke="none"
      />
    </svg>
  );
};
