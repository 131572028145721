import {
  OnnEventDeterminedDate,
  OnnEventEvaluation,
  CandidateDate,
  Employee,
  OnnEvent,
  OnnEventSlotDate,
} from "@onn/common";

import { RecruitmentProcessRecordByEmployee } from "~/hooks/recruitmentProcess/useRecruitmentProcessRecordsByEmployee";

export const columnTypes = [
  "recordLabel",
  "status",
  "schedule",
  "assignee",
  "evaluation",
  "relatedFiles",
  "updatedAt",
] as const;
// key の順序をテーブル表示に利用する

export type ColumnType = (typeof columnTypes)[number];
export type BodyRowData = {
  recordLabel: RecruitmentProcessRecordByEmployee;
  status?: string;
  schedule: {
    isEvent: boolean;
    shouldPromptForScheduleSetting: boolean;
    onnEventInfo?: {
      onnEvent: OnnEvent;
      candidateDate?: CandidateDate;
      determinedDate?: OnnEventDeterminedDate;
      slotDate?: OnnEventSlotDate;
    };
    newGraduateId: string;
  };
  assignee: {
    isEvent: boolean;
    shouldPromptForScheduleSetting: boolean;
    onnEventInfo?: {
      onnEvent: OnnEvent;
      candidateDate?: CandidateDate;
      determinedDate?: OnnEventDeterminedDate;
      slotDate?: OnnEventSlotDate;
    };
    assignee?: Employee;
    newGraduateId: string;
  };
  evaluation: {
    isEvent: boolean;
    onnEventId?: string;
    newGraduateId: string;
    newGraduateName: string;
    shouldPromptForScheduleSetting: boolean;
    canEvaluate: boolean;
    evaluation?: OnnEventEvaluation;
  };
  relatedFiles: string[];
  updatedAt: Date;
};

export const labelMap: Record<ColumnType, string> = {
  recordLabel: "履歴",
  status: "ステータス",
  schedule: "日程",
  assignee: "担当者",
  evaluation: "評価",
  relatedFiles: "関連ファイル",
  updatedAt: "更新日時",
};

export const widthPxMap: Record<ColumnType, number> = {
  recordLabel: 160,
  status: 160,
  schedule: 160,
  assignee: 120,
  evaluation: 120,
  relatedFiles: 200,
  updatedAt: 160,
};

export const EMPTY_TEXT_AREA_HEIGHT = 200;

export const HEADER_CELL_HEIGHT_PX = 56;
export const BODY_CELL_HEIGHT_PX = 72;
