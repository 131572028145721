import { z } from "zod";

const statues = ["WAITING", "SENDING", "SENT", "FAILED"] as const;
export type Status = (typeof statues)[number];

export const onnTaskLinkedOnnEventCandidateDateOrderSchema = z.object({
  id: z.string(),
  tenantId: z.string(),
  spaceId: z.string(),
  onnEventId: z.string(),
  onnEventCandidateDateId: z.string(),
  onnTaskId: z.string(),

  status: z.enum(statues),

  createdAt: z.date(),
  updatedAt: z.date(),
});

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IOnnTaskLinkedOnnEventCandidateDateOrder
  extends z.infer<typeof onnTaskLinkedOnnEventCandidateDateOrderSchema> {}
