import { Box } from "@material-ui/core";
import { OnnEventDeterminedDate } from "@onn/common";
import React, { FC } from "react";
import styled from "styled-components";

import { Icon, Typography } from "~/components/uiParts";

export const EventTypeCell: FC<{ determinedDate?: OnnEventDeterminedDate }> = ({
  determinedDate,
}) => {
  if (!determinedDate?.eventFormat) return "-";

  if (determinedDate.eventFormat.type === "online" && determinedDate.eventFormat.url) {
    return (
      <CellWrapper p={"1px"}>
        <StyledAnchor href={determinedDate.eventFormat.url} target="_blank">
          <Typography variant="body2" color="primary" style={{ cursor: "pointer" }}>
            オンライン
          </Typography>
          <Icon icon="link" size="md" color="primary" />
        </StyledAnchor>
      </CellWrapper>
    );
  }

  return (
    <CellWrapper p={"1px"}>
      <Typography variant="body2" color="textSecondary">
        {determinedDate.eventFormat?.type === "online" ? "オンライン" : "オフライン・対面"}
      </Typography>
    </CellWrapper>
  );
};

const CellWrapper = styled(Box)<{ bgcolor?: string }>`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.bgcolor};
  border: solid ${(props) => props.theme.palette.grey[100]};
  border-width: 1px 1px 0px 0px;
`;

const StyledAnchor = styled.a`
  text-decoration: none;
  color: inherit;
  display: flex;
  align-items: center;
  gap: 4px;
`;
