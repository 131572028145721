import { useCallback, useState } from "react";

import { useSnackbar } from "../shared";

import { apiClient } from "~/libs";
import { captureException } from "~/util";

/**
 * タグを削除する関数を提供するhooks
 */
export const useDeleteEmployeeTag = (): {
  isLoading: boolean;
  deleteEmployeeTag: (tenantId: string, tagId: string) => Promise<void>;
} => {
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);

  /**
   * タグを削除する関数
   */
  const deleteEmployeeTag = useCallback(
    async (_tenantId: string, tagId: string) => {
      setIsLoading(true);
      await apiClient
        .delete("/tag_api/employee-tags", {
          employeeTagId: tagId,
        })
        .then(() => {
          enqueueSnackbar("タグを削除しました", { variant: "success" });
        })
        .catch((e) => {
          enqueueSnackbar(e.message, { variant: "error" });
          captureException({
            error: e as Error,
            tags: {
              type: "useDeleteEmployeeTag:deleteEmployeeTag",
            },
          });
        })
        .finally(() => setIsLoading(false));
    },
    [enqueueSnackbar]
  );

  return {
    isLoading,
    deleteEmployeeTag,
  };
};
