import {
  CandidateDateWithNumberOfParticipants,
  EmployeeActiveLog,
  OnnEvent,
  OnnEventDeterminedDate,
} from "@onn/common";
import React, { useContext, useMemo } from "react";
import { GridCellRenderer, Index } from "react-virtualized";

import { BodyCell } from "./TableCell/BodyCell";
import { HeaderCell } from "./TableCell/HeaderCell";

import { RecruitmentStatusesContext } from "~/components/providers/RecruitmentStatusProvider";
import { OnnEventAnswerWithEmployee } from "~/hooks/onnEvent";

const EMPTY_TEXT_AREA_HEIGHT = 200;

const BODY_CELL_HEIGHT = 72;

const HEADER_HEIGHT_WHEN_EXIST_CAPACITY = 88;

const HEADER_HEIGHT_WHEN_NOT_EXIST_CAPACITY = 60;

export const useAnswerResultTable = ({
  onnEvent,
  onnEventAnswers,
  candidateDateList,
  employeeIdToDeterminedDate,
  hasCapacity,
  numberOfDistribution,
  numberOfResponses,
  employeeIdToLatestEmployeeActiveLogMap,
  selectedCandidateDateIds,
}: {
  onnEvent: OnnEvent;
  candidateDateList: CandidateDateWithNumberOfParticipants[];
  onnEventAnswers: OnnEventAnswerWithEmployee[];
  employeeIdToDeterminedDate: Map<string, OnnEventDeterminedDate>;
  hasCapacity: boolean;
  numberOfDistribution: number;
  numberOfResponses: number;
  employeeIdToLatestEmployeeActiveLogMap: Map<string, EmployeeActiveLog>;
  selectedCandidateDateIds: string[];
}) => {
  const { recruitmentStatusMap } = useContext(RecruitmentStatusesContext);

  // 回答者がいるかどうか
  const isExistBodyRows = useMemo(() => !!onnEventAnswers.length, [onnEventAnswers]);

  // NOTE:
  // 開催日時フィルターが何も選択されていない場合は「内定者アイコンの列」＋「回答状況列」＋「候補日の列」＋「参加できる日程がない列」の合計値が列数
  // 開催日時フィルターが選択されている場合は「内定者アイコンの列」＋「回答状況列」＋「選択されている開催日時（参加できる日程がないも含む）の列」の合計値が列数
  const employeeColumnCount = 1;
  const answerStatusColumnCount = 1;
  const candidateDateColumnCount = candidateDateList.length;
  const cannotParticipateColumnCount = 1;
  const columnCount =
    employeeColumnCount +
    answerStatusColumnCount +
    (selectedCandidateDateIds.length === 0
      ? candidateDateColumnCount + cannotParticipateColumnCount
      : selectedCandidateDateIds.length);

  // ヘッダー行の数＋回答者数 の合計値が行数
  const headerRowCount = 1;
  const rowCount = headerRowCount + onnEventAnswers.length;

  // ヘッダーの高さ
  const headerCellHeight = hasCapacity
    ? HEADER_HEIGHT_WHEN_EXIST_CAPACITY
    : HEADER_HEIGHT_WHEN_NOT_EXIST_CAPACITY;

  // 各行の高さ
  const rowHeight = (params: Index) => (params.index === 0 ? headerCellHeight : BODY_CELL_HEIGHT);
  // 各カラムの幅
  const columnWidth = (params: Index) => (params.index === 0 ? 204 : 148);

  // multiGridの高さ
  const multiGridHight = useMemo(() => {
    const height = headerCellHeight + BODY_CELL_HEIGHT * (rowCount - 1);
    return height > 1000 ? 1000 : height;
  }, [headerCellHeight, rowCount]);

  // 回答結果テーブルペーパーの高さ
  const paperHeight = useMemo(() => {
    const paddingTop = 40;
    const paddingBottom = 40;
    const result = paddingTop + paddingBottom + multiGridHight;
    if (isExistBodyRows) return result;
    return result + EMPTY_TEXT_AREA_HEIGHT;
  }, [isExistBodyRows, multiGridHight]);

  // multiGridの各セルでのレンダリング
  const cellRenderer: GridCellRenderer = ({ style, rowIndex, columnIndex }) => {
    // ヘッダー行
    if (rowIndex === 0) {
      return (
        <HeaderCell
          {...{
            style,
            candidateDateList,
            onnEventAnswers,
            hasCapacity,
            columnIndex,
            numberOfDistribution,
            numberOfResponses,
            columnLength: columnCount,
            selectedCandidateDateIds,
          }}
        />
      );
    }

    // ボディ行
    const onnEventAnswer = onnEventAnswers[rowIndex - 1];
    const determinedDate =
      onnEventAnswer && employeeIdToDeterminedDate.get(onnEventAnswer.employeeId);
    const candidateDate = candidateDateList[columnIndex - 2];
    const candidateDateMap = new Map(
      candidateDateList.map((candidateDate) => [candidateDate.id, candidateDate])
    );
    const answeredCandidateDate = determinedDate
      ? candidateDateMap.get(determinedDate.candidateDateId)
      : undefined;
    const isLastColumn = columnIndex === candidateDateList.length + 1;

    const employeeActiveLog =
      onnEventAnswer && employeeIdToLatestEmployeeActiveLogMap.get(onnEventAnswer.employeeId);
    return (
      <BodyCell
        style={{ ...style }}
        isLastColumn={isLastColumn}
        columnIndex={columnIndex}
        onnEvent={onnEvent}
        onnEventAnswer={onnEventAnswer}
        columnLength={columnCount}
        determinedDate={determinedDate}
        answeredCandidateDate={answeredCandidateDate}
        candidateDate={candidateDate}
        employeeActiveLog={employeeActiveLog}
        recruitmentStatusMap={recruitmentStatusMap}
        selectedCandidateDateIds={selectedCandidateDateIds}
        candidateDateList={candidateDateList}
      />
    );
  };
  return {
    cellRenderer,
    rowCount,
    columnCount,
    rowHeight,
    columnWidth,
    paperHeight,
    multiGridHight,
    isExistBodyRows,
  };
};
