import { Box } from "@material-ui/core";
import { OnnEventSlotDate } from "@onn/common";
import React, { FC, useCallback } from "react";
import styled from "styled-components";

import { Button, Icon, Linkify, Typography } from "~/components/uiParts";
import { colors } from "~/config/theme";
import { useClipboard } from "~/hooks/shared";

type Props = {
  selectedOnnEventSlotDate: OnnEventSlotDate;
};

export const NewInterviewEventFormatInfoSection: FC<Props> = ({ selectedOnnEventSlotDate }) => {
  const { handleCopy } = useClipboard();

  const handleClickCopy = useCallback(() => {
    if (selectedOnnEventSlotDate.eventType === "online" && selectedOnnEventSlotDate?.url) {
      handleCopy(selectedOnnEventSlotDate?.url, "面談用リンク");
    }
  }, [handleCopy, selectedOnnEventSlotDate.eventType, selectedOnnEventSlotDate?.url]);

  return (
    <Box display="flex" flexDirection="column" gridRowGap="24px">
      <Typography variant="body1" bold>
        開催方法
      </Typography>
      <Box display="flex" flexDirection="column" gridRowGap="8px">
        <Typography variant="body1">
          {selectedOnnEventSlotDate.eventType === "online" ? "オンライン" : "オフライン"}
        </Typography>
        {selectedOnnEventSlotDate.eventType === "offline" && (
          <Box p={2} style={{ background: colors.grey[50], borderRadius: 4 }}>
            <Typography variant="body2">{selectedOnnEventSlotDate.eventAddressText}</Typography>
          </Box>
        )}
        <Box p={2} style={{ background: colors.grey[50], borderRadius: 4 }}>
          <Typography variant="body2">
            <Linkify>{selectedOnnEventSlotDate.description}</Linkify>
          </Typography>
          {selectedOnnEventSlotDate.eventType === "online" && (
            <>
              {selectedOnnEventSlotDate.url ? (
                <Box mt={3} display="flex" flexDirection="column" gridGap="16px">
                  {/* TODO: 直接アクセスされるのではなくModalを開くようにする https://app.clickup.com/t/860rzpg10 */}
                  <StyledAnchor href={selectedOnnEventSlotDate.url} target="_blank">
                    <Button fullWidth borderRadius="circle" variant="contained" color="primary">
                      オンライン面談・面接を開始する
                    </Button>
                  </StyledAnchor>
                  <Box
                    display="flex"
                    gridGap="4px"
                    alignItems="center"
                    mx="auto"
                    onClick={handleClickCopy}
                    style={{ cursor: "pointer" }}
                  >
                    <Typography variant="body2" bold color="primary">
                      面談・面接用のリンクをコピー
                    </Typography>
                    <Icon icon="copy" size="sm" color="primary" />
                  </Box>
                </Box>
              ) : (
                <Box
                  mt={3}
                  py={1}
                  style={{
                    textAlign: "center",
                    background: "white",
                    border: `1px solid ${colors.grey[200]}`,
                    borderRadius: 8,
                  }}
                >
                  <Typography variant="body2" bold color="textSecondary">
                    {`当日までに設定された\n面談・面接用のリンクが表示されます`}
                  </Typography>
                </Box>
              )}
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
};

const StyledAnchor = styled.a`
  text-decoration: none;
  color: inherit;
`;
