import { APISchema, OnnFormTask, OnnTaskQuestion } from "@onn/common";
import { instanceToPlain } from "class-transformer";

import { apiClient } from "~/libs";

type Body = APISchema["/create_onn_form_task"]["POST"]["body"];
export const useCreateOnnFormTask = () => {
  const createOnnFormTask = async ({
    onnFormTask,
    questions,
  }: {
    onnFormTask: OnnFormTask;
    questions: OnnTaskQuestion[];
  }) => {
    const body: Body = {
      onnFormTask,
      questions,
    };
    await apiClient.post("/create_onn_form_task", instanceToPlain(body) as Body);
  };

  return { createOnnFormTask };
};
