import { Box, DialogContent } from "@material-ui/core";
import { OnnEvent } from "@onn/common";
import React, { FC, useState, useCallback } from "react";
import styled from "styled-components";

import { Button, Typography, Modal } from "~/components/uiParts";
import { OnnEventAnswerWithEmployee } from "~/hooks/onnEvent/answerResult/useOnnEventAnswersWithEmployee";
import { mixin } from "~/util";

type Props = {
  open: boolean;
  onCancel: () => void;
  onSubmit: (
    onnEvent: OnnEvent,
    onnEventAnswerWithEmployee: OnnEventAnswerWithEmployee
  ) => Promise<void>;
  onnEvent: OnnEvent;
  onnEventAnswerWithEmployee: OnnEventAnswerWithEmployee;
};

export const RemoveEmployeeFromOnnEventCandidateListModal: FC<Props> = ({
  open,
  onCancel,
  onSubmit,
  onnEvent,
  onnEventAnswerWithEmployee,
}) => {
  const [sending, setSending] = useState(false);

  const handleSubmit = useCallback(async () => {
    setSending(true);
    await onSubmit(onnEvent, onnEventAnswerWithEmployee)
      .then(() => {
        onCancel();
      })
      .finally(() => {
        setSending(false);
      });
  }, [onCancel, onSubmit, onnEvent, onnEventAnswerWithEmployee]);

  const Content = (
    <StyledDialogContent>
      <StyledBox>
        <StyledTypographyWithMB display="block" variant="body1" color="textSecondary">
          {`${onnEventAnswerWithEmployee.employee.getName()}さんはすでに回答を完了しています。\n「${
            onnEvent.title
          }」の配信対象からを削除しますか？`}
        </StyledTypographyWithMB>
        <Typography display="block" variant="caption" color="textSecondary">
          {`削除後、自動でイベントの対象から削除された旨が該当のメンバーに通知されます。\nこの操作は取り消すことができません。`}
        </Typography>
      </StyledBox>
    </StyledDialogContent>
  );

  const Footer = (
    <StyledButtonContainer>
      <Button fullWidth borderRadius="circle" variant="outlined" color="default" onClick={onCancel}>
        キャンセル
      </Button>
      <Button
        fullWidth
        borderRadius="circle"
        variant="contained"
        color="secondary"
        disabled={sending}
        onClick={handleSubmit}
      >
        削除
      </Button>
    </StyledButtonContainer>
  );

  return (
    <Modal open={open} title="配信対象削除" content={Content} footer={Footer} onCancel={onCancel} />
  );
};

const StyledDialogContent = styled(DialogContent)`
  &.MuiDialogContent-root {
    padding: 0;
    margin: 0 auto;
  }
`;

const StyledBox = styled(Box)`
  padding-top: 16px;
  text-align: center;
`;

const StyledButtonContainer = styled(Box)`
  ${mixin.fixedWidthButtonContainer}
`;

const StyledTypographyWithMB = styled(Typography)`
  &.MuiTypography-root {
    margin-bottom: 32px;
  }
`;
