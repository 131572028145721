import { z } from "zod";

import { OnnEventSlotDateSchema } from "../../../domain/_gen/zodSchema";

export const onnEventSlotDateSchema = OnnEventSlotDateSchema.merge(
  z.object({
    capacity: z.number().nullable(),
    assigneeId: z.string().nullable(),
    eventAddressPostCode: z.string().nullable(),
    eventAddressText: z.string().nullable(),
    url: z.string().nullable(),
    eventPlaceId: z.string().nullable(),
  })
).refine((data) => data.from < data.until, {
  message: "終了日時は開始日時より後に設定してください",
});

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IOnnEventSlotDate extends z.infer<typeof onnEventSlotDateSchema> {}
