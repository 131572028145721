import { useCallback, useState } from "react";
import { useSWRConfig } from "swr";

import { useMutateAllNewcomers } from "../useAllNewcomers";
import { generateUseEmployeeKeys } from "../useEmployee";

import { generateUseNewGraduateKey } from "../useNewGraduate";

import { useSnackbar } from "~/hooks/shared";

import { apiClient } from "~/libs";
import { captureException } from "~/util";

/**
 * 招待通知を送信する関数を提供するhooks
 */
export const useSendInvitationNotification = () => {
  const [isLoading, setIsLoading] = useState(false);

  const { enqueueSnackbar } = useSnackbar();
  const { mutate } = useSWRConfig();
  const { mutateAllNewcomers } = useMutateAllNewcomers();

  /**
   * 招待通知を送信する関数
   * @param employeeId employeeId
   * @returns {Employee} employee
   */
  const sendInvitation = useCallback(
    async (employeeId: string, isRemind: boolean) => {
      setIsLoading(true);
      await apiClient
        .post(`/send_invitation_notifications`, {
          "employee-ids": [employeeId],
        })
        .then(() => {
          mutate(generateUseEmployeeKeys(employeeId));
          mutate(generateUseNewGraduateKey({ newGraduateId: employeeId }));
          mutateAllNewcomers();
          enqueueSnackbar(`招待メールを${isRemind ? "再送" : "送信"}しました`, {
            variant: "success",
          });
        })
        .catch((error) => {
          enqueueSnackbar(error.message, { variant: "error" });
          captureException({
            error: error as Error,
            tags: { type: "useSendInvitationNotification:sendInvitation" },
          });
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [mutate, mutateAllNewcomers, enqueueSnackbar]
  );

  return { sendInvitation, isLoading };
};
