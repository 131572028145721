import { APISchema } from "@onn/common";
import { useCallback } from "react";

import { mutateOnnEventSlotDatesForDisplay } from "./useOnnEventSlotDatesForDisplay";

import { apiClient } from "~/libs";

type Endpoint = APISchema["/delete_onn_event_slot"]["DELETE"];
export const useDeleteOnnEventSlotDate = () => {
  const deleteOnnEventSlotDates = useCallback(async (arg: Endpoint["body"]) => {
    const requestBody: Endpoint["body"] = {
      onnEventId: arg.onnEventId,
      onnEventSlotId: arg.onnEventSlotId,
    };

    const response = await apiClient.delete("/delete_onn_event_slot", {}, requestBody, {
      isNotifyError: true,
    });
    mutateOnnEventSlotDatesForDisplay(arg.onnEventId);

    return response;
  }, []);

  return { deleteOnnEventSlotDates };
};
