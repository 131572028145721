import {
  createIMockCandidateDate,
  createIMockOnnEventAnswer,
  createIMockOnnEventDeterminedDate,
  OnnEvent,
  toCloudFunctionsCompatible,
  OnnEventSlotDateWithNumberOfParticipants,
} from "@onn/common";
import { addDays, setHours, setMinutes } from "date-fns";
import { useCallback } from "react";

import { useCurrentUser } from "../../employee";
import { useCandidateDatesWithNumberOfParticipants } from "../../onnEvent";
import { ONN_EVENT_PREVIEW_DATA_KEY } from "../../onnEvent/useOnnEventForPortal";
import { useOnnEventPlaces } from "../../onnEventPlace/useOnnEventPlaces";
import { useOnnEventSlotDatesForDisplay } from "../../onnEventSlotDates/useOnnEventSlotDatesForDisplay";
import { useTenantSettings } from "../../tenantSetting";

import { useLocalStorage } from "~/hooks/shared";

export const useOpenPortalOnnEventPreview = (onnEventId?: string) => {
  const { currentUser } = useCurrentUser();
  const { storeValue } = useLocalStorage();
  const { tenantSettings } = useTenantSettings();
  const { data: onnEventPlaces, isLoading: isLoadingOnnEventPlaces } = useOnnEventPlaces();
  const {
    data: onnEventSlotDateWithNumberOfParticipants,
    isLoading: isLoadingOnnEventSlotDateWithNumberOfParticipants,
  } = useOnnEventSlotDatesForDisplay(onnEventId);

  const {
    data: candidateDatesWithNumberOfParticipants,
    isLoading: isLoadingCandidateDatesWithNumberOfParticipants,
  } = useCandidateDatesWithNumberOfParticipants({
    onnEventId: onnEventId,
    currentEmployeeId: currentUser.id,
  });

  const storeNormalEvent = useCallback(
    (onnEvent: OnnEvent) => {
      storeValue(ONN_EVENT_PREVIEW_DATA_KEY, {
        onnEvent: toCloudFunctionsCompatible({ ...onnEvent, type: "normal" }),
        onnEventAnswer: toCloudFunctionsCompatible(createIMockOnnEventAnswer()),
      });
    },
    [storeValue]
  );

  const storeInterviewEvent = useCallback(
    (onnEvent: OnnEvent) => {
      const candidateDate = createIMockCandidateDate({
        // NOTE: イメージがつきやすいように、現在時点から1週間後の15時から15時半の間に設定する
        from: addDays(setMinutes(setHours(new Date(), 15), 0), 7),
        until: addDays(setMinutes(setHours(new Date(), 15), 30), 7),
      });
      storeValue(ONN_EVENT_PREVIEW_DATA_KEY, {
        onnEvent: toCloudFunctionsCompatible({
          ...onnEvent,
          type: "interview",
          candidateDates: [candidateDate],
        }),
        onnEventAnswer: toCloudFunctionsCompatible(
          createIMockOnnEventAnswer({
            answer: {
              [candidateDate.id]: "possible",
            },
          })
        ),
        onnEventDeterminedDate: toCloudFunctionsCompatible(
          createIMockOnnEventDeterminedDate({
            candidateDateId: candidateDate.id,
            eventFormat: {
              // NOTE: オンラインの開催概要の方が情報量の多いため表示している
              type: "online",
              description:
                tenantSettings?.eventFormatTemplates?.online.description ||
                "ここにイベントの開催概要が入ります。",
              url: tenantSettings?.eventFormatTemplates?.online.url || "",
            },
          })
        ),
      });
    },
    [
      storeValue,
      tenantSettings?.eventFormatTemplates?.online.description,
      tenantSettings?.eventFormatTemplates?.online.url,
    ]
  );

  const storeForNewInterviewEvent = useCallback(
    (onnEvent: OnnEvent) => {
      storeValue(ONN_EVENT_PREVIEW_DATA_KEY, {
        onnEvent: toCloudFunctionsCompatible({ ...onnEvent, type: "new_interview" }),
        onnEventAnswer: toCloudFunctionsCompatible(
          createIMockOnnEventAnswer({ employeeId: currentUser.id, tenantId: currentUser.tenantId })
        ),
        onnEventDeterminedDate: null,
        onnEventPlaces: onnEventPlaces || [],
        candidateDatesWithNumberOfParticipants: candidateDatesWithNumberOfParticipants || [],
        onnEventSlotDateWithNumberOfParticipants:
          onnEventSlotDateWithNumberOfParticipants
            ?.map(
              (v) =>
                new OnnEventSlotDateWithNumberOfParticipants({
                  onnEventSlotDate: v,
                  numberOfParticipants: v.reservedCount,
                })
            )
            .filter((v) => v.canParticipate()) || [],
      });
    },
    [
      candidateDatesWithNumberOfParticipants,
      currentUser.id,
      currentUser.tenantId,
      onnEventPlaces,
      onnEventSlotDateWithNumberOfParticipants,
      storeValue,
    ]
  );

  /**
   * ポータルのイベントプレビューを開く
   * @param {OnnEvent} onnEvent
   */
  const openPortalOnnEventPreview = useCallback(
    (onnEvent: OnnEvent) => {
      switch (onnEvent.type) {
        case "normal":
          storeNormalEvent(onnEvent);
          break;
        case "interview":
          storeInterviewEvent(onnEvent);
          break;
        case "new_interview":
          storeForNewInterviewEvent(onnEvent);
          break;
      }
      window.open(`/portal/events/${onnEvent.id}?preview=true`, "popup", "width=375,height=480");
    },
    [storeForNewInterviewEvent, storeInterviewEvent, storeNormalEvent]
  );

  const isReady =
    !isLoadingOnnEventPlaces ||
    !isLoadingOnnEventSlotDateWithNumberOfParticipants ||
    !isLoadingCandidateDatesWithNumberOfParticipants;

  return { openPortalOnnEventPreview, isReady };
};
