import { Theme } from "@material-ui/core";
import React from "react";
import styled from "styled-components";

import { Add } from "./icons/Add";
import { Analytics } from "./icons/Analytics";
import { ArrowDouble } from "./icons/ArrowDouble";
import { ArrowDown } from "./icons/ArrowDown";
import { ArrowDropDown } from "./icons/ArrowDropDown";
import { ArrowDropUp } from "./icons/ArrowDropUp";
import { ArrowLeft } from "./icons/ArrowLeft";
import { ArrowRight } from "./icons/ArrowRight";
import { ArrowUp } from "./icons/ArrowUp";
import { At } from "./icons/At";
import { Ban } from "./icons/Ban";
import { Bookmark } from "./icons/Bookmark";
import { BookmarkBorder } from "./icons/BookmarkBorder";
import { Calendar } from "./icons/Calendar";
import { Check } from "./icons/Check";
import { CheckCircle } from "./icons/CheckCircle";
import { CheckMarkFill } from "./icons/CheckMarkFill";
import { CheckOutlineCircle } from "./icons/CheckOutlineCircle";
import { ChevronDown } from "./icons/ChevronDown";
import { ChevronLeft } from "./icons/ChevronLeft";
import { ChevronRight } from "./icons/ChevronRight";
import { ChevronUp } from "./icons/ChevronUp";
import { Circle } from "./icons/Circle";
import { Clip } from "./icons/Clip";
import { Clock } from "./icons/Clock";
import { Close } from "./icons/Close";
import { CloudUpload } from "./icons/CloudUpload";
import { Copy } from "./icons/Copy";
import { Cross } from "./icons/Cross";
import { Document } from "./icons/Document";
import { DoubleArrowRight } from "./icons/DoubleArrowRight";
import { Download } from "./icons/Download";
import { DropdownArrow } from "./icons/DropdownArrow";
import { Edit } from "./icons/Edit";
import { Email } from "./icons/Email";
import { EmojiSmile } from "./icons/EmojiSmile";
import { Error } from "./icons/Error";
import { Eye } from "./icons/Eye";
import { Feedback } from "./icons/Feedback";
import { File } from "./icons/File";
import { FilePdf } from "./icons/FilePdf";
import { Filter } from "./icons/Filter";
import { Form } from "./icons/Form";
import { GoBack } from "./icons/GoBack";
import { Grid } from "./icons/Grid";
import { GripHorizontal } from "./icons/GripHorizontal";
import { GripVertical } from "./icons/GripVertical";
import { Help } from "./icons/Help";
import { HorizontalBar } from "./icons/HorizontalBar";
import { Info } from "./icons/Info";
import { Line } from "./icons/Line";
import { LineChat } from "./icons/LineChat";
import { Link } from "./icons/Link";
import { List } from "./icons/List";
import { Location } from "./icons/Location";
import { Memo } from "./icons/Memo";
import { MenuHor } from "./icons/MenuHor";
import { MenuVert } from "./icons/MenuVert";
import { Navi } from "./icons/Navi";
import { Notice } from "./icons/Notice";
import { Onboarding } from "./icons/Onboarding";
import { PaperAirplane } from "./icons/PaperAirplane";
import { Pencil } from "./icons/Pencil";
import { Photo } from "./icons/Photo";
import { QRCode } from "./icons/QRCode";
import { Remove } from "./icons/Remove";
import { Search } from "./icons/Search";
import { Security } from "./icons/Security";
import { Send } from "./icons/Send";
import { Setting } from "./icons/Setting";
import { Slack } from "./icons/Slack";
import { SpeechBalloon } from "./icons/SpeechBalloon";
import { Story } from "./icons/Story";
import { Suitcase } from "./icons/Suitcase";
import { Trash } from "./icons/Trash";
import { Triangle } from "./icons/Triangle";
import { UnVisibility } from "./icons/UnVisibility";
import { Upload } from "./icons/Upload";
import { User } from "./icons/User";
import { Visibility } from "./icons/Visibility";
import { Warning } from "./icons/Warning";

const SIZE = {
  vsm: 8,
  sm: 16,
  ssm: 20,
  md: 24,
  lg: 32,
  xl: 40,
  xxl: 80,
};

const COLOR = (theme: Theme) => {
  return {
    primary: theme.palette.primary.main,
    secondary: theme.palette.secondary.main,
    lightGrey: theme.palette.grey[200],
    grey300: theme.palette.grey[300],
    grey400: theme.palette.grey[400],
    grey500: theme.palette.grey[500],
    grey: theme.palette.text.secondary,
    darkGrey: theme.palette.grey[500],
    error: theme.palette.error.main,
    white: theme.palette.primary.contrastText,
    blue: theme.palette.blue.main,
    inherit: "currentColor",
  } as const;
};

export const ICONS = [
  "add",
  "analytics",
  "arrowDouble",
  "arrowDropDown",
  "arrowDropUp",
  "arrowLeft",
  "arrowRight",
  "arrowDown",
  "arrowUp",
  "at",
  "ban",
  "bookmark",
  "bookmarkBorder",
  "calendar",
  "check",
  "checkCircle",
  "checkOutlineCircle",
  "checkMarkFill",
  "chevronDown",
  "chevronLeft",
  "chevronRight",
  "chevronUp",
  "circle",
  "clip",
  "clock",
  "close",
  "cloudUpload",
  "copy",
  "cross",
  "edit",
  "email",
  "emojiSmile",
  "document",
  "doubleArrowRight",
  "download",
  "dropdownArrow",
  "error",
  "eye",
  "feedback",
  "file",
  "filePdf",
  "filter",
  "form",
  "goBack",
  "grid",
  "gripHorizontal",
  "gripVertical",
  "help",
  "horizontalBar",
  "info",
  "line",
  "lineChat",
  "link",
  "list",
  "location",
  "memo",
  "menuHor",
  "menuVert",
  "navi",
  "notice",
  "onboarding",
  "paperAirplane",
  "pencil",
  "photo",
  "qrCode",
  "remove",
  "search",
  "security",
  "send",
  "setting",
  "slack",
  "speechBalloon",
  "story",
  "suitcase",
  "trash",
  "triangle",
  "unVisibility",
  "upload",
  "user",
  "visibility",
  "warning",
] as const;

export type IconType = (typeof ICONS)[number];

type Props = {
  size?: "vsm" | "sm" | "ssm" | "md" | "lg" | "xl" | "xxl";
  icon: IconType;
  color: keyof ReturnType<typeof COLOR>;
  id?: string;
};

export const Icon = ({ size, icon, color, id, ...props }: Props): JSX.Element => {
  const icons = {
    add: <Add />,
    analytics: <Analytics />,
    arrowDouble: <ArrowDouble />,
    arrowDropDown: <ArrowDropDown />,
    arrowDropUp: <ArrowDropUp />,
    arrowLeft: <ArrowLeft />,
    arrowRight: <ArrowRight />,
    arrowDown: <ArrowDown />,
    arrowUp: <ArrowUp />,
    at: <At />,
    ban: <Ban />,
    bookmark: <Bookmark />,
    bookmarkBorder: <BookmarkBorder />,
    calendar: <Calendar />,
    check: <Check />,
    checkCircle: <CheckCircle />,
    checkOutlineCircle: <CheckOutlineCircle />,
    checkMarkFill: <CheckMarkFill />,
    chevronDown: <ChevronDown />,
    chevronLeft: <ChevronLeft />,
    chevronRight: <ChevronRight />,
    chevronUp: <ChevronUp />,
    circle: <Circle />,
    clip: <Clip />,
    clock: <Clock />,
    close: <Close />,
    cloudUpload: <CloudUpload />,
    copy: <Copy />,
    cross: <Cross />,
    email: <Email />,
    emojiSmile: <EmojiSmile />,
    document: <Document />,
    doubleArrowRight: <DoubleArrowRight />,
    download: <Download />,
    dropdownArrow: <DropdownArrow />,
    edit: <Edit />,
    error: <Error />,
    eye: <Eye />,
    feedback: <Feedback />,
    file: <File />,
    filePdf: <FilePdf />,
    filter: <Filter />,
    form: <Form />,
    goBack: <GoBack />,
    grid: <Grid />,
    gripHorizontal: <GripHorizontal />,
    gripVertical: <GripVertical />,
    help: <Help />,
    horizontalBar: <HorizontalBar />,
    info: <Info />,
    line: <Line />,
    lineChat: <LineChat />,
    link: <Link />,
    list: <List />,
    location: <Location />,
    memo: <Memo />,
    menuHor: <MenuHor />,
    menuVert: <MenuVert />,
    navi: <Navi />,
    notice: <Notice />,
    onboarding: <Onboarding />,
    paperAirplane: <PaperAirplane />,
    pencil: <Pencil />,
    photo: <Photo />,
    qrCode: <QRCode />,
    remove: <Remove />,
    search: <Search />,
    security: <Security />,
    send: <Send />,
    setting: <Setting />,
    slack: <Slack />,
    speechBalloon: <SpeechBalloon />,
    story: <Story />,
    suitcase: <Suitcase />,
    trash: <Trash />,
    triangle: <Triangle />,
    unVisibility: <UnVisibility />,
    upload: <Upload />,
    user: <User />,
    visibility: <Visibility />,
    warning: <Warning />,
  };

  return (
    <StyledWrapper color={color} size={size} {...props} id={id}>
      {icons[icon]}
    </StyledWrapper>
  );
};

const StyledWrapper = styled.svg<Pick<Props, "size" | "color">>`
  flex-shrink: 0;
  ${(props) => props.size && `height: ${SIZE[props.size]}px;`}
  ${(props) => props.size && `width: ${SIZE[props.size]}px;`}
  fill: ${(props) => COLOR(props.theme)[props.color]};
  stroke: ${(props) => COLOR(props.theme)[props.color]};
`;
