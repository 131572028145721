import { Box } from "@material-ui/core";
import { EmployeeTag } from "@onn/common";
import React, { useCallback, useMemo, useState } from "react";

import { EmployeeTagsTable } from "~/components/domains/tenantSettings/EmployeeTagsTable";
import { Button, Icon, Loading, SearchForm } from "~/components/uiParts";
import { useCurrentUser } from "~/hooks/employee";
import { useEmployeeTags } from "~/hooks/employeeTag";
import { useMutateEmployeeTags } from "~/hooks/employeeTag/useEmployeeTags";
import { useModal } from "~/hooks/modal";
import { useFilterObjectByPartialMatch } from "~/hooks/shared";
import { useCreateEmployeeTag } from "~/hooks/tenantSetting";
import { NotFound } from "~/pages/NotFound";

export const TagTab = (): JSX.Element => {
  const { data: employeeTagsData, isLoading: isLoadingEmployeeTagsData } = useEmployeeTags();

  if (isLoadingEmployeeTagsData) {
    return <Loading size="large" fullHeight />;
  }

  if (!employeeTagsData) {
    return <NotFound />;
  }

  return (
    <TagTabCore
      employeeTags={employeeTagsData.employeeTags}
      relationsCountMap={employeeTagsData.meta.relationsCountMap}
    />
  );
};

const TagTabCore = ({
  employeeTags,
  relationsCountMap,
}: {
  employeeTags: EmployeeTag[];
  relationsCountMap: Record<string, number>;
}): JSX.Element => {
  const { currentUser } = useCurrentUser();
  const { handleModal } = useModal();

  const { createEmployeeTag } = useCreateEmployeeTag();
  const { filterObjectByPartialMatch } = useFilterObjectByPartialMatch();
  const { mutateEmployeeTags } = useMutateEmployeeTags();

  const [searchText, setSearchText] = useState("");
  const onSearchValue = useCallback((value: string) => {
    setSearchText(value);
  }, []);

  const filteredTags = useMemo(() => {
    return filterObjectByPartialMatch({
      objects: employeeTags,
      searchText,
      getProperties: [(tag) => tag.name],
    });
  }, [filterObjectByPartialMatch, searchText, employeeTags]);

  const handleOpenAddOrEditTagModal = useCallback(() => {
    handleModal({
      name: "addOrEditTagModal",
      args: {
        mode: "create",
        onSubmit: async (tag) => {
          await createEmployeeTag(currentUser.tenantId, tag).then(() => mutateEmployeeTags());
        },
        employeeTags,
      },
    });
  }, [handleModal, employeeTags, createEmployeeTag, currentUser.tenantId, mutateEmployeeTags]);

  return (
    <>
      <Box display="flex" alignItems="center" justifyContent="flex-end" mb="20px">
        <Box mr="16px">
          <SearchForm
            onSearchValue={onSearchValue}
            placeholder="タグ名で検索"
            variant="standard"
            fullWidth
          />
        </Box>
        <Box>
          <Button
            color="primary"
            variant="outlined"
            borderRadius="regular"
            startIcon={<Icon icon="add" color="primary" size="md" />}
            onClick={handleOpenAddOrEditTagModal}
          >
            タグを追加
          </Button>
        </Box>
      </Box>
      <EmployeeTagsTable
        employeeTags={filteredTags}
        relationsCountMap={relationsCountMap}
        mutateEmployeeTags={() => mutateEmployeeTags()}
      />
    </>
  );
};
