export const HEADER_LABELS = [
  "Onn固有ID",
  "日付",
  "開始時間",
  "終了時間",
  "担当者",
  "MTG 形式",
  "概要",
  "オンラインURL",
];

export const COLUMNS_NUMBER = {
  ONN_UNIQUE_ID: 0,
  DATE: 1,
  START_TIME: 2,
  END_TIME: 3,
  ASSIGNEE_EMAIL: 4,
  MTG_TYPE: 5,
  DESCRIPTION: 6,
  URL: 7,
} as const;
