import { z } from "zod";

import { JsonValueSchema } from "../inputTypeSchemas/JsonValueSchema";

/////////////////////////////////////////
// FORM REVISION SCHEMA
/////////////////////////////////////////

export const FormRevisionSchema = z.object({
  id: z.string(),
  tenantId: z.string(),
  formTaskId: z.string(),
  createdUserId: z.string(),
  questions: JsonValueSchema.array().nullable(),
  createdAt: z.date(),
});

export type FormRevision = z.infer<typeof FormRevisionSchema>;

/////////////////////////////////////////
// FORM REVISION OPTIONAL DEFAULTS SCHEMA
/////////////////////////////////////////

export const FormRevisionOptionalDefaultsSchema = FormRevisionSchema.merge(
  z.object({
    createdAt: z.date().optional(),
  })
);

export type FormRevisionOptionalDefaults = z.infer<typeof FormRevisionOptionalDefaultsSchema>;

export default FormRevisionSchema;
