import { useCallback, useState } from "react";

import { useSnackbar } from "../../shared";

import { EmployeeUseCase } from "~/service/usecases/employeeUseCase";
import { captureException } from "~/util";

/**
 * idをもとにフォロワーを解除する関数を提供するhooks
 */
export const useDismissFollower = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  /**
   * idをもとにフォロワーを解除する
   * @param {string} newGraduateId 入社者のid
   * @param {string} followerId フォロワーのid
   */
  const dismissFollower = useCallback(
    async (newGraduateId: string, followerId: string) => {
      setIsLoading(true);
      await EmployeeUseCase.dismissSupportMember(newGraduateId, followerId)
        .then(() => {
          enqueueSnackbar("フォロワーを解除しました", { variant: "success" });
        })
        .catch((e) => {
          enqueueSnackbar("フォロワーへの解除に失敗しました", { variant: "error" });
          captureException({
            error: e as Error,
            tags: { type: "useDismissSupportMember:dismissSupportMember" },
          });
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [enqueueSnackbar]
  );

  return {
    isLoading,
    dismissFollower,
  };
};
