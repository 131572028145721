import { v4 } from "uuid";
import { z } from "zod";

export const onnEventOnlineFormatSchema = z.object({
  type: z.literal("online"),
  description: z.string().trim().min(1, { message: "内容は1文字以上で設定してください" }),
  url: z
    .string()
    .trim()
    .url({ message: "正しい形式のURLで設定してください" })
    .or(z.string().length(0))
    // NOTE: 入力時に空文字を許容するためにtransformを使ってundefinedに変換する
    .transform((value) => (value === "" ? undefined : value))
    .optional(),
});

export const onnEventOfflineFormatSchema = z.object({
  type: z.literal("offline"),
  description: z.string().trim().min(1, { message: "内容は1文字以上で設定してください" }),
});

const AttendanceStatuses = ["ATTENDED", "ABSENT", "UNREGISTERED"] as const;
export type AttendanceStatus = (typeof AttendanceStatuses)[number];

export const onnEventDeterminedDateSchema = z.object({
  id: z.string().default(v4),
  onnEventId: z.string(),
  employeeId: z.string(),
  candidateDateId: z.string(),
  onnEventSlotDateId: z.string().optional(),
  createdAt: z.date().default(() => new Date()),
  updatedAt: z.date().default(() => new Date()),
  tenantId: z.string(),
  assigneeId: z.string().optional().nullable(),
  attendanceStatus: z.enum(AttendanceStatuses),
  eventFormat: z.union([onnEventOnlineFormatSchema, onnEventOfflineFormatSchema]).optional(),
  latestSentAtOnlineUrlNotification: z.date().optional(),
});

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IOnnEventDeterminedDate extends z.infer<typeof onnEventDeterminedDateSchema> {}
