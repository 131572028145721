import {
  OnnEvent,
  OnnEventAnswer,
  OnnEventEvaluation,
  OnnFormTask,
  OnnFormTaskAnswer,
} from "@onn/common";

import { format } from "date-fns";

import { BodyRowData } from "./const";

import { RecruitmentProcessRecordByEmployee } from "~/hooks/recruitmentProcess/useRecruitmentProcessRecordsByEmployee";

export const createBodyRowDataArray = (
  record: RecruitmentProcessRecordByEmployee
): BodyRowData[] => {
  const events = record.relation.onnEvent
    ? [{ ...record.relation, onnEvent: record.relation.onnEvent }]
    : [];
  // onnEvent が undefined の場合を除外する型合わせ

  const relations = [...events, ...(record.relation.onnTasksWithRelation || [])];

  const bodyRowDataArray = relations.map((r) => createBodyRowData(r));

  return bodyRowDataArray.sort((a, b) => {
    if (a.answeredAt && b.answeredAt) {
      return b.answeredAt.getTime() - a.answeredAt.getTime();
    } else if (a.answeredAt) {
      return -1;
    } else if (b.answeredAt) {
      return 1;
    } else {
      return 0;
    }
  });
};

const createBodyRowData = (
  relation:
    | {
        onnEvent: OnnEvent;
        statusLabel?: string;
        lastReadAt?: Date;
        onnEventAnswer?: OnnEventAnswer;
        onnEventEvaluation?: OnnEventEvaluation;
      }
    | {
        onnTask: OnnFormTask;
        statusLabel?: string;
        lastReadAt?: Date;
        answer?: OnnFormTaskAnswer;
      }
): BodyRowData => {
  if ("onnTask" in relation) {
    return {
      type: "タスク",
      title: { text: relation.onnTask.title, linkPath: `onn_tasks/${relation.onnTask.id}` },
      status:
        relation.statusLabel ||
        (relation.lastReadAt ? format(relation.lastReadAt, "yyyy/M/dd 既読") : undefined),
      relatedFiles:
        relation.answer?.answers.flatMap((a) =>
          a.type === "FILE" && a.filePath ? [a.filePath] : []
        ) || [],
      answeredAt: relation.answer?.lastAnsweredAt || undefined,
    };
  }
  return {
    type: "イベント",
    title: { text: relation.onnEvent.title, linkPath: `events/${relation.onnEvent.id}` },
    status:
      relation.statusLabel ||
      (relation.lastReadAt ? format(relation.lastReadAt, "yyyy/M/dd 既読") : undefined),
    relatedFiles: relation.onnEventEvaluation?.filePaths || [],
    answeredAt: relation.onnEventAnswer?.isAnswered()
      ? relation.onnEventAnswer?.updatedAt
      : undefined,
  };
};
