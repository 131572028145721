import { OnnTaskQuestion } from "@onn/common";

import { State } from "../reducer";

import { useSubmitHandler } from "./useSubmitHandler";
import { useValidateInput } from "./useValidateInput";

export const usePageForFormTaskCore = ({
  state,
  isPreview,
  formRevisionId,
  onnFormTaskId,
  questions,
  isAlreadyAnswered,
}: {
  state: State;
  isPreview?: boolean;
  formRevisionId: string;
  onnFormTaskId: string;
  questions: OnnTaskQuestion[];
  isAlreadyAnswered: boolean;
}) => {
  const { onClickSubmit, isSubmitting } = useSubmitHandler({
    state,
    isPreview,
    formRevisionId,
    onnFormTaskId,
    isAlreadyAnswered,
  });

  const { validateInput } = useValidateInput();

  const isValid = validateInput(state, questions);

  const isDisabledSubmit = !isValid || isSubmitting;

  return {
    onClickSubmit,
    isSubmitting,
    isDisabledSubmit,
  };
};
