import { Box } from "@material-ui/core";
import {
  CandidateDateWithNumberOfParticipants,
  EmployeeActiveLog,
  OnnEventDeterminedDate,
  RecruitmentStatus,
  NewGraduate,
  OnnEvent,
} from "@onn/common";
import React, { FC, useCallback, useMemo } from "react";
import styled from "styled-components";

import { CAN_NOT_JOIN } from "../../../hooks/OnnEventAnswerResultTab/filter/useFilterByCandidateDates";
import {
  StatusForDisplayEventTable,
  getStatusForDisplayEventTable,
} from "../utils/getStatusForDisplayEventTable";

import { AnswerIconForUnAvailable } from "./Body/AnswerIconForUnAvailable";
import { AnswerIconWhenExistDeterminedDate } from "./Body/AnswerIconWhenExistDeterminedDate";
import { AnswerIconWhenNotExistDeterminedDate } from "./Body/AnswerIconWhenNotExistDeterminedDate";
import { AnswerStatusCellWrapper } from "./Body/AnswerStatusCellWrapper";
import { UserIconWithLabel } from "./Body/UserIconWithLabel";

import { OnnEventAnswerWithEmployee } from "~/hooks/onnEvent";
import { captureException } from "~/util";

export const BodyCell: FC<
  Omit<BodyCellCoreProps, "onnEventAnswer" | "statusForDisplayEventTable"> & {
    style: React.CSSProperties;
    isLastColumn: boolean;
    onnEventAnswer?: OnnEventAnswerWithEmployee;
  }
> = ({
  style,
  isLastColumn,
  columnIndex,
  onnEvent,
  onnEventAnswer,
  columnLength,
  determinedDate,
  candidateDate,
  answeredCandidateDate,
  employeeActiveLog,
  recruitmentStatusMap,
  candidateDateList,
  selectedCandidateDateIds,
}) => {
  if (!onnEventAnswer) {
    captureException({
      error: new Error("onnEventAnswer が想定しない undefined になっています"),
      tags: { type: "BodyCellCore" },
    });
    return null;
  }

  const employee = onnEventAnswer.employee;

  const recruitmentStatus = recruitmentStatusMap.get(employee.recruitmentStatusId || "");
  if (!recruitmentStatus) {
    captureException({
      error: new Error("recruitmentStatus が想定しない undefined になっています"),
      tags: { type: "BodyCellCore" },
      extras: {
        employeeId: employee.id,
        recruitmentStatusId: employee.recruitmentStatusId,
        recruitmentStatusMap,
      },
    });
    return null;
  }

  const statusForDisplayEventTable = getStatusForDisplayEventTable({
    recruitmentStatus,
    onnEventDeterminedDate: determinedDate,
    onnEventAnswer,
    answeredCandidateDate,
    employeeActiveLog,
    newGraduate: employee,
  });

  return (
    <BodyCellWrapper style={{ ...style }} isLastColumn={isLastColumn}>
      <BodyCellCore
        columnIndex={columnIndex}
        onnEvent={onnEvent}
        onnEventAnswer={onnEventAnswer}
        columnLength={columnLength}
        determinedDate={determinedDate}
        candidateDate={candidateDate}
        answeredCandidateDate={answeredCandidateDate}
        employeeActiveLog={employeeActiveLog}
        recruitmentStatusMap={recruitmentStatusMap}
        statusForDisplayEventTable={statusForDisplayEventTable}
        candidateDateList={candidateDateList}
        selectedCandidateDateIds={selectedCandidateDateIds}
      />
    </BodyCellWrapper>
  );
};

export const BodyCellWrapper: FC<{
  children: React.ReactNode;
  style: React.CSSProperties;
  isLastColumn: boolean;
}> = ({ children, style }) => {
  return <Box style={{ ...style }}>{children}</Box>;
};

type BodyCellCoreProps = {
  columnIndex: number;
  columnLength: number;
  onnEvent: OnnEvent;
  onnEventAnswer: OnnEventAnswerWithEmployee;
  determinedDate?: OnnEventDeterminedDate;
  candidateDate?: CandidateDateWithNumberOfParticipants;
  employeeActiveLog?: EmployeeActiveLog;
  recruitmentStatusMap: Map<string, RecruitmentStatus>;
  statusForDisplayEventTable: StatusForDisplayEventTable;
  answeredCandidateDate: CandidateDateWithNumberOfParticipants | undefined;
  selectedCandidateDateIds: string[];
  candidateDateList: CandidateDateWithNumberOfParticipants[];
};

const BodyCellCore: FC<BodyCellCoreProps> = ({
  columnIndex,
  columnLength,
  onnEvent,
  onnEventAnswer,
  determinedDate,
  employeeActiveLog,
  statusForDisplayEventTable,
  selectedCandidateDateIds,
  candidateDateList,
}) => {
  const employee = onnEventAnswer.employee;

  // NOTE: 「ユーザーアイコン」列のセル
  const UserIconWithLabelCellMemo = useMemo(
    () => (
      <BorderBox>
        <UserIconWithLabel
          newGraduate={employee as NewGraduate}
          key={onnEventAnswer.id}
          onnEvent={onnEvent}
          onnEventAnswer={onnEventAnswer}
        />
      </BorderBox>
    ),
    [employee, onnEvent, onnEventAnswer]
  );

  // NOTE: 「回答状況」列のセル
  const AnswerStatusCellMemo = useMemo(
    () => (
      <AnswerStatusCellWrapper
        onnEvent={onnEvent}
        onnEventAnswer={onnEventAnswer}
        statusForDisplayEventTable={statusForDisplayEventTable}
        lastReadAt={employeeActiveLog ? employeeActiveLog.createdAt : null}
      />
    ),
    [onnEvent, onnEventAnswer, statusForDisplayEventTable, employeeActiveLog]
  );

  // NOTE: 「参加できる日程がない」列のセル
  const AnswerIconForUnAvailableCellMemo = useMemo(
    () => (
      <BorderBox $isLast={true}>
        <AnswerIconForUnAvailable
          key={`${onnEventAnswer.id}-unavailable`}
          onnEventAnswerWithEmployee={onnEventAnswer}
          statusForDisplayEventTable={statusForDisplayEventTable}
        />
      </BorderBox>
    ),
    [onnEventAnswer, statusForDisplayEventTable]
  );

  // NOTE: 「回答結果」列のセル
  const AnswerCellRenderer = useCallback(
    (candidateDate: CandidateDateWithNumberOfParticipants | undefined) => {
      if (!candidateDate) {
        captureException({
          error: new Error("candidateDate が想定しない undefined になっています"),
          tags: { type: "BodyCellCore" },
        });
        return null;
      }

      if (determinedDate) {
        // 日程が確定している場合
        return (
          <BorderBox>
            <AnswerIconWhenExistDeterminedDate
              key={determinedDate.id + candidateDate.id}
              determinedDate={determinedDate}
              candidateDate={candidateDate}
              statusForDisplayEventTable={statusForDisplayEventTable}
            />
          </BorderBox>
        );
      } else {
        // 日程が確定していない場合
        return (
          <BorderBox>
            <AnswerIconWhenNotExistDeterminedDate
              key={onnEventAnswer.id + candidateDate.id}
              candidateDate={candidateDate}
              onnEventAnswerWithEmployee={onnEventAnswer}
              statusForDisplayEventTable={statusForDisplayEventTable}
            />
          </BorderBox>
        );
      }
    },
    [determinedDate, onnEventAnswer, statusForDisplayEventTable]
  );

  // NOTE: ２列目以降は、開催日時フィルターに応じて動的に表示項目が変わる
  const columnMap = useMemo(() => {
    const _columnMap: { [index: number]: React.JSX.Element | null } = {
      0: UserIconWithLabelCellMemo,
      1: AnswerStatusCellMemo,
    };
    if (selectedCandidateDateIds.length === 0) {
      // NOTE: 開催日時フィルターが選択されていない場合は、全ての日程と「参加できる日程がない」を表示する
      candidateDateList.forEach((candidateDate, index) => {
        _columnMap[index + 2] = AnswerCellRenderer(candidateDate);
      });
      _columnMap[columnLength - 1] = AnswerIconForUnAvailableCellMemo;
    } else {
      // NOTE: 開催日時フィルターが選択されている場合は、選択された日程のみ表示する
      const displayedCandidateDates = candidateDateList.filter((candidateDate) => {
        return selectedCandidateDateIds.includes(candidateDate.id);
      });
      displayedCandidateDates.forEach((candidateDate, index) => {
        _columnMap[index + 2] = AnswerCellRenderer(candidateDate);
      });
      if (selectedCandidateDateIds.includes(CAN_NOT_JOIN)) {
        _columnMap[columnLength - 1] = AnswerIconForUnAvailableCellMemo;
      }
    }

    return _columnMap;
  }, [
    AnswerCellRenderer,
    AnswerIconForUnAvailableCellMemo,
    AnswerStatusCellMemo,
    UserIconWithLabelCellMemo,
    candidateDateList,
    columnLength,
    selectedCandidateDateIds,
  ]);

  return columnMap[columnIndex];
};

const BorderBox = styled(Box)<{ $isLast?: boolean }>`
  height: 100%;
  width: 100%;
  border: solid ${(props) => props.theme.palette.grey[100]};
  border-width: 1px ${(props) => (props.$isLast ? 0 : 1)}px 0px 0px;
  display: flex;
  align-items: center;
`;
