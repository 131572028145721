import React, { FC, useEffect } from "react";
import { Navigate, useParams } from "react-router-dom";

import { RegistrationIndividual } from "./RegistrationIndividual";

import { Loading } from "~/components/uiParts";

import { useUpdateUnregisteredEmployeeInvitedAt } from "~/hooks/employee";
import { useRegistrationInfoByInvitationToken } from "~/hooks/employee/useRegistrationInfoByInvitationToken";
import { useRedirectAfterRegistration } from "~/hooks/portal/redirect";
import { useSnackbar } from "~/hooks/shared";

import { getTenantIdFromDomain } from "~/libs/getTenantIdFromDomain";
import { NotFound } from "~/pages/NotFound";
import { captureException } from "~/util/loggerUtil";

export const Wrapper: FC<{
  children: typeof RegistrationIndividual;
}> = ({ children: Children }) => {
  const { id: invitationToken } = useParams<"id">();

  const { updateUnregisteredEmployeeInvitedAt } = useUpdateUnregisteredEmployeeInvitedAt();

  const {
    data: registrationInfoByInvitationToken,
    isLoading: isLoadingRegistrationInfoByInvitationToken,
  } = useRegistrationInfoByInvitationToken(invitationToken || "");

  const { enqueueSnackbar } = useSnackbar();
  const { redirectAfterRegistration } = useRedirectAfterRegistration();
  useEffect(() => {
    const employee = registrationInfoByInvitationToken?.employee;
    if (employee && employee.isRegistered()) {
      const shouldNavigateToLineQR = !employee.isFollowedLineOfficialAccount;
      if (!shouldNavigateToLineQR) {
        enqueueSnackbar("すでにアカウントが登録されています", { variant: "info" });
      }

      redirectAfterRegistration(employee);
    }
    if (employee && !employee.invitedAt) {
      invitationToken && updateUnregisteredEmployeeInvitedAt(invitationToken);
    }
  }, [
    enqueueSnackbar,
    invitationToken,
    redirectAfterRegistration,
    registrationInfoByInvitationToken,
    updateUnregisteredEmployeeInvitedAt,
  ]);

  const tenantId = getTenantIdFromDomain();
  if (tenantId || registrationInfoByInvitationToken?.employee.assignedAsNewcomer) {
    // 候補者や候補者向けのドメインだった場合には、候補者用のアカウント登録ページにリダイレクトする
    return <Navigate to={`/portal/accounts/invitations/${invitationToken}`} />;
  }

  const isLoading = isLoadingRegistrationInfoByInvitationToken;
  if (isLoading) {
    return <Loading size="large" fullHeight />;
  }

  if (!invitationToken || !registrationInfoByInvitationToken) {
    captureException({
      error: new Error("アカウント登録ページの情報が取得できず、NotFoundが開かれました"),
      tags: { type: "Wrapper" },
      extras: {
        invitationToken,
        registrationInfoByInvitationToken,
      },
    });

    return <NotFound />;
  }

  return <Children registrationInfoByInvitationToken={registrationInfoByInvitationToken} />;
};
