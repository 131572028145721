import { Box } from "@material-ui/core";
import { OnnEvent, OnnEventDeterminedDate } from "@onn/common";
import { format } from "date-fns";
import { ja } from "date-fns/locale";
import React, { useCallback, FC } from "react";
import styled from "styled-components";

import { Typography } from "~/components/uiParts";
import { useModal } from "~/hooks/modal";

export const DateCell: FC<{ onnEvent: OnnEvent; determinedDate?: OnnEventDeterminedDate }> = ({
  onnEvent,
  determinedDate,
}) => {
  const { handleModal } = useModal();
  const candidateDate = determinedDate?.candidateDateId
    ? onnEvent.getCandidateDatesById(determinedDate.candidateDateId)
    : undefined;

  const handleClickText = useCallback(() => {
    handleModal({
      name: "answerInterviewEventOnBehalfModal",
      args: {
        mode:
          candidateDate && determinedDate
            ? {
                type: "edit",
                onnEventDeterminedDate: determinedDate,
                candidateDate,
              }
            : { type: "create" },
        onnEvent,
      },
    });
  }, [candidateDate, determinedDate, handleModal, onnEvent]);

  return (
    <CellWrapper p={"1px"}>
      <Typography
        variant="body2"
        color="textSecondary"
        onClick={handleClickText}
        style={{ cursor: "pointer" }}
      >
        {candidateDate
          ? `${format(candidateDate.from, "MM/dd(E) H:mm", { locale: ja })}〜${format(
              candidateDate.until,
              "H:mm"
            )}`
          : "-"}
      </Typography>
    </CellWrapper>
  );
};

const CellWrapper = styled(Box)<{ bgcolor?: string }>`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.bgcolor};
  border: solid ${(props) => props.theme.palette.grey[100]};
  border-width: 1px 1px 0px 0px;
`;
