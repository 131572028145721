import { OnnEventDeterminedDate } from "@onn/common";
import { useMemo, useState } from "react";

import { OnnEventAnswerWithEmployee } from "~/hooks/onnEvent";

/**
 * 担当者による絞り込みと結果
 */
export const useFilterByAssignees = (
  onnEventAnswersWithEmployee: OnnEventAnswerWithEmployee[],
  employeeIdToDeterminateDate: Map<string, OnnEventDeterminedDate>
) => {
  const [selectedAssigneeIds, setSelectedAssigneeIds] = useState<string[]>([]);

  const answersFilteredByMentors = useMemo(() => {
    if (!selectedAssigneeIds.length) return onnEventAnswersWithEmployee;
    return onnEventAnswersWithEmployee.filter((v) => {
      const determinateDate = employeeIdToDeterminateDate.get(v.employee.id);

      if (!determinateDate?.assigneeId) return false;
      return selectedAssigneeIds.includes(determinateDate.assigneeId);
    });
  }, [employeeIdToDeterminateDate, onnEventAnswersWithEmployee, selectedAssigneeIds]);

  return {
    answersFilteredByMentors,
    selectedAssigneeIds,
    setSelectedAssigneeIds,
  };
};
