import { zodResolver } from "@hookform/resolvers/zod";
import {
  candidateDateSchema,
  onnEventDeterminedDateSchema,
  OnnEvent,
  OnnEventDeterminedDate,
  CandidateDate,
  TenantSettings,
} from "@onn/common";

import { set } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import { useState } from "react";
import { useForm } from "react-hook-form";

import { z } from "zod";

import { answerInterviewEventOnBehalfFormSchema } from "./answerInterviewEventOnBehalfFormSchema";

import { useCurrentUser } from "~/hooks/employee";
import { mutateOnnEventAnswers } from "~/hooks/onnEvent/answerResult/useOnnEventAnswers";
import { mutateOnnEventAnswersWithEmployee } from "~/hooks/onnEvent/answerResult/useOnnEventAnswersWithEmployee";
import { mutateDeterminedDate } from "~/hooks/onnEvent/determinedDate/useDeterminedDate";

import { useAnswerEventOnBehalfOfNewGraduate } from "~/hooks/onnEvent/useAnswerEventOnBehalfOfNewGraduate";
import { mutateCandidateDatesWithNumberOfParticipants } from "~/hooks/onnEvent/useCandidateDatesWithNumberOfParticipants";
import { mutateOnnEvent } from "~/hooks/onnEvent/useOnnEvent";
import { useReAnswerEventOnBehalfOfNewGraduate } from "~/hooks/onnEvent/useReAnswerEventOnBehalfOfNewGraduate";

type InputState = Pick<
  z.infer<typeof onnEventDeterminedDateSchema>,
  "employeeId" | "assigneeId"
> & { candidateDate: Pick<z.infer<typeof candidateDateSchema>, "from" | "until"> } & {
  date: Date;
} & {
  eventFormat: {
    type: "online" | "offline";
    descriptionForOnline: string;
    urlForOnline?: string;
    descriptionForOffline: string;
  };
};

export const useAnswerInterviewEventOnBehalfForm = ({
  onnEvent,
  current,
  onSubmit,
  additionalMutate,
  selectedEmployeeId,
  eventFormatTemplates,
}: {
  current?: {
    onnEventDeterminedDate: OnnEventDeterminedDate;
    candidateDate: CandidateDate;
  };
  onnEvent: OnnEvent;
  onSubmit: () => void;
  selectedEmployeeId?: string;
  additionalMutate?: () => void;
  eventFormatTemplates: TenantSettings["eventFormatTemplates"];
}) => {
  const { execAnswerEventOnBehalfOfNewGraduate } = useAnswerEventOnBehalfOfNewGraduate();
  const { execReAnswerEventOnBehalfOfNewGraduate } = useReAnswerEventOnBehalfOfNewGraduate();
  const [isLoading, setIsLoading] = useState(false);
  const { currentUser } = useCurrentUser();

  const form = useForm<InputState>({
    defaultValues: current
      ? {
          employeeId: selectedEmployeeId || current.onnEventDeterminedDate.employeeId,
          assigneeId: current.onnEventDeterminedDate.assigneeId,
          eventFormat: {
            type: current.onnEventDeterminedDate.eventFormat?.type,
            descriptionForOnline:
              current.onnEventDeterminedDate.eventFormat?.type === "online"
                ? current.onnEventDeterminedDate.eventFormat?.description
                : undefined,
            urlForOnline:
              current.onnEventDeterminedDate.eventFormat?.type === "online"
                ? current.onnEventDeterminedDate.eventFormat?.url
                : undefined,
            descriptionForOffline:
              current.onnEventDeterminedDate.eventFormat?.type === "offline"
                ? current.onnEventDeterminedDate.eventFormat?.description
                : undefined,
          },
          date: current.candidateDate.from,
          candidateDate: {
            from: current.candidateDate.from,
            until: current.candidateDate.until,
          },
        }
      : {
          employeeId: selectedEmployeeId,
          assigneeId: undefined,
          eventFormat: {
            descriptionForOnline: eventFormatTemplates?.online.description,
            urlForOnline: eventFormatTemplates?.online.url,
            descriptionForOffline: eventFormatTemplates?.offline.description,
          },
          date: new Date(),
          candidateDate: {
            from: set(utcToZonedTime(new Date(), "Asia/Tokyo"), {
              hours: 10,
              minutes: 0,
              seconds: 0,
              milliseconds: 0,
            }),
            until: set(utcToZonedTime(new Date(), "Asia/Tokyo"), {
              hours: 11,
              minutes: 0,
              seconds: 0,
              milliseconds: 0,
            }),
          },
        },
    mode: "onChange",
    resolver: zodResolver(answerInterviewEventOnBehalfFormSchema),
  });

  return {
    ...form,
    isLoading,
    handleSubmit: form.handleSubmit(async (inputValue: InputState) => {
      setIsLoading(true);

      const saveJob = current
        ? execReAnswerEventOnBehalfOfNewGraduate({
            onnEventDeterminedDateId: current.onnEventDeterminedDate.id,
            assigneeId: form.formState.dirtyFields.assigneeId ? inputValue.assigneeId : undefined,
            eventFormat: form.formState.dirtyFields.eventFormat
              ? {
                  type: inputValue.eventFormat.type,
                  description:
                    inputValue.eventFormat.type === "online"
                      ? inputValue.eventFormat.descriptionForOnline
                      : inputValue.eventFormat.descriptionForOffline,
                  url:
                    inputValue.eventFormat.type === "online"
                      ? inputValue.eventFormat.urlForOnline
                      : undefined,
                }
              : undefined,
            candidateDate:
              // NOTE: dateを更新するときはcandidateDateが更新されるのでdateが変更されたかも見ている
              form.formState.dirtyFields.date || form.formState.dirtyFields.candidateDate
                ? inputValue.candidateDate
                : undefined,
          })
        : execAnswerEventOnBehalfOfNewGraduate({
            onnEventId: onnEvent.id,
            employeeId: inputValue.employeeId,
            assigneeId: inputValue.assigneeId ?? undefined,
            eventFormat: {
              type: inputValue.eventFormat.type,
              description:
                inputValue.eventFormat.type === "online"
                  ? inputValue.eventFormat.descriptionForOnline
                  : inputValue.eventFormat.descriptionForOffline,
              url:
                inputValue.eventFormat.type === "online"
                  ? inputValue.eventFormat.urlForOnline
                  : undefined,
            },
            candidateDate: inputValue.candidateDate,
          });

      await saveJob
        .then(() => {
          onSubmit();
          additionalMutate && additionalMutate();
          mutateDeterminedDate(onnEvent.id);
          mutateOnnEvent(onnEvent.tenantId, onnEvent.id);
          mutateOnnEventAnswers(onnEvent.id);
          mutateOnnEventAnswersWithEmployee(onnEvent.id);
          mutateCandidateDatesWithNumberOfParticipants(currentUser.id, onnEvent.id);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }),
  };
};
