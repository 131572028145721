import { format } from "date-fns";
import { ja } from "date-fns/locale";
import React, { FC } from "react";

import { mutate } from "swr";

import { BodyRowData, widthPxMap } from "../const";

import { CellItem, StyleProps } from "./CellItem";

import { Button, Icon, Typography } from "~/components/uiParts";
import { useCurrentUser } from "~/hooks/employee";
import { useModal } from "~/hooks/modal";
import { generateUseRecruitmentProcessRecordsByEmployeeKey } from "~/hooks/recruitmentProcess/useRecruitmentProcessRecordsByEmployee";

export const ScheduleCell: FC<BodyRowData["schedule"] & StyleProps> = ({
  onnEventInfo,
  isEvent,
  shouldPromptForScheduleSetting,
  newGraduateId,
  ...styleProps
}) => {
  const { handleModal } = useModal();
  const { currentUser } = useCurrentUser();

  if (!isEvent || !onnEventInfo) {
    return (
      <CellItem {...styleProps}>
        <Icon color="lightGrey" size="xl" icon="horizontalBar" />
      </CellItem>
    );
  }

  const { determinedDate, candidateDate, onnEvent, slotDate } = onnEventInfo;

  const schedule = (() => {
    if (onnEvent.type === "new_interview" && determinedDate && slotDate)
      return { from: slotDate.from, until: slotDate.until };
    if (onnEvent.type === "interview" && determinedDate && candidateDate)
      return { from: candidateDate.from, until: candidateDate.until };
    if (onnEvent.type === "normal" && determinedDate && candidateDate)
      return { from: candidateDate.from, until: candidateDate.until };
    return undefined;
  })();

  // 面談が設定がされている場合は、面談の日程を表示
  if (schedule) {
    const { from, until } = schedule;
    return (
      <CellItem style={styleProps.style} isLastColumn={styleProps.isLastColumn}>
        <Typography variant="body2" color="textSecondary">
          {format(from, "MM/dd(E) H:mm", { locale: ja })}-{format(until, "H:mm")}
        </Typography>
      </CellItem>
    );
  }

  const openAnswerModal = () => {
    handleModal({
      name: "answerInterviewEventOnBehalfModal",
      args: {
        onnEvent,
        selectedEmployeeId: newGraduateId,
        mode: {
          type: "create",
        },
        additionalMutate: () =>
          mutate(generateUseRecruitmentProcessRecordsByEmployeeKey(newGraduateId)),
      },
    });
  };

  const openNewInterviewAnswerModal = () => {
    handleModal({
      name: "answerNewInterviewEventOnBehalfModal",
      args: {
        onnEventId: onnEvent.id,
        fixedOption: {
          selectedEmployeeId: newGraduateId,
        },
        slotDefaultValueSetting: onnEvent.slotDefaultValueSetting,
        mode: {
          type: "create",
        },
      },
    });
  };

  return (
    <CellItem
      style={{
        ...styleProps.style,
        width: shouldPromptForScheduleSetting
          ? widthPxMap["schedule"] + widthPxMap["assignee"] + widthPxMap["evaluation"] + "px"
          : styleProps.style.width,
      }}
      isLastColumn={styleProps.isLastColumn}
    >
      {shouldPromptForScheduleSetting ? (
        <Button
          color="primary"
          variant="text"
          borderRadius="regular"
          startIcon={<Icon icon="add" color="primary" size="sm" />}
          onClick={
            onnEvent.type === "new_interview" ? openNewInterviewAnswerModal : openAnswerModal
          }
          disabled={!currentUser.isAdmin()}
        >
          <Typography variant="caption" color="primary" bold>
            面談詳細を設定
          </Typography>
        </Button>
      ) : (
        <Icon color="lightGrey" size="xl" icon="horizontalBar" />
      )}
    </CellItem>
  );
};
