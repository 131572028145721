import { Box } from "@material-ui/core";
import React, { FC, useContext, useMemo } from "react";

import { ContactContextV2 } from "~/components/providers";

import { Typography, PortalListItem } from "~/components/uiParts";
import { captureException } from "~/util";

export const ContactRoomsIndex: FC = () => {
  const { contactRoomUnreadCountList, contactRooms, isLoadingUnreadMessageCountList } =
    useContext(ContactContextV2);
  const contactRoomsWithUnreadCount = useMemo(
    () =>
      contactRooms.map((room) => {
        const unreadCountInfo = contactRoomUnreadCountList.find(
          (uc) => uc.contactRoomId === room.id
        );

        // NOTE: 未読情報が存在しない場合はエラーを通知する
        if (!unreadCountInfo && !isLoadingUnreadMessageCountList) {
          captureException({
            error: new Error("未読情報が存在していません"),
            tags: { type: "ContactRoomsIndex" },
            extras: {
              contactRoomId: room.id,
              unreadCountInfo,
            },
          });
        }
        const unreadCount = unreadCountInfo?.unreadCount ?? 0;
        return {
          ...room,
          unreadCount,
        };
      }),
    [contactRooms, contactRoomUnreadCountList, isLoadingUnreadMessageCountList]
  );

  const contents = useMemo(
    () => (
      <>
        {contactRoomsWithUnreadCount.map((room) => (
          <Box key={room.id} mt="24px">
            <PortalListItem
              title="会社とのメッセージ"
              to={`/portal/contact_rooms/${room.id}`}
              badgeLabel={0 < room.unreadCount ? room.unreadCount.toString() : undefined}
            />
          </Box>
        ))}
      </>
    ),
    [contactRoomsWithUnreadCount]
  );

  return (
    <Box pt="40px" px="24px" pb="40px">
      <Typography variant="h3" color="textPrimary">
        Contact
      </Typography>
      <Typography variant="caption" color="textPrimary">
        {/* TODO: 中途の方を文言変更(https://app.clickup.com/t/864emfdcf) */}
        聞きたいことや相談したいことがある方は、こちらから人事・受け入れ担当者へ問い合わせましょう。
      </Typography>
      <Box mt="24px"> {contents}</Box>
    </Box>
  );
};
