import { isPast, addDays, differenceInCalendarDays } from "date-fns";
import { v4 } from "uuid";

import { Employee } from "../../Employee";
import { BaseOnnTask, OnnTaskTypes } from "../BaseOnnTask";

import { IOnnFormTaskSchema, onnFormTaskSchema } from "./schema";

export type OnnFormTaskDeliverySettingsCompleted = OnnFormTask & {
  deadlineDate: Date;
  scheduledDate: Date;
};

export class OnnFormTask extends BaseOnnTask implements IOnnFormTaskSchema {
  static readonly validator = onnFormTaskSchema;
  type = OnnTaskTypes.FORM_TASK;

  constructor(init: ExcludeMethods<OnnFormTask>) {
    const parsedInit = OnnFormTask.validator.parse(init);

    super(parsedInit);
  }

  public static create(
    params: Optional<ExcludeMethods<OnnFormTask>, "id" | "createdAt" | "updatedAt" | "type">
  ) {
    return new OnnFormTask({
      ...params,
      id: params.id ?? v4(),
      type: OnnTaskTypes.FORM_TASK,
      createdAt: params.createdAt ?? new Date(),
      updatedAt: params.updatedAt ?? new Date(),
    });
  }

  // 回答後の編集ができるかどうか
  canEditAnswer(): boolean {
    if (!this.isAllowEditAnswer) return false;
    if (!this.scheduledDate || !this.deadlineDate) return false;

    if (!this.isExceededScheduledDate()) {
      return false;
    }

    return !isPast(this.deadlineDate);
  }

  // 新規の回答ができるかどうか
  canAnswer(): boolean {
    if (!this.scheduledDate || !this.deadlineDate) return false;

    if (!this.isExceededScheduledDate()) {
      return false;
    }

    if (!this.canAnswerAfterDeadline) {
      return !isPast(this.deadlineDate);
    }

    return !isPast(addDays(this.deadlineDate, 14));
  }

  public isEditable(currentUser: Employee): boolean {
    return currentUser.isAdmin() || this.assigneeId === currentUser.id;
  }

  public isDeletable(currentUser: Employee): boolean {
    return currentUser.isAdmin() || this.assigneeId === currentUser.id;
  }

  public isRemindable(currentUser: Employee): boolean {
    return currentUser.isAdmin() || this.assigneeId === currentUser.id;
  }

  public isExceededScheduledDate(): boolean {
    if (!this.scheduledDate) {
      return false;
    }

    return isPast(this.scheduledDate);
  }

  public isExceededDeadlineDate(): boolean {
    if (!this.deadlineDate) {
      return false;
    }
    return isPast(this.deadlineDate);
  }

  public isDeliverySettingsCompleted(): this is OnnFormTaskDeliverySettingsCompleted {
    return !!this.deadlineDate && !!this.scheduledDate;
  }

  /**
   * 期日の3日前から期日当日まで "期日が近い" 扱いとする
   */
  public isNearDeadline(): boolean {
    if (!this.deadlineDate) {
      return false;
    }

    const diff = differenceInCalendarDays(this.deadlineDate, new Date());
    return 0 <= diff && diff <= 3;
  }

  public updateDeliverySettings(update: {
    scheduledDate?: Date | null;
    deadlineDate?: Date | null;
    canAnswerAfterDeadline: boolean;
  }) {
    if (update.scheduledDate !== undefined) {
      this.scheduledDate = update.scheduledDate;
    }
    if (update.deadlineDate !== undefined) {
      this.deadlineDate = update.deadlineDate;
    }

    this.canAnswerAfterDeadline = update.canAnswerAfterDeadline;
    this.updatedAt = new Date();
  }

  public async update(update: {
    isAllowEditAnswer: boolean;
    title: string;
    description: string;
    filePaths: string[];
  }) {
    this.validate(update);

    this.isAllowEditAnswer = update.isAllowEditAnswer;
    this.title = update.title;
    this.description = update.description;
    this.filePaths = update.filePaths;
    this.updatedAt = new Date();
  }

  public validate(update: Partial<OnnFormTask>) {
    OnnFormTask.validator.parse({ ...this, ...update });
  }

  public clone(): OnnFormTask {
    return new OnnFormTask(this);
  }
}
