import { isEmpty } from "lodash";

import { OnnEventDataForPortal } from "~/hooks/onnEvent/useOnnEventForPortal";

export const getSelectableEventTypes = (eventData: OnnEventDataForPortal) => {
  const selectableOnlineEvents = eventData.onnEventSlotDateWithNumberOfParticipants.filter(
    (v) => v.onnEventSlotDate.isOnline() && v.canParticipate()
  );
  const selectableOfflineEvents = eventData.onnEventSlotDateWithNumberOfParticipants.filter(
    (v) => v.onnEventSlotDate.isOffline() && v.canParticipate()
  );

  const result: Array<"offline" | "online" | undefined> = [];
  if (!isEmpty(selectableOnlineEvents)) result.push("online");
  if (!isEmpty(selectableOfflineEvents)) result.push("offline");

  return result;
};
