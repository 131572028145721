import React, { ReactNode, FC } from "react";
import { Draggable } from "react-beautiful-dnd";
import styled from "styled-components";

type Props = {
  draggableId: string;
  index: number;
  isDragDisabled: boolean;
  children: ReactNode;
  shouldRoundCorner?: boolean;
};

export const DnDDraggable: FC<Props> = ({
  draggableId,
  index,
  isDragDisabled,
  children,
  shouldRoundCorner = true,
}) => {
  return (
    <Draggable draggableId={draggableId} index={index} isDragDisabled={isDragDisabled}>
      {(provided, snapshot) => (
        <StyledDnDEffect
          $isDragging={snapshot.isDragging}
          $shouldRoundCorner={shouldRoundCorner}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          {children}
        </StyledDnDEffect>
      )}
    </Draggable>
  );
};

const StyledDnDEffect = styled.div<{ $isDragging: boolean; $shouldRoundCorner: boolean }>`
  box-shadow: ${({ $isDragging }) => ($isDragging ? "0px 0px 10px #40404033" : "none")};
  background: #fff;
  border-radius: ${({ $shouldRoundCorner }) => ($shouldRoundCorner ? "8px" : "0px")};
`;
