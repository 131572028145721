import { OnnEventSlotDate } from "./OnnEventSlotDate";

export class OnnEventSlotDatesForDisplay extends OnnEventSlotDate {
  reservedCount: number;
  assigneeInfo?: {
    id: string;
    name: string;
    iconUrl?: string;
  };
  constructor(init: ExcludeMethods<OnnEventSlotDatesForDisplay>) {
    super(init);
    this.reservedCount = init.reservedCount;
    this.assigneeInfo = init.assigneeInfo;
  }

  public getStatusForDisplay(): "done" | "closed" | "draft" | "full" | "adjusting" {
    if (this.isDraft()) return "draft";
    if (this.isDone()) return "done";
    if (this.isClosed()) return "closed";
    if (this.isFull(this.reservedCount)) return "full";
    return "adjusting";
  }

  public getSlotTypeForDisplay(): SlotTypeForDisplay {
    return new SlotTypeForDisplay({
      type: this.eventType,
      url: this.url || undefined,
      location: this.eventAddressText || undefined,
    });
  }
}

export class SlotTypeForDisplay {
  type: "online" | "offline";
  url?: string;
  location?: string;
  constructor(init: ExcludeMethods<SlotTypeForDisplay>) {
    this.type = init.type;
    this.url = init.url;
    this.location = init.location;
  }

  isWarning(): boolean {
    if (this.type === "online") {
      return !this.url;
    }
    if (this.type === "offline") {
      return !this.location;
    }
    throw new Error("Invalid slot type");
  }

  hasUrl(): boolean {
    return !!this.url;
  }

  hasLocation(): boolean {
    return !!this.location;
  }

  getLabel(): string {
    return this.type === "offline" ? "オフライン・現地" : "オンライン";
  }

  isOnline(): boolean {
    return this.type === "online";
  }

  isOffline(): boolean {
    return this.type === "offline";
  }
}
