import { Box } from "@material-ui/core";
import { CandidateDate, Employee, OnnEvent, OnnEventDeterminedDate } from "@onn/common";
import React from "react";
import styled from "styled-components";

import { AnswerManageMenu } from "./AnswerManageMenu";

import { TooltipWhenTextTruncated, Typography, UserIcon } from "~/components/uiParts";

export const UserIconWithLabel = ({
  employee,
  onnEvent,
  onnEventDeterminedDate,
  candidateDate,
}: {
  employee: Employee;
  onnEvent: OnnEvent;
  onnEventDeterminedDate?: OnnEventDeterminedDate;
  candidateDate?: CandidateDate;
}) => {
  return (
    <Box display="flex" alignItems="center" justifyContent="space-between" width="100%" mr="8px">
      <Box display="flex" alignItems="center" gridGap={"16px"} ml={0}>
        <UserIcon
          username={employee.getName()}
          profileIconImageUrl={employee.profileIconImageUrl}
          size={"small"}
        />
        <Box display="grid">
          <TooltipWhenTextTruncated text={employee.getName()}>
            {(ref) => (
              <StyledTypographyName ref={ref} variant={"body1"} color="textPrimary">
                {employee.getName()}
              </StyledTypographyName>
            )}
          </TooltipWhenTextTruncated>
        </Box>
      </Box>
      {
        // インタビュータイプでは必ず候補日と決定日が存在するはずだが、型合わせのため存在しない場合は非表示にしている
        candidateDate && onnEventDeterminedDate && (
          <AnswerManageMenu
            onnEvent={onnEvent}
            candidateDate={candidateDate}
            onnEventDeterminedDate={onnEventDeterminedDate}
          />
        )
      }
    </Box>
  );
};

const StyledTypographyName = styled(Typography)`
  padding-right: 12px;
  &.MuiTypography-root {
    display: -webkit-box;
    overflow: hidden;
    word-break: break-all;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
  }
`;
