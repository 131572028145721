import { v4 } from "uuid";

import { Answer } from "../Answer";

import { IOnnFormTaskAnswerSchema, onnFormTaskAnswerSchema } from "./schema";

export class OnnFormTaskAnswer implements IOnnFormTaskAnswerSchema {
  static readonly validator = onnFormTaskAnswerSchema;
  id: string;
  formTaskId: string;
  employeeId: string;
  tenantId: string;
  // 入社者がはじめに既読にした時刻
  firstReadAt: Date | null;
  // 入社者が既読にした時刻
  lastReadAt: Date | null;
  // 入社者が回答した時刻
  lastAnsweredAt: Date | null;

  // 表示するのは最新のrevision,回答したタイミングでidが固定される
  answeredFormRevisionId: string | null;
  answers: Answer[];

  constructor(init: ExcludeMethods<OnnFormTaskAnswer>) {
    const parsedInit = OnnFormTaskAnswer.validator.parse(init);

    this.id = parsedInit.id;
    this.formTaskId = parsedInit.formTaskId;
    this.employeeId = parsedInit.employeeId;
    this.tenantId = parsedInit.tenantId;
    this.firstReadAt = parsedInit.firstReadAt;
    this.lastReadAt = parsedInit.lastReadAt;
    this.lastAnsweredAt = parsedInit.lastAnsweredAt;
    this.answeredFormRevisionId = parsedInit.answeredFormRevisionId;
    this.answers = parsedInit.answers;
  }

  public static create(params: Optional<ExcludeMethods<OnnFormTaskAnswer>, "id">) {
    return new OnnFormTaskAnswer({
      ...params,
      id: params.id ?? v4(),
    });
  }

  public async updateAnswer(update: { answeredFormRevisionId: string; answers: Answer[] }) {
    this.validate(update);

    this.answeredFormRevisionId = update.answeredFormRevisionId;
    this.answers = update.answers;
    this.lastAnsweredAt = new Date();
  }

  public async read() {
    if (!this.firstReadAt) this.firstReadAt = new Date();
    this.lastReadAt = new Date();
  }

  public validate(update: Partial<OnnFormTaskAnswer>) {
    OnnFormTaskAnswer.validator.parse({ ...this, ...update });
  }

  public clone(): OnnFormTaskAnswer {
    return new OnnFormTaskAnswer(this);
  }

  isAnswered(): boolean {
    return !!this.answeredFormRevisionId;
  }
}
