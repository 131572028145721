import { Box } from "@material-ui/core";
import { OnnEvent, OnnEventDeterminedDate } from "@onn/common";
import React, { FC, useCallback } from "react";
import styled from "styled-components";

import { Typography, UserIcon } from "~/components/uiParts";
import { useEmployee } from "~/hooks/employee";
import { useModal } from "~/hooks/modal";

export const AssigneeCell: FC<{
  onnEvent: OnnEvent;
  determinedDate?: OnnEventDeterminedDate;
}> = ({ onnEvent, determinedDate }) => {
  const { data: assignee } = useEmployee(determinedDate?.assigneeId || undefined);
  const { handleModal } = useModal();

  const candidateDate = determinedDate?.candidateDateId
    ? onnEvent.getCandidateDatesById(determinedDate.candidateDateId)
    : undefined;

  const handleClickIcon = useCallback(() => {
    handleModal({
      name: "answerInterviewEventOnBehalfModal",
      args: {
        mode:
          candidateDate && determinedDate
            ? {
                type: "edit",
                onnEventDeterminedDate: determinedDate,
                candidateDate,
              }
            : { type: "create" },
        onnEvent,
      },
    });
  }, [candidateDate, determinedDate, handleModal, onnEvent]);

  return (
    <CellWrapper p={"1px"}>
      <Typography variant="body2" color="textSecondary">
        <UserIcon
          username={assignee?.getName() || ""}
          profileIconImageUrl={assignee?.profileIconImageUrl}
          size={"small"}
          circular={true}
          onClick={handleClickIcon}
        />
      </Typography>
    </CellWrapper>
  );
};

const CellWrapper = styled(Box)<{ bgcolor?: string }>`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.bgcolor};
  border: solid ${(props) => props.theme.palette.grey[100]};
  border-width: 1px 1px 0px 0px;
`;
