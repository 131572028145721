import { Box, RadioGroup } from "@material-ui/core";
import React from "react";

import { useRecruitmentStatusList } from "~/components/providers/RecruitmentStatusProvider";

import { FormControlLabel, RadioButton } from "~/components/uiParts";

type Props = {
  selectedStatusId: string | undefined;
  onChange(statusId: string): void;
};
export const RecruitmentStatusSelector = ({ selectedStatusId, onChange }: Props): JSX.Element => {
  const { recruitmentStatuses } = useRecruitmentStatusList();
  return (
    <Box>
      <RadioGroup defaultValue={selectedStatusId} onChange={(e) => onChange(e.target.value)}>
        {recruitmentStatuses.map((status) => (
          <FormControlLabel
            key={status.id}
            control={<RadioButton color="primary" />}
            label={status.label}
            value={status.id}
          />
        ))}
      </RadioGroup>
    </Box>
  );
};
