import { RecruitmentStatus } from "@onn/common";
import React, {
  FC,
  createContext,
  ReactNode,
  useEffect,
  useCallback,
  useMemo,
  useContext,
} from "react";

import { useCurrentUser } from "~/hooks/employee";

import { useRecruitmentStatuses } from "~/hooks/recruitmentStatus";
import { captureException } from "~/util";

type recruitmentStatusId = string;
export const RecruitmentStatusesContext = createContext<{
  recruitmentStatuses: RecruitmentStatus[];
  recruitmentStatusMap: Map<recruitmentStatusId, RecruitmentStatus>;
  getByRecruitmentStatusId: (recruitmentStatusId: string) => RecruitmentStatus | undefined;
}>({
  recruitmentStatuses: [],
  recruitmentStatusMap: new Map(),
  getByRecruitmentStatusId: () => undefined,
});

export const RecruitmentStatusProvider: FC<{
  children: ReactNode;
}> = ({ children }) => {
  const { currentUser } = useCurrentUser();

  // ポータルの場合は、ステータス一覧を使用しないので即時レンダリングを行う
  if (currentUser.assignedAsNewcomer) {
    return <>{children}</>;
  }

  return <RecruitmentStatusProviderCore>{children}</RecruitmentStatusProviderCore>;
};

export const RecruitmentStatusProviderCore: FC<{
  children: ReactNode;
}> = ({ children }) => {
  const { data: recruitmentStatuses = [], isLoading } = useRecruitmentStatuses();
  useEffect(() => {
    if (!isLoading && recruitmentStatuses.length === 0) {
      captureException({
        error: new Error("選考ステータス一覧が取得できませんでした"),
        tags: { type: "RecruitmentStatusProvider" },
      });
    }
  }, [isLoading, recruitmentStatuses]);

  const getByRecruitmentStatusId = useCallback(
    (recruitmentStatusId: string) => {
      return recruitmentStatuses.find(
        (recruitmentStatus) => recruitmentStatus.id === recruitmentStatusId
      );
    },
    [recruitmentStatuses]
  );

  const recruitmentStatusMap = useMemo(() => {
    return new Map(
      recruitmentStatuses.map((recruitmentStatus) => [recruitmentStatus.id, recruitmentStatus])
    );
  }, [recruitmentStatuses]);

  if (isLoading) return null;

  return (
    <RecruitmentStatusesContext.Provider
      value={{ recruitmentStatuses, recruitmentStatusMap, getByRecruitmentStatusId }}
    >
      {children}
    </RecruitmentStatusesContext.Provider>
  );
};

export const useRecruitmentStatusList = () => useContext(RecruitmentStatusesContext);
