import { Employee, EmployeeToDisplay } from "@onn/common";
import { isEmpty } from "lodash";
import React, { useCallback, useState } from "react";
import styled from "styled-components";

import { NewGraduateSelectionCore } from "../NewGraduateSelectionCore";

import { Button, Modal } from "~/components/uiParts";
import { useModalContentHeight } from "~/hooks/modal";

type Props = {
  tagId: string;
  open: boolean;
  onCancel: () => void;
  onSubmit: (emails: string[]) => Promise<void>;
  onClickCsvUpload: () => void;
};

const MODAL_PADDING_TOP = 40;
const MODAL_PADDING_BOTTOM = 24;
const MODAL_MARGIN = 32;

export const AssignTagToEmployeesModal = ({
  tagId,
  open,
  onCancel,
  onSubmit,
  onClickCsvUpload,
}: Props) => {
  const [loading, setLoading] = useState(false);
  const [selectedEmployees, setSelectedEmployees] = useState<Employee[]>([]);

  const handleSubmit = useCallback(async () => {
    setLoading(true);
    await onSubmit(selectedEmployees.map((employee) => employee.email))
      .then(() => {
        onCancel();
      })
      .finally(() => setLoading(false));
  }, [onCancel, onSubmit, selectedEmployees]);

  const filter = useCallback(
    (employee: EmployeeToDisplay) => !employee.employeeTagIds?.includes(tagId),
    [tagId]
  );

  const { contentHeight } = useModalContentHeight({
    headerHeight: 40,
    footerHeight: 50 + 24, // hooterの高さ+上方向の余白
    sumOfVerticalPadding: MODAL_PADDING_TOP + MODAL_PADDING_BOTTOM,
    sumOfVerticalMargin: MODAL_MARGIN * 2, // 上下方向分なので2倍している
  });

  return (
    <StyledModal
      open={open}
      onCancel={onCancel}
      fullWidth
      disableBackdropModal
      title="対象者追加"
      titleHint="選択した対象者にタグを一括で付与することができます。"
      content={
        <NewGraduateSelectionCore
          selectedEmployees={selectedEmployees}
          setSelectedEmployees={setSelectedEmployees}
          filter={filter}
          height={contentHeight}
          includeRejectedAndWithdrew={true}
          isAcrossSpace={true}
        />
      }
      footer={
        <FooterContainer>
          <Button
            variant="outlined"
            color="primary"
            borderRadius="regular"
            onClick={onClickCsvUpload}
          >
            CSVアップロード
          </Button>
          <ButtonContainer>
            <Button variant="text" borderRadius="circle" color="default" onClick={onCancel}>
              キャンセル
            </Button>
            <Button
              variant="contained"
              borderRadius="circle"
              color="primary"
              onClick={handleSubmit}
              disabled={isEmpty(selectedEmployees)}
              isLoading={loading}
            >
              タグを付与
            </Button>
          </ButtonContainer>
        </FooterContainer>
      }
    />
  );
};

const StyledModal = styled(Modal)`
  .MuiDialog-paper {
    height: 100%;
    padding-bottom: ${MODAL_PADDING_BOTTOM}px;
  }
  footer {
    margin-top: 24px;
  }
`;

const FooterContainer = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ButtonContainer = styled("div")`
  display: flex;
  align-items: center;
  gap: 8px;
`;
