import { useContext } from "react";

import { ContactContextV2 } from "~/components/providers";
import { NotificationCountContext } from "~/components/providers/NotificationCountProvider";

/**
 * コンタクトルームのコンテキストを取得するhooks
 */
export const useContactContext = () => {
  const contactContextV2 = useContext(ContactContextV2);
  const { expiredRemindersMutate } = useContext(NotificationCountContext);

  return {
    ...contactContextV2,
    contactRoomNotificationCountList: contactContextV2.contactRoomUnreadCountList,
    totalNotificationCount: contactContextV2.totalUnreadCount,
    expiredRemindersMutate,
  };
};
