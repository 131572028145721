import { Failure, Result, Success, removeUndefinedFromObject } from "@onn/common";

import { useCallback } from "react";

import { HEADER_LABELS } from "./DownloadSampleFileStepContent";
import { ParsedInputValuesType } from "./ParsedInputValuesType";

/**
 * ダブルクォーテーションで囲まれたvalueを変換するための関数。
 * CSVを扱う環境によっては、値がダブルクォーテーションで囲まれてしまうため。
 * "test" -> test
 * @param input
 * @returns
 */
const removeQuotes = (input: string): string => {
  if (input.startsWith('"') && input.endsWith('"')) {
    return input.substring(1, input.length - 1);
  }
  return input;
};

export const useConvertCSVtoPlainObject = () => {
  const convertCSVtoPlainObject = useCallback(
    (result: string): Result<ParsedInputValuesType[], Error> => {
      const regex = new RegExp(/\r\n|\r|\n/);
      const tmp = result.trimEnd().split(regex); // TODO: 終端だけでなく全ての空行を無視して計算できるとよい
      const [headerRow, ...rowsForData] = tmp.map((row) =>
        row.split(",").map((v) => removeQuotes(v))
      );

      if (!headerRow || headerRow.length !== HEADER_LABELS.length) {
        return new Failure(
          new Error(`サンプルのCSVを参照し、正しいヘッダーの名前を入力してください`)
        );
      }

      const invalidLabels = headerRow.filter((label) => !HEADER_LABELS.includes(label));

      const inputValues = rowsForData.map((row) => {
        let count = 0;
        // NOTE: 更新しないために空欄の場合はundefinedに変換し取り除く
        return {
          uniqueId: row[count++] || undefined,
          employeeName: {
            lastName: row[count++] || undefined,
            firstName: row[count++] || undefined,
          },
          employeeInformationValue: Object.fromEntries(
            Object.entries(
              removeUndefinedFromObject({
                kanaName: removeUndefinedFromObject({
                  lastName: row[count++] || undefined,
                  firstName: row[count++] || undefined,
                }),
                gender: row[count++] || undefined,
                dateOfBirth: row[count++] || undefined,
                phoneNumber: row[count++] || undefined,
                graduationYearAndMonth: removeUndefinedFromObject({
                  graduationYear: row[count++] || undefined,
                  graduationMonth: row[count++] || undefined,
                }),
                affiliation: removeUndefinedFromObject({
                  schoolName: row[count++] || undefined,
                  faculty: row[count++] || undefined,
                }),
                address: removeUndefinedFromObject({
                  postalCode: row[count++] || undefined,
                  prefecture: row[count++] || undefined,
                  city: row[count++] || undefined,
                  addressLine1: row[count++] || undefined,
                  addressLine2: row[count++] || undefined,
                }),
                hometownAddress: removeUndefinedFromObject({
                  postalCode: row[count++] || undefined,
                  prefecture: row[count++] || undefined,
                  city: row[count++] || undefined,
                  addressLine1: row[count++] || undefined,
                  addressLine2: row[count++] || undefined,
                }),
                externalId: row[count++] || undefined,
              })
              // NOTE: ここで空のオブジェクトを除外する
            ).filter(
              ([, v]) =>
                typeof v !== "object" || (typeof v === "object" && Reflect.ownKeys(v).length !== 0)
            )
          ),
        };
      });

      if (invalidLabels.length > 0) {
        const typoLabels = invalidLabels
          .filter((label): label is string => label != null && label !== "")
          .map((label) => `「${label}」`);
        const typoLabelText = `${typoLabels.join("、")}という存在しないヘッダー`;
        const numberOfUndefinedLabels = invalidLabels.length - typoLabels.length;
        const numberOfUndefinedLabelsText = `${numberOfUndefinedLabels}件の空のヘッダー`;

        if (typoLabels.length > 0 && numberOfUndefinedLabels > 0) {
          return new Failure(
            new Error(
              `${typoLabelText}、及び${numberOfUndefinedLabelsText}があります。サンプルのCSVを参照し、正しいヘッダーの名前を入力してください`
            )
          );
        }

        if (typoLabels.length > 0) {
          return new Failure(
            new Error(
              `${typoLabelText}があります。サンプルのCSVを参照し、正しいヘッダーの名前を入力してください`
            )
          );
        }

        if (numberOfUndefinedLabels > 0) {
          return new Failure(
            new Error(
              `${numberOfUndefinedLabelsText}があります。サンプルのCSVを参照し、正しいヘッダーの名前を入力してください`
            )
          );
        }
      }

      return new Success(inputValues as ParsedInputValuesType[]);
    },
    []
  );
  return { convertCSVtoPlainObject };
};
