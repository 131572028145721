import { RecruitmentStatus } from "@onn/common";
import useSWR from "swr";

import { useCurrentUser } from "../employee";
import { useCurrentSpace } from "../space/useCurrentSpace";

import { apiClient } from "~/libs";

const generateKey = (tenantId: string, spaceId: string) => {
  return {
    name: `/get_recruitment_statuses`,
    tenantId,
    spaceId,
  } as const;
};

// ポータルで使用するとクラッシュするため気をつける
export const useRecruitmentStatuses = () => {
  const { currentUser } = useCurrentUser();
  const { currentSpace } = useCurrentSpace();

  return useSWR(generateKey(currentUser.tenantId, currentSpace.id), async ({ name }) => {
    const response = await apiClient.get(name);
    return response.data.map((d) => new RecruitmentStatus(d));
  });
};
