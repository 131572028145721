import { CandidateDate } from "../../domain/OnnEvent";

export const createIMockCandidateDate = (mock: Partial<CandidateDate> = {}): CandidateDate => {
  return new CandidateDate({
    id: mock.id || "onnEvent1",
    from: mock.from || new Date("2021-01-01T00:00:00"),
    until: mock.until || new Date("2021-01-07T00:00:00"),
    createdAt: mock.createdAt || new Date(2022, 2, 20),
    updatedAt: mock.updatedAt || new Date(2022, 2, 20),
    capacity: mock.capacity,
  });
};
