import { OnnEvent } from "@onn/common";
import { OnnEventAnswerForDisplay } from "@onn/common/domain/OnnEvent/OnnEventAnswer";
import React, { FC } from "react";

import { AssigneeCell } from "./cells/AssigneeCell";
import { DescriptionCell } from "./cells/DescriptionCell";
import { SlotTimeCell } from "./cells/SlotTimeCell";
import { SlotTypeCell } from "./cells/SlotTypeCell";
import { StatusCell } from "./cells/StatusCell";
import { UserIconWithLabel } from "./cells/UserIconWithLabel";
import { ColumnType } from "./utils";

import { CellItem, Icon, StyleProps } from "~/components/uiParts";

type Props = {
  onnEventAnswerForDisplay: OnnEventAnswerForDisplay;
  columnType: ColumnType;
  isShowRightBorder: boolean;
  onnEvent: OnnEvent;
} & StyleProps;

export const BodyCell: FC<Props> = ({
  onnEventAnswerForDisplay,
  columnType,
  onnEvent,
  ...styleProps
}) => {
  const { selectedOnnEventSlotDate, assigneeInfo } = onnEventAnswerForDisplay;

  if (columnType === "newGraduate") {
    return (
      <CellItem {...styleProps} isShowTopBorder>
        <UserIconWithLabel
          newGraduateToDisplay={onnEventAnswerForDisplay.newGraduateToDisplay}
          onnEvent={onnEvent}
          isAlreadyAnswered={!!onnEventAnswerForDisplay.selectedOnnEventSlotDate}
          onnEventAnswer={onnEventAnswerForDisplay}
        />
      </CellItem>
    );
  }
  if (columnType === "slotTime") {
    return (
      <SlotTimeCell
        selectedOnnEventSlotDate={selectedOnnEventSlotDate}
        onnEvent={onnEvent}
        newGraduateToDisplayId={onnEventAnswerForDisplay.newGraduateToDisplay.id}
        {...styleProps}
      />
    );
  }

  if (columnType === "assignee") {
    return (
      <AssigneeCell
        assigneeName={assigneeInfo?.name}
        profileIconImageUrl={assigneeInfo?.iconUrl}
        {...styleProps}
        isShowTopBorder
      />
    );
  }

  if (columnType === "status") {
    return (
      <StatusCell
        statusForDisplay={onnEventAnswerForDisplay.getStatusForDisplay()}
        {...styleProps}
        isShowTopBorder
      />
    );
  }

  // TODO: 実施後の面談で評価を記入できる https://app.clickup.com/t/86enrmfxw

  if (columnType === "eventType" && !!selectedOnnEventSlotDate) {
    return (
      <SlotTypeCell onnEventSlotDate={selectedOnnEventSlotDate} {...styleProps} isShowTopBorder />
    );
  }

  if (columnType === "description" && !!selectedOnnEventSlotDate) {
    return <DescriptionCell description={selectedOnnEventSlotDate?.description} {...styleProps} />;
  }

  return (
    <CellItem {...styleProps} isShowTopBorder>
      <Icon color="lightGrey" size="xl" icon="horizontalBar" />
    </CellItem>
  );
};
