import { z } from "zod";

import { candidateDateSchema } from "../CandidateDate";

import { onnEventTypes } from "./types";

export const onnEventSchema = z.object({
  id: z.string().trim(),
  tenantId: z.string().trim(),
  spaceId: z.string().trim(),
  title: z
    .string()
    .trim()
    .min(1, { message: "タイトルが短すぎます" })
    .max(50, { message: "タイトルが長すぎます" }),
  content: z
    .string()
    .trim()
    .min(1, { message: "説明が短すぎます" })
    .max(1000, { message: "説明が長すぎます" }),
  deadlineDate: z.date().optional(), // 回答締め切り日
  capacity: z.number().min(1, { message: "最低1名以上に設定してください" }).optional(), // 1回のイベントに参加できる最大人数
  candidateDates: z.array(candidateDateSchema), // 候補日程リスト
  assigneeIds: z.array(z.string()),
  type: z.enum(onnEventTypes),
  createdAt: z.date(),
  updatedAt: z.date(),
  scheduledDate: z.date().optional(),
  firstDeliveredAt: z.date().optional(),
  canAnswerAfterDeadline: z.boolean().optional(),
  shouldRequestAttendance: z.boolean().optional(),
  registrationInvitationLinkId: z.string().optional(),
  isRestrictedAnswerFromNewGraduate: z.boolean().optional(),
  isRestrictedEditAnswerFromNewGraduate: z.boolean().optional(),
  isRestrictedDaysAgoWhenAnswer: z.boolean().optional(),
  daysAgoRestrictAnswer: z
    .number()
    .int()
    .min(1, { message: "1以上の整数を設定してください" })
    .optional(),
  slotDefaultValueSetting: z
    .object({
      slotType: z.enum(["online", "offline"]).optional(),
      online: z
        .object({
          description: z.string().optional(),
          url: z.string().optional(),
        })
        .optional(),
      offline: z
        .object({
          description: z.string().optional(),
        })
        .optional(),
    })
    .optional(),
});

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IOnnEvent extends z.infer<typeof onnEventSchema> {}
