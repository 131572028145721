import { z } from "zod";

const statues = ["ACTIVE", "IN_ACTIVE"] as const;
export type Status = (typeof statues)[number];

const ruleTypes = ["CONTACT_MESSAGE"] as const;
export type RuleType = (typeof ruleTypes)[number];

export const TEXT_MAX_LENGTH = 5000;

export const autoContactMessageSettingSchema = z.object({
  id: z.string(),
  tenantId: z.string(),
  spaceId: z.string(),
  name: z.string(),
  status: z.enum(statues),
  rule: z.object({
    type: z.enum(ruleTypes),
    text: z
      .string()
      .max(TEXT_MAX_LENGTH, { message: `本文は${TEXT_MAX_LENGTH}文字以内にしてください` })
      .min(1, { message: "本文は1文字以上にしてください" }),
    senderEmployeeId: z.string().optional(),
  }),
  createdAt: z.date(),
  updatedAt: z.date(),
});

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IAutoContactMessageSetting
  extends z.infer<typeof autoContactMessageSettingSchema> {}
