import { useCallback } from "react";

import { apiClient } from "~/libs";
import { captureException } from "~/util";

export const useAnswerNewInterviewEvent = () => {
  const execAnswerNewInterviewEvent = useCallback(
    async ({
      onnEventId,
      onnEventSlotDateId,
    }: {
      onnEventId: string;
      onnEventSlotDateId: string;
    }): Promise<void> => {
      try {
        await apiClient.post("/answer_new_interview_event", {
          onnEventId,
          onnEventSlotDateId,
        });
      } catch (e) {
        captureException({
          error: e as Error,
          tags: {
            type: "useAnswerNewInterviewEvent:execAnswerNewInterviewEvent",
          },
        });
        throw e;
      }
    },
    []
  );

  return { execAnswerNewInterviewEvent };
};
