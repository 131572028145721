import { Box, AppBar } from "@material-ui/core";
import React, { FC } from "react";
import styled from "styled-components";

import { Button } from "~/components/uiParts";

import theme from "~/config/theme";

type Props = {
  isSaving: boolean;
  cancelButtonText?: string;
  submitButtonText: string;
  onClickCancel: () => void;
  onClickConfirmSave: () => void;
  isDisabledSaveButton: boolean;
};

export const ActionFooter: FC<Props> = ({
  // プレビューボタンや戻るボタンの表示に対応し、様々なページで利用できるようにする
  isSaving,
  cancelButtonText = "キャンセル",
  submitButtonText,
  onClickCancel,
  onClickConfirmSave,
  isDisabledSaveButton,
}) => {
  return (
    <StyledActionFooter
      style={{
        bottom: 0,
        top: "auto",
        backgroundColor: theme.palette.background.paper,
        height: "78px",
      }}
    >
      <StyledDynamicPaddingLeftBox
        display="flex"
        height="80px"
        alignItems="center"
        justifyContent="flex-end"
        mx="40px"
        my="16px"
      >
        <Box display="flex" height="46px">
          <Box
            display="flex"
            justifyContent={"center"}
            alignItems="center"
            height={"56px"}
            ml="24px"
          >
            <Box>
              <Button variant="text" borderRadius="regular" onClick={onClickCancel} color="default">
                {cancelButtonText}
              </Button>
            </Box>
          </Box>
          <Box ml="24px">
            <StyledSaveButton
              isLoading={isSaving}
              variant="contained"
              borderRadius="circle"
              onClick={onClickConfirmSave}
              color="primary"
              disabled={isDisabledSaveButton}
            >
              {submitButtonText}
            </StyledSaveButton>
          </Box>
        </Box>
      </StyledDynamicPaddingLeftBox>
    </StyledActionFooter>
  );
};

const StyledActionFooter = styled(AppBar)``;

const StyledSaveButton = styled(Button)`
  &.MuiButton-root > div {
    line-height: 12px;
  }
`;

const StyledDynamicPaddingLeftBox = styled(Box)`
  padding-left: ${(props) => {
    return props.theme.isOpenSidebar
      ? props.theme.constants.SIDEBAR_OPEN_WIDTH
      : props.theme.constants.SIDEBAR_CLOSED_WIDTH;
  }}px;
`;
