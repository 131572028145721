import { Box, Menu, MenuItem } from "@material-ui/core";
import { OnnTask } from "@onn/common";
import { format } from "date-fns";
import ja from "date-fns/locale/ja";
import React, { useState, FC, MouseEvent, memo, useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { useCurriedOnClickZipDL } from "../../../_share/hooks/useCurriedOnClickZipDL";

import { ContentWithLabel } from "./ContentWithLabel";

import { CsvDLMenuItem } from "~/components/domains/onnTasks/OnnTaskTable/TableRowItems/OnnTaskManageCell/CsvDLMenuItem";
import { ZipDLMenuItem } from "~/components/domains/onnTasks/OnnTaskTable/TableRowItems/OnnTaskManageCell/ZipDLMenuItem";
import {
  IconButton,
  Paper,
  Typography,
  Tooltip,
  UserIcon,
  TooltipWhenTextTruncated,
  Icon,
} from "~/components/uiParts";
import { useCurrentUser, useEmployee } from "~/hooks/employee";
import { useModal } from "~/hooks/modal";
import { useRemindOnnFormTaskAnswer } from "~/hooks/onnFormTaskAnswer";
import { useOnnFormTasksAnswersOnlyCountable } from "~/hooks/onnFormTaskAnswer/useOnnFormTasksAnswersOnlyCountable";
import { useFormRevisions } from "~/hooks/onnTask";
import { useDeleteOnnTask } from "~/hooks/onnTask/useDeleteOnnTask";

export type Props = {
  onnTask: OnnTask;
  isRounded?: boolean;
};

export const OnnTaskDetailSummaryPaper: FC<Props> = memo(function EventDetailSummary({
  isRounded = true,
  onnTask,
}) {
  const { currentUser } = useCurrentUser();
  const navigation = useNavigate();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const { data: onnFormTaskAnswers, isLoading: isLoadingOnnFormTaskAnswers } =
    useOnnFormTasksAnswersOnlyCountable({ onnTaskId: onnTask.id });

  const { handleModal } = useModal();
  const { execRemindOnnFormTaskAnswer } = useRemindOnnFormTaskAnswer();
  const { data: assignee } = useEmployee(onnTask.assigneeId);
  const { deleteOnnTask } = useDeleteOnnTask(onnTask.tenantId);
  const { data: formRevisionsData } = useFormRevisions({
    onnFormTaskId: onnTask.id,
  });

  const handleOpenMenu = useCallback((event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleCloseMenu = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleClickDeliverySetting = () => {
    handleCloseMenu();
    navigation(`/onn_tasks/${onnTask.id}/delivery_setting?from_page=detail`);
  };

  const handleClickEditOnnEvent = () => {
    handleCloseMenu();
    navigation(`/onn_tasks/${onnTask.id}/edit/?from_page=detail`);
  };

  const handleClickBulkRemindButton = () => {
    handleCloseMenu();
    handleModal({
      name: "onnTaskBulkRemindModal",
      args: {
        onSubmit: () => execRemindOnnFormTaskAnswer({ onnTaskId: onnTask.id }),
      },
    });
  };

  const handleClickDeleteButton = () => {
    handleCloseMenu();
    handleModal({
      name: "deleteOnnTaskModal",
      args: {
        taskName: onnTask.title,
        username: assignee?.getName() ?? "",
        profileIconImageUrl: assignee?.profileIconImageUrl ?? "",
        onSubmit: async () => {
          await deleteOnnTask({ id: onnTask.id });
          navigation(`/onn_tasks`);
        },
      },
    });
  };

  const isShowBulkRemindMenuItem = useMemo(
    () =>
      onnTask.isExceededScheduledDate() &&
      !onnTask.isExceededDeadlineDate() &&
      onnFormTaskAnswers &&
      onnFormTaskAnswers.length > 0,
    [onnFormTaskAnswers, onnTask]
  );

  const isShowCsvDLMenuItem = useMemo(
    () => onnTask.isExceededScheduledDate() && onnFormTaskAnswers && onnFormTaskAnswers.length > 0,
    [onnFormTaskAnswers, onnTask]
  );

  const isShowZipDLMenuItem = useMemo(
    () =>
      onnTask.isExceededScheduledDate() &&
      onnFormTaskAnswers &&
      onnFormTaskAnswers.some((taskAnswer) =>
        taskAnswer.answers.some((answer) => answer.type === "FILE" && answer.filePath)
      ),
    [onnTask, onnFormTaskAnswers]
  );
  const { curriedOnClickZipDL } = useCurriedOnClickZipDL();

  return (
    <StyledPaper square={!isRounded}>
      <Box display="flex" alignItems="center" justifyContent="space-between" height={68}>
        <Box display="flex" alignItems="center" gridGap="8px">
          <Icon icon="form" color="lightGrey" size="md" />
          <Box display="flex" flexDirection="column" gridRowGap="4px" maxWidth="350px">
            <TooltipWhenTextTruncated text={onnTask.title}>
              {(ref) => (
                <StyledTitle ref={ref} variant="body1">
                  {onnTask.title}
                </StyledTitle>
              )}
            </TooltipWhenTextTruncated>
            <Typography variant="caption" color="textSecondary">
              {onnTask.isDeliverySettingsCompleted()
                ? `回答期間：${format(onnTask.scheduledDate, "yyyy/MM/dd HH:mm", {
                    locale: ja,
                  })} 〜 ${format(onnTask.deadlineDate, "yyyy/MM/dd HH:mm", { locale: ja })}`
                : "回答期間：未設定"}
            </Typography>
          </Box>
        </Box>
        <Box display="flex" alignItems="center">
          <Box minWidth={50}>
            <AssigneeEmployee assigneeEmployeeId={onnTask.assigneeId} />
          </Box>
          <Box ml="40px" minWidth={50}>
            <NumberOfDistribution
              count={onnFormTaskAnswers?.length || 0}
              isLoading={isLoadingOnnFormTaskAnswers}
            />
          </Box>
          <Box ml="40px" minWidth={50}>
            <NumberOfResponses
              count={onnFormTaskAnswers?.filter((v) => v.isAnswered())?.length || 0}
              isLoading={isLoadingOnnFormTaskAnswers}
            />
          </Box>
          <Box ml="40px">
            {currentUser.isAdmin() && (
              <>
                <IconButton icon="menuVert" onClick={handleOpenMenu} />
                <StyledMenu
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleCloseMenu}
                  getContentAnchorEl={null}
                  anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                  transformOrigin={{ vertical: "top", horizontal: "right" }}
                >
                  <MenuItem onClick={handleClickEditOnnEvent}>編集</MenuItem>
                  <MenuItem onClick={handleClickDeliverySetting}>配信設定</MenuItem>
                  {isShowBulkRemindMenuItem && (
                    <MenuItem onClick={handleClickBulkRemindButton}>一括リマインド</MenuItem>
                  )}
                  {isShowCsvDLMenuItem && (
                    <CsvDLMenuItem
                      onnFormTaskAnswers={onnFormTaskAnswers || []}
                      taskTitle={onnTask.title}
                      formRevisions={formRevisionsData?.formRevisions || []}
                    />
                  )}
                  {isShowZipDLMenuItem && (
                    <ZipDLMenuItem
                      onClickZipDL={curriedOnClickZipDL({
                        onnFormTaskId: onnTask.id,
                        onnFormTaskTitle: onnTask.title,
                        handleCloseMenu,
                      })}
                    />
                  )}
                  <MenuItem onClick={handleClickDeleteButton}>削除</MenuItem>
                </StyledMenu>
              </>
            )}
          </Box>
        </Box>
      </Box>
    </StyledPaper>
  );
});

// 担当者
const AssigneeEmployee = ({ assigneeEmployeeId }: { assigneeEmployeeId?: string }) => {
  const { data: assigneeEmployee, isLoading } = useEmployee(assigneeEmployeeId);

  return (
    <ContentWithLabel label="担当者" isLoading={isLoading}>
      {assigneeEmployee ? (
        <StyledClickableBox display="flex" alignItems="center">
          <Box display="flex" alignItems="center" gridGap={"8px"}>
            <UserIcon
              username={assigneeEmployee.getName()}
              profileIconImageUrl={assigneeEmployee.profileIconImageUrl}
              size={"small"}
              circular={true}
            />

            <Box display="grid" gridRowGap="4px">
              <StyledTypography variant={"caption"} color="textPrimary" noWrap>
                {assigneeEmployee.getName()}
              </StyledTypography>
            </Box>
          </Box>
        </StyledClickableBox>
      ) : (
        <Tooltip arrow placement="bottom" title={"担当者を設定する"}>
          <StyledClickableBox display="flex" alignItems="center">
            <>
              <UserIcon circular size="small" username="未登録" badgeType="dot" />
              <Box display="inline-block" width="8px" />
            </>
            <Typography variant="caption" color="textSecondary" display="block">
              未登録
            </Typography>
          </StyledClickableBox>
        </Tooltip>
      )}
    </ContentWithLabel>
  );
};

// 配信数
const NumberOfDistribution = ({ count, isLoading }: { count: number; isLoading: boolean }) => {
  return (
    <ContentWithLabel label="配信数" isLoading={isLoading}>
      <Typography variant="h3">{count}</Typography>
    </ContentWithLabel>
  );
};

// 回答者数
const NumberOfResponses = ({ count, isLoading }: { count: number; isLoading: boolean }) => {
  return (
    <ContentWithLabel label="回答者数" isLoading={isLoading}>
      <Typography variant="h3">{count}</Typography>
    </ContentWithLabel>
  );
};

const StyledPaper = styled(Paper)`
  padding: 24px;
`;

const StyledMenu = styled(Menu)`
  margin-top: 8px;
`;

const StyledClickableBox = styled(Box)<{ $width?: number }>`
  /* TODO: 担当者変更実装時にコメントアウトを外す */
  /* cursor: pointer; */
  ${({ $width }) => ($width ? `width: ${$width}px` : "width: 100%")}
`;

const StyledTypography = styled(Typography)`
  &.MuiTypography-root {
    line-height: 1.1;
  }
`;

const StyledTitle = styled(Typography)`
  &.MuiTypography-root {
    display: -webkit-box;
    overflow: hidden;
    word-break: break-all;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
  }
`;
