import { useCallback } from "react";

import { useSnackbar } from "../shared";

import { apiClient } from "~/libs";
import { captureException } from "~/util";

export const useAttendOnnEvent = () => {
  const { enqueueSnackbar } = useSnackbar();
  const attendOnnEvent = useCallback(
    async ({
      onnEventId,
      candidateDateId,
    }: {
      onnEventId: string;
      candidateDateId: string;
    }): Promise<void> => {
      try {
        await apiClient.patch("/patch_events_attend", {
          onnEventId,
          candidateDateId,
        });
        enqueueSnackbar("参加登録しました", { variant: "success" });
      } catch (e) {
        const errorMessage = e instanceof Error && e.message ? e.message : "参加登録に失敗しました";
        enqueueSnackbar(errorMessage, { variant: "error" });
        captureException({
          error: e as Error,
          tags: {
            type: "useAttendOnnEvent:attendOnnEvent",
          },
        });
        throw e;
      }
    },
    [enqueueSnackbar]
  );

  return { attendOnnEvent };
};
