import { APISchema } from "@onn/common";

import { apiClient } from "~/libs";

type EndPoint = APISchema["/remove_new_interview_determined_date"]["POST"];

export const useRemoveNewInterviewDeterminedDate = () => {
  const removeNewInterviewDeterminedDate = async (args: EndPoint["body"]) => {
    const response = await apiClient.post("/remove_new_interview_determined_date", args, {
      isNotifyError: true,
      keepalive: true,
    });

    return response;
  };

  return { removeNewInterviewDeterminedDate };
};
