import { Box, Menu, MenuItem } from "@material-ui/core";

import { ContactMessageTemplate } from "@onn/common";
import { isEmpty } from "lodash";
import React, { FC, useState } from "react";
import styled from "styled-components";

import { TemplateChangeMenuItem } from "./TemplateChangeMenuItem";
import { TemplateMenuItem } from "./TemplateMenuItem";

import { Divider, Typography, Loading } from "~/components/uiParts";
import { useContactMessageTemplates } from "~/hooks/contactMessageTemplate/useContactMessageTemplates";
import { useCreateContactMessageTemplate } from "~/hooks/contactMessageTemplate/useCreateContactMessageTemplate";
import { useDeleteContactMessageTemplate } from "~/hooks/contactMessageTemplate/useDeleteContactMessageTemplate";
import { useUpdateContactMessageTemplate } from "~/hooks/contactMessageTemplate/useUpdateContactMessageTemplate";
import { useCurrentUser } from "~/hooks/employee";
import { useModal } from "~/hooks/modal";
import { useTenant } from "~/hooks/tenant/useTenant";

type Props = {
  menuStartAnchorEl: HTMLElement | null;
  closeMenu: () => void;
  reflectMessage: (v: string) => void;
};

export const ContactMessageTemplateMenu: FC<Props> = ({
  menuStartAnchorEl,
  closeMenu,
  reflectMessage,
}) => {
  const {
    tenant: { tenantId },
  } = useTenant();
  const [templateChangeMenu, setTemplateChangeMenu] = useState<HTMLElement | null>(null);
  const [selectedTemplate, setSelectedTemplate] = useState<ContactMessageTemplate | undefined>(
    undefined
  );
  const { handleModal } = useModal();
  const { currentUser } = useCurrentUser();

  const {
    data: contactMessageTemplates,
    isLoading,
    refetchKey,
  } = useContactMessageTemplates(tenantId);
  const { createContactMessageTemplate } = useCreateContactMessageTemplate({
    contactMessageTemplates: contactMessageTemplates || [],
    refetchKey,
  });
  const { updateContactMessageTemplate } = useUpdateContactMessageTemplate({
    contactMessageTemplates: contactMessageTemplates || [],
    refetchKey,
  });
  const { deleteContactMessageTemplate } = useDeleteContactMessageTemplate({
    contactMessageTemplates: contactMessageTemplates || [],
    refetchKey,
  });

  return (
    <>
      <StyledTemplatesMenu
        anchorEl={menuStartAnchorEl}
        open={Boolean(menuStartAnchorEl)}
        onClose={closeMenu}
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
        transformOrigin={{ vertical: "bottom", horizontal: "left" }}
        getContentAnchorEl={null}
      >
        <StyledTemplateMenuItemsBox>
          {isLoading ? (
            <Loading size="small" />
          ) : (
            <>
              {isEmpty(contactMessageTemplates) ? (
                <Box px="24px">
                  <Typography variant="body2" color="textSecondary">
                    テンプレートが存在しません
                  </Typography>
                </Box>
              ) : (
                <>
                  {contactMessageTemplates?.map((template) => (
                    <TemplateMenuItem
                      key={template.id}
                      contactMessageTemplate={template}
                      isAdmin={currentUser.isAdmin()}
                      reflectMessage={reflectMessage}
                      closeMenu={closeMenu}
                      openTemplateChangeMenu={(el) => {
                        setTemplateChangeMenu(el);
                        setSelectedTemplate(template);
                      }}
                    />
                  ))}
                </>
              )}
            </>
          )}
        </StyledTemplateMenuItemsBox>
        {currentUser.isAdmin() && [
          <StyledDivider key="divider" />,
          <StyledTemplateCreateMenuItem
            key="create"
            onClick={() =>
              handleModal({
                name: "editContactMessageTemplateModal",
                args: {
                  mode: "create",
                  onSubmit: async (newTemplate: ContactMessageTemplate) => {
                    createContactMessageTemplate(newTemplate);
                  },
                },
              })
            }
          >
            <Typography variant="body2">テンプレート新規作成</Typography>
          </StyledTemplateCreateMenuItem>,
        ]}
      </StyledTemplatesMenu>
      <StyledTemplateUpdateMenu
        anchorEl={templateChangeMenu}
        open={Boolean(templateChangeMenu)}
        onClose={() => setTemplateChangeMenu(null)}
        anchorOrigin={{ vertical: "center", horizontal: "right" }}
        transformOrigin={{ vertical: "center", horizontal: "left" }}
        getContentAnchorEl={null}
      >
        <TemplateChangeMenuItem
          label="編集"
          onClick={() => {
            handleModal({
              name: "editContactMessageTemplateModal",
              args: {
                mode: "edit",
                oldContactMessageTemplate: selectedTemplate,
                onSubmit: (newTemplate: ContactMessageTemplate) => {
                  updateContactMessageTemplate(newTemplate);
                },
              },
            });
          }}
        />
        <TemplateChangeMenuItem
          label="削除"
          onClick={() => {
            if (!selectedTemplate) throw new Error("template is not selected");

            handleModal({
              name: "deleteContactMessageTemplateModal",
              args: {
                contactMessageTemplate: selectedTemplate,
                onSubmit: (newTemplate: ContactMessageTemplate) => {
                  deleteContactMessageTemplate(newTemplate);
                },
              },
            });
          }}
        />
      </StyledTemplateUpdateMenu>
    </>
  );
};

const StyledTemplatesMenu = styled(Menu)`
  .MuiPaper-root {
    width: 244px;
  }

  .MuiList-padding {
    padding-top: 16px;
  }
`;

const StyledTemplateMenuItemsBox = styled(Box)`
  &.MuiBox-root {
    max-height: 242px; // スクロールできるような見た目にするため5.5行分の高さにしている (44 * 5.5)
    overflow-y: auto;
  }
`;

const StyledDivider = styled(Divider)`
  &.MuiDivider-root {
    margin: 10px 0 2px 0;
  }
`;

const StyledTemplateCreateMenuItem = styled(MenuItem)`
  &.MuiMenuItem-root {
    padding-top: 10px;
    padding-bottom: 10px;
  }
`;

const StyledTemplateUpdateMenu = styled(Menu)`
  .MuiList-padding {
    padding: 12px 0;
  }
`;
