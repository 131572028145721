import { Box, Menu, MenuItem } from "@material-ui/core";
import { Employee, EmployeeTag } from "@onn/common";
import { format } from "date-fns";
import React, { MouseEvent, useCallback, useState } from "react";

import { CSVLink } from "react-csv";
import styled from "styled-components";

import { useCsvData } from "./useCsvData";

import { SpaceFilter } from "~/components/domains/space/SpaceFilter";

import { Button, Icon, IconButton, SearchForm, Typography, UserIcon } from "~/components/uiParts";
import { TableRowWrapper } from "~/components/uiParts/VirtualizedTable";
import { TableRow } from "~/components/uiParts/VirtualizedTable/TableRow";
import { VirtualizedTableV2 } from "~/components/uiParts/VirtualizedTable/VirtualizedTableV2";
import { useCurrentUser } from "~/hooks/employee";
import { useCurrentSpace } from "~/hooks/space/useCurrentSpace";

const useTableMenu = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleClickCsvDL = useCallback(() => {
    // NOTE: CSVLinkを使ってるため、ここではロジックが必要ない
    setAnchorEl(null);
  }, []);

  const handleOpenMenu = useCallback((event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  }, []);

  const handleCloseMenu = useCallback(() => {
    setAnchorEl(null);
  }, []);

  return {
    anchorEl,
    handleClickCsvDL,
    handleOpenMenu,
    handleCloseMenu,
  };
};

type Props = {
  taggedEmployees: Employee[];
  AllTaggedEmployees: Employee[];
  employeeIdToTaggedAtMap: Record<string, string>;
  onOpenAssign: () => void;
  onClickRemoveTag: (employee: Employee) => void;
  onSearchValue: (value: string) => void;
  selectedSpaceIds: string[];
  setSelectedSpaceIds: (ids: string[]) => void;
  employeeTag: EmployeeTag;
};

export const TaggedEmployeesTable = ({
  taggedEmployees,
  AllTaggedEmployees,
  employeeIdToTaggedAtMap,
  onOpenAssign,
  onClickRemoveTag,
  onSearchValue,
  selectedSpaceIds,
  setSelectedSpaceIds,
  employeeTag,
}: Props): JSX.Element => {
  const { currentUser } = useCurrentUser();
  const { spaces, isShowSpaceOnScreen } = useCurrentSpace();
  const spaceMap = new Map(spaces.map((v) => [v.id, v]));

  const widthOptions = ["70%", "10%", "10%", "2.5%"];
  const tableMenu = useTableMenu();
  const { csvData, filename } = useCsvData({
    taggedEmployees: AllTaggedEmployees,
    // CSV出力対象にフィルターを適用していないことは、仕様である
    employeeTag,
    spaces,
    employeeIdToTaggedAtMap,
  });
  return (
    <>
      <Box display="flex" justifyContent="end" mb="24px">
        {isShowSpaceOnScreen(spaces) && (
          <Box display="flex" flexDirection="column" gridRowGap="8px" width="212px" mr="auto">
            <Typography variant="body2" bold color="textSecondary">
              年次
            </Typography>
            <SpaceFilter
              defaultLabel="全て"
              spaces={spaces}
              selectedSpaceIds={selectedSpaceIds}
              onChange={(ids: string[]) => setSelectedSpaceIds(ids)}
            />
          </Box>
        )}
        <Box display="flex" alignItems="center">
          <Box mr="16px" width="280px">
            <SearchForm
              onSearchValue={onSearchValue}
              placeholder="ユーザー名・メールアドレスで検索"
              variant="standard"
              fullWidth
            />
          </Box>
          {currentUser.isAdmin() && (
            <>
              <Box>
                <Button
                  color="primary"
                  variant="outlined"
                  borderRadius="regular"
                  onClick={onOpenAssign}
                >
                  <Icon size="sm" icon="add" color="primary" />
                  対象者を追加
                </Button>
              </Box>
              <Box>{/* TODO: 三点リーダー */}</Box>
            </>
          )}
          <Box ml="24px">
            <IconButton icon="menuVert" onClick={tableMenu.handleOpenMenu} />
            <StyledMenu
              anchorEl={tableMenu.anchorEl}
              keepMounted
              open={Boolean(tableMenu.anchorEl)}
              onClose={tableMenu.handleCloseMenu}
              getContentAnchorEl={null}
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              transformOrigin={{ vertical: "top", horizontal: "right" }}
            >
              <StyledCSVLink data={csvData} filename={filename}>
                <MenuItem onClick={tableMenu.handleClickCsvDL}>CSVダウンロード</MenuItem>
              </StyledCSVLink>
            </StyledMenu>
          </Box>
        </Box>
      </Box>
      <VirtualizedTableV2<Employee>
        hover
        headers={[
          {
            text: "ユーザー",
          },
          {
            text: "年次",
          },
          {
            text: "追加日",
          },
          {
            text: "",
          },
        ]}
        rowHeight={102}
        widthOptions={widthOptions}
        emptyText="結果が見つかりませんでした"
        rows={taggedEmployees}
        rowRenderer={({ key, index, style, rowData: employee }) => {
          const contents = [
            <Box key="user" display="flex" alignItems="center">
              <Box mr="16px">
                <UserIcon
                  username={employee.getName()}
                  size={"small"}
                  profileIconImageUrl={employee.profileIconImageUrl}
                  backgroundColor={employee.isRegistered() ? "primary" : "grey"}
                  hover={false}
                  touch={false}
                />
              </Box>
              <Box>
                <Typography display="block" variant="body1" color="textPrimary">
                  {employee.getName()}
                </Typography>
                <Typography display="block" variant="caption" color="textSecondary">
                  {employee.email}
                </Typography>
              </Box>
            </Box>,
            <Box key="space">
              <Typography variant="body2" color="textSecondary">
                {employee.spaceId && spaceMap.get(employee.spaceId)?.name}
              </Typography>
            </Box>,
            <Box key="taggedAt">
              <Typography variant="body2" color="textSecondary">
                {format(
                  new Date(
                    employeeIdToTaggedAtMap[employee.id] as (typeof employeeIdToTaggedAtMap)[string]
                  ),
                  "yyyy/M/d"
                )}
              </Typography>
            </Box>,
            <Box key="options">
              {currentUser.isAdmin() && (
                <ContextMenu
                  employee={employee}
                  onClickDelete={() => {
                    onClickRemoveTag(employee);
                  }}
                />
              )}
            </Box>,
          ];
          return (
            <TableRowWrapper key={key} index={index} {...style}>
              <TableRow
                row={{
                  contents,
                  to: `/employee/${employee.id}`,
                  isTargetBlank: true,
                }}
                widthOptions={widthOptions}
                hover={true}
              />
            </TableRowWrapper>
          );
        }}
      />
    </>
  );
};

const ContextMenu = ({
  employee,
  onClickDelete,
}: {
  employee: Employee;
  onClickDelete: () => void;
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleClickButton = useCallback((event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClickDelete = useCallback(() => {
    onClickDelete();
    setAnchorEl(null);
  }, [onClickDelete]);

  return (
    <>
      <IconButton onClick={handleClickButton} icon="menuVert" />
      <Menu
        id={employee.id}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem key={`${employee.id}-edit`} onClick={handleClickDelete}>
          <Typography variant="body2">削除</Typography>
        </MenuItem>
      </Menu>
    </>
  );
};

const StyledMenu = styled(Menu)`
  margin-top: 8px;
`;

const StyledCSVLink = styled(CSVLink)`
  text-decoration: none;
`;
