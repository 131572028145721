import { OnnFormTaskAnswer } from "@onn/common";
import React from "react";

import { CountCell } from "../../components/AnswerResultTab/table/CountCell";

import { HeaderCell } from "../../components/AnswerResultTab/table/HeaderCell";

import { SortedRevisionQuestionDataList } from "./useGenerateSortedRevisionQuestionDataList";

// 回答ステータステーブルのヘッダーを生成する
export const useGenerateHeaderRow = () => {
  const generateHeaderRow = ({
    sortedRevisionQuestionDataList,
    onnFormTaskAnswers,
  }: {
    sortedRevisionQuestionDataList: SortedRevisionQuestionDataList;
    onnFormTaskAnswers: OnnFormTaskAnswer[];
  }): JSX.Element[] => {
    const { answeredCount, notAnsweredCount } = getAnsweredCounts(onnFormTaskAnswers);

    const headerRow = [
      <CountCell
        key="count-header-cell"
        answeredCount={answeredCount}
        notAnsweredCount={notAnsweredCount}
      />,
      <HeaderCell key="answer-status-header-cell" title="回答状況" />,
    ];

    sortedRevisionQuestionDataList.forEach((revisionQuestionData, index) => {
      headerRow.push(
        <HeaderCell
          key={revisionQuestionData.question.title}
          title={revisionQuestionData.question.title}
          isLast={index === sortedRevisionQuestionDataList.length - 1}
        />
      );
    });

    return headerRow;
  };

  return { generateHeaderRow };
};

const getAnsweredCounts = (onnFormTaskAnswers: OnnFormTaskAnswer[]) => {
  const answeredCount = onnFormTaskAnswers.filter((onnFormTaskAnswer) =>
    onnFormTaskAnswer.isAnswered()
  ).length;
  const notAnsweredCount = onnFormTaskAnswers.length - answeredCount;
  return { answeredCount, notAnsweredCount };
};
