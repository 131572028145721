import { Tenant, resolveHostname } from "@onn/common";
import React, { FC, useCallback, useState, createContext, useEffect, ReactNode } from "react";
import { useLocation } from "react-router-dom";

import { useCurrentUser, useSignOut } from "~/hooks/employee";
import { apiClient } from "~/libs";
import { captureException, chanelTalk } from "~/util";
import { isAdminHostname } from "~/util/isAdminHostname";

export const TenantContext = createContext<{
  tenant: Tenant | undefined;
  fetchTenant: () => void;
}>({
  tenant: undefined,
  fetchTenant: () => void 0,
});

export const TenantProvider: FC<{
  children: ReactNode;
}> = ({ children }) => {
  const { currentUser } = useCurrentUser();
  const location = useLocation();

  const [tenant, setTenant] = useState<Tenant | null>(null);

  const fetchTenant = useCallback(async () => {
    const tenant = await apiClient
      .get("/get_tenant")
      .then((res) => Tenant.plainToInstance(res.data));
    setTenant(tenant);
  }, []);

  useEffect(() => {
    fetchTenant();
  }, [fetchTenant]);

  useEffect(() => {
    if (!tenant) return;

    // NOTE: 新卒向けテナントの管理者にのみ表示する
    if (currentUser.isAdmin() && Tenant.isNewGraduateTenant(tenant)) {
      chanelTalk(currentUser.getName(), tenant.tenantName, location.pathname);
    }
  }, [currentUser, location.pathname, tenant]);

  useRedirect(tenant);

  if (!tenant || Tenant.isMidCarrierTenant(tenant)) return null;

  return (
    <TenantContext.Provider value={{ tenant, fetchTenant }}>{children}</TenantContext.Provider>
  );
};

const useRedirect = (tenant: Tenant | null) => {
  const location = useLocation();
  const { currentUser } = useCurrentUser();
  const { signOut } = useSignOut();

  // 新卒の、管理者 <=> 候補者のリダイレクト
  useEffect(() => {
    if (!tenant) return;

    // NOTE: isActiveNewGraduateかどうかをチェックしているのは中途向けのユーザーがログインしたときに新卒向けドメインにリダイレクトさせないため
    // TODO[中途向けユーザーのケア]: 移行が完全に完了したら削除する
    if (Tenant.isMidCarrierTenant(tenant)) return;

    if (currentUser.isNewcomer() && isAdminHostname()) {
      signOut();
      redirectToNewGraduateHost(currentUser.tenantId);
      return;
    }

    if (!currentUser.isNewcomer() && !isAdminHostname()) {
      signOut();
      redirectToAdminHost();
      return;
    }
  });

  // 中途 <=> 新卒のリダイレクト
  useEffect(() => {
    if (!tenant) return;

    // 中途向けのユーザーが新卒向け環境にアクセスしないようにリダイレクトさせる処理
    // TODO[中途向けユーザーのケア]: 移行が完全に完了したら削除する
    if (Tenant.isMidCarrierTenant(tenant)) {
      signOut();
      window.location.replace(
        // 一時的なコードになるのでハードコードしている
        process.env.NODE_ENV === "production"
          ? "https://onboarding.onn-hr.com/?is-redirect=true"
          : "https://onboarding-staging.onn-hr.com/?is-redirect=true"
      );
    }
  }, [currentUser, location.pathname, signOut, tenant]);
};

const redirectToNewGraduateHost = (tenantId: string) => {
  const hostname = resolveHostname({ isAdmin: false, tenantId, env: process.env.NODE_ENV });
  if (!hostname) {
    _hostnameCouldNotResolvedErrorLogger();
    return;
  }

  _redirectTo(hostname);
};

const redirectToAdminHost = () => {
  const hostname = resolveHostname({ isAdmin: true, env: process.env.NODE_ENV });
  if (!hostname) {
    _hostnameCouldNotResolvedErrorLogger();
    return;
  }

  _redirectTo(hostname);
};

const _redirectTo = (hostname: string) => {
  const currentURL = new URL(location.href);
  currentURL.searchParams.append("is-redirect", "true");
  currentURL.hostname = hostname;
  window.location.replace(currentURL.href);
};

const _hostnameCouldNotResolvedErrorLogger = () => {
  captureException({
    error: new Error("リダイレクト用 hostname が解決できませんでした"),
    tags: {
      type: "could not resolve hostname to redirect to",
    },
  });
};
