import { Box } from "@material-ui/core";
import { EmployeeTag } from "@onn/common";
import React, { FC } from "react";
import styled from "styled-components";

import { EmployeeTagSelect } from "../EmployeeTagSelect";

import { Icon, IconButton, Typography, TextField } from "~/components/uiParts";

type Props = {
  userDataArray: {
    email: string;
    emailErrorMessage: string;
    employeeTagIds: string[];
  }[];
  onChangeInputEmail: (email: string, index: number) => void;
  onDeleteEmails: (index: number) => void;
  onAddEmails: () => void;
  employeeTags: EmployeeTag[];
  onChangeEmployeeTagIds: (tagIds: string[], index: number) => void;
};

const MAX_INVITED_FIELDS = 10;

export const SelectNewGraduateStep: FC<Props> = ({
  userDataArray,
  onChangeInputEmail,
  onDeleteEmails,
  onAddEmails,
  ...props
}) => {
  return (
    <Box display="flex" flexDirection="column">
      <Typography variant="body1" color="textPrimary">
        追加する対象者の情報を入力してください。
      </Typography>
      <Box display="flex" marginTop="32px" gridGap="16px" height="14px">
        <Box flex={1}>
          <Typography variant="body2" bold color="textSecondary">
            メールアドレス
          </Typography>
        </Box>

        <Box flex={1}>
          <Typography variant="body2" bold color="textSecondary">
            タグ（任意）
          </Typography>
        </Box>
        {/* 削除アイコン分の余白を作るためのBox */}
        <Box padding="12px 20px" />
      </Box>

      {userDataArray.map((userData, index) => {
        return (
          <Box key={index} display="flex" alignItems="center" marginTop="16px" gridColumnGap="16px">
            <Box flex={1} maxWidth="50%">
              <StyledTextField
                name="email"
                type="email"
                value={userData.email}
                fullWidth
                variant="outlined"
                placeholder="name@example.com"
                helperText={userData.emailErrorMessage}
                error={!!userData.emailErrorMessage}
                onChange={(event) => onChangeInputEmail(event.target.value, index)}
              />
            </Box>
            <Box flex={1} maxWidth="50%">
              <EmployeeTagSelect
                employeeTags={props.employeeTags}
                selectedTagIds={userData.employeeTagIds || []}
                onChange={(selectedTagIds) => props.onChangeEmployeeTagIds(selectedTagIds, index)}
              />
            </Box>
            <Box width="40px" height="40px">
              {userDataArray.length > 1 && (
                <IconButton
                  icon="close"
                  color="grey"
                  onClick={() => onDeleteEmails(index)}
                  size="sm"
                />
              )}
            </Box>
          </Box>
        );
      })}

      {userDataArray.length !== MAX_INVITED_FIELDS && (
        <StyledTypography variant="body2" bold color="primary" onClick={() => onAddEmails()}>
          <Icon size="sm" icon="add" color="primary" />
          対象者を追加
        </StyledTypography>
      )}
    </Box>
  );
};

const StyledTextField = styled(TextField)`
  .MuiInputBase-input {
    padding: 16px;
  }
`;

const StyledTypography = styled(Typography)`
  &.MuiTypography-root {
    margin-top: 24px;
    display: flex;
    align-items: center;
    grid-gap: 4px;
    cursor: pointer;
  }
`;
