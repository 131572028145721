import { Box } from "@material-ui/core";
import {
  DEFAULT_UPLOAD_ACCEPTED_FILE_TYPES,
  DEFAULT_UPLOAD_INPUT_ACCEPTED_FILE_TYPES,
} from "@onn/common";
import { isEmpty } from "lodash";
import { RequiredBy } from "notistack";
import React, { ComponentProps, FC, useCallback, useState } from "react";

import styled from "styled-components";
import { v4 } from "uuid";

import { InformationValueEditor } from "../InformationValueEditor";

import { AttachmentFileListItem } from "./parts/AttachmentFileListItem";

import { Button, FilePicker, Icon, Loading, Typography } from "~/components/uiParts";
import { useCurrentUser } from "~/hooks/employee";
import { useMetaDataByUrls } from "~/hooks/file";
import { usePrivateFileAPIAdapter, useSnackbar } from "~/hooks/shared";
import { FileMetaData } from "~/infrastructure/usecases/file/fileAPIAdapter";

type Props = {
  onSave: ({ filePaths }: { filePaths: string[] }) => void;
  onCancel: () => void;
  data?: string[];
};

type CoreProps = Props & {
  defaultFiles: FileMetaData[];
};

type FileStatus = {
  status: "uploading" | "error" | "success";
  file?: FileMetaData;
  index: number;
};

export const AttachmentFileEditor: FC<Props> = ({ onSave, onCancel, data }) => {
  const { data: defaultFiles, isLoading } = useMetaDataByUrls(data || []);

  if (isLoading) {
    return <Loading size="small" />;
  }

  return (
    <AttachmentFileEditorCore
      defaultFiles={defaultFiles || []}
      onSave={onSave}
      onCancel={onCancel}
    />
  );
};

export const AttachmentFileEditorCore: FC<CoreProps> = ({ defaultFiles, onSave, onCancel }) => {
  const { currentUser } = useCurrentUser();
  const { enqueueSnackbar } = useSnackbar();
  const { uploadFile, getFileMetaData } = usePrivateFileAPIAdapter();
  const [fileStatus, setFileStatus] = useState<FileStatus[]>(
    defaultFiles.map((defaultFile, index) => {
      return { status: "success", file: defaultFile, index };
    })
  );

  const setErrorStatus = useCallback((index: number) => {
    setFileStatus((prev) =>
      prev.map((v) => {
        if (v.index === index) {
          return { status: "error", index };
        }
        return v;
      })
    );
  }, []);

  const uploadFileAndUpdateFileStatus = useCallback(
    async ({ file, index }: { file: File; index: number }) => {
      try {
        const path = await uploadFile({
          path: `/tenants/${currentUser.tenantId}/employee_informations/${v4()}/${file.name}`,
          file,
        });

        const fileMetaData = await getFileMetaData(path);

        setFileStatus((prev) =>
          prev.map((v) => {
            if (v.index === index) {
              return {
                status: "success",
                index,
                file: fileMetaData,
              };
            }
            return v;
          })
        );
      } catch (error) {
        enqueueSnackbar((error as Error).message, { variant: "error" });
        setErrorStatus(index);
      }
    },
    [currentUser.tenantId, enqueueSnackbar, getFileMetaData, setErrorStatus, uploadFile]
  );

  const handleInputFile: ComponentProps<typeof FilePicker>["onChange"] = useCallback(
    async (payload) => {
      const index = fileStatus.length;
      setFileStatus((prev) => [...prev, { status: "uploading", index }]);

      if (payload.status === "error") {
        enqueueSnackbar(payload.message, { variant: "error" });
        setErrorStatus(index);
        return;
      }
      const file = payload.files[0];
      if (file) {
        uploadFileAndUpdateFileStatus({ file, index });
      }
    },
    [enqueueSnackbar, fileStatus.length, setErrorStatus, uploadFileAndUpdateFileStatus]
  );

  const handleDelete = useCallback((index: number) => {
    setFileStatus((prev) => {
      const newFileStatus = [...prev];
      newFileStatus.splice(index, 1);
      return newFileStatus;
    });
  }, []);

  const handleSave = useCallback(() => {
    onSave({
      filePaths:
        fileStatus
          .filter((v): v is RequiredBy<FileStatus, "file"> => !!v.file)
          .map((v) => v.file?.fullPath) || [],
    });
  }, [fileStatus, onSave]);

  return (
    <InformationValueEditor
      form={
        <Box style={{ display: "flex", flexDirection: "column", rowGap: 16 }}>
          <Box display="flex" gridGap="16px" alignItems="center">
            <FilePicker
              accepts={DEFAULT_UPLOAD_ACCEPTED_FILE_TYPES}
              inputAccept={DEFAULT_UPLOAD_INPUT_ACCEPTED_FILE_TYPES}
              multiple={false}
              onChange={handleInputFile}
            >
              <StyledButtonWrapper>
                <Button
                  startIcon={<Icon icon="clip" size="sm" color="primary" />}
                  color={"primary"}
                  borderRadius={"regular"}
                  variant={"outlined"}
                >
                  ファイルを選択
                </Button>
              </StyledButtonWrapper>
            </FilePicker>
            {isEmpty(fileStatus) && (
              <Typography variant="caption" color="textSecondary">
                {"ファイルが選択されていません"}
              </Typography>
            )}
          </Box>
          <Box style={{ display: "flex", flexDirection: "column", rowGap: 4 }}>
            {fileStatus?.map(({ status, file }, index) => (
              <AttachmentFileListItem
                key={index}
                file={file}
                status={status}
                onDelete={() => handleDelete(index)}
              />
            ))}
          </Box>
        </Box>
      }
      onSave={handleSave}
      onCancel={onCancel}
      disabled={fileStatus.some((v) => v.status !== "success")}
    />
  );
};

const StyledButtonWrapper = styled(Box)`
  /* TODO: buttonコンポーネントを修正してネスト指定が必要ないようにする */
  .MuiButtonBase-root > div {
    padding: 12px;
  }
`;
