import { MemoTransaction } from "@onn/common";

import { MemoDB } from "../transactionRepository";

import { convertTimestampToDate } from "~/util/convertTimestampToDate";

/**
 * transactionRepository.tsからexportされる複数のクラスで横断的に利用されているconvert関数をこのファイルに定義する
 * transactionRepositoryからは1クラスのみをexportするべきであり、convert関数を唯一に統一されるべきであるため、
 * その対応が完了したら、convert関数はprivateな関数に移動し、このファイルは削除されるべきである
 */

export const convertToMemoTransaction = (data: MemoDB): MemoTransaction => {
  return new MemoTransaction(
    data.id,
    data.dataType,
    data.contents,
    data.tenantId,
    data.employeeId,
    data.status,
    convertTimestampToDate(data.createdAt),
    convertTimestampToDate(data.updatedAt),
    convertTimestampToDate(data.sendAt),
    data.deleted
  );
};
