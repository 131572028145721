import { intersectionBy } from "lodash";
import { useMemo } from "react";

import { useFilterByAnswerStatus, useFilterByNameOrEmail, useFilterByAssignees } from "./filter";

import { useRecruitmentStatusList } from "~/components/providers/RecruitmentStatusProvider";
import { useCurrentUser } from "~/hooks/employee";
import {
  useEmployeeActiveLogsByType,
  useGenerateEmployeeIdToLatestEmployeeActiveLogMap,
} from "~/hooks/employeeActiveLog";
import {
  useCandidateDatesWithNumberOfParticipants,
  useDeterminedDate,
  useOnnEventAnswersWithEmployee,
} from "~/hooks/onnEvent";

export const useAnswerResultTab = ({ onnEventId }: { onnEventId: string }) => {
  const { getByRecruitmentStatusId } = useRecruitmentStatusList();
  const { currentUser } = useCurrentUser();
  const { data: onnEventAnswersWithEmployee, isLoading: isLoadingEventAnswers } =
    useOnnEventAnswersWithEmployee({ onnEventId });

  const { data: determinedDates, isLoading: isLoadingDeterminedDate } = useDeterminedDate({
    onnEventId,
  });
  const { data: candidateDatesWithNumberOfParticipants, isLoading: isCandidateDatesLoading } =
    useCandidateDatesWithNumberOfParticipants({ onnEventId, currentEmployeeId: currentUser.id });
  const { selectedAnswerStatusTypes, setSelectedAnswerStatusTypes, answersFilteredByAnswerStatus } =
    useFilterByAnswerStatus(onnEventAnswersWithEmployee ?? []);
  const { setSearchValue, answersFilteredBySearchValue } = useFilterByNameOrEmail(
    onnEventAnswersWithEmployee ?? []
  );
  const employeeIdToDeterminateDate = new Map(
    (determinedDates || []).map((determinedDate) => [determinedDate.employeeId, determinedDate])
  );
  const { selectedAssigneeIds, setSelectedAssigneeIds, answersFilteredByMentors } =
    useFilterByAssignees(onnEventAnswersWithEmployee ?? [], employeeIdToDeterminateDate);

  const { data: employeeActiveLogs = [] } = useEmployeeActiveLogsByType({
    onnEventId,
    type: "VISITED_ONN_EVENT_LANDING_PAGE",
  });

  const { generateEmployeeIdToLatestEmployeeActiveLogMap } =
    useGenerateEmployeeIdToLatestEmployeeActiveLogMap();

  const employeeIdToLatestEmployeeActiveLogMap = useMemo(
    () => generateEmployeeIdToLatestEmployeeActiveLogMap({ employeeActiveLogs }),
    [employeeActiveLogs, generateEmployeeIdToLatestEmployeeActiveLogMap]
  );

  /**
   * 担当者のIDリスト
   */
  const allAssigneeIds = useMemo(() => {
    if (!determinedDates) return [];
    return [...new Set(determinedDates.flatMap((v) => v.assigneeId ?? []))];
  }, [determinedDates]);

  /**
   * 絞り込みの積集合
   */
  const selectedOnnEventAnswers = useMemo(() => {
    // 回答状況, ユーザー名・メールアドレス, 担当者 による絞り込み
    return intersectionBy(
      answersFilteredBySearchValue,
      answersFilteredByAnswerStatus,
      answersFilteredByMentors
    );
  }, [answersFilteredByAnswerStatus, answersFilteredBySearchValue, answersFilteredByMentors]);

  /**
   * 辞退」・「不採用」の場合は、一番下に表示する
   */
  const sortedOnnEventAnswersWithEmployee = useMemo(() => {
    if (!selectedOnnEventAnswers) return [];
    return selectedOnnEventAnswers.sort((a, b) => {
      const aRecruitmentStatus = getByRecruitmentStatusId(a.employee.recruitmentStatusId || "");
      const bRecruitmentStatus = getByRecruitmentStatusId(b.employee.recruitmentStatusId || "");
      if (!aRecruitmentStatus || !bRecruitmentStatus) return 0;

      const aIsRejected = aRecruitmentStatus.type === "rejected";
      const bIsRejected = bRecruitmentStatus.type === "rejected";
      const aIsWithdrew = aRecruitmentStatus.type === "withdrew";
      const bIsWithdrew = bRecruitmentStatus.type === "withdrew";
      if (aIsRejected && bIsWithdrew) return -1;
      if (aIsWithdrew && bIsRejected) return 1;
      if (aIsRejected || aIsWithdrew) return 1;
      if (bIsRejected || bIsWithdrew) return -1;
      return 0;
    });
  }, [getByRecruitmentStatusId, selectedOnnEventAnswers]);

  // 配信数
  const numberOfDistribution = onnEventAnswersWithEmployee ? onnEventAnswersWithEmployee.length : 0;
  // 回答者数
  const numberOfResponses = onnEventAnswersWithEmployee
    ? onnEventAnswersWithEmployee.filter((v) => v.isAnswered()).length
    : 0;

  const isLoading = isLoadingEventAnswers || isLoadingDeterminedDate || isCandidateDatesLoading;

  return {
    determinedDates,
    selectedOnnEventAnswers: sortedOnnEventAnswersWithEmployee,
    setSearchValue,
    isLoading,
    selectedAnswerStatusTypes,
    setSelectedAnswerStatusTypes,
    allAssigneeIds,
    selectedAssigneeIds,
    setSelectedAssigneeIds,
    candidateDatesWithNumberOfParticipants,
    numberOfDistribution,
    numberOfResponses,
    employeeIdToLatestEmployeeActiveLogMap,
  };
};
