import { APISchema } from "@onn/common";

import { useSnackbar } from "../shared";

import { apiClient } from "~/libs";
import { captureException } from "~/util";

/**
 * onnTaskを削除するためのhooks
 */
export const useRemindOnnFormTaskAnswer = () => {
  const { enqueueSnackbar } = useSnackbar();

  const execRemindOnnFormTaskAnswer = async ({
    onnTaskId,
    employeeIds,
  }: {
    onnTaskId: string;
    employeeIds?: string[];
  }) => {
    const body: APISchema["/remind_onn_form_task_answers"]["POST"]["body"] = {
      onnTaskId,
      employeeIds,
    };

    try {
      await apiClient.post(`/remind_onn_form_task_answers`, body);
      enqueueSnackbar("タスクをリマインドしました", { variant: "success" });
    } catch (e) {
      enqueueSnackbar("タスクのリマインドに失敗しました", { variant: "error" });
      captureException({
        error: e as Error,
        tags: {
          type: "useRemindOnnFormTaskAnswer:execRemindOnnFormTaskAnswer",
        },
      });
    }
  };

  return { execRemindOnnFormTaskAnswer };
};
