import { RecruitmentStatus, OnnEvent, EmployeeTag, OnnTask } from "@onn/common";
import { useCallback } from "react";

export const useGenerateSampleCSV = () => {
  const generateSampleCSV = useCallback(
    (
      employeeTags: EmployeeTag[],
      onnEvents: OnnEvent[],
      onnTasks: OnnTask[],
      recruitmentStatuses: RecruitmentStatus[]
    ) => {
      const baseData = [
        [
          "「選考ステータス」コピー欄",
          "「タグ」コピー欄",
          "「イベント」コピー欄",
          "「タスク」コピー欄",
        ],
        [
          "該当する「選考ステータス」は以下候補からコピーして入社者情報記入欄にペーストしてください",
          "該当する「タグ」は以下候補からコピーして入社者情報記入欄にペーストしてください",
          "該当する「イベント」は以下候補からコピーして入社者情報記入欄にペーストしてください",
          "該当する「タスク」は以下候補からコピーして入社者情報記入欄にペーストしてください",
        ],
      ];
      const generatedData = [...baseData];

      const normalOnnEvents = onnEvents.filter((event) => event.type === "normal");
      // lengthは長い方に合わせる
      const length = Math.max(
        recruitmentStatuses.length,
        employeeTags.length,
        normalOnnEvents.length,
        onnTasks.length
      );

      for (let index = 0; index < length; index++) {
        const employeeTag = employeeTags[index];
        const normalOnnEvent = normalOnnEvents[index];
        const onnTask = onnTasks[index];
        const recruitmentStatus = recruitmentStatuses[index];
        generatedData.push([
          recruitmentStatus ? recruitmentStatus.label : "",
          employeeTag ? employeeTag.name : "",
          normalOnnEvent ? normalOnnEvent.title : "",
          onnTask ? onnTask.title : "",
        ]);
      }

      return generatedData;
    },
    []
  );

  return { generateSampleCSV };
};
