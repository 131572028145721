import { Box } from "@material-ui/core";
import { OnnFormTask, OnnTaskQuestion } from "@onn/common";

import React, { FC } from "react";

import { Action, State } from "../../reducer";
import { CheckBoxQuestionForm } from "../form/CheckBoxQuestionForm";
import { FileQuestionForm } from "../form/FileQuestionForm";
import { RadioQuestionForm } from "../form/RadioQuestionForm";
import { TextQuestionForm } from "../form/TextQuestionForm";

import { extractFileNameFromPath } from "~/pages/onn_task/_share_in_create_edit/extractFileNameFromPath";
type Props = {
  onnFormTask: OnnFormTask;
  questions: OnnTaskQuestion[];
  state: State;
  dispatch: React.Dispatch<Action>;
  sampleFileSignedUrlMapObj?: Record<string, string>;
};

export const AnswerEditor: FC<Props> = ({
  onnFormTask,
  questions,
  state,
  dispatch,
  sampleFileSignedUrlMapObj,
}) => {
  return questions.map((question) => {
    const answer = state.answers.find((a) => a.questionId === question.id);
    return (
      <Box mb="40px" key={question.id}>
        {question.type === "TEXT" &&
          (() => {
            const isTextAnswer = answer?.type === "TEXT";
            return (
              <TextQuestionForm
                question={question}
                dispatch={dispatch}
                answer={{
                  text: isTextAnswer ? answer.value : "",
                }}
              />
            );
          })()}

        {question.type === "RADIO" &&
          (() => {
            const isRadioAnswer = answer?.type === "RADIO";
            return (
              <RadioQuestionForm
                question={question}
                dispatch={dispatch}
                selectedOptionId={isRadioAnswer ? answer.selectedOptionId : ""}
              />
            );
          })()}

        {question.type === "CHECK_BOX" &&
          (() => {
            const isCheckBoxAnswer = answer?.type === "CHECK_BOX";
            return (
              <CheckBoxQuestionForm
                question={question}
                dispatch={dispatch}
                answer={{
                  freeText: isCheckBoxAnswer ? answer.freeText : "",
                  selectedOptionIds: isCheckBoxAnswer ? answer.selectedOptionIds : [],
                }}
              />
            );
          })()}

        {question.type === "FILE" &&
          (() => {
            const isFileAnswer = answer?.type === "FILE";
            const sampleFileSignedUrl = question.sampleFilePath
              ? sampleFileSignedUrlMapObj?.[question.sampleFilePath]
              : undefined;
            return (
              <FileQuestionForm
                question={question}
                dispatch={dispatch}
                answer={{
                  fileName: isFileAnswer ? extractFileNameFromPath(answer?.filePath) : "",
                }}
                onnFormTaskId={onnFormTask.id}
                sampleFileSignedUrl={sampleFileSignedUrl}
              />
            );
          })()}
      </Box>
    );
  });
};
