import { Box } from "@material-ui/core";
import { isValidEmail, isValidPassword } from "@onn/common";
import { isEmpty } from "lodash";

import React, { useState, useCallback, FC } from "react";
import styled from "styled-components";

import { useGetIconUrl } from "../../../hooks/useGetIconUrl";

import { Footer } from "./Footer";

import { ConfirmChangeAccountInfoModal } from "~/components/domains/employees";
import { ImageUploadArea, PasswordField, TextField, Typography } from "~/components/uiParts";
import { useCurrentUser } from "~/hooks/employee";
import { checkInputIconHasValues, useInputIcon, useUploadImageFile } from "~/hooks/file";
import { useSnackbar } from "~/hooks/shared";
import { usePrompt } from "~/hooks/shared/usePrompt";
import { AccountUseCase, EmployeeUseCase } from "~/service/usecases/employeeUseCase";
import { captureException, mixin } from "~/util";

export const SettingsAccountForEmailAuth: FC = () => {
  const { currentUser, fetchCurrentUser } = useCurrentUser();
  const { enqueueSnackbar } = useSnackbar();

  const initialAccountInfo = {
    email: currentUser.email,
    firstName: currentUser.firstName,
    lastName: currentUser.lastName,
    password: "",
  };
  const initialChangedAccountInfo = {
    email: false,
    firstName: false,
    lastName: false,
    password: false,
    icon: false,
  };

  const [newAccountInfo, setNewAccountInfo] = useState(initialAccountInfo);
  const [changedAccountInfo, setChangedAccountInfo] = useState(initialChangedAccountInfo);

  const [openConfirmChangeAccountInfoModal, setOpenConfirmChangeAccountInfoModal] =
    useState<boolean>(false);

  const { inputIcon, handleUploadIcon, handleErrorUploadIcon, resetInputIcon, allowImageSize } =
    useInputIcon({
      dataUrl: undefined,
      path: undefined,
    });

  const { getIconUrl } = useGetIconUrl();

  // このタイミングでprofileIconImageUrlがundefinedのことは仕様的にないはずなので、一旦空文字をいれてる。
  const defaultImage = getIconUrl(currentUser.profileIconImageUrl || "");
  const { uploadImageFile } = useUploadImageFile();

  // パスワードはブランクかポリシーを満たすもの
  const canSubmit =
    Object.values(changedAccountInfo).some((v) => v) && // 一箇所以上変更されている
    (isEmpty(newAccountInfo.password) || isValidPassword(newAccountInfo.password)) && // パスワードフィールドが空か、正しい内容である
    isValidEmail(newAccountInfo.email) && // emailは正しい内容である
    Boolean(newAccountInfo.firstName.trim()) && // 名はブランクでない
    Boolean(newAccountInfo.lastName.trim()); // 姓はブランクでない

  usePrompt(
    "編集内容を破棄しますか？",
    // 一箇所でも変更されている場合は確認ダイアログを表示する
    Object.values(changedAccountInfo).some((v) => v)
  );

  const updateAccountInfoWithoutPassword = async (): Promise<void> => {
    try {
      updateBasicAccountInfo();

      setNewAccountInfo((prev) => ({ ...prev, password: "" }));
      setChangedAccountInfo(initialChangedAccountInfo);
      fetchCurrentUser();
      enqueueSnackbar("アカウント設定を更新しました", { variant: "success" });
      setOpenConfirmChangeAccountInfoModal(false);
    } catch (e) {
      if (e instanceof Error) {
        enqueueSnackbar(e.message, { variant: "error" });
      }
      captureException({
        error: e as Error,
        tags: { type: "SettingsAccount:updateAccountInfoWithoutPassword" },
      });
    }
  };

  const updateAccountInfoWithPassword = async (currentPassword: string): Promise<void> => {
    try {
      await updateSecureAccountInfo(currentPassword);
      await updateBasicAccountInfo();

      setNewAccountInfo((prev) => ({ ...prev, password: "" }));
      setChangedAccountInfo(initialChangedAccountInfo);
      fetchCurrentUser();
      enqueueSnackbar("アカウント設定を更新しました", { variant: "success" });
      setOpenConfirmChangeAccountInfoModal(false);
    } catch (e) {
      if (e instanceof Error) {
        enqueueSnackbar(e.message, { variant: "error" });
      }
      captureException({
        error: e as Error,
        tags: { type: "SettingsAccount:updateAccountInfoWithPassword" },
      });
    }
  };

  const updateSecureAccountInfo = async (currentPassword: string): Promise<void> => {
    // NOTE: updateEmailは重複チェックの対象になっているので、emailが変更されている場合のみ更新する
    if (newAccountInfo.email !== currentUser.email) {
      await AccountUseCase.updateEmail(newAccountInfo.email, currentPassword);
    }

    if (!isEmpty(newAccountInfo.password)) {
      await AccountUseCase.updatePassword(currentPassword, newAccountInfo.password);
    }
  };

  const updateBasicAccountInfo = async (): Promise<void> => {
    const shouldUploadImage =
      checkInputIconHasValues(inputIcon) &&
      /data:image\//i.test(inputIcon.dataUrl) &&
      currentUser.profileIconImageUrl !== inputIcon.path;

    if (shouldUploadImage) {
      await uploadImageFile(inputIcon);
    }

    await EmployeeUseCase.updateCurrentEmployee({
      firstName: newAccountInfo.firstName,
      lastName: newAccountInfo.lastName,
      email: newAccountInfo.email,
      ...(shouldUploadImage ? { profileIconImageUrl: inputIcon.path } : {}),
    });
  };

  const handleClickSave = () => {
    // Email or パスワードが変更されている場合は確認ダイアログを表示する
    if (changedAccountInfo.email || changedAccountInfo.password) {
      handleOpenConfirmModal();
      // それ以外の場合はそのまま更新する
    } else {
      updateAccountInfoWithoutPassword();
    }
  };

  const handleOpenConfirmModal = () => {
    setOpenConfirmChangeAccountInfoModal(true);
  };

  const handleCloseConfirmModal = () => {
    setOpenConfirmChangeAccountInfoModal(false);
  };

  // キャンセルすることで初期化する
  const handleDiscard = () => {
    setNewAccountInfo(initialAccountInfo);
    setChangedAccountInfo(initialChangedAccountInfo);
    resetInputIcon();
  };

  const handleChangeField = useCallback((value: string, target: keyof typeof newAccountInfo) => {
    setNewAccountInfo((prev) => ({ ...prev, [target]: value }));
    setChangedAccountInfo((prev) => ({ ...prev, [target]: true }));
  }, []);

  const handleChangeIcon = useCallback(
    (file: File) => {
      handleUploadIcon(file);
      setChangedAccountInfo((prev) => ({ ...prev, icon: true }));
    },
    [handleUploadIcon]
  );

  return (
    <>
      <Typography variant="body2" bold color="textSecondary">
        アイコン
      </Typography>
      <Typography variant="caption" align="left" color="textSecondary">
        アイコン画像を設定してください。顔写真を推奨しています。
      </Typography>
      <Box height="20px" />
      <ImageUploadArea
        alt="アイコン"
        defaultImage={defaultImage}
        imagePath={inputIcon?.dataUrl || ""}
        allowImageSize={allowImageSize}
        onChange={handleChangeIcon}
        onError={handleErrorUploadIcon}
        size="large"
      />
      <Box height="40px" />
      <StyledTitleTypography variant="body2" bold color="textSecondary">
        氏名
      </StyledTitleTypography>
      <Box display="flex" gridGap="16px">
        <TextField
          fullWidth
          name="lname"
          value={newAccountInfo.lastName}
          variant="outlined"
          onChange={(e) => handleChangeField(e.target.value, "lastName")}
        />
        <TextField
          fullWidth
          name="fname"
          value={newAccountInfo.firstName}
          variant="outlined"
          onChange={(e) => handleChangeField(e.target.value, "firstName")}
        />
      </Box>
      <Box height="40px" />
      <StyledTitleTypography variant="body2" bold color="textSecondary">
        メールアドレス
      </StyledTitleTypography>
      <TextField
        name="email"
        type="email"
        value={newAccountInfo.email}
        variant="outlined"
        autoComplete="email"
        fullWidth
        error={!isValidEmail(newAccountInfo.email)}
        helperText={!isValidEmail(newAccountInfo.email) ? "不正なメールアドレスです" : undefined}
        onChange={(e) => handleChangeField(e.target.value, "email")}
      />
      <Box height="40px" />
      <StyledTitleTypography variant="body2" bold color="textSecondary">
        新しいパスワード
      </StyledTitleTypography>
      <PasswordField
        helperText="半角英数字6文字以上で入力してください"
        value={newAccountInfo.password}
        onChange={(v) => handleChangeField(v, "password")}
        fullWidth
        error={!isEmpty(newAccountInfo.password) && !isValidPassword(newAccountInfo.password)}
        autoComplete="new-password"
      />
      <Footer onClickCancel={handleDiscard} onClickSave={handleClickSave} canSubmit={canSubmit} />

      {openConfirmChangeAccountInfoModal && (
        <ConfirmChangeAccountInfoModal
          open={openConfirmChangeAccountInfoModal}
          onCancel={handleCloseConfirmModal}
          onAccept={updateAccountInfoWithPassword}
          hasChangedEmail={changedAccountInfo.email}
          hasChangedName={changedAccountInfo.lastName || changedAccountInfo.firstName}
          hasChangedPassword={changedAccountInfo.password}
          hasChangedIcon={changedAccountInfo.icon}
        />
      )}
    </>
  );
};

const StyledTitleTypography = styled(Typography)`
  ${mixin.sp`
    &.MuiTypography-root {
      margin-bottom: 16px;
    }
  `}
`;
