import { Box } from "@material-ui/core";
import React, { FC, useState } from "react";
import styled from "styled-components";

import { Button, Icon, Loading, Typography } from "~/components/uiParts";
import { useChangeAuthenticationTypeLink } from "~/hooks/changeAuthenticationTypeLink/useChangeAuthenticationTypeLink";
import { useCreateChangeAuthenticationTypeLink } from "~/hooks/changeAuthenticationTypeLink/useCreateChangeAuthenticationTypeLink";
import { useCurrentUser } from "~/hooks/employee";
import { useNotifyOperationLog, useSnackbar } from "~/hooks/shared";
import { isMobileBrowser } from "~/util";
export const AccountLinkage: FC = () => {
  const { currentUser } = useCurrentUser();
  const { data: changeAuthenticationTypeLink, isLoading } = useChangeAuthenticationTypeLink();
  if (isLoading) {
    return <Loading size="large" />;
  }
  return (
    <StyledWrapper>
      {currentUser.currentAuthenticationType === "email" && (
        <WhenCurrentAuthenticationTypeIsEmail
          isExistNotExpiredLink={!!changeAuthenticationTypeLink}
        />
      )}
      {currentUser.currentAuthenticationType === "line" && <WhenCurrentAuthenticationTypeIsLine />}
    </StyledWrapper>
  );
};

// email認証候補者向けのコンポーネント
const WhenCurrentAuthenticationTypeIsEmail: FC<{ isExistNotExpiredLink: boolean }> = ({
  isExistNotExpiredLink,
}) => {
  const { notifyOperationLog, operationLog } = useNotifyOperationLog();
  const { currentUser } = useCurrentUser();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const { createChangeAuthenticationTypeLink } = useCreateChangeAuthenticationTypeLink();
  const handleLinkageButtonClick = async () => {
    try {
      setIsLoading(true);
      await createChangeAuthenticationTypeLink({
        fromType: "email",
        toType: "line",
      });
      notifyOperationLog(operationLog.notifyAttemptToChangeAuthentication(currentUser));
      enqueueSnackbar("ご登録のメールアドレスにLINEログイン切替用のリンクを送付しました。", {
        variant: "success",
      });
    } catch (error) {
      enqueueSnackbar("エラーが発生しました。管理者までお問い合わせください。", {
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const isButtonDisabled = isLoading || isExistNotExpiredLink;
  const isButtonLoading = isLoading;
  return (
    <Box
      display={"flex"}
      justifyContent={"space-between"}
      alignItems={isMobileBrowser() ? "" : "center"}
      flexDirection={isMobileBrowser() ? "column" : "row"}
    >
      <Box display={"flex"} justifyContent={isMobileBrowser() ? "" : "space-between"}>
        <Icon icon="email" color="primary" size="xl" />
        <Box ml="16px">
          <StyledTypographyBody1 variant="body1" bold>
            メールアドレスログイン
          </StyledTypographyBody1>
          <StyledTypography variant="caption" component={"div"}>
            連携状況：連携済み
          </StyledTypography>
        </Box>
      </Box>
      <Box
        display={"flex"}
        justifyContent="spaceBetween"
        mt={isMobileBrowser() ? "24px" : ""}
        height={"48px"}
      >
        <Button
          onClick={handleLinkageButtonClick}
          variant={isExistNotExpiredLink ? "outlined" : "contained"}
          color="primary"
          borderRadius="circle"
          disabled={isButtonDisabled}
          isLoading={isButtonLoading}
          fullWidth={isMobileBrowser()}
          fullHeight
        >
          {isExistNotExpiredLink ? "メール送付済" : " LINEログインへ変更"}
        </Button>
      </Box>
    </Box>
  );
};

// line認証候補者向けのコンポーネント
const WhenCurrentAuthenticationTypeIsLine: FC = () => {
  return (
    <Box display={"flex"} justifyContent={"space-between"}>
      <Box display={"flex"} justifyContent="space-between">
        <Icon icon="lineChat" color="primary" size="xl" />
        <Box ml="16px">
          <StyledTypographyBody1 variant="body1" bold>
            LINEログイン
          </StyledTypographyBody1>
          <StyledTypography variant="caption" component={"div"}>
            連携状況：連携済み
          </StyledTypography>
        </Box>
      </Box>
    </Box>
  );
};

const StyledTypographyBody1 = styled(Typography)`
  margin: 0px;
  padding: 0px;
  &.MuiTypography-caption {
    line-height: 1.5;
  }
`;

const StyledTypography = styled(Typography)`
  margin: 0px;
  padding: 0px;
  &.MuiTypography-caption {
    line-height: 1.2;
  }
`;

const StyledWrapper = styled.div`
  max-width: 500px;
`;
