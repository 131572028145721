import { OnnEventPlace } from "@onn/common";

import { InputState } from "./zodFormSchema";

export const generateInitialValues = ({
  onnEventPlace,
}: {
  onnEventPlace?: OnnEventPlace;
}): InputState => {
  if (!onnEventPlace) {
    return {
      placeName: "",
      postCode: "",
      state: "",
      city: "",
      address1: "",
      address2: "",
    };
  }
  return {
    placeName: onnEventPlace.name,
    postCode: onnEventPlace.postCode,
    state: onnEventPlace.state,
    city: onnEventPlace.city,
    address1: onnEventPlace.address1,
    address2: onnEventPlace.address2,
  };
};
