import { OnnEvent, OnnEventSlotDate } from "@onn/common";
import { format } from "date-fns";
import ja from "date-fns/locale/ja";
import React, { FC, useCallback } from "react";

import { Button, CellItem, Icon, StyleProps, Typography } from "~/components/uiParts";
import { useModal } from "~/hooks/modal";

export const SlotTimeCell: FC<
  {
    selectedOnnEventSlotDate?: OnnEventSlotDate;
    onnEvent: OnnEvent;
    newGraduateToDisplayId: string;
  } & StyleProps
> = ({ selectedOnnEventSlotDate, onnEvent, newGraduateToDisplayId, ...styleProps }) => {
  const { handleModal } = useModal();

  const openNewInterviewAnswerModal = useCallback(() => {
    handleModal({
      name: "answerNewInterviewEventOnBehalfModal",
      args: {
        onnEventId: onnEvent.id,
        fixedOption: {
          selectedEmployeeId: newGraduateToDisplayId,
        },
        mode: {
          type: "create",
        },
        slotDefaultValueSetting: onnEvent.slotDefaultValueSetting,
      },
    });
  }, [handleModal, newGraduateToDisplayId, onnEvent.id, onnEvent.slotDefaultValueSetting]);

  return (
    <CellItem {...styleProps} isShowTopBorder>
      {selectedOnnEventSlotDate ? (
        <Typography variant="body2">
          {`${format(selectedOnnEventSlotDate.from, "MM/dd(E) HH:mm", { locale: ja })}〜${format(
            selectedOnnEventSlotDate.until,
            "HH:mm"
          )}`}
        </Typography>
      ) : (
        <Button
          borderRadius="regular"
          color="primary"
          variant="text"
          onClick={openNewInterviewAnswerModal}
          startIcon={<Icon icon="add" color="primary" size="sm" />}
        >
          代理予約
        </Button>
      )}
    </CellItem>
  );
};
