import { Chip as MuiChip } from "@material-ui/core";
import React from "react";
import styled from "styled-components";

import { StyledHeaderCellWrapper } from "./StyledHeaderCellWrapper";

import { Typography } from "~/components/uiParts";

export const UnAvailableHeaderCell = ({
  style,
  columnIndex,
  numOfAnswerWithUnavailableCandidates,
}: {
  style: React.CSSProperties;
  columnIndex: number;
  numOfAnswerWithUnavailableCandidates: number;
}) => {
  return (
    <StyledHeaderCellWrapper
      key={`TableHead-${columnIndex}`}
      px="16px"
      style={{ ...style }}
      isLastColumn={true}
    >
      <Typography
        display="block"
        variant="body1"
        noWrap
        // Muiのデフォルトのスタイルを上書きするためにstyleで記述。styledで上書きされなかったため。
        style={{ lineHeight: 1.4, textAlign: "center" }}
      >
        参加できる
        <br />
        日程がない
      </Typography>
      <StyledChip
        label={
          <Typography variant="overline" display="inline" color="textSecondary">
            {`${numOfAnswerWithUnavailableCandidates}人`}
          </Typography>
        }
      />
    </StyledHeaderCellWrapper>
  );
};

const StyledChip = styled(MuiChip)`
  &.MuiChip-root {
    margin-top: 8px;
    font-size: 10px;
    height: 24px;
    padding: 4px;

    background-color: ${(props) => props.theme.palette.grey[50]};
  }
`;
