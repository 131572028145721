import {
  createIMockOnnEventAnswer,
  createIMockOnnEventDeterminedDate,
  OnnEventSlotDateWithNumberOfParticipants,
} from "@onn/common";
import { useCallback } from "react";

import { useCurrentUser } from "../../employee";
import {
  ONN_EVENT_PREVIEW_DATA_KEY,
  OnnEventDataForPortal,
} from "../../onnEvent/useOnnEventForPortal";

import { useLocalStorage } from "~/hooks/shared";

export const useAnswerOnnEventForPreview = () => {
  const { currentUser } = useCurrentUser();
  const { storeValue } = useLocalStorage();
  const answerNewInterviewEventForPreview = useCallback(
    (eventData: OnnEventDataForPortal, onnEventSlotDateId: string) => {
      storeValue(ONN_EVENT_PREVIEW_DATA_KEY, {
        onnEvent: eventData.onnEvent,
        onnEventAnswer: createIMockOnnEventAnswer({
          onnEventId: eventData.onnEvent.id,
          employeeId: currentUser.id,
          selectedOnnEventSlotDateId: onnEventSlotDateId,
        }),
        onnEventDeterminedDate: createIMockOnnEventDeterminedDate({
          onnEventId: eventData.onnEvent.id,
          employeeId: currentUser.id,
          onnEventSlotDateId: onnEventSlotDateId,
        }),
        candidateDatesWithNumberOfParticipants:
          eventData.candidateDatesWithNumberOfParticipants || [],
        onnEventSlotDateWithNumberOfParticipants:
          eventData.onnEventSlotDateWithNumberOfParticipants
            ?.map(
              (v) =>
                new OnnEventSlotDateWithNumberOfParticipants({
                  onnEventSlotDate: v.onnEventSlotDate,
                  numberOfParticipants: v.numberOfParticipants,
                })
            )
            .filter((v) => v.onnEventSlotDate.id === onnEventSlotDateId || v.canParticipate()) ||
          [],
      });
    },
    [currentUser.id, storeValue]
  );

  return { answerNewInterviewEventForPreview };
};
