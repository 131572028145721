import { z } from "zod";

import { onnEventTypes, OnnEventType } from "../OnnEvent/OnnEvent/types";
import { onnTaskTypes, OnnTaskType } from "../OnnTask/BaseOnnTask/types";

export type RecruitmentProcessRelationSetting = {
  event?: {
    type: OnnEventType;
    id: string;
  };
  tasks: Array<{
    type: OnnTaskType;
    id: string;
  }>;
};

export const relationSettingSchema = z.object({
  event: z
    .object({
      type: z.enum(onnEventTypes),
      id: z.string(),
    })
    .optional(),
  tasks: z.array(
    z.object({
      type: z.enum(onnTaskTypes),
      id: z.string(),
    })
  ),
});
