import { z } from "zod";

export const RecruitmentProcessRecordScalarFieldEnumSchema = z.enum([
  "id",
  "tenantId",
  "spaceId",
  "employeeId",
  "recruitmentStatusId",
  "progressStatus",
  "relationSetting",
  "createdAt",
  "updatedAt",
]);

export default RecruitmentProcessRecordScalarFieldEnumSchema;
