import React, { FC } from "react";

export const ArrowUp: FC = () => {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.59961 7.4L13.1996 12L8.59961 16.6L9.99961 18L15.9996 12L9.99961 6L8.59961 7.4Z"
        stroke="none"
      />
    </svg>
  );
};
