import { OnnTask, OnnFormTask } from "@onn/common";
import useSWR, { SWRResponse } from "swr";

import { apiClient } from "~/libs";

const generateOnnTasksKey = (employeeId: string) => {
  return { endpoint: `/get_onn_tasks_for_portal`, employeeId: `${employeeId}` } as const;
};

export const useOnnTasksForPortal = ({
  employeeId,
}: {
  employeeId: string;
}): SWRResponse<OnnTask[], Error> => {
  return useSWR(generateOnnTasksKey(employeeId), async ({ endpoint }) => {
    const response = await apiClient.get(endpoint);
    return response.data.map((d) => {
      switch (d.type) {
        case "FORM_TASK": {
          return new OnnFormTask(d);
        }
        default: {
          throw new Error(`${d.type} is not implemented yet`);
        }
      }
    });
  });
};
