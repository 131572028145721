import { OnnEventAnswerStatus } from "@onn/common";

import { useMemo, useState } from "react";

import { OnnEventAnswerWithEmployee } from "~/hooks/onnEvent";

/**
 * 回答状況による絞り込みと結果
 */
export const useFilterByAnswerStatus = (
  onnEventAnswersWithEmployee: OnnEventAnswerWithEmployee[]
) => {
  const [selectedAnswerStatusTypes, setSelectedAnswerStatusTypes] = useState<
    OnnEventAnswerStatus[]
  >([]);

  const answersFilteredByAnswerStatus = useMemo(() => {
    if (!selectedAnswerStatusTypes.length) return onnEventAnswersWithEmployee;
    return onnEventAnswersWithEmployee.filter((answer) => {
      if (selectedAnswerStatusTypes.includes("answered") && answer.isAnswered()) return true;
      if (selectedAnswerStatusTypes.includes("unAnswered") && !answer.isAnswered()) return true;

      return false;
    });
  }, [onnEventAnswersWithEmployee, selectedAnswerStatusTypes]);

  return {
    selectedAnswerStatusTypes,
    setSelectedAnswerStatusTypes,
    answersFilteredByAnswerStatus,
  };
};
