import { Box } from "@material-ui/core";

import { OnnEvent, Employee } from "@onn/common";
import { format } from "date-fns";
import React, { FC } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { AssigneeCell } from "./AssigneeCell";

import { OnnEventManageMenu } from "~/components/domains/onnEvents/OnnEventManageMenu/OnnEventManageMenu";
import {
  Typography,
  Button,
  TooltipWhenTextTruncated,
  Loading,
  Chip,
  IconButton,
} from "~/components/uiParts";
import { TableRowWrapper } from "~/components/uiParts/VirtualizedTable";
import { TableRow } from "~/components/uiParts/VirtualizedTable/TableRow";
import { VirtualizedTableV2 } from "~/components/uiParts/VirtualizedTable/VirtualizedTableV2";
import { useModal } from "~/hooks/modal";
import { useOnnEventAnswersWithEmployee } from "~/hooks/onnEvent";
import { useOnnEventAnswers } from "~/hooks/onnEvent/answerResult/useOnnEventAnswers";

type Props = {
  currentUser: Employee;
  onnEvents: OnnEvent[];
  isLoading?: boolean;
  onClickAssignButton: (onnEventId: string) => void;
};

export const EventsListTable: FC<Props> = ({
  currentUser,
  onnEvents,
  isLoading,
  onClickAssignButton,
}) => {
  const widthOptions = currentUser.isAdmin()
    ? ["46%", "20%", "6.5%", "6.5%", "9%", "4%", "8%"]
    : ["60%", "20%", "8%", "4%", "8%"];
  return (
    <VirtualizedTableV2<OnnEvent>
      rowHeight={90}
      isLoading={isLoading}
      widthOptions={widthOptions}
      headers={[
        {
          text: "タイトル",
        },
        {
          text: "担当者",
        },
        {
          text: "配信数",
        },
        {
          text: "回答数",
        },
        currentUser.isAdmin()
          ? {
              text: "",
            }
          : [],
        {
          text: "",
        },
        currentUser.isAdmin()
          ? {
              text: "",
            }
          : [],
      ].flat()}
      rows={onnEvents}
      rowRenderer={({ key, index, style, rowData: onnEvent }) => {
        const contents = [
          <TitleCell key={onnEvent.id} onnEvent={onnEvent} />,
          <AssigneeCell
            key={onnEvent.id}
            onnEvent={onnEvent}
            onClickAssignButton={onClickAssignButton}
          />,
          <NumberOfDistributionCell key={onnEvent.id} onnEventId={onnEvent.id} />,
          <NumberOfResponses key={onnEvent.id} onnEventId={onnEvent.id} />,
          currentUser.isAdmin() ? (
            <ToDetailOrDeliveryButtonCellOrStatusChip key={onnEvent.id} onnEvent={onnEvent} />
          ) : (
            []
          ),
          <QrCodeCell key="qrCode" onnEvent={onnEvent} />,
          currentUser.isAdmin() ? (
            <OnnEventManageMenuWrapper
              onnEvent={onnEvent}
              key={onnEvent.id}
              onClickAssignButton={onClickAssignButton}
            />
          ) : (
            []
          ),
        ].flat();
        return (
          <TableRowWrapper key={key} index={index} {...style}>
            <TableRow
              row={{
                contents,
                to: `/events/${onnEvent.id}`,
                isTargetBlank: false,
              }}
              widthOptions={widthOptions}
              hover={true}
            />
          </TableRowWrapper>
        );
      }}
    />
  );
};

// タイトルを表示するセル
const TitleCell = ({ onnEvent }: { onnEvent: OnnEvent }) => {
  const deadlineDateStr = `回答期限：${
    onnEvent.deadlineDate ? format(onnEvent.deadlineDate, "〜yyyy/MM/dd") : "未設定"
  }`;

  const titleText = `${onnEvent.title}`;
  return (
    <Box key={onnEvent.id}>
      <Box>
        <Box>
          <TooltipWhenTextTruncated text={titleText}>
            {(ref) => (
              <StyledTitle ref={ref} variant="body1" bold>
                {titleText}
              </StyledTitle>
            )}
          </TooltipWhenTextTruncated>
        </Box>
      </Box>
      <StyledDeadlineDate key={onnEvent.id}>{deadlineDateStr}</StyledDeadlineDate>
    </Box>
  );
};

// 配信数を表示するセル
const NumberOfDistributionCell = ({ onnEventId }: { onnEventId: string }) => {
  const { data: onnEventsAnswers, isLoading } = useOnnEventAnswers({ onnEventId });
  if (isLoading) return <Loading size="small" fullHeight />;
  if (onnEventsAnswers && onnEventsAnswers.length !== 0) return <>{onnEventsAnswers.length}</>;
  return <>-</>;
};

// 回答数を表示するセル
const NumberOfResponses = ({ onnEventId }: { onnEventId: string }) => {
  const { data: onnEventsAnswers, isLoading } = useOnnEventAnswers({ onnEventId });
  if (isLoading) return <Loading size="small" fullHeight />;
  if (onnEventsAnswers && onnEventsAnswers.length !== 0)
    return <>{onnEventsAnswers.filter((v) => v.isAnswered()).length}</>;
  return <>-</>;
};

// 配信設定or回答確認ボタンを表示するセル
const ToDetailOrDeliveryButtonCellOrStatusChip = ({ onnEvent }: { onnEvent: OnnEvent }) => {
  const { data: onnEventsAnswers, isLoading } = useOnnEventAnswers({ onnEventId: onnEvent.id });
  const navigation = useNavigate();

  if (isLoading || onnEventsAnswers === undefined) return <Loading size="small" fullHeight />;

  if (
    onnEvent.isSettingCompleted() &&
    !onnEvent.isExceededScheduledDate() &&
    onnEventsAnswers.length > 0
  ) {
    // 配信待ちというテキストをChipで表示する
    return <StyledChip label="配信待ち" color="grey" bold />;
  }

  const shouldShowGoToAnswersButton = onnEvent.isSettingCompleted() && onnEventsAnswers.length > 0;

  return (
    <Box height={"30px"}>
      {/* NOTE: interviewタイプのイベントは入社者が日程回答できないため配信設定画面に遷移させない */}
      {shouldShowGoToAnswersButton || onnEvent.type === "interview" ? (
        <StyledButton
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            navigation(`/events/${onnEvent.id}#answer`);
          }}
          color="default"
          variant="outlined"
          borderRadius="regular"
        >
          回答確認
        </StyledButton>
      ) : (
        <StyledButton
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            navigation(`/events/${onnEvent.id}/delivery_setting`);
          }}
          color="secondary"
          variant="outlined"
          borderRadius="regular"
        >
          配信設定
        </StyledButton>
      )}
    </Box>
  );
};

const QrCodeCell = ({ onnEvent }: { onnEvent: OnnEvent }) => {
  const { handleModal } = useModal();

  const openQRCodeModal = () => {
    handleModal({
      name: "eventAttendanceQRModal",
      args: {
        onnEvent,
      },
    });
  };
  return <IconButton icon="qrCode" onClick={openQRCodeModal} />;
};

// ３点ドットメニューセル
const OnnEventManageMenuWrapper = ({
  onnEvent,
  onClickAssignButton,
  ...rest
}: {
  onnEvent: OnnEvent;
  onClickAssignButton: (onnEventId: string) => void;
}) => {
  const { data: onnEventAnswers, isLoading } = useOnnEventAnswersWithEmployee({
    onnEventId: onnEvent.id,
  });

  if (isLoading || onnEventAnswers === undefined) return <Loading size="small" fullHeight />;
  return (
    <OnnEventManageMenu
      onnEvent={onnEvent}
      numberOfDistribution={onnEventAnswers.length}
      onnEventAnswers={onnEventAnswers}
      onClickAssignButton={onClickAssignButton}
      {...rest} // 特にkey
    />
  );
};

// TODO: Muiを剥がすタイミングでボタンサイズをデザインに揃える
const StyledButton = styled(Button)``;

const StyledTitle = styled(Typography)`
  padding-right: 12px;
  margin-right: 10px;
  position: relative;
  display: inline-block;
  &.MuiTypography-root {
    display: -webkit-box;
    overflow: hidden;
    word-break: break-all;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
  }
`;

const StyledDeadlineDate = styled.span`
  font-size: 12px;
  color: ${(props) => props.theme.palette.text.muted};
`;

const StyledChip = styled(Chip)`
  &.MuiChip-root {
    font-size: 10px;
    height: 32px;
    padding: 0 8px;
    line-height: 13px;
    cursor: pointer;
  }
`;
