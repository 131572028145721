import { OnnEvent, OnnEventAnswer } from "@onn/common";
import { useState, useEffect, useCallback, useMemo } from "react";

import { useLocation, useNavigate } from "react-router-dom";

import { useModal } from "~/hooks/modal";
import { useUpdateOnnEventAssignee } from "~/hooks/onnEvent";
import { useNotifyOnnEventAnswerRemindByEventId } from "~/hooks/onnEventAnswer/useNotifyOnnEventAnswerRemindByEventId";
import { useSnackbar } from "~/hooks/shared";

const tabValueForNewInterview = ["eventContents", "eventSlots", "interviewSchedule"] as const;
const tabValueForOthers = ["eventContents", "answer"] as const;

type TabValueForNewInterview = (typeof tabValueForNewInterview)[number];
type TabValueForOthers = (typeof tabValueForOthers)[number];
type TabValue = TabValueForNewInterview | TabValueForOthers;

export const useEventDetail = ({
  onnEvent,
  onnEventAnswers,
}: {
  onnEvent: OnnEvent;
  onnEventAnswers: OnnEventAnswer[];
}) => {
  const location = useLocation();
  const navigate = useNavigate();

  const tabs: Array<{ label: string; value: string }> = useMemo(() => {
    return onnEvent.type === "new_interview"
      ? [
          {
            label: "イベント内容",
            value: "eventContents",
          },
          {
            label: "予約枠",
            value: "eventSlots",
          },
          {
            label: "面談予定",
            value: "interviewSchedule",
          },
        ]
      : [
          {
            label: "イベント内容",
            value: "eventContents",
          },
          {
            label: "回答ステータス",
            value: "answer",
          },
        ];
  }, [onnEvent.type]);

  const [activeTab, setActiveTab] = useState<TabValue>("eventContents");

  const handleChangeActiveTab = (_: unknown, newValue: TabValue) => {
    navigate(`#${newValue}`);
    setActiveTab(newValue);
  };
  const isNewInterviewEvent = useMemo(() => onnEvent.isNewInterviewEvent(), [onnEvent]);

  useEffect(() => {
    if (isNewInterviewEvent) {
      switch (location.hash) {
        case "#eventContents": {
          setActiveTab("eventContents");
          break;
        }
        case "#eventSlots": {
          setActiveTab("eventSlots");
          break;
        }
        case "#interviewSchedule": {
          setActiveTab("interviewSchedule");
          break;
        }
        default: {
          void 0;
        }
      }
    } else {
      switch (location.hash) {
        case "#eventContents": {
          setActiveTab("eventContents");
          break;
        }
        case "#answer": {
          setActiveTab("answer");
          break;
        }
        default: {
          void 0;
        }
      }
    }
  }, [location.hash, navigate, isNewInterviewEvent]);

  const { handleModal } = useModal();
  const { enqueueSnackbar } = useSnackbar();
  const { updateOnnEventAssignee } = useUpdateOnnEventAssignee();
  const { notifyOnnEventAnswerRemindByEventId } = useNotifyOnnEventAnswerRemindByEventId();

  const onClickBulkRemindButton = useCallback(
    (tenantId: string, eventId: string) => {
      handleModal({
        name: "eventBulkRemindModal",
        args: {
          tenantId,
          eventId,
          onSubmit: async (tenantId: string, eventId: string) => {
            await notifyOnnEventAnswerRemindByEventId({
              tenantId: tenantId,
              onnEventId: eventId,
            }).catch((e) => {
              enqueueSnackbar("リマインド送信が失敗しました。再度お試しください。", {
                variant: "error",
              });
              throw e;
            });
            enqueueSnackbar("リマインドを送信しました", { variant: "success" });
          },
        },
      });
    },
    [handleModal, notifyOnnEventAnswerRemindByEventId, enqueueSnackbar]
  );

  const onClickAssignButton = useCallback(() => {
    handleModal({
      name: "updateEventAssigneeModal",
      args: {
        existingAssigneeIds: onnEvent.assigneeIds,
        onSubmit: async (employeeIds) => {
          updateOnnEventAssignee({ onnEventId: onnEvent.id, employeeIds });
        },
      },
    });
  }, [onnEvent, handleModal, updateOnnEventAssignee]);

  // 回答者数
  const numberOfResponses = onnEventAnswers.filter(
    (v) => Object.keys(v.answer).length !== 0
  ).length;

  // 配信数
  const numberOfDistribution = onnEventAnswers.length;

  return {
    activeTab,
    handleChangeActiveTab,
    tabs,
    onClickBulkRemindButton,
    onClickAssignButton,
    numberOfResponses,
    numberOfDistribution,
  };
};
