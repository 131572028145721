import { Box } from "@material-ui/core";
import { prefectures } from "@onn/common";
import React, { ChangeEvent, FC } from "react";
import { Controller, UseFormReturn } from "react-hook-form";

import styled from "styled-components";

import { InputState } from "../formUtils/zodFormSchema";

import { SelectForm, TextField, Typography } from "~/components/uiParts";

export const AddressInputPart: FC<{ form: UseFormReturn<InputState> }> = ({ form }) => {
  const { formState } = form;
  const hasError = Object.keys(formState.errors).length > 0;
  return (
    <Box>
      <Box mb="16px" display="flex" alignItems="center">
        <Typography variant="body2" bold>
          住所
        </Typography>
      </Box>
      <Box
        display={"grid"}
        gridTemplateColumns={"100px 1fr"}
        gridRowGap={hasError ? "32px" : "16px"}
      >
        <Box display={"flex"} alignItems={"center"}>
          <Typography variant="body2">郵便番号</Typography>
        </Box>
        <Box display="flex" alignItems="center" mr="8px" height={"40px"}>
          <Controller
            name="postCode"
            control={form.control}
            render={({ field, fieldState }) => {
              const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
                field.onChange(e.target.value);
                form.trigger("postCode");
              };
              return (
                <StyledTextField
                  {...field}
                  fullWidth
                  variant="outlined"
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                  onChange={onChange}
                />
              );
            }}
          />
        </Box>
        <Box display={"flex"} alignItems={"center"}>
          <Typography variant="body2">都道府県</Typography>
        </Box>
        <Box display="flex" alignItems="center" mr="8px" height={"40px"}>
          <Controller
            name="state"
            control={form.control}
            render={({ field, fieldState }) => {
              const onChange = (e: ChangeEvent<{ name?: string | undefined; value: unknown }>) => {
                field.onChange(e.target.value);
                form.trigger("state");
              };
              return (
                <StyledSelectForm
                  {...field}
                  menuItems={prefectures
                    .map((prefectureLabel) => ({
                      name: prefectureLabel,
                      value: prefectureLabel,
                    }))
                    .concat({ name: "都道府県を選択してください", value: "" })}
                  fullWidth
                  selected={field.value}
                  onChange={onChange}
                  errorBorder={!!fieldState.error}
                  errorText={fieldState.error?.message}
                />
              );
            }}
          />
        </Box>
        <Box display={"flex"} alignItems={"center"}>
          <Typography variant="body2">市町村区</Typography>
        </Box>
        <Box display="flex" alignItems="center" mr="8px" height={"40px"}>
          <Controller
            name="city"
            control={form.control}
            render={({ field, fieldState }) => {
              const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
                field.onChange(e.target.value);
                form.trigger("city");
              };
              return (
                <StyledTextField
                  {...field}
                  fullWidth
                  variant="outlined"
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                  onChange={onChange}
                />
              );
            }}
          />
        </Box>
        <Box display={"flex"} alignItems={"center"}>
          <Typography variant="body2">番地</Typography>
        </Box>
        <Box display="flex" alignItems="center" mr="8px" height={"40px"}>
          <Controller
            name="address1"
            control={form.control}
            render={({ field, fieldState }) => {
              const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
                field.onChange(e.target.value);
                form.trigger("address1");
              };
              return (
                <StyledTextField
                  {...field}
                  fullWidth
                  variant="outlined"
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                  onChange={onChange}
                />
              );
            }}
          />
        </Box>
        <Box display={"flex"} alignItems={"center"}>
          <Typography variant="body2">建物名</Typography>
        </Box>
        <Box display="flex" alignItems="center" mr="8px" height={"40px"}>
          <Controller
            name="address2"
            control={form.control}
            render={({ field, fieldState }) => {
              const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
                field.onChange(e.target.value);
                form.trigger("address2");
              };
              return (
                <StyledTextField
                  {...field}
                  fullWidth
                  variant="outlined"
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                  onChange={onChange}
                />
              );
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};

const StyledTextField = styled(TextField)`
  height: 100%;
  .MuiInputBase-root {
    height: 100%;
  }
`;

const StyledSelectForm = styled(SelectForm)`
  .MuiInputBase-formControl {
    min-width: 100px;
    height: 40px;
    font-size: 16px;
  }
  .MuiTypography-root {
    font-size: 16px;
    color: ${({ theme }) => theme.palette.text.primary};
  }
`;
