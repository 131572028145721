import { Box } from "@material-ui/core";
import React, { FC } from "react";
import styled from "styled-components";

import { Icon, Tooltip, Typography } from "~/components/uiParts";

export const EventDescriptionCell: FC<{ description: string }> = ({ description }) => {
  return (
    <CellWrapper p={"1px"}>
      <Tooltip title={description}>
        <Typography variant="body2" color="textSecondary">
          <Icon icon="line" size="md" color="grey" />
        </Typography>
      </Tooltip>
    </CellWrapper>
  );
};

const CellWrapper = styled(Box)<{ bgcolor?: string }>`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.bgcolor};
  border: solid ${(props) => props.theme.palette.grey[100]};
  border-width: 1px 1px 0px 0px;
`;
