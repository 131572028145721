import { Box } from "@material-ui/core";
import { OnnEvent, OnnEventDeterminedDate, CandidateDate } from "@onn/common";
import { format, isValid, parse, set } from "date-fns";
import React, { FC, useCallback, useMemo } from "react";
import { Controller } from "react-hook-form";
import styled from "styled-components";

import { SelectMenu } from "../../employees";

import { useAnswerInterviewEventOnBehalfForm } from "./hooks/useAnswerInterviewEventOnBehalfForm";

import {
  Button,
  Typography,
  Modal,
  Tooltip,
  Icon,
  FormControlLabel,
  RadioButton,
  TextareaAutosize,
  TextField,
  DatePickerV2,
  SelectForm,
  Loading,
} from "~/components/uiParts";
import { useAdmins, useAllNewcomers, useCurrentUser } from "~/hooks/employee";
import { useDeterminedDate } from "~/hooks/onnEvent";
import { useTenantSettings } from "~/hooks/tenantSetting";
import { mixin } from "~/util";
import { getTimeMenuItems } from "~/util/getTimeMenuItems";

type Props = {
  open: boolean;
  onCancel: () => void;
  onnEvent: OnnEvent;
  mode:
    | {
        type: "create";
      }
    | {
        type: "edit";
        onnEventDeterminedDate: OnnEventDeterminedDate;
        candidateDate: CandidateDate;
      };
  selectedEmployeeId?: string;
  additionalMutate?: () => void;
};

export const AnswerInterviewEventOnBehalfModal: FC<Props> = ({
  open,
  mode,
  onCancel,
  onnEvent,
  selectedEmployeeId,
  additionalMutate,
}) => {
  const { currentUser } = useCurrentUser();
  const { data: allNewComers, isLoading: isLoadingAllNewcomers } = useAllNewcomers();
  const { data: admins, isLoading: isLoadingAdmins } = useAdmins(currentUser.tenantId);
  const { tenantSettings } = useTenantSettings();

  const { data: determinedDates } = useDeterminedDate({
    onnEventId: onnEvent.id,
  });

  const selectableNewGraduates = useMemo(() => {
    const alreadyAnsweredEmployeeIds = determinedDates?.map((v) => v.employeeId) || [];
    if (!allNewComers) return [];
    return allNewComers.filter((newGraduate) => {
      if (newGraduate.isNotRegisteredAndInvited()) return false;

      if (mode.type === "edit" && newGraduate.id === mode.onnEventDeterminedDate.employeeId) {
        return true;
      }

      if (newGraduate.isRejectedOrWithdrew()) return false;
      if (alreadyAnsweredEmployeeIds.includes(newGraduate.id)) return false;

      return true;
    });
  }, [allNewComers, determinedDates, mode]);

  const selectableNewGraduatesMap = new Map(
    selectableNewGraduates.map((employee) => [employee.id, employee])
  );
  const adminsMap = new Map((admins || []).map((employee) => [employee.id, employee]));

  const { control, trigger, handleSubmit, watch, isLoading, formState, getValues, setValue } =
    useAnswerInterviewEventOnBehalfForm({
      onnEvent,
      current: mode.type === "edit" ? mode : undefined,
      onSubmit: onCancel,
      additionalMutate,
      selectedEmployeeId,
      eventFormatTemplates: tenantSettings.eventFormatTemplates,
    });

  const watchEventFormatType = watch("eventFormat.type");

  const syncDate = useCallback((dateToUpdate: Date, date: Date) => {
    return set(dateToUpdate, {
      year: date.getFullYear(),
      month: date.getMonth(),
      date: date.getDate(),
    });
  }, []);

  const handleChangeDate = useCallback(
    (date: Date) => {
      const from = watch("candidateDate.from");
      const until = watch("candidateDate.until");
      from && setValue("candidateDate.from", syncDate(from, date));
      until && setValue("candidateDate.until", syncDate(until, date));
    },
    [setValue, syncDate, watch]
  );

  const Content = (
    <form onSubmit={handleSubmit}>
      <Box>
        <Box display="flex" gridGap="12px" alignItems="center">
          <Typography variant="body2" bold>
            候補者
          </Typography>
          {mode.type === "create" && (
            <Tooltip
              title="「現在配信対象になっていない候補者(辞退・不合格を除く)」と「配信対象のうち未回答の候補者」の選択が可能です。"
              placement="top-start"
            >
              <Icon icon="help" size="sm" color="grey" />
            </Tooltip>
          )}
        </Box>
        <Box mt="12px">
          {mode.type === "create" && !selectedEmployeeId ? (
            <Controller
              name="employeeId"
              control={control}
              render={({ field: { onChange, value } }) =>
                // NOTE: SelectMenuは検索対象のEmployeeが全て出揃っていること前提のコンポーネント
                isLoadingAllNewcomers ? (
                  <Loading size="small" />
                ) : (
                  <SelectMenu
                    selectedEmployee={value ? selectableNewGraduatesMap.get(value) : undefined}
                    selectEmployee={(employee) => onChange(employee?.id)}
                    employees={selectableNewGraduates}
                    isMultiple={false}
                  />
                )
              }
            />
          ) : (
            <Typography>
              {selectableNewGraduatesMap.get(getValues("employeeId"))?.getName()}
            </Typography>
          )}
        </Box>
        <Box mt="32px" display="flex" gridGap="12px" alignItems="center">
          <Typography variant="body2" bold>
            選考担当者
          </Typography>
          <Tooltip
            title="Onnの管理者アカウントを持っているメンバーのみ表示されます。"
            placement="top-start"
          >
            <Icon icon="help" size="sm" color="grey" />
          </Tooltip>
        </Box>
        <Box mt="12px">
          <Controller
            name="assigneeId"
            control={control}
            render={({ field: { onChange, value } }) =>
              // NOTE: SelectMenuは検索対象のEmployeeが全て出揃っていること前提のコンポーネント
              isLoadingAdmins ? (
                <Loading size="small" />
              ) : (
                <SelectMenu
                  selectedEmployee={value ? adminsMap.get(value) : undefined}
                  selectEmployee={(employee) => onChange(employee?.id)}
                  employees={admins || []}
                  isMultiple={false}
                />
              )
            }
          />
        </Box>
        <Box mt="32px" display="flex" gridGap="12px" alignItems="center">
          <Typography variant="body2" bold>
            実施日程
          </Typography>
        </Box>
        <Box mt="12px">
          <Box display="flex" minHeight="50px" mb="16px">
            <Box mr="16px" width="200px">
              <Controller
                name="date"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <StyledDatePicker
                    fullWidth
                    placeholder="日程を選択"
                    value={value}
                    onChange={(date) => {
                      // NOTE: jsでは型でInvalidDateを表現できないため、isValidでチェックしている
                      if (!date || !isValid(date)) return;
                      handleChangeDate(date);
                      onChange(date);
                    }}
                  />
                )}
              />
            </Box>
            <Box mr="8px">
              <Controller
                name="candidateDate.from"
                control={control}
                render={({ field, fieldState }) => (
                  <StyledSelectForm
                    {...field}
                    icon="clock"
                    menuItems={getTimeMenuItems("15_MINUTE")}
                    selected={
                      watch("candidateDate.from")
                        ? format(watch("candidateDate.from"), "H:mm")
                        : null
                    }
                    onChange={(e) => {
                      field.onChange(parse(e.target.value as string, "H:mm", watch("date")));
                      trigger("candidateDate.until");
                    }}
                    errorBorder={!!fieldState.error}
                    errorText={fieldState.error?.message}
                  />
                )}
              />
            </Box>
            <Box mr="8px" mt="12px">
              <Typography color="textSecondary">〜</Typography>
            </Box>
            <Box mr="16px">
              <Controller
                name="candidateDate.until"
                control={control}
                render={({ field, fieldState }) => (
                  <StyledSelectForm
                    {...field}
                    icon="clock"
                    menuItems={getTimeMenuItems("15_MINUTE")}
                    selected={
                      watch("candidateDate.until")
                        ? format(watch("candidateDate.until"), "H:mm")
                        : null
                    }
                    onChange={(e) => {
                      field.onChange(parse(e.target.value as string, "H:mm", watch("date")));
                      trigger("candidateDate.from");
                    }}
                    errorBorder={!!fieldState.error}
                    errorText={fieldState.error?.message}
                  />
                )}
              />
            </Box>
          </Box>
        </Box>
        <Box mt="32px" display="flex" gridGap="12px" alignItems="center">
          <Typography variant="body2" bold>
            開催方法
          </Typography>
          <Tooltip
            title="開催方法に記載される概要は日程確定後、候補者側からいつでも確認可能になります。候補者へリマインドが送られる「開催日程の前日」までに概要の追加／編集を完了してください。"
            placement="top-start"
          >
            <Icon icon="help" size="sm" color="grey" />
          </Tooltip>
        </Box>
        <Box my="12px" display="flex" flexDirection="column" gridGap="24px">
          <Box>
            <Controller
              name="eventFormat.type"
              control={control}
              render={({ field: { onChange, value } }) => (
                <StyledFormControlLabel
                  value="online"
                  control={
                    <StyledRadioButton
                      color="primary"
                      defaultColor={value === "online" ? "primary" : "default"}
                      checked={value === "online"}
                      onChange={(e) => onChange(e.target.value)}
                    />
                  }
                  label={<Typography variant="body2">オンライン</Typography>}
                />
              )}
            />
            {watchEventFormatType === "online" && (
              <Box mt="12px" pl="28px" gridGap="8px">
                <Box display="flex" alignItems="center" gridGap="16px">
                  <Icon icon="line" size="md" color="lightGrey" />
                  <Controller
                    name="eventFormat.descriptionForOnline"
                    control={control}
                    render={({ field, fieldState }) => (
                      <TextareaAutosize
                        {...field}
                        fullWidth
                        placeholder="開催概要を入力"
                        minRows={3}
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                      />
                    )}
                  />
                </Box>
                <Box display="flex" alignItems="center" gridGap="16px">
                  <Icon icon="link" size="md" color="lightGrey" />
                  <Controller
                    name="eventFormat.urlForOnline"
                    control={control}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        fullWidth
                        variant="outlined"
                        placeholder="オンライン会議ツールのURL"
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                      />
                    )}
                  />
                </Box>
              </Box>
            )}
          </Box>
          <Box>
            <Controller
              name="eventFormat.type"
              control={control}
              render={({ field: { onChange, value } }) => (
                <StyledFormControlLabel
                  value="offline"
                  control={
                    <StyledRadioButton
                      color="primary"
                      defaultColor={value === "offline" ? "primary" : "default"}
                      checked={value === "offline"}
                      onChange={(e) => onChange(e.target.value)}
                    />
                  }
                  label={<Typography variant="body2">オフライン・対面</Typography>}
                />
              )}
            />
            {watchEventFormatType === "offline" && (
              <Box mt="12px" pl="28px">
                <Box display="flex" alignItems="center" gridGap="16px">
                  <Icon icon="line" size="md" color="lightGrey" />
                  <Controller
                    name="eventFormat.descriptionForOffline"
                    control={control}
                    render={({ field, fieldState }) => (
                      <TextareaAutosize
                        {...field}
                        fullWidth
                        placeholder="開催概要を入力"
                        minRows={3}
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                      />
                    )}
                  />
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
      <StyledButtonContainer>
        <Button
          fullWidth
          borderRadius="circle"
          variant="outlined"
          color="default"
          onClick={onCancel}
        >
          キャンセル
        </Button>
        <Button
          type="submit"
          fullWidth
          borderRadius="circle"
          variant="contained"
          color="primary"
          isLoading={isLoading}
          // NOTE: 編集の場合はformに変更があるかどうかもチェックする必要がある
          disabled={!formState.isValid || (mode.type === "edit" && !formState.isDirty)}
        >
          回答を保存
        </Button>
      </StyledButtonContainer>
    </form>
  );

  return (
    <Modal
      open={open}
      title={mode.type === "create" ? "回答追加" : "編集"}
      content={Content}
      onCancel={onCancel}
    />
  );
};

const StyledButtonContainer = styled(Box)`
  margin-top: 72px;
  ${mixin.fixedWidthButtonContainer}
`;

const StyledFormControlLabel = styled(FormControlLabel)`
  &.MuiFormControlLabel-root {
    height: 24px;
    margin-left: 0px;
  }
  .MuiFormControlLabel-label {
    overflow: hidden;
  }
`;

const StyledRadioButton = styled(RadioButton)`
  padding: 0;
  margin-right: 8px;
`;

const StyledDatePicker = styled(DatePickerV2)`
  .MuiFormHelperText-root {
    width: 228px;
  }
`;

const StyledSelectForm = styled(SelectForm)`
  .MuiInputBase-formControl {
    min-width: 100px;
    height: 50px;
  }
`;
