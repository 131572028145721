import { OnnEventPlace } from "@onn/common";
import React, { FC } from "react";

import { useDeletePlaceConfirmModal } from "../hooks/useDeletePlaceConfirmModal";

import { useUpdateEventPlaceModal } from "../hooks/useUpdateEventPlaceModal";

import { IconButton, Typography, UncontrolledMenu } from "~/components/uiParts";
import { TableRowWrapper } from "~/components/uiParts/VirtualizedTable";
import { TableRow } from "~/components/uiParts/VirtualizedTable/TableRow";
import { VirtualizedTableV2 } from "~/components/uiParts/VirtualizedTable/VirtualizedTableV2";

type Props = {
  isLoading: boolean;
  onnEventPlaces: OnnEventPlace[];
};

export const PlaceTable: FC<Props> = ({ isLoading, onnEventPlaces }) => {
  const { openDeletePlaceConfirmModal } = useDeletePlaceConfirmModal();
  const { openUpdateEventPlaceModal } = useUpdateEventPlaceModal();

  const widthOptions = ["40%", "50%", "10%"];

  return (
    <VirtualizedTableV2<OnnEventPlace>
      isLoading={isLoading}
      widthOptions={widthOptions}
      headers={[
        {
          text: "会場名",
        },
        {
          text: "住所",
        },
        {
          text: "",
        },
      ]}
      rows={onnEventPlaces}
      rowRenderer={({ key, index, style, rowData: onnEventPlace }) => {
        const contents = [
          <Typography key={`placeName-${onnEventPlace.id}`} variant="body2" color="textPrimary">
            {onnEventPlace.name}
          </Typography>,
          <Typography key={`address-${onnEventPlace.id}`} variant="caption" color="textPrimary">
            {`${onnEventPlace.postCode} ${onnEventPlace.getFullAddress()}`}
          </Typography>,
          <UncontrolledMenu
            key={`menu-${onnEventPlace.id}`}
            menuItemOptions={[
              { text: "編集", onClick: () => openUpdateEventPlaceModal(onnEventPlace) },
              { text: "削除", onClick: () => openDeletePlaceConfirmModal(onnEventPlace) },
            ]}
            renderButton={(openMenu) => <IconButton icon="menuVert" onClick={openMenu} />}
          />,
        ];
        return (
          <TableRowWrapper key={key} index={index} {...style}>
            <TableRow
              row={{
                contents,
              }}
              widthOptions={widthOptions}
            />
          </TableRowWrapper>
        );
      }}
    />
  );
};
