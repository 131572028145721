import { Box } from "@material-ui/core";
import { OnnEvent } from "@onn/common";

import React, { FC, useCallback } from "react";

import { OnnEventSlotDatesMultiGrid } from "./Table/OnnEventSlotDatesMultiGrid";

import { Button, Icon } from "~/components/uiParts";
import { useCurrentUser } from "~/hooks/employee";
import { useModal } from "~/hooks/modal";
import { useOnnEventAnswersForDisplay } from "~/hooks/onnEventAnswer/useOnnEventAnswersForDisplay";

type Props = {
  onnEvent: OnnEvent;
};

export const TabPage: FC<Props> = ({ onnEvent }) => {
  const { currentUser } = useCurrentUser();
  const { data: onnEventAnswersForDisplay = [], isLoading } = useOnnEventAnswersForDisplay(
    onnEvent.id
  );

  return (
    <Box pt="40px" display="flex" flexDirection="column" gridRowGap="32px">
      <Box display="flex" gridGap={24} alignItems="flex-end" justifyContent="end">
        <Box display={"flex"} gridColumnGap={"24px"}>
          {currentUser.isAdmin() && <AddSlotButton onnEvent={onnEvent} />}
        </Box>
      </Box>
      <OnnEventSlotDatesMultiGrid
        onnEventAnswersForDisplay={onnEventAnswersForDisplay}
        isLoading={isLoading}
        onnEvent={onnEvent}
      />
    </Box>
  );
};

const AddSlotButton: FC<{ onnEvent: OnnEvent }> = ({ onnEvent }) => {
  const { handleModal } = useModal();

  const openCreateOrEdiOnnEventSlotsModal = useCallback(() => {
    handleModal({
      name: "answerNewInterviewEventOnBehalfModal",
      args: {
        mode: {
          type: "create",
        },
        onnEventId: onnEvent.id,
        slotDefaultValueSetting: onnEvent.slotDefaultValueSetting,
      },
    });
  }, [handleModal, onnEvent]);

  return (
    <Button
      onClick={openCreateOrEdiOnnEventSlotsModal}
      color="default"
      variant="outlined"
      borderRadius="regular"
      startIcon={<Icon size="sm" icon="add" color="grey400" />}
    >
      代理予約
    </Button>
  );
};
