import { useCallback } from "react";

import { useSelectableNewGraduatesForAnswerInterviewEvent } from "~/hooks/onnEvent/useSelectableNewGraduatesForAnswerInterviewEvent";

export const useGenerateSampleCSV = ({ onnEventId }: { onnEventId: string }) => {
  const { selectableNewGraduates, isLoading } = useSelectableNewGraduatesForAnswerInterviewEvent({
    onnEventId,
  });

  const generateSampleCSV = useCallback(() => {
    return [
      ["「Onn固有ID」コピー欄", "名前", "メールアドレス"],
      ...selectableNewGraduates.map((v) => {
        return [v.uniqueId, v.getName(), v.email];
      }),
    ];
  }, [selectableNewGraduates]);

  return { generateSampleCSV, isLoading };
};
