import { Menu, MenuItem } from "@material-ui/core";
import React, { useState, FC, MouseEvent, useCallback } from "react";

import { IconButton, Typography } from "~/components/uiParts";

type Props = {
  openManageContactTeamModal: () => void;
};

export const DetailMenu: FC<Props> = ({ openManageContactTeamModal }) => {
  const [anchorEllipsisEl, setAnchorEllipsisEl] = useState<HTMLElement | null>(null);

  const handleOpenEllipsisMenu = useCallback((event: MouseEvent<HTMLElement>) => {
    setAnchorEllipsisEl(event.currentTarget);
  }, []);

  const handleCloseEllipsisMenu = useCallback(() => {
    setAnchorEllipsisEl(null);
  }, []);

  return (
    <>
      <IconButton icon="menuVert" onClick={handleOpenEllipsisMenu} />
      <Menu
        anchorEl={anchorEllipsisEl}
        keepMounted
        open={Boolean(anchorEllipsisEl)}
        onClose={handleCloseEllipsisMenu}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MenuItem onClick={openManageContactTeamModal}>
          <Typography variant="body2">対応チーム設定</Typography>
        </MenuItem>
      </Menu>
    </>
  );
};
