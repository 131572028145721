import { Employee } from "@onn/common";
import { IEmployeeInformationValue } from "@onn/common/domain/EmployeeInformation/schema";
import { useCallback } from "react";

import { apiClient } from "~/libs";

export const useUpdateEmployeeInformations = () => {
  const execUpdateEmployeeInformations = useCallback(
    async ({
      data,
    }: {
      data: {
        uniqueId: string;
        employeeName: Partial<Pick<Employee, "lastName" | "firstName">>;
        employeeInformationValue: IEmployeeInformationValue;
      }[];
    }): Promise<void> => {
      await apiClient.post("/post_employee_informations", {
        data,
      });
    },
    []
  );

  return { execUpdateEmployeeInformations };
};
