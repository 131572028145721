import { Box } from "@material-ui/core";
import { Employee, EmployeeTag } from "@onn/common";
import React, { useCallback, useMemo, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";

import { EmployeeTagDetailSummary } from "./EmployeeTagDetailSummary";
import { TaggedEmployeesTable } from "./TaggedEmployeesTable";

import { Loading, Icon, Button } from "~/components/uiParts";

import { useCurrentUser, useEmployee } from "~/hooks/employee";
import { useAddTagToEmployees } from "~/hooks/employee/useAddTagToEmployees";
import { useRemoveTagFromEmployee } from "~/hooks/employee/useRemoveTagFromEmployee";
import { useEmployeeTags } from "~/hooks/employeeTag";
import { useEmployeeTag, useMutateEmployeeTag } from "~/hooks/employeeTag/useEmployeeTag";
import { useMutateEmployeeTags } from "~/hooks/employeeTag/useEmployeeTags";
import {
  useMutateTaggedEmployees,
  useTaggedEmployees,
} from "~/hooks/employeeTag/useTaggedEmployees";
import { useModal } from "~/hooks/modal";
import { useFilterObjectByPartialMatch, useSnackbar } from "~/hooks/shared";
import { useDeleteEmployeeTag, useUpdateEmployeeTag } from "~/hooks/tenantSetting";
import { NotFound } from "~/pages/NotFound";

export const EmployeeTagsDetailPage = () => {
  const { id: employeeTagId = "" } = useParams<"id">();
  const { data: employeeTagData, isLoading: isLoadingEmployeeTagData } =
    useEmployeeTag(employeeTagId);
  const { data: employeeTagsData, isLoading: isLoadingEmployeeTagsData } = useEmployeeTags();
  const { data: taggedEmployeesData, isLoading: isLoadingTaggedEmployeesData } =
    useTaggedEmployees(employeeTagId);
  const { data: creator, isLoading: isLoadingCreator } = useEmployee(
    employeeTagData?.data.creatorId ?? ""
  );

  const isLoading =
    isLoadingEmployeeTagData ||
    isLoadingTaggedEmployeesData ||
    isLoadingCreator ||
    isLoadingEmployeeTagsData;
  if (isLoading) {
    return <Loading size="large" fullHeight />;
  }

  if (!employeeTagData || !taggedEmployeesData || !creator || !employeeTagsData) {
    return <NotFound />;
  }

  return (
    <PageCore
      employeeTag={employeeTagData.data}
      employeeTags={employeeTagsData.employeeTags}
      relationsCount={employeeTagData.meta.relationsCount}
      creator={creator}
      taggedEmployees={taggedEmployeesData.taggedEmployees ?? []}
      employeeIdToTaggedAtMap={taggedEmployeesData.meta.employeeIdToTaggedAtMap}
    />
  );
};

const PageCore = ({
  employeeTag,
  employeeTags,
  creator,
  taggedEmployees,
  relationsCount,
  employeeIdToTaggedAtMap,
}: {
  relationsCount: number;
  employeeTag: EmployeeTag;
  employeeTags: EmployeeTag[];
  creator: Employee;
  taggedEmployees: Employee[];
  employeeIdToTaggedAtMap: Record<string, string>;
}) => {
  const [searchText, setSearchText] = useState("");
  const [selectedSpaceIds, setSelectedSpaceIds] = useState<string[]>([]);
  const { filterObjectByPartialMatch } = useFilterObjectByPartialMatch();

  const handleSearchValue = useCallback((value: string) => {
    setSearchText(value);
  }, []);

  /**
   * スペースの絞り込み結果
   */
  const employeesFilteredBySpaceIds = useMemo(() => {
    if (!selectedSpaceIds.length) return taggedEmployees;
    return taggedEmployees.filter(
      (employee) => employee.spaceId && selectedSpaceIds.includes(employee.spaceId)
    );
  }, [selectedSpaceIds, taggedEmployees]);

  const filteredEmployees = useMemo(() => {
    return filterObjectByPartialMatch<Employee>({
      objects: employeesFilteredBySpaceIds,
      searchText,
      getProperties: [
        (employee: Employee) => employee.getName(),
        (employee: Employee) => employee.email,
      ],
    });
  }, [filterObjectByPartialMatch, searchText, employeesFilteredBySpaceIds]);

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { handleModal } = useModal();
  const { currentUser } = useCurrentUser();
  const { deleteEmployeeTag } = useDeleteEmployeeTag();
  const { updateEmployeeTag } = useUpdateEmployeeTag();
  const { addTagToEmployees } = useAddTagToEmployees();
  const { removeTagFromEmployee } = useRemoveTagFromEmployee();

  const { mutateEmployeeTag } = useMutateEmployeeTag();
  const { mutateEmployeeTags } = useMutateEmployeeTags();
  const { mutateTaggedEmployees } = useMutateTaggedEmployees();

  const mutateRelatedData = useCallback(() => {
    mutateEmployeeTag(employeeTag.id);
    mutateEmployeeTags();
    mutateTaggedEmployees(employeeTag.id);
  }, [employeeTag.id, mutateEmployeeTag, mutateEmployeeTags, mutateTaggedEmployees]);

  const handleClickRemoveTag = useCallback(
    async (employee: Employee) => {
      await removeTagFromEmployee(employeeTag.id, employee.id);
      mutateRelatedData();
      enqueueSnackbar("タグの対象者から削除しました", { variant: "success" });
    },
    [employeeTag.id, mutateRelatedData, removeTagFromEmployee, enqueueSnackbar]
  );

  const handleOpenEdit = useCallback(() => {
    handleModal({
      name: "addOrEditTagModal",
      args: {
        mode: "edit",
        selectedTag: employeeTag,
        onSubmit: async (newTag) => {
          await updateEmployeeTag(
            currentUser.tenantId,
            employeeTag.update({ name: newTag.name })
          ).then(() => {
            mutateRelatedData();
          });
        },
        employeeTags,
      },
    });
  }, [
    handleModal,
    employeeTag,
    employeeTags,
    updateEmployeeTag,
    currentUser.tenantId,
    mutateRelatedData,
  ]);

  const handleOpenCsvUpload = useCallback(() => {
    handleModal({
      name: "addTagToEmployeesByCSVModal",
      args: {
        tagId: employeeTag.id,
        onSubmit: async ({ tagId, emails }: { tagId: string; emails: string[] }) =>
          await addTagToEmployees(tagId, emails).then(() => mutateEmployeeTags()),
      },
    });
  }, [employeeTag.id, handleModal, addTagToEmployees, mutateEmployeeTags]);

  const handleOpenAssign = useCallback(() => {
    handleModal({
      name: "assignTagToEmployeesModal",
      args: {
        tagId: employeeTag.id,
        onSubmit: async (employeeIds) =>
          await addTagToEmployees(employeeTag.id, employeeIds).then(() => mutateRelatedData()),
        onClickCsvUpload: handleOpenCsvUpload,
      },
    });
  }, [handleModal, employeeTag.id, addTagToEmployees, mutateRelatedData, handleOpenCsvUpload]);

  const handleOpenDelete = useCallback(() => {
    handleModal({
      name: "confirmDeleteTagModal",
      args: {
        deleteTagName: employeeTag.name,
        onAccept: async () => {
          await deleteEmployeeTag(currentUser.tenantId, employeeTag.id).then(() => {
            mutateEmployeeTags();
            navigate("/settings/admin#tag");
          });
        },
      },
    });
  }, [
    handleModal,
    employeeTag.name,
    employeeTag.id,
    deleteEmployeeTag,
    currentUser.tenantId,
    mutateEmployeeTags,
    navigate,
  ]);

  return (
    <StyledBox p={5} width="100%" display="flex" flexDirection="column" alignItems="center">
      <Box width="100%" maxWidth="1090px">
        <StyledLink to="/settings/admin#tag">
          <Button variant="text" borderRadius="regular" color="default">
            <StyledReverseIcon icon="arrowRight" size="sm" color="grey" />
            タグ管理
          </Button>
        </StyledLink>
        <Box mb="48px">
          <EmployeeTagDetailSummary
            creator={creator}
            employeeTag={employeeTag}
            numberOfTaggedEmployees={relationsCount}
            onClickEdit={handleOpenEdit}
            onClickAssign={handleOpenAssign}
            onClickDelete={handleOpenDelete}
          />
        </Box>

        <TaggedEmployeesTable
          onSearchValue={handleSearchValue}
          onOpenAssign={handleOpenAssign}
          taggedEmployees={filteredEmployees}
          employeeIdToTaggedAtMap={employeeIdToTaggedAtMap}
          onClickRemoveTag={handleClickRemoveTag}
          selectedSpaceIds={selectedSpaceIds}
          setSelectedSpaceIds={setSelectedSpaceIds}
          employeeTag={employeeTag}
          AllTaggedEmployees={taggedEmployees}
        />
      </Box>
    </StyledBox>
  );
};
const StyledLink = styled(Link)`
  display: inline-block;
  text-decoration: none;
  margin-bottom: 48px;
`;

const StyledReverseIcon = styled(Icon)`
  transform: rotate(180deg);
  margin-right: 8px;
`;

const StyledBox = styled(Box)`
  min-height: 100vh;
  background: ${(props) => props.theme.palette.grey[50]};
`;
