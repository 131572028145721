import liff from "@line/liff";
import { Box } from "@material-ui/core";
import { FileQuestion } from "@onn/common";
import React, { FC, memo } from "react";

import { v4 } from "uuid";

import { useCreateFileQuestionFilePath } from "../../hooks/useCreateFileQuestionFilePath";
import { Action } from "../../reducer";

import { AnswerBaseBox } from "./AnswerBaseBox";

import { FilePickerButton } from "~/components/domains/onnTasks";
import { Button, Divider, Icon, Typography } from "~/components/uiParts";
import { Payload } from "~/components/uiParts/FilePicker/FilePicker";
import { useMetaDataByUrls } from "~/hooks/file";
import { useFileViewer } from "~/hooks/shared";

export const FileQuestionForm: FC<{
  onnFormTaskId: string;
  question: FileQuestion;
  dispatch: React.Dispatch<Action>;
  answer: { fileName?: string };
  sampleFileSignedUrl?: string;
}> = ({ question, dispatch, answer: { fileName }, onnFormTaskId, sampleFileSignedUrl }) => {
  const { handleDownloadFile } = useFileViewer();

  const shouldFetchMetaData = question.sampleFilePath ? true : false;
  const { createFileQuestionFilePath } = useCreateFileQuestionFilePath();

  const { data: files, isLoading } = useMetaDataByUrls(
    shouldFetchMetaData ? [question.sampleFilePath] : []
  );

  const onClickDownloadFile = () => {
    if (!files) return;
    if (files.length === 0) return;

    if (liff.isInClient() && sampleFileSignedUrl) {
      liff.openWindow({
        url: sampleFileSignedUrl,
        external: true,
      });
      return;
    }
    handleDownloadFile((files[0] as (typeof files)[number]).url, files);
  };

  const onChangeFile = (payload: Payload) => {
    if (payload.status === "success") {
      dispatch({
        type: "CHANGE_FILE_ANSWER",
        payload: {
          questionId: question.id,
          file: payload.files[0] as (typeof payload.files)[number],
          filePath: createFileQuestionFilePath({
            uuidV4: v4(),
            questionId: question.id,
            onnFormTaskId,
            fileName: (payload.files[0] as (typeof payload.files)[number]).name,
          }),
        },
      });
    }
  };

  return (
    <AnswerBaseBox questionTitle={question.title} isRequired={question.isRequired}>
      {question.sampleFilePath && (
        <TemplateDownloadMemo onClick={onClickDownloadFile} isLoading={isLoading} />
      )}
      <Box
        mb="32px"
        sx={{
          display: "flex",
          alignItems: "center",
          gridGap: "8px",
        }}
      >
        <FilePickerButton onChange={onChangeFile} />
        <Typography variant="caption" color="textSecondary" style={{ lineBreak: "anywhere" }}>
          {fileName ? fileName : ""}
        </Typography>
      </Box>
    </AnswerBaseBox>
  );
};

const TemplateDownloadMemo = memo<{ onClick: () => void; isLoading: boolean }>(
  ({ onClick, isLoading }) => (
    <Box>
      <Typography color="textSecondary" variant="caption">
        添付のテンプレートファイルをダウンロードして、必要事項を記入の上アップロードしてください。
      </Typography>
      <Box my="16px">
        <Button
          startIcon={<Icon icon="download" size="sm" color="primary" />}
          color={"primary"}
          borderRadius={"regular"}
          variant={"text"}
          onClick={onClick}
          isLoading={isLoading}
        >
          テンプレートファイルをダウンロード
        </Button>
      </Box>
      <Box mb="16px">
        <Divider />
      </Box>
    </Box>
  )
);
