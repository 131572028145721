export const EmployeeInformationErrorType = {
  OVER_THE_LIMIT: "OVER_THE_LIMIT",
  NO_UNIQUE_ID: "NO_UNIQUE_ID",
  NOT_EXIST_UNIQUE_ID: "NOT_EXIST_UNIQUE_ID",
  INVALID_FORMAT_GENDER: "INVALID_FORMAT_GENDER",
  INVALID_FORMAT_DATE_OR_BIRTH: "INVALID_FORMAT_DATE_OR_BIRTH",
  INVALID_FORMAT_GRADUATION_YEAR: "INVALID_FORMAT_GRADUATION_YEAR",
  INVALID_FORMAT_GRADUATION_MONTH: "INVALID_FORMAT_GRADUATION_MONTH",
  INVALID_FORMAT_ADDRESS_PREFECTURE: "INVALID_FORMAT_ADDRESS_PREFECTURE",
  INVALID_FORMAT_HOMETOWN_ADDRESS_PREFECTURE: "INVALID_FORMAT_HOMETOWN_ADDRESS_PREFECTURE",
} as const;

type ErrorTypes = keyof typeof EmployeeInformationErrorType;

export type EmployeeInformationErrorStatus = Map<ErrorTypes, number[]>;
