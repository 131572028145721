import { Box, Tab } from "@material-ui/core";
import { TabContext, TabList } from "@material-ui/lab";

import { AllContactRoom, LatestContactMessage } from "@onn/common";
import React, { FC, useMemo } from "react";
import { WindowScroller } from "react-virtualized";
import styled from "styled-components";

import { useViewModelV2 } from "./hooks/useViewModelV2";

import {
  NewGraduateSPContactRoomItem,
  ContactRoomListItemV2,
} from "~/components/domains/contactRooms";
import {
  Checkbox,
  FormControlLabel,
  Icon,
  Loading,
  TextField,
  Typography,
} from "~/components/uiParts";
import { AutoSizer, List as VirtualizedList } from "~/components/uiParts/ReactVirtualized";
import { captureException } from "~/util";

// 自分自身が対象となっているルームは取得しない
// 入社者のユーザーでログインし、他の入社者のコンタクトルームの閲覧権限を有する場合に表示されてしまうことを防ぐ
export const NewGraduateSPContactMessages: FC = () => {
  const {
    currentContactRooms,
    searchValue,
    handleChangeSearchValue,
    activeRecruitmentStatusTab,
    isDisplayOnlyMyCandidates,
    isLoadingContactRooms,
    selectedContactRoomId,
    isLoadingLatestMessages,
    isLoadingUnreadMessageCountList,
    handleOnClickContactRoomListItem,
    handleChangeActiveRecruitmentStatus,
    handleChangeIsDisplayOnlyMyCandidates,
    recruitmentStatusTabs,
    contactMessageDraft,
    saveContactMessageDraft,
    contactRoomIdToLatestMessageMap,
    contactRoomIdToUnreadCountMap,
  } = useViewModelV2();

  const isLoading =
    isLoadingContactRooms || isLoadingLatestMessages || isLoadingUnreadMessageCountList;

  const content = useMemo(() => {
    if (selectedContactRoomId) {
      return (
        <NewGraduateSPContactRoomItem
          contactRoomId={selectedContactRoomId}
          contactMessageDraft={contactMessageDraft}
          saveContactMessageDraft={saveContactMessageDraft}
          onClickBack={() => {
            handleOnClickContactRoomListItem(undefined);
          }}
        />
      );
    }

    return (
      <TabContext value={activeRecruitmentStatusTab || "all"}>
        <Box width="100%" pb="24px" px="24px">
          <StyledTabList
            onChange={handleChangeActiveRecruitmentStatus}
            indicatorColor="primary"
            textColor="primary"
          >
            {recruitmentStatusTabs.map((tab) => (
              <StyledTab
                key={tab.recruitmentStatusIdOrAll}
                label={tab.label}
                value={tab.recruitmentStatusIdOrAll}
              />
            ))}
          </StyledTabList>
          <Box mt="16px" display="flex">
            <StyledForm onSubmit={(e) => e.preventDefault()}>
              <TextField
                fullWidth
                variant="outlined"
                value={searchValue}
                onChange={(e) => handleChangeSearchValue(e.target.value)}
                endAdornment={<Icon size="sm" color="grey" icon="search" />}
              />
            </StyledForm>
          </Box>
          <FormControlLabel
            style={{ margin: "16px 0px 0px" }}
            control={
              <Checkbox
                style={{ padding: 0, marginRight: "8px" }}
                checked={isDisplayOnlyMyCandidates}
                onChange={handleChangeIsDisplayOnlyMyCandidates}
              />
            }
            label={
              <Box width="100%" display="flex" alignItems="center" gridGap="4px" overflow="hidden">
                <Typography variant="body2" color="textSecondary" noWrap>
                  担当候補者を絞り込む
                </Typography>
              </Box>
            }
          />
        </Box>
        {isLoading ? (
          <Loading size={"small"} />
        ) : (
          <ContactRoomsList
            contactRooms={currentContactRooms}
            isLoadingLatestMessages={isLoadingLatestMessages}
            isLoadingUnreadMessageCountList={isLoadingUnreadMessageCountList}
            handleOnClickContactRoomListItem={handleOnClickContactRoomListItem}
            contactRoomIdToLatestMessageMap={contactRoomIdToLatestMessageMap}
            contactRoomIdToUnreadCountMap={contactRoomIdToUnreadCountMap}
          />
        )}
      </TabContext>
    );
  }, [
    selectedContactRoomId,
    activeRecruitmentStatusTab,
    handleChangeActiveRecruitmentStatus,
    recruitmentStatusTabs,
    searchValue,
    isDisplayOnlyMyCandidates,
    handleChangeIsDisplayOnlyMyCandidates,
    isLoading,
    currentContactRooms,
    isLoadingLatestMessages,
    isLoadingUnreadMessageCountList,
    handleOnClickContactRoomListItem,
    contactRoomIdToLatestMessageMap,
    contactRoomIdToUnreadCountMap,
    contactMessageDraft,
    saveContactMessageDraft,
    handleChangeSearchValue,
  ]);

  return (
    <StyledContainerBox>
      <Box display="flex">
        <StyledBox width="100%" bgcolor="white" display="flex" flexDirection="column">
          {content}
        </StyledBox>
      </Box>
    </StyledContainerBox>
  );
};

const ContactRoomsList: FC<{
  contactRooms: AllContactRoom[];
  contactRoomIdToLatestMessageMap: Map<string, LatestContactMessage>;
  contactRoomIdToUnreadCountMap: Map<string, number>;
  isLoadingLatestMessages: boolean;
  isLoadingUnreadMessageCountList: boolean;
  handleOnClickContactRoomListItem: (contactRoomId: string | undefined) => void;
}> = ({
  contactRooms,
  contactRoomIdToUnreadCountMap,
  isLoadingLatestMessages,
  isLoadingUnreadMessageCountList,
  handleOnClickContactRoomListItem,
  contactRoomIdToLatestMessageMap,
}) => {
  return (
    <WindowScroller>
      {({ height }) => (
        <Box height="100%">
          <AutoSizer disableHeight>
            {(size) => (
              <VirtualizedList
                height={height}
                width={size.width}
                overscanRowCount={10} // 先にレンダリングしておくリストの数
                rowCount={contactRooms.length}
                rowHeight={80}
                rowRenderer={(props) => {
                  const contactRoom = contactRooms[props.index] as (typeof contactRooms)[number];
                  const latestMessage = contactRoomIdToLatestMessageMap.get(contactRoom.id);
                  const unreadCount = contactRoomIdToUnreadCountMap.get(contactRoom.id);
                  // NOTE: コンタクトルームは存在するのに、未読情報が存在しない場合はSentryで通知を行う
                  if (unreadCount === undefined && !isLoadingUnreadMessageCountList) {
                    captureException({
                      error: new Error("未読情報が存在していません"),
                      tags: { type: "NewGraduateSPContactMessages" },
                      extras: {
                        contactRoomId: contactRoom.id,
                        contactRoomIdToUnreadCountMap,
                        contactRoomIdToUnreadCountMapSize: contactRoomIdToUnreadCountMap.size,
                      },
                    });
                  }
                  return (
                    <Box
                      key={props.key}
                      style={props.style} // 動的に計算されるtopなどのプロパティが入る
                    >
                      <ContactRoomListItemV2
                        key={contactRoom.id}
                        contactRoom={contactRoom}
                        isSelected={false} // NOTE: SP版では選択状態はない
                        onClickContactRoomListItem={() => {
                          handleOnClickContactRoomListItem(contactRoom.id);
                        }}
                        unreadCount={unreadCount || 0}
                        latestMessage={latestMessage}
                        isLoadingLatestMessages={isLoadingLatestMessages}
                      />
                    </Box>
                  );
                }}
                noRowsRenderer={() => (
                  <Box justifyContent="center" display="flex" alignItems="center" height="100%">
                    <Typography variant="body1" align="center">
                      {`担当者になっている\nコンタクトはありません`}
                    </Typography>
                  </Box>
                )}
              />
            )}
          </AutoSizer>
        </Box>
      )}
    </WindowScroller>
  );
};

const StyledContainerBox = styled(Box)`
  background-color: ${(props) => props.theme.palette.grey[50]};
`;

const StyledForm = styled.form`
  .MuiOutlinedInput-input {
    padding: 8px;
  }
  width: 100%;
`;

const StyledBox = styled(Box)`
  z-index: 1;
`;

const StyledTabList = styled(TabList)`
  &.MuiTabs-root {
    padding-top: 0;
    border-bottom: 1px solid ${(props) => props.theme.palette.divider};
    .MuiTabs-scroller {
      ::-webkit-scrollbar {
        display: none;
      }
    }
  }
`;

const StyledTab = styled(Tab)`
  &.MuiTab-root {
    margin-right: 20px;

    > .MuiTab-wrapper {
      font-size: 16px;
    }
  }
`;
