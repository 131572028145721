import { Employee, Role } from "@onn/common";
import { useEffect, useState } from "react";

import { useAllEmployeesWithDeleted, useCurrentUser } from "~/hooks/employee";

export type OtherEmployee = {
  id: string;
  name: string;
  profileIconImageUrl?: string;
  deleted?: boolean;
  role: Role;
};

export const useOtherEmployees = ({ newHireEmployee }: { newHireEmployee?: Employee }) => {
  const { currentUser } = useCurrentUser();

  const [isLoadingOtherEmployees, setIsLoadingOtherEmployees] = useState(true);
  const [otherEmployees, setOtherEmployees] = useState<OtherEmployee[]>([]);

  const { data: allEmployeesWithDeleted } = useAllEmployeesWithDeleted(currentUser.tenantId);

  useEffect(() => {
    if (!allEmployeesWithDeleted) return;
    setOtherEmployees(
      allEmployeesWithDeleted
        .filter((employee) => !!employee.getName().trim() && employee.id !== newHireEmployee?.id)
        .map<OtherEmployee>((employee) => ({
          id: employee.id,
          name: employee.getName(),
          profileIconImageUrl: employee.profileIconImageUrl,
          deleted: employee.deleted,
          role: employee.role,
        }))
    );
    setIsLoadingOtherEmployees(false);
  }, [allEmployeesWithDeleted, newHireEmployee?.id]);

  return {
    isLoadingOtherEmployees,
    otherEmployees,
  };
};
