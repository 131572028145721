import {
  EmployeeInformationErrorType,
  IEmployeeInformationValue,
  NewGraduateToDisplay,
  genders,
  graduationYearAndMonthSchema,
  prefectures,
  Employee,
} from "@onn/common";
import { isMatch, parse } from "date-fns";
import { toNumber } from "lodash";
import { useCallback } from "react";

import { ParsedInputValuesType } from "./ParsedInputValuesType";

type ErrorMapType = Map<keyof typeof EmployeeInformationErrorType, number[]>;

export const useCheckInputValues = () => {
  const checkInputValues = useCallback(
    (
      allNewGraduates: NewGraduateToDisplay[],
      inputValues: ParsedInputValuesType[]
    ): {
      errorMap: ErrorMapType;
      validatedInputValues: {
        uniqueId: string;
        employeeName: Partial<Pick<Employee, "lastName" | "firstName">>;
        employeeInformationValue: IEmployeeInformationValue;
      }[];
    } => {
      const uniqueIdToNewGraduateIdMap = new Map(
        allNewGraduates.map((newGraduate) => [newGraduate.uniqueId, newGraduate.id])
      );
      const errorMap: ErrorMapType = new Map();
      const setError = (errorType: keyof typeof EmployeeInformationErrorType, index: number) => {
        const errorRowNumbers = errorMap.get(errorType);
        const next = errorRowNumbers ? [...errorRowNumbers, index] : [index];
        errorMap.set(errorType, next);
      };

      if (inputValues.length > 5000) {
        errorMap.set(EmployeeInformationErrorType.OVER_THE_LIMIT, [5001]);
      }

      const validatedInputValues = inputValues.map((inputValue, index) => {
        const { employeeInformationValue } = inputValue;
        const { gender, dateOfBirth, graduationYearAndMonth, address, hometownAddress } =
          employeeInformationValue;

        if (!inputValue.uniqueId) {
          setError(EmployeeInformationErrorType.NO_UNIQUE_ID, index + 1);
        }
        if (inputValue.uniqueId && !uniqueIdToNewGraduateIdMap.has(inputValue.uniqueId)) {
          setError(EmployeeInformationErrorType.NOT_EXIST_UNIQUE_ID, index + 1);
        }

        if (gender && !genders.includes(gender)) {
          setError(EmployeeInformationErrorType.INVALID_FORMAT_GENDER, index + 1);
        }

        if (dateOfBirth && !isMatch(dateOfBirth, "yyyyMMdd")) {
          setError(EmployeeInformationErrorType.INVALID_FORMAT_DATE_OR_BIRTH, index + 1);
        }

        if (
          graduationYearAndMonth?.graduationYear &&
          !graduationYearAndMonthSchema.shape.graduationYear.safeParse(
            toNumber(graduationYearAndMonth.graduationYear)
          ).success
        ) {
          setError(EmployeeInformationErrorType.INVALID_FORMAT_GRADUATION_YEAR, index + 1);
        }

        if (
          graduationYearAndMonth?.graduationMonth &&
          !graduationYearAndMonthSchema.shape.graduationMonth.safeParse(
            toNumber(graduationYearAndMonth.graduationMonth)
          ).success
        ) {
          setError(EmployeeInformationErrorType.INVALID_FORMAT_GRADUATION_MONTH, index + 1);
        }

        if (address?.prefecture && !prefectures.includes(address.prefecture)) {
          setError(EmployeeInformationErrorType.INVALID_FORMAT_ADDRESS_PREFECTURE, index + 1);
        }

        if (hometownAddress?.prefecture && !prefectures.includes(hometownAddress.prefecture)) {
          setError(
            EmployeeInformationErrorType.INVALID_FORMAT_HOMETOWN_ADDRESS_PREFECTURE,
            index + 1
          );
        }

        // NOTE: バリデーションが通った場合は正しい型になるため、asで型を指定する
        return {
          ...inputValue,
          employeeInformationValue: {
            ...employeeInformationValue,
            dateOfBirth: dateOfBirth ? parse(dateOfBirth, "yyyyMMdd", new Date()) : undefined,
            graduationYearAndMonth: graduationYearAndMonth
              ? {
                  graduationYear: graduationYearAndMonth.graduationYear
                    ? toNumber(graduationYearAndMonth.graduationYear)
                    : undefined,
                  graduationMonth: graduationYearAndMonth.graduationMonth
                    ? toNumber(graduationYearAndMonth.graduationMonth)
                    : undefined,
                }
              : undefined,
          },
        } as {
          uniqueId: string;
          employeeName: Partial<Pick<Employee, "lastName" | "firstName">>;
          employeeInformationValue: IEmployeeInformationValue;
        };
      });

      return { errorMap, validatedInputValues };
    },
    []
  );

  return { checkInputValues };
};
