import { Box } from "@material-ui/core";

import React, { useCallback, FC, useMemo, useState } from "react";
import styled from "styled-components";

import type { DebouncedFunc } from "lodash";

import { Choice } from "~/components/types/choice";

import { Checkbox, Divider, FormControlLabel, SearchForm, Typography } from "~/components/uiParts";

type Props = {
  searchPlaceholder?: string;
  choices: Choice<string>[];
  defaultSelectedValues: string[];
  onChange:
    | ((selectedValues: string[]) => Promise<void> | void)
    | DebouncedFunc<(employeeTagIds: string[]) => Promise<string | number | void | undefined>>; // HACK: debounce使いたいときにわざわざdebounceの型を指定しなくてもいい感じに書けないか？
};

export const ImmediateSelectCheckboxMenu: FC<Props> = ({
  choices,
  defaultSelectedValues,
  searchPlaceholder = "検索",
  onChange,
}) => {
  const [searchValue, setSearchValue] = useState<string>("");
  const [selectedValues, setSelectedValues] = useState<string[]>(defaultSelectedValues);

  const onSearchValue = useCallback((value: string) => setSearchValue(value), []);
  const searchedChoices = useMemo(
    () =>
      searchValue.trim()
        ? choices.filter((choice) => searchValue.trim() && choice.label.includes(searchValue))
        : choices,
    [choices, searchValue]
  );

  const handleChange = useCallback(
    (value: string) => {
      const newValues = selectedValues.includes(value)
        ? selectedValues.filter((selectedValue) => selectedValue !== value)
        : [...selectedValues, value];
      setSelectedValues(newValues);
      onChange(newValues);
    },
    [onChange, selectedValues]
  );

  const isSelected = useCallback(
    (value: string) => selectedValues.includes(value),
    [selectedValues]
  );

  const noPropagation = useCallback((e: React.MouseEvent) => e.stopPropagation(), []);
  const onChangeCheckBox = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => handleChange(e.target.value),
    [handleChange]
  );

  return (
    <Box width={"320px"} height={"300px"}>
      <StyledSearchFormWrapper padding={"8px 24px"} marginBottom={"8px"} onClick={noPropagation}>
        <SearchForm
          placeholder={searchPlaceholder}
          variant="outlined"
          fullWidth
          onSearchValue={onSearchValue}
        />
      </StyledSearchFormWrapper>
      <Divider />
      <Box mt="8px" px="24px" maxHeight={`${300 - 72}px`} overflow="auto">
        {searchedChoices.map((choice) => (
          <StyledFormControlLabel
            key={choice.value}
            onClick={noPropagation}
            control={
              <Checkbox
                onClick={noPropagation}
                onChange={onChangeCheckBox}
                checked={isSelected(choice.value)}
                name={choice.value}
                value={choice.value}
              />
            }
            label={
              <Box width="100%" display="flex" alignItems="center" gridGap="4px" overflow="hidden">
                <StyledTypography variant="body2" noWrap>
                  {choice.label}
                </StyledTypography>
              </Box>
            }
          />
        ))}
      </Box>
    </Box>
  );
};

const StyledFormControlLabel = styled(FormControlLabel)`
  &.MuiFormControlLabel-root {
    width: 100%;
  }
  .MuiFormControlLabel-label {
    overflow: hidden;
  }
`;

const StyledTypography = styled(Typography)`
  &.MuiTypography-root {
    margin-left: 8px;
    color: ${(props) => props.theme.palette.text.secondary}
`;

const StyledSearchFormWrapper = styled(Box)`
  .MuiInputBase-root {
    height: 42px;
  }
`;
