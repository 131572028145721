import { ContactRoom, Employee, EmployeeTag, NewGraduateToDisplay } from "@onn/common";
import React, { FC, useState, useEffect, useCallback, useMemo } from "react";

import { TableRowProps } from "react-virtualized";

import { NewGraduateUseIcon } from "../NewGraduateUseIcon";

import { NewGraduateCheckbox } from "./TableRowItems/NewGraduateCheckbox";
import { NewGraduateContactRoom } from "./TableRowItems/NewGraduateContactRoom";
import { NewGraduateManageMenu } from "./TableRowItems/NewGraduateManageMenu";

import { NewGraduateMemo } from "./TableRowItems/NewGraduateMemo";
import { NewGraduateMentor } from "./TableRowItems/NewGraduateMentor";

import { NewGraduateTags } from "./TableRowItems/NewGraduateTags";

import { TableRowWrapper } from "~/components/uiParts/VirtualizedTable";
import { TableRow } from "~/components/uiParts/VirtualizedTable/TableRow";
import { VirtualizedTableV2 } from "~/components/uiParts/VirtualizedTable/VirtualizedTableV2";
import { useAccessControl } from "~/hooks/accessControl";
import { useContactContext } from "~/hooks/contactMessage/useContactContext";
import {
  useAllEmployees,
  useCurrentUser,
  useEmployeeModals,
  useNewGraduateModals,
  useUpdateNewGraduateMemo,
} from "~/hooks/employee";

import { useModal } from "~/hooks/modal";
import { useQuery } from "~/hooks/shared";

type Props = {
  displayEmployees: NewGraduateToDisplay[];
  employeeTags: EmployeeTag[];
  selectedEmployees: Employee[];
  checked: boolean;
  indeterminate: boolean;
  onSelectEmployee(employee: Employee): void;
  onChangeCheckBox(): void;
};

const widthOptions = ["8%", "33%", "7%", "17%", "20%", "5%", "5%"];

const useHeaders = ({
  checked,
  indeterminate,
  onChangeCheckBox,
}: {
  checked: boolean;
  indeterminate: boolean;
  onChangeCheckBox: () => void;
}) => {
  const headers = useMemo(
    () => [
      {
        text: "",
        onChangeCheckBox,
        checked,
        indeterminate,
      },
      {
        text: "ユーザー",
      },
      {
        text: "担当者",
      },
      {
        text: "タグ",
      },

      {
        text: "社内メモ",
      },
      {
        text: "",
      },
      {
        text: "",
      },
    ],
    [checked, indeterminate, onChangeCheckBox]
  );

  return headers;
};

const useEmployeeIdToEmployeeMap = () => {
  const { allEmployees } = useAllEmployees();
  const employeeIdToEmployeeMap = useMemo(() => {
    return new Map<string, Employee>(allEmployees.map((employee) => [employee.id, employee]));
  }, [allEmployees]);

  return { employeeIdToEmployeeMap };
};

const useContactRoomEtcMap = () => {
  const { contactRoomNotificationCountList, contactRoomsWithoutCurrentUser: contactRooms } =
    useContactContext();

  const employeeIdToContactRoomMap = useMemo(() => {
    return new Map<string, ContactRoom>(contactRooms.map((room) => [room.employeeId || "", room]));
  }, [contactRooms]);

  const roomIdToUnreadInfoMap = useMemo(() => {
    return new Map(contactRoomNotificationCountList.map((v) => [v.contactRoomId, v]));
  }, [contactRoomNotificationCountList]);

  return { employeeIdToContactRoomMap, roomIdToUnreadInfoMap };
};

export const NewGraduateTable: FC<Props> = ({
  displayEmployees,
  employeeTags,
  selectedEmployees,
  onSelectEmployee,
  onChangeCheckBox,
  checked,
  indeterminate,
}) => {
  const { currentUser } = useCurrentUser();
  const { isEditable } = useAccessControl();
  const { query } = useQuery();
  const { updateNewGraduateMemo } = useUpdateNewGraduateMemo();

  const { employeeIdToEmployeeMap } = useEmployeeIdToEmployeeMap();

  const { employeeIdToContactRoomMap, roomIdToUnreadInfoMap } = useContactRoomEtcMap();

  const { handleOpenDeleteEmployeeModal, handleAddTag } = useEmployeeModals();
  const { handleOpenAssignNewGraduateMentorModal, handleOpenManageMentorAndFollowersModal } =
    useNewGraduateModals();

  // 新卒向けと中途向けが混在しているのでuseEmployeeModalsを使わない
  const { handleModal } = useModal();

  const [isCheckQuery, setIsCheckQuery] = useState(false);

  const headers = useHeaders({ checked, indeterminate, onChangeCheckBox });

  // 担当者が設定されている場合管理モーダルを表示し、そうでない場合はAdminの場合にのみ設定モーダルを表示する
  const switchMentorModalOpen = useCallback(
    (newHire: Employee, mentor?: Employee, supportMembers?: Employee[]) => {
      if (mentor) {
        return handleOpenManageMentorAndFollowersModal(newHire, mentor, supportMembers);
      }
      if (isEditable(newHire)) {
        return handleOpenAssignNewGraduateMentorModal(newHire, mentor, supportMembers);
      }
    },
    [isEditable, handleOpenManageMentorAndFollowersModal, handleOpenAssignNewGraduateMentorModal]
  );

  const rowRenderer = useCallback(
    ({
      key,
      index,
      style,
      rowData: displayNewHire,
    }: Omit<TableRowProps, "rowData"> & {
      rowData: NewGraduateToDisplay;
    }) => {
      const mentor = employeeIdToEmployeeMap.get(displayNewHire.mentorUserId || "");
      const supportMembers = (displayNewHire.supportMemberEmployeeIds || []).flatMap((id) => {
        return employeeIdToEmployeeMap.get(id) || [];
      });
      const targetContactRoom = employeeIdToContactRoomMap.get(displayNewHire.id);

      const contents = [
        <NewGraduateCheckbox
          key={`checkbox-${displayNewHire.id}`}
          displayNewHire={displayNewHire}
          onSelectEmployee={onSelectEmployee}
          selectedEmployees={selectedEmployees}
        />,
        <NewGraduateUseIcon key={`user-${displayNewHire.id}`} newGraduate={displayNewHire} />,
        <NewGraduateMentor
          key={`mentor-${displayNewHire.id}`}
          mentor={mentor}
          isEditable={isEditable(displayNewHire)}
          onClickMentor={() => switchMentorModalOpen(displayNewHire, mentor, supportMembers)}
        />,
        <NewGraduateTags
          key={`tag-${displayNewHire.id}`}
          allTags={employeeTags}
          addedTagIds={displayNewHire.employeeTagIds}
          employeeId={displayNewHire.id}
        />,
        <NewGraduateMemo
          key={`memo-${displayNewHire.id}`}
          memo={displayNewHire.admin_memo || ""}
          onUpdateMemo={async (newMemo: string) =>
            await updateNewGraduateMemo(displayNewHire, newMemo)
          }
        />,
        <NewGraduateContactRoom
          key={`contact-${displayNewHire.id}`}
          // 未招待の場合はコンタクトルームへの動線を表示しない
          disabledContactRoomIcon={displayNewHire.isNotRegisteredAndInvited()}
          contactRoomId={targetContactRoom?.id}
          unreadContactMessageCount={
            roomIdToUnreadInfoMap.get(targetContactRoom?.id || "")?.unreadCount
          }
        />,
        <>
          {isEditable(displayNewHire) && (
            <NewGraduateManageMenu
              newHire={displayNewHire}
              onClickDeleteAccountButton={() => handleOpenDeleteEmployeeModal(displayNewHire)}
              onClickInvitationButton={() =>
                handleModal({
                  name: "inviteNewGraduateModal",
                  args: {
                    currentUser,
                    newGraduate: displayNewHire,
                  },
                })
              }
              onClickSwitchMentorButton={() =>
                switchMentorModalOpen(displayNewHire, mentor, supportMembers)
              }
              onClickAddTag={() => {
                handleAddTag(displayNewHire);
              }}
            />
          )}
        </>,
      ];

      return (
        <TableRowWrapper key={key} index={index} {...style}>
          <TableRow
            row={{
              contents,
              to: `/employee/${displayNewHire.id}`,
              isTargetBlank: true,
            }}
            widthOptions={widthOptions}
            hover={true}
          />
        </TableRowWrapper>
      );
    },
    [
      currentUser,
      employeeIdToContactRoomMap,
      employeeIdToEmployeeMap,
      employeeTags,
      handleAddTag,
      handleModal,
      handleOpenDeleteEmployeeModal,
      isEditable,
      onSelectEmployee,
      roomIdToUnreadInfoMap,
      selectedEmployees,
      switchMentorModalOpen,
      updateNewGraduateMemo,
    ]
  );

  useEffect(() => {
    if (isCheckQuery) return;
    setIsCheckQuery(true);

    const targetEmployeeId = query.get("targetEmployeeId");

    const newHire = employeeIdToEmployeeMap.get(targetEmployeeId || "");
    if (!newHire) return;

    const mentor = employeeIdToEmployeeMap.get(newHire.mentorUserId || "");

    const supportMembers = (newHire.supportMemberEmployeeIds || []).flatMap((id) => {
      return employeeIdToEmployeeMap.get(id) || [];
    });

    // クエリパラメータで指定されている場合はダイアログを開く
    switch (query.get("openDialogType")) {
      case "mentor":
        handleOpenAssignNewGraduateMentorModal(newHire, mentor, supportMembers);
        break;
    }
  }, [
    displayEmployees,
    employeeIdToEmployeeMap,
    handleOpenAssignNewGraduateMentorModal,
    handleOpenManageMentorAndFollowersModal,
    isCheckQuery,
    query,
  ]);

  return (
    <VirtualizedTableV2<NewGraduateToDisplay>
      rows={displayEmployees}
      hover
      widthOptions={widthOptions}
      rowHeight={106}
      headers={headers}
      rowRenderer={rowRenderer}
    />
  );
};
