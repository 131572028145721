import { Box, Grid } from "@material-ui/core";
import React from "react";

import { DropResult } from "react-beautiful-dnd";
import styled from "styled-components";

import { useSelectionConditions } from "./useSelectionConditions";

import {
  Checkbox,
  DnDDraggable,
  DnDDroppable,
  DnDProvider,
  Icon,
  Typography,
} from "~/components/uiParts";

type Props = Omit<ReturnType<typeof useSelectionConditions>, "selectedConditionFieldKeys">;
export const ModalContent = ({
  conditionFields,
  changeOrder,
  toggleIsSelected,
}: Props): JSX.Element => {
  const onChangeOrder = (result: DropResult) => {
    if (!result.destination) return;

    const {
      source: { index: sourceIndex },
      destination: { index: destinationIndex },
    } = result;

    // drop可能範囲以外でのdropは無効 or 移動元と移動先が同じ場合は処理を終了
    if (destinationIndex === undefined || sourceIndex === destinationIndex) return;

    changeOrder({ sourceIndex, destinationIndex });
  };

  const lastIndex = conditionFields.length - 1;

  const content = (
    <DnDProvider onDragEnd={onChangeOrder}>
      <DnDDroppable droppableId="droppableId" isDropDisabled={false}>
        <Box minWidth="450px">
          {conditionFields.map((field, i) => {
            return (
              <DnDDraggable
                draggableId={field.key}
                index={i}
                key={field.key}
                isDragDisabled={false}
                shouldRoundCorner={false}
              >
                <StyledRow $isLast={i === lastIndex}>
                  <StyleHeadCell>
                    <Icon icon="gripVertical" size="sm" color="grey" />
                    <Typography variant="body2">{field.label}</Typography>
                    <Checkbox
                      checked={field.isSelected}
                      onChange={() => toggleIsSelected(field.key)}
                    />
                  </StyleHeadCell>

                  {"sampleValues" in field ? (
                    <StyleMultiCells>
                      {field.sampleValues.map((sampleValue, index) => {
                        const widthPercent = 100 / field.sampleValues.length;
                        const width = widthPercent > 30 ? `${widthPercent}%` : "30%"; // 最小幅を30%にする
                        const isLast = index === field.sampleValues.length - 1;
                        return (
                          <StyleCell key={sampleValue} width={width} $hasRightBorder={!isLast}>
                            <Typography variant="body2" color="textSecondary" noWrap>
                              {field.sampleValues[index]}
                            </Typography>
                          </StyleCell>
                        );
                      })}
                    </StyleMultiCells>
                  ) : (
                    <StyleCell flex={1} width="100px">
                      <Typography variant="body2" color="textSecondary" noWrap>
                        {field.sampleValue}
                      </Typography>
                    </StyleCell>
                  )}
                </StyledRow>
              </DnDDraggable>
            );
          })}
        </Box>
      </DnDDroppable>
    </DnDProvider>
  );

  return (
    <>
      <Box pb={"24px"}>
        <Typography align="center">
          CSVファイルに書き出す項目と順序を設定してください。表示されている内容は例文です。
        </Typography>
      </Box>
      {content}
    </>
  );
};

const StyledRow = styled(Grid)<{ $isLast: boolean }>`
  width: 100%;
  height: 56px;
  display: flex;
  border-bottom: ${(props) => (!props.$isLast ? `1px solid ${props.theme.palette.grey[100]}` : "")};
`;

const StyleHeadCell = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 240px;
  padding: 0 10px;
  background-color: ${(props) => props.theme.palette.grey[50]};
  border-right: ${(props) => `1px solid ${props.theme.palette.grey[100]}`};
`;

const StyleMultiCells = styled(Box)`
  display: flex;
  flex: 1;
  overflow-x: scroll;
`;

const StyleCell = styled(Box)<{ $hasRightBorder?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  padding: 16px 8px;
  border-right: ${(props) =>
    props.$hasRightBorder ? `1px solid ${props.theme.palette.grey[100]}` : ""};
`;
