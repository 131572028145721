import { Box } from "@material-ui/core";
import { Employee, OnnEvent, OnnEventSlotDate, getHowLongAgo } from "@onn/common";
import { format } from "date-fns";
import { ja } from "date-fns/locale";
import React, { FC, useCallback } from "react";
import styled from "styled-components";

import {
  Button,
  Divider,
  Icon,
  IconButton,
  Loading,
  ScrollableBodyModal,
  Typography,
  UncontrolledMenu,
  UserIconWithLabel,
} from "~/components/uiParts";
import { colors } from "~/config/theme";
import { useEmployees } from "~/hooks/employee";
import { useModal } from "~/hooks/modal";
import { useDeterminedDate } from "~/hooks/onnEvent";

type Props = {
  open: boolean;
  onCancel: () => void;
  onnEvent: OnnEvent;
  slotId: string;
  fromDate: Date;
  untilDate: Date;
  type: OnnEventSlotDate["eventType"];
  isFull: boolean;
};

export const ListOfEmployeesParticipatingInInterviewModal: FC<Props> = ({
  open,
  onCancel,
  onnEvent,
  slotId,
  fromDate,
  untilDate,
  type: _type,
  isFull,
}) => {
  const { handleModal } = useModal();
  // ex) 2024/10/4(月) 15:00〜16:00
  const dateAndTime = `${format(fromDate, "yyyy/MM/dd(E) HH:mm", { locale: ja })}〜${format(
    untilDate,
    "HH:mm",
    { locale: ja }
  )}`;
  const type = _type === "offline" ? "オフライン・現地" : "オンライン";

  const { data: onnEventDeterminedDates = [], isLoading: isLoadingDeterminedDates } =
    useDeterminedDate({ onnEventId: onnEvent.id });

  const targetDeterminedDates = onnEventDeterminedDates.filter(
    (determinedDate) => determinedDate.onnEventSlotDateId === slotId
  );

  const employeeIds = targetDeterminedDates.map((determinedDate) => determinedDate.employeeId);
  const { data: employees, isLoading: isLoadingEmployees } = useEmployees(employeeIds);

  const isLoading = isLoadingDeterminedDates || isLoadingEmployees;

  const openNewInterviewAnswerModal = () => {
    handleModal({
      name: "answerNewInterviewEventOnBehalfModal",
      args: {
        onnEventId: onnEvent.id,
        fixedOption: {
          selectedSlotDateId: slotId,
        },
        mode: {
          type: "create",
        },
        slotDefaultValueSetting: onnEvent.slotDefaultValueSetting,
      },
    });
  };

  const openNewInterviewAnswerForUpdateModal = useCallback(
    (employee: Employee) => {
      handleModal({
        name: "answerNewInterviewEventOnBehalfModal",
        args: {
          onnEventId: onnEvent.id,
          fixedOption: {
            selectedEmployeeId: employee.id,
          },
          mode: {
            type: "edit",
            onnEventSlotDateId: slotId,
          },
          slotDefaultValueSetting: onnEvent.slotDefaultValueSetting,
        },
      });
    },
    [handleModal, onnEvent, slotId]
  );

  const onClickOpenRemoveDeterminedDateModal = useCallback(
    (employee: Employee) => {
      handleModal({
        name: "removeNewInterviewDeterminedDateModal",
        args: {
          onnEvent,
          employee,
          isAlreadyAnswered: true,
        },
      });
    },
    [handleModal, onnEvent]
  );

  return (
    <StyledModal
      open={open}
      title="予約確認"
      onCancel={onCancel}
      content={
        <Box
          display="flex"
          flexDirection="column"
          gridRowGap="24px"
          style={{ overflowY: "scroll" }}
        >
          <SlotInfoArea dataAndTime={dateAndTime} type={type} />
          <Box
            display="flex"
            gridRowGap={"8px"}
            flexDirection={"column"}
            minHeight={"312px"}
            justifyContent={employees?.length === 0 || isLoading ? "center" : "flex-start"}
            alignItems={"center"}
            style={{ overflowY: "scroll" }}
          >
            {isLoading ? (
              <Loading size="small" />
            ) : employees?.length === 0 ? (
              <Typography
                variant="body1"
                color="textPrimary"
                component={"div"}
                style={{ height: "100%" }}
              >
                予約している候補者がいません。
              </Typography>
            ) : (
              employees?.map((employee) => {
                return (
                  <Box
                    key={employee.id}
                    p="8px"
                    width={"100%"}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <UserIconWithLabel
                      size="small"
                      name={employee.getName()}
                      secondaryText={
                        employee.lastActiveTime
                          ? `最終アクセス：${getHowLongAgo(employee.lastActiveTime)}`
                          : "未読"
                      }
                      textSize={"normal"}
                      iconPath={employee.profileIconImageUrl}
                    />
                    <UncontrolledMenu
                      renderButton={(openMenu) => <IconButton icon="menuVert" onClick={openMenu} />}
                      menuItemOptions={[
                        {
                          text: "予約変更",
                          onClick: () => openNewInterviewAnswerForUpdateModal(employee),
                        },
                        {
                          text: "予約取り消し",
                          onClick: () => onClickOpenRemoveDeterminedDateModal(employee),
                        },
                      ]}
                    />
                  </Box>
                );
              })
            )}
          </Box>
          {!isFull && (
            <>
              <Divider />
              <Box display="flex" justifyContent="start">
                <Button
                  onClick={openNewInterviewAnswerModal}
                  color="primary"
                  variant="outlined"
                  borderRadius="regular"
                  startIcon={<Icon size="sm" icon="add" color="primary" />}
                >
                  代理予約
                </Button>
              </Box>
            </>
          )}
        </Box>
      }
    />
  );
};

const SlotInfoArea: FC<{ dataAndTime: string; type: string }> = ({ dataAndTime, type }) => {
  return (
    <Box
      height={"56px"}
      display="flex"
      alignItems={"center"}
      p="16px"
      style={{ backgroundColor: colors.grey["50"], borderRadius: "4px" }}
    >
      <Box width="80px" mr="8px">
        <Typography variant="body2" color="textPrimary" bold>
          開催日時
        </Typography>
      </Box>
      <Box width="248px">
        <Typography variant="body2" color="textPrimary">
          {dataAndTime}
        </Typography>
      </Box>
      <Box width="80px" mr="8px">
        <Typography variant="body2" color="textPrimary" bold>
          開催方法
        </Typography>
      </Box>
      <Box width="248px">
        <Typography variant="body2" color="textPrimary">
          {type}
        </Typography>
      </Box>
    </Box>
  );
};

const StyledModal = styled(ScrollableBodyModal)`
  .MuiDialog-paper {
    width: 800px;
  }
`;
