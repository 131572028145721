import { v4 } from "uuid";

import { ILineUserActiveLogSchema, TType, lineUserActiveLogSchema } from "./schema";

export class LineUserActiveLog implements ILineUserActiveLogSchema {
  static validator = lineUserActiveLogSchema;

  id: string;
  externalLineUserId: string;
  location: string;
  tenantId: string;
  spaceId?: string;
  type: TType;
  sequenceId?: string;
  createdAt: Date;
  updatedAt: Date;

  constructor({
    id,
    tenantId,
    externalLineUserId,
    location,
    spaceId,
    type,
    sequenceId,
    createdAt,
    updatedAt,
  }: {
    id: string;
    tenantId: string;
    externalLineUserId: string;
    location: string;
    spaceId?: string;
    type: TType;
    sequenceId?: string;
    createdAt: Date;
    updatedAt: Date;
  }) {
    this.id = id;
    this.tenantId = tenantId;
    this.externalLineUserId = externalLineUserId;
    this.location = location;
    this.spaceId = spaceId;
    this.type = type;
    this.sequenceId = sequenceId;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
  }

  public updateUpdatedAt() {
    this.updatedAt = new Date();
  }

  public updateLocation(location: string) {
    this.validate({ location });
    this.location = location;
  }
  public static create(
    params: Optional<ExcludeMethods<LineUserActiveLog>, "id" | "createdAt" | "updatedAt">
  ) {
    return new LineUserActiveLog({
      ...params,
      id: params.id ?? v4(),
      createdAt: params.createdAt ?? new Date(),
      updatedAt: params.updatedAt ?? new Date(),
    });
  }

  public validate(update: Partial<LineUserActiveLog>) {
    LineUserActiveLog.validator.parse({ ...this, ...update });
  }
}
