import { Space } from "@onn/common";
import * as Sentry from "@sentry/react";
import { first } from "lodash";
import React, { FC, ReactNode, createContext, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSWRConfig } from "swr";

import { useCurrentUser } from "~/hooks/employee";

import { useSnackbar } from "~/hooks/shared";
import { useAllSpaces } from "~/hooks/space/useAllSpaces";
import { useTenant } from "~/hooks/tenant";
import {
  getSelectedSpaceId,
  getSpaceIdFromSS,
  getStorageKey,
  removeValueFromSS,
  storeValueToLS,
  storeValueToSS,
} from "~/libs/spaceIdFromStorage";

export const SpaceContext = createContext<{
  currentSpace: Space | undefined;
  switchSpace: (spaceId: string) => void;
  switchSpaceTemporary: (spaceId: string) => void;
  spaces: Space[] | undefined;
  isShowSpaceOnScreen: (spaces: Space[]) => spaces is NonEmpty<Space>;
}>({
  currentSpace: undefined,
  switchSpace: (_spaceId: string) => void 0,
  switchSpaceTemporary: (_spaceId: string) => void 0,
  spaces: undefined,
  isShowSpaceOnScreen: (spaces: Space[]): spaces is NonEmpty<Space> => false,
});

export const SpaceProvider: FC<{
  children: ReactNode;
}> = ({ children }) => {
  const { tenant } = useTenant();
  const { currentUser } = useCurrentUser();

  // 中途向けテナントの場合はスペース機能を使用しないので即時レンダリングを行う
  if (!tenant.isActiveNewGraduate) {
    return children;
  }

  // 新卒向けテナントであっても候補者の場合はスペース機能を使用しないので即時レンダリングを行う
  if (currentUser.assignedAsNewcomer) {
    return children;
  }

  return <SpaceProviderCore>{children}</SpaceProviderCore>;
};

const SpaceProviderCore: FC<{
  children: ReactNode;
}> = ({ children }) => {
  const { currentUser } = useCurrentUser();
  const { cache, mutate } = useSWRConfig();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [currentSpace, setCurrentSpace] = useState<Space>();

  const { data: spaces, isLoading } = useAllSpaces();

  const determinateCurrentSpace = useCallback(async () => {
    if (!spaces) return;

    const spaceId = getSelectedSpaceId(currentUser.tenantId);

    // スペースが存在しなかった場合は取得したスペースの一番目をstateにセットする。
    const space = spaces.find((space) => space.id === spaceId) || first(spaces);

    setCurrentSpace(space);

    if (!space) return;
    const spaceIdFromSS = getSpaceIdFromSS(currentUser.tenantId);

    // セッションストレージにスペースIDが存在する場合は一時的なスペース切り替えになるのでローカルストレージの値は更新しない
    if (spaceIdFromSS) return;

    storeValueToLS(getStorageKey(currentUser.tenantId), space.id);
  }, [currentUser.tenantId, spaces]);

  const updateSpaceBySpaceId = useCallback(
    (spaceId: string) => {
      if (!spaces) return;
      const space = spaces.find((space) => space.id === spaceId);
      // ここに入ってくることはないはずだが、念のためSentryにエラーを送信する
      if (!space) {
        const errorMessage = "存在しないSpaceIdです";
        Sentry.captureException(errorMessage, {
          extra: { spaces, spaceId, currentUser },
        });
        enqueueSnackbar("存在しないSpaceIdです", { variant: "error" });
        throw new Error(errorMessage);
      }

      enqueueSnackbar(`${space.name}の年次に切り替えました`, { variant: "success" });
      setCurrentSpace(space);

      // SWRのkeyにスペースがないためスペースを切り替えないと古いスペースのキャッシュを消す目的で全てのキーをmutateする
      Array.from(cache.keys()).forEach((key) => {
        mutate(key);
      });

      return space;
    },
    [cache, currentUser, enqueueSnackbar, mutate, spaces]
  );

  const switchSpace = useCallback(
    (spaceId: string) => {
      storeValueToLS(getStorageKey(currentUser.tenantId), spaceId);
      // この関数を実行するときは一時的な年次切り替えではないことを意味するためセッションストレージの値を削除する
      removeValueFromSS(getStorageKey(currentUser.tenantId));

      updateSpaceBySpaceId(spaceId);

      navigate("/");
    },
    [currentUser.tenantId, navigate, updateSpaceBySpaceId]
  );

  const switchSpaceTemporary = useCallback(
    (spaceId: string) => {
      const spaceIdFromStorage = getSelectedSpaceId(currentUser.tenantId);

      if (spaceId === spaceIdFromStorage) return;

      storeValueToSS(getStorageKey(currentUser.tenantId), spaceId);
      updateSpaceBySpaceId(spaceId);
    },
    [currentUser.tenantId, updateSpaceBySpaceId]
  );

  useEffect(() => {
    determinateCurrentSpace();
  }, [determinateCurrentSpace]);

  if (isLoading) return null;
  // spacesとcurrentSpaceのundefinedは取得前を示す
  if (currentSpace === undefined || spaces === undefined) return null;

  // スペースが1つしか存在しない場合は画面上にスペースを表示しない
  const isShowSpaceOnScreen = (spaces: Space[]): spaces is NonEmpty<Space> => {
    return spaces.length > 1;
  };

  return (
    <SpaceContext.Provider
      value={{ currentSpace, switchSpace, switchSpaceTemporary, spaces, isShowSpaceOnScreen }}
    >
      {children}
    </SpaceContext.Provider>
  );
};
