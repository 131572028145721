import { isEmpty } from "lodash";
import React, { FC } from "react";

import { CellItem, StyleProps } from "./CellItem";

import { Button, Typography } from "~/components/uiParts";
import { useModal } from "~/hooks/modal";
import { RecruitmentProcessRecordByEmployee } from "~/hooks/recruitmentProcess/useRecruitmentProcessRecordsByEmployee";

export const RecruitmentStatusLabelCell: FC<
  {
    recruitmentProcessRecordByEmployee: RecruitmentProcessRecordByEmployee;
  } & StyleProps
> = ({ recruitmentProcessRecordByEmployee, ...styleProps }) => {
  const hasRelation =
    !!recruitmentProcessRecordByEmployee.relation.onnEvent ||
    !isEmpty(recruitmentProcessRecordByEmployee.relation.onnTasksWithRelation);

  const { handleModal } = useModal();
  const openModal = () => {
    handleModal({
      name: "recruitmentProcessDetailModal",
      args: {
        recruitmentProcessRecord: recruitmentProcessRecordByEmployee,
      },
    });
  };

  return (
    <CellItem {...styleProps}>
      {hasRelation ? (
        <Button color="primary" variant="text" borderRadius="regular" onClick={openModal}>
          <Typography variant="body2" color="primary" bold>
            {recruitmentProcessRecordByEmployee.recruitmentStatusLabel}
          </Typography>
        </Button>
      ) : (
        <Typography variant="body2" color="textSecondary" bold>
          {recruitmentProcessRecordByEmployee.recruitmentStatusLabel}
        </Typography>
      )}
    </CellItem>
  );
};
