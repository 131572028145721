import { z } from "zod";

const statues = ["SENT", "CANCELED", "WAITING", "FAILED"] as const;
export type Status = (typeof statues)[number];

export const TEXT_MAX_LENGTH = 5000;

export const contactMessagesOrderSchema = z.object({
  id: z.string(),
  tenantId: z.string(),
  spaceId: z.string(),
  text: z
    .string()
    .max(TEXT_MAX_LENGTH, { message: `本文は${TEXT_MAX_LENGTH}文字以内にしてください` })
    .min(1, { message: "本文は1文字以上にしてください" }),
  filePaths: z.array(z.string()),
  scheduledDate: z.date().optional(),

  senderEmployeeId: z.string().optional(),
  receiverEmployeeIds: z.array(z.string()),

  createdEmployeeId: z.string(),
  createdAt: z.date(),
  updatedAt: z.date(),

  status: z.enum(statues),
  sentEmployeeIds: z.array(z.string()),
});

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IContactMessagesOrder extends z.infer<typeof contactMessagesOrderSchema> {}
