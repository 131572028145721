import { Menu, MenuItem, Box } from "@material-ui/core";
import { EmployeeTag } from "@onn/common";
import { isEmpty } from "lodash";
import React, { ChangeEvent, FC, MouseEvent, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { IconButton, TextField, Typography } from "~/components/uiParts";

type Props = {
  anchorEl: HTMLElement | null;
  onClose: (event: MouseEvent<HTMLElement>) => void;
  candidateTags: EmployeeTag[];
  onClickTag: (tagID: string) => Promise<void>;
};

export const AddTagsMenu: FC<Props> = ({ anchorEl, onClose, candidateTags, onClickTag }) => {
  const [searchValue, setSearchValue] = useState("");
  const menuOptions = candidateTags
    .filter((candidateTag) => {
      const pattern = new RegExp(searchValue.trim());
      return candidateTag.name.match(pattern) !== null;
    })
    .map((tag) => ({
      id: tag.id,
      name: tag.name,
      onClick: onClickTag,
    }));

  const handleChangedInputValue = (value: string) => {
    setSearchValue(value);
  };

  const menuItemLinkToSettingsAdminTag = useMemo(() => {
    return (
      <StyledLink to="/settings/admin#tag" target="_blank">
        <StyledMenuItemWithBorder>
          <Typography variant="body2" color="textSecondary">
            新規作成
          </Typography>
        </StyledMenuItemWithBorder>
      </StyledLink>
    );
  }, []);

  const isNotExistedTag = useMemo(() => isEmpty(candidateTags), [candidateTags]);
  const isNotMatchedTag = useMemo(() => isEmpty(menuOptions), [menuOptions]);

  return (
    <StyledMenu
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={onClose}
      getContentAnchorEl={null}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
    >
      {isNotExistedTag ? (
        <>
          <StyledBox width="318px">
            <Typography variant="body2" color="textSecondary">
              タグが存在しません
            </Typography>
          </StyledBox>
          {menuItemLinkToSettingsAdminTag}
        </>
      ) : (
        <>
          <StyledTextFieldWrapper>
            <StyledTextField
              autoFocus
              type={"text"}
              variant="outlined"
              size="small"
              placeholder="タグ名で検索"
              value={searchValue}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                handleChangedInputValue(e.target.value);
              }}
              endAdornment={<IconButton icon="search" color="grey" size="md" />}
            />
          </StyledTextFieldWrapper>
          <StyledBoxToScrollMenuItems>
            {isNotMatchedTag ? (
              <StyledBox>
                <Typography variant="body2" color="textSecondary">
                  一致するタグがありません
                </Typography>
              </StyledBox>
            ) : (
              <>
                {menuOptions.map((option) => (
                  <MenuItem key={option.id} onClick={() => option.onClick(option.id)}>
                    <Typography variant="body2">{option.name}</Typography>
                  </MenuItem>
                ))}
              </>
            )}
          </StyledBoxToScrollMenuItems>
          {menuItemLinkToSettingsAdminTag}
        </>
      )}
    </StyledMenu>
  );
};

const StyledMenu = styled(Menu)`
  margin-top: 12px;
`;

const StyledBoxToScrollMenuItems = styled(Box)`
  overflow-y: scroll;
  max-height: 308px;
`;

const StyledMenuItemWithBorder = styled(MenuItem)`
  border-top: 1px solid ${(props) => props.theme.palette.grey[200]} !important;
  padding: 16px 24px 6px !important;
`;

const StyledLink = styled(Link)`
  color: inherit;
  display: block;
  text-decoration: none;
`;

const StyledBox = styled(Box)`
  padding: 16px 24px;
`;

const StyledTextFieldWrapper = styled(Box)`
  padding: 8px 24px 16px;
  border-bottom: 1px solid ${(props) => props.theme.palette.grey[200]};
`;

/**
 * padding-rightを0にすることで、末尾のアイコンの右側の余白を削除しています。
 */
const StyledTextField = styled(TextField)`
  > div.MuiOutlinedInput-adornedEnd {
    padding-right: 0 !important;
  }
`;
