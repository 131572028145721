import { MenuItem } from "@material-ui/core";
import { OnnEvent, generatePagePath } from "@onn/common";

import React, { useCallback, useMemo } from "react";
import { CSVLink } from "react-csv";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { UncontrolledMenu, IconButton, Loading } from "~/components/uiParts";
import {
  useEmployeeActiveLogsByType,
  useGenerateEmployeeIdToLatestEmployeeActiveLogMap,
} from "~/hooks/employeeActiveLog";
import { useModal } from "~/hooks/modal";
import {
  useDeleteOnnEvent,
  useOnnEventAnswersWithEmployee,
  useOutputResultAnswerCsv,
  useUpdateOnnEventAssignee,
} from "~/hooks/onnEvent";

// ３点ドットメニューセル
export const ThreeDotMenu = ({ onnEvent }: { onnEvent: OnnEvent }) => {
  const navigation = useNavigate();

  const { onClickUpdateAssigneeButton } = useOnClickUpdateAssigneeButton(onnEvent);
  const { onClickSlotDefaultValueSetting } = useOnClickSlotDefaultValueSetting(onnEvent.id);
  const { onClickDeleteButton } = useOnClickDeleteButton(onnEvent);

  const { renderCustomComponent } = useCsvDownloadMenu(onnEvent);

  const isNewInterview = onnEvent.type === "new_interview";

  const menuItemOptions = [
    {
      text: "編集",
      onClick: () => navigation(generatePagePath.onnEvent.edit(onnEvent.id, { from_page: "list" })),
    },
    isNewInterview
      ? []
      : {
          text: "担当者変更",
          onClick: onClickUpdateAssigneeButton,
        },
    isNewInterview
      ? {
          text: "共通設定",
          onClick: onClickSlotDefaultValueSetting,
        }
      : [],
    isNewInterview
      ? []
      : {
          renderCustomComponent: renderCustomComponent,
        },
    isNewInterview
      ? {
          text: "配信設定",
          onClick: () =>
            navigation(
              generatePagePath.onnEvent.deliverySetting(onnEvent.id, { from_page: "list" })
            ),
        }
      : [],
    {
      text: "削除",
      onClick: onClickDeleteButton,
    },
  ].flat();

  return (
    <UncontrolledMenu
      renderButton={(openMenu) => {
        return <IconButton icon="menuVert" onClick={openMenu} />;
      }}
      menuItemOptions={menuItemOptions}
    />
  );
};

const useCsvDownloadMenu = (onnEvent: OnnEvent) => {
  const { data: onnEventAnswers = [], isLoading: isLoadingAnswers } =
    useOnnEventAnswersWithEmployee({
      onnEventId: onnEvent.id,
    });

  const { data: employeeActiveLogs = [], isLoading: isLoadingLogs } = useEmployeeActiveLogsByType({
    onnEventId: onnEvent.id,
    type: "VISITED_ONN_EVENT_LANDING_PAGE",
  });

  const { generateEmployeeIdToLatestEmployeeActiveLogMap } =
    useGenerateEmployeeIdToLatestEmployeeActiveLogMap();

  const employeeIdToLatestEmployeeActiveLogMap = useMemo(
    () => generateEmployeeIdToLatestEmployeeActiveLogMap({ employeeActiveLogs }),
    [employeeActiveLogs, generateEmployeeIdToLatestEmployeeActiveLogMap]
  );

  const {
    csvData,
    filename,
    isLoading: isLoadingOutput,
  } = useOutputResultAnswerCsv({
    onnEvent,
    onnEventAnswers,
    employeeIdToLatestEmployeeActiveLogMap,
  });

  const isLoading = isLoadingAnswers || isLoadingLogs || isLoadingOutput;

  const renderCustomComponent = (closeMenu: () => void) => {
    if (isLoading)
      return (
        <MenuItem
          key="csv_download_loading"
          onClick={(e) => {
            e.preventDefault();
          }}
        >
          <Loading size="small" />
        </MenuItem>
      );
    return (
      <StyledCSVLink key="csv_download" data={csvData} filename={filename}>
        <MenuItem
          onClick={(e) => {
            e.stopPropagation();
            closeMenu();
          }}
        >
          csvダウンロード
        </MenuItem>
      </StyledCSVLink>
    );
  };

  return { renderCustomComponent };
};

// 「担当者変更」ボタンのクリックハンドラ
const useOnClickUpdateAssigneeButton = (onnEvent: OnnEvent) => {
  const { handleModal } = useModal();

  const { updateOnnEventAssignee } = useUpdateOnnEventAssignee();

  const onClickUpdateAssigneeButton = useCallback(() => {
    handleModal({
      name: "updateEventAssigneeModal",
      args: {
        existingAssigneeIds: onnEvent?.assigneeIds ?? [],
        onSubmit: async (employeeIds) => {
          updateOnnEventAssignee({ onnEventId: onnEvent.id, employeeIds });
        },
      },
    });
  }, [handleModal, onnEvent?.assigneeIds, onnEvent.id, updateOnnEventAssignee]);

  return { onClickUpdateAssigneeButton };
};

// 「共通設定」ボタンのクリックハンドラ
const useOnClickSlotDefaultValueSetting = (eventId: string) => {
  const navigation = useNavigate();

  const onClickSlotDefaultValueSetting = useCallback(() => {
    navigation(generatePagePath.onnEvent.slotDefaultValueSetting(eventId, { from_page: "list" }));
  }, [eventId, navigation]);

  return { onClickSlotDefaultValueSetting };
};

// 「削除」ボタンのクリックハンドラ
const useOnClickDeleteButton = (onnEvent: OnnEvent) => {
  const navigation = useNavigate();
  const { handleModal } = useModal();
  const { execDeleteOnnEvent } = useDeleteOnnEvent();

  const onClickDeleteButton = useCallback(() => {
    handleModal({
      name: "eventDeleteConfirmModal",
      args: {
        eventTitle: onnEvent.title,
        onSubmit: async () => {
          await execDeleteOnnEvent(onnEvent.id);
          navigation(`/events?from_page=detail`);
        },
      },
    });
  }, [handleModal, execDeleteOnnEvent, onnEvent, navigation]);

  return { onClickDeleteButton };
};

const StyledCSVLink = styled(CSVLink)`
  text-decoration: none;
`;
