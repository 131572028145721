import { Box } from "@material-ui/core";
import { OnnEvent, OnnEventSlotDatesForDisplay } from "@onn/common";
import React, { FC, useCallback, useMemo } from "react";

import { OnnEventSlotDatesMultiGrid } from "./Table/OnnEventSlotDatesMultiGrid";

import { useSelectedSlots } from "./useSelectedSlots";

import { useSlotBulkDeleteConfirmModal } from "./useSlotBulkDeleteConfirmModal";
import { useStatusBulkUpdateConfirmModal } from "./useStatusBulkUpdateConfirmModal";

import { Button, Icon, IconButton, UncontrolledMenu, Typography } from "~/components/uiParts";

import { useCurrentUser } from "~/hooks/employee";
import { useModal } from "~/hooks/modal";
import { useOnnEventSlotDatesForDisplay } from "~/hooks/onnEventSlotDates/useOnnEventSlotDatesForDisplay";

type Props = {
  onnEvent: OnnEvent;
};

export const TabPage: FC<Props> = ({ onnEvent }) => {
  const { currentUser } = useCurrentUser();

  const { data: onnEventSlotDatesForDisplay = [], isLoading: isLoadingFetchingSlots } =
    useOnnEventSlotDatesForDisplay(onnEvent.id);

  const slotIdToSlotForDisplayMap = useMemo(
    () => new Map(onnEventSlotDatesForDisplay.map((slot) => [slot.id, slot])),
    [onnEventSlotDatesForDisplay]
  );

  const { allSelectionState, selectedSlotIds, setSelectedSlotIds, onResetSelectedSlotIds } =
    useSelectedSlots({
      onnEventSlotDatesForDisplay,
    });

  return (
    <>
      <Box my="24px" display="flex" gridGap={24} alignItems="center" justifyContent="space-between">
        {allSelectionState.stateType === "isUnchecked" ? (
          <>
            <Box></Box>
            <Box display={"flex"} gridColumnGap={"24px"} height={"40px"}>
              {currentUser.isAdmin() && (
                <>
                  <AddSlotButton onnEvent={onnEvent} />
                  <TableMenu onnEvent={onnEvent} />
                </>
              )}
            </Box>
          </>
        ) : (
          <>
            <Typography>{`${allSelectionState.label.count}件の予約枠が選択されています`}</Typography>
            <Box display={"flex"} gridColumnGap={"24px"} height={"40px"}>
              {currentUser.isAdmin() && (
                <>
                  <BulkOperationButton
                    onnEvent={onnEvent}
                    selectedSlotIds={selectedSlotIds}
                    onResetSelectedSlotIds={onResetSelectedSlotIds}
                    slotIdToSlotForDisplayMap={slotIdToSlotForDisplayMap}
                  />
                  <CancelSelectionButton onResetSelectedSlotIds={onResetSelectedSlotIds} />
                </>
              )}
            </Box>
          </>
        )}
      </Box>
      <OnnEventSlotDatesMultiGrid
        onnEvent={onnEvent}
        onnEventSlotDatesForDisplay={onnEventSlotDatesForDisplay}
        isLoadingFetchingSlots={isLoadingFetchingSlots}
        setSelectedSlotIds={setSelectedSlotIds}
        allSelectionState={allSelectionState}
        selectedSlotIds={selectedSlotIds}
      />
    </>
  );
};

const BulkOperationButton: FC<{
  onnEvent: OnnEvent;
  selectedSlotIds: string[];
  onResetSelectedSlotIds: () => void;
  slotIdToSlotForDisplayMap: Map<string, OnnEventSlotDatesForDisplay>;
}> = ({ onnEvent, selectedSlotIds, onResetSelectedSlotIds, slotIdToSlotForDisplayMap }) => {
  const selectedSlots = useMemo(() => {
    return selectedSlotIds.flatMap((slotId) => slotIdToSlotForDisplayMap.get(slotId) ?? []);
  }, [selectedSlotIds, slotIdToSlotForDisplayMap]);

  const { openStatusBulkUpdateConfirmModal } = useStatusBulkUpdateConfirmModal({
    onnEventId: onnEvent.id,
    onnEventSlotIds: selectedSlotIds,
    onResetSelectedSlotIds,
  });

  const { openSlotBulkDeleteConfirmModal } = useSlotBulkDeleteConfirmModal({
    onnEventId: onnEvent.id,
    onnEventSlotIds: selectedSlotIds,
    onResetSelectedSlotIds,
  });

  const isShowToPublicMenu = selectedSlots.every((slot) => slot.status === "draft");
  const isShowToDraftMenu = selectedSlots.every(
    (slot) => slot.getStatusForDisplay() === "adjusting" && slot.reservedCount === 0
  );

  const menuItemOptions = [
    {
      onClick: openSlotBulkDeleteConfirmModal,
      text: "予約枠削除",
    },
    isShowToPublicMenu
      ? {
          onClick: () => openStatusBulkUpdateConfirmModal("published"),
          text: "公開",
        }
      : [],
    isShowToDraftMenu
      ? {
          onClick: () => openStatusBulkUpdateConfirmModal("draft"),
          text: "非公開",
        }
      : [],
  ].flat();

  return (
    <UncontrolledMenu
      renderButton={(openMenu) => (
        <Button
          color="primary"
          variant="outlined"
          borderRadius="regular"
          fullWidth
          onClick={openMenu}
          startIcon={<Icon icon="pencil" size="md" color="primary" />}
          endIcon={<Icon icon="dropdownArrow" size="sm" color="primary" />}
        >
          <Box width="100%" display="flex" flex={1}>
            <Typography variant="body2" bold noWrap>
              一括操作
            </Typography>
          </Box>
        </Button>
      )}
      menuItemOptions={menuItemOptions}
    />
  );
};

const CancelSelectionButton: FC<{ onResetSelectedSlotIds: () => void }> = ({
  onResetSelectedSlotIds,
}: {
  onResetSelectedSlotIds: () => void;
}) => {
  return (
    <Button
      onClick={onResetSelectedSlotIds}
      color="default"
      variant="outlined"
      borderRadius="regular"
      fullHeight
    >
      選択解除
    </Button>
  );
};

const AddSlotButton: FC<{ onnEvent: OnnEvent }> = ({ onnEvent }) => {
  const { handleModal } = useModal();

  const openCreateOrEdiOnnEventSlotsModal = useCallback(() => {
    handleModal({
      name: "createOrEditOnnEventSlotDateModal",
      args: {
        data: {
          mode: "create",
          onnEvent,
        },
      },
    });
    return 0;
  }, [handleModal, onnEvent]);

  return (
    <Button
      onClick={openCreateOrEdiOnnEventSlotsModal}
      color="primary"
      variant="outlined"
      borderRadius="regular"
      startIcon={<Icon size="sm" icon="add" color="primary" />}
      fullHeight
    >
      予約枠追加
    </Button>
  );
};

const TableMenu: FC<Props> = ({ onnEvent }) => {
  const { handleModal } = useModal();

  const openCreateOnnEventSlotsWithCSVModal = useCallback(() => {
    handleModal({
      name: "createOnnEventSlotsWithCSVModal",
      args: {
        onnEvent,
      },
    });
  }, [handleModal, onnEvent]);

  return (
    <UncontrolledMenu
      renderButton={(openMenu) => <IconButton icon="menuVert" onClick={openMenu} />}
      menuItemOptions={[
        {
          onClick: openCreateOnnEventSlotsWithCSVModal,
          text: "予約枠一括登録",
        },
      ]}
    />
  );
};
