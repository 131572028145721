import { Employee, NewGraduateToDisplay } from "@onn/common";
import { intersectionBy, orderBy } from "lodash";
import { useState, useMemo, useContext, useCallback } from "react";

import {
  AnyInputCondition,
  AnyValidCondition,
} from "~/components/domains/employees/NewGraduateSearchModal/types/condition";
import { LogicType } from "~/components/domains/employees/NewGraduateSearchModal/types/logic-type";
import { RecruitmentStatusesContext } from "~/components/providers/RecruitmentStatusProvider";
import { useAllNewcomers } from "~/hooks/employee";
import { useMutateAllNewcomers } from "~/hooks/employee/useAllNewcomers";
import { useFilterObjectByPartialMatch, useSnackbar } from "~/hooks/shared";
import { useToggleSelectAll } from "~/hooks/shared/useToggleSelectAll";
import { apiClient } from "~/libs";
import { captureException } from "~/util";

/**
 * 取得したemployeeに基づき各種StatusのEmployeeをmemoするViewModel
 */
export const useViewModel = () => {
  const { data: allNewComers } = useAllNewcomers();
  const { recruitmentStatuses } = useContext(RecruitmentStatusesContext);
  const { filterObjectByPartialMatch } = useFilterObjectByPartialMatch();
  const { enqueueSnackbar } = useSnackbar();
  const { mutateAllNewcomers } = useMutateAllNewcomers();

  const [selectedRecruitmentStatusIdOrNull, setSelectedRecruitmentStatusIdOrNull] = useState<
    string | null
  >(null);
  const [selectedMentorIds, setSelectedMentorIds] = useState<string[]>([]);
  const [searchValue, setSearchValue] = useState("");
  const [selectedEmployees, setSelectedEmployees] = useState<Employee[]>([]);

  /**
   * 新卒候補者
   */
  const allNewGraduates = useMemo(() => {
    if (!allNewComers) return [];
    return allNewComers.filter((v) => v.isNewGraduate);
  }, [allNewComers]);

  const allMentorIds = useMemo(
    () => [...new Set(allNewGraduates?.flatMap((employee) => employee.mentorUserId ?? []))],
    [allNewGraduates]
  );

  const {
    conditions,
    logicType,
    searchedNewGraduateIds,
    validConditionsCount,
    onSearchConfirm,
    onResetSearchConditions,
  } = useNewGraduateSearch();

  /**
   * メアドの絞り込み結果
   */
  const employeesFilteredBySearchValue = useMemo(() => {
    return filterObjectByPartialMatch({
      objects: allNewGraduates,
      searchText: searchValue,
      getProperties: [(employee: Employee) => employee.email],
    });
  }, [allNewGraduates, searchValue, filterObjectByPartialMatch]);

  /**
   * バディの絞り込み結果
   */
  const employeesFilteredByMentorIds = useMemo(() => {
    if (!selectedMentorIds.length) return allNewGraduates;
    return allNewGraduates.filter(
      (employee) => employee.mentorUserId && selectedMentorIds.includes(employee.mentorUserId)
    );
  }, [allNewGraduates, selectedMentorIds]);

  const employeesFilteredBySearch = useMemo(() => {
    if (!searchedNewGraduateIds) return allNewGraduates;
    return allNewGraduates.filter((employee) => searchedNewGraduateIds.includes(employee.id));
  }, [allNewGraduates, searchedNewGraduateIds]);

  /**
   * 絞り込みの積集合
   */
  const filteredNewGraduatesForAllStatus: NewGraduateToDisplay[] = useMemo(() => {
    return intersectionBy(
      employeesFilteredBySearchValue,
      employeesFilteredByMentorIds,
      employeesFilteredBySearch
    );
  }, [employeesFilteredByMentorIds, employeesFilteredBySearch, employeesFilteredBySearchValue]);

  const newGraduatesByRecruitmentStatuses = useMemo(() => {
    return recruitmentStatuses.reduce((acc, cur) => {
      const recruitmentStatusId = cur.id;
      acc[recruitmentStatusId] = filteredNewGraduatesForAllStatus.filter(
        (e) => e.recruitmentStatusId === recruitmentStatusId
      );
      return acc;
    }, {} as Record<string, NewGraduateToDisplay[]>);
  }, [recruitmentStatuses, filteredNewGraduatesForAllStatus]);

  const filteredNewGraduatesByStatus: NewGraduateToDisplay[] = useMemo(() => {
    if (selectedRecruitmentStatusIdOrNull === null) {
      return filteredNewGraduatesForAllStatus;
    }
    return newGraduatesByRecruitmentStatuses[selectedRecruitmentStatusIdOrNull] || [];
  }, [
    selectedRecruitmentStatusIdOrNull,
    newGraduatesByRecruitmentStatuses,
    filteredNewGraduatesForAllStatus,
  ]);

  const { toggleSelectAll: _toggleSelectAll, allSelectionState } = useToggleSelectAll({
    options: filteredNewGraduatesByStatus,
    selectedOptions: selectedEmployees,
  });

  const newGraduatesForTableView: NewGraduateToDisplay[] = useMemo(() => {
    return orderBy(filteredNewGraduatesByStatus, "createdAt", "desc");
  }, [filteredNewGraduatesByStatus]);

  const onResetSelectedEmployees = useCallback(() => {
    setSelectedEmployees([]);
  }, []);

  // TODO: 選考ステータスフィルタを消した段階で削除する
  const changeSelectedRecruitmentStatusIdOrNull = useCallback(
    (value: string | null) => {
      setSelectedRecruitmentStatusIdOrNull(value);
      onResetSelectedEmployees();
    },
    [onResetSelectedEmployees]
  );

  const onSelectEmployee = useCallback((employee: Employee) => {
    setSelectedEmployees((prev) =>
      prev.map((e) => e.id).includes(employee.id)
        ? prev.filter((e) => e.id !== employee.id)
        : [...prev, employee]
    );

    // TODO: 選考ステータスフィルタを消した段階で削除する
    setSelectedRecruitmentStatusIdOrNull(null);
  }, []);

  const toggleSelectAll = useCallback(() => {
    const employees = _toggleSelectAll();
    setSelectedEmployees(employees);

    // TODO: 選考ステータスフィルタを消した段階で削除する
    setSelectedRecruitmentStatusIdOrNull(null);
  }, [_toggleSelectAll]);

  const onConfirmUpdateRecruitmentStatus = useCallback(
    async (statusId: string) => {
      const {
        data: {
          count: { updatedNewGraduates },
        },
      } = await apiClient
        .post("/update_recruitment_statuses", {
          recruitmentStatusId: statusId,
          employeeIds: selectedEmployees.map((e) => e.id),
        })
        .catch((e) => {
          captureException({
            error: new Error("選考ステータスの一括変更に失敗しました"),
            tags: {
              type: "onConfirmUpdateRecruitmentStatus",
            },
            extras: {
              statusId,
              employeeIds: selectedEmployees.map((e) => e.id),
            },
          });
          enqueueSnackbar("選考ステータスの一括変更に失敗しました", { variant: "error" });
          throw e;
        });

      mutateAllNewcomers();
      enqueueSnackbar(`${updatedNewGraduates}名の候補者の選考ステータスが変更されました`, {
        variant: "success",
      });
    },
    [enqueueSnackbar, selectedEmployees, mutateAllNewcomers]
  );

  const onConfirmAddTags = useCallback(
    async (tagIds: string[]) => {
      await apiClient.post("/tag_api/add-tags-to-employees", {
        employeeTagIds: tagIds,
        employeeIds: selectedEmployees.map((e) => e.id),
      });

      mutateAllNewcomers();
      enqueueSnackbar(
        `${selectedEmployees.length}名の候補者に${tagIds.length}件のタグが付与されました`,
        {
          variant: "success",
        }
      );
    },
    [enqueueSnackbar, selectedEmployees, mutateAllNewcomers]
  );

  return {
    allMentorIds,
    filteredNewGraduatesForAllStatus,
    newGraduatesByRecruitmentStatuses,
    newGraduatesForTableView,

    selectedRecruitmentStatusIdOrNull,
    changeSelectedRecruitmentStatusIdOrNull,
    setSearchValue,
    selectedMentorIds,
    setSelectedMentorIds,

    conditions,
    logicType,
    validConditionsCount,
    allNewGraduatesCount: allNewGraduates.length,
    defaultSearchResultCount: employeesFilteredBySearch.length,
    onSearchConfirm,
    onResetSearchConditions,

    onResetSelectedEmployees,
    onSelectEmployee,
    selectedEmployees,
    toggleSelectAll,
    allSelectionState,

    onConfirmUpdateRecruitmentStatus,
    onConfirmAddTags,
  };
};

const useNewGraduateSearch = () => {
  const [conditions, setConditions] = useState<AnyInputCondition[]>([{ target: undefined }]);
  const [logicType, setLogicType] = useState<LogicType>("AND");
  const [searchedNewGraduateIds, setSearchedNewGraduateIds] = useState<string[] | undefined>(
    undefined
  );
  const [validConditionsCount, setValidConditionsCount] = useState(0);

  const { enqueueSnackbar } = useSnackbar();

  const onResetSearchConditions = useCallback(() => {
    setConditions([{ target: undefined }]);
    setValidConditionsCount(0);
    setLogicType("AND");
    setSearchedNewGraduateIds(undefined);
  }, []);

  const onSearchConfirm = useCallback(
    async (logicType: LogicType, conditions: AnyValidCondition[]) => {
      setConditions(conditions.length ? conditions : [{ target: undefined }]);
      setValidConditionsCount(conditions.length);
      setLogicType(logicType);

      if (conditions.length === 0) {
        onResetSearchConditions();
        return;
      }

      const { newGraduateIds } = await apiClient
        .post("/search_new_graduates", {
          type: logicType,
          conditions,
        })
        .catch((e) => {
          enqueueSnackbar("検索に失敗しました。通信環境をご確認の上、再度お試しください。", {
            variant: "error",
          });
          throw e;
        });
      setSearchedNewGraduateIds(newGraduateIds);
    },
    [enqueueSnackbar, onResetSearchConditions]
  );

  return {
    conditions,
    logicType,
    searchedNewGraduateIds,
    validConditionsCount,
    onSearchConfirm,
    onResetSearchConditions,
  };
};
