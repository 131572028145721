import { useCallback, useState } from "react";

import { useSnackbar } from "~/hooks/shared";
import { apiClient } from "~/libs";
import { captureException } from "~/util";

export const useAddTagToEmployees = (): {
  isLoading: boolean;
  addTagToEmployees: (tagId: string, emails: string[], isCsv?: boolean) => Promise<void>;
} => {
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const addTagToEmployees = useCallback(
    async (tagId: string, emails: string[]) => {
      try {
        await apiClient.post("/tag_api/add-tag-on-employees", {
          emails,
          tagId,
        });
        enqueueSnackbar(`${emails.length}名の対象者にタグが付与されました`, {
          variant: "success",
        });
      } catch (e) {
        enqueueSnackbar("タグの付与に失敗しました", { variant: "error" });
        captureException({
          error: e as Error,
          tags: {
            type: "useAddTagToEmployees:addTagToEmployees",
          },
        });
      } finally {
        setIsLoading(false);
      }
    },
    [enqueueSnackbar]
  );

  return { isLoading, addTagToEmployees };
};
