import { Box, colors } from "@material-ui/core";
import { generatePagePath } from "@onn/common";
import { OnnEvent } from "@onn/common/domain/OnnEvent/OnnEvent/OnnEvent";
import React, { FC } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { NewInterviewsListTable } from "./components/Table";
import { useFilter } from "./hooks/useFilter";

import { Button, Icon, SearchForm } from "~/components/uiParts";

export const InterviewEventTabPage: FC<{ allInterviews: OnnEvent[] }> = ({ allInterviews }) => {
  const { setFilter, filteredInterviews } = useFilter({ interviews: allInterviews });
  const { onClickCreateButton } = useOnClickCreateButton();
  // NOTE: 新旧が混在しないようにするため、旧面談イベントが存在する場合にはイベント作成の動線を表示させない
  // 旧面談イベントを移行し切った後に処理を削除する
  const isExistOldInterviews = filteredInterviews.some((v) => v.type === "interview");

  return (
    <>
      <Box>
        <Box display="flex" alignItems={"center"} justifyContent={"space-between"}>
          <Box height="40px" mt="40px" mb="24px" width="224px">
            <StyledSearchForm
              variant="outlined"
              onSearchValue={(value) => setFilter({ interviewName: value })}
              placeholder="イベント名"
              fullHeight
              fullWidth
              backgroundColor={colors.common.white}
            />
          </Box>
          {!isExistOldInterviews && (
            <Box height="40px" mt="40px" mb="24px">
              <Button
                color="primary"
                variant="outlined"
                borderRadius="regular"
                startIcon={<Icon icon="add" color="primary" size="md" />}
                onClick={onClickCreateButton}
                fullHeight
              >
                新規作成
              </Button>
            </Box>
          )}
        </Box>
        <NewInterviewsListTable interviewEvents={filteredInterviews} isLoading={false} />
      </Box>
    </>
  );
};

const useOnClickCreateButton = () => {
  const navigation = useNavigate();
  const onClickCreateButton = () => {
    navigation(generatePagePath.onnEvent.create({ eventType: "new_interview" }));
  };

  return {
    onClickCreateButton,
  };
};

const StyledSearchForm = styled(SearchForm)`
  width: 100%;
  height: 100%;
  .MuiInputBase-root {
    height: 100%;
  }
`;
