import { APISchema, OnnFormTask, OnnTaskQuestion } from "@onn/common";
import { instanceToPlain } from "class-transformer";

import { apiClient } from "~/libs";

type Body = APISchema["/update_onn_form_task"]["POST"]["body"];
export const useUpdateOnnFormTask = () => {
  const updateOnnFormTask = async ({
    onnFormTask,
    questions,
  }: {
    onnFormTask: OnnFormTask;
    questions: OnnTaskQuestion[];
  }) => {
    const body: Body = {
      onnFormTaskId: onnFormTask.id,
      isAllowEditAnswer: onnFormTask.isAllowEditAnswer,
      title: onnFormTask.title,
      description: onnFormTask.description,
      filePaths: onnFormTask.filePaths,
      questions,
    };
    await apiClient.post("/update_onn_form_task", instanceToPlain(body) as Body);
  };

  return { updateOnnFormTask };
};
