import { Box } from "@material-ui/core";
import React, { useState } from "react";

import { FilePreparationStep } from "./FilePreparationStep";

import { FileUploadStep } from "./FileUploadStep";

import { Divider, Modal, VerticalStepper } from "~/components/uiParts";

type Props = {
  open: boolean;
} & ContentProps;

export const AddTagToEmployeesByCSVModal = ({
  open,
  tagId,
  onSubmit,
  onCancel,
}: Props): JSX.Element => {
  return (
    <Modal
      title="CSVアップロード"
      fullWidth
      open={open}
      onCancel={onCancel}
      content={
        <Content
          tagId={tagId}
          onCancel={onCancel}
          onSubmit={(v) => {
            onSubmit(v);
            onCancel();
          }}
        />
      }
    />
  );
};

type ContentProps = {
  tagId: string;
  onCancel: () => void;
  onSubmit: ({ tagId, emails }: { tagId: string; emails: string[] }) => void;
};

function Content(props: ContentProps) {
  const [activeStep, setActiveStep] = useState(1);

  return (
    <Box display="grid" gridTemplateColumns="auto auto 1fr" gridGap="40px">
      <VerticalStepper activeStep={activeStep} labels={["ファイル準備", "アップロード"]} />
      <Divider orientation="vertical" />
      {activeStep === 1 && (
        <FilePreparationStep tagId={props.tagId} onNext={() => setActiveStep((prev) => prev + 1)} />
      )}
      {activeStep === 2 && <FileUploadStep {...props} />}
    </Box>
  );
}
