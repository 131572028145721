import { zodResolver } from "@hookform/resolvers/zod";
import { Typography } from "@material-ui/core";
import { affiliationSchema } from "@onn/common/domain/EmployeeInformation/schema";
import React, { FC } from "react";
import { Controller, useForm } from "react-hook-form";
import styled from "styled-components";
import { z } from "zod";

import { InformationValueEditor } from "../InformationValueEditor";

import { TextField } from "~/components/uiParts";

type Props = {
  onSave: (affiliation: z.infer<typeof affiliationSchema>) => void;
  onCancel: () => void;
  data?: z.infer<typeof affiliationSchema>;
};

export const AffiliationEditor: FC<Props> = ({ onSave, onCancel, data }) => {
  const { handleSubmit, control, formState } = useForm<z.infer<typeof affiliationSchema>>({
    defaultValues: { ...data },
    mode: "onChange",
    resolver: zodResolver(affiliationSchema.required()),
  });

  return (
    <InformationValueEditor
      form={
        <form
          style={{ display: "flex", flexDirection: "column", rowGap: 16 }}
          onSubmit={handleSubmit(onSave)}
        >
          <Controller
            name="schoolName"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <div style={{ display: "flex", alignItems: "center", columnGap: 16 }}>
                <div style={{ width: 120 }}>
                  <Typography variant="body2">学校名</Typography>
                </div>
                <StyledTextField
                  defaultValue={data?.schoolName}
                  placeholder="○○大学"
                  variant="outlined"
                  fullWidth
                  name={field.name}
                  error={!!error}
                  helperText={error?.message}
                  onChange={field.onChange}
                />
              </div>
            )}
          />
          <Controller
            name="faculty"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <div style={{ display: "flex", alignItems: "center", columnGap: 16 }}>
                <div style={{ width: 120 }}>
                  <Typography variant="body2">学部／院区分</Typography>
                </div>
                <StyledTextField
                  defaultValue={data?.faculty}
                  placeholder="□□学部○○学科"
                  variant="outlined"
                  fullWidth
                  name={field.name}
                  error={!!error}
                  helperText={error?.message}
                  onChange={field.onChange}
                />
              </div>
            )}
          />
        </form>
      }
      onSave={handleSubmit(onSave)}
      onCancel={onCancel}
      disabled={!formState.isValid || !formState.isDirty}
    />
  );
};

const StyledTextField = styled(TextField)`
  .MuiInputBase-input {
    font-size: 14px;
    height: 24px;
    padding: 8px 16px;
  }
`;
