import { useCallback, useState } from "react";

import { useAuthorizationNonGuarded } from "~/hooks/context";
import { useRedirectAfterRegistration } from "~/hooks/portal/redirect";
import { useSnackbar } from "~/hooks/shared";

import { useCurrentTenantId } from "~/hooks/tenant/useCurrentTenantId";

import { apiClient } from "~/libs";

/**
 * すでに認証しているユーザーの登録をおこなう
 */
export const useAuthorizeForRegistrationInvitationLink = () => {
  const [isLoading, setIsLoading] = useState(false);

  const { updateCurrentTenantId } = useCurrentTenantId();
  const { mutateAuthorizedUsers } = useAuthorizationNonGuarded();
  const { enqueueSnackbar } = useSnackbar();
  const { redirectAfterRegistration } = useRedirectAfterRegistration();

  const authorize = useCallback(
    async ({
      lineAccessToken,
      firstName,
      lastName,
      email,
      registrationInvitationLinkId,
    }: {
      lineAccessToken?: string;
      firstName: string;
      lastName: string;
      registrationInvitationLinkId: string;
      email: string;
    }) => {
      setIsLoading(true);
      const response = await apiClient
        .post("/authorize_for_registration_invitation_link", {
          registrationInvitationLinkId,
          lineAccessToken,
          firstName,
          lastName,
          email,
        })
        .catch((e) => {
          enqueueSnackbar(e.message, { variant: "error" });
          throw e;
        })
        .finally(() => {
          setIsLoading(false);
        });

      await mutateAuthorizedUsers();
      updateCurrentTenantId(response.data.userAccount.tenantId);
      await redirectAfterRegistration(response.data.userAccount);
      enqueueSnackbar("アカウント登録が完了しました", { variant: "success" });

      return response;
    },
    [mutateAuthorizedUsers, updateCurrentTenantId, redirectAfterRegistration, enqueueSnackbar]
  );

  return { authorize, isLoading };
};
