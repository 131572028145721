import { Transaction, TransactionDataType, MemoTransaction, Memo } from "@onn/common";

import {
  TransactionRepository,
  MemoTransactionRepository,
} from "~/infrastructure/api/transactionRepository";

export interface ITransactionRepository {
  findById(id: string): Promise<Transaction>;
  whereByDataTypes({
    employeeId,
    dataTypes,
  }: {
    employeeId: string;
    dataTypes: (keyof typeof TransactionDataType)[];
  }): Promise<Array<Transaction | MemoTransaction>>;
  updateJoinDateType(tenantId: string, employeeId: string): Promise<void>;
  updateProfileType(tenantId: string, employeeId: string): Promise<void>;
}

export interface IMemoTransactionRepository {
  findMemo(id: string): Promise<MemoTransaction>;
  createMemo({
    tenantId,
    employeeId,
    title,
    text,
    createdEmployeeId,
  }: {
    tenantId: string;
    employeeId: string;
    title: string;
    text: string;
    createdEmployeeId: string;
  }): Promise<{ memoId: string }>;
  editMemo(id: string, memoContent: Memo): Promise<void>;
  deleteMemo(memoId: string): Promise<void>;
}

export const factory = {
  transactionRepository: (): ITransactionRepository => {
    return new TransactionRepository();
  },
  memoTransactionRepository: (): IMemoTransactionRepository => {
    return new MemoTransactionRepository();
  },
};
