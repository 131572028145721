import { v4 } from "uuid";

import { CandidateDate } from "../OnnEvent";

import { FunctionName, INotificationLog, notificationLogSchema } from "./schema";

/**
 * 通知の実行ログ
 * - ログの記録対象は functionName を参照する。全通知のログを記録しているわけではない。
 */
export class NotificationLog implements INotificationLog {
  static validator = notificationLogSchema;

  readonly id: string;
  readonly tenantId: string;
  readonly functionName: FunctionName;
  /**
   * 通知の対象となるドキュメントのID
   * - functionName ごとに、対象が異なる
   * - targetId の扱いは、 NotificationLogService に任せるようにする
   */
  readonly targetId: string;
  readonly createdAt: Date;

  constructor(init: Input) {
    const parsedInit = notificationLogSchema.parse(init);

    this.id = parsedInit.id;
    this.tenantId = parsedInit.tenantId;
    this.functionName = parsedInit.functionName;
    this.targetId = parsedInit.targetId;
    this.createdAt = parsedInit.createdAt;
  }

  private static createNew(init: Omit<Input, "id" | "createdAt">): NotificationLog {
    return new NotificationLog({
      ...init,
      id: v4(),
      createdAt: new Date(),
    });
  }

  public static createLogForNotifyAttendanceRequest(
    tenantId: string,
    candidateDate: CandidateDate
  ): NotificationLog {
    return NotificationLog.createNew({
      tenantId,
      functionName: "notifyAttendanceRequest",
      targetId: candidateDate.id,
    });
  }
}

type Input = ExcludeMethods<NotificationLog>;
