import { EmailType, LineType } from "@onn/common/domain/Employee/AuthenticationType";
import React from "react";

import { Condition } from "../type";

import { ModalContent } from "./ModalContent";
import { ModalFooter } from "./ModalFooter";

export const useAddConditionModeModal = ({
  selectedCondition,
  addSelectedOnnEventIds,
  removeSelectedOnnEventIds,
  changeSelectedRecruitmentStatusId,
  addSelectedAuthenticationFlowTypes,
  removeSelectedAuthenticationFlowTypes,
  addSelectedOnnTaskIds,
  removeSelectedOnnTaskIds,
  addSelectedTagIds,
  removeSelectedTagIds,
  isLoadingFooterButton,
  onAddConditions,
  onCancelAddConditions,
}: {
  selectedCondition: Condition;
  addSelectedAuthenticationFlowTypes: (authenticationFlowTypes: (LineType | EmailType)[]) => void;
  removeSelectedAuthenticationFlowTypes: (
    authenticationFlowTypes: (LineType | EmailType)[]
  ) => void;
  changeSelectedRecruitmentStatusId: (recruitmentStatusId: string) => void;
  addSelectedOnnEventIds: (onnEventIds: string[]) => void;
  removeSelectedOnnEventIds: (onnEventIds: string[]) => void;
  addSelectedOnnTaskIds: (onnTaskIds: string[]) => void;
  removeSelectedOnnTaskIds: (onnTaskIds: string[]) => void;
  addSelectedTagIds: (tagIds: string[]) => void;
  removeSelectedTagIds: (tagIds: string[]) => void;
  isLoadingFooterButton: boolean;
  onAddConditions: () => Promise<void>;
  onCancelAddConditions: () => void;
}) => {
  return {
    title: "招待QR・URL｜条件付与",
    titleHint: undefined,
    content: (
      <ModalContent
        selectedCondition={selectedCondition}
        addSelectedAuthenticationFlowTypes={addSelectedAuthenticationFlowTypes}
        removeSelectedAuthenticationFlowTypes={removeSelectedAuthenticationFlowTypes}
        addSelectedOnnEventIds={addSelectedOnnEventIds}
        removeSelectedOnnEventIds={removeSelectedOnnEventIds}
        addSelectedOnnTaskIds={addSelectedOnnTaskIds}
        removeSelectedOnnTaskIds={removeSelectedOnnTaskIds}
        addSelectedTagIds={addSelectedTagIds}
        removeSelectedTagIds={removeSelectedTagIds}
        changeSelectedRecruitmentStatusId={changeSelectedRecruitmentStatusId}
      />
    ),
    footer: (
      <ModalFooter
        isLoading={isLoadingFooterButton}
        onAddConditions={onAddConditions}
        onCancel={onCancelAddConditions}
      />
    ),
  };
};
