import { TransactionStatus, Transaction, MemoTransaction, Employee } from "@onn/common";
import { useCallback, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";

import { calcAccessibleEmployees } from "../functions/calcAccessibleEmployees";

import { sortTransactions } from "../functions/sortTransactions";
import { useGetIsAccessibleEmployeePage } from "../hooks";
import { useMemoUseCase } from "../hooks/useMemoUseCase";
import { useOtherEmployees } from "../hooks/useOtherEmployees";
import { TransactionCommentsMap, useTransactionComments } from "../hooks/useTransactionComments";

import type { OtherEmployee } from "../functions/calcAccessibleEmployees";

import { useAccessControl } from "~/hooks/accessControl";
import { useCurrentUser } from "~/hooks/employee/useCurrentUser";
import { useSnackbar } from "~/hooks/shared";
import { useCurrentSpace } from "~/hooks/space/useCurrentSpace";
import { useTransactions } from "~/hooks/transaction/useTransactions";

type MemoUseCase = ReturnType<typeof useMemoUseCase>;

export const useViewModel = (
  newHireEmployee?: Employee
): {
  transactionsLoading: boolean;
  memoAttachedFilesLoading: boolean;
  commentsLoading: boolean;
  transactionCommentsMap: TransactionCommentsMap;
  transactions: (Transaction | MemoTransaction)[] | undefined;
  memos: MemoUseCase["memos"];
  memoAttachedFilesMap: MemoUseCase["attachedFilesMap"];
  currentUser: Employee;
  accessibleEmployees: OtherEmployee[];
  handleDropFilesError: (message: string) => void;
  handleCreateMemo: MemoUseCase["handleCreateMemo"];
  handleEditMemo: MemoUseCase["handleEditMemo"];
  handleDeleteMemo: MemoUseCase["handleDeleteMemo"];
  handleDownloadMemoAttachedFile: MemoUseCase["handleDownloadAttachedFile"];
  handleDeleteMemoAttachedFile: MemoUseCase["handleDeleteAttachedFile"];
  loadTransactionComments: (transactionId: string) => void;
} => {
  const navigate = useNavigate();

  const { enqueueSnackbar } = useSnackbar();

  const { currentUser } = useCurrentUser();
  const { isEditable } = useAccessControl();
  const { switchSpaceTemporary } = useCurrentSpace();

  useEffect(() => {
    if (!newHireEmployee?.spaceId) return;

    switchSpaceTemporary(newHireEmployee.spaceId);
  }, [switchSpaceTemporary, newHireEmployee]);

  const { getIsAccessibleEmployeePage } = useGetIsAccessibleEmployeePage();

  const { otherEmployees } = useOtherEmployees({ newHireEmployee });
  const { data: transactions, mutate: mutateTransactions } = useTransactions(newHireEmployee?.id);

  const transactionIds = useMemo(() => {
    if (!transactions) return [];
    return transactions.map(({ id }) => id);
  }, [transactions]);

  const { commentsLoading, loadTransactionComments, transactionCommentsMap } =
    useTransactionComments({
      transactionIds,
      otherEmployees,
    });

  const accessibleEmployees = useMemo(
    () =>
      calcAccessibleEmployees({
        newHireEmployee,
        otherEmployees,
      }),
    [newHireEmployee, otherEmployees]
  );

  const sortedTransactions = useMemo(() => {
    if (!transactions) return undefined;

    const completedTransactions = transactions.filter((t) => t.status !== TransactionStatus.SENT);
    return sortTransactions([...completedTransactions]);
  }, [transactions]);

  const memoTransactions = useMemo<MemoTransaction[]>(
    () =>
      sortedTransactions?.filter((v): v is MemoTransaction => v instanceof MemoTransaction) ?? [],
    [sortedTransactions]
  );

  const handleDropFilesError = useCallback(
    (message: string) => {
      enqueueSnackbar(message, { variant: "error" });
    },
    [enqueueSnackbar]
  );

  const {
    loading: memoOperationLoading,
    attachedFilesLoading,
    memos,
    attachedFilesMap,
    handleCreateMemo,
    handleEditMemo,
    handleDeleteMemo,
    handleDownloadAttachedFile,
    handleDeleteAttachedFile,
  } = useMemoUseCase({
    newHireEmployee,
    otherEmployees,
    memoTransactions,
    onSuccess: () => mutateTransactions(),
  });

  // 同じテナントの入社者か確認する
  useEffect(() => {
    if (newHireEmployee && currentUser?.tenantId !== newHireEmployee.tenantId) {
      enqueueSnackbar("このページの閲覧権限がありません", { variant: "error" });
      navigate("/");
    }
  }, [currentUser?.tenantId, enqueueSnackbar, navigate, newHireEmployee]);

  // 削除されていない入社者か確認する
  useEffect(() => {
    if (newHireEmployee && newHireEmployee.deleted) {
      enqueueSnackbar("このページの閲覧権限がありません", { variant: "error" });
      navigate("/");
    }
  }, [currentUser, enqueueSnackbar, navigate, newHireEmployee]);

  // currentUserがアクセスできるかを確認する
  useEffect(() => {
    if (newHireEmployee && !getIsAccessibleEmployeePage(currentUser, newHireEmployee, isEditable)) {
      enqueueSnackbar("このページの閲覧権限がありません", { variant: "error" });
      navigate("/");
    }
  }, [
    currentUser,
    enqueueSnackbar,
    getIsAccessibleEmployeePage,
    isEditable,
    navigate,
    newHireEmployee,
  ]);

  return {
    transactionsLoading: memoOperationLoading || !transactions,
    memoAttachedFilesLoading: attachedFilesLoading,
    commentsLoading,
    transactionCommentsMap,
    transactions: sortedTransactions,
    memos,
    memoAttachedFilesMap: attachedFilesMap,
    currentUser,
    accessibleEmployees,
    handleDropFilesError,
    handleCreateMemo,
    handleEditMemo,
    handleDeleteMemo,
    handleDownloadMemoAttachedFile: handleDownloadAttachedFile,
    handleDeleteMemoAttachedFile: handleDeleteAttachedFile,
    loadTransactionComments,
  };
};
