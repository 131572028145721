import { OnnEvent, OnnEventSlotDate } from "@onn/common";
import { format } from "date-fns";

import { InputState } from "./zodFormSchema";

export const generateInitialValues = ({
  onnEventSlotDate,
  defaultValueSettings,
}: {
  onnEventSlotDate?: OnnEventSlotDate;
  defaultValueSettings: OnnEvent["slotDefaultValueSetting"];
}): InputState => {
  if (!onnEventSlotDate) {
    return {
      assigneeId: undefined,
      slotDate: new Date(),
      slotFromTimeString: "10:00",
      slotUntilString: "11:00",
      capacity: "",
      slotInfo: {
        type: defaultValueSettings?.slotType || "online",
        online: {
          description: defaultValueSettings?.online?.description || "",
          url: defaultValueSettings?.online?.url || "",
        },
        offline: {
          description: defaultValueSettings?.offline?.description || "",
          location: "",
          postCode: "",
          address: "",
        },
      },
    };
  }

  return {
    assigneeId: onnEventSlotDate.assigneeId || undefined,
    slotDate: onnEventSlotDate.from,
    slotFromTimeString: format(onnEventSlotDate.from, "HH:mm"),
    slotUntilString: format(onnEventSlotDate.until, "HH:mm"),
    capacity: String(onnEventSlotDate.capacity),
    slotInfo: {
      type: onnEventSlotDate.eventType,
      online: {
        description: (onnEventSlotDate.isOnline() && onnEventSlotDate.description) || "",
        url: onnEventSlotDate.url || "",
      },
      offline: {
        description: (onnEventSlotDate.isOffline() && onnEventSlotDate.description) || "",
        location: onnEventSlotDate.eventPlaceId || "",
        postCode: onnEventSlotDate.eventAddressPostCode || "",
        address: onnEventSlotDate.eventAddressText || "",
      },
    },
  };
};
