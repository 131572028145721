import { Box, Tab } from "@material-ui/core";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import { OnnEvent, generatePagePath } from "@onn/common";
import React, { FC, useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { useTabs } from "./hooks/useTabs";
import { InterviewEventTabPage } from "./tabs/interview/tabPage";
import { NormalEventTabPage } from "./tabs/normal/tabPage";

import { Button, Icon, Loading, Typography } from "~/components/uiParts";
import { useAllOnnEvent } from "~/hooks/onnEvent";

type PartitionedOnnEvents = {
  interviews: OnnEvent[];
  others: OnnEvent[];
};
export const Page: FC = () => {
  const { tabs, activeTab, onChangeActiveTab } = useTabs();

  /**
   * テナントに紐づく全てのイベント一覧
   */
  const { data: allOnnEvents = [], isLoading } = useAllOnnEvent();

  const partitionedOnnEvents = useMemo(
    () =>
      allOnnEvents.reduce<PartitionedOnnEvents>(
        (acc, event) => {
          if (event.type === "interview" || event.type === "new_interview") {
            acc.interviews.push(event);
          } else {
            acc.others.push(event);
          }
          return acc;
        },
        {
          interviews: [],
          others: [],
        }
      ),
    [allOnnEvents]
  );

  const navigate = useNavigate();
  const onClickSettings = useCallback(() => {
    navigate(generatePagePath.onnEvent.setting());
  }, [navigate]);

  return (
    <>
      <Box mb={4} display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
        <Typography variant="h4" color="textPrimary" bold>
          イベント
        </Typography>
        <Button
          variant="text"
          color="default"
          borderRadius="regular"
          startIcon={<Icon icon="setting" size="sm" color="grey400" />}
          onClick={onClickSettings}
        >
          設定
        </Button>
      </Box>
      <TabContext value={activeTab}>
        <StyledTabList indicatorColor="primary" textColor="primary" onChange={onChangeActiveTab}>
          {tabs.map((tab) => (
            <StyledTab key={tab.value} label={tab.label} value={tab.value} />
          ))}
        </StyledTabList>
        {isLoading ? (
          <Box height={"600px"}>
            <Loading size="large" />
          </Box>
        ) : (
          <>
            <StyledTabPanel value="other">
              <NormalEventTabPage allNormalOnnEvents={partitionedOnnEvents.others} />
            </StyledTabPanel>
            <StyledTabPanel value="interview">
              <InterviewEventTabPage allInterviews={partitionedOnnEvents.interviews} />
            </StyledTabPanel>
          </>
        )}
      </TabContext>
    </>
  );
};

const StyledTab = styled(Tab)`
  &.MuiTab-root {
    padding: 0px 20px;
  }
`;

const StyledTabList = styled(TabList)`
  &.MuiTabs-root {
    border-bottom: 1px solid ${(props) => props.theme.palette.divider};
  }
`;

const StyledTabPanel = styled(TabPanel)`
  width: 100%;
  &.MuiTabPanel-root {
    padding: 0;
  }
`;
