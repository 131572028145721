import { Employee, OnnEvent } from "@onn/common";

import { useCancelButtonHandler } from "../../_share/hooks/useCancelButtonHandler";
import { useFromPageInQS } from "../../_share/hooks/useFromPageInQS";
import { useGoBackButtonHandler } from "../../_share/hooks/useGoBackButtonHandler";

import { useSubmitButtonHandler } from "./useSubmitButtonHandler";

export const useActionFooter = ({
  onnEvent,
  employeeToDeliverIds,
  setSelectedNewGraduates,
}: {
  onnEvent: OnnEvent;
  employeeToDeliverIds: string[];
  setSelectedNewGraduates: React.Dispatch<React.SetStateAction<Employee[]>>;
}) => {
  const fromPage = useFromPageInQS();
  const { onClickCancel } = useCancelButtonHandler({ onnEvent });

  const { onClickGoBack } = useGoBackButtonHandler(onnEvent.id);

  const { onClickConfirmSave, isLoading: isSubmittingLoading } = useSubmitButtonHandler({
    onnEvent,
    employeeToDeliverIds,
    setSelectedNewGraduates,
  });

  const isDisabledSaveButton = isSubmittingLoading || employeeToDeliverIds.length === 0;
  return {
    cancelButtonText: fromPage === "create" ? "あとで設定" : "キャンセル",
    submitButtonText: "配信",
    onClickGoBack,
    onClickCancel,
    onClickConfirmSave,
    isDisabledSaveButton,
    isSubmittingLoading,
  };
};
