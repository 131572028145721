import { Box, FormControlLabel, Tab } from "@material-ui/core";
import { TabContext, TabList } from "@material-ui/lab";
import React, { FC } from "react";
import styled from "styled-components";

import { useViewModelV2 } from "./hooks/useViewModelV2";

import {
  ContactRoomListItemV2,
  NewGraduatePCContactRoomItem,
} from "~/components/domains/contactRooms";

import { Checkbox, Icon, Loading, TextField, Tooltip, Typography } from "~/components/uiParts";
import { AutoSizer, List as VirtualizedList } from "~/components/uiParts/ReactVirtualized";
import { useCurrentUser } from "~/hooks/employee";
import { captureException } from "~/util";

export const NewGraduatePCContactMessages: FC = () => {
  const {
    currentContactRooms,
    activeRecruitmentStatusTab,
    isDisplayOnlyMyCandidates,
    searchValue,
    contactRoomIdToUnreadCountMap,
    selectedContactRoomId,
    recruitmentStatusTabs,
    isLoading,
    contactMessageDraft,
    isLoadingDraft,
    saveContactMessageDraft,
    handleChangeSearchValue,
    handleChangeIsDisplayOnlyMyCandidates,
    handleChangeActiveRecruitmentStatus,
    handleOnClickContactRoomListItem,
    isLoadingAllContactRooms,
    contactRoomIdToLatestMessageMap,
    isLoadingUnreadMessageCountList,
  } = useViewModelV2();
  const { currentUser } = useCurrentUser();

  return (
    <StyledContainerBox>
      <Box display="flex" height="100vh">
        <StyledBox width="360px" bgcolor="white" display="flex" flexDirection="column">
          {/* NOTE: TabContextではnullを使えないため文字列("all")として表現 */}
          <TabContext value={activeRecruitmentStatusTab || "all"}>
            <Box width="360px" pl="24px" pt="40px" pb="16px">
              <StyledTabList
                onChange={handleChangeActiveRecruitmentStatus}
                indicatorColor="primary"
                textColor="primary"
              >
                {recruitmentStatusTabs.map((tab) => (
                  <StyledTab
                    key={tab.recruitmentStatusIdOrAll}
                    label={tab.label}
                    value={tab.recruitmentStatusIdOrAll}
                  />
                ))}
              </StyledTabList>
              {isLoadingAllContactRooms ? (
                <Box height="51px" pt="16px">
                  <Loading size="small" />
                </Box>
              ) : (
                <Box mt="16px" display={"flex"} pr="24px">
                  <StyledTextField
                    autoFocus
                    fullWidth
                    variant="outlined"
                    value={searchValue}
                    placeholder="氏名またはメールアドレス"
                    onChange={(e) => handleChangeSearchValue(e.target.value)}
                    endAdornment={<Icon size="ssm" color="grey" icon="search" />}
                  />
                  <Box ml="16px" display={"flex"} gridGap="8px" alignItems={"center"}>
                    {currentUser.isAdmin() && (
                      <Box
                        p="4px"
                        height="32px"
                        onClick={() => {
                          window.open("/contact_rooms/contact_messages_orders/new", "_blank");
                        }}
                      >
                        <Tooltip title="新規メッセージ作成">
                          <StyledIcon icon="edit" size="md" color="primary" />
                        </Tooltip>
                      </Box>
                    )}
                  </Box>
                </Box>
              )}
              <FormControlLabel
                style={{ margin: "16px 0px 0px" }}
                control={
                  <Checkbox
                    style={{ padding: 0, marginRight: "8px" }}
                    checked={isDisplayOnlyMyCandidates}
                    onChange={handleChangeIsDisplayOnlyMyCandidates}
                  />
                }
                label={
                  <Box
                    width="100%"
                    display="flex"
                    alignItems="center"
                    gridGap="4px"
                    overflow="hidden"
                  >
                    <Typography variant="body2" color="textSecondary" noWrap>
                      担当候補者を絞り込む
                    </Typography>
                  </Box>
                }
              />
            </Box>
            <Box height="100%">
              {isLoading ? (
                <Loading size={"small"} />
              ) : (
                <AutoSizer>
                  {(size) => (
                    <VirtualizedList
                      height={size.height}
                      width={size.width}
                      overscanRowCount={10} // 先にレンダリングしておくリストの数
                      rowCount={currentContactRooms.length}
                      rowHeight={80}
                      rowRenderer={(props) => {
                        const contactRoom = currentContactRooms[
                          props.index
                        ] as (typeof currentContactRooms)[number];
                        const latestMessage = contactRoomIdToLatestMessageMap.get(contactRoom.id);

                        const unreadCount = contactRoomIdToUnreadCountMap.get(contactRoom.id);
                        // NOTE: コンタクトルームは存在するのに、未読情報が存在しない場合はSentryで通知を行う
                        if (unreadCount === undefined && !isLoadingUnreadMessageCountList) {
                          captureException({
                            error: new Error("未読情報が存在していません"),
                            tags: {
                              type: "NewGraduatePCContactMessages",
                            },
                            extras: {
                              contactRoomId: contactRoom.id,
                              contactRoomIdToUnreadCountMap,
                              contactRoomIdToUnreadCountMapSize: contactRoomIdToUnreadCountMap.size,
                            },
                          });
                        }
                        return (
                          <Box
                            key={props.key}
                            style={props.style} // 動的に計算されるtopなどのプロパティが入る
                          >
                            <ContactRoomListItemV2
                              key={props.key}
                              isSelected={
                                !!selectedContactRoomId && contactRoom.id === selectedContactRoomId
                              }
                              onClickContactRoomListItem={() => {
                                handleOnClickContactRoomListItem(contactRoom.id);
                              }}
                              contactRoom={contactRoom}
                              unreadCount={unreadCount || 0}
                              latestMessage={latestMessage}
                            />
                          </Box>
                        );
                      }}
                      noRowsRenderer={NoRowsRenderer}
                    />
                  )}
                </AutoSizer>
              )}
            </Box>
          </TabContext>
        </StyledBox>
        <Box width="100%" overflow="scroll">
          {selectedContactRoomId &&
            (!isLoadingDraft ? (
              <NewGraduatePCContactRoomItem
                contactMessageDraft={contactMessageDraft}
                contactRoomId={selectedContactRoomId}
                saveContactMessageDraft={saveContactMessageDraft}
              />
            ) : (
              <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                <Loading size="large" />
              </Box>
            ))}
        </Box>
      </Box>
    </StyledContainerBox>
  );
};

const NoRowsRenderer = () => (
  <Box height="100%" display="flex" justifyContent="center" alignItems="center">
    <Typography variant="body1" align="center">
      {`担当者になっている\nコンタクトはありません`}
    </Typography>
  </Box>
);

const StyledContainerBox = styled(Box)`
  background-color: ${(props) => props.theme.palette.grey[50]};
  min-height: 100vh;
`;

const StyledTextField = styled(TextField)`
  .MuiOutlinedInput-root {
    height: 40px;
  }
  .MuiOutlinedInput-input {
    padding: 8px 12px;
  }
  .MuiInputBase-input {
    font-size: 14px;
  }
`;

const StyledBox = styled(Box)`
  box-shadow: ${(props) => props.theme.shadows[2]};
  z-index: 1;
`;

const StyledTabList = styled(TabList)`
  .MuiTabs-flexContainer {
    display: inherit;
  }
  &.MuiTabs-root {
    min-height: 36px;
    font-size: 14px;
    padding-top: 0px;
    border-bottom: 1px solid ${(props) => props.theme.palette.divider};
  }
`;

const StyledTab = styled(Tab)`
  &.MuiTab-root {
    min-height: 36px;
    margin-right: 40px;
    font-size: 14px;
    align-items: flex-start;
  }
`;

const StyledIcon = styled(Icon)`
  cursor: pointer;
`;
