import React, { FC, useEffect } from "react";

import { useParams } from "react-router-dom";

import { EventEditor } from "./EventEditor";

import { Loading } from "~/components/uiParts";

import { useCurrentUser } from "~/hooks/employee";
import { useOnnEvent, useCandidateDatesWithNumberOfParticipants } from "~/hooks/onnEvent";

import { useCurrentSpace } from "~/hooks/space/useCurrentSpace";
import { useTenant } from "~/hooks/tenant";
import { NotFound } from "~/pages/NotFound";

export const Page: FC = () => {
  const { tenant } = useTenant();
  const { currentUser } = useCurrentUser();
  const { switchSpaceTemporary } = useCurrentSpace();
  const params = useParams();
  const onnEventId = params.id;
  const { data: currentOnnEvent, isLoading } = useOnnEvent({
    tenantId: tenant.tenantId,
    onnEventId: onnEventId,
  });

  useEffect(() => {
    if (!currentOnnEvent) return;
    switchSpaceTemporary(currentOnnEvent.spaceId);
  }, [currentOnnEvent, switchSpaceTemporary]);

  const { data: candidateDatesWithNumberOfParticipants, isLoading: isLoadingCandidateDate } =
    useCandidateDatesWithNumberOfParticipants({ currentEmployeeId: currentUser.id, onnEventId });

  if (isLoading || isLoadingCandidateDate) return <Loading size="large" fullHeight />;

  if (!onnEventId || !currentOnnEvent) {
    return <NotFound />;
  }

  return (
    <EventEditor
      currentOnnEvent={currentOnnEvent}
      candidateDatesWithNumberOfParticipants={candidateDatesWithNumberOfParticipants || []}
    />
  );
};
