import { APISchema } from "@onn/common";

import { useSnackbar } from "../shared";

import { useOnnTasks } from "./useOnnTasks";

import { apiClient } from "~/libs";
import { captureException } from "~/util";

/**
 * onnTaskを削除するためのhooks
 */
export const useDeleteOnnTask = (tenantId: string) => {
  const { enqueueSnackbar } = useSnackbar();
  const { mutate: fetchOnnTasks } = useOnnTasks({ tenantId });

  const deleteOnnTask = async ({ id }: { id: string }) => {
    const queryParam: APISchema["/delete_onn_task"]["DELETE"]["query"] = {
      id: id,
    };

    try {
      await apiClient.delete(`/delete_onn_task`, queryParam);
      await fetchOnnTasks();
      enqueueSnackbar("タスクを削除しました", { variant: "success" });
    } catch (e) {
      enqueueSnackbar("タスクの削除に失敗しました", { variant: "error" });
      captureException({
        error: e as Error,
        tags: {
          type: "useDeleteOnnTask:deleteOnnTask",
        },
      });
    }
  };

  return { deleteOnnTask };
};
