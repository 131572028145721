import { Box } from "@material-ui/core";
import React, { FC } from "react";
import styled from "styled-components";

import { Typography } from "~/components/uiParts";

export const CellItem: FC<{
  style: React.CSSProperties;
  text: string;
  isLastColumn: boolean;
}> = ({ style, text, isLastColumn }) => {
  return (
    <StyledBox style={{ ...style }} isLastColumn={isLastColumn}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          height: "100%",
          width: "100%",
          justifyContent: "center",
        }}
      >
        <Typography variant="body2">{text}</Typography>
      </Box>
    </StyledBox>
  );
};

export const StyledBox = styled(Box)<{ isLastColumn: boolean }>`
  border: solid ${(props) => props.theme.palette.grey[100]};
  background-color: ${(props) => props.theme.palette.grey[50]};
  border-width: 0px ${(props) => (props.isLastColumn ? 0 : 1)}px 0px 0px;
  text-align: center;
`;
