import { v4 } from "uuid";

import { IRegistrationInvitationLinkAccessLog, schema } from "./schema";

export class RegistrationInvitationLinkAccessLog implements IRegistrationInvitationLinkAccessLog {
  private static validator = schema;
  readonly id: string;
  readonly tenantId: string;
  readonly externalLineUserId: string;
  readonly registrationInvitationLinkId: string;
  readonly createdAt: Date;

  constructor(init: ExcludeMethods<RegistrationInvitationLinkAccessLog>) {
    RegistrationInvitationLinkAccessLog.validator.parse(init);

    this.id = init.id;
    this.tenantId = init.tenantId;
    this.externalLineUserId = init.externalLineUserId;
    this.registrationInvitationLinkId = init.registrationInvitationLinkId;
    this.createdAt = init.createdAt;
  }

  static createNew(
    params: Omit<ExcludeMethods<RegistrationInvitationLinkAccessLog>, "id" | "createdAt">
  ) {
    return new RegistrationInvitationLinkAccessLog({
      ...params,
      id: v4(),
      createdAt: new Date(),
    });
  }
}
