import { useCallback, useState } from "react";
import { useSWRConfig } from "swr";

import { useMutateAllNewcomers } from "../useAllNewcomers";
import { generateUseEmployeeKeys } from "../useEmployee";

import { generateUseNewGraduateKey } from "../useNewGraduate";

import { useSnackbar } from "~/hooks/shared";

import { apiClient } from "~/libs";
import { captureException } from "~/util";

/**
 * 一括で招待通知を送信する関数を提供するhooks
 */
export const useSendInvitationNotifications = () => {
  const [isLoading, setIsLoading] = useState(false);

  const { enqueueSnackbar } = useSnackbar();
  const { mutate } = useSWRConfig();
  const { mutateAllNewcomers } = useMutateAllNewcomers();

  /**
   * 一括で招待通知を送信する関数
   */
  const sendInvitations = useCallback(
    async (employeeIds: string[]) => {
      setIsLoading(true);
      await apiClient
        .post(`/send_invitation_notifications`, {
          "employee-ids": employeeIds,
        })
        .then(() => {
          employeeIds.forEach((employeeId) => {
            mutate(generateUseEmployeeKeys(employeeId));
            mutate(generateUseNewGraduateKey({ newGraduateId: employeeId }));
          });
          mutateAllNewcomers();
          enqueueSnackbar("招待メールを送信しました", {
            variant: "success",
          });
        })
        .catch((error) => {
          enqueueSnackbar(error.message, { variant: "error" });
          captureException({
            error: error as Error,
            tags: { type: "useSendInvitationNotifications:sendInvitations" },
          });
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [mutateAllNewcomers, enqueueSnackbar, mutate]
  );

  return { sendInvitations, isLoading };
};
