import React, { useMemo } from "react";

import { ModalContentLayout } from "./ModalContentLayout";
import { RecruitmentStatusSelector } from "./parts/RecruitmentStatusSelector";

type Props = {
  selectedStatusId: string | undefined;
  onChangeStatusId(statusId: string): void;
};
export const ModalContent = ({ selectedStatusId, onChangeStatusId }: Props): JSX.Element => {
  const recruitmentStatusSelector = useMemo(
    () => (
      <RecruitmentStatusSelector selectedStatusId={selectedStatusId} onChange={onChangeStatusId} />
    ),
    [onChangeStatusId, selectedStatusId]
  );

  return <ModalContentLayout recruitmentStatusSelector={recruitmentStatusSelector} />;
};
