import React, { FC, useMemo } from "react";
import { useController, Control } from "react-hook-form";
import styled from "styled-components";

import { InputState } from "./InputState";

import { TextField } from "~/components/uiParts";

type Props = {
  control: Control<InputState>;
};

export const TitleTextField: FC<Props> = ({ control }) => {
  const {
    field: { onChange, value },
    fieldState: { error },
  } = useController({ control, name: "title" });
  const isError = error !== undefined;
  const helperText = isError
    ? value.length === 0
      ? "イベントタイトルを入力してください"
      : error.message
    : "";
  const Component = useMemo(() => {
    return (
      <OnnEventTitleTextField
        name="title"
        placeholder="イベントタイトルを入力してください"
        fullWidth
        value={value}
        onChange={onChange}
        error={isError}
        autoFocus
        maxTextCount={50}
        helperText={helperText}
      />
    );
  }, [helperText, isError, onChange, value]);

  return Component;
};

const OnnEventTitleTextField = styled(TextField)`
  color: ${(props) => props.theme.palette.text.muted};

  .MuiInput-underline:before {
    border-bottom: 1px solid ${(props) => props.theme.palette.text.muted};
  }
  .MuiInputBase-input {
    font-weight: normal;
    font-size: 24px;
    margin-bottom: 16px;
    padding-bottom: 0px;
    height: 1.4em; // アルファベットのgが切れるのを防ぐ
  }
`;
