import { Employee } from "@onn/common";
import { useCallback } from "react";

import { useSWRConfig } from "swr";

import { useAllNewcomers } from "../useAllNewcomers";
import { generateUseEmployeeKeys } from "../useEmployee";

import { generateUseNewGraduateKey } from "../useNewGraduate";

import { useAssignFollowers } from "./useAssignFollowers";

import { useAssignMentorWithAssignContactRoom } from "./useAssignMentorWithAssignContactRoom";

import { useDismissFollower } from "./useDismissFollower";

import { useAccessControl } from "~/hooks/accessControl";
import { useModal } from "~/hooks/modal";

/**
 * 新卒候補者操作系のモーダルの呼び出し
 * 複数箇所で利用されるのでこのモーダルにロジックなどを隠蔽する
 */
export const useNewGraduateModals = () => {
  const { handleModal } = useModal();
  const { mutate } = useSWRConfig();
  const { isEditable } = useAccessControl();
  const { mutate: mutateAllNewcomers } = useAllNewcomers();
  const { assignMentorWithAssignContactRoom } = useAssignMentorWithAssignContactRoom();
  const { assignFollowers } = useAssignFollowers();
  const { dismissFollower } = useDismissFollower();

  const handleOpenAssignNewGraduateMentorModal = useCallback(
    (newGraduate: Employee, mentor?: Employee, followers?: Employee[]) =>
      handleModal({
        name: "assignNewGraduateMentorModal",
        args: {
          onSubmit: async (newMentorId?: string, emailForInvite?: string) => {
            // 担当者アサイン + コンタクトルーム担当者にアサイン
            await assignMentorWithAssignContactRoom({
              newGraduate,
              emailForInvite,
              newMentorId,
              mentor,
            });
          },
          newGraduate,
          mentor,
          followers,
        },
      }),
    [assignMentorWithAssignContactRoom, handleModal]
  );

  const handleOpenAssignNewGraduateFollowersModal = useCallback(
    (newGraduate: Employee, mentor?: Employee, followers?: Employee[]) =>
      handleModal({
        name: "assignNewGraduateFollowersModal",
        args: {
          onSubmit: async (employeeIds: string[]) => {
            await assignFollowers({ employeeIds, newGraduate });
          },
          newGraduate,
          mentor,
          followers,
        },
      }),
    [assignFollowers, handleModal]
  );

  const handleOpenChangeNewGraduateMentorConfirmModal = useCallback(
    (newGraduate: Employee, mentor: Employee, followers?: Employee[]) =>
      handleModal({
        name: "changeNewGraduateMentorConfirmModal",
        args: {
          onSubmit: () => handleOpenAssignNewGraduateMentorModal(newGraduate, mentor, followers),
          profileIconImageUrl: mentor.profileIconImageUrl || "",
          username: mentor.getName(),
        },
      }),
    [handleModal, handleOpenAssignNewGraduateMentorModal]
  );

  const handleOpenDismissNewGraduateFollowerConfirmModal = useCallback(
    (newGraduate: Employee, follower: Employee) =>
      handleModal({
        name: "dismissNewGraduateFollowerConfirmModal",
        args: {
          onSubmit: async () => {
            await dismissFollower(newGraduate.id, follower.id).then(async () => {
              mutate(generateUseEmployeeKeys(newGraduate.id));
              mutate(generateUseNewGraduateKey({ newGraduateId: newGraduate.id }));
              mutateAllNewcomers();
            });
          },
          profileIconImageUrl: follower.profileIconImageUrl,
          username: follower.getName(),
        },
      }),
    [dismissFollower, handleModal, mutate, mutateAllNewcomers]
  );

  const handleOpenManageMentorAndFollowersModal = useCallback(
    (newHire: Employee, mentor?: Employee, followers?: Employee[]) =>
      handleModal({
        name: "newGraduateManageMentorAndFollowersModal",
        args: {
          onClickAddMentorButton: () => {
            handleOpenAssignNewGraduateMentorModal(newHire, mentor, followers);
          },
          onClickAddAssignFollowers: () => {
            handleOpenAssignNewGraduateFollowersModal(newHire, mentor, followers);
          },
          onClickChangeMentorButton: (mentor: Employee) => {
            handleOpenChangeNewGraduateMentorConfirmModal(newHire, mentor, followers);
          },
          onClickDismissButton: (follower: Employee) => {
            handleOpenDismissNewGraduateFollowerConfirmModal(newHire, follower);
          },
          mentor,
          followers,
          onlyView: !isEditable(newHire),
        },
      }),
    [
      handleModal,
      isEditable,
      handleOpenAssignNewGraduateMentorModal,
      handleOpenAssignNewGraduateFollowersModal,
      handleOpenChangeNewGraduateMentorConfirmModal,
      handleOpenDismissNewGraduateFollowerConfirmModal,
    ]
  );

  return {
    handleOpenAssignNewGraduateFollowersModal,
    handleOpenAssignNewGraduateMentorModal,
    handleOpenManageMentorAndFollowersModal,
  };
};
