import { Box, AppBar } from "@material-ui/core";
import React, { FC } from "react";
import styled from "styled-components";

import { Button, Divider, Icon } from "~/components/uiParts";

import theme from "~/config/theme";

type Props = {
  onClickSaveAndToNext: () => Promise<void>;
  onClickCancel: () => void;
  onClickPreview?: () => void;
  isReadyToOpenPortalOnnEventPreview: boolean;
  isDisableSaveButton: boolean;
  saveButtonText?: string;
};

export const ActionFooter: FC<Props> = ({
  onClickCancel,
  onClickSaveAndToNext,
  onClickPreview,
  isReadyToOpenPortalOnnEventPreview,
  isDisableSaveButton,
  saveButtonText = "保存して次へ",
}) => {
  return (
    <StyledActionFooter
      // NOTE: styledで上書きできなかったので、styleで定義
      style={{
        bottom: 0,
        top: "auto",
        backgroundColor: theme.palette.background.paper,
        height: "78px",
        padding: "0px 40px",
        display: "flex",
        flexDirection: "row",
      }}
    >
      <Box display="flex" alignItems="center" ml="auto" py="24px">
        {onClickPreview && (
          <>
            <Button
              variant="text"
              borderRadius="regular"
              onClick={onClickPreview}
              color="primary"
              disabled={!isReadyToOpenPortalOnnEventPreview || isDisableSaveButton}
              startIcon={
                <Icon icon="eye" color={isDisableSaveButton ? "lightGrey" : "primary"} size="md" />
              }
            >
              プレビュー
            </Button>
            <Divider margin={24} orientation="vertical" />
          </>
        )}
        <Box display="flex" justifyContent={"center"} alignItems="center">
          <Box>
            <Button variant="text" borderRadius="regular" onClick={onClickCancel} color="default">
              キャンセル
            </Button>
          </Box>
        </Box>
      </Box>
      <Box display="flex" alignItems="center" ml="24px" py="16px">
        <StyledSaveButton
          variant="contained"
          borderRadius="circle"
          onClick={onClickSaveAndToNext}
          color="primary"
          disabled={isDisableSaveButton}
        >
          {saveButtonText}
        </StyledSaveButton>
      </Box>
    </StyledActionFooter>
  );
};

const StyledActionFooter = styled(AppBar)``;

// TODO: MUIを剥がすときにボタンサイズをデザインに合わせる
const StyledSaveButton = styled(Button)``;
