import React, { useMemo } from "react";
import { GridCellRenderer, Index, MultiGridProps } from "react-virtualized";

import { BodyCell } from "./BodyCell";
import { HeaderCell } from "./HeaderCell";
import {
  BODY_CELL_HEIGHT_PX,
  BodyRowData,
  HEADER_CELL_HEIGHT_PX,
  HeaderRowData,
  MULTI_GRID_MAX_HEIGHT,
  PAPER_PADDING,
  columnTypes,
  widthPxMap,
} from "./const";

export const useMultiGridOnPaper = ({
  headerRowData,
  bodyRowDataArray,
}: {
  headerRowData: HeaderRowData;
  bodyRowDataArray: BodyRowData[];
}) => {
  const headerRowCount = 1;
  const rowCount = headerRowCount + bodyRowDataArray.length;
  const columnCount = columnTypes.length;

  // 各行の高さを格納した配列
  const rowHeightList = useMemo(
    () => [HEADER_CELL_HEIGHT_PX, ...bodyRowDataArray.map(() => BODY_CELL_HEIGHT_PX)],
    [bodyRowDataArray]
  );

  // MultiGridが各行の高さを算出するための関数
  const rowHeight = (params: Index) => {
    return rowHeightList[params.index] || BODY_CELL_HEIGHT_PX;
  };

  // MultiGridが各列の幅を算出するための関数
  const columnWidth = (params: Index) => {
    const columnType = columnTypes[params.index];
    if (columnType) return widthPxMap[columnType];

    return widthPxMap.slotDate;
  };

  // MultiGridの高さ
  const multiGridHight = useMemo(() => {
    const height = rowHeightList.reduce((acc, cur) => acc + cur, 0);
    return height > MULTI_GRID_MAX_HEIGHT ? MULTI_GRID_MAX_HEIGHT : height;
  }, [rowHeightList]);

  // ペーパーの高さ
  const paperHeight = useMemo(() => {
    const paddingTop = PAPER_PADDING;
    const paddingBottom = PAPER_PADDING;

    const result = paddingTop + paddingBottom + multiGridHight;
    return result;
  }, [multiGridHight]);

  const cellRenderer: GridCellRenderer = ({ style, rowIndex, columnIndex }) => {
    const columnType = columnTypes[columnIndex];
    if (!columnType) return;

    const isLastColumn = columnIndex === columnTypes.length - 1;
    const isShowRightBorder = !isLastColumn && columnIndex !== 0 && columnIndex !== 1;

    const key = String(rowIndex) + String(columnIndex);
    if (rowIndex === 0) {
      return (
        <HeaderCell
          key={key}
          style={style}
          columnType={columnType}
          isShowRightBorder={isShowRightBorder}
          headerRowData={headerRowData}
        />
      );
    }
    const bodyRowData = bodyRowDataArray[rowIndex - 1];
    if (!bodyRowData) return;

    return (
      <BodyCell
        key={key}
        bodyRowData={bodyRowData}
        columnType={columnType}
        style={style}
        isShowRightBorder={isShowRightBorder}
      />
    );
  };

  const multiGridProps: MultiGridProps = {
    rowCount,
    columnCount,
    rowHeight,
    columnWidth,
    height: multiGridHight,
    cellRenderer,
    enableFixedColumnScroll: true,
    fixedRowCount: 1,
    width: 0, // NOTE: widthはAutoSizerで取得するためこの値はdummy
  };

  return { multiGridProps, paperHeight };
};
