import { Box } from "@material-ui/core";
import { NewGraduateToDisplay } from "@onn/common";
import React, { FC } from "react";
import { AutoSizer, MultiGrid } from "react-virtualized";
import styled from "styled-components";

import { EMPTY_TEXT_AREA_HEIGHT } from "./parts/const";
import { createBodyRowData } from "./parts/createBodyRowData";

import { useTableView } from "./parts/useTableView";

import { Loading, Paper, Typography } from "~/components/uiParts";
import { useRecruitmentProcessRecordsByEmployee } from "~/hooks/recruitmentProcess";

type Props = {
  employee: NewGraduateToDisplay;
};

export const RecruitmentProcessTab: FC<Props> = ({ employee }) => {
  const { data: recruitmentProcessRecords = [], isLoading } =
    useRecruitmentProcessRecordsByEmployee({
      employeeId: employee.id,
    });
  const tableViewInfo = useTableView({
    bodyRowDataArray: recruitmentProcessRecords.map((record) =>
      createBodyRowData(record, employee)
    ),
  });

  const isEmpty = !isLoading && !tableViewInfo.isNotEmpty;

  return (
    <StyledPaper height={tableViewInfo.paperHeight}>
      {!isEmpty && (
        <AutoSizer disableHeight>
          {({ width }) => {
            return (
              <StyledBox width={width}>
                <MultiGrid
                  {...tableViewInfo}
                  height={tableViewInfo.multiGridHight}
                  width={width}
                  fixedColumnCount={1}
                  fixedRowCount={1}
                  enableFixedColumnScroll={true}
                  ref={tableViewInfo.multiGridRef}
                />
              </StyledBox>
            );
          }}
        </AutoSizer>
      )}
      {isLoading && (
        <StyledEmptyBox>
          <Loading size="large" />
        </StyledEmptyBox>
      )}

      {isEmpty && (
        <StyledEmptyBox>
          <Typography variant="body2" align="center" color="textSecondary">
            選考履歴がありません。
          </Typography>
        </StyledEmptyBox>
      )}
    </StyledPaper>
  );
};

const StyledPaper = styled(Paper)<{ height: number }>`
  margin: 40px 0;
  padding: 40px;
  height: ${(props) => props.height}px;

  &.MuiPaper-rounded {
    border-radius: 10px;
  }
`;

const StyledBox = styled(Box)`
  .ReactVirtualized__Grid:first-child {
    -ms-overflow-style: none;
    ::-webkit-scrollbar {
      display: none;
    }
  }
`;

const StyledEmptyBox = styled(Box)`
  height: ${EMPTY_TEXT_AREA_HEIGHT}px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
