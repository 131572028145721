import { Reminder } from "@onn/common";
import { useCallback } from "react";
import { useSWRConfig } from "swr";

import { useContactContext } from "../contactMessage/useContactContext";

import { Key } from "./useInCompletedReminders";

import { useSnackbar } from "~/hooks/shared";
import { apiClient } from "~/libs";
import { captureException } from "~/util";

/**
 * リマインダーを作成するAPIを呼び出す関数を返す
 * @param mutateKeys
 */
export const useCreateReminder = ({
  contactRoomId,
  reminders,
  refetchKey,
}: {
  contactRoomId: string;
  reminders: Reminder[];
  refetchKey: Key;
}) => {
  const { mutate } = useSWRConfig();
  const { enqueueSnackbar } = useSnackbar();
  const { expiredRemindersMutate } = useContactContext();

  /**
   * リマインダーを作成する
   */
  const createReminder = useCallback(
    async (args: { title: string; remindedAt: Date }) => {
      try {
        const optimisticData = [
          ...reminders,
          Reminder.createNew({
            tenantId: "dummy",
            contactRoomId,
            ...args,
          }),
        ].sort((a, b) => (a.remindedAt > b.remindedAt ? 1 : -1));

        await mutate(
          refetchKey,
          async () => {
            await apiClient.post(`/reminder_api/reminders`, {
              ["title"]: args.title,
              ["contact-room-id"]: contactRoomId,
              ["reminded-at"]: args.remindedAt,
            });
            return mutate(refetchKey);
          },
          {
            optimisticData,
          }
        );
        expiredRemindersMutate();
        enqueueSnackbar("リマインダーを追加しました", { variant: "success" });
      } catch (e) {
        enqueueSnackbar("リマインダーの作成に失敗しました", { variant: "error" });
        captureException({
          error: e as Error,
          tags: {
            type: "useCreateReminder:createReminder",
          },
        });
      }
    },
    [contactRoomId, enqueueSnackbar, expiredRemindersMutate, mutate, refetchKey, reminders]
  );

  return { createReminder };
};
