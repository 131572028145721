import { Box } from "@material-ui/core";
import { Employee } from "@onn/common";
import React from "react";

import { Checkbox } from "~/components/uiParts";

type Props = {
  displayNewHire: Employee;
  selectedEmployees: Employee[];
  onSelectEmployee(employee: Employee): void;
};
export const NewGraduateCheckbox = ({
  displayNewHire,
  selectedEmployees,
  onSelectEmployee,
}: Props) => {
  return (
    <Box padding={"10px"}>
      <Checkbox
        checked={selectedEmployees.some((e) => e.id === displayNewHire.id)}
        onChange={() => onSelectEmployee(displayNewHire)}
      />
    </Box>
  );
};
