import { Box } from "@material-ui/core";
import { format } from "date-fns";
import { ja } from "date-fns/locale";
import React, { FC, useEffect } from "react";
import { Navigate, useParams } from "react-router-dom";
import styled from "styled-components";

import { Button, Typography, Loading, Paper } from "~/components/uiParts";

import { useCurrentUser } from "~/hooks/employee";
import { useCreateEmployeeActiveLog } from "~/hooks/employeeActiveLog";
import { useOnnEventForPortal } from "~/hooks/onnEvent";
import { OnnEventDataForPortal } from "~/hooks/onnEvent/useOnnEventForPortal";
import { useQuery } from "~/hooks/shared";
import { useNavigateWithQuery } from "~/hooks/shared/useNavigateWithQuery";
import { NotFound } from "~/pages/NotFound";
import { mixin } from "~/util";

export const OnnEventThanks: FC = () => {
  const { currentUser } = useCurrentUser();
  const { id: onnEventId } = useParams<"id">();
  const { query } = useQuery();

  // TODO: previewが動作するようにする https://app.clickup.com/t/86entat6z
  const isPreview = query.get("preview") === "true";
  const navigate = useNavigateWithQuery();

  const { data: eventData, isLoading } = useOnnEventForPortal({
    employeeId: currentUser.id,
    id: typeof onnEventId === "string" ? onnEventId : undefined,
    isPreview,
  });

  useEffect(() => {
    if (!isPreview && eventData && !eventData.onnEventAnswer.isAnswered()) {
      // 未回答の場合
      navigate(`/portal/events/${eventData.onnEvent.id}`);
    }
  }, [eventData, isPreview, navigate]);

  if (isLoading) {
    return <Loading size="large" fullHeight />;
  }

  if (!eventData) {
    return <NotFound />;
  }

  if (!isPreview && !eventData.onnEventAnswer.isAnswered()) {
    return <Loading size="large" fullHeight />;
  }

  /* NOTE: 初期版では面談イベントは入社者が回答を編集できないようにするため */
  if (eventData.onnEvent.type === "interview") {
    return <Navigate to="/portal/events" />;
  }

  return <OnnEventThanksPage eventData={eventData} />;
};

type OnnEventThanksPage = {
  eventData: OnnEventDataForPortal;
};

export const OnnEventThanksPage: FC<OnnEventThanksPage> = ({ eventData }) => {
  const navigate = useNavigateWithQuery();
  const { currentUser } = useCurrentUser();
  const answeredCandidateDateId = eventData.onnEventAnswer.getPossibleCandidateDateId();
  const answeredDate = eventData.onnEvent.candidateDates.find(
    (date) => date.id === answeredCandidateDateId
  );

  const onClickToEdit = () => {
    navigate(`/portal/events/${eventData.onnEvent.id}/new`);
  };

  const { createEmployeeLog } = useCreateEmployeeActiveLog();

  useEffect(() => {
    createEmployeeLog("VISITED_ONN_EVENT_THANKS_PAGE", eventData.onnEvent.id, currentUser.tenantId);
    // NOTE: アクセスしたタイミングのみ記録するため、eslint-disable-next-lineを記述
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <StyledBox>
      <Typography variant="h2" color="textPrimary" style={{ fontWeight: 400 }}>
        {eventData.onnEvent.title}
      </Typography>
      <StyledPaper>
        <Typography variant="h2" bold color="primary" align="center">
          THANK YOU!
        </Typography>
        <Box height={32} />
        {eventData.onnEventAnswer.isUnavailableCandidates() || !answeredDate ? (
          <Typography variant="body1" align="center" color="textSecondary" bold>
            「参加できる日程がない」 が送信されました。
          </Typography>
        ) : (
          <>
            <Typography variant="body1" align="center" color="textSecondary" bold>
              「{format(answeredDate.from, "MM/dd(E) HH:mm", { locale: ja })}~
              {format(answeredDate.until, "HH:mm")}」
            </Typography>
            <Typography variant="body1" align="center" color="textSecondary">
              希望された上記の日程で参加日が確定しました！
            </Typography>
          </>
        )}
        <Box height={32} />
        <Button
          fullWidth
          variant="outlined"
          color="default"
          borderRadius="circle"
          onClick={onClickToEdit}
        >
          回答を編集
        </Button>
      </StyledPaper>
      <Box mt={4} display="flex" justifyContent="center">
        <Typography variant="caption" color="textSecondary">
          ブラウザを閉じて終了してください。
        </Typography>
      </Box>
    </StyledBox>
  );
};

const StyledBox = styled(Box)`
  background-color: ${(props) => props.theme.palette.grey[50]};
  padding: 80px 24px;
  width: 100%;

  ${mixin.portalSp`
    padding: 40px 24px;
    width: 100%;
  `}
`;

const StyledPaper = styled(Paper)`
  margin-top: 28px;
  padding: 80px;
  ${mixin.portalSp`
    margin-top: 24px;
    padding: 80px 24px;
  `}
`;
