import { OnnTaskQuestion } from "@onn/common";
import {
  RadioAnswer,
  TextAnswer,
  CheckBoxAnswer,
  FileAnswer,
  Answer,
} from "@onn/common/domain/OnnTask/Answer/Answer";
import { v4 } from "uuid";

export type FIlE_ANSWER = FileAnswer & {
  file?: File;
};

export type AnswerForState = TextAnswer | RadioAnswer | CheckBoxAnswer | FIlE_ANSWER;

export type State = {
  answers: AnswerForState[];
};

export type Action =
  | {
      type: "CHANGE_TEXT_ANSWER";
      payload: {
        questionId: string;
        text: string;
      };
    }
  | {
      type: "CHANGE_RADIO_ANSWER";
      payload: {
        questionId: string;
        selectedOptionId: string;
      };
    }
  | {
      type: "CHANGE_CHECKBOX_ANSWER";
      payload: {
        questionId: string;
        addedSelectedOptionId?: string;
        removedSelectedOptionId?: string;
      };
    }
  | {
      type: "CHANGE_CHECKBOX_TEXT_ANSWER";
      payload: {
        questionId: string;
        text: string;
      };
    }
  | {
      type: "CHANGE_FILE_ANSWER";
      payload: {
        questionId: string;
        filePath: string;
        file: File;
      };
    };

export const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case "CHANGE_TEXT_ANSWER":
      return {
        ...state,
        answers: state.answers.map((answer) => {
          if (answer.questionId === action.payload.questionId && answer.type === "TEXT") {
            return { ...answer, value: action.payload.text };
          }
          return answer;
        }),
      };
    case "CHANGE_RADIO_ANSWER":
      return {
        ...state,
        answers: state.answers.map((answer) => {
          if (answer.questionId === action.payload.questionId && answer.type === "RADIO") {
            return { ...answer, selectedOptionId: action.payload.selectedOptionId };
          }
          return answer;
        }),
      };

    case "CHANGE_CHECKBOX_ANSWER":
      return {
        ...state,
        answers: state.answers.map((answer) => {
          if (answer.questionId === action.payload.questionId && answer.type === "CHECK_BOX") {
            if (action.payload.removedSelectedOptionId) {
              return {
                ...answer,
                selectedOptionIds: answer.selectedOptionIds.filter(
                  (id) => id !== action.payload.removedSelectedOptionId
                ),
              };
            }
            if (action.payload.addedSelectedOptionId) {
              return {
                ...answer,
                selectedOptionIds: [
                  ...answer.selectedOptionIds,
                  action.payload.addedSelectedOptionId,
                ],
              };
            }
          }
          return answer;
        }),
      };

    case "CHANGE_CHECKBOX_TEXT_ANSWER":
      return {
        ...state,
        answers: state.answers.map((answer) => {
          if (answer.questionId === action.payload.questionId && answer.type === "CHECK_BOX") {
            return { ...answer, value: action.payload.text };
          }
          return answer;
        }),
      };

    case "CHANGE_FILE_ANSWER":
      return {
        ...state,
        answers: state.answers.map((answer) => {
          if (answer.questionId === action.payload.questionId && answer.type === "FILE") {
            return {
              ...answer,
              filePath: action.payload.filePath,
              file: action.payload.file,
            } as FIlE_ANSWER;
          }
          return answer;
        }),
      };
  }
};

export const createInitialState = (question: OnnTaskQuestion[], answers: Answer[]): State => {
  return {
    answers: question.map((q) => {
      const existAnswer = answers.find((a) => a.questionId === q.id);

      switch (q.type) {
        case "TEXT": {
          const _existAnswer = existAnswer as TextAnswer;
          const answer: TextAnswer = {
            id: v4(),
            questionId: q.id,
            type: "TEXT",
            value: _existAnswer ? _existAnswer.value : "",
          };
          return answer;
        }
        case "RADIO": {
          const _existAnswer = existAnswer as RadioAnswer;
          // すでに回答している選択肢が、設問の編集等によって削除されている場合は、空文字を選択肢IDとして設定する
          const selectedOptionId =
            q.options.find((o) => o.id === _existAnswer?.selectedOptionId) === undefined
              ? ""
              : _existAnswer?.selectedOptionId;
          const answer: RadioAnswer = {
            id: v4(),
            questionId: q.id,
            type: "RADIO",
            selectedOptionId,
          };
          return answer;
        }
        case "CHECK_BOX": {
          const _existAnswer = existAnswer as CheckBoxAnswer;
          // すでに回答している選択肢が、 設問の編集等によって削除されている場合は、選択済みから除外するための処理。
          // そうしないと、すでに削除済みの選択肢が選択肢として回答されてしまう。
          const selectedOptionIds = q.options
            .filter((o) => _existAnswer?.selectedOptionIds.includes(o.id))
            .map((o) => o.id);
          const answer: CheckBoxAnswer = {
            id: v4(),
            questionId: q.id,
            type: "CHECK_BOX",
            selectedOptionIds,
          };
          return answer;
        }
        case "FILE": {
          const _existAnswer = existAnswer as FIlE_ANSWER;
          const answer: FIlE_ANSWER = {
            id: v4(),
            questionId: q.id,
            type: "FILE",
            filePath: _existAnswer?.filePath || "",
            file: undefined,
          };
          return answer;
        }
      }
    }),
  };
};
