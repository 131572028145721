import { Box } from "@material-ui/core";
import React, { FC } from "react";
import { Controller, UseFormReturn } from "react-hook-form";

import styled from "styled-components";

import { InputState } from "../formUtils/zodFormSchema";

import { Icon, TextField, Tooltip, Typography } from "~/components/uiParts";

export const PlaceNameInputPart: FC<{ form: UseFormReturn<InputState> }> = ({ form }) => {
  return (
    <Box>
      <Box height="24px" mb="16px" display="flex" alignItems="center">
        <Box display="flex" alignItems="center" mr="8px">
          <Typography variant="body2" bold>
            会場名
          </Typography>
        </Box>
        <Tooltip
          title="会場名はオフライン・現地開催のイベントで候補者の選択肢となります。どこの会場かわかる簡潔な名称を設定してください。"
          placement="top-start"
        >
          <Icon icon="help" size="ssm" color="grey" />
        </Tooltip>
      </Box>
      <Box height={"40px"}>
        <Controller
          name="placeName"
          control={form.control}
          render={({ field, fieldState }) => {
            const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
              field.onChange(e.target.value);
              form.trigger("placeName");
            };
            return (
              <StyledTextField
                {...field}
                fullWidth
                variant="outlined"
                placeholder="会場名を入力してください。"
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
                onChange={onChange}
              />
            );
          }}
        />
      </Box>
    </Box>
  );
};

const StyledTextField = styled(TextField)`
  height: 100%;
  .MuiInputBase-root {
    height: 100%;
  }
`;
