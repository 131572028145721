import { useMemo } from "react";

export const useDisplayDeliveryTimeItems = () => {
  const selectableTimeRange = useMemo(
    () => [
      0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24,
    ],
    []
  );

  const displayDeliveryTimeItems = useMemo(
    () =>
      selectableTimeRange.flatMap((hour, i) => {
        return [0, 15, 30, 45].flatMap((min, ii) => {
          if (i === selectableTimeRange.length - 1) {
            if (ii !== 0) return [];
          }
          return {
            value: hour + min / 60,
            name: `${hour}:${("00" + min).slice(-2)}`, //19時ぴったりの時などに、19:0ではなく19:00と表示するため
          };
        });
      }),
    [selectableTimeRange]
  );

  // 開始時間セレクターの選択肢
  const displayDeliveryFromTimeItems = useMemo(
    () => displayDeliveryTimeItems.filter((_, i) => i < displayDeliveryTimeItems.length - 1),
    [displayDeliveryTimeItems]
  );
  // 終了時間セレクターの選択肢
  const displayDeliveryUntilTimeItems = useMemo(
    () => displayDeliveryTimeItems.filter((_, i) => i !== 0),
    [displayDeliveryTimeItems]
  );

  return {
    displayDeliveryFromTimeItems,
    displayDeliveryUntilTimeItems,
  };
};
