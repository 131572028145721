import { Box } from "@material-ui/core";
import { getHowLongAgo, NewGraduateToDisplay } from "@onn/common";
import React, { FC, memo, useMemo } from "react";
import styled from "styled-components";

import { NewGraduateStatusChip } from "../NewGraduateStatusChip";

import { Typography } from "~/components/uiParts/Typography";
import { UserIcon } from "~/components/uiParts/UserIcon";

import { useUpdateNewGraduateRecruitmentStatus } from "~/hooks/employee/useUpdateNewGraduateRecruitmentStatus";

type Props = {
  newGraduate: NewGraduateToDisplay;
  onUpdateRecruitmentStatus?: (recruitmentStatusId: string) => Promise<void>;
};

export const NewGraduateUseIcon: FC<Props> = memo(({ newGraduate, onUpdateRecruitmentStatus }) => {
  const { updateNewGraduateRecruitmentStatus } = useUpdateNewGraduateRecruitmentStatus();

  const employeeStatusText = useMemo(() => {
    return newGraduate.lastActiveTime ? getHowLongAgo(newGraduate.lastActiveTime) : "なし";
  }, [newGraduate.lastActiveTime]);

  const iconBadgeType = useMemo(() => {
    // NOTE: undefinedは "登録前" かつLineUser未連携を表す
    if (!newGraduate.uid && !newGraduate.lineUserId) return undefined;

    if (newGraduate.isFollowedLineOfficialAccount) return "lineChat";
    return "email";
  }, [newGraduate]);

  return (
    <>
      <Box display="flex" alignItems="center" gridGap="16px">
        <UserIcon
          username={newGraduate.getName()}
          profileIconImageUrl={newGraduate.profileIconImageUrl || ""}
          size="small"
          backgroundColor={newGraduate.uid ? "primary" : "grey"}
          badgeType={iconBadgeType}
        />
        <Box display="grid" gridRowGap="4px">
          <StyledTypography variant="body1" color="textPrimary" bold noWrap>
            {newGraduate.getName()}
          </StyledTypography>
          <Box display="flex" alignItems="center" gridGap="8px" flexWrap="wrap">
            <StyledTypography variant="caption" color="textSecondary" noWrap disablePreWrap>
              最終アクセス：{employeeStatusText}
            </StyledTypography>
            <NewGraduateStatusChip
              newGraduate={newGraduate}
              updateRecruitmentStatus={async (recruitmentStatusId) => {
                await updateNewGraduateRecruitmentStatus(newGraduate, recruitmentStatusId);
                onUpdateRecruitmentStatus?.(recruitmentStatusId);
              }}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
});

const StyledTypography = styled(Typography)`
  &.MuiTypography-root {
    line-height: 1.1;
  }
`;
