import { Box, Link } from "@material-ui/core";
import { isValidPassword, Employee } from "@onn/common";
import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import styled, { CSSObject } from "styled-components";

import { NotFound } from "../../../pages/NotFound";

import { PasswordInput, useFirstAndLastNameInput } from "~/components/domains/account/_shared";

import { FirstAndLastNameInput } from "~/components/domains/account/_shared/FirstAndLastNameInput";
import TermsOfUse from "~/components/domains/account/_shared/TermsOfUse";

import { Paper, Button, Divider, Icon, Typography } from "~/components/uiParts";
import { useSignUp } from "~/hooks/employee";
import { RegistrationInfo } from "~/hooks/employee/useRegistrationInfoByInvitationToken";
import { mixin } from "~/util";

export const RegisterIndividualByEmail = ({
  registrationInfo,
}: {
  registrationInfo: RegistrationInfo | undefined;
}) => {
  const { t } = useTranslation(["account"]);

  const employee = registrationInfo?.employee;
  const tenantName = registrationInfo?.tenantName;

  const {
    firstName,
    firstNameError,
    lastName,
    lastNameError,
    onChangeFirstName,
    onChangeLastName,
  } = useFirstAndLastNameInput(employee);

  const [password, setPassword] = useState<string>("");
  const [passwordError, setPasswordError] = useState<boolean>(false);
  const [isAgreed, setIsAgreed] = useState(false);

  const { loading: isSignUpLoading, signUp } = useSignUp();

  const isDisableSignUpButton = useMemo(() => {
    return (
      firstName.length === 0 ||
      lastName.length === 0 ||
      password.length === 0 ||
      passwordError ||
      !isAgreed
    );
  }, [firstName.length, isAgreed, lastName.length, password.length, passwordError]);

  useEffect(() => {
    if (password.length === 0) {
      return setPasswordError(false);
    }
    setPasswordError(!isValidPassword(password));
  }, [password]);

  const handleSubmit = useCallback(
    async (userAccount: Employee) => {
      await signUp({
        firstName,
        lastName,
        password,
        invitationToken: userAccount.invitationToken,
      });
    },
    [firstName, lastName, password, signUp]
  );

  if (!employee || !tenantName) {
    return <NotFound />;
  }

  return (
    <StyledContainer maxWidth="500px">
      <StyledPaper>
        <Box display="inline" fontWeight={400} lineHeight="24px">
          <Typography variant="body2" color="textSecondary">
            {t("guideText", {
              tenantName,
              role: Employee.displayRoleMap[employee.role],
              context: employee.isNewGraduate ? "newGraduate" : "midCareer",
            })}
          </Typography>
        </Box>

        <Box>
          <StyledForm
            noValidate
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit(employee);
            }}
          >
            <Box mb={5}>
              <Typography variant="body2" bold gutterBottom>
                メールアドレス
              </Typography>
              <Box display="flex">
                <Box width="95%">
                  {/* TODO: FixedEmailInput に置き換える */}
                  <StyledFixedEmailInput
                    value={employee.email}
                    name="email"
                    type="email"
                    readOnly
                  />
                </Box>
                <Icon icon="check" color="primary" size="sm" />
              </Box>
              <StyledDivider />
            </Box>
            <Box>
              <Typography variant="body2" bold>
                アカウント登録情報
              </Typography>
              <FirstAndLastNameInput
                firstNameError={firstNameError}
                lastNameError={lastNameError}
                onChangeFirstName={onChangeFirstName}
                onChangeLastName={onChangeLastName}
                firstName={firstName}
                lastName={lastName}
              />
            </Box>
            <Box mt={3}>
              <PasswordInput
                password={password}
                passwordError={passwordError}
                onChange={(inputValue: string): void => setPassword(inputValue)}
              />
            </Box>
            <TermsOfUse
              isAgreed={isAgreed}
              isNewGrad={true}
              onChange={() => setIsAgreed((prv) => !prv)}
            />
            <Box
              textAlign="center"
              color="textSecondary"
              lineHeight="24px"
              mt={5}
              mb={4}
              pl={3}
              pr={3}
            >
              <Typography variant="caption">アカウントを作成することにより、Onnの</Typography>
              <Link
                href="https://onn-hr.com/privacy_policy"
                underline="always"
                target="_blank"
                color="textSecondary"
              >
                <Typography variant="caption">プライバシーポリシー</Typography>
              </Link>
              <Typography variant="caption">に同意するものとします。</Typography>
            </Box>
            <Box textAlign="center" mt={2} mb={2}>
              <Button
                fullWidth
                type="submit"
                variant="contained"
                borderRadius="circle"
                color="primary"
                isLoading={isSignUpLoading}
                disabled={isDisableSignUpButton || isSignUpLoading}
              >
                {isSignUpLoading ? "送信中" : "アカウント作成する"}
              </Button>
            </Box>
          </StyledForm>
        </Box>
      </StyledPaper>
    </StyledContainer>
  );
};

const StyledFixedEmailInput = styled.input`
  width: 100%;
  user-select: none;
  color: ${(props) => props.theme.palette.text.secondary};
  ${(props) => props.theme.typography.subtitle2 as CSSObject};
  font-weight: bold;

  &:focus {
    outline: none;
  }
`;

const StyledContainer = styled(Box)`
  padding: 64px 24px;
  width: 100%;

  ${mixin.breakDown.sm`
    padding: 40px 0px;
    padding-top: 0px;
  `}
`;

const StyledPaper = styled(Paper)`
  &.MuiPaper-root {
    ${mixin.breakDown.sm`
      box-shadow: none;
      `}
  }
`;

const StyledForm = styled.form`
  width: 100%;
  font-size: 12px;
  color: ${(props) => props.theme.palette.text.secondary};
`;

const StyledDivider = styled(Divider)`
  height: 2px;
`;
