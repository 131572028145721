import {
  OnnFormTask,
  CandidateDate,
  Employee,
  OnnEvent,
  OnnEventAnswer,
  OnnEventDeterminedDate,
  OnnEventEvaluation,
  RecruitmentProcessRecord,
  OnnFormTaskAnswer,
  OnnEventSlotDate,
} from "@onn/common";
import useSWR, { SWRResponse, mutate } from "swr";

import { apiClient } from "~/libs";

type Key = ["/get_employees_recruitment_processes", Employee["id"]];
export const generateUseRecruitmentProcessRecordsByEmployeeKey = (employeeId: string): Key => {
  return ["/get_employees_recruitment_processes", employeeId];
};

export const useRecruitmentProcessRecordsByEmployee = ({
  employeeId,
}: {
  employeeId: string;
}): SWRResponse<RecruitmentProcessRecordByEmployee[], Error> => {
  const key = generateUseRecruitmentProcessRecordsByEmployeeKey(employeeId);

  return useSWR(key, async ([endpoint, employeeId]: Key) => {
    const response = await apiClient.get(endpoint, {
      "employee-id": employeeId,
    });
    return response.map(({ progressStatusLabel, recruitmentStatusLabel, ...result }) => ({
      processRecord: new RecruitmentProcessRecord(result),
      progressStatusLabel,
      recruitmentStatusLabel,
      relation: {
        onnEvent: result.relation.onnEventWithRelation?.onnEvent
          ? new OnnEvent(result.relation.onnEventWithRelation.onnEvent)
          : undefined,
        statusLabel: result.relation.onnEventWithRelation?.statusLabel || undefined,
        lastReadAt: result.relation.onnEventWithRelation?.lastReadAt || undefined,
        candidateDate: result.relation.onnEventWithRelation?.candidateDate
          ? new CandidateDate(result.relation.onnEventWithRelation.candidateDate)
          : undefined,
        onnEventAnswer: result.relation.onnEventWithRelation?.answer
          ? new OnnEventAnswer(result.relation.onnEventWithRelation.answer)
          : undefined,
        onnEventDeterminedDate: result.relation.onnEventWithRelation?.determinedDate
          ? new OnnEventDeterminedDate(result.relation.onnEventWithRelation.determinedDate)
          : undefined,
        slotDate: result.relation.onnEventWithRelation?.slotDate
          ? new OnnEventSlotDate(result.relation.onnEventWithRelation.slotDate)
          : undefined,
        onnEventEvaluation: result.relation.onnEventWithRelation?.evaluation
          ? new OnnEventEvaluation(result.relation.onnEventWithRelation.evaluation)
          : undefined,
        assignee: result.relation.onnEventWithRelation?.assignee
          ? new Employee(result.relation.onnEventWithRelation.assignee)
          : undefined,
        onnTasksWithRelation: result.relation.onnTasksWithRelation?.map(
          ({ onnTask, answer, statusLabel, lastReadAt }) => ({
            onnTask: new OnnFormTask(onnTask),
            answer: answer ? new OnnFormTaskAnswer(answer) : undefined,
            statusLabel,
            lastReadAt,
          })
        ),
      },
    }));
  });
};

export const mutateRecruitmentProcessRecordsByEmployee = (employeeId: string) => {
  mutate(generateUseRecruitmentProcessRecordsByEmployeeKey(employeeId));
};

export type RecruitmentProcessRecordByEmployee = {
  // 型合わせの都合で、 undefined のユニオン型にしている
  processRecord: RecruitmentProcessRecord;
  recruitmentStatusLabel: string;
  progressStatusLabel: string | undefined;
  relation: {
    onnEvent: OnnEvent | undefined;
    statusLabel: string | undefined;
    lastReadAt: Date | undefined;
    candidateDate: CandidateDate | undefined;
    onnEventAnswer: OnnEventAnswer | undefined;
    onnEventDeterminedDate: OnnEventDeterminedDate | undefined;
    onnEventEvaluation: OnnEventEvaluation | undefined;
    assignee: Employee | undefined;
    onnTasksWithRelation:
      | Array<{
          onnTask: OnnFormTask;
          statusLabel: string | undefined;
          lastReadAt: Date | undefined;
          answer: OnnFormTaskAnswer | undefined;
        }>
      | undefined;
    slotDate: OnnEventSlotDate | undefined;
  };
};
