import { APISchema } from "@onn/common";
import { useCallback } from "react";

import { apiClient } from "~/libs";

export const useAnswerEventOnBehalfOfNewGraduatesByCsv = () => {
  const execAnswerEventOnBehalfOfNewGraduatesByCsv = useCallback(
    async ({
      onnEventId,
      base64EncodedCsvFile,
      isDryRun,
    }: {
      onnEventId: string;
      base64EncodedCsvFile: string;
      isDryRun: boolean;
    }) => {
      const requestBody: APISchema["/answer_event_on_behalf_of_new_graduates_by_csv"]["POST"]["body"] =
        {
          onnEventId,
          base64EncodedCsvFile,
          isDryRun,
        };
      const response = await apiClient.post(
        "/answer_event_on_behalf_of_new_graduates_by_csv",
        requestBody
      );

      return response;
    },
    []
  );

  return { execAnswerEventOnBehalfOfNewGraduatesByCsv };
};
