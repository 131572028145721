import { v4 } from "uuid";
import { z } from "zod";

export const TITLE_MAX_LENGTH = 20;

export const contactMessageTemplateSchema = z.object({
  id: z.string().trim().default(v4),
  tenantId: z.string(),
  spaceId: z.string(),
  title: z
    .string()
    .trim()
    .min(1, { message: "タイトルが短すぎます" })
    .max(TITLE_MAX_LENGTH, { message: "タイトルが長すぎます" }),
  text: z.string().trim().min(1, { message: "テキストが短すぎます" }),
  createdAt: z.date().default(() => new Date()),
  updatedAt: z.date().default(() => new Date()),
});

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IContactMessageTemplateSchema
  extends z.infer<typeof contactMessageTemplateSchema> {}
