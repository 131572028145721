import { Employee, OnnEvent } from "@onn/common";
import { useCallback, useState } from "react";

import { useNavigate } from "react-router-dom";

import { useEnqueueSuccessSnackbar } from "./useEnqueueSuccessSnackbar";

import { mutateOnnEventAnswers } from "~/hooks/onnEvent/answerResult/useOnnEventAnswers";
import { useCreateNewInterviewAnswerBox } from "~/hooks/onnEvent/useCreateNewInterviewAnswerBox";
import { useSnackbar } from "~/hooks/shared";

export const useSubmitButtonHandler = ({
  onnEvent,
  employeeToDeliverIds,
  setSelectedNewGraduates,
}: {
  onnEvent: OnnEvent;
  employeeToDeliverIds: string[];
  setSelectedNewGraduates: React.Dispatch<React.SetStateAction<Employee[]>>;
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const { enqueueSuccessSnackbar } = useEnqueueSuccessSnackbar();

  const navigate = useNavigate();

  const { createNewInterviewAnswerBox } = useCreateNewInterviewAnswerBox();

  const onClickConfirmSave = useCallback(async () => {
    try {
      setIsLoading(true);
      const _employeeToDeliverIds = [...employeeToDeliverIds];
      setSelectedNewGraduates([]);
      await createNewInterviewAnswerBox({
        onnEventId: onnEvent.id,
        employeeIds: _employeeToDeliverIds,
      });
      enqueueSuccessSnackbar(_employeeToDeliverIds.length);
      mutateOnnEventAnswers(onnEvent.id);
      navigate(`/events/${onnEvent.id}`);
    } catch (error) {
      enqueueSnackbar("配信対象の追加に失敗しました。管理者より連絡がくるまで、お待ちください", {
        variant: "error",
      });
      navigate(`/events/${onnEvent.id}`);
    } finally {
      setIsLoading(false);
    }
  }, [
    createNewInterviewAnswerBox,
    employeeToDeliverIds,
    enqueueSnackbar,
    enqueueSuccessSnackbar,
    navigate,
    onnEvent.id,
    setSelectedNewGraduates,
  ]);

  return { onClickConfirmSave, isLoading };
};
