import { Box, Tab } from "@material-ui/core";

import { TabContext, TabList, TabPanel } from "@material-ui/lab";

import { OnnEvent, Employee, OnnEventAnswer } from "@onn/common";
import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import styled from "styled-components";

import { useEventDetail } from "../hooks/useHooks";

import { TabPage as AnswerResultTab } from "./AnswerResultTab/TabPage";
import { TabPage as AnswerResultTabForInterviewEvent } from "./AnswerResultTabForInterviewEvent/TabPage";
import { TabPage as AnswerResultTabForNewInterviewEvent } from "./AnswerResultTabForNewInterviewEvent/TabPage";
import { EventDetailSummary } from "./EventDetailSummary/EventDetailSummary";
import { EventDetailSummaryPaperForNewInterview } from "./EventDetailSummary/EventDetailSummaryPaperForNewInterview";
import { TabPage as OnnEventContentsTab } from "./OnnEventContentsTab/TabPage";
import { TabPage as OnnEventSlotsTab } from "./OnnEventSlotsTab/TabPage";

import { Typography, Loading } from "~/components/uiParts";

import { useCurrentUser, useEmployees } from "~/hooks/employee";
import { useOnnEvent } from "~/hooks/onnEvent";
import { useOnnEventAnswers } from "~/hooks/onnEvent/answerResult/useOnnEventAnswers";
import { useCurrentSpace } from "~/hooks/space/useCurrentSpace";
import { useTenant } from "~/hooks/tenant/useTenant";
import { NotFound } from "~/pages/NotFound";

export const Page = () => {
  const { tenant } = useTenant();
  const { switchSpaceTemporary } = useCurrentSpace();
  const { currentUser } = useCurrentUser();
  const params = useParams();
  const onnEventId = params.id;

  const { data: onnEvent, isLoading: isLoadingOnnEvent } = useOnnEvent({
    tenantId: tenant.tenantId,
    onnEventId,
  });
  const { data: assigneeEmployees, isLoading: isLoadingEmployee } = useEmployees(
    onnEvent?.assigneeIds
  );
  const { data: onnEventAnswers, isLoading: isLoadingOnnEventAnswers } = useOnnEventAnswers({
    onnEventId,
  });

  useEffect(() => {
    if (!onnEvent) return;
    switchSpaceTemporary(onnEvent.spaceId);
  }, [switchSpaceTemporary, onnEvent]);

  const isLoading = isLoadingOnnEvent || isLoadingEmployee || isLoadingOnnEventAnswers;
  if (isLoading) return <Loading size="large" fullHeight />;
  if (!onnEventId || !onnEvent) {
    return <NotFound />;
  }

  return (
    <EventDetail
      currentUser={currentUser}
      onnEvent={onnEvent}
      assigneeEmployees={assigneeEmployees || []}
      onnEventAnswers={onnEventAnswers || []}
    />
  );
};

const EventDetail = ({
  currentUser,
  onnEvent,
  assigneeEmployees,
  onnEventAnswers,
}: {
  currentUser: Employee;
  onnEvent: OnnEvent;
  assigneeEmployees: Employee[];
  onnEventAnswers: OnnEventAnswer[];
}) => {
  const {
    activeTab,
    handleChangeActiveTab,
    tabs,
    onClickBulkRemindButton,
    onClickAssignButton,
    numberOfResponses,
    numberOfDistribution,
  } = useEventDetail({
    onnEvent,
    onnEventAnswers,
  });

  return (
    <>
      <StyledLink to="/events">
        <Typography variant="body2" color="textSecondary">
          ← イベント一覧
        </Typography>
      </StyledLink>
      {onnEvent.isNewInterviewEvent() ? (
        <EventDetailSummaryPaperForNewInterview currentUser={currentUser} onnEvent={onnEvent} />
      ) : (
        <EventDetailSummary
          currentUser={currentUser}
          onnEvent={onnEvent}
          numberOfResponses={numberOfResponses}
          numberOfDistribution={numberOfDistribution}
          assigneeEmployees={assigneeEmployees || []}
          onClickAssignButton={onClickAssignButton}
        />
      )}
      <Box mt={3}>
        <TabContext value={activeTab}>
          <StyledTabList
            indicatorColor="primary"
            textColor="primary"
            onChange={handleChangeActiveTab}
          >
            {tabs.map((tab) => (
              <StyledTab key={tab.value} label={tab.label} value={tab.value} />
            ))}
          </StyledTabList>
          {onnEvent.isNewInterviewEvent() ? (
            <TabPanelsForNewInterview currentUser={currentUser} onnEvent={onnEvent} />
          ) : onnEvent.type === "normal" ? (
            <TabPanelsForNormalEvent
              currentUser={currentUser}
              onnEvent={onnEvent}
              onClickBulkRemindButton={onClickBulkRemindButton}
            />
          ) : onnEvent.type === "interview" ? (
            <TabPanelsForOldInterview currentUser={currentUser} onnEvent={onnEvent} />
          ) : (
            <></>
          )}
        </TabContext>
      </Box>
    </>
  );
};

const TabPanelsForNormalEvent = ({
  currentUser,
  onnEvent,
  onClickBulkRemindButton,
}: {
  currentUser: Employee;
  onnEvent: OnnEvent;
  onClickBulkRemindButton: (tenantId: string, eventId: string) => void;
}) => {
  return (
    <>
      <StyledTabPanel value="eventContents">
        <OnnEventContentsTab currentUser={currentUser} onnEvent={onnEvent} />
      </StyledTabPanel>
      <StyledTabPanel value="answer">
        <AnswerResultTab
          currentUser={currentUser}
          onnEvent={onnEvent}
          onClickBulkRemindButton={onClickBulkRemindButton}
        />
      </StyledTabPanel>
    </>
  );
};

const TabPanelsForOldInterview = ({
  currentUser,
  onnEvent,
}: {
  currentUser: Employee;
  onnEvent: OnnEvent;
}) => {
  return (
    <>
      <StyledTabPanel value="eventContents">
        <OnnEventContentsTab currentUser={currentUser} onnEvent={onnEvent} />
      </StyledTabPanel>
      <StyledTabPanel value="answer">
        <AnswerResultTabForInterviewEvent onnEvent={onnEvent} />
      </StyledTabPanel>
    </>
  );
};

const TabPanelsForNewInterview = ({
  currentUser,
  onnEvent,
}: {
  currentUser: Employee;
  onnEvent: OnnEvent;
}) => {
  return (
    <>
      <StyledTabPanel value="eventContents">
        <OnnEventContentsTab currentUser={currentUser} onnEvent={onnEvent} />
      </StyledTabPanel>
      <StyledTabPanel value="eventSlots">
        <OnnEventSlotsTab onnEvent={onnEvent} />
      </StyledTabPanel>
      <StyledTabPanel value="interviewSchedule">
        <AnswerResultTabForNewInterviewEvent onnEvent={onnEvent} />
      </StyledTabPanel>
    </>
  );
};

const StyledLink = styled(Link)`
  display: inline-block;
  text-decoration: none;
  margin-bottom: 48px;
`;

const StyledTab = styled(Tab)`
  &.MuiTab-root {
    padding: 0px 20px;
  }
`;

const StyledTabList = styled(TabList)`
  &.MuiTabs-root {
    border-bottom: 1px solid ${(props) => props.theme.palette.divider};
  }
`;

const StyledTabPanel = styled(TabPanel)`
  width: 100%;
  &.MuiTabPanel-root {
    padding: 0;
  }
`;
