import { Box } from "@material-ui/core";
import { format } from "date-fns";
import ja from "date-fns/locale/ja";
import React, { FC } from "react";

import { StyledHeaderCellWrapper } from "./StyledHeaderCellWrapper";

import { Chip } from "~/components/domains/onnEvents";
import { Typography } from "~/components/uiParts";

export const CandidateDateHeaderCell: FC<{
  style: React.CSSProperties;
  hasCapacity: boolean;
  capacity?: number;
  numberOfParticipants: number;
  from: Date;
  until: Date;
}> = ({ style, hasCapacity, capacity, numberOfParticipants, from, until }) => {
  const dateStr = format(from, "MM/dd(eee)", { locale: ja });
  const fromTimeStr = format(from, "HH:mm", { locale: ja });
  const untilTimeStr = format(until, "HH:mm", { locale: ja });
  const label = `${dateStr}\n${fromTimeStr}〜${untilTimeStr}`;

  return (
    <StyledHeaderCellWrapper
      px="16px"
      style={{ ...style }}
      // FIXME: 単一回答ではない場合を想定しなければならない
      isLastColumn={false}
    >
      <Typography
        display="block"
        variant="body1"
        noWrap
        // Muiのデフォルトのスタイルを上書きするためにstyleで記述。styledで上書きされなかったため。
        style={{ lineHeight: 1.4, textAlign: "center" }}
      >
        {label.split("\n").map((v) => (
          <>
            {v}
            <br />
          </>
        ))}
      </Typography>
      {hasCapacity &&
        (() => {
          return (
            <Box display="flex" justifyContent="center" mt="8px">
              <Chip numberOfParticipants={numberOfParticipants} capacity={capacity || 0} />
            </Box>
          );
        })()}
    </StyledHeaderCellWrapper>
  );
};
