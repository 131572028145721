import { Box } from "@material-ui/core";
import { OnnEvent, CandidateDateWithNumberOfParticipants } from "@onn/common";
import React, { FC } from "react";

import {
  ContentDescriptionTypography,
  TitleTextField,
  ContentTextarea,
  ActionFooter,
  CandidateDateRHF,
} from "../../_shared/createAndEdit";
import { RestrictAnswerRHF } from "../../_shared/createAndEdit/ReactHooksFormComponents/RestrictAnswerRHF";
import { usePage } from "../hooks/usePage";

import { Typography, Paper } from "~/components/uiParts";

type EventEditProps = {
  currentOnnEvent: OnnEvent;
  candidateDatesWithNumberOfParticipants: CandidateDateWithNumberOfParticipants[];
};

export const EventEditor: FC<EventEditProps> = ({
  currentOnnEvent,
  candidateDatesWithNumberOfParticipants,
}) => {
  const {
    footerAction: {
      onClickCancel,
      onClickSaveAndToNext,
      isReadyToOpenPortalOnnEventPreview,
      onClickPreview,
    },
    isDisableSaveButton,
    candidateForm: { displayDeliveryFromTimeItems, displayDeliveryUntilTimeItems },
    reactHookForm: { control, trigger },
    saveButtonText,
  } = usePage({ currentOnnEvent, candidateDatesWithNumberOfParticipants });

  return (
    <Box display="flex" justifyContent="center">
      <Box width="800px" display="flex" flexDirection="column" gridRowGap={40} pb={8}>
        <Typography variant="h4" bold>
          イベント編集
        </Typography>
        <Paper style={{ display: "flex", flexDirection: "column", rowGap: 32 }}>
          <Box display="flex" flexDirection="column" gridRowGap={16}>
            <Box display="flex" flexDirection="column" gridRowGap={32}>
              <TitleTextField control={control} />
              <ContentTextarea control={control} />
            </Box>
            <ContentDescriptionTypography />
          </Box>
          <Box display="flex" flexDirection="column" gridRowGap={16}>
            <Typography variant="body2" bold>
              イベントタイプ
            </Typography>
            <Typography variant="body1">
              {OnnEvent.getEventTypeTextMap(currentOnnEvent.type)}
            </Typography>
          </Box>
        </Paper>
        {/* NOTE: 旧面談イベントではオプションを表示しない */}
        {currentOnnEvent.type !== "interview" && (
          <Paper style={{ display: "flex", flexDirection: "column", rowGap: 40 }}>
            {currentOnnEvent.type === "normal" && (
              <CandidateDateRHF
                isEdit={true}
                control={control}
                trigger={trigger}
                displayDeliveryFromTimeItems={displayDeliveryFromTimeItems}
                displayDeliveryUntilTimeItems={displayDeliveryUntilTimeItems}
              />
            )}
            <RestrictAnswerRHF control={control} onnEventType={currentOnnEvent.type} />
          </Paper>
        )}
      </Box>
      <ActionFooter
        isDisableSaveButton={isDisableSaveButton}
        onClickSaveAndToNext={onClickSaveAndToNext}
        onClickCancel={onClickCancel}
        isReadyToOpenPortalOnnEventPreview={isReadyToOpenPortalOnnEventPreview}
        onClickPreview={onClickPreview}
        saveButtonText={saveButtonText}
      />
    </Box>
  );
};
