import { MemoTransaction, Transaction } from "@onn/common";
import useSWR, { SWRResponse } from "swr";

import { TransactionUseCase } from "~/service/usecases/transactionUseCase";

/**
 * employeeIdをもとに全Transactionsを返すswr
 * @param employeeId employeeのid
 */
export const useTransactions = (
  employeeId?: string
): SWRResponse<Array<Transaction | MemoTransaction>, Error> => {
  const key = employeeId ? ["transactions", employeeId] : null;

  return useSWR(key, ([_key, employeeId]: [string, string]) =>
    TransactionUseCase.whereByDataTypes({
      employeeId,
      dataTypes: [
        "WELCOME",
        "PROFILE_COMMENT",
        "MENTOR_REGISTRATION",
        "JOIN_DATE_INPUT",
        "ADD_MEMO",
      ],
    }).then((res) => res)
  );
};
