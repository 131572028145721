import { Box } from "@material-ui/core";
import React, { useCallback, useState } from "react";
import styled from "styled-components";

import { ModalContent } from "./modal-content/ModalContent";

import { useUpdateRecruitmentStatusActionModal } from "./useUpdateRecruitmentStatusActionModal";

import { Button, ScrollableBodyModal } from "~/components/uiParts";

type Props = {
  open: boolean;
  onCancel(): void;
  onConfirm(statusId: string): Promise<void>;
};

export const UpdateRecruitmentStatusActionModal = ({
  open,
  onCancel,
  onConfirm,
}: Props): JSX.Element => {
  const [isUpdating, setUpdating] = useState(false);
  const { selectedStatusId, onChangeStatusId } = useUpdateRecruitmentStatusActionModal();

  const handleConfirm = useCallback(async () => {
    if (!selectedStatusId) return;

    setUpdating(true);
    await onConfirm(selectedStatusId).finally(() => setUpdating(false));
    onCancel();
  }, [selectedStatusId, onConfirm, onCancel]);

  return (
    <ScrollableBodyModal
      open={open}
      title={"絞り込み"}
      footer={
        <StyledButtonContainer>
          <Box display={"flex"} gridGap={"16px"}>
            <Box>
              <Button borderRadius="circle" variant="text" color="default" onClick={onCancel}>
                キャンセル
              </Button>
            </Box>
            <Box>
              <Button
                borderRadius="circle"
                variant="contained"
                color="primary"
                onClick={() => handleConfirm()}
                isLoading={isUpdating}
              >
                変更を保存
              </Button>
            </Box>
          </Box>
        </StyledButtonContainer>
      }
      content={
        <ModalContent selectedStatusId={selectedStatusId} onChangeStatusId={onChangeStatusId} />
      }
      onCancel={onCancel}
      fullWidth
      disableBackdropModal
    />
  );
};

const StyledButtonContainer = styled(Box)`
  display: flex;
  justify-content: flex-end;
`;
