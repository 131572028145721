import { EmployeeToDisplay, NewGraduateToDisplay } from "@onn/common";
import { useCallback, useMemo } from "react";
import useSWR, { KeyedMutator, SWRResponse, useSWRConfig } from "swr";

import { useCurrentUser } from "~/hooks/employee";
import { apiClient } from "~/libs";

const getKey = ({ tenantId, isAcrossSpace }: { tenantId: string; isAcrossSpace?: boolean }) => {
  const limit = 1_000_000;
  const offset = 0;

  return {
    endpoint: "/get_list_new_graduates",
    limit,
    offset,
    tenantId,
    isAcrossSpace,
  } as const;
};

/**
 * 権限がメンバーの場合自分がアサインされている入社者のみを取得する
 */
export const useAllNewcomers = (args?: {
  isAcrossSpace?: boolean;
}): SWRResponse<NewGraduateToDisplay[], Error> => {
  const { currentUser } = useCurrentUser();

  return useSWR(
    getKey({ tenantId: currentUser.tenantId, isAcrossSpace: args?.isAcrossSpace }),
    async ({ endpoint, limit, offset, isAcrossSpace }) => {
      const response = await apiClient.get(endpoint, {
        "include-deleted": false,
        limit,
        offset,
        filter: { isNewcomer: true, isAcrossSpace },
      });

      const newGraduateToDisplay = response.data.data.map((v) => {
        return new NewGraduateToDisplay(v, v.employeeTagIds, {
          id: v.recruitmentStatusId,
          label: v.recruitmentStatusLabel,
          type: v.recruitmentStatusType,
        });
      });

      return newGraduateToDisplay;
    }
  );
};

export const useMutateAllNewcomers = () => {
  const { mutate } = useSWRConfig();
  const { currentUser } = useCurrentUser();

  const key = useMemo(() => getKey({ tenantId: currentUser.tenantId }), [currentUser.tenantId]);

  const mutateAllNewcomers: KeyedMutator<EmployeeToDisplay[] | undefined> = useCallback(
    async (data, opts) => {
      if (!data) {
        return mutate(key);
      }
      return mutate(key, data, opts);
    },
    [key, mutate]
  );

  return { mutateAllNewcomers };
};
