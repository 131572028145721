import { v4 } from "uuid";

import { EmployeeTagRelation as IEmployeeTagRelation } from "../_gen/zodSchema/index";

import { employeeTagRelationSchema } from "./schema";

export class EmployeeTagRelation implements IEmployeeTagRelation {
  static validator = employeeTagRelationSchema;

  id: string;
  tagId: string;
  employeeId: string;
  creatorId?: string; // 共通招待リンクを用いたアカウント登録などの場合に、creatorId が埋まらない
  tenantId: string;
  createdAt: Date;
  /** 紐づくEmployeeが削除された場合に true になる
   * - 通常の削除操作では、物理削除される
   */
  deleted: boolean;

  constructor(init: ExcludeMethods<EmployeeTagRelation>) {
    const parsedInit = EmployeeTagRelation.validator.parse(init);

    this.id = parsedInit.id;
    this.tagId = parsedInit.tagId;
    this.employeeId = parsedInit.employeeId;
    this.creatorId = parsedInit.creatorId;
    this.tenantId = parsedInit.tenantId;
    this.createdAt = parsedInit.createdAt;
    this.deleted = parsedInit.deleted;
  }

  static create(
    init: ExcludeMethods<Optional<EmployeeTagRelation, "id" | "createdAt" | "deleted">>
  ) {
    return new EmployeeTagRelation({
      ...init,
      id: init.id ?? v4(),
      createdAt: init.createdAt ?? new Date(),
      deleted: init.deleted ?? false,
    });
  }

  logicallyDelete() {
    return new EmployeeTagRelation({ ...this, deleted: true });
  }

  isDeleted() {
    return this.deleted;
  }
}
