import React, { FC, useMemo } from "react";

import { HeaderCellItem } from "./Header/HeaderCellItem";
import { TotalCountHeaderCell } from "./Header/TotalCountHeaderCell";

export const HeaderCell: FC<{
  style: React.CSSProperties;

  columnIndex: number;
  numberOfResponses: number;
}> = ({ style, columnIndex, numberOfResponses }) => {
  const columnMap: Record<number, React.JSX.Element> = useMemo(() => {
    return {
      0: <TotalCountHeaderCell numberOfResponses={numberOfResponses} style={style} />,
      1: <HeaderCellItem style={style} text="調整状況" />,
      2: <HeaderCellItem style={style} text="選考担当者" />,
      3: <HeaderCellItem style={style} text="日程" />,
      4: <HeaderCellItem style={style} text="開催方法" />,
      5: <HeaderCellItem style={style} text="概要" />,
    };
  }, [numberOfResponses, style]);

  return columnMap[columnIndex];
};
