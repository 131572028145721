import {
  autoContactMessageSettingSchema,
  IAutoContactMessageSetting,
  Status,
  TEXT_MAX_LENGTH,
  RuleType,
} from "./schema";

export class AutoContactMessageSetting implements IAutoContactMessageSetting {
  private static validator = autoContactMessageSettingSchema;
  static textMaxLength = TEXT_MAX_LENGTH;

  readonly id: string;
  readonly tenantId: string;
  readonly spaceId: string;
  readonly name: string;
  readonly status: Status;
  readonly rule: {
    type: RuleType;
    text: string;
    senderEmployeeId: string;
  };
  readonly createdAt: Date;
  readonly updatedAt: Date;

  constructor(init: ExcludeMethods<AutoContactMessageSetting>) {
    AutoContactMessageSetting.validator.parse(init);

    this.id = init.id;
    this.tenantId = init.tenantId;
    this.spaceId = init.spaceId;
    this.name = init.name;
    this.status = init.status;
    this.rule = init.rule;
    this.createdAt = init.createdAt;
    this.updatedAt = init.updatedAt;
  }

  // アプリケーションからは、シナリオ設定を作成しない
}
