import {
  CandidateDateWithNumberOfParticipants,
  EmployeeActiveLog,
  OnnEvent,
  OnnEventDeterminedDate,
} from "@onn/common";
import React, { useMemo } from "react";
import { GridCellRenderer, Index } from "react-virtualized";

import { BodyCell } from "./TableCell/BodyCell";
import { HeaderCell } from "./TableCell/HeaderCell";

import { useRecruitmentStatusList } from "~/components/providers/RecruitmentStatusProvider";
import { OnnEventAnswerWithEmployee } from "~/hooks/onnEvent";

const EMPTY_TEXT_AREA_HEIGHT = 200;

const BODY_CELL_HEIGHT = 72;

const HEADER_HEIGHT_WHEN_EXIST_CAPACITY = 88;

const HEADER_HEIGHT_WHEN_NOT_EXIST_CAPACITY = 60;

export const useAnswerResultTable = ({
  onnEvent,
  onnEventAnswers,
  candidateDateList,
  determinedDates,
  hasCapacity,
  numberOfDistribution,
  numberOfResponses,
  employeeIdToLatestEmployeeActiveLogMap,
}: {
  candidateDateList: CandidateDateWithNumberOfParticipants[];
  onnEvent: OnnEvent;
  onnEventAnswers: OnnEventAnswerWithEmployee[];
  determinedDates: OnnEventDeterminedDate[];
  hasCapacity: boolean;
  numberOfDistribution: number;
  numberOfResponses: number;
  employeeIdToLatestEmployeeActiveLogMap: Map<string, EmployeeActiveLog>;
}) => {
  const { recruitmentStatusMap } = useRecruitmentStatusList();

  // 回答者がいるかどうか
  const isExistBodyRows = useMemo(() => !!onnEventAnswers.length, [onnEventAnswers]);

  // インタビューイベントの列数は固定値
  const columnCount = 6;

  // ヘッダー行の数＋回答者数 の合計値が行数
  const headerRowCount = 1;
  const rowCount = headerRowCount + onnEventAnswers.length;

  // ヘッダーの高さ
  const headerCellHeight = hasCapacity
    ? HEADER_HEIGHT_WHEN_EXIST_CAPACITY
    : HEADER_HEIGHT_WHEN_NOT_EXIST_CAPACITY;

  // 各行の高さ
  const rowHeight = (params: Index) => (params.index === 0 ? headerCellHeight : BODY_CELL_HEIGHT);
  // 各カラムの幅
  const columnWidth = (params: Index) => {
    if (params.index === 0) return 204;
    if (params.index === 3) return 240;
    return 148;
  };
  // multiGridの高さ
  const multiGridHight = useMemo(() => {
    const height = headerCellHeight + BODY_CELL_HEIGHT * (rowCount - 1);
    return height > 1000 ? 1000 : height;
  }, [headerCellHeight, rowCount]);

  // 回答結果テーブルペーパーの高さ
  const paperHeight = useMemo(() => {
    const paddingTop = 40;
    const paddingBottom = 40;
    const result = paddingTop + paddingBottom + multiGridHight;
    if (isExistBodyRows) return result;
    return result + EMPTY_TEXT_AREA_HEIGHT;
  }, [isExistBodyRows, multiGridHight]);

  // multiGridの各セルでのレンダリング
  const cellRenderer: GridCellRenderer = ({ style, rowIndex, columnIndex }) => {
    // ヘッダー行
    if (rowIndex === 0) {
      return (
        <HeaderCell
          {...{
            style,
            candidateDateList,
            onnEventAnswers,
            hasCapacity,
            columnIndex,
            numberOfDistribution,
            numberOfResponses,
            columnLength: columnCount,
          }}
        />
      );
    }

    // ボディ行
    const onnEventAnswer = onnEventAnswers[rowIndex - 1];
    const determinedDate =
      onnEventAnswer &&
      determinedDates.find((determinedDate) =>
        determinedDate.isEmployeeDeterminate({
          onnEventId: onnEventAnswer.onnEventId,
          employeeId: onnEventAnswer.employeeId,
        })
      );
    const candidateDate = candidateDateList.find(
      (date) => date.id === determinedDate?.candidateDateId
    );

    const employeeActiveLog =
      onnEventAnswer && employeeIdToLatestEmployeeActiveLogMap.get(onnEventAnswer.employeeId);
    return (
      <BodyCell
        style={{ ...style }}
        columnIndex={columnIndex}
        onnEvent={onnEvent}
        onnEventAnswer={onnEventAnswer}
        columnLength={columnCount}
        determinedDate={determinedDate}
        candidateDate={candidateDate}
        employeeActiveLog={employeeActiveLog}
        recruitmentStatusMap={recruitmentStatusMap}
      />
    );
  };
  return {
    cellRenderer,
    rowCount,
    columnCount,
    rowHeight,
    columnWidth,
    paperHeight,
    multiGridHight,
    isExistBodyRows,
  };
};
