import { Menu, MenuItem } from "@material-ui/core";
import React, { FC, useState, MouseEvent } from "react";

import { IconButton, Typography } from "~/components/uiParts";

export const ControlMenu: FC<{ onClickDelete: () => void; onClickDuplication: () => void }> = ({
  onClickDelete,
  onClickDuplication,
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const handleClickButton = (event: MouseEvent<HTMLButtonElement>) =>
    setAnchorEl(event.currentTarget);
  const menuOptions = [
    { id: "DUPLICATION", title: "複製", onClick: onClickDuplication },
    { id: "DELETE", title: "削除", onClick: onClickDelete },
  ];

  return (
    <>
      <IconButton icon="menuVert" onClick={handleClickButton} />
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        {menuOptions.map((option) => (
          <MenuItem
            key={option.id}
            onClick={(e) => {
              e.stopPropagation();
              option.onClick();
              setAnchorEl(null);
            }}
          >
            <Typography variant="body2">{option.title}</Typography>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
