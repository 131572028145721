import { Box, Menu, MenuItem } from "@material-ui/core";
import { OnnEvent } from "@onn/common";

import React, { FC, MouseEvent, useCallback, useMemo, useState } from "react";
import { CSVLink } from "react-csv";
import { AutoSizer, MultiGrid } from "react-virtualized";
import styled from "styled-components";

import { AnswerStatusFilter } from "./AnswerStatusFilter/AnswerStatusFilter";
import { useAnswerResultTab } from "./hooks/OnnEventAnswerResultTabForInterviewEvent/useAnswerResultTab";
import { useAnswerResultTable } from "./useAnswerResultTable";

import { useOutputResultAnswerCsv } from "./useOutputResultAnswerCsv";

import { EmployeeFilter } from "~/components/domains/employees";
import {
  Button,
  Loading,
  Typography,
  TableActionsLayoutWithFiltersAndSearchForm,
  Paper,
  Icon,
  IconButton,
} from "~/components/uiParts";

import { SearchForm } from "~/components/uiParts/SearchForm";

import { useAdmins, useCurrentUser, useNewGraduates } from "~/hooks/employee";
import { useModal } from "~/hooks/modal";

type Props = {
  onnEvent: OnnEvent;
};

export const TabPage: FC<Props> = ({ onnEvent }) => {
  const {
    selectedOnnEventAnswers,
    setSearchValue,
    determinedDates,
    isLoading,
    selectedAnswerStatusTypes,
    setSelectedAnswerStatusTypes,
    allAssigneeIds,
    selectedAssigneeIds,
    setSelectedAssigneeIds,
    candidateDatesWithNumberOfParticipants,
    numberOfDistribution,
    numberOfResponses,
    employeeIdToLatestEmployeeActiveLogMap,
  } = useAnswerResultTab({
    onnEventId: onnEvent.id,
  });

  const {
    cellRenderer,
    rowCount,
    columnCount,
    rowHeight,
    columnWidth,
    paperHeight,
    multiGridHight,
    isExistBodyRows,
  } = useAnswerResultTable({
    determinedDates: determinedDates || [],
    candidateDateList: candidateDatesWithNumberOfParticipants || [],
    onnEvent,
    onnEventAnswers: selectedOnnEventAnswers,
    hasCapacity: onnEvent.hasCapacity(),
    numberOfDistribution,
    numberOfResponses,
    employeeIdToLatestEmployeeActiveLogMap,
  });

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const openMenu = useCallback((event: MouseEvent<HTMLElement>) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  }, []);

  const closeMenu = useCallback((event: MouseEvent<HTMLElement>) => {
    event.preventDefault();
    setAnchorEl(null);
  }, []);

  const { currentUser } = useCurrentUser();
  const { handleModal } = useModal();

  const employeeIds = selectedOnnEventAnswers.map((v) => v.employeeId);
  const { data: newGraduates, isLoading: isLoadingNewGraduate } = useNewGraduates({
    newGraduateIds: employeeIds,
  });
  const newGraduatesMap = new Map(newGraduates?.map((ng) => [ng.id, ng])) || [];
  const { data: admins, isLoading: isLoadingAdmins } = useAdmins(currentUser.tenantId);
  const assigneeMap = new Map(admins?.map((admin) => [admin.id, admin])) || [];
  const isCsvDataLoading = isLoadingNewGraduate || isLoadingAdmins;

  const { csvData, filename } = useOutputResultAnswerCsv({
    onnEvent,
    determinedDates: determinedDates || [],
    assigneeMap,
    newGraduatesMap,
  });

  const firstFilter = useMemo(
    () => (
      <Box display="flex" flexDirection="column" gridRowGap="8px">
        <Typography variant="body2" bold color="textSecondary">
          調整状況
        </Typography>
        <Box>
          <AnswerStatusFilter
            selectedAnswerStatusTypes={selectedAnswerStatusTypes}
            onSetSelectedAnswerStatusTypes={setSelectedAnswerStatusTypes}
          />
        </Box>
      </Box>
    ),
    [selectedAnswerStatusTypes, setSelectedAnswerStatusTypes]
  );

  const secondFilter = useMemo(
    () => (
      <Box display="flex" flexDirection="column" gridRowGap="8px">
        <Typography variant="body2" bold color="textSecondary">
          選考担当者
        </Typography>
        <Box>
          <EmployeeFilter
            selectableEmployeeIds={allAssigneeIds}
            selectedEmployeeIds={selectedAssigneeIds}
            onChange={setSelectedAssigneeIds}
          />
        </Box>
      </Box>
    ),

    [allAssigneeIds, selectedAssigneeIds, setSelectedAssigneeIds]
  );

  const handleSubmit = useCallback(async () => {
    handleModal({
      name: "answerInterviewEventOnBehalfModal",
      args: {
        onnEvent,
        mode: {
          type: "create",
        },
      },
    });
  }, [handleModal, onnEvent]);

  const openAnswerInterviewEventOnBehalfWithCSVModal = useCallback(() => {
    handleModal({
      name: "answerInterviewEventOnBehalfWithCSVModal",
      args: {
        onnEvent,
      },
    });
  }, [handleModal, onnEvent]);

  if (isLoading) {
    return <Loading size="large" />;
  }

  return (
    <>
      <Box
        my="36px"
        display="flex"
        gridGap={24}
        alignItems="flex-end"
        justifyContent="space-between"
      >
        <TableActionsLayoutWithFiltersAndSearchForm
          firstFilter={firstFilter}
          secondFilter={secondFilter}
          searchForm={
            <SearchForm
              onSearchValue={setSearchValue}
              placeholder="ユーザー名・メールアドレスで検索"
              variant="standard"
              fullWidth
            />
          }
        />
        <Box display="flex" alignItems="flex-center" gridGap={24}>
          <Box height={40}>
            <Button
              color="primary"
              variant="outlined"
              borderRadius="regular"
              fullWidth
              fullHeight
              startIcon={<Icon icon="add" color="primary" size="md" />}
              onClick={handleSubmit}
            >
              回答追加
            </Button>
          </Box>
          {currentUser.isAdmin() && (
            <>
              <IconButton icon="menuVert" onClick={openMenu} />
              <Menu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={closeMenu}
                getContentAnchorEl={null}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              >
                <MenuItem>
                  {isCsvDataLoading ? (
                    <Loading size="small" />
                  ) : (
                    <StyledCSVLink data={csvData} filename={filename}>
                      <Typography variant="body2" color="textPrimary">
                        CSVダウンロード
                      </Typography>
                    </StyledCSVLink>
                  )}
                </MenuItem>
                <MenuItem onClick={openAnswerInterviewEventOnBehalfWithCSVModal}>
                  <Typography variant="body2" color="textPrimary">
                    回答一括登録
                  </Typography>
                </MenuItem>
              </Menu>
            </>
          )}
        </Box>
      </Box>
      <StyledPaper height={paperHeight}>
        <AutoSizer disableHeight>
          {({ width }) => {
            return (
              <StyledBox width={width}>
                <MultiGrid
                  cellRenderer={cellRenderer}
                  rowHeight={rowHeight}
                  columnWidth={columnWidth}
                  columnCount={columnCount}
                  rowCount={rowCount}
                  fixedColumnCount={1}
                  fixedRowCount={1}
                  enableFixedColumnScroll
                  height={multiGridHight}
                  width={width}
                />
              </StyledBox>
            );
          }}
        </AutoSizer>
        {!isExistBodyRows && (
          <Box
            width="100%"
            height="100%"
            display="flex"
            alignItems="center"
            justifyContent="center"
            pb="40px" // NOTE: 上下中央に配置するためにpaddingを設定
          >
            <Typography variant="body2" align="center" color="textSecondary">
              結果が見つかりませんでした。
            </Typography>
          </Box>
        )}
      </StyledPaper>
    </>
  );
};

const StyledPaper = styled(Paper)<{ height: number }>`
  padding: 40px;
  height: ${(props) => props.height}px;
`;

const StyledBox = styled(Box)`
  .ReactVirtualized__Grid:first-child {
    -ms-overflow-style: none;
    ::-webkit-scrollbar {
      display: none;
    }
  }
`;

const StyledCSVLink = styled(CSVLink)`
  text-decoration: none;
`;
