import {
  OnnEventEvaluation,
  OnnEventEvaluationRank,
  getFileLikeObjectsFromFilePath,
} from "@onn/common";

export const useIsSubmitButtonDisabled = ({
  pageType,
  evaluationText,
  selectedOnnEventEvaluationRank,
  attachedFiles,
  alreadyRegisteredOnnEventEvaluation,
  alreadyRegisteredOnnEventEvaluationText,
}: {
  pageType: "new" | "edit";
  evaluationText: string;
  selectedOnnEventEvaluationRank: OnnEventEvaluationRank | null;
  attachedFiles: File[] | Pick<File, "name">[];
  alreadyRegisteredOnnEventEvaluation?: OnnEventEvaluation;
  alreadyRegisteredOnnEventEvaluationText: string;
}) => {
  // 「変更を保存」ボタンのdisabled状態を制御する
  const isSubmitButtonDisabled = (() => {
    if (pageType === "new") {
      return evaluationText == "" && selectedOnnEventEvaluationRank == null;
    } else {
      const currentOnnEventEvaluationFilePaths =
        alreadyRegisteredOnnEventEvaluation?.filePaths || [];

      // すでに登録されている評価と差分がない場合
      const isNoDIff =
        (alreadyRegisteredOnnEventEvaluationText === evaluationText &&
          (selectedOnnEventEvaluationRank?.id || null) ===
            (alreadyRegisteredOnnEventEvaluation?.onnEventEvaluationRankId || null) &&
          attachedFiles.length === currentOnnEventEvaluationFilePaths.length &&
          alreadyRegisteredOnnEventEvaluation?.filePaths?.every((filePath) =>
            attachedFiles.some((file) => file.name === getFileLikeObjectsFromFilePath(filePath))
          )) ||
        false;

      // 評価ランク・メモともに未入力
      const isNotInputRankAndText = evaluationText == "" && selectedOnnEventEvaluationRank == null;

      return isNoDIff || isNotInputRankAndText;
    }
  })();

  return { isSubmitButtonDisabled };
};
