import { ErrorType, ErrorStatus } from "@onn/common";
import { useCallback } from "react";

export const useGetErrorMessage = () => {
  const getErrorMessage = useCallback((errorStatus: ErrorStatus, isCsv: boolean): string[] => {
    const errorMessages: string[] = [];
    errorStatus.forEach((rows, errorType) => {
      const errorRowNumbers = rows.toString();

      if (isCsv && errorType === ErrorType.NO_EMAIL_ADDRESS) {
        errorMessages.push(
          `${errorRowNumbers}番目の「メールアドレス(必須)」が入力されていません。また、空白番の場合は削除してください`
        );
      }

      if (errorType === ErrorType.DUPLICATED_EMAIL_ADDRESS_IN_CSV) {
        errorMessages.push(
          `${
            isCsv ? `${errorRowNumbers}番目の「メールアドレス」` : "メールアドレス"
          }が重複しています。どちらかを削除してください`
        );
      }

      if (errorType === ErrorType.INVALID_EMAIL_ADDRESS) {
        errorMessages.push(
          `${
            isCsv ? `${errorRowNumbers}番目の「メールアドレス」` : "メールアドレス"
          } の形式が間違っています`
        );
      }

      if (errorType === ErrorType.NOT_FOUND_EMPLOYEE_BY_EMAIL_ADDRESS) {
        errorMessages.push(
          `${
            isCsv ? `${errorRowNumbers}番目の「メールアドレス」` : "このメールアドレス"
          }を使用しているユーザーが見つかりません。`
        );
      }

      if (errorType === ErrorType.ALREADY_DELETED_USER) {
        errorMessages.push(
          `${
            isCsv ? `${errorRowNumbers}番目の「メールアドレス」` : "このメールアドレス"
          }を使用しているユーザーは既に削除されています。`
        );
      }

      if (isCsv && errorType === ErrorType.NO_EXISTING_TAG_NAME) {
        errorMessages.push(
          `${errorRowNumbers}番目の「タグ」が間違っています。サンプルファイルをダウンロードし、コピーしてください`
        );
      }

      if (errorType === ErrorType.ALREADY_TAG_REGISTERED) {
        errorMessages.push(
          `${
            isCsv ? `${errorRowNumbers}番目の「メールアドレス」` : "このメールアドレス"
          }を使用しているユーザーには既に同じタグが付与されています。`
        );
      }
    });

    return errorMessages;
  }, []);

  return { getErrorMessage };
};
