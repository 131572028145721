import React, { FC } from "react";

import { ColumnType, BodyRowData } from "../const";

import { AssigneeCell } from "./AssigneeCell";
import { StyleProps } from "./CellItem";
import { EvaluationCell } from "./EvaluationCell";
import { ProgressStatusCell } from "./ProgressStatusCell";
import { RecruitmentStatusLabelCell } from "./RecruitmentStatusLabelCell";
import { RelatedFilesCell } from "./RelatedFilesCell";
import { ScheduleCell } from "./ScheduleCell";
import { UpdatedAtCell } from "./UpdatedAtCell";

export const BodyCell: FC<
  {
    bodyRowData: BodyRowData;
    columnType: ColumnType;
  } & StyleProps
> = ({ bodyRowData, columnType, ...styleProps }) => {
  const result = (): JSX.Element => {
    switch (columnType) {
      case "recordLabel":
        return (
          <RecruitmentStatusLabelCell
            recruitmentProcessRecordByEmployee={bodyRowData[columnType]}
            {...styleProps}
          />
        );
      case "status":
        return <ProgressStatusCell label={bodyRowData[columnType]} {...styleProps} />;
      case "schedule":
        return <ScheduleCell {...bodyRowData[columnType]} {...styleProps} />;
      case "assignee":
        return <AssigneeCell {...bodyRowData[columnType]} {...styleProps} />;
      case "evaluation":
        return <EvaluationCell {...bodyRowData[columnType]} {...styleProps} />;
      case "relatedFiles":
        return <RelatedFilesCell filePaths={bodyRowData[columnType]} {...styleProps} />;
      case "updatedAt":
        return <UpdatedAtCell updatedAt={bodyRowData[columnType]} {...styleProps} />;
    }
  };
  return result();
};
