import { Box, Menu } from "@material-ui/core";
import { EmployeeTag } from "@onn/common";
import { throttle } from "lodash";
import React, { FC, memo, useCallback, useMemo, useRef, useState } from "react";
import styled from "styled-components";

import { ImmediateSelectCheckboxMenu } from "../../ImmediateSelectCheckboxMenu";

import { Button, Icon, Tooltip, Typography } from "~/components/uiParts";
import { useCurrentUser } from "~/hooks/employee";
import { useEditTagsOnEmployee } from "~/hooks/employee/useEditTagsOnEmployee";
import { useSnackbar } from "~/hooks/shared";

type Props = {
  employeeId: string;
  allTags: ExcludeMethods<EmployeeTag>[];
  addedTagIds: string[];
};

const anchorOrigin = { vertical: "bottom", horizontal: "right" } as const;
const transformOrigin = { vertical: "top", horizontal: "right" } as const;

export const NewGraduateTags: FC<Props> = memo(({ employeeId, allTags, addedTagIds }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const anchorElRef = useRef(null);
  const { currentUser } = useCurrentUser();
  const { trigger: editTagsOnEmployee } = useEditTagsOnEmployee({ employeeId });
  const { enqueueSnackbar } = useSnackbar();

  const throttledOnSelect = useMemo(() => {
    return throttle(async (employeeTagIds: string[]) => {
      return editTagsOnEmployee({ employeeTagIds }).catch(() =>
        enqueueSnackbar("タグの更新に失敗しました", { variant: "error" })
      );
    }, 1000);
  }, [editTagsOnEmployee, enqueueSnackbar]);

  const tagsForRow = useMemo(() => {
    return allTags
      .filter((tag) => addedTagIds.includes(tag.id))
      .sort((a, b) => a.name.localeCompare(b.name));
  }, [addedTagIds, allTags]);
  const tagsText = tagsForRow?.map((v) => v?.name).join(",") || "";

  const openMenu = useCallback((e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setAnchorEl(anchorElRef.current);
  }, []);

  const onClickMenu = useCallback(() => setAnchorEl(null), []);

  const choices = useMemo(() => allTags.map((t) => ({ label: t.name, value: t.id })), [allTags]);

  return (
    <Box display={"flex"} flexDirection={"column"} alignItems={"start"}>
      {/* ml -8px はメニューとタグの間に余白をつけるために付けている */}
      <div style={{ marginLeft: "-8px" }} ref={anchorElRef} />
      {!currentUser.isAdmin() || addedTagIds.length ? (
        <Tooltip title={currentUser.isAdmin() ? "編集" : tagsText} placement="top">
          <Box
            height="40px"
            width="100%"
            py="4px"
            onClick={currentUser.isAdmin() ? openMenu : undefined}
          >
            <StyledTypography variant="caption" color="textPrimary">
              {tagsText}
            </StyledTypography>
          </Box>
        </Tooltip>
      ) : (
        <Button
          variant="text"
          color="default"
          borderRadius="regular"
          startIcon={<Icon icon="add" color="lightGrey" size="md" />}
          onClick={currentUser.isAdmin() ? openMenu : undefined}
        >
          <Typography color="textSecondary" variant="caption">
            タグを追加
          </Typography>
        </Button>
      )}
      <Menu
        key={"menu"}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClick={onClickMenu}
        getContentAnchorEl={null}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
      >
        {/* div については https://github.com/mui/material-ui/issues/15903 を参照 */}
        <div>
          <ImmediateSelectCheckboxMenu
            choices={choices}
            defaultSelectedValues={addedTagIds}
            onChange={throttledOnSelect}
          />
        </div>
      </Menu>
    </Box>
  );
});

const StyledTypography = styled(Typography)`
  display: -webkit-box;
  overflow: hidden;
  word-break: break-all;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
`;
