import { Box, Menu } from "@material-ui/core";
import { isEmpty } from "lodash";
import React, { useCallback, useState } from "react";
import styled from "styled-components";

import {
  Button,
  Checkbox,
  FormControlLabel,
  Icon,
  SearchForm,
  Typography,
} from "~/components/uiParts";

export interface Choice<T extends string> {
  label: string;
  value: T;
}

type Props<T extends string> = {
  key: string;
  placeHolder?: string;
  selectedChoices: Choice<T>[] | undefined;
  choices: Choice<T>[];
  /**
   * choices.label を文字列でフィルターする
   */
  withSearch?: boolean;
  searchPlaceholder?: string;
  onChange(newChoices: Choice<T>[]): void;
};
export const SelectMultipleConditionDropdown = <T extends string = string>({
  key,
  selectedChoices,
  placeHolder,
  choices,
  withSearch,
  searchPlaceholder,
  onChange,
}: Props<T>): JSX.Element => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [searchValue, setSearchValue] = useState("");

  const toggleCheck = useCallback(
    (checked: boolean, choice: Choice<T>) =>
      checked
        ? onChange([...(selectedChoices ?? []), choice])
        : onChange([...(selectedChoices ?? []).filter((c) => c.label !== choice.label)]),
    [onChange, selectedChoices]
  );

  return (
    <Box width={"100%"}>
      <Button
        color="default"
        variant="outlined"
        borderRadius="regular"
        fullWidth
        onClick={(e) => setAnchorEl(e.currentTarget)}
        endIcon={<Icon icon="dropdownArrow" size="sm" color="grey" />}
      >
        <Box width={"100%"} flex={1} display={"flex"}>
          <Typography variant="body2" noWrap>
            {selectedChoices && !isEmpty(selectedChoices)
              ? selectedChoices.map((c) => c.label).join(",")
              : placeHolder ?? "選択してください"}
          </Typography>
        </Box>
      </Button>
      <Menu
        keepMounted
        key={key}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClick={() => setAnchorEl(null)}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Box py="8px" width="240px" display="flex" flexDirection="column">
          {withSearch && (
            <StyledSearchFormWrapper
              padding={"8px"}
              marginBottom={"8px"}
              onClick={(e) => e.stopPropagation()}
            >
              <SearchForm
                placeholder={searchPlaceholder || "検索"}
                variant="outlined"
                fullWidth
                onSearchValue={(value) => {
                  setSearchValue(value);
                }}
              />
            </StyledSearchFormWrapper>
          )}
          <Box
            px="24px"
            maxHeight={300}
            overflow="auto"
            display="flex"
            flexDirection="column"
            gridGap="8px"
          >
            {choices.flatMap((choice) => {
              if (searchValue.trim() && !choice.label.includes(searchValue)) {
                return [];
              }

              return (
                <FormControlLabel
                  onClick={(e) => e.stopPropagation()}
                  key={choice.value}
                  control={
                    <Checkbox
                      checked={
                        selectedChoices &&
                        selectedChoices.map((c) => c.label).includes(choice.label)
                      }
                      onClick={(e) => e.stopPropagation()}
                      onChange={(_e, checked) => {
                        toggleCheck(checked, choice);
                      }}
                      name={choice.value}
                      value={choice.value}
                    />
                  }
                  label={
                    <Typography variant="body2" color="textSecondary">
                      {choice.label}
                    </Typography>
                  }
                />
              );
            })}
          </Box>
          {!isEmpty(selectedChoices) && (
            <Box mt="8px" px="24px">
              <Button
                onClick={(e) => {
                  e.stopPropagation();
                  onChange([]);
                }}
                color="primary"
                variant="text"
                borderRadius="regular"
              >
                条件をクリア
              </Button>
            </Box>
          )}
        </Box>
      </Menu>
    </Box>
  );
};

const StyledSearchFormWrapper = styled(Box)`
  .MuiInputBase-root {
    height: 42px;
  }
`;
