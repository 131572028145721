import React, { FC } from "react";

import { BodyRowData } from "../const";

import { CellItem, StyleProps } from "./CellItem";

import { Button, Icon, IconButton, Typography } from "~/components/uiParts";
import { useModal } from "~/hooks/modal";

export const EvaluationCell: FC<BodyRowData["evaluation"] & StyleProps> = ({
  evaluation,
  isEvent,
  shouldPromptForScheduleSetting,
  canEvaluate,
  onnEventId,
  newGraduateId,
  newGraduateName,
  ...styleProps
}) => {
  const { handleModal } = useModal();

  const handleOpenEvaluationModal = () => {
    // 評価入力モーダルを開くときは必ず渡される
    if (onnEventId) {
      handleModal({
        name: "editOnnEventEvaluationModal",
        args: {
          onnEventId: onnEventId,
          newGraduateName,
          newGraduateId,
          alreadyRegisteredOnnEventEvaluation: evaluation,
        },
      });
    }
  };

  if (!isEvent) {
    return (
      <CellItem {...styleProps}>
        <Icon color="lightGrey" size="xl" icon="horizontalBar" />
      </CellItem>
    );
  }

  if (shouldPromptForScheduleSetting) {
    return <></>;
  }

  if (!canEvaluate) {
    return (
      <CellItem {...styleProps}>
        <Icon color="lightGrey" size="xl" icon="horizontalBar" />
      </CellItem>
    );
  }

  // TODO[選考プロセス]: 評価入力モーダルを開けるようにする
  return (
    <CellItem {...styleProps}>
      {evaluation ? (
        <IconButton icon="memo" color="primary" size="lg" onClick={handleOpenEvaluationModal} />
      ) : (
        <Button
          color="primary"
          variant="text"
          borderRadius="regular"
          startIcon={<Icon icon="add" color="primary" size="sm" />}
          onClick={handleOpenEvaluationModal}
        >
          <Typography variant="caption" color="primary" bold>
            評価を入力
          </Typography>
        </Button>
      )}
    </CellItem>
  );
};
