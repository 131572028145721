import { Box } from "@material-ui/core";
import { Employee, TransactionDataType, Transaction, MemoTransaction } from "@onn/common";
import { format } from "date-fns";
import React, { useState, useEffect, ReactNode, useRef, FC } from "react";
import styled from "styled-components";

import {
  JoinedDateNotifyPaper,
  JoiningPaper,
  MentorDecisionNotifyPaper,
} from "~/components/domains/activities";
import { MemoPreview } from "~/components/domains/transactions";
import { ActivityContentItem, Paper, Icon, Typography } from "~/components/uiParts";
import { useEmployee, useScrollToTargetElement } from "~/hooks/employee";
import { EmployeeUseCase } from "~/service/usecases/employeeUseCase";
import { mixin } from "~/util";

const TIMESTAMP_FORMAT = "yyyy/MM/dd HH:mm";

type MemoAttachedFile = {
  fileName: string;
  src: string;
  path: string;
};

type MemoAttachedFilesMap = {
  [memoId: string]: MemoAttachedFile[];
};

type Props = {
  transaction: Transaction | MemoTransaction;
  employee: Employee;
  isScrollTarget: boolean;
  memoAttachedFilesMap: MemoAttachedFilesMap;
  memoAttachedFilesLoading: boolean;
  onClickAddCommentButton: () => void;
  onDownloadMemoAttachedFile: (url: string) => void;
  onDeleteMemoAttachedFile: (path: string) => void;
  isActive?: boolean;
};

export const ActivityContent: FC<Props> = ({
  transaction,
  employee,
  isScrollTarget,
  memoAttachedFilesMap,
  memoAttachedFilesLoading,
  onClickAddCommentButton,
  onDownloadMemoAttachedFile,
  onDeleteMemoAttachedFile,
  isActive = false,
}) => {
  const { data: mentor } = useEmployee(employee.mentorUserId);

  const [memoCreatedUser, setMemoCreatedUser] = useState<Employee>();

  const rootRef = useRef<HTMLDivElement>(null);

  let caption = !(transaction instanceof MemoTransaction) // メモ・WelcomeMessage以外のキャプション
    ? `${transaction.sendAt?.toString().replace(/-/g, "/")} 完了`
    : "";
  let title = "";
  let content: ReactNode | null;

  const findMemoCreatedUser = async (memoCreatedUserId: string) => {
    setMemoCreatedUser(await EmployeeUseCase.findById(memoCreatedUserId));
  };

  useEffect(() => {
    transaction instanceof MemoTransaction &&
      findMemoCreatedUser(transaction.contents.createdEmployeeId);
  }, [employee, transaction]);

  useScrollToTargetElement({
    enabled: isScrollTarget,
    targetRef: rootRef,
  });

  const targetTransaction = transaction as Transaction | MemoTransaction;

  switch (targetTransaction.dataType) {
    case TransactionDataType.JOIN_DATE_INPUT:
      title = "入社日設定";
      caption = `${format(targetTransaction.sendAt, TIMESTAMP_FORMAT)} 完了`;

      content = (
        <JoinedDateNotifyPaper
          fullName={employee.getName()}
          hasMentor={employee.hasMentor()}
          joinedAt={employee.joinAt?.toString().replace(/-/g, "/") ?? ""}
          isActive={isActive}
        />
      );
      break;
    case TransactionDataType.MENTOR_REGISTRATION:
      if (!mentor) break;

      title = "バディ設定";
      caption = `${format(targetTransaction.sendAt, TIMESTAMP_FORMAT)} 完了`;

      content = (
        <MentorDecisionNotifyPaper
          newHireName={employee.getName()}
          mentorProfileIconImageUrl={mentor && mentor.profileIconImageUrl}
          mentorName={mentor && mentor.getName()}
          isActive={isActive}
        />
      );
      break;
    case TransactionDataType.WELCOME:
      title = "入社";
      caption = `${format(targetTransaction.sendAt, TIMESTAMP_FORMAT)} 完了`;
      content = <JoiningPaper newHireEmployeeName={employee.getName()} isActive={isActive} />;
      break;
    case TransactionDataType.ADD_MEMO: {
      const memoTransaction = transaction as MemoTransaction;
      title = "メモ";
      caption = `${format(memoTransaction.createdAt, "yyyy/MM/dd HH:mm")} 投稿`;

      const attachedFiles = memoAttachedFilesMap[memoTransaction.id] ?? [];

      content = (
        <StyledPaper $isActive={isActive}>
          {!memoTransaction.deleted ? (
            <MemoPreview
              title={memoTransaction.contents.title}
              text={memoTransaction.contents.text}
              createdUser={
                memoCreatedUser
                  ? {
                      name: memoCreatedUser.getName(),
                      profileIconImageUrl: memoCreatedUser.profileIconImageUrl,
                      deleted: memoCreatedUser.deleted,
                    }
                  : undefined
              }
              createdAt={format(memoTransaction.createdAt, TIMESTAMP_FORMAT)}
              updatedAt={format(memoTransaction.updatedAt, TIMESTAMP_FORMAT)}
              attachedFiles={attachedFiles}
              attachedFilesLoading={memoAttachedFilesLoading}
              onDeleteFile={onDeleteMemoAttachedFile}
              onDownloadFile={onDownloadMemoAttachedFile}
            />
          ) : (
            <StyledBox display="flex" alignItems="center" $isActive={isActive}>
              <Box display="flex" mr={0.5}>
                <Icon icon="trash" size="md" color="grey" />
              </Box>
              <Typography>このメモは削除されました。</Typography>
            </StyledBox>
          )}
        </StyledPaper>
      );
      break;
    }
    default:
      break;
  }

  return (
    <div ref={rootRef}>
      <ActivityContentItem
        title={title}
        caption={caption}
        content={content}
        shareLink={`${location.origin}/employee/${employee.id}?contentId=${transaction.id}`}
        onClickAddCommentButton={onClickAddCommentButton}
      />
    </div>
  );
};

const StyledPaper = styled(Paper)<{ $isActive: boolean }>`
  ${(props) => props.$isActive && mixin.outlineAnimation()}
`;

const StyledBox = styled(Box)<{ $isActive: boolean }>`
  &.MuiPaper-root {
    ${(props) => props.$isActive && mixin.outlineAnimation()}
  }
`;
