import { AllContactRoom, LatestContactMessage, LineContactRoom } from "@onn/common";
import { isAfter, isEqual } from "date-fns";
import { useCallback } from "react";

export const useSortContactRoomsV2 = () => {
  /**
   * ContactRoomに紐づく最新メッセージの作成日時でソートする関数
   * - メッセージが存在する場合: 最新メッセージの作成日時が現在と近いものから降順
   *
   * @param {AllContactRoom} contactRooms
   */
  const sortContactRooms = useCallback(
    (contactRooms: AllContactRoom[], latestContactMessages?: LatestContactMessage[]) => {
      const sortByJLineContactRoom = (
        contactRoomA: AllContactRoom,
        contactRoomB: AllContactRoom
      ) => {
        // Lineユーザーの場合は上に表示する
        if (!contactRoomA.employee && contactRoomA instanceof LineContactRoom) return -1;
        if (!contactRoomB.employee && contactRoomB instanceof LineContactRoom) return 1;

        return -1;
      };

      return contactRooms.sort((a, b) => {
        // メッセージがない場合はコンタクトルームが作られた日、メッセージがあるものは最新のメッセージを比較のベースにする
        const aLatestMessage = latestContactMessages?.find((v) => v.contactRoomId === a.id);
        const bLatestMessage = latestContactMessages?.find((v) => v.contactRoomId === b.id);
        const aCreatedAt = aLatestMessage?.createdAt ?? a.createdAt;
        const bCreatedAt = bLatestMessage?.createdAt ?? b.createdAt;

        if (isEqual(aCreatedAt, bCreatedAt)) {
          return sortByJLineContactRoom(a, b);
        }

        // 日時が現在と近いものを上にするように
        return isAfter(aCreatedAt, bCreatedAt) ? -1 : 1;
      });
    },
    []
  );

  return { sortContactRooms };
};
