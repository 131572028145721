import { OnnFormTaskAnswer } from "../../domain";

export const createIMockOnnFromTaskAnswer = (
  mock: Partial<OnnFormTaskAnswer> = {}
): OnnFormTaskAnswer => {
  return new OnnFormTaskAnswer({
    id: mock.id ?? "OnboardingTaskMemo1",
    formTaskId: mock.formTaskId ?? "formTask1",
    employeeId: mock.employeeId ?? "employee1",
    tenantId: mock.tenantId ?? "tenant1",
    lastReadAt: mock.lastReadAt ?? null,
    firstReadAt: mock.firstReadAt ?? null,
    lastAnsweredAt: mock.lastAnsweredAt ?? null,
    answeredFormRevisionId: mock.answeredFormRevisionId ?? null,
    answers: mock.answers ?? [
      {
        id: "answer1",
        questionId: "question1",
        type: "CHECK_BOX",
        selectedOptionIds: ["option1", "option2"],
        freeText: "自由記述",
      },
      {
        id: "answer2",
        questionId: "question2",
        type: "TEXT",
        value: "テキスト",
      },
      {
        id: "answer3",
        questionId: "question3",
        type: "RADIO",
        selectedOptionId: "option1",
      },
      {
        id: "answer4",
        questionId: "question4",
        type: "FILE",
        filePath: "sample.pdf",
      },
    ],
  });
};
