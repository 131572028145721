import { CandidateDateWithNumberOfParticipants } from "@onn/common";
import React, { FC, useCallback, useMemo } from "react";

import { CAN_NOT_JOIN } from "../../../hooks/OnnEventAnswerResultTab/filter/useFilterByCandidateDates";

import { AnswerStatusHeaderCell } from "./Header/AnswerStatusHeaderCell";
import { CandidateDateHeaderCell } from "./Header/CandidateDateHeaderCell";
import { TotalCountHeaderCell } from "./Header/TotalCountHeaderCell";

import { UnAvailableHeaderCell } from "./Header/UnAvailableHeaderCell";

import { OnnEventAnswerWithEmployee } from "~/hooks/onnEvent";

export const HeaderCell: FC<{
  style: React.CSSProperties;
  candidateDateList: CandidateDateWithNumberOfParticipants[];
  onnEventAnswers: OnnEventAnswerWithEmployee[];
  hasCapacity: boolean;
  columnIndex: number;
  numberOfDistribution: number;
  numberOfResponses: number;
  columnLength: number;
  selectedCandidateDateIds: string[];
}> = ({
  style,
  columnIndex,
  candidateDateList,
  onnEventAnswers,
  hasCapacity,
  numberOfDistribution,
  numberOfResponses,
  columnLength,
  selectedCandidateDateIds,
}) => {
  // NOTE: 「ユーザーアイコン」列のレンダリング
  const TotalCountHeaderCellMemo = useMemo(
    () => (
      <TotalCountHeaderCell
        numberOfDistribution={numberOfDistribution}
        numberOfResponses={numberOfResponses}
        style={style}
      />
    ),
    [numberOfDistribution, numberOfResponses, style]
  );

  // NOTE: 「回答状況」列のレンダリング
  const AnswerStatusHeaderCellMemo = useMemo(
    () => <AnswerStatusHeaderCell style={style} />,
    [style]
  );

  // NOTE: 一番最後の列の「参加できる日程がない」列のレンダリング
  const UnAvailableHeaderCellMemo = useMemo(() => {
    const numOfAnswerWithUnavailableCandidates = onnEventAnswers.reduce((acc, currentAnswer) => {
      if (currentAnswer.isUnavailableCandidates()) return acc + 1;
      return acc;
    }, 0);
    return (
      <UnAvailableHeaderCell
        style={{ ...style }}
        columnIndex={columnIndex}
        numOfAnswerWithUnavailableCandidates={numOfAnswerWithUnavailableCandidates}
      />
    );
  }, [columnIndex, onnEventAnswers, style]);

  // NOTE:「回答結果」列のレンダリング
  const CandidateDateHeaderCellRenderer = useCallback(
    (candidateDate: CandidateDateWithNumberOfParticipants | undefined) => {
      if (!candidateDate) return null;
      return (
        <CandidateDateHeaderCell
          style={{ ...style }}
          hasCapacity={hasCapacity}
          numberOfParticipants={candidateDate.numberOfParticipants}
          capacity={candidateDate.capacity}
          from={candidateDate.from}
          until={candidateDate.until}
        />
      );
    },
    [hasCapacity, style]
  );

  // NOTE: ２列目以降は、開催日時フィルターに応じて動的に表示項目が変わる
  const columnMap = useMemo(() => {
    const _columnMap: { [index: number]: React.JSX.Element | null } = {
      0: TotalCountHeaderCellMemo,
      1: AnswerStatusHeaderCellMemo,
    };

    if (selectedCandidateDateIds.length === 0) {
      // NOTE: 開催日時フィルターが選択されていない場合は、全ての日程＋「参加できる日程がない」を表示する
      candidateDateList.forEach((candidateDate, index) => {
        _columnMap[index + 2] = CandidateDateHeaderCellRenderer(candidateDate);
      });
      _columnMap[columnLength - 1] = UnAvailableHeaderCellMemo;
    } else {
      // NOTE: 開催日時フィルターが選択されている場合は、選択された日程のみ表示する
      const displayedCandidateDates = candidateDateList.filter((candidateDate) => {
        return selectedCandidateDateIds.includes(candidateDate.id);
      });
      displayedCandidateDates.forEach((candidateDate, index) => {
        _columnMap[index + 2] = CandidateDateHeaderCellRenderer(candidateDate);
      });
      if (selectedCandidateDateIds.includes(CAN_NOT_JOIN)) {
        _columnMap[columnLength - 1] = UnAvailableHeaderCellMemo;
      }
    }

    return _columnMap;
  }, [
    AnswerStatusHeaderCellMemo,
    CandidateDateHeaderCellRenderer,
    TotalCountHeaderCellMemo,
    UnAvailableHeaderCellMemo,
    candidateDateList,
    columnLength,
    selectedCandidateDateIds,
  ]);

  return columnMap[columnIndex];
};
