import { EmployeeInformation, KanaName } from "../../domain";

import {
  Address,
  Affiliation,
  GraduationYearAndMonth,
} from "../../domain/EmployeeInformation/EmployeeInformation";

export const createIMockEmployeeInformation = (
  mock: Partial<
    DeepConvertValueType<
      EmployeeInformation,
      KanaName | undefined,
      { firstName?: string; lastName?: string }
    >
  > = {}
): EmployeeInformation =>
  new EmployeeInformation({
    id: mock.id ?? "employeeInformation1",
    tenantId: mock.tenantId ?? "tenant1",
    employeeId: mock.employeeId ?? "employee1",
    value: mock.value
      ? {
          ...mock.value,
          kanaName: mock.value.kanaName && new KanaName(mock.value.kanaName),
        }
      : {},
  });

export const createIMockEmployeeInformationWithValue = (
  mock: Partial<EmployeeInformation> = {}
): EmployeeInformation =>
  new EmployeeInformation({
    id: mock.id ?? "employeeInformation1",
    tenantId: mock.tenantId ?? "tenant1",
    employeeId: mock.employeeId ?? "employee1",
    value: {
      kanaName: new KanaName({
        firstName: "firstName",
        lastName: "lastName",
      }),
      gender: "男性",
      dateOfBirth: new Date("2000-01-01"),
      phoneNumber: "000-0000-0000",
      address: new Address({
        postalCode: "000-0000",
        prefecture: "東京都",
        city: "千代田区",
        addressLine1: "千代田1-1-1",
        addressLine2: "千代田ビル",
      }),
      hometownAddress: new Address({
        postalCode: "000-0000",
        prefecture: "東京都",
        city: "千代田区",
        addressLine1: "千代田1-1-1",
        addressLine2: "千代田ビル",
      }),
      graduationYearAndMonth: new GraduationYearAndMonth({
        graduationYear: 2022,
        graduationMonth: 3,
      }),
      externalId: "外部ID",
      affiliation: new Affiliation({
        schoolName: "東京大学",
        faculty: "法学部",
      }),
      ...mock.value,
    },
  });
