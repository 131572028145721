import React, { useCallback, useEffect, useRef, useState, FC } from "react";
import styled, { css } from "styled-components";

import {
  Props as EventDetailSummaryPaperProps,
  OnnTaskDetailSummaryPaper,
} from "./OnnTaskDetailSummaryPaper";

import { useShouldSPView } from "~/hooks/shared/useShouldSPView";

type Props = Omit<EventDetailSummaryPaperProps, "isRounded">;

export const OnnTaskDetailSummary: FC<Props> = (props) => {
  const defaultContainerRef = useRef<HTMLDivElement>(null);
  const [defaultContainerPosition, setDefaultContainerPosition] = useState<number | undefined>(
    undefined
  );
  const shouldSPView = useShouldSPView();

  const handleSetDefaultContainerPosition = useCallback(() => {
    const clientRect = defaultContainerRef.current?.getBoundingClientRect();
    if (clientRect) {
      setDefaultContainerPosition(clientRect.top);
    }
  }, [defaultContainerRef]);

  useEffect(() => {
    // スクロールイベントで EventDetailSummary の位置を取得してセットする
    window.addEventListener("scroll", handleSetDefaultContainerPosition);

    return () => window.removeEventListener("scroll", handleSetDefaultContainerPosition);
  }, [handleSetDefaultContainerPosition]);

  return (
    <>
      {/* デフォルト表示用 */}
      <div ref={defaultContainerRef}>
        <OnnTaskDetailSummaryPaper {...props} />
      </div>

      {/* ダウンスクロール時にヘッダー固定表示用 */}
      <StyledHeaderFixedContainer
        $visible={defaultContainerPosition !== undefined && defaultContainerPosition < 0}
        $isSP={shouldSPView}
      >
        <OnnTaskDetailSummaryPaper {...props} isRounded={false} />
      </StyledHeaderFixedContainer>
    </>
  );
};

const StyledHeaderFixedContainer = styled.div<{ $visible: boolean; $isSP: boolean }>`
  position: fixed;
  top: 0;
  z-index: ${(props) => props.theme.zIndex.appBar};

  width: calc(
    100% -
      ${(props) => {
        if (props.$isSP) {
          return 0;
        }

        return props.theme.isOpenSidebar
          ? props.theme.constants.SIDEBAR_OPEN_WIDTH
          : props.theme.constants.SIDEBAR_CLOSED_WIDTH;
      }}px
  );
  left: ${(props) => {
    if (props.$isSP) {
      return 0;
    }

    return props.theme.isOpenSidebar
      ? props.theme.constants.SIDEBAR_OPEN_WIDTH
      : props.theme.constants.SIDEBAR_CLOSED_WIDTH;
  }}px;

  ${({ $visible }) =>
    $visible
      ? css`
          opacity: 1;
          visibility: visible;
        `
      : css`
          opacity: 0;
          visibility: hidden;
        `}
`;
