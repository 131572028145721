import { Menu, MenuItem, Box } from "@material-ui/core";
import { Employee, AllContactRoom, NewGraduateToDisplay } from "@onn/common";
import { isEmpty } from "lodash";
import React, { useState, useCallback, FC, MouseEvent, memo, useMemo } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { NewGraduateUseIcon } from "../NewGraduateUseIcon";

import { ContentWithLabel } from "./ContentWithLabel";

import { AddTagsMenu } from "~/components/domains/employees/EmployeeTags/AddTagsMenu";
import { NewGraduateMemo } from "~/components/domains/employees/NewGraduateTable/TableRowItems/NewGraduateMemo";
import { useGetBorderColorById } from "~/components/domains/employees/hooks";
import {
  UserIcon,
  UserIconGroup,
  IconButton,
  Paper,
  Typography,
  Tooltip,
  Loading,
  Icon,
  ClickableChip,
  AddChip,
  Button,
} from "~/components/uiParts";
import { Badge } from "~/components/uiParts/Badge";
import {
  useEmployee,
  useEmployeeModals,
  useEmployees,
  useNewGraduateModals,
  useUpdateNewGraduateMemo,
} from "~/hooks/employee";
import { useAddTagToEmployee } from "~/hooks/employee/useAddTagToEmployee";
import { useRemoveTagFromEmployee } from "~/hooks/employee/useRemoveTagFromEmployee";
import { useEmployeeTags } from "~/hooks/employeeTag";
import { useModal } from "~/hooks/modal";

// ====================
// props
// ====================

type Props = {
  newHire: NewGraduateToDisplay;
  currentUser: Employee;
  contactRoom: AllContactRoom | undefined;
  isRounded?: boolean;
  unreadContactMessageCount: number;
};

// ====================
// magic number
// ====================

const FirstIconWidthInUserIconGroup = 40;
const AfterTheFirstWidthInUserIconGroup = 32; // 2番目以降のアイコンや+nの部分

// ====================
// main
// ====================

export const NewGraduateSummaryPaper: FC<Props> = memo(function EmployeeSummary({
  newHire,
  currentUser,
  contactRoom,
  isRounded = true,
  unreadContactMessageCount,
}) {
  // ====================
  // hooks
  // ====================

  const { handleOpenDeleteEmployeeModal } = useEmployeeModals();
  const {
    handleOpenAssignNewGraduateFollowersModal,
    handleOpenAssignNewGraduateMentorModal,
    handleOpenManageMentorAndFollowersModal,
  } = useNewGraduateModals();

  const { data: mentor } = useEmployee(newHire.mentorUserId || "");
  const { data: followers = [] } = useEmployees(newHire.supportMemberEmployeeIds || []);
  const { getBorderColorById } = useGetBorderColorById();
  const { updateNewGraduateMemo } = useUpdateNewGraduateMemo();
  const { data: employeeTagsData, isLoading: isLoadingEmployeeTagsData } = useEmployeeTags();
  const { trigger: addTagToEmployee } = useAddTagToEmployee({
    employeeID: newHire.id,
  });
  const { removeTagFromEmployee } = useRemoveTagFromEmployee();
  const { handleModal } = useModal();

  // ====================
  // state
  // ====================

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [tagMenuAnchorEl, setTagMenuAnchorEl] = useState<HTMLElement | null>(null);
  // ====================
  // variables
  // ====================

  const handleOpenTagMenu = (event: MouseEvent<HTMLElement>) => {
    event.preventDefault();
    // NOTE: タグの件数に応じて表示するNodeが異なるため、敢えてWrapperの親Nodeを指定している
    setTagMenuAnchorEl(event.currentTarget.parentElement);
  };

  const handleCloseTagMenu = (event: MouseEvent<HTMLElement>) => {
    event.preventDefault();
    setTagMenuAnchorEl(null);
  };

  const isAccessible = useMemo(() => currentUser.isAdmin(), [currentUser]);

  const renderTooltipTitleForMentor = () => {
    let title = "";
    if (mentor) {
      title = "受け入れチーム設定を開く";
    } else if (isAccessible) {
      title = "担当者を設定する";
    }
    return title;
  };

  const buildMentorLabel = (mentor?: Employee) => {
    if (!mentor) return "未登録";

    // 設定済みでも招待中で氏名が設定されていなければemailを表示する
    if (mentor.getName().trim()) {
      return mentor.getName();
    } else {
      return mentor.email;
    }
  };

  // フォロワーが設定されている場合管理モーダルを表示し、そうでない場合はAdminOrDepartmentAdminの場合にのみ設定モーダルを表示する
  const switchSupportMemberModalOpen = useCallback(() => {
    if (!isEmpty(followers)) {
      return handleOpenManageMentorAndFollowersModal(newHire, mentor, followers);
    }
    if (isAccessible) {
      handleOpenAssignNewGraduateFollowersModal(newHire, mentor, followers);
    }
  }, [
    handleOpenAssignNewGraduateFollowersModal,
    handleOpenManageMentorAndFollowersModal,
    isAccessible,
    mentor,
    newHire,
    followers,
  ]);

  // 担当者が設定されている場合管理モーダルを表示し、そうでない場合はAdminOrDepartmentAdminの場合にのみ設定モーダルを表示する
  const switchMentorModalOpen = useCallback(() => {
    if (mentor) {
      return handleOpenManageMentorAndFollowersModal(newHire, mentor, followers);
    }
    if (isAccessible) {
      return handleOpenAssignNewGraduateMentorModal(newHire, mentor, followers);
    }
  }, [
    mentor,
    isAccessible,
    handleOpenManageMentorAndFollowersModal,
    newHire,
    followers,
    handleOpenAssignNewGraduateMentorModal,
  ]);

  // 画面遷移を防ぐpreventDefaultとメニューを閉じるsetStateをひとまとめに実行した後に目的のcallbackを実行する
  const handleClickHF = useCallback((e: MouseEvent<HTMLElement>, callback: () => void) => {
    e.preventDefault();
    setAnchorEl(null);
    callback();
  }, []);

  const menuOptions = () => {
    const optionsForUnregisteredNewHire = [];

    // アカウント未登録の場合は招待モーダルを表示する
    if (newHire.canInvite()) {
      optionsForUnregisteredNewHire.push({
        title: "招待",
        func: (e: MouseEvent<HTMLElement>) =>
          handleClickHF(e, () =>
            handleModal({
              name: "inviteNewGraduateModal",
              args: {
                currentUser,
                newGraduate: newHire,
              },
            })
          ),
      });
    }

    return [
      ...optionsForUnregisteredNewHire,
      {
        title: "受け入れチーム設定",
        func: (e: MouseEvent<HTMLElement>) => handleClickHF(e, () => switchMentorModalOpen()),
      },
      {
        title: "アカウント削除",
        func: (e: MouseEvent<HTMLElement>) =>
          handleClickHF(e, () => handleOpenDeleteEmployeeModal(newHire)),
      },
    ].flat();
  };

  const handleOpenMenu = (event: MouseEvent<HTMLElement>) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = (event: MouseEvent<HTMLElement>) => {
    event.preventDefault();
    setAnchorEl(null);
  };

  const supportMembersInfoForIcon = useMemo<
    { username: string; profileIconImageUrl?: string; borderColor?: "primary" | "blue" }[]
  >(
    () =>
      followers.map((sm) => ({
        username: sm.getName(),
        profileIconImageUrl: sm.profileIconImageUrl,
        borderColor: getBorderColorById(newHire, sm.id),
      })),
    [getBorderColorById, followers, newHire]
  );

  const supportMemberAreaWidth = useMemo<number>(
    () =>
      followers.length >= 4
        ? FirstIconWidthInUserIconGroup + AfterTheFirstWidthInUserIconGroup * 3
        : FirstIconWidthInUserIconGroup +
          AfterTheFirstWidthInUserIconGroup * (followers.length - 1),
    [followers.length]
  );

  const allTags = employeeTagsData?.employeeTags || [];
  const selectedTags = allTags.filter((v) => newHire.employeeTagIds?.includes(v.id));
  const candidateTags = allTags.filter((v) => !selectedTags.some((x) => x.id === v.id));

  const handleClickTagInMenu = useCallback(
    async (tagID: string) => {
      await addTagToEmployee({ newTagIDs: [tagID] });
    },
    [addTagToEmployee]
  );

  const handleClickTag = useCallback(
    async (tagID: string) => {
      await removeTagFromEmployee(tagID, newHire.id);
    },
    [removeTagFromEmployee, newHire.id]
  );
  // ====================
  // component
  // ====================

  if (isLoadingEmployeeTagsData) {
    return (
      <Box height="116px">
        <Loading size="small" />
      </Box>
    );
  } else {
    return (
      <StyledPaper square={!isRounded}>
        <Box display="flex" alignItems="center">
          <NewGraduateUseIcon newGraduate={newHire} />

          <Box ml="auto">
            <Box display="flex" alignItems="center" flexWrap="wrap" pl={2}>
              <ContentWithLabel label="担当者">
                <Tooltip arrow placement="bottom" title={renderTooltipTitleForMentor()}>
                  <StyledClickableBox
                    display="flex"
                    alignItems="center"
                    $isAccessible={isAccessible || mentor != null}
                    onClick={(e) => handleClickHF(e, () => switchMentorModalOpen())}
                  >
                    {(mentor || isAccessible) && (
                      <>
                        <UserIcon
                          circular
                          size="small"
                          username={mentor ? mentor.getName() : "未登録"}
                          profileIconImageUrl={mentor?.profileIconImageUrl}
                          badgeType={mentor ? undefined : "dot"}
                          borderColor="primary"
                        />
                        <Box display="inline-block" width="8px" />
                      </>
                    )}
                    <Typography
                      variant="caption"
                      color={mentor ? "textPrimary" : "textSecondary"}
                      display="block"
                      noWrap
                      disablePreWrap
                    >
                      {buildMentorLabel(mentor)}
                    </Typography>
                  </StyledClickableBox>
                </Tooltip>
              </ContentWithLabel>
            </Box>
          </Box>

          <Box ml="40px">
            <ContentWithLabel label="フォロワー">
              {isEmpty(followers) ? (
                <Tooltip
                  arrow
                  placement="bottom"
                  title={isAccessible ? "フォロワーを設定する" : ""}
                >
                  <StyledClickableBox
                    display="flex"
                    alignItems="center"
                    $isAccessible={isAccessible || !isEmpty(followers)}
                    onClick={(e) => handleClickHF(e, () => switchSupportMemberModalOpen())}
                  >
                    {isAccessible && (
                      <>
                        <UserIcon circular size="small" username="未登録" badgeType="dot" />
                        <Box display="inline-block" width="8px" />
                      </>
                    )}
                    <Typography variant="caption" color="textSecondary" display="block">
                      未登録
                    </Typography>
                  </StyledClickableBox>
                </Tooltip>
              ) : (
                <StyledClickableBox
                  display="flex"
                  alignItems="center"
                  onClick={(e) => handleClickHF(e, () => switchSupportMemberModalOpen())}
                  $isAccessible={isAccessible || !isEmpty(followers)}
                  $width={supportMemberAreaWidth}
                >
                  <UserIconGroup usersInfo={supportMembersInfoForIcon} max={4} tooltip />
                </StyledClickableBox>
              )}
            </ContentWithLabel>
          </Box>

          <Box ml="40px" minWidth={250}>
            <NewGraduateMemo
              key={`memo-${newHire.id}`}
              memo={newHire.admin_memo || ""}
              onUpdateMemo={async (newMemo: string) =>
                await updateNewGraduateMemo(newHire, newMemo)
              }
            />
          </Box>

          <Box ml="40px">
            {isAccessible && <IconButton icon="menuVert" onClick={handleOpenMenu} />}
          </Box>

          {contactRoom && !newHire.isNotRegisteredAndInvited() && (
            <Box ml="8px" height="40px" p="8px">
              <Link to={`/contact_rooms?contactRoomId=${contactRoom.id}`}>
                <Badge
                  badgeContent={
                    unreadContactMessageCount > 0 ? (
                      <Typography bold variant="caption">
                        {unreadContactMessageCount}
                      </Typography>
                    ) : undefined
                  }
                  color="secondary"
                >
                  <Icon icon="paperAirplane" color="primary" size="md" />
                </Badge>
              </Link>
            </Box>
          )}
        </Box>
        <StyledBox mt={2}>
          {newHire.employeeTagIds &&
            selectedTags.map((v) => (
              <ClickableChip
                key={v.id}
                label={v.name}
                onDelete={currentUser.isAdmin() ? () => handleClickTag(v.id) : undefined}
                addPaddingsWhenHover={false}
              />
            ))}
          {currentUser.isAdmin() && (
            <div>
              {newHire.employeeTagIds?.length === 0 ? (
                <Button
                  variant="text"
                  color="primary"
                  borderRadius="regular"
                  startIcon={<Icon icon="add" color="primary" size="md" />}
                  onClick={handleOpenTagMenu}
                >
                  <Typography bold>タグを追加</Typography>
                </Button>
              ) : (
                <AddChip
                  label={<Icon icon="add" color="primary" size="sm" />}
                  onClick={handleOpenTagMenu}
                />
              )}
            </div>
          )}
        </StyledBox>
        {Boolean(tagMenuAnchorEl) && (
          <AddTagsMenu
            anchorEl={tagMenuAnchorEl}
            onClose={handleCloseTagMenu}
            candidateTags={candidateTags}
            onClickTag={handleClickTagInMenu}
          />
        )}
        {Boolean(anchorEl) && (
          <Menu
            key={newHire.id}
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleCloseMenu}
            getContentAnchorEl={null}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            transformOrigin={{ vertical: "top", horizontal: "right" }}
          >
            {menuOptions().map((option) => (
              <MenuItem key={option.title} onClick={option.func}>
                <Typography variant="body2">{option.title}</Typography>
              </MenuItem>
            ))}
          </Menu>
        )}
      </StyledPaper>
    );
  }
});

// ====================
// style
// ====================

const StyledBox = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  row-gap: 8px;
  column-gap: 4px;
`;

const StyledPaper = styled(Paper)`
  padding: 24px;
`;

const StyledClickableBox = styled(Box)<{ $isAccessible: boolean; $width?: number }>`
  ${({ $isAccessible }) => $isAccessible && `cursor: pointer;`}
  ${({ $width }) => ($width ? `width: ${$width}px` : "width: 100%")}
`;
