import { Box } from "@material-ui/core";
import { OnnEvent } from "@onn/common";
import React, { FC, useMemo, useState } from "react";
import styled from "styled-components";

import { DownloadSampleFileStepContent } from "./DownloadSampleFileStepContent";
import { UploadFileStepContent } from "./UploadFileStepContent";

import { Modal, VerticalStepper } from "~/components/uiParts";

type Props = {
  open: boolean;
  onCancel: () => void;
  onnEvent: OnnEvent;
};

export const AnswerInterviewEventOnBehalfWithCSVModal: FC<Props> = ({
  open,
  onCancel,
  onnEvent,
}) => {
  const [activeStep, setActiveStep] = useState(1);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const stepContent = useMemo(() => {
    switch (activeStep) {
      case 1:
        return (
          <DownloadSampleFileStepContent onClickNextButton={handleNext} onnEventId={onnEvent.id} />
        );
      case 2:
        return <UploadFileStepContent onnEventId={onnEvent.id} onCancel={onCancel} />;
    }
  }, [activeStep, onCancel, onnEvent.id]);

  return (
    <Modal
      open={open}
      title={"回答一括登録"}
      content={
        <Box display="flex" alignItems="flex-start">
          <VerticalStepper activeStep={activeStep} labels={["ファイル準備", "アップロード"]} />
          <StyledStepContentContainer>{stepContent}</StyledStepContentContainer>
        </Box>
      }
      onCancel={onCancel}
    />
  );
};

const StyledStepContentContainer = styled(Box)`
  padding-left: 32px;
  margin-left: 32px;
  width: 720px;
  border-left: 1px solid ${(props) => props.theme.palette.grey[100]};
`;
