import { Failure, Success, Result, Employee } from "../../../../domain";
import { systemMessageActionTypes } from "../shared";

import { IListSystemMessagesRequest, listSystemMessagesRequestSchema } from "./schema";

export class ListSystemMessagesRequest implements IListSystemMessagesRequest {
  static readonly validator = listSystemMessagesRequestSchema;

  actionType: typeof systemMessageActionTypes.listSystemMessages = "listSystemMessages";
  employeeId: Employee["id"];

  constructor(init: ListSystemMessagesRequest) {
    this.employeeId = init.employeeId;
  }

  public static validate(
    data: ListSystemMessagesRequest
  ): Result<ListSystemMessagesRequest, Error> {
    const onnEventIdResult = ListSystemMessagesRequest.validator.safeParse(data);
    if (!onnEventIdResult.success) {
      return new Failure(new Error(onnEventIdResult.error.message));
    }

    return new Success(new ListSystemMessagesRequest(data));
  }

  public static createRequestBody({ employeeId }: { employeeId: string }) {
    return {
      actionType: "listSystemMessages",
      employeeId,
    };
  }
}
