import {
  CandidateDateWithNumberOfParticipants,
  EmployeeActiveLog,
  OnnEventDeterminedDate,
} from "@onn/common";

import { useContext, useMemo, useState } from "react";

import {
  StatusForDisplayEventTable,
  getStatusForDisplayEventTable,
} from "../../../components/AnswerResultTab/utils/getStatusForDisplayEventTable";

import { RecruitmentStatusesContext } from "~/components/providers/RecruitmentStatusProvider";
import { OnnEventAnswerWithEmployee } from "~/hooks/onnEvent";
import { captureException } from "~/util";

/**
 * ステータスによる絞り込みと結果
 */
export const useFilterByStatus = ({
  onnEventAnswersWithEmployee,
  determinedDates,
  candidateDateList,
  employeeIdToLatestEmployeeActiveLogMap,
}: {
  onnEventAnswersWithEmployee: OnnEventAnswerWithEmployee[];
  determinedDates: OnnEventDeterminedDate[];
  candidateDateList: CandidateDateWithNumberOfParticipants[];
  employeeIdToLatestEmployeeActiveLogMap: Map<string, EmployeeActiveLog>;
}) => {
  // ステータスに絞り込み条件
  const [selectedStatusTypes, setSelectedStatusTypes] = useState<StatusForDisplayEventTable[]>([]);

  const { recruitmentStatusMap } = useContext(RecruitmentStatusesContext);

  const employeeIdToDeterminedDate = useMemo(
    () =>
      new Map(
        (determinedDates || []).map((determinedDate) => [determinedDate.employeeId, determinedDate])
      ),
    [determinedDates]
  );

  const candidateDateIdToCandidateDateMap = useMemo(
    () => new Map(candidateDateList.map((candidateDate) => [candidateDate.id, candidateDate])),
    [candidateDateList]
  );

  // ステータスによる絞り込み結果
  const answersFilteredByAnswerStatus = useMemo(() => {
    if (!selectedStatusTypes.length) return onnEventAnswersWithEmployee;
    return onnEventAnswersWithEmployee.filter((answer) => {
      const recruitmentStatus = recruitmentStatusMap.get(answer.employee.recruitmentStatusId || "");
      if (!recruitmentStatus) {
        captureException({
          error: new Error("recruitmentStatus が想定しない undefined になっています"),
          tags: { type: "answersFilteredByAnswerStatus" },
          extras: {
            answerId: answer.id,
            employeeId: answer.employee.id,
            recruitmentStatusId: answer.employee.recruitmentStatusId,
            recruitmentStatusMap,
          },
        });
        return null;
      }

      const onnEventDeterminedDate = employeeIdToDeterminedDate.get(answer.employee.id);
      const answeredCandidateDate = onnEventDeterminedDate
        ? candidateDateIdToCandidateDateMap.get(onnEventDeterminedDate.candidateDateId)
        : undefined;

      const employeeActiveLog = employeeIdToLatestEmployeeActiveLogMap.get(answer.employeeId);

      const statusForDisplay = getStatusForDisplayEventTable({
        recruitmentStatus,
        newGraduate: answer.employee,
        onnEventAnswer: answer,
        onnEventDeterminedDate,
        answeredCandidateDate,
        employeeActiveLog,
      });

      return selectedStatusTypes.includes(statusForDisplay);
    });
  }, [
    candidateDateIdToCandidateDateMap,
    employeeIdToDeterminedDate,
    employeeIdToLatestEmployeeActiveLogMap,
    onnEventAnswersWithEmployee,
    recruitmentStatusMap,
    selectedStatusTypes,
  ]);

  return {
    answersFilteredByAnswerStatus,
    setSelectedStatusTypes,
    selectedStatusTypes,
  };
};
