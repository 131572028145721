import { Box } from "@material-ui/core";
import React, { ComponentProps, useState } from "react";

import styled from "styled-components";

import { useParseCsv } from "./useParseCsv";

import { Button, FilePicker, Icon, Typography } from "~/components/uiParts";
import { useSnackbar } from "~/hooks/shared";

type Props = {
  tagId: string;
  onCancel: () => void;
  onSubmit: ({ tagId, emails }: { tagId: string; emails: string[] }) => void;
};

export function FileUploadStep({ tagId, onCancel, onSubmit }: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const [file, setFile] = useState<File>();
  const parseResult = useParseCsv(file, tagId);

  const handleInputFile: ComponentProps<typeof FilePicker>["onChange"] = (payload) => {
    if (payload.status === "error") {
      enqueueSnackbar(payload.message, { variant: "error" });
      return;
    }
    setFile(payload.files[0]);
  };

  const handleUploadButton = () => {
    if (parseResult.status !== "success") throw new Error("no reachable");

    onSubmit(parseResult.result);
  };

  return (
    <Box display="grid" gridGap="40px">
      <Box display="grid" gridGap="32px">
        <Typography>準備したCSVファイルを選択し、アップロードしてください。</Typography>
        {parseResult.status === "error" && (
          <ErrorMessageArea>
            <Typography color="secondary" bold={true}>
              エラーの内容を確認し、修正したファイルをアップロードしてください
            </Typography>
            <ErrorList>
              {parseResult.errors.map((error) => (
                <Typography key={error} variant="body2" color="secondary" role="listitem">
                  {error}
                </Typography>
              ))}
            </ErrorList>
          </ErrorMessageArea>
        )}
        <Box display="flex" gridGap="16px" alignItems="center">
          <FilePicker
            accepts={["csv"]}
            inputAccept={".csv"}
            multiple={false}
            onChange={handleInputFile}
          >
            <Button
              startIcon={<Icon icon="clip" size="sm" color="primary" />}
              color={"primary"}
              borderRadius={"regular"}
              variant={"outlined"}
            >
              ファイルを選択
            </Button>
          </FilePicker>
          <Typography variant="body2">{file?.name || "ファイルが選択されていません"}</Typography>
        </Box>
      </Box>
      <Box
        display="grid"
        gridTemplateColumns="repeat(2, minmax(auto, 240px))"
        justifyContent="center"
        gridGap="24px"
      >
        <Button
          fullWidth
          color="default"
          variant="outlined"
          borderRadius="circle"
          onClick={onCancel}
        >
          キャンセル
        </Button>
        <Button
          fullWidth
          color="primary"
          variant="contained"
          borderRadius="circle"
          disabled={parseResult.status !== "success"}
          onClick={handleUploadButton}
        >
          アップロード
        </Button>
      </Box>
    </Box>
  );
}

const ErrorMessageArea = styled(Box)`
  padding: 16px;
  color: ${(props) => props.theme.palette.secondary.main};
  border: 1px solid ${(props) => props.theme.palette.secondary.main};
  border-radius: 4px;
  display: grid;
  gap: 8px;
`;

const ErrorList = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  & > *[role="listitem"] {
    &:before {
      content: "※";
      margin-right: 4px;
    }
  }
`;
