import { APISchema } from "@onn/common";
import { useCallback } from "react";

import { useAllNewcomers } from "./useAllNewcomers";

import { apiClient } from "~/libs";

export const useCreateNewGraduateWithCsv = () => {
  const { mutate: mutateAllNewcomers } = useAllNewcomers();

  const createNewGraduateWithCsv = useCallback(
    async ({
      isDryRun,
      base64EncodedCsvFile,
    }: {
      isDryRun: boolean;
      base64EncodedCsvFile: string;
    }) => {
      const requestBody: APISchema["/create_new_graduates_with_csv"]["POST"]["body"] = {
        base64EncodedCsvFile,
        isDryRun,
      };

      const response = await apiClient.post("/create_new_graduates_with_csv", requestBody);

      if (!requestBody.isDryRun) {
        mutateAllNewcomers();
      }
      return response;
    },
    [mutateAllNewcomers]
  );

  return { createNewGraduateWithCsv };
};
