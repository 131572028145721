import { Box } from "@material-ui/core";
import React, { FC, useCallback } from "react";

import { Button } from "~/components/uiParts";

// ====================
// props
// ====================

type Props = {
  newHireId: string;
};

// ====================
// main
// ====================

/**
 * 候補者詳細画面へのリンク
 */
export const NewGraduateDetailLink: FC<Props> = ({ newHireId }) => {
  // ====================
  // event handler
  // ====================

  const handleOpenNewHireDetail = useCallback(() => {
    window.open(`/employee/${newHireId}`, "_blank");
  }, [newHireId]);

  // ====================
  // component
  // ====================

  return (
    <Box display="flex" alignItems="center" width="312px">
      <Button
        color="default"
        variant="outlined"
        borderRadius="circle"
        fullWidth
        onClick={handleOpenNewHireDetail}
      >
        詳細を見る
      </Button>
    </Box>
  );
};
