import { addDays, format, isThisYear, isToday, isYesterday } from "date-fns";
import { v4 } from "uuid";

import { Employee } from "../Employee";

import { INotificationPart } from "../NotificationPart";

import { ISystemMessage, systemMessageSchema } from "./schema";

/**
 * @summary Onnのシステムが発行するメッセージ
 * 現時点ではコンタクトルームで表示される。
 * 今後アクティビティで表示される可能性もある
 */
export class SystemMessage implements ISystemMessage {
  static readonly validator = systemMessageSchema;

  readonly id: string;
  readonly employeeId: string;
  readonly tenantId: string;
  readonly title: string;
  readonly contents: INotificationPart[];
  readonly createdAt: Date;

  constructor(init: ExcludeMethods<SystemMessage>) {
    const parsedInit = SystemMessage.validator.parse(init);

    this.id = parsedInit.id;
    this.employeeId = parsedInit.employeeId;
    this.tenantId = parsedInit.tenantId;
    this.title = parsedInit.title;
    this.contents = parsedInit.contents;
    this.createdAt = parsedInit.createdAt;
  }

  /**
   * SystemMessageを新規作成するときに使うメソッド
   * @param {Optional<ExcludeMethods<SystemMessage>, "id" | "createdAt">} params
   * @returns SystemMessage
   */
  public static create(
    params: Optional<ExcludeMethods<SystemMessage>, "id" | "createdAt">
  ): SystemMessage {
    return new SystemMessage({
      ...params,
      id: params.id ?? v4(),
      createdAt: params.createdAt ?? new Date(),
    });
  }

  /**
   * SystemMessageを更新するときに使うメソッド
   * @param {Employee} currentUser
   * @returns {boolean} currentUserがこのSystemMessageを閲覧できるかどうか
   */
  public isViewable(currentUser: Employee): boolean {
    return this.tenantId === currentUser.tenantId;
  }

  getDisplayDate(): string | null {
    const createdAt = this.createdAt;
    if (isToday(createdAt)) {
      // 当日の場合は時刻表示
      return format(createdAt, "HH:mm");
    }

    if (isYesterday(createdAt)) {
      return "昨日";
    }

    if (isYesterday(addDays(createdAt, 1))) {
      return "一昨日";
    }

    // 同じ年でない場合は年も表示する
    if (!isThisYear(createdAt)) {
      return format(createdAt, "yyyy/MM/dd");
    }

    // それ以外は日付表示
    return format(createdAt, "MM/dd");
  }

  static plainToInstance(params: ExcludeMethods<SystemMessage>): SystemMessage {
    return new SystemMessage(params);
  }
}
