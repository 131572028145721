import { isEmpty } from "lodash";
import { useCallback } from "react";

import { v4 } from "uuid";

import { useSnackbar } from "../shared/useSnackbar";

import { useTenant } from "../tenant";

import { FileAPIAdapter } from "~/infrastructure/usecases/file/fileAPIAdapter";
import { apiClient } from "~/libs";
import { captureException } from "~/util";

const fileAPIAdapter = new FileAPIAdapter({ bucketType: "private" });

export function useCreateContactMessagesOrder() {
  const { tenant } = useTenant();
  const { enqueueSnackbar } = useSnackbar();

  /**
   * 一括メッセージ予約を作成する
   */
  const createOrder = useCallback(
    async ({
      text,
      files,
      receiverEmployeeIds,
      scheduledDate,
    }: {
      text: string;
      files: File[];
      receiverEmployeeIds: string[];
      scheduledDate?: Date;
    }) => {
      try {
        const fileInfos = files.map((file) => {
          const uid = v4();
          return {
            path: `tenants/${tenant.tenantId}/contact_messages/${uid}/${file.name}`,
            file,
          };
        });
        if (!isEmpty(fileInfos)) {
          await fileAPIAdapter.uploadFiles(
            fileInfos.map((fileInfo) => {
              return {
                path: fileInfo.path,
                file: fileInfo.file,
              };
            })
          );
        }

        const result = await apiClient.post(`/post_contact_messages_orders`, {
          text,
          "file-paths": fileInfos.map((fileInfo) => fileInfo.path),
          "scheduled-date": scheduledDate,
          "receiver-employee-ids": receiverEmployeeIds,
        });

        const successMessage = scheduledDate
          ? "新規メッセージの配信予約が完了しました"
          : `${result.data.sentEmployeeIds.length}名の対象者にメッセージが配信されました`;

        enqueueSnackbar(successMessage, { variant: "success" });
      } catch (e) {
        const errorMessage =
          e instanceof Error && e.message
            ? e.message
            : "配信予約の作成に失敗しました。Onn の管理者にお問い合わせください。";
        enqueueSnackbar(errorMessage, { variant: "error" });
        captureException({
          error: e as Error,
          tags: {
            type: "useCreateContactMessagesOrder:createOrder",
          },
        });
      }
    },
    [enqueueSnackbar, tenant.tenantId]
  );

  return { createOrder };
}
